import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataAnnex5, reportDataAnnex8 } from '@constants/reports/report-annex';
import { Report } from '@interfaces/report/report.interface';
import { ReportsService } from '@services/reports/reports.service';

@Component({
  selector: 'app-report-annex',
  templateUrl: './report-annex.component.html',
  styleUrls: ['./report-annex.component.scss']
})
export class ReportAnnexComponent {
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectedRadio = '5';

  constructor(
    private router: Router,
    private reportService: ReportsService
  ) {
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {}

  getData(): any {
    switch (this.selectedRadio) {
      case '5':
        return reportDataAnnex5;
      case '8':
      default:
        return reportDataAnnex8;
    }
  }

  handlePreviewClick(): void {
    this.reportService.handlePreviewClick(
      this.router,
      this.getData()
    );
  }

  handlePrintClick(): void {
    this.reportService.handlePrintClick(this.getData());
  }

  handlePdfClick(): void {
    this.reportService.handlePdfClick(this.getData());
  }

  handleExcelClick(): void {
    this.reportService.handleExcelClick(this.getData());
  }
}
