<ng-container class="report-custom-container">
  <app-report-select
    [title]="'Operaciones del plan de medicación'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="report-type">
          <div class="subtitle">Tipo de reporte</div>
          <mat-radio-group formControlName="reportType">
            <mat-radio-button value="0"> Consolidado </mat-radio-button>
            <mat-radio-button value="1"> Detallado tabla </mat-radio-button>
            <mat-radio-button value="2"> Detallado gráfico </mat-radio-button>
          </mat-radio-group>
        </section>

        <section class="plan-type" *ngIf="form.controls['reportType'].value !== '0'">
          <div class="subtitle">Tipo de plan de medicación</div>
          <app-form-field
            class="field col-6"
            [type]="'select'"
            label=""
            [fieldControl]="form.controls['planType'] | asFormControl"
            [options]="planTypeOptions"
            [multipleSelect]="false">
          </app-form-field>
        </section>

        <section class="institutions" *ngIf="form.controls['reportType'].value !== '0'">
          <div class="subtitle">Centros</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona los centros"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions"
            [multipleSelect]="true">
          </app-form-field>
        </section>

        <section class="interval">
          <div class="subtitle">Intervalo</div>

          <div
            class="interval-days"
          >
            <mat-form-field>
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <div class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>