<ng-container class="report-custom-container">
  <app-report-select
    [title]="'Reportes de incontinencia'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()">
    
    <div slot="content" class="container-options">
      <form [formGroup]="form" class="container-form">
        
        <section class="filter-container">
          <p class="subtitle">Tipo de reporte</p>
          <mat-radio-group formControlName="typeReport" class="col-6">
            <mat-radio-button value="1" class="col-4"> Pañales </mat-radio-button>
            <mat-radio-button value="2" class="col-4"> Materiales </mat-radio-button>
            <mat-radio-button value="3" class="col-4" *ngIf="typeGlobal"> RAPPEL-TENA </mat-radio-button>
            <mat-radio-button value="4" class="col-4" *ngIf="typeGlobal"> Márgenes </mat-radio-button>
          </mat-radio-group>
        </section>

        <section class="filter-container" *ngIf="form.controls['typeReport'].value !== '3'">
          <p class="subtitle">Agrupación de datos</p>
          <mat-radio-group formControlName="groupData" class="col-6">
            <mat-radio-button value="1" class="col-4"> Ordenes </mat-radio-button>
            <mat-radio-button
              *ngIf="form.controls['typeReport'].value === '1' && !typeGlobal"
              value="4"
              class="col-4"
            >
              Pacientes
            </mat-radio-button>
            <mat-radio-button value="2" class="col-4" *ngIf="typeGlobal"> Institución </mat-radio-button>
            <mat-radio-button value="3" class="col-4"> Artículos </mat-radio-button>
          </mat-radio-group>

          <mat-checkbox 
            class="col-12 marginBottom" 
            formControlName="groupBrandsCheck"
            *ngIf="typeGlobal">
            Agrupar marcas
          </mat-checkbox>
        </section>
        
        <section class="filter-container">
          <p class="subtitle">Filtrar por</p>
          <!-- Centros -->
          <app-form-field
            *ngIf="typeGlobal && form.controls['typeReport'].value !== '3'"
            class="field col-12 marginBottom"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>

          <!-- Empresas -->
          <mat-form-field 
            class="field col-12 marginBottom"
            *ngIf="form.controls['typeReport'].value === '3'">
            <mat-select
              formControlName="selectedCompany"
              placeholder="(Todas las empresas)"
            >
              <mat-option
                *ngFor="let option of selectedOptionsCompanies"
                [value]="option.Id"
              >
                {{ option.Description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox 
            class="col-12 marginBottom" 
            formControlName="deliveryNoteCheck" 
            *ngIf="form.controls['typeReport'].value === '3'">
            Intervalo de albaranes
          </mat-checkbox>
          <app-form-field
            *ngIf="typeGlobal && form.controls['deliveryNoteCheck'].value"
            class="field col-12"
            [type]="'number'"
            label="Albaran inicial"
            [fieldControl]="form.controls['deliveryNoteFrom'] | asFormControl">
          </app-form-field>
          <app-form-field
            *ngIf="typeGlobal && form.controls['deliveryNoteCheck'].value"
            class="field col-12"
            [type]="'number'"
            label="Albaran final"
            [fieldControl]="form.controls['deliveryNoteTo'] | asFormControl">
          </app-form-field>

          <mat-checkbox class="col-12" formControlName="dateIntervalCheck" *ngIf="form.controls['typeReport'].value !== '3'">
            Intervalo de fechas
          </mat-checkbox>
          <div *ngIf="form.controls['dateIntervalCheck'].value && form.controls['typeReport'].value !== '3'" class="interval marginBottom">
            <mat-form-field class="marginBottom">
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker>
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <div class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker>
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker>
            </mat-form-field>

            <mat-radio-group  formControlName="stateDateFilter" class="groupRadio col-2">
              <mat-radio-button value="1" class="col-6"> PPS </mat-radio-button>
              <mat-radio-button value="2" class="col-6"> PSR </mat-radio-button>
            </mat-radio-group>
          </div>

          <mat-checkbox class="col-12" formControlName="orderIntervalCheck" *ngIf="form.controls['typeReport'].value !== '3' && form.controls['groupData'].value !== '4'">
            Intervalo de ordenes
          </mat-checkbox>
          <div *ngIf="form.controls['orderIntervalCheck'].value && form.controls['typeReport'].value !== '3' && form.controls['groupData'].value !== '4'" class="orders marginBottom">
            <mat-form-field class="marginBottom">
              <div class="label">Desde</div>
              <input matInput type="number" formControlName="startSeq" />
            </mat-form-field>

            <mat-form-field>
              <div class="label">Hasta</div>
              <input matInput type="number" formControlName="endSeq" />
            </mat-form-field>
          </div>

          <mat-checkbox class="col-12" formControlName="refDiapersCheck" *ngIf="form.controls['typeReport'].value === '1' && form.controls['groupData'].value !== '4'">
            Referencias de pañales
          </mat-checkbox>
          <app-form-field
            class="field col-8"
            *ngIf="form.controls['refDiapersCheck'].value && form.controls['typeReport'].value === '1' && form.controls['groupData'].value !== '4'"
            [type]="'select'"
            label="Si solamente desea mostrar algunos pañales, selecciónelos aquí"
            [fieldControl]="form.controls['selectedRefDiapers'] | asFormControl"
            [options]="selectedOptionsRefDiapersOptions"
            [multipleSelect]="true">
          </app-form-field>

          <mat-checkbox class="col-12" formControlName="brandDiapersCheck" *ngIf="(form.controls['typeReport'].value === '1' || form.controls['typeReport'].value === '4') && form.controls['groupData'].value !== '4'">
            Marcas de pañales
          </mat-checkbox>
          <app-form-field
            class="field col-8"
            *ngIf="form.controls['brandDiapersCheck'].value && (form.controls['typeReport'].value === '1' || form.controls['typeReport'].value === '4') && form.controls['groupData'].value !== '4'"
            [type]="'select'"
            label="Si solamente desea mostrar algunas marcas, selecciónelas aquí"
            [fieldControl]="form.controls['selectedBrandDiapers'] | asFormControl"
            [options]="selectedOptionsBrandDiapersOptions"
            [multipleSelect]="true">
          </app-form-field>
        </section>

      </form>
    </div>
  </app-report-select>
</ng-container>
