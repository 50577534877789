<section [hidden]="loading" style="height: 100%;">
  <section #cards class="cards-wrapper">
    <div class="no-data"  *ngIf="!currentData?.length">Ningún residente encontrado</div>

    <ul class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
      <div *ngFor="let patient of currentData | slice : lowValue : highValue">
        <app-card [title]="patient.name" [id]="patient.id" [img]="patient.imagePath" [route]="route"
          [ngClass]="{ 'male': patient.gender == 'M', 'female': patient.gender == 'F' }"
          [actions]="actions" [alert]="(patient.alert || incorrectData(patient))"
          [colorHeader]="getPatientCardHeaderColor(patient)"
          [greyed]="isExitus(patient)"
          (deleteButtonCLicked)="handleDelete($event)"
          [defaultImg]="patient.gender == 'M' ? '/assets/pics/male.png' : '/assets/pics/female.png'"
          [nameTooltip]="getAlertTooltip(patient)"
          [canNavigate]="canNavigate"
        >
          <div slot="content">
            <p (click)="navigate(patient)">{{ patient.inst }} - {{ patient.number }}</p>
            <p (click)="navigate(patient)">
              {{ patient.birthday | date : "dd/MM/yyyy" }}
              {{
              patient.expedition
              ? " - " + (patient.expedition | date : "dd/MM/yyyy")
              : ""
              }}
            </p>
            <p (click)="navigate(patient)">{{ patient.department }}</p>
          </div>
        </app-card>
      </div>
    </ul>
    <ng-container *ngIf="!loading">
      <p *ngIf="currentFilter || currentSearch">
        {{ msgNotFound }}:
        <span class="search-value">{{ currentFilter || currentSearch }}</span>
      </p>
    </ng-container>

    <div class="pagination">
      <app-color-legend 
        class="legend" 
        [items]="colorLegendItems" 
        [legendPosition]="'top'"
      >
      </app-color-legend>
      
      <mat-paginator #paginator aria-label="Seleccionar pagina" [length]="currentData.length"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPageEvent($event)"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </section>
</section>
