//FIXME: Habrá que cambiar para todos los downloadpf y tal, el nombre del archivo (dn), referente a los datos dados.
export const reportDataPatientMedicineUse = {
  data: {
    rpp: '/COROTA/PatientMedicineUse',  // Or /COROTA/PatientMedicineUseByMed
    pts: 1,
    rp: '',
    dn: '',
    fr: true,
  },
  dataDownloadPDF: {
    rpp: '/COROTA/PatientMedicineUse',  // Or /COROTA/PatientMedicineUseByMed
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/PatientMedicineUse',  // Or /COROTA/PatientMedicineUseByMed
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/PatientMedicineUse',  // Or /COROTA/PatientMedicineUseByMed
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

