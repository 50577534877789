<div class="container-fluid" *ngIf="formReady">
    <div class="profile">
        <app-form-detail
        [title]="institutionInfo.main.name"
        [subTitle]="institutionInfo.main.nameAbbreviation"
        [formChanged]="formChanged"
        [canSave]="true"
        [collapsedLayout]="collapsedProfile"
        [aditionalActions]="getAditionalActions()"
        [typeDefaultImg]="2"
        [hideImageActions]="currentTab !== 0"
        [disabledAllActions]="institutionId == undefined"
        (saveChanges)="updateInstitutionEdit()"
        (collapseStatus)="collapsedProfile = $event"
        [section]="MainSection.Institution">
      </app-form-detail>
    </div>

    <div class="content" [ngClass]="{'full-width': collapsedProfile}">
      <mat-tab-group style="height: 100%;" animationDuration="0ms" [selectedIndex]="selectedTab" (selectedIndexChange)="tabIndexChange($event)">
        <ng-container *ngFor="let tab of tabs; let i = index" >
          <mat-tab 
            [disabled]="(isEdit && formChanged && currentTab !== i)"
            *ngIf="tab.hidden == undefined || tab.hidden().length > 0">
            <ng-template mat-tab-label>
              <mat-icon *ngIf="forms[tab.form] && forms[tab.form].form.invalid && forms[tab.form].form.touched" 
                color="warn" 
                class="example-tab-icon me-2">
                info
              </mat-icon>
              {{ tab.label }}
            </ng-template>
            <ng-container [ngSwitch]="tab.form">
            
              <!-- General -->
              <ng-container *ngSwitchCase="'general'">
                <app-institution-edit-general
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema"
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-general>
              </ng-container>

              <!-- Schedule -->
              <ng-container *ngSwitchCase="'schedules'">
                <app-institution-edit-schedule
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema"
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-schedule>
              </ng-container>

              <!-- Actus -->
              <ng-container *ngSwitchCase="'actus'">
                <app-institution-edit-actus
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema"
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-actus>
              </ng-container>

              <!-- Departments -->
              <ng-container *ngSwitchCase="'departments'">
                <app-institution-edit-departaments
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema"
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
              </app-institution-edit-departaments>
              </ng-container>

              <!--re -->
              <ng-container *ngSwitchCase="'re'">
                <app-institution-edit-re
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-re>
              </ng-container>

              <!-- pm -->
              <ng-container *ngSwitchCase="'pm'">
                <app-institution-edit-pm
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-pm>
              </ng-container>

              <!--Docuements -->
              <ng-container *ngSwitchCase="'documents'">
                <app-institution-edit-documents
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateInstitutionEdit()"
                >
                </app-institution-edit-documents>
              </ng-container>

              <!-- [patient]="patientData" -->
    
              <!-- <ng-container *ngSwitchDefault>
                <app-dynamic-form 
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [patient]="patientData"
                  [formChanged]="formChanged" 
                  (save)="saveRe()">
                </app-dynamic-form>
              </ng-container> -->
            </ng-container>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>