<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de incidencias en RE/PRM'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">

      <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Incidencias">
          <form [formGroup]="form">
            <div class="section">
              <p class="subtitle col-12">Tipo de reporte</p>
              <mat-radio-group formControlName="typeReport">
                <mat-radio-button value="0" class="col-4" *ngIf="typeGlobal"> Consolidado </mat-radio-button>
                <mat-radio-button value="1" class="col-4"> Por incidencias </mat-radio-button>
                <mat-radio-button value="2" class="col-4"> Por pacientes </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="section" *ngIf="typeGlobal && form.controls['typeReport'].value === '0'">
              <mat-radio-group formControlName="consolidatedType">
                <mat-radio-button value="0" class="col-12"> Todos los tipos </mat-radio-button>
                <mat-radio-button value="1" class="col-12"> Tipo 1+2 </mat-radio-button>
                <mat-radio-button value="2" class="col-12"> Tipo 3 </mat-radio-button>
                <mat-radio-button value="3" class="col-12"> Tipo 4 </mat-radio-button>
                <mat-radio-button value="4" class="col-12"> Tipo 5 </mat-radio-button>
                <mat-radio-button value="5" class="col-12"> Tipo 6 </mat-radio-button>
                <mat-radio-button value="6" class="col-12"> Tipo 7 </mat-radio-button>
                <mat-radio-button value="7" class="col-12"> PRM </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="section" *ngIf="typeGlobal && form.controls['typeReport'].value === '2'">
              <mat-checkbox class="col-12" formControlName="includeExitus">
                Incluir prescripciones vencidas y Exitus
              </mat-checkbox>
              <mat-checkbox class="col-12" formControlName="includeHistorical">
                Incluir históricos
              </mat-checkbox>

              <mat-form-field class="field col-4" *ngIf="form.controls['includeExitus'].value || form.controls['includeHistorical'].value">
                <input matInput [matDatepicker]="datePicker" formControlName="date" />
                <mat-datepicker-toggle matSuffix [for]="datePicker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="section" *ngIf="typeGlobal && form.controls['typeReport'].value !== '0'" >
              <div class="subtitle">Centro</div>
              <app-form-field
                class="field"
                [type]="'select'"
                label="Selecciona un centro"
                [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                [options]="selectOptionsInsitutions">
              </app-form-field>
            </div>

            <div class="section" *ngIf="form.controls['typeReport'].value !== '0'">
              <p class="subtitle col-12">Agrupación por</p>
              <mat-checkbox formControlName="checkboxDoctorSS">
                MedicoSS
              </mat-checkbox>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="Gestión" [disabled]="!typeGlobal">
          <form [formGroup]="form">
            <div class="section">
              <p class="subtitle col-12">Fecha del reporte</p>
              
              <mat-form-field class="field col-4">
                <input matInput [matDatepicker]="datePicker" formControlName="dateReport" />
                <mat-datepicker-toggle matSuffix [for]="datePicker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </app-report-select>
</ng-container>
