import { Component, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { io, Socket } from 'socket.io-client';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from '@json/src/environments/environment';
import { env } from 'process';

@Component({
  selector: 'app-chat-assistant',
  templateUrl: './chat-assistant.component.html',
  styleUrls: ['./chat-assistant.component.scss'],
  animations: [
    trigger('inOutToggleIcon', [
       transition(':enter', [
          style({transform: 'scale(0)'}),
          animate('300ms ease', style({transform: 'scale(1)'}))
       ]),
       transition(':leave', [
          animate('300ms ease', style({transform: 'scale(0)'}))
       ])
    ]),
    trigger('inOutChatbox', [
      transition(':enter', [
        style({opacity: 0, width: '200px', height: '0', minHeight: '0'}),
        animate('300ms ease', style({opacity: 1, width: '500px', height: '70vh'}))
      ]),
      transition(':leave', [
          animate('300ms ease', style({opacity: 0, width: '200px', height: '0', minHeight: '0'}))
      ])
    ]),
    trigger('inOutMessages', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(5px)'}),
        animate('300ms ease', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
          animate('300ms ease', style({opacity: 0, transform: 'translateY(5px)'}))
      ])
    ]),
    trigger('inOutInput', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease', style({opacity: 1}))
      ]),
      transition(':leave', [
          animate('300ms ease', style({opacity: 0}))
      ])
    ])
  ]
})
export class ChatAssistantComponent {

  @ViewChild('messagesContainer') messagesContainer: ElementRef | undefined;
  @ViewChild('input') input: ElementRef | undefined;

  socket: Socket;

  open: boolean = false;
  opened: boolean = false;

  errored: boolean = false;

  messages: { identifier?: number, sender: 'assistant' | 'human', text: string }[] = [
    { sender: 'assistant', text: '¿En qué puedo ayudarte?' }
  ]

  sampleQuestions: {question: string, title?: string, subheader?: string}[] = [
    { title: 'Dime que sabes hacer', question: '¿Con qué me puedes ayudar?' },
    { question: '¿Como busco un paciente?', subheader: 'Búsqueda' },
    { title: 'Pautar agudo', question: '¿Como pauto un agudo?', subheader: 'Prescripciones' },
    { question: '¿Como busco un medicamento?', subheader: 'Búsqueda' },
    { title: '¿Que significa PE al prescribir un medicamento?', question: '¿Que significa PE?', subheader: 'Prescripciones' },
    { question: '¿Que indica el símbolo del euro?', subheader: 'Prescripciones' },
    { title: 'Hacer una prescripción', question: '¿Cómo hago una prescripción?', subheader: 'Prescripciones' },
  ];

  userInput: string = '';

  footerOnScreen: boolean = false;

  constructor( 
    private router: Router,
    private cdRef: ChangeDetectorRef 
  ) {

    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(event => {
      console.log((event as NavigationEnd).urlAfterRedirects);

      if ( (event as NavigationEnd).urlAfterRedirects.includes('/cards') || (event as NavigationEnd).urlAfterRedirects.includes('/list') ) {
        this.footerOnScreen = true;
      } else {
        this.footerOnScreen = false;
      }
    });

    this.initializeSocket();
  }

  toggleChatbox() {
    if ( this.open ) {
      this.opened = false;
      this.errored = false;

      setTimeout(() => {
        this.open = false;
      }, 200)
    } else {
      this.open = true;

      setTimeout(() => {
        this.opened = true;
        this.cdRef.detectChanges();
        this.scrollToBottom();
        this.input?.nativeElement.focus();
      }, 200)
    }
  }

  initializeSocket() {
    this.socket = io(environment.xiltec_ai_assistant_url, {
      reconnection: true,
      autoConnect: false,
      extraHeaders: {
        'Authorization': environment.xiltec_ai_assistant_key
      }
    });

    this.socket.connect();

    this.socket.on('streamStart', () => {
      this.errored = false;
      this.messages.push({ identifier: 1, sender: 'assistant', text: '' });
      this.cdRef.detectChanges();
      this.scrollToBottom();
    })

    this.socket.on('stream', (data) => {
      this.messages.find(message => message.identifier === 1)!.text += data;
      this.cdRef.detectChanges();
      this.scrollToBottom();
    })

    this.socket.on('streamEnd', () => {
      this.messages.find(message => message.identifier === 1)!.identifier = undefined;
    })

    this.socket.on('error', (message) => {
      this.errored = true;
      console.error(`AI error: ${message}`);
    })
  }

  sendMessage() {
    if ( this.userInput.trim() === '' ) return; // BREAK EXECUTION

    let history = this.messages
      .slice(-6)
      .map(message => `${message.sender === 'human' ? 'user' : 'assistant'}: ${message.text}`)
      .join('\n');

    history += `\nuser: ${this.userInput}`;

    this.messages.push({ sender: 'human', text: this.userInput });
    this.socket.emit('query', history);
    this.userInput = '';

    this.cdRef.detectChanges();
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.messagesContainer?.nativeElement.scrollTo({
      left: 0, 
      top: this.messagesContainer.nativeElement.scrollHeight,
      behaviour: 'smooth' 
    });
  }

  askSampleQuestion(question: string) {
    this.userInput = question;
    this.sendMessage();
  }

  clearChat() {
    this.messages = [{ sender: 'assistant', text: '¿En qué puedo ayudarte?' }];
  }
}
