import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';

@Component({
  selector: 'app-vaccines',
  templateUrl: './vaccines.component.html',
  styleUrls: ['./vaccines.component.scss']
})
export class VaccinesComponent implements OnInit, OnDestroy {

  @Input() schema: FormItemSchemaInterface;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
