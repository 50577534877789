
<app-report-select
    class="report-custom-container"
    [title]="'Autorización de blísters'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div class="container-options" slot="content">

      <!-- filter centros -->
      <form [formGroup]="form" *ngIf="typeGlobal">

        <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>

        </section>
      </form>


      <p class="subtitle">Tipo de autorización</p>
      <mat-radio-group aria-label="Selecciona una opción">
        <mat-radio-button value="1" [checked]="true"
          >Autorización del paciente
        </mat-radio-button>
      </mat-radio-group>
  </div>
</app-report-select>