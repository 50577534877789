<mat-expansion-panel class="expansion-group" [expanded]="isNew && newExpanded">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{fieldControl.value.title ??  fieldsData?.title}}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
    </mat-expansion-panel-header>
    <app-dynamic-form
        [schema]="schema"
    ></app-dynamic-form>
</mat-expansion-panel>
