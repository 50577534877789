import { Injectable } from '@angular/core';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { MedicineFilterCondition } from '@interfaces/medicine/medicine.interface';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor() {}

  customFilterPredicatePatient(data: any, filters: string): boolean {
    const filterObj = JSON.parse(filters);
    const {
      filterValueGlobal,
      filterValueAlert,
      filterValueGender,
      filterValueExitus,
      filterValueBloqHosp,
      filterValueBloqVac,
    } = filterObj;

    const dataValues = Object.values(data).map((value: any) =>
      String(value).toLowerCase()
    );

    const transformedFilter = filterValueGlobal.trim().toLowerCase();

    const exitusCondition = filterValueExitus // if expedition !== null means that the person is dead.
      ? true
      : data?.expedition == null;

    const alertCondition =
      filterValueAlert === 'true'
        ? String(data.alert).toLowerCase().includes(filterValueAlert)
        : true;

    const genderCondition = filterValueGender
      ? String(data.gender).toLowerCase().includes(filterValueGender)
      : true;

    const bloqVacationCondition = filterValueBloqVac
      ? true
      : data?.blq == 1 || data?.blq == 0 || data?.blq == null;

    const bloqHospitalCondition = filterValueBloqHosp
      ? data?.blq == 2 || data?.blq == 4
      : true;

    return (
      dataValues.some((value: string) => value.includes(transformedFilter)) &&
      alertCondition &&
      genderCondition &&
      exitusCondition &&
      bloqHospitalCondition &&
      bloqVacationCondition
    );
  }

  //FIXME:  We will have to try to find another possible solution for the future
  // The custom functions for angular table filters do not allow references to external
  // methods and/or services, besides references to "this.", that is why this function must
  // be so long. There is a function in the Institution Service that performs functions
  // equal to those of this method, but these cannot be called.

  // In addition, the any types are complicated to change since this table is used for
  // very different objects.
  customFilterPredicateInstitution(data: any, filters: string): boolean {
    const filterObj = JSON.parse(filters);
    const { filterValueGlobal, filterValueType } = filterObj;

    const dataValues = Object.values(data).map((value: any) =>
      String(value).toLowerCase()
    );

    const transformedFilter = filterValueGlobal.trim().toLowerCase();

    let selectedFilters = {
      pharmacy: false,
      hospital: false,
      residency: false,
      other: false,
    };

    filterValueType.forEach((filter: SelectOption) => {
      if (filter.value === 'Farmacia') {
        selectedFilters.pharmacy = true;
      }

      if (filter.value === 'Hospital') {
        selectedFilters.hospital = true;
      }

      if (filter.value === 'Residencia') {
        selectedFilters.residency = true;
      }

      if (filter.value == 'Otro') {
        selectedFilters.other = true;
      }
    });

    const type = String(data.type).toLowerCase();
    let pharmacyCondition = true;
    let hospitalCondition = true;
    let residencyCondition = true;
    let otherCondition = true;

    if (!selectedFilters.pharmacy) {
      pharmacyCondition = !type.includes('farmacia');
    }

    if (!selectedFilters.hospital) {
      hospitalCondition = !type.includes('hospital');
    }

    if (!selectedFilters.residency) {
      residencyCondition = !type.includes('residencia');
    }

    if (!selectedFilters.other) {
      otherCondition =
        type.includes('farmacia') &&
        type.includes('hospital') &&
        type.includes('residencia');
    }

    return dataValues.some(
      (value: string) =>
        value.includes(transformedFilter) &&
        pharmacyCondition &&
        hospitalCondition &&
        residencyCondition &&
        otherCondition
    );
  }

  customFilterPredicateMedicine(data: any, filters: string): boolean {
    const filterObj = JSON.parse(filters);
    const { filterValueGlobal } = filterObj;
    const dataValues = Object.values(data).map((value: any) =>
      String(value).toLowerCase()
    );

    const transformedFilter = filterValueGlobal.trim().toLowerCase();
    const flag = data.flag;

    const isRO = (flag & 0x200) > 0;
    const isRA = (flag & 0x80) > 0;
    const isMEC = (flag & 0x100) > 0;
    const isAlzheimer = (flag & 2) > 0;
    const isObsolete = (flag & 4) > 0;
    const isNotPrescription = (flag & 0x400) > 0;
    const isUnidosis = (flag & 0x10) > 0;
    const isCobert = !((flag & 8) > 0);
    const isEmbistable = (flag & 1) > 0;
    const isNoIventary = (flag & 0x20) > 0;
    const isNone =
      isRO &&
      isRA &&
      isAlzheimer &&
      isObsolete &&
      isUnidosis &&
      !isCobert &&
      isEmbistable &&
      isNoIventary;

    let conditions: MedicineFilterCondition = {
      roCondition: filterObj.RO ? true : !isRO,
      raCondition: filterObj.RA ? true : !isRA,
      mecCondition: filterObj.MEC ? true : !isMEC,
      alzheimerCondition: filterObj.alzheimer ? true : !isAlzheimer,
      obsoleteCondition: filterObj.obsolete ? true : !isObsolete,
      noPrescriptionCondition: filterObj.noPrescription
        ? true
        : !isNotPrescription,
      unitDoseCondition: filterObj.unitDose ? true : !isUnidosis,
      notCobertCondition: filterObj.cobert ? true : isCobert,
      embistableCondition: filterObj.noEmbistable ? true : !isEmbistable,
      noInventaryCondition: filterObj.noInventary ? true : !isNoIventary,
      noneCondition: filterObj.none ? true : isNone,
    };
    return dataValues.some(
      (value: string) =>
        value.includes(transformedFilter) &&
        conditions.roCondition &&
        conditions.mecCondition &&
        conditions.obsoleteCondition &&
        conditions.noPrescriptionCondition &&
        conditions.raCondition &&
        conditions.unitDoseCondition &&
        conditions.notCobertCondition &&
        conditions.embistableCondition &&
        conditions.noInventaryCondition &&
        conditions.alzheimerCondition &&
        conditions.noneCondition
    );
  }

  customFilterPredicateDefault(data: any, filters: string): boolean {
    const filterObj = JSON.parse(filters);
    const { filterValueGlobal } = filterObj;
    const dataValues = Object.values(data).map((value: any) =>
      String(value).toLowerCase()
    );
    const transformedFilter = filterValueGlobal.trim().toLowerCase();
    return dataValues.some((value: string) =>
      value.includes(transformedFilter)
    );
  }
}
