import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ModalService } from '@services/modal.service';
import { MedicineEditComponent } from '@shared/medicine-edit/medicine-edit.component';
import { PatientEditComponent } from '@shared/patient-edit/patient-edit.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsavedChangesGuard implements CanDeactivate<PatientEditComponent | MedicineEditComponent> {

    constructor(
        private modal: ModalService,
        private router: Router,
        private auth: AuthService
    ) { }

    canDeactivate(
        component: PatientEditComponent | MedicineEditComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.auth.loggedUserId == undefined) return true;

        if (this.router.getCurrentNavigation()?.extras.state?.['ignore']) {
            return true;
        }

        const changed = component?.formChanged;

        if ( component instanceof PatientEditComponent ) {
            if ( 
                !(
                    (component as PatientEditComponent).prescriptionEdit?.showEditForm && 
                    ((component as PatientEditComponent).prescriptionEdit.prescriptionToEdit === undefined || (component as PatientEditComponent).prescriptionEdit.isModification ) && 
                    ((component as PatientEditComponent).prescriptionEdit.prescriptionEdit ? (component as PatientEditComponent).prescriptionEdit.prescriptionEdit.form.valid : false)
                ) && !changed
            ) {
                return true;
            }
        } else {
            if (!changed) return true;
        }

        return new Promise<boolean>((resolve, reject) => {
            this.modal.openModalCallback(() => {
                resolve(true);
            }, `
            No se han guardado los datos.

            ¿Está seguro de que desea salir?
            `, "Salir", true, () => reject(false));
        });
    }

}
