import { DocumentService } from './../document.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CallsService } from '@services/api/calls.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
    DonwloadReportAPI,
    PreviewReportAPI,
    RenderReportAPI,
    ReportAPI,
    Report,
    ConfigRA,
} from '@interfaces/report/report.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { LoadingService } from '@services/loading.service';
import { environment } from '@json/src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {

    lastInvoicingDate: Date;

    constructor(
        private calls: CallsService,
        private documentService: DocumentService,
        private loading: LoadingService
    ) { }

    private _report: ReportAPI = { fn: '', fp: '', sz: 1, dct: '', pq: 0 };
    public reportList: BehaviorSubject<ReportAPI> =
        new BehaviorSubject<ReportAPI>({ fn: '', fp: '', sz: 1, dct: '', pq: 0 });

    transformReportAPItoReportAPP(report: ReportAPI) {
        return new Report(report);
    }

    downloadReport(
        data: DonwloadReportAPI,
        calls: CallsService
    ): Observable<{ report: Report; unsafeURL: string } | null> {
        const downloadObservable = calls.downloadReport(data);
        return downloadObservable.pipe(
            map((res: any) => {
                const report = res.payload;
                if (report.fn !== '' && report) {
                    const transformedReport = this.transformReportAPItoReportAPP(report);
                    const unsafeURL = this.parsePathHtml(
                        environment.urlBack,
                        transformedReport.filePath,
                        transformedReport.fileName
                    );
                    return { report: transformedReport, unsafeURL: unsafeURL };
                }
                return null;
            })
        );
    }

    getRepParamsEditDataIEF(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataIEF(insId);
    }

    getRepParamsEditDataPharmacologicPlan(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataPharmacologicPlan(insId);
    }

    getRepParamsEditDataPatDiet(insId: number) : Observable<any> {
        return this.calls.getRepParamsEditDataPatDiet(insId);
    }

    getRepParamsEditDataPrescriptionsPE(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataPrescriptionsPE(insId);
    }

    getRepParamsEditDataNeList(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataNeList(insId);
    }

    getRepParamsEditDataCursoClin(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataCursoClin(insId);
    }

    getRepParamsEditDataDiapers(distname: '' | null): Observable<any> {
        return this.calls.getRepParamsEditDataDiapers(distname);
    }

    getRepParamsEditDataPharmalert(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataPharmalert(insId);
    }

    getRepParamsEditDataMedchanges(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataMedchanges(insId);
    }

    getRepParamsEditDataActus(insId: number): Observable<any> {
        return this.calls.getRepParamsEditDataActus(insId);
    }

    renderReportPrint(
        data: RenderReportAPI,
        calls: CallsService
    ): Observable<{ report: Report; unsafeURL: string } | null> {
        const downloadObservable = calls.renderReport(data);
        return downloadObservable.pipe(
            map((res: any) => {
                const report = res.payload;
                if (report.fn !== '' && report) {
                    const transformedReport = this.transformReportAPItoReportAPP(report);
                    const unsafeURL = this.parsePathHtml(
                        environment.urlBack,
                        transformedReport.filePath,
                        transformedReport.fileName
                    );
                    return { report: transformedReport, unsafeURL: unsafeURL };
                }
                return null;
            })
        );
    }
    previewReport(data: PreviewReportAPI): void {
        this.calls.previewReport(data).subscribe({
            next: (res) => {
                this._report = res.payload;
                this.reportList.next(this._report);
            },
            error: () => {
                this.calls.openSnack('Error al visualizar el reporte');
            },
        });
    }

    parsePathHtml(globalPath: string, pathFp: string, fileName: string): string {
        let path = pathFp.replace(/\\/g, '/');
        return `${globalPath}${path}/${fileName}`;
    }

    changePageHtml(input: string, newNumber: number): string {
        const regex = /_(\d+)\.html$/;
        const replacement = `_${newNumber}.html`;
        return input.replace(regex, replacement);
    }

    findValueByType(typeToFind: string, data: any[]): any {
        const foundObject = data.find((obj) => obj.type === typeToFind);
        if (foundObject) {
            return foundObject.value;
        }
        return null;
    }

    getFilter(
        selectedNE: string,
        selectedSP: string,
        selectedDiaper: string,
        selectedHealMaterial: string
    ): number {
        let filt = 0;

        if (selectedNE) filt |= 0x02;
        if (selectedSP) filt |= 0x04;
        if (selectedDiaper) filt |= 0x08;
        if (selectedHealMaterial) filt |= 0x010;

        return filt;
    }

    handlePreviewClick(router: Router, reportData: any) {
        let newReportId = 0

        do {
            newReportId = Math.floor(Math.random() * 100000);
        } while (sessionStorage.getItem('report-'+newReportId) != null);

        sessionStorage.setItem('report-' + newReportId, JSON.stringify({
            data: JSON.stringify(reportData.data),
            print: JSON.stringify(reportData.dataRenderReport),
            pdf: JSON.stringify(reportData.dataDownloadPDF),
            excel: JSON.stringify(reportData.dataDownloadExcel)
        }));

        const urlTree = router.createUrlTree(['/global-eye/reports/viewer'], {
            queryParams: {
                id: newReportId
            }
        });
        const url = urlTree.toString();
        window.open(url, '_blank');
        setTimeout(() => {
            // Eliminamos la clave de la sesión actual, esperando un tiempo prudencial de 30 segundos
            sessionStorage.removeItem('report-' + newReportId);
        }, 1000 * 30);
    }

    handlePrintClick(reportData: any) {
        this.loading.start('Generando');
        this.renderReportPrint(reportData.dataRenderReport, this.calls).subscribe(
            (result: any) => {
                if (result) {
                    const report: any = result.report;
                    const unsafeURL: any = result.unsafeURL;
                   

                    this.loading.stop();
                    this.documentService.printFile(unsafeURL);
                }
            }
        );
    }

    handlePdfClick(reportData: any) {
        this.loading.start('Generando');
        this.downloadReport(reportData.dataDownloadPDF, this.calls).subscribe(
            (result: any) => {
                if (result) {
                    const report: any = result.report;
                    const unsafeURL: any = result.unsafeURL;
                    this.documentService.download(unsafeURL, report.fileName);
                }
                this.loading.stop();
            }
        );
    }

    handleExcelClick(reportData: any) {
        this.loading.start('Generando');
        this.downloadReport(reportData.dataDownloadExcel, this.calls).subscribe(
            (result: any) => {
                if (result) {
                    const report: any = result.report;
                    const unsafeURL: any = result.unsafeURL;

                    this.documentService.download(unsafeURL, report.fileName);
                }

                this.loading.stop();
            }
        );
    }

    getTextDescriptionRaReport(
        raReport: ConfigRA,
        optionsMedicine: SelectOption[]
    ): string {
        switch (raReport.RaStatus) {
            case 0:
                return 'No modificado';
            case 1:
                return 'Stop';
            case 2:
                return (
                    optionsMedicine.find(
                        (medicine) => medicine.value == raReport.MedTranslate
                    )?.label || ''
                );

            default:
                return '';
        }
    }
}
