<ng-container class="report-custom-container">
  <app-report-select
    [title]="'Reporte de estupefacientes'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="interval">
          <div class="subtitle">Tipo de reporte</div>
          <mat-radio-group formControlName="reportType">
            <mat-radio-button value="0"> Consolidado </mat-radio-button>
            <mat-radio-button value="1"> Detallado </mat-radio-button>
          </mat-radio-group>
        </section>

        <section class="interval"
          *ngIf="
            form.controls['reportType'].value == '1'
          "
        >
          <div class="subtitle">Limpieza</div>

          <mat-checkbox formControlName="checkboxShowAsProcessed">
            Marcar como procesados
          </mat-checkbox>
        </section>

        <section class="interval">
          <div class="subtitle">Intervalo</div>

          <div
            class="interval-days"
          >
            <mat-form-field>
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <div class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-checkbox class="show-processed" formControlName="checkboxShowProcessed">
              Mostrar procesados
            </mat-checkbox>
          </div>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
