import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { SharedModule } from '@shared/shared.module';

import { LayoutQuantumComponent } from './layout-quantum/layout-quantum.component';
import { PatientsQuantumComponent } from './patients-quantum/patients-quantum.component';
import { InstitutionsQuantumComponent } from './institutions-quantum/institutions-quantum.component';
import { MedicinesQuantumComponent } from './medicines-quantum/medicines-quantum.component';
import { MatDividerModule } from '@angular/material/divider';
import { CardsComponent as PatientCardsComponent } from './patients-quantum/cards/cards.component';
import { ListsComponent as PatientListsComponent } from './patients-quantum/lists/lists.component';
import { CardsComponent as MedicinesCardsComponent } from './medicines-quantum/cards/cards.component';
import { ListsComponent as MedicinesListsComponent } from './medicines-quantum/lists/lists.component';
import { CardsComponent as InstitutionsCardsComponent } from './institutions-quantum/cards/cards.component';
import { ListsComponent as InstitutionsListsComponent } from './institutions-quantum/lists/lists.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    LayoutQuantumComponent,
    PatientsQuantumComponent,
    InstitutionsQuantumComponent,
    MedicinesQuantumComponent,
    PatientCardsComponent,
    PatientListsComponent,
    MedicinesCardsComponent,
    MedicinesListsComponent,
    InstitutionsCardsComponent,
    InstitutionsListsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
})
export class ComponentsModule {}
