<section [hidden]="loading"  style="height: 100%;">
    <section #cards class="cards-wrapper">
        <ul class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
            <div *ngFor="let medicine of currentData | slice : lowValue : highValue">
                <app-card [title]="medicine.name" [id]="medicine.id" [img]="defaultImg" [route]="routes"
                    [alert]="hasAlert(medicine)"
                    [nameTooltip]="alertReason(medicine)"
                    [actions]="actions" 
                    [flag]="medicine.flag" 
                    [fgp]="isFGP(medicine)"
                    [greyed]="isObsolete(medicine)"
                    [colorHeader]="getColorHeader(medicine.flag)"
                    [containImage]="true"
                    (deleteButtonCLicked)="handleDelete($event)">
                    <div slot="content">
                        <p [routerLink]="routes + '/edit/' + medicine.id">{{ medicine.code }}</p>
                        <p [routerLink]="routes + '/edit/' + medicine.id">{{ medicine.supplier }}</p>
                    </div>
                </app-card>
            </div>
        </ul>
        <ng-container *ngIf="currentData.length === 0 && !loading">
            <mat-spinner *ngIf="!(currentFilter || currentSearch)"></mat-spinner>
            <p *ngIf="currentFilter || currentSearch">
                {{ msgNotFound }}:
                <span class="search-value">{{ currentFilter || currentSearch }}</span>
            </p>
        </ng-container>
        <div class="pagination">
            <app-color-legend 
                class="legend" 
                [items]="colorLegendItems" 
                [legendPosition]="'top'"
            >
            </app-color-legend>

            <mat-paginator aria-label="Seleccionar pagina" [length]="currentData.length" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" (page)="getPageEvent($event)" [pageIndex]="pageIndex"
                [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </section>
</section>
