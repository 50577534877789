export const reportDataMEC = {
  data: {
    rpp: '/COROTA/MecCount',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/MecCount',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/MecCount',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    dl: false,
    fmt: 2,
    htmf: false,
    z: 100,
    rpp: '/COROTA/MecCount',
    pts: 0,
    fr: true,
    dn: '',
    rp: '',
  },
};
