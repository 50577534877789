import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataPharmaPlan } from '@constants/reports/report-pharma-plan';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    DepartmentPharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-pharma-plan',
    templateUrl: './report-pharma-plan.component.html',
    styleUrls: ['./report-pharma-plan.component.scss'],
})
export class ReportPharmaPlanComponent implements OnInit {
    public reportDataPharmaPlan = reportDataPharmaPlan;
    public showPatientSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showDepartmentSelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public selectOptionsPatientsWithoutFilter: PatientPharma[];
    _selectOptionsPatients: PatientPharma[];
    set selectOptionsPatients(value: PatientPharma[]) {
        this._selectOptionsPatients = value;
        this.selectOptionsPatientsAutocomplete = value.map(v => {
            return {
                label: v.nam,
                value: v.id
            }
        })
    }
    get selectOptionsPatients() {
        return this._selectOptionsPatients;
    }
    selectOptionsPatientsAutocomplete: SelectOption[];
    public selectOptionsDepartment: DepartmentPharma[];



    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    


    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            generationDay: [moment(), Validators.required],
            checkboxControlPatient: [''],
            checkboxControlPatientExitus: [''],
            checkboxControlDepartment: [''],
            selectedDepartment: [''],
            selectedPatient: [''],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Plan farmacológico`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Plan farmacológico`);

        this.typeGlobal = this.roleManager.isPharma();
        this.getDataForTheSelects();
    }

    toggleSelectDepartment(): void {
        this.showDepartmentSelect = !this.showDepartmentSelect;
        if (!this.showDepartmentSelect) {
            this.form.patchValue({
                selectedDepartment: '',
            });
        }
    }
    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
              }
          });


        this.reportService
            .getRepParamsEditDataPharmacologicPlan(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsDepartment = result.payload.deps;
                this.selectOptionsPatientsWithoutFilter = result.payload.pats;
                this.selectOptionsPatients = this.setFilterExitus(false);
            });
    }

    toggleSelectPatientExitus(): void {
        if (this.exitusSelect) {
            this.selectOptionsPatients = this.setFilterExitus(false);
        } else {
            this.selectOptionsPatients = this.setFilterExitus(true);
        }

        this.exitusSelect = !this.exitusSelect;
    }

    setFilterExitus(isExitus: boolean): PatientPharma[] {
        // if (isExitus) return this.selectOptionsPatientsWithoutFilter;
        return this.selectOptionsPatientsWithoutFilter.filter(
            (patient) => patient.isEx == isExitus
        );
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { generationDay, selectedDepartment, selectedPatient, selectedInstitution } =
                this.getFormData();

            this.setInfoRP(generationDay, selectedDepartment, selectedPatient, selectedInstitution, true);

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPharmaPlan,
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
        
    }

    getFormData(): {
        generationDay: string;
        selectedDepartment: string;
        selectedPatient: string;
        selectedInstitution: string
    } {
        const generationDay = this.dateService.formatDateToString(
            this.form.get('generationDay')?.value
        );
        const selectedDepartment = this.form.get('selectedDepartment')?.value;
        const selectedPatient = this.form.get('selectedPatient')?.value;

        const {
            selectedInstitution,
          } = this.form.getRawValue();

        return { generationDay, selectedDepartment, selectedPatient, selectedInstitution };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { generationDay, selectedDepartment, selectedPatient, selectedInstitution } =
                this.getFormData();

            this.setInfoRP(generationDay, selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handlePrintClick(this.reportDataPharmaPlan);
        } else {
             if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { generationDay, selectedDepartment, selectedPatient, selectedInstitution } =
                this.getFormData();
            this.setInfoRP(generationDay, selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handlePdfClick(this.reportDataPharmaPlan);
        } else {
             if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { generationDay, selectedDepartment, selectedPatient, selectedInstitution } =
                this.getFormData();

            this.setInfoRP(generationDay, selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handleExcelClick(this.reportDataPharmaPlan);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(
        generationDay: string,
        selectedDepartment: string,
        selectedPatient: string,
        selectedInstitution: any, 
        isPreview: boolean = false
    ): void {
        // let selectInstitutions;
        // selectInstitutions = selectedInstitution
        // .map((obj: { id: any }) => obj.id)
        // .join(',');

        const rp = JSON.stringify({
            LocaleId: '3082',
            StartDate: Utils.BUG_FixDateForTimeZone(moment(generationDay).startOf('day').toDate())?.toISOString().split('.')[0],
            EndDate: this.dateService.transformDateTimeIncrementMonth(generationDay),
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DepartmentId: selectedDepartment ? String(selectedDepartment) : null,
            InstitutionId: String(selectedInstitution.id),
            // InstitutionId: String(this.currentInstitution),
            IncludeNoBlister: '1',
            Colegiado: '',
        });

        this.reportDataPharmaPlan.data.rp = rp;
        if (isPreview) this.reportDataPharmaPlan.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataPharmaPlan.dataDownloadPDF.rp = rp;
        this.reportDataPharmaPlan.dataDownloadExcel.rp = rp;
        this.reportDataPharmaPlan.dataRenderReport.rp = rp;

        const now = moment();
        this.reportDataPharmaPlan.dataDownloadPDF.dn = this.reportDataPharmaPlan.dataRenderReport.dn =
            `Plan_farmacologico_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataPharmaPlan.dataDownloadExcel.dn =
            `Plan_farmacologico_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
