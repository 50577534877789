<ng-container class="report-custom-container">
    <app-report-select 
        [title]="'Reporte de medicamentos no emblistados - S/P'"
        [disabled]="noFilterSelected()" 
        (previewClicked)="handlePreviewClick()"
        (printClicked)="handlePrintClick()" 
        (pdfClicked)="handlePdfClick()" 
        (excelClicked)="handleExcelClick()">
        <div slot="content" class="container-options">
            <form [formGroup]="form">


                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
            
                  </section>
            

                <section class="interval">
                    <p class="subtitle">Tipo de reporte</p>

                    <mat-radio-group formControlName="reportType">
                        <mat-radio-button value="consolidated" class="col-6">
                            Consolidado
                        </mat-radio-button>

                        <mat-radio-button value="byPatients" class="col-6">
                            Por pacientes
                        </mat-radio-button>
                    </mat-radio-group>
                </section>

                <section class="dates">
                <p class="col-12 subtitle">Intervalo</p>

                    <div class="container-fields">
                        <mat-form-field class="col-6">
                            <div class="label">Desde</div>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"/>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="col-6">
                            <div class="label">Hasta</div>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"/>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </section>

                <section class="filter-container">
                <p class="subtitle">Filtrar por</p>

                    <mat-checkbox formControlName="selectedNE" class="col-6">
                        Mostrar N/E
                    </mat-checkbox>
                    <mat-checkbox formControlName="selectedSP"  class="col-6">
                        Mostrar S/P
                    </mat-checkbox>
                    <mat-checkbox formControlName="selectedDiaper"  class="col-6">
                        Mostrar pañales
                    </mat-checkbox>
                    <mat-checkbox formControlName="selectedHealMaterial"  class="col-6">
                        Mostrar material curas
                    </mat-checkbox>

                    <mat-checkbox  class="col-12" *ngIf="form.controls['reportType'].value === 'byPatients'"
                        (change)="toggleCheckbox('checkboxControlDepartment')">
                        Departamentos
                    </mat-checkbox>
                    <app-form-field
                        class="field col-7"
                        *ngIf="form.controls['checkboxControlDepartment'].value"
                        [type]="'select'"
                        label="Si solamente desea mostrar algunos departamentos, seleciónelos aquí"
                        [fieldControl]="form.controls['selectedDepartments'] | asFormControl"
                        [options]="departmentSelectOptions"
                        [multipleSelect]="true">
                    </app-form-field>

                    <mat-checkbox  class="col-12" *ngIf="form.controls['reportType'].value === 'byPatients'"
                        (change)="toggleCheckbox('checkboxControlMedicationSchedule')">
                        Horarios de tomas
                    </mat-checkbox>
                    <app-form-field
                        class="field col-7"
                        *ngIf="form.controls['checkboxControlMedicationSchedule'].value"
                        [type]="'select'"
                        label="Si solamente desea mostrar algunos horarios, seleciónelos aquí"
                        [fieldControl]="form.controls['selectedMedicationSchedule'] | asFormControl"
                        [options]="scheduleSelect"
                        [multipleSelect]="true">
                    </app-form-field>

                    <mat-checkbox  class="col-12" (change)="toggleCheckbox('checkboxControlAdminRoute')">
                        Vía de administración
                    </mat-checkbox>
                    <app-form-field
                        class="field col-7"
                        *ngIf="form.controls['checkboxControlAdminRoute'].value"
                        [type]="'select'"
                        label="Si solamente desea mostrar algunas vías de administración, seleciónelas aquí"
                        [fieldControl]="form.controls['selectedAdminRoute'] | asFormControl"
                        [options]="medicineAdminRouteListOptions"
                        [multipleSelect]="true">
                    </app-form-field>

                    <mat-checkbox  class="col-12"(change)="toggleCheckbox('checkboxControlMedicament')">
                        Medicamentos
                    </mat-checkbox>
                    <app-form-field
                        class="field col-7"
                        *ngIf="form.controls['checkboxControlMedicament'].value"
                        [type]="'select'"
                        label="Si desea mostrar algunos medicamentos, seleciónelos aquí (Máximo 200 seleccionados)"
                        [fieldControl]="form.controls['selectedMedicines'] | asFormControl"
                        [options]="medicineSelectOptions"
                        [multipleSelect]="true"
                        [maxOptionSelect]="200">
                    </app-form-field>
                </section>
            </form>
        </div>
    </app-report-select>
</ng-container>
