<mat-dialog-content class="content">
  <lazy-img 
    [source]="source"  
    [contain]="true"
    [lens]="true"
    (onError)="onError()"
  >
  </lazy-img>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions">
  <button mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-actions>