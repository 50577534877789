<ng-container class="report-custom-container">
    <app-report-select [title]="'Reporte de cambios de medicación'"
        (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
        (excelClicked)="handleExcelClick()"
        [disabled]="!isChecked()">
        <div slot="content" class="container-options">
            <form [formGroup]="form">
                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
            
                  </section>

                <section class="interval">
                      
                    <p class="subtitle">Intervalo</p>

                    <mat-radio-group formControlName="intervalType">
                        <mat-radio-button value="specificDay" (change)="toggleSelectSpecificDay()">
                            Día específico
                        </mat-radio-button>

                        <mat-radio-button value="intervalDay" (change)="toggleSelectIntervalDay()">
                            Intervalo de fechas
                        </mat-radio-button>
                    </mat-radio-group>

                    <mat-form-field *ngIf="form.controls['intervalType'].value === 'specificDay'">
                        <div class="label"> Día específico</div>
                        <input matInput [matDatepicker]="specificDay" formControlName="specificDay" />
                        <mat-datepicker-toggle matSuffix [for]="specificDay">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #specificDay></mat-datepicker>
                    </mat-form-field>

                    <div class="interval-days" *ngIf="form.controls['intervalType'].value === 'intervalDay'">
                        <mat-form-field>
                            <div class="label">Desde</div>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <div class="label">Selecciona una opción</div>
                            <mat-select formControlName="selectedOptionFrom">
                                <mat-option *ngFor="let option of schedulesOptions" [value]="option.value">
                                    {{ option.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="interval-days" *ngIf="form.controls['intervalType'].value === 'intervalDay'">
                        <mat-form-field>
                            <div class="label">Hasta</div>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <div class="label">Selecciona una opción</div>
                            <mat-select formControlName="selectedOptionUntil">
                                <mat-option *ngFor="let option of schedulesOptions" [value]="option.value">
                                    {{ option.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </section>

                <section class="filter-container">
                    <p class="subtitle">Filtrar por</p>
                    <mat-checkbox (change)="toggleSelectPatient()" class="col-12">
                        Paciente
                    </mat-checkbox>
                    <div  class="select col-12" *ngIf="showPatientSelect">
                        <app-form-field 
                            label="" 
                            type="autocomplete" 
                            [options]="selectOptionsPatients" 
                            [fieldControl]="form.controls['selectedPatient'] | asFormControl">
                        </app-form-field>
                    </div>
                   
                    <mat-checkbox (change)="toggleSelectPersonal()"  class="col-12">
                        Personal
                    </mat-checkbox>
                    <div  class="select col-12" *ngIf="showPersonalSelect">
                        <mat-form-field >
                            <!-- <div class="label">Selecciona una opción</div> -->
                            <mat-select formControlName="selectedPersonal">
                                <mat-option *ngFor="let option of selectOptionsPersonal" [value]="option.nam">
                                    {{ option.nam }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                  
                </section>

                <section class="filter-container">
                    <p class="subtitle">Mostrar</p>
                    <mat-checkbox *ngFor="let data of filtersMedChanges" [formControlName]="data.formControlName" class="col-2">
                        {{ data.label }}
                    </mat-checkbox>
                </section>

                <p class="subtitle">Agrupación por</p>
                <mat-checkbox formControlName="checkboxDoctorSS">
                    MedicoSS
                </mat-checkbox>
            </form>
        </div>
    </app-report-select>
</ng-container>
