import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
    reportDataMissingMedicine,
    reportDataMissingMedicineByPatient,
    typesMissingMedicine,
} from '@constants/reports/report-missing-medicine';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-medicines-missing',
    templateUrl: './report-medicines-missing.component.html',
    styleUrls: ['./report-medicines-missing.component.scss'],
})
export class ReportMedicinesMissingComponent implements OnInit{
    public reportData = reportDataMissingMedicine;
    public typesMissingMedicine = typesMissingMedicine;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public missingMedicationForm: FormGroup<any>;


    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  

    constructor(
        private router: Router,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private calls: CallsService,
        private formBuilder: FormBuilder,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.currentRoute = this.router.url;
        this.missingMedicationForm = this.formBuilder.group({
            selectedType: ['missing-medicine', Validators.required],
            selectedInstitution: [''],
        });
    }


    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Medicamentos faltantes`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Medicamentos faltantes`);

        this.typeGlobal = this.roleManager.isPharma();
        
        this.getDataForTheSelects();
    }

    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
          this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());
            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
        
          if (this.selectOptionsInsitutions) {
            const hasEmptyValue = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
            );
            if (hasEmptyValue) {
              this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                (item) => item.value !== ''
              );
            }
            this.selectOptionsInsitutions.unshift({
                label: 'Todos los centros',
                value: ''
            });
          }

          if ( !this.typeGlobal ) {
            this.missingMedicationForm.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
          }
        });
    }
    setDataByTypeReport(): void {
        const selectedType = this.missingMedicationForm.get('selectedType');
        if (selectedType?.value == 'missing-medicine-patient') {
            this.reportData = reportDataMissingMedicineByPatient;
        } else {
            this.reportData = reportDataMissingMedicine;
        }

        const now = moment();

        this.reportData.dataDownloadPDF.dn = this.reportData.dataRenderReport.dn = `Reporte_de_medicamentos_faltantes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportData.dataDownloadExcel.dn = `Reporte_de_medicamentos_faltantes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    handlePreviewClick(): void {
        if (this.missingMedicationForm.valid) {
            this.setDataByTypeReport();
            this.reportData.data.rp = this.getInfoRP();
            this.reportData.data.dn = Utils.buildUniqueFileName + "_{0}.html";
            this.reportService.handlePreviewClick(this.router, this.reportData);
        } else {
            if ( this.missingMedicationForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un tipo de reporte');
            }
        }
    }

    handlePrintClick(): void {
        if (this.missingMedicationForm.valid) {
            this.setDataByTypeReport();
            this.reportData.dataRenderReport.rp = this.getInfoRP();
            this.reportService.handlePrintClick(this.reportData);
        } else {
            if ( this.missingMedicationForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un tipo de reporte');
            }
        }
    }

    handlePdfClick(): void {
        if (this.missingMedicationForm.valid) {
            this.setDataByTypeReport();
            this.reportData.dataDownloadPDF.rp = this.getInfoRP();
            this.reportService.handlePdfClick(this.reportData);
        } else {
            if ( this.missingMedicationForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un tipo de reporte');
            }
        }
    }

    handleExcelClick(): void {
        if (this.missingMedicationForm.valid) {
            this.setDataByTypeReport();
            this.reportData.dataDownloadExcel.rp = this.getInfoRP();
            this.reportService.handleExcelClick(this.reportData);
        } else {
            if ( this.missingMedicationForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un tipo de reporte');
            }
        }
    }

    getInfoRP(): string {
        let data: any = {
            LocaleId: "3082"
        };
      
        let  currentInstitution;
        const dataForm = this.missingMedicationForm.getRawValue()

        if (dataForm.selectedInstitution.id ) {
            //@ts-ignore
            currentInstitution = dataForm.selectedInstitution.id.toString();
        } else {
            currentInstitution = null;
        }


        if (this.missingMedicationForm.get('selectedType')?.value == 'missing-medicine-patient') {
            data = {
                LocaleId: "3082",
                FarmaticLinkId: this.institutionService.getCurrentFarmacy(),
                InstitutionId: currentInstitution,
                DateFrom: Utils.BUG_FixDateForTimeZone(moment().startOf('day').toDate())?.toISOString().split('.')[0],
                DateUntil: Utils.BUG_FixDateForTimeZone(moment().add(1, 'month').endOf('day').toDate())?.toISOString().split('.')[0]
            }
        }
        return JSON.stringify(data);
    }
}
