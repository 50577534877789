

  <ng-container class="report-custom-container"
    ><app-report-select
      [title]="'Reporte de movimientos de pacientes'"
      (previewClicked)="handlePreviewClick()"
      (printClicked)="handlePrintClick()"
      (pdfClicked)="handlePdfClick()"
      (excelClicked)="handleExcelClick()"
    >
      <div slot="content"  class="container-options">
        <form [formGroup]="dateForm" class="container-form">


          <section class="filter-container" *ngIf="typeGlobal" >
            <div class="subtitle">Centro</div>
            <app-form-field
              class="field"
              [type]="'select'"
              label="Selecciona un centro"
              [fieldControl]="dateForm.controls['selectedInstitution'] | asFormControl"
              [options]="selectOptionsInsitutions">
            </app-form-field>

          </section>

          <section class="filter-container" >
            <div class="subtitle">Intervalo</div>

            <mat-form-field>
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              >
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <div  class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              >
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #endDatePicker>
              </mat-datepicker>
            </mat-form-field>
          </section>
        </form>
      </div>
    </app-report-select>
  </ng-container>
