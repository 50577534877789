import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { GENDER_OPTIONS } from '@constants/options';

import { User } from '@interfaces/user/user.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';

import { FormMode } from '@json/src/app/enums/form-mode';
import { UserRole } from '@json/src/app/enums/user-role';
import { Utils } from '@json/src/app/Utils';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { InstitutionService } from '@services/institution.service';
import { PHARMA_ROLES, QUANTUM_ROLES } from '@constants/roles';
import { CallsService } from '@services/api/calls.service';


@Component({
    selector: 'app-admin-user-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit, OnDestroy {

    @Input() user: User;
    @Input() locales: Array<{ lid: number, nam: string }>
    @Output() formChanged = new EventEmitter<boolean>();
    @Output() onResetPassword = new EventEmitter<void>();
    @Output() onChangePassword = new EventEmitter<void>();
    @Input() canChangePassword = false;
    @Input() canResetPassword = false;
    tempPwd?: string;

    FormMode = FormMode;
    Utils = Utils;

    mode: FormMode = FormMode.View;

    form: FormGroup = new FormGroup({});

    public selectOptionsInsitutions: SelectOption[];

    genderOptions: SelectOption[] = GENDER_OPTIONS;
    languageOptions: SelectOption[] = []

    private subs: Subscription[] = [];
    isPharma = false;

    public roles: SelectOption[] = [];

    formReady: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public auth: AuthService,
        private institutionService: InstitutionService,
        private calls: CallsService
    ) {
        if (this.activatedRoute.snapshot.routeConfig?.path?.includes('new')) {
            this.mode = FormMode.New;
        } else {
            this.mode = FormMode.Edit;
        }

        this.activatedRoute.queryParamMap.subscribe(data => {
            if (data.has("tempPwd")) {
                this.tempPwd = data.get('tempPwd')!;
            }
        });

        this.form.addControl('username', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('name', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('gender', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('language', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('selectedInstitution', new FormControl(null, { validators: [Validators.required] }));
    }

    async ngOnInit() {
        await new Promise((resolve) => {
            this.subs.push(
                this.auth.decodedTokenSub.subscribe((token) => {
                    this.isPharma = token?.corotaIsFarmacia ?? false;

                    if ( this.isPharma ) {
                        this.roles = PHARMA_ROLES;
                    } else {
                        this.roles = QUANTUM_ROLES;
                    }

                    // Create a form group for each role
                    const rolesGrup = new FormGroup({}, { validators: [Validators.required] });
                    
                    this.roles.forEach(role => {
                        let status: boolean = false;

                        if (this.mode !== FormMode.New && this.user.roles.includes(role.value)) {
                            status = true;
                        }
                        
                        rolesGrup.addControl(role.label, new FormControl(status, { validators: [Validators.required] }));
                    
                        // Disable the role if the user is editing himself
                        if ( this.auth.getLoggedUser() == this.user.id ) {
                            rolesGrup.disable();
                        }

                        if ( AuthService.userRoles.includes(UserRole.Titular) && !AuthService.userRoles.includes(UserRole.Admin) ) {
                            rolesGrup.get('Admin')?.disable();
                        }
                    });

                    this.form.addControl('role', rolesGrup);

                    if (token != undefined)
                        resolve(true);
                })
            );
        });

        this.locales.forEach(l => {
            this.languageOptions.push({
                label: l.nam,
                value: l.lid
            });
        });

        if (this.mode !== FormMode.New) {
            this.form.controls['username'].setValue(this.user.uid);
            this.form.controls['username'].disable();
            this.form.controls['name'].setValue(this.user.nam);
            this.form.controls['gender'].setValue(this.user.gnd);
            this.form.controls['language'].setValue(this.user.locid);

            if ( !this.imAdmin() ) {
                this.form.controls['selectedInstitution'].disable();
            }
        } else {
            this.form.controls['gender'].patchValue('M', { emitEvent: false, onlySelf: true });
            this.form.markAllAsTouched();
        }

        await new Promise((resolve) => {
            this.institutionService.getInstitutions().then(() => {
                this.selectOptionsInsitutions = Object.assign([], this.institutionService.institutionList.getValue());
                // Order Alpabetically label and text uppercase
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
                // this.selectOptionsInsitutions.map((a) => {
                //     a.label = a.label.toUpperCase();
                // })
                if (this.selectOptionsInsitutions) {
                    const hasEmptyValue = this.selectOptionsInsitutions.some(
                        (item) => item.value === ''
                    );
                    if (hasEmptyValue) {
                        this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                            (item) => item.value !== ''
                        );
                    }
                }

                if (!this.isPharma) {
                    this.form.controls['selectedInstitution'].patchValue(this.selectOptionsInsitutions.filter(x => this.user.institutions?.includes(x.value.id)).map(x => x.value), { emitEvent: false, onlySelf: true });
                    if (this.form.controls['selectedInstitution'].value.length == 0 && this.mode == FormMode.New) {
                        this.form.controls['selectedInstitution'].patchValue([this.selectOptionsInsitutions.find(x => x.value.id == this.institutionService.getCurrentInstitution())?.value], { emitEvent: false, onlySelf: true })
                    }
                }

                resolve(true);
            });
        });

        this.form.valueChanges.subscribe((data) => {
            this.formChanged.emit(true)

            if ( this.isPharma ) {
                const roles = Object.keys(data.role)
                    .filter(key => data.role[key] === true);

                if ( roles.length > 1 && roles.includes('Admin') ) {
                    this.form.controls['role'].get('Admin')?.setValue(false);
                } 
            }
        });

        if ( this.isPharma ) {
            this.form.controls['role'].get('Admin')?.valueChanges.subscribe((data) => {
                if ( data ) {
                    this.form.controls['role'].get('Titular')?.setValue(false, { emitEvent: false });
                    this.form.controls['role'].get('Farmaceutico')?.setValue(false, { emitEvent: false });
                    this.form.controls['role'].get('Tecnico')?.setValue(false, { emitEvent: false });
                }
            });
        } else {
            this.form.controls['role'].get('Due')?.valueChanges.subscribe((data) => {
                if ( data && this.form.controls['role'].get('Medicacion')?.value ) {
                    this.form.controls['role'].get('Medicacion')?.setValue(false, { emitEvent: false });
                    this.calls.openSnack('No puede ser Medicación y Due a la vez');
                }
            });

            this.form.controls['role'].get('Medicacion')?.valueChanges.subscribe((data) => {
                if ( data && this.form.controls['role'].get('Due')?.value ) {
                    this.form.controls['role'].get('Due')?.setValue(false, { emitEvent: false });
                    this.calls.openSnack('No puede ser Due y Medicación a la vez');
                }
            });
        }

        this.formReady = true;
    }


    ngOnDestroy() {
        this.clearSubs();
    }

    imAdmin() {
        return AuthService.userRoles?.includes(UserRole.Admin);
    }

    clearSubs(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    getPasswordExpireTime() {
        var days = moment(this.user.updt).add(3, this.user.flg & 1 ? "d" : "M").diff(moment(), "days");
        this.user
        if (days > 0) {
            return 'La contraseña expira ' + moment.duration(days, 'days').locale('es').humanize(true);
        }
        else {
            return 'La contraseña expiró ' + moment.duration(days, 'days').locale('es').humanize(true);
        }
    }

    resetPassword() {
        this.onResetPassword.emit();
    }

    changePassword() {
        this.onChangePassword.emit();
    }

    getRoleFormControl(role: SelectOption): AbstractControl<any, any> {
        // @ts-ignore
        return this.form.controls['role'].get(role.label);
    }
}
