import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CallsService } from '@services/api/calls.service';
import { FilterService } from '@services/filter/filter.service';
import { LoadingService } from '@services/loading.service';
import { MedicinesService } from '@services/medicines.service';
import { ModalService } from '@services/modal.service';
import { PaginationService } from '@services/pagination/pagination.service';
import { CardsComponent } from '../cards/cards.component';
import { Medicine } from '@interfaces/medicine/medicine.interface';
import { RoleManagerService } from '@services/role-manager.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss', '../../../../../../../src/app/components/base/list/list.component.scss'],
})
export class ListsComponent extends CardsComponent implements OnInit, OnDestroy {

  typeGlobal: number = 1;

  constructor(
    protected override medicinesService: MedicinesService,
    protected override filterService: FilterService,
    protected override calls: CallsService,
    protected override modalService: ModalService,
    protected override router: Router,
    protected override loadingService: LoadingService,
    protected override paginationService: PaginationService,
    protected roleManager: RoleManagerService,
  ) {
    super(
      medicinesService,
      filterService,
      calls,
      modalService,
      router,
      loadingService,
      paginationService
    )
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.roleManager.isPharma()) {
      
      this.typeGlobal = 0;
    }
}

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
