import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    DiagnogsticsData,
    DiagnogsticsForm,
    // DiagnogsticsQueryType,
    DiagnogsticsItem,
} from '@interfaces/patient/patient.interface';
import { CallsService } from '@services/api/calls.service';
import { PatientsService } from '@services/patients.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RADIO_OPTIONS } from '@constants/options';
import { FormFieldComponent } from '@shared/form-field/form-field.component';
import { Utils } from '@json/src/app/Utils';
import { isMoment } from 'moment';

@Component({
    selector: 'app-diagnogstics-form',
    templateUrl: './diagnogstics-form.component.html',
    styleUrls: ['./diagnogstics-form.component.scss'],
})
export class DiagnogsticsFormComponent implements OnInit, OnDestroy {
    @Input() fieldControl: FormControl;
    @Input() readOnly?: () => boolean = () => false;
    @ViewChild("autocomplete") autocomplete: FormFieldComponent;

    public form: FormGroup<DiagnogsticsForm>;
    public files: File[];
    public showImportFile: boolean = false;
    public patientId: string;

    public columns = [
        { header: 'Fecha', columnDef: 'date' },
        { header: 'Código', columnDef: 'code' },
        { header: 'Descripción', columnDef: 'description' },
        { header: '', columnDef: 'actions' },
    ];

    public columnNames: string[] = this.columns.map((c) => c.columnDef);
    _rows: { date: Date; code: string; description: string }[] = []
    public set rows(value: { date: Date; code: string; description: string }[]) {
        value.sort((a, b) => b.date.getTime() - a.date.getTime());
        this._rows = value;
    }
    public get rows(): { date: Date; code: string; description: string }[] {
        return this._rows;
    }

    public radioOptions: SelectOption[] = RADIO_OPTIONS;

    public searchingResult: SelectOption = {
        label: 'Buscando...',
        value: { code: 'searching', description: 'searching' },
    };
    public noResult: SelectOption = {
        label: 'No hay resultados',
        value: { code: 'noResults', description: 'No results' },
    };
    public searchOptions: SelectOption[] = [];

    private subs: Subscription[] = [];

    constructor(
        private patientsService: PatientsService,
        private callsService: CallsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.rows = this.fieldControl.value;
        this.initForm();
        this.subs.push(
            this.fieldControl.valueChanges.subscribe((value) => {
                this.rows = [...value];
            }),
            this.route.paramMap.subscribe((params: ParamMap) => {
                this.patientId = params.get('id') ?? '';
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    initForm(): void {
        this.form = new FormGroup({
            date: new FormControl(new Date(), { validators: [Validators.required] }),
            removeCurrent: new FormControl(false, {
                validators: [],
                nonNullable: true,
            }),
            useDate: new FormControl(false, { validators: [], nonNullable: true }),
            // searchType: new FormControl<DiagnogsticsQueryType>(1, {
            //     validators: [],
            //     nonNullable: true,
            // }),
            search: new FormControl<DiagnogsticsItem | null>(null, [])
            // resultSelected: new FormControl<DiagnogsticsItem | null>(null, []),
        });

        // this.setNoResults();
        this.subs.push(
            this.form.controls['search'].valueChanges.subscribe((val) => {
                // this.search(val);
                this.setOptionSelected(<DiagnogsticsItem>val);
            }),
            // this.form.controls['resultSelected'].valueChanges.subscribe((val) => {
            //     this.setOptionSelected(<DiagnogsticsItem>val);
            // })
        );
    }

    lastOptions: SelectOption[] = [];
    async search(value: string) {
        if (value == undefined || value.trim().length == 0) return [];
        // const searchType = this.form.value['searchType'] ?? 1;
        // if (searchType == 0) {
        //     value = value?.split(' - ')[0];
        // }
        const prom = await new Promise<SelectOption[]>((resolve) => {
            this.patientsService
            .getDiagnogsticsList({
                query: value ?? ''
                // type: searchType,
            })
            .subscribe({
                next: (res) => {
                    console.log(res);

                    // if (searchType == 0) {
                    //     // Hacemos este cambio para agradar al autocomplete (ya que utiliza el label para buscar...)
                        res.forEach(r => {
                            const originalLabel = r.label;
                            const code = r.value.code;
                            const description = r.value.description
                            r.label = code + " - " + Utils.capitalize(description, true);
                        });
                    // }
                    // else {
                        // res.forEach(r => r.label = Utils.capitalize(r.label, true));
                    // }
                    res.sort((a, b) => a.value.description.localeCompare(b.value.description));

                    if (res.length > 0) this.lastOptions = res;
                    resolve(res.length > 0 ? res : this.lastOptions);
                },
                error: () => {
                    resolve([]);
                    // this.setNoResults();
                    // this.callsService.openSnack('Error al obtener los diagnósticos');
                },
            });
        });

        console.log(prom);
        return prom;
    }

    getContext() {
        return this;
    }

    // search(query: string | null): void {
    //     this.setSearching();
    //     this.patientsService
    //         .getDiagnogsticsList({
    //             query: query ?? '',
    //             type: this.form.value['searchType'] ?? 1,
    //         })
    //         .subscribe({
    //             next: (res) => {
    //                 res.length ? this.setResults(res) : this.setNoResults();
    //             },
    //             error: () => {
    //                 this.setNoResults();
    //                 this.callsService.openSnack('Error al obtener los diagnósticos');
    //             },
    //         });
    // }

    // setSearching(): void {
    //     this.form.controls['resultSelected'].setValue(null);
    //     // this.form.controls['resultSelected'].disable();
    //     this.searchOptions = [this.searchingResult];
    // }

    // setNoResults(): void {
    //     this.form.controls['resultSelected'].setValue(this.noResult.value);
    //     // this.form.controls['resultSelected'].disable();
    //     this.searchOptions = [this.noResult];
    // }

    // setResults(results: SelectOption[]): void {
    //     this.form.controls['resultSelected'].enable();
    //     this.form.controls['resultSelected'].setValue(null);
    //     this.searchOptions = results;
    // }

    setOptionSelected(option: DiagnogsticsItem): void {
        if (option && !['noResults', 'searching'].includes(option.code)) {
            // this.form.controls['search'].setValue(null);
            this.autocomplete.clearAutocompleteValue();
            this.addItem(option);
        }
    }

    addItem(option: DiagnogsticsItem): void {
        const temp = this.fieldControl.value;
        
        temp.push({
            date: isMoment(this.form.controls['date'].value) ? this.form.controls['date'].value.toDate() : this.form.controls['date'].value, 
            code: option.code,
            description: option.description,
        });

        this.fieldControl.setValue(temp);
    }

    deleteItem(row: any): void {
        const index = (this.fieldControl.value as []).findIndex((r) => r === row);
        const temp = this.fieldControl.value;
        temp.splice(index, 1);
        this.fieldControl.setValue(temp);
    }

    uploadFile(event: any): void {
        const fileList: FileList = event.target?.files ?? [];
        const formValue = this.form.value;
        const data: DiagnogsticsData = {
            date: <Date>formValue.date,
            removeCurrent: !!formValue.removeCurrent,
            useDate: !!formValue.useDate,
            patientId: this.patientId,
        };
        if (fileList.length) {
            this.patientsService.uploadDiagnogsticsFile(fileList[0], data).subscribe({
                next: (res) => {
                    //TODO: Que alguien haga algo
                },
                error: () => {
                    this.callsService.openSnack('Error al subir el archivo.');
                },
            });
        } else {
            this.callsService.openSnack('Archivo no seleccionado');
        }
    }
}
