<ng-container *ngFor="let section of schema; let i = index;">
    <div class="section" 
      *ngIf="visibility[i]?.visible" 
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <!-- Title -->
      <div class="title">
        {{section.label}}
      
        <ng-container *ngIf="section.label === 'Principios activos' && dangerGroup">
          <div class="badges">
            <ng-container [ngSwitch]="dangerGroup">
              <img
                *ngSwitchCase="1"
                class="img-icon" 
                src="assets/images/meidcamentos-peligrosos/grupo1-32.png"
                matTooltip="Antineoplásico"
              />
              <img
                *ngSwitchCase="2"
                class="img-icon" 
                src="assets/images/meidcamentos-peligrosos/grupo2-32.png"
                matTooltip="No antineoplásico"
              />
              <img
                *ngSwitchCase="3"
                class="img-icon" 
                src="assets/images/meidcamentos-peligrosos/grupo3-32.png"
                matTooltip="Riesgo para el proceso reproductivo"
              />
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="section.label === 'Otros datos'">
          <div class="badges">
            <img
              *ngIf="isMedicineHospitalUse(section)"
              class="img-icon hospital" 
              src="assets/images/gsp/diagnostico-hospitalario-32.png"
              matTooltip="Hospitalario"
            />
          </div>
        </ng-container>
      </div>
      <!-- Content -->
      <ng-container [ngSwitch]="section.type">
        <div class="content" >
          <!-- Active ingredients -->
          <ng-container *ngSwitchCase="'active-ingredients'">
            <div class="active-ingredients-list">
              <div class="active-ingredients-item" *ngFor="let activeIngredient of section.fieldControl.value">
                <div class="name">{{activeIngredient.Nombre}}</div>
                <div class="badges">
                  <img
                    *ngIf="activeIngredient.Estupefaciente"
                    class="img-icon hospital" 
                    src="assets/images/gsp/estupefaciente-32.png"
                    matTooltip="Estupefaciente"
                  />
                  <img
                    *ngIf="activeIngredient.Psicotropo"
                    class="img-icon hospital" 
                    src="assets/images/gsp/psicotropo-32.png"
                    matTooltip="Psicótropo"
                  />
                  <img
                    *ngIf="activeIngredient.DMARC"
                    class="img-icon hospital" 
                    src="assets/images/gsp/marc-64.png"
                    matTooltip="MARK"
                  />
                </div>
              </div>

              <div class="no-data" *ngIf="!section.fieldControl.value.length">
                No hay principios activos conocidos
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngFor="let child of section.childs">
              <ng-container [ngSwitch]="child.type">
            
                <!-- Group checkbox field -->
                <ng-container *ngSwitchCase="'group-checkbox'">
                  <app-form-field 
                    *ngFor="let check of child.childs"
                    class="field"
                    [class]="check.size || ''"
                    [ngClass]="check.customClass"
                    [ngStyle]="check.customStyle"
                    [type]="check.type" 
                    [label]="check.label" 
                    [hideLabel]="check.hideLabel"
                    [hintMsg]="check.hintMsg" 
                    [placeholder]="check.placeholder"
                    [fieldControl]="check.fieldControl | asFormControl" 
                    [customFieldData]="check.customFieldData"
                    [options]="check.options" 
                    [readOnly]="check.readOnly" 
                    [min]="check.min" 
                    [max]="check.max"
                    [inputPattern]="check.inputPattern" 
                    [capitalize]="check.capitalize"
                    [uppercase]="check.uppercase"
                    [errorMsg]="check.errorMsg ?? ''" 
                    [group]="check.group" 
                    [customStyle]="check.customStyle">
                  </app-form-field>
                </ng-container>
                <!-- Default form field -->
                <ng-container *ngSwitchDefault>
                  <ng-container *ngIf="child.visible">
                    <app-form-field 
                      class="field"
                      [class]="child.size || ''"
                      [ngClass]="child.customClass"
                      [ngStyle]="child.customStyle"
                      [type]="child.type" 
                      [label]="child.label" 
                      [hideLabel]="child.hideLabel"
                      [hintMsg]="child.hintMsg" 
                      [placeholder]="child.placeholder"
                      [fieldControl]="child.fieldControl | asFormControl" 
                      [customFieldData]="child.customFieldData"
                      [options]="child.options" 
                      [readOnly]="child.readOnly" 
                      [min]="child.min" 
                      [max]="child.max"
                      [inputPattern]="child.inputPattern" 
                      [capitalize]="child.capitalize"
                      [uppercase]="child.uppercase"
                      [errorMsg]="child.errorMsg ?? ''" 
                      [group]="child.group" 
                      [customStyle]="child.customStyle">
                    </app-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>