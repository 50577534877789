import { getReportReasonDescription } from '@constants/funtions-utils';
import { ProcAuthCancelAPI } from '@interfaces/procs/proc-auth-cancel';
import { TableColumn } from '@interfaces/table.interface';

export const procAuthCancelColumns: TableColumn[] = [
  {
    columnDef: 'InstName',
    header: 'Centro',
    cell: (process: ProcAuthCancelAPI) => `${process.InstName}`,
  },
  {
    columnDef: 'id',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'PatName',
    header: 'Paciente',
    cell: (process: ProcAuthCancelAPI) => `${process.PatName}`,
  },
  {
    columnDef: 'PatNum',
    header: 'Número',
    cell: (process: ProcAuthCancelAPI) => `${process.PatNum}`,
  },
  {
    columnDef: 'CIP',
    header: 'CIP',
    cell: (process: ProcAuthCancelAPI) => `${process.CIP}`,
  },
  {
    columnDef: 'RE',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'RE2',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'Fecha',
    header: 'Fecha',
    cell: (process: ProcAuthCancelAPI) =>
      `${new Date(process.Fecha).toLocaleDateString('es-ES')} `,
  },
  {
    columnDef: 'ReportReasonCode',
    header: 'Notas',
    cell: (process: ProcAuthCancelAPI) =>
      `${getReportReasonDescription(
        process.ReportReasonCode,
        process.BlockReason || null,
        process.ExitusReason,
        false
      )}`,
  },
  {
    columnDef: 'BlockReason',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'BlockDerivatedTo',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'ExitusReason',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'Flag',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'FechaMod',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'InstModId',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'UserMod',
    header: '',
    cell: () => ``,
  },
  {
    columnDef: 'OpId',
    header: '',
    cell: () => ``,
  },
];

export const processAuthCancelDataRender = {
  dataRenderReport: {
    rpp: '/COROTA/DailyChgList',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};
