<div class="re-issues"
    [ngClass]="{ 'disabled': disabled }"
>
    <div class="issue_icon big"
        matTooltip="RE no activada/caducada"
        [ngClass]="{ filled: flags[0] }"
        (click)="onFlagClick(0)"
    > 1+2
    </div>
    <div class="issue_icon"
        matTooltip="Dosis insuficiente"
        [ngClass]="{ filled: flags[2] }"
        (click)="onFlagClick(2)"
    > 3
    </div>
    <div class="issue_icon"
        matTooltip="No congruente"
        [ngClass]="{ filled: flags[3] }"
        (click)="onFlagClick(3)"
    > 4
    </div>
    <div class="issue_icon"
        matTooltip="No en RE"
        [ngClass]="{ filled: flags[4] }"
        (click)="onFlagClick(4)"
    > 5
    </div>
    <div class="issue_icon"
        matTooltip="Falta validación"
        [ngClass]="{ filled: flags[5] }"
        (click)="onFlagClick(5)"
    > 6
    </div>
    <div class="issue_icon"
        matTooltip="Bloqueada por papel"
        [ngClass]="{ filled: flags[6] }"
        (click)="onFlagClick(6)"
    > 7
    </div>
    <div class="issue_icon big"
        matTooltip="PRM"
        [ngClass]="{ filled: flags[7] }"
        (click)="onFlagClick(7)"
    > PRM
    </div>
        
        
        
        
        
        
        
</div>