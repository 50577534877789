<!-- Users card mode -->
<div class="list card-mode">
    <app-card 
        *ngFor="let user of currentData" 
        [title]="user.nam" 
        [id]="user.id" 
        [img]="user.imagePath" 
        [actions]="actions"
        [colorHeader]="'default'" 
        [route]="'/global-eye/admin/users'"
        [defaultImg]="user.gnd == 'M' ? '/assets/pics/male_nurse.svg' : '/assets/pics/female_nurse.svg'"
        (deleteButtonCLicked)="handleDelete($event)"
    >
        <div slot="content" class="content">
            <div class="uid">{{user.uid}}</div>
            <div class="roles">
                <ng-container *ngIf="!isPharma() || !isValidPharmaRole(user.roles)">
                    <ng-container *ngFor="let role of user.roles | slice:0:2">
                        <div class="role-label admin" *ngIf="role === UserRole.Admin">
                            Admin
                        </div>
                        <div class="role-label other" *ngIf="role !== UserRole.Admin">
                            {{UserRole[role]}}
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isPharma() && isValidPharmaRole(user.roles)">
                    <div class="role-label other">
                        {{getUserRole(user.roles)}}
                    </div>
                </ng-container>
            </div>
            <div class="date">
                {{user.exdt.toLocaleDateString()}}
            </div>
        </div>
    </app-card>
</div>


