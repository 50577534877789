<div class="branch" *ngFor="let item of dynamicData">
  <div class="item">
    <button 
      mat-icon-button 
      [attr.aria-label]="'Toggle ' + item.title" 
      [disabled]="!item.items || item.blocked"
      (click)="toggleItem(item)">
      <mat-icon class="mat-icon-rtl-mirror" *ngIf="item.items">
        {{ item.isExpanded ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>

    <mat-checkbox 
      class="label" 
      *ngIf="item.title !== ''"
      [checked]="item.checkStatus === 2"
      [indeterminate]="item.checkStatus === 1"
      [disabled]="item.blocked"
      [ngClass]="{'blocked': item.blocked}"
      (change)="toggleItemCheck($event, item)">
      {{ item.title }}
    </mat-checkbox>
  </div>

  <div class="childs" *ngIf="item.isExpanded && item.items && item.items[0].id !== ''">
    <app-tree 
      [dynamicData]="item.items" 
      [parentCheckStatus]="item.checkStatus" 
      (requestData)="generateRequestData($event, item)"
      (onItemChecked)="checkItemCheckStatus(item)">
    </app-tree>
  </div>

  <div class="loading-childs" *ngIf="item.isExpanded && item.items && item.items[0].id == ''">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>