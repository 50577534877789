import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { formsConfig, tabs } from '@constants/institutions-forms.config';
import { TabInterface } from '@interfaces/dynamic-form.interface';
import {
    FormInterface,
    InstitutionForm,
} from '@interfaces/institution/institution-edit.interface';
import { InstitutionEditEmpty, InstitutionEditForm } from '@interfaces/institution/institution.interface';
import { FormService } from '@services/form.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { Subscription, skipWhile, take } from 'rxjs';
import { LoadingService } from '@services/loading.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { RoleManagerService } from '@services/role-manager.service';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { MainSection } from '@json/src/app/enums/section';
import { CallsService } from '@services/api/calls.service';
import { FormDetailAction } from '@interfaces/form/form-detail';
import { ModalService } from '@services/modal.service';

@Component({
    selector: 'app-institution-edit',
    templateUrl: './institution-edit.component.html',
    styleUrls: ['./institution-edit.component.scss'],
})
export class InstitutionEditComponent implements OnInit, OnDestroy {
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    selectedTab = 0;

    MainSection = MainSection;

    prescriptionEdit: boolean = true
    public institutionId: string | null;
    public isEdit: boolean = true;
    public forms: FormInterface = {};
    public tabs: TabInterface[] = tabs;
    private formsConfig = formsConfig;
    public tabQueryParam: string | null;
    private subs: Subscription[] = [];
    public institutionInfo: InstitutionEditForm;
    public selectPrefixs: any;
    public excludedMedicines: SelectOption[];
    public notReplaceableMedicines: SelectOption[];
    public formReady: boolean = false;
    public formChanged: boolean = false;
    formSubscription: Subscription[] = [];
    institutionData: InstitutionForm | undefined;

    raIgnoreMedTransl: any;
    raStop: any;

    public collapsedProfile: boolean = false;

    currentTab: number = 0;

    private newInstitutionAditionalActions?: FormDetailAction[] = [
        {
            id: '4',
            name: 'CANCELAR FICHA',
            iconName: 'close',
            callback: () => { this.cancelNew() },
            customClass: ['cancel']
        },
    ];

    public onInstitutionId: (instId: string | null) => void;

    constructor(
        protected route: ActivatedRoute,
        protected institutionService: InstitutionsService,
        protected formService: FormService,
        protected loadingService: LoadingService,
        protected location: Location,
        protected roleManager: RoleManagerService, 
        private calls: CallsService,
        private modalService: ModalService,
        private router: Router,
    ) {
        this.route.queryParamMap.subscribe((data) => {
            const index = Number(data.get("tab"));
            if (!isNaN(index) && index != undefined) {
                this.selectedTab = index;
                this.currentTab = index;
            }
        });
    }
    ngOnInit(): void {
        this.formReady = false;
        this.formChanged = false;
        this.loadingService.start('Cargando Formulario...');
        this.getInstitutionId();
        this.initForms();
        this.institutionService.clearStoredData();
        this.getInstitutionInfo();
    }
    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
        this.formSubscription.forEach((f) => f.unsubscribe());
    }

    getAditionalActions() {
        if ( !this.institutionId ) {
            return this.newInstitutionAditionalActions;
        } else {
            return [];
        }
    }

    getInstitutionId(): void {
        this.subs.push(
            this.route.paramMap.subscribe((params: ParamMap) => {
                this.institutionId = params.get('id');

                if (this.onInstitutionId) this.onInstitutionId(this.institutionId);

                this.isEdit = !!this.institutionId;
            })
        );
    }
    initForms(): void {
        this.tabs.forEach((tab) => {
            const formName = tab.form;
            const result = this.formService.buildForm(this.formsConfig[formName]);
            this.forms[formName] = {
                form: result[0],
                schema: result[1],
            };
        });

        if ( !this.roleManager.isPharma() ) {
            this.forms['re'].schema[0].visible = false;
            this.forms['re'].schema[1].visible = false;
            this.forms['re'].schema[2].visible = false;
            !!this.forms['re'].schema[3].childs ? this.forms['re'].schema[3].childs[0].visible = false : null;
            this.forms['re'].schema[4].visible = false;
            this.forms['re'].schema[5].visible = false;
        }
    }

    setListeners(): void {
        this.formSubscription.push(
            this.forms['general'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
            })
        );

        this.formSubscription.push(
            this.forms['schedules'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
            })
        );

        this.formSubscription.push(
            this.forms['re'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
            })
        );

        this.formSubscription.push(
            this.forms['pm'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
            })
        );

        this.formSubscription.push(
            this.forms['actus'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
            })
        );
    }
    getInstitutionInfo(): void {
        this.institutionService.institutionEdit
        .pipe(
            skipWhile(data => data instanceof InstitutionEditEmpty), 
            take(1))
        .subscribe((institution) => {
            this.institutionInfo =
                this.institutionService.transformInstitutionEditToForm(institution);
            
            if (
                this.institutionInfo.main.name != ''
            ) {
                this.selectPrefixs = institution.fcprefixs.map((prefix) => {
                    return this.institutionService.transformInstitutionPrefixFarmaticToSelectOptions(
                        prefix
                    );
                });

                this.raIgnoreMedTransl = institution.inst.raIgnoreMedTransl;
                this.raStop = institution.inst.raStop;

                this.excludedMedicines = institution.inst.raStop.map((item) => { return {label: item.MedicineName, value: item.MedicineId} });
                this.notReplaceableMedicines = institution.inst.raIgnoreMedTransl.map((item) => { return {label: item.MedicineName, value: item.MedicineId} });

                this.setInstitutionInfo(this.institutionInfo);
            }
        })

        if (this.institutionId !== null) {
            this.institutionService.getInstitutionEdit(parseInt(this.institutionId));
        } else {
            this.institutionInfo = this.institutionService.transformInstitutionEditToForm(new InstitutionEditEmpty());
            this.setInstitutionInfo(this.institutionInfo);
        }
    }
    setInstitutionInfo(institutions: InstitutionEditForm): void {
        this.setGeneralFormInfo(institutions);
        this.setSchedulesFormInfo(institutions);
        this.setReFormInfo(institutions);
        this.setPmFormInfo(institutions);
        this.setDeparmentsInfo(institutions);
        this.setDocumentsInfo(institutions);
        this.setActusFormInfo(institutions);
        this.setListeners();

        this.formReady = true;
        this.loadingService.stop();
    }
    setDeparmentsInfo(institutionForm: InstitutionEditForm): void {
        setTimeout(() => {
            this.forms['departments'].form.patchValue({
                departments: institutionForm.departments,
            }, { emitEvent: true });

            this.formSubscription.push(
                this.forms['departments'].form.valueChanges.subscribe(() => {
                    this.formChanged = true;
                })
            );
        })  // Fix: list subform value change no triggered

    }
    setGeneralFormInfo(institutionForm: InstitutionEditForm): void {
        this.forms['general'].form.patchValue(institutionForm, { emitEvent: false });
    }

    setDocumentsInfo(institutionForm: InstitutionEditForm): void {
        setTimeout(() => {
            this.forms['documents'].form.patchValue(institutionForm);
        })  // Fix: list subform value change no triggered
    }
    setSchedulesFormInfo(institutionForm: InstitutionEditForm): void {
        this.forms['schedules'].form.patchValue(institutionForm, { emitEvent: false });
    }
    setReFormInfo(institutionForm: InstitutionEditForm): void {
        this.forms['re'].form.patchValue(institutionForm, { emitEvent: false });

        const farmaticChilds = this.forms['re'].schema[6].childs;
        if (farmaticChilds) {
            farmaticChilds[0].options = this.selectPrefixs;
        }

        const automaticReChilds = this.forms['re'].schema[4].childs;

        if (automaticReChilds) {
            automaticReChilds[1].options = this.excludedMedicines;
            automaticReChilds[2].options = this.notReplaceableMedicines;

            // Fill selected
            this.raStop ? automaticReChilds[1].fieldControl.setValue(this.raStop.filter((item: any) => item.Selected).map((item: any) => item.MedicineId )) : null;
            this.raIgnoreMedTransl ? automaticReChilds[2].fieldControl.setValue(this.raIgnoreMedTransl.filter((item: any) => item.Selected).map((item: any) => item.MedicineId )) : null;
        }
    }
    setPmFormInfo(institutionForm: InstitutionEditForm): void {
        this.forms['pm'].form.patchValue(institutionForm, { emitEvent: false });
    }
    setActusFormInfo(institutionForm: InstitutionEditForm): void {
        this.forms['actus'].form.patchValue(institutionForm.actus, { emitEvent: false });
    }

    updateInstitutionEdit(): void {
        if ( !this.formValid() ) {
            return ;    // BREAK EXECUTION
        }

        this.loadingService.start('Guardando cambios...');

        const institution = this.getFormInfo();

        this.institutionService.updateInstitution(
            institution,
            this.institutionId !== null ? +this.institutionId : null,
            this.excludedMedicines || [],
            this.notReplaceableMedicines || []
        );

        this.formReady = false;
    }

    /**
     * Check if the form is valid
     * @param displayErrors show feedback to user
     * @returns if the form is valid
     */
    formValid(displayErrors: boolean = true): boolean {
        let valid = true;

        if ( !this.forms['re'].form.valid ) {
            this.forms['re'].form.get('autovalidation')?.markAllAsTouched();

            if ( this.forms['re'].form.get('autovalidation')?.get('autoValidationOrder')?.value ) {
                if ( !this.forms['re'].form.get('autovalidation')?.get('type')?.valid ) {
                    valid = false;
                    displayErrors ? this.calls.openSnack('El tipo de autovalidación es obligatorio', 'X', 5000) : null;
                } else if ( !this.forms['re'].form.get('autovalidation')?.get('type')?.value && (!this.forms['re'].form.get('autovalidation')?.get('day')?.valid || !this.forms['re'].form.get('autovalidation')?.get('hour')?.valid) ) {
                    valid = false;
                    displayErrors ? this.calls.openSnack('Los campos de día y hora son obligatorios', 'X', 5000) : null;
                } else if ( this.forms['re'].form.get('autovalidation')?.get('type')?.value && !this.forms['re'].form.get('autovalidation')?.get('houts')?.valid ) {
                    valid = false;
                    displayErrors ? this.calls.openSnack('El campo horas es obligatorio', 'X', 5000) : null;
                }
            }
        } else if ( this.forms['pm'].form.value.interval.initialDay !== null && !Number(this.forms['pm'].form.value.interval.intervalDay) ) {
            valid = false;
            displayErrors ? this.calls.openSnack('El campo intervalo es obligatorio', 'X', 5000) : null;
        } else if ( this.forms['pm'].form.value.interval.initialDay !== null && !this.forms['pm'].form.valid ) {
            valid = false;
            displayErrors ? this.calls.openSnack('El campo intervalo debe ser entre 1 y 14 días', 'X', 5000) : null;
        }

        return valid;
    }

    getFormInfo(): InstitutionForm {
        const object: { [key: string]: any } = {};
        this.tabs.forEach((tab) => {
            const formName = tab.form;
            object[formName] = this.forms[formName].form.value;
        });

        const { general, re, schedules, ...otherProperties } = object;
        const res: InstitutionForm = {
            ...general,
            ...re,
            ...schedules.schedule,
            ...otherProperties,
        };
        res.id = this.institutionId ?? '';

        return res;
    }

    navChange = false;
    tabIndexChange(index: number) {
        this.currentTab = index;

        if (this.navChange) {
            this.navChange = false;
            return;
        }
        this.location.go(location.pathname, "tab=" + index);
    }

    cancelNew() {
        this.modalService.openModalCallback(
            () => {
                this.router.navigate(["/global-eye/institutions/cards"])
            },
            '¿Está seguro de volver sin guardar?',
            'Cancelar Ficha'
        );
    }
}
