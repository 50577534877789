<div class="receptions-wrapper">
  <div class="cards" *ngIf="receptionData">
    <div *ngFor="let cardProcess of cardProcesses">
      <app-card-process
        [title]="cardProcess.title"
        [content]="cardProcess.content"
      ></app-card-process>
    </div>
  </div>

  <form [formGroup]="form" (submit)="importReception()">
    <app-form-field
      type="date"
      label="Día de recepción"
      [fieldControl]="form.controls['dayReception'] | asFormControl"
    >
    </app-form-field>

    <app-form-field
      multiple
      type="select"
      label="Seleccione las recepciones"
      [fieldControl]="form.controls['selectReceptions'] | asFormControl"
      [options]="optionsSelectReceptions"
    >
    </app-form-field>

    <button
      mat-button
      color="primary"
      type="submit"
      [disabled]="form.controls['selectReceptions'].value"
    >
      Importar recepción
    </button>
  </form>

  <div *ngIf="cardReceptions" class="cards">
    <div *ngFor="let cardProcess of cardReceptions">
      <app-card-process
        [title]="cardProcess.title"
        [content]="cardProcess.content"
      ></app-card-process>
    </div>
  </div>
</div>
