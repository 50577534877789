<div class="container-fluid" [hidden]="!formReady">
  <div class="profile" *ngIf="formReady">
    <app-form-detail
      [title]="forms['general'].form.value.general.name"
      [typeDefaultImg]="1"
      [subTitle]="forms['general'].form.value.general.surname1"
      [patientData]="patientData?.rawData"
      [formChanged]="formChanged"
      [canSave]="prescriptionEdit == undefined || !prescriptionEdit.showEditForm"
      [canUndo]="prescriptionEdit == undefined || !prescriptionEdit.showEditForm"
      [collapsedLayout]="collapsedProfile"
      [aditionalActions]="getAditionalActions()"
      [hideImageActions]="currentTab !== 0 || !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)"
      [disabledAllActions]="!patientId || !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)"
      (saveChanges)="handleUpdatePatient()"
      (cancelChanges)="cancelChanges()"
      (collapseStatus)="collapsedProfile = $event">
    </app-form-detail>

    <div class="wrong-data" *ngIf="patientIncomplete() && currentTab === 0" [@inOutFade]="{value: true, params: {duration: '100ms'}}">
      <mat-icon color="warn">error_outline</mat-icon>
      <div class="text">
        Este paciente tiene algún dato incompleto o incorrecto. Revise sus datos por favor.
      </div>
    </div>

    <div class="color-legend" *ngIf="currentTab === 5 && !prescriptionEdit?.showEditForm">
      <app-color-legend 
        class="legend" 
        [items]="colorLegendItems" 
        [legendPosition]="'top'"
      >
      </app-color-legend>
    </div>
  </div>
  <div class="content" [ngClass]="{'full-width': collapsedProfile}">
    <mat-tab-group
      style="height: 100%;"
      animationDuration="0ms"
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="tabIndexChange($event)"
    >
      <ng-container *ngFor="let tab of tabs; let i = index">
        <mat-tab
          [disabled]="
            ((!isEdit && tab.form !== 'general') || 
            (formChanged && !tabsChanged.includes(tab.form)) ||
            (prescriptionInited() && currentTab !== i)) &&
            !(tab?.form === 'general' && forms[tab.form].form.invalid)"
          *ngIf="tab.hidden == undefined || tab.hidden().length > 0">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="forms[tab.form] && forms[tab.form].form.invalid && forms[tab.form].form.touched"
              color="warn"
              class="example-tab-icon me-2">
              info
            </mat-icon>
            <span (click)="checkCanNavigate()">{{ tab.label }}</span>
          </ng-template>
          <ng-container [ngSwitch]="tab.form">
            <!-- Prescriptions -->
            <ng-container *ngSwitchCase="'prescriptions'">
              <app-prescriptions-table
                *ngIf="forms[tab.form]"
                [patientId]="patientId"
                [patientInstitutionId]="patientInstitutionId"
                [patiendDefaultDep]="patientDefaultDep"
                [patientLink]="patientLink"
                [exportCf]="exportCf"
                [allergies]="allergies"
                [forms]="forms"></app-prescriptions-table>
            </ng-container>

            <!-- General -->
            <ng-container *ngSwitchCase="'general'">
              <app-patient-edit-general
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                [cipaPattern]="patientCipaPattern"
                (save)="saveRe()"
              >
              </app-patient-edit-general>
            </ng-container>

            <!-- Clinical notes -->
            <ng-container *ngSwitchCase="'clinicNotes'">
              <app-patient-edit-notes-clinical
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                (save)="saveRe()"
              >
              </app-patient-edit-notes-clinical>
            </ng-container>

            <!-- locks -->
            <ng-container *ngSwitchCase="'blocks'">
              <app-patient-edit-locks
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                (save)="saveRe()"
              >
              </app-patient-edit-locks>
            </ng-container>

            <!-- Diagnosis -->
            <ng-container *ngSwitchCase="'diagnostic'">
              <app-patient-edit-diagnosis
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                (save)="saveRe()"
              >
              </app-patient-edit-diagnosis>
            </ng-container>

            <!-- Daily pattern -->
            <ng-container *ngSwitchCase="'dailyPattern'">
              <app-patient-edit-daily-pattern
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                (save)="saveRe()"
              >
              </app-patient-edit-daily-pattern>
            </ng-container>


          <!--Docuements -->
          <ng-container *ngSwitchCase="'documents'">
            <app-patient-edit-documents
              *ngIf="forms[tab.form]"
              [schema]="forms[tab.form].schema"
              [patient]="patientData"
              [formChanged]="formChanged"
              (save)="saveRe()"
            >
            </app-patient-edit-documents>
          </ng-container>

            <ng-container *ngSwitchDefault>
              <app-dynamic-form
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [patient]="patientData"
                [formChanged]="formChanged"
                (save)="saveRe()">
              </app-dynamic-form>
            </ng-container>
          </ng-container>
          <!-- TODO Traer este dato de back https://trello.com/c/DDSIeBbd-->
          <!-- <span class="colegiado" *ngIf="tab.form == 'general'">BENJAMIN SANCHEZ SOLER CON Nº COLEGIADO 10396
            COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD</span> -->
        </mat-tab>
      </ng-container>

    </mat-tab-group>
  </div>
</div>
