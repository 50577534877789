<div class="container-fluid" >
    <div class="lateral">
        <div class="option-button" >
            <button mat-button class="action first"
                (click)="onPreviewClick()"
                title="Vista previa" 
                [disabled]="disabled">
                <mat-icon>remove_red_eye</mat-icon>
                <span>VISTA PREVIA</span>
            </button>

            <button mat-button class="action"
                (click)="onPrintClick()"
                title="Imprimir" 
                [disabled]="disabled">
                <mat-icon>print</mat-icon>
                <span>IMPRIMIR</span>
            </button>

            <button mat-button class="action"
                (click)="onPdfClick()"
                title="PDF" 
                [disabled]="disabled">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>EXPORTAR PDF</span>
            </button>

            <button mat-button class="action"
                (click)="onExcelClick()"
                title="Excel" 
                [disabled]="disabled">
                <mat-icon>insert_drive_file</mat-icon>
                <span>EXPORTAR EXCEL</span>
            </button>
        </div>

        <div class="nav-button">
            <div class="title">Otros reportes</div>
            
            <div class="box">
                <ng-container *ngFor="let link of links">
                    <mat-list-item class="action"
                        *ngIf="testPerm(link)"
                        [matTooltip]="link.label"
                        title=""
                        routerLinkActive="is-active"
                        [routerLink]="'../../' + link.link">
                        <span>{{link.label}}</span>
                    </mat-list-item>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="content-box">
            <div class="title">
                {{ title }}
            </div>
            <ng-content select="[slot=content]"></ng-content>
        </div>
    </div>
  </div>
  