import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    FormItemSchemaInterface,
    ConditionalFieldConfigInterface,
} from '@interfaces/dynamic-form.interface';
import { PatientFormData } from '@interfaces/patient/patient.interface';
import { SortService } from '@services/sort.service';
import { TableFormComponent } from '@shared/table-form/table-form.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnDestroy {
    @Input() schema: FormItemSchemaInterface[] = [];
    @ViewChildren("rows") rows = new QueryList<ElementRef<HTMLDivElement>>;
    @Input() patient: PatientFormData | void;
    @Input() formChanged: boolean;
    @Input() customStyle?: any;
    @Output() save = new EventEmitter<void>();

    public visibility: { visible: boolean }[] = [];

    protected subs: Subscription[] = [];

    constructor(
        protected sortService: SortService,
        protected cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.visibility = this.schema.map((item) => { return { visible: item.visible === false ? false : true } });

        this.schema.forEach((schema, index) => {
            this.fieldVisible(schema, this.visibility[index]);
        });
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    fieldVisible(
        schema: FormItemSchemaInterface,
        visibility: any
    ): void {
        visibility.visible === undefined ? visibility.visible = true : null;

        const config = schema.conditionalConfig;
        if (config) {
            if (config.mode == 'hide') {
                visibility.visible = false;
            }

            const controls = config.fields.map((field) => {
                return this.findFieldControl(field.name, schema.fieldControl);
            });

            controls.forEach((control) => {
                if (control) {
                    this.checkFieldVisible(controls, config, schema, visibility)

                    this.subs.push(
                        control.valueChanges.subscribe(() => {
                            this.checkFieldVisible(controls, config, schema, visibility)
                        })
                    );
                }
            });
        }
    }

    checkFieldVisible(controls: (AbstractControl | false)[], config: ConditionalFieldConfigInterface, schema: FormItemSchemaInterface, visibility: any) {
        const result = this.checkVisibility(controls, config);
        if (config.mode == 'hide') {
            // if (result) {
            //     schema.fieldControl.enable({
            //         emitEvent: false
            //     });
            // } else {
            //     schema.fieldControl.reset(undefined, {
            //         emitEvent: false
            //     });
            //     schema.fieldControl.disable({
            //         emitEvent: false
            //     });
            // }

            visibility.visible = result;
        }
        else if (config.mode == 'required') {
            if (result) {
                if (!schema.fieldControl.hasValidator(Validators.required))
                    schema.fieldControl.addValidators(Validators.required);
            }
            else {
                schema.fieldControl.removeValidators(Validators.required);
            }
            schema.fieldControl.updateValueAndValidity();
        }
    }

    findFieldControl(
        controlName: string,
        control: FormControl | FormGroup | FormArray
    ): AbstractControl | false {
        let foundControlName;
        //TODO: Complicarse la vida para que también busque enlos campos hijos de los formgroup que no sean él mismo

        if (control instanceof FormGroup && control.controls) {
            const controlNames = Object.keys(control.controls);
            foundControlName = controlNames.find((c) => c === controlName);

            if (foundControlName) {
                // Si en mis controles no está miro los del padre
                return control.controls[foundControlName];
            }
            return control.parent
                ? this.findFieldControl(controlName, control.parent)
                : false;
        }

        if (control instanceof FormControl || control instanceof FormArray) {
            // Si soy un control normal miro llos del padre
            return control.parent
                ? this.findFieldControl(controlName, control.parent)
                : false;
        }

        return false;
    }

    checkVisibility(
        controls: (AbstractControl | false)[],
        config: ConditionalFieldConfigInterface
    ): boolean {
        const { mode, match, filter, fields } = config;
        let result = false;

        const evaluations = controls.map((control, index) => {
            return control
                ? this.sortService.compare(control.value, fields[index].value, match)
                : true;
        });

        switch (filter) {
            case 'every':
                result = evaluations.every((evaluation) => evaluation === true);
                break;
            case 'some':
                result = evaluations.some((evaluation) => evaluation === true);
                break;
        }

        if (mode == 'hide' || mode == 'required') {
            return result;
        }

        return false
    }

    saveRe() {
        this.save.emit();
    }
}
