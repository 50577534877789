import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

import { inOutFade } from '@json/src/app/animations/general';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';

@Component({
  selector: 'app-patient-edit-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss'],
  animations: [ inOutFade ]
})
export class DiagnosisComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  @ViewChild('clinicCourse') clinicCourse: ElementRef;
  Utils = Utils;
  Permission = Permission;
  PermissionType = PermissionType;

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addNewItem($event: any, section: any) {
    $event.stopPropagation();

    let element: ElementRef;

    switch( section.childs[0].customType ) {
      case 'clinic-course': element = this.clinicCourse; break;
    }

    // All this elements extends from ArraySubformComponent so we
    // can ignore ts error here
    // @ts-ignore
    element?.addOne();
  }

  openCalendar($event: any, section: any) {
    $event.stopPropagation();

    let element: ElementRef;

    switch( section.childs[0].customType ) {
      case 'clinic-course': element = this.clinicCourse; break;
    }

    // All this elements extends from ArraySubformComponent so we
    // can ignore ts error here
    // @ts-ignore
    element.showCalendar = !element.showCalendar;
  }
}
