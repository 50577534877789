import { CardAction } from '@interfaces/card-actions';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { Utils } from '../../Utils';
import { Permission, PermissionType } from '../../enums/PermissionType';

export const routeMedicines: string = '/global-eye/medicines';

export const actionsMedicine: CardAction[] = [
  {
    id: 'edit',
    name: Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE) ? 'Editar' : 'Ver',
    iconName: Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE) ? 'edit' : 'visibility',
    routeLink: '/edit',
  },
  {
    id: 'epigraph',
    name: 'Epigrafes',
    iconName: 'more_vert',
    routeLink: '/edit',
    queryParam: { tab: 2 }
  },
  {
    id: 'interactions',
    name: 'Interacciones',
    iconName: 'sync_alt',
    routeLink: '/edit',
    queryParam: { tab: 3 },
  },
  {
    id: 'delete',
    name: 'Borrar',
    iconName: 'delete',
    hasPermission: () => {
        const perms = Utils.hasPermission(Permission.VADEMECUM)();
        if (!perms.includes(PermissionType.WRITE)) return [];
        return perms;
    }
  },
];

export const medicineFilterSelect: FilterSelect[] = [
  {
    name: 'General',
    filter: [
      {
        id: 'emblistable',
        value: 'Emblistable',
      },
      {
        id: 'no-emblistable',
        value: 'No emblistable',
      },
      {
        id: 'cobert',
        value: 'Cobertura sanitaria',
      },
      {
        id: 'no-cobert',
        value: 'Pago',
      },
      {
        id: 'hospital-use',
        value: 'Hospitalario',
      },
      {
        id: 'mec',
        value: 'MEC',
      },
      {
        id: 'ra',
        value: 'RA',
      },
      {
        id: 'no-inventary',
        value: 'Sin inventario',
      },
    ]
  },
  {
    name: 'IEF',
    filter: [
      {
        id: 'aine',
        value: 'AINE',
      },
      {
        id: 'alzheimer',
        value: 'Alzheimer',
      },
      {
        id: 'nervans',
        value: 'Ansiolíticos',
      },
      {
        id: 'antibio',
        value: 'Antibiótico',
      },
      {
        id: 'anticoag',
        value: 'Anticoagulante',
      },
      {
        id: 'nervadp',
        value: 'Antidepresivos',
      },
      {
        id: 'nervaps',
        value: 'Antipsicóticos',
      },
      {
        id: 'diabetes',
        value: 'Diabetes',
      },
      {
        id: 'nerveutatc',
        value: 'Eutimizantes',
      },
      {
        id: 'ibp',
        value: 'IBP',
      },
      {
        id: 'opioid',
        value: 'Opioides',
      },
      {
        id: 'others',
        value: 'Ninguno',
      }
    ],
  },
  {
    name: 'Peligroso',
    filter: [
      {
        id: 'dangerous-group-1',
        value: 'Grupo 1',
      },
      {
        id: 'dangerous-group-2',
        value: 'Grupo 2',
      },
      {
        id: 'dangerous-group-3',
        value: 'Grupo 3',
      },
    ],
  }
];

export const medicineFilterSelected: FilterOption[] = [];

export const medicineFilters: FilterOption[] = [
  {
    id: 'cobert',
    value: 'Cobertura sanitaria',
  },
  {
    id: 'alzheimer',
    value: 'Alzheimer',
  },
  {
    id: 'no-emblistable',
    value: 'No emblistable',
  },
  {
    id: 'unitdose',
    value: 'Unidosis',
  },
  {
    id: 'ra',
    value: 'RA',
  },
  {
    id: 'mec',
    value: 'MEC',
  },
  {
    id: 'ro',
    value: 'RO',
  },
  {
    id: 'none',
    value: 'Ninguno anterior',
  },
  {
    id: 'obsolete',
    value: 'Obsoleto',
  },
  {
    id: 'no-inventary',
    value: 'Sin inventario',
  },
  {
    id: 'no-prescription',
    value: 'No prescribir',
  },
  {
    id: 'hospital-use',
    value: 'Hospitalario',
  },
];
