import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-date-info',
    templateUrl: './date-info.component.html',
    styleUrls: ['./date-info.component.scss']
})
export class DateInfoComponent {

    @Input() label: string;
    @Input() hideLabel?: boolean;
    @Input() fieldControl: FormControl;
    @Input() readOnly?: () => boolean = () => false;

}
