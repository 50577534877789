import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataObsolescenceMeds } from '@constants/reports/report-obsolescense-meds';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { Subscription } from 'rxjs';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-report-obsolescence-meds',
  templateUrl: './report-obsolescence-meds.component.html',
  styleUrls: ['./report-obsolescence-meds.component.scss'],
})
export class ReportObsolescenceMedsComponent implements OnInit{
  public reportDataObsolescenceMeds = reportDataObsolescenceMeds;
  public subs: Subscription[] = [];
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;

  public selectOptionsInsitutions: SelectOption[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService,
  ) {
    var date = new Date();

    this.form = this.formBuilder.group({
      startDate: [
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
        Validators.required,
      ],
      endDate: [new Date(), Validators.required],
      showOnlyActives: [true],
      showMissingCF: [true],
      showObsoleteCF: [true],
      showNoAction: [false],
      // selectedInstitution: ['', Validators.required],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
  }

  getDataForTheSelects(): void {
      this.institutionService.getInstitutions().then(() => {
        this.selectOptionsInsitutions =
          Object.assign([], this.institutionService.institutionList.getValue());
           // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })
        if (this.selectOptionsInsitutions) {
          const hasEmptyValue = this.selectOptionsInsitutions.some(
            (item) => item.value === ''
          );
          if (hasEmptyValue) {
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
              (item) => item.value !== ''
            );
          }
        }
      });
  }

  handlePreviewClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction,
        true
      );

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataObsolescenceMeds
      );
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack('Debes seleccionar un día de generación de plan');
      // }
    }
  }

  getFormData(): {
    startDate: string;
    endDate: string;
    showOnlyActives: string;
    showMissingCF: string;
    showObsoleteCF: string;
    showNoAction: string;
  } {
    const startDate = this.dateService.formatDateToString(
      this.form.get('startDate')?.value
    );
    const endDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('endDate')?.value).endOf('day').toDate())?.toISOString().split(".")[0];

    const { showOnlyActives, showMissingCF, showObsoleteCF, showNoAction } =
      this.form.getRawValue();

    return {
      startDate,
      // @ts-ignore
      endDate,
      showOnlyActives,
      showMissingCF,
      showObsoleteCF,
      showNoAction,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction
      );
      this.reportService.handlePrintClick(this.reportDataObsolescenceMeds);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack('Debes seleccionar un día de generación de plan');
      // }
    }
  }

  handlePdfClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction,
      } = this.getFormData();
      this.setInfoRP(
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction
      );
      this.reportService.handlePdfClick(this.reportDataObsolescenceMeds);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack('Debes seleccionar un día de generación de plan');
      // }
    }
  }

  handleExcelClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        showOnlyActives,
        showMissingCF,
        showObsoleteCF,
        showNoAction
      );
      this.reportService.handleExcelClick(this.reportDataObsolescenceMeds);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack('Debes seleccionar un día de generación de plan');
      // }
    }
  }

  setInfoRP(
    startDate: string,
    endDate: string,
    showOnlyActives: string,
    showMissingCF: string,
    showObsoleteCF: string,
    showNoAction: string,
    isPreview: boolean = false
  ): void {

    let  currentInstitution;
    const dataForm = this.form.getRawValue()
    //@ts-ignore
    // currentInstitution = dataForm.selectedInstitution.id


    const rp = JSON.stringify({
      // InstitutionId: String(currentInstitution),
      LocaleId: '3082',
      ShowOnlyActives: showOnlyActives ?? false,
      ShowMissingCF: showMissingCF ?? false,
      ShowObsoleteCF: showObsoleteCF ?? false,
      ShowNoAction: showNoAction ?? false,
      StartDate: startDate,
      EndDate: endDate,
    });

    isPreview ? this.reportDataObsolescenceMeds.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    this.reportDataObsolescenceMeds.dataDownloadPDF.dn = this.reportDataObsolescenceMeds.dataRenderReport.dn = `Obsolescencia_${moment(startDate).format('YYYYMMDD')}_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataObsolescenceMeds.dataDownloadExcel.dn = `Obsolescencia_${moment(startDate).format('YYYYMMDD')}_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataObsolescenceMeds.data.rp = rp;
    this.reportDataObsolescenceMeds.dataDownloadPDF.rp = rp;
    this.reportDataObsolescenceMeds.dataDownloadExcel.rp = rp;
    this.reportDataObsolescenceMeds.dataRenderReport.rp = rp;
  }
}
