import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormDetailAction } from '@interfaces/form/form-detail';
import { PatientDataAPIInterface } from '@interfaces/patient/patient.interface';
import { environment } from '@json/src/environments/environment';
import { ImageService } from '@services/image/image.service';
import { ModalService } from '@services/modal.service';
import { ImageModalComponent } from '@shared/image-modal/image-modal.component';
import { User } from '@interfaces/user/user.interface';
import { trigger, transition, animate, style } from '@angular/animations';
import { CallsService } from '@services/api/calls.service';
import { MainSection } from '@json/src/app/enums/section';
import { ImageViewerComponent } from '@shared/image-viewer/image-viewer.component';

@Component({
    selector: 'app-form-detail',
    templateUrl: './form-detail.component.html',
    styleUrls: ['./form-detail.component.scss'],
    animations: [
        trigger('inOutFade', [
            transition(':enter', [
               style({opacity: '0'}),
               animate('300ms 300ms', style({opacity: '1'}))
            ]),
            transition(':leave', [
               animate('0ms', style({opacity: '0'}))
            ])
        ])
    ]
})
export class FormDetailComponent implements OnInit {

    @Input() title?: string | undefined;
    @Input() subTitle?: string | undefined;
    @Input() image?: File;
    @Input() ariaTitle?: string;
    @Input() ariaSubtitle?: string;
    @Input() actions?: FormDetailAction[];
    @Input() aditionalActions?: FormDetailAction[] = [];
    @Input() disabledAllActions?: boolean = false;
    @Input() patientData?: PatientDataAPIInterface;
    @Input() userData?: User;
    @Input() canSave: boolean = false;
    @Input() canUndo: boolean = false;
    @Input() formChanged: boolean = false;
    @Input() collapsedLayout: boolean = false;
    @Input() hideImageActions: boolean = false;
    @Input() typeDefaultImg: number = 0;
    @Input() section: MainSection = MainSection.Patient;
    @Input() isVademecum: boolean = false;
    @Input() avatarZoomable: boolean = false;

    @Output() collapseStatus: EventEmitter<boolean> = new EventEmitter();
    @Output() saveChanges: EventEmitter<void> = new EventEmitter();
    @Output() cancelChanges: EventEmitter<void> = new EventEmitter();

    MainSection = MainSection;

    paramId: string;
    @Input() type: string;
    private formatedID: string;

    public selectedImg: string;
    public defaultImg: string = '';
    // public defaultImg: string = encodeURI(`https://ui-avatars.com/api/?name=N+R`);

    public src: string;
    public defaultActions: FormDetailAction[] = [
        {
            id: '0',
            name: 'SUBIR IMAGEN',
            iconName: 'upload',
            callback: () => this.openImageModal(),
        },
        {
            id: '1',
            name: 'BORRAR IMAGEN',
            iconName: 'delete',
            callback: () => this.deleteImageModal(),
        },
        {
            id: '2',
            name: 'GUARDAR FICHA',
            iconName: 'save',
            callback: () => this.saveChanges.emit(),
        },
        {
            id: '3',
            name: 'DESCARTAR',
            iconName: 'undo',
            callback: () => this.cancelChanges.emit(),
        },
    ];
   
    initials: string = '';

    constructor(
        private dialog: MatDialog,
        private imageService: ImageService,
        private route: ActivatedRoute,
        private router: Router,
        private modal: ModalService,
        private calls: CallsService,
    ) { }

    ngOnInit(): void {

        this.route.params.subscribe(
            (params) => (this.paramId = params['id']?.toString())
        );
        if (this.type == undefined) {
            this.type = this.imageService.getEntityTarget(this.router.url);
        }
        
        this.getImage();

        switch( this.section ) {
            case MainSection.Patient:
                this.generateInitials(this.title, this.subTitle);
                break;
            case MainSection.Vademecum:
            case MainSection.Institution:
                this.generateInitials(this.title, undefined);
                break;
        }
    }

    getImage(): void {
        if (this.paramId) {
            this.formatedID = this.imageService.formatNumberImage(
                Number(this.paramId)
            );
            if (this.paramId){
                if ( this.typeDefaultImg === 1) { // PATIENT
                    this.defaultImg = this.patientData?.pat.gnd == 'M' ? '/assets/pics/male.png' : '/assets/pics/female.png';
                }  else if ( this.typeDefaultImg === 2) { // INSITITUTION
                    this.defaultImg = 'assets/pics/medical_residencia.svg';
                } else if ( this.typeDefaultImg === 3) { // MEDICINES
                    this.defaultImg = '/assets/images/medical_pills2.svg';
                } else{
                    this.defaultImg = encodeURI(`https://ui-avatars.com/api/?name=${this.title + " " + this.subTitle?.split(' ')[0]}`);
                } 
            }

            const imageUrl = `${environment.urlBack}resources/assets/pics/${this.type}/e00/${this.formatedID}.jpg`;
            this.loadImage(imageUrl);
        }
    }

    loadImage(imageUrl: string): void {
        const img = new Image();
        const timestamp = new Date().getTime();
        const url = `${imageUrl}?timestamp=${timestamp}`;

        img.onload = () => {
            this.src = url;
        };

        img.onerror = () => {
            // If there is an error, set the URL of the alternative image
            this.src = this.defaultImg;
            this.avatarZoomable = false;
        };

        img.src = url;
    }

    reloadComponent() {
        this.router
            .navigateByUrl('/refresh', { skipLocationChange: true })
            .then(() => {
                this.router.navigate([this.router.url]);
            });
    }

    reloadPage(): void {
        const currentUrl = this.router.url;
        const navigationExtras: NavigationExtras = {
            skipLocationChange: true,
        };
        this.router.navigateByUrl(currentUrl, navigationExtras);
    }

    openImageModal(): void {
        const dialogRef = this.dialog.open(ImageModalComponent);
        dialogRef.afterClosed().subscribe((result: File) => {
            if (result) {
                this.image = result;
                this.imageService
                    .uploadEntityPic(this.image, this.paramId, this.type)
                    .subscribe((result) => {
                        if (result) {
                            this.getImage();
                            this.calls.openSnack(
                                'Imagen subida correctamente',
                                'Ok'
                            );
                        }
                    });
            }
        });
    }

    deleteImageModal(): void {
        this.modal.openModalCallback(
            () => this.deleteImage(),
            '¿Está seguro de que desea eliminar la imagen?',
            'Eliminar imagen'
        );
    }

    deleteImage(): void {
        const emptyBlob = new Blob([], { type: 'image/jpeg' });
        this.imageService
            .uploadEntityPic(emptyBlob, this.paramId, this.type)
            .subscribe((result) => {
                if (result) {
                    this.getImage();
                    this.calls.openSnack(
                        'Imagen borrada correctamente',
                        'Ok'
                    );
                }
            });
    }

    capitalize(label?: string) {
        if (label == undefined) return '';
        let words = label.split(' ');
        words.forEach((word, i) => {
            words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return words.join(' ');
    }

    generateInitials(t1: string | undefined, t2?: string | undefined) {
        if ( t1 ) {
          const text = t1 + (t2 ? ` ${t2}` : '');

          // @ts-ignore
          this.initials = (text.match(/(\b\S)?/g).join('').split('').join('.') + '.').slice(0, 6);
        }
      }

    zoomAvatar($event: any) {
        $event.stopPropagation();

        const dialogRef = this.dialog.open(ImageViewerComponent, {
            width: '90vw',
            height: '90vh',
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
                thumbnail: this.src,
                full: this.src.substring(0, this.src.lastIndexOf('.')) + '_full' + this.src.substring(this.src.lastIndexOf('.'))
            },
        });
    }
}
