import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { showFilters } from '@constants/reports/report-change-medications';
import {
  reportDataForecast,
  reportDataForecastReportTry,
  reportDataForecastReportPatientTry
} from '@constants/reports/report-forecast';
import { schedulesOptions } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
  PatientPharma,
  Report,
  ReportForecastDates,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { ReportsService } from '@services/reports/reports.service';
import moment, { Moment } from 'moment';
import { linkData } from '@constants/farmatic';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'app-report-forecast',
  templateUrl: './report-forecast.component.html',
  styleUrls: ['./report-forecast.component.scss'],
})
export class ReportForecastComponent {
  public reportDataForecast = reportDataForecast;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsPatientsWithoutFilter: PatientPharma[];
  public selectOptionsInsitutions: SelectOption[];
  public filtersMedChanges = showFilters;
  public schedulesOptions = schedulesOptions;

  public errorMUTUAS = 'Debes elegir al menos un centro';

  public checkboxsForecastDates: ReportForecastDates = {
    lastDates: [],
    nextDate: '',
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService,
    private loadingService: LoadingService,
    private modal: ModalService,
    private authService: AuthService
  ) {
    this.selectOptionsInsitutions = [];
    var date = new Date();
    this.form = this.formBuilder.group({
      specificDay: [''],
      selectedInstitution: ['', Validators.required],
      reportType: ['1'],
      checkboxIndividualPatient: [false],
      checkboxUnlockedInterval: [false],
      checkboxNoInventary: [false],
      checkboxOnlySelfManaged: [false],
      checkboxsDateOnlyTray: [''],
      startDate: [
        new Date(),
        Validators.required,
      ],
      endDate: [new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7), Validators.required],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setCurrentDate();
    this.getForecastHistoryDate();
  }

  getForecastHistoryDate() {
    this.loadingService.start('Cargando intervalos..');
    this.calls.getLastForecastTrayHistoryDates().subscribe((res) => {
      if (res) {
        const { lastDates, nextDate } = res.payload;

        this.checkboxsForecastDates = {
          lastDates: lastDates,
          nextDate: nextDate,
        };

        const lastDatesLength = this.checkboxsForecastDates.lastDates.length;
        const lastDateValue =
          this.checkboxsForecastDates.lastDates[lastDatesLength - 1];
        this.form.get('checkboxsDateOnlyTray')?.patchValue(lastDateValue);

        this.loadingService.stop();
      }
    });
  }

  setCurrentDate(): void {
    const currentDate = new Date();

    this.form.patchValue({ specificDay: currentDate });
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
      this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
      // this.selectOptionsInsitutions.map((a) =>{
      //     a.label = a.label.toUpperCase();
      // })
      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }
      }

      this.form.controls['selectedInstitution'].setValue(
        [this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value]
      )
    });
  }

  handlePreviewClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray,
        true
      );

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataForecast
      );
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  getFormData(): {
    startDate: string;
    endDate: string;
    selectedInstitution: string;
    reportType: string;
    checkboxIndividualPatient: string;
    checkboxUnlockedInterval: string;
    checkboxNoInventary: string;
    checkboxOnlySelfManaged: string;
    checkboxsDateOnlyTray: string;
  } {
    const startDate = this.dateService.formatDateToString(
      moment(this.form.get('startDate')?.value).startOf('day').toDate()
    );

    const endDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('endDate')?.value).endOf('day').toDate())?.toISOString().split(".")[0];

    const {
      selectedInstitution,
      reportType,
      checkboxIndividualPatient,
      checkboxUnlockedInterval,
      checkboxNoInventary,
      checkboxOnlySelfManaged,
      checkboxsDateOnlyTray,
    } = this.form.getRawValue();

    return {
      startDate,
      // @ts-ignore
      endDate,
      selectedInstitution,
      reportType,
      checkboxIndividualPatient,
      checkboxUnlockedInterval,
      checkboxNoInventary,
      checkboxOnlySelfManaged,
      checkboxsDateOnlyTray,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray
      );
      this.reportService.handlePrintClick(this.reportDataForecast);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  handlePdfClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray,
      } = this.getFormData();
      this.setInfoRP(
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray
      );
      this.reportService.handlePdfClick(this.reportDataForecast);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  handleExcelClick(): void {
    if (this.form.valid) {
      const {
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray,
      } = this.getFormData();

      this.setInfoRP(
        startDate,
        endDate,
        selectedInstitution,
        reportType,
        checkboxIndividualPatient,
        checkboxUnlockedInterval,
        checkboxNoInventary,
        checkboxOnlySelfManaged,
        checkboxsDateOnlyTray
      );
      this.reportService.handleExcelClick(this.reportDataForecast);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  setInfoRP(
    startDate: string,
    endDate: string,
    selectedInstitution: any,
    reportType: string,
    checkboxIndividualPatient: string,
    checkboxUnlockedInterval: string,
    checkboxNoInventary: string,
    checkboxOnlySelfManaged: string,
    checkboxsDateOnlyTray: string,
    isPreview: boolean = false
  ): void {
   
    let  currentInstitution;
    const dataForm = this.form.getRawValue()
    //@ts-ignore
    currentInstitution = dataForm.selectedInstitution.map((item: any) => item.id).join();
    let rpData: any;

    if (reportType == '1' || reportType == '3') {
      rpData = {
        LocaleId: '3082',
        OnlyUnblocked: String(checkboxUnlockedInterval),
        OnlyNoStock: String(checkboxNoInventary),
        OnlySelfManaged: String(checkboxOnlySelfManaged),
        ForecastDays: '0',
        DateFrom: startDate,
        DateUntil: endDate,
        InstitutionList: currentInstitution,
        OnlyTray: reportType == '1' ? String(false) : null,
      };

      this.reportDataForecast = reportDataForecast;
    } else {
      rpData = {
        LocaleId: '3082',
        OnlyUnblocked: String(checkboxUnlockedInterval),
        OnlyNoStock: String(checkboxNoInventary),
        OnlySelfManaged: String(checkboxOnlySelfManaged),
        DateFrom: this.dateService.formatDateToString(
          new Date(checkboxsDateOnlyTray)
        ),
        InstitutionList: currentInstitution,
        ReportType: 2,
      };

      this.reportDataForecast = checkboxIndividualPatient ? reportDataForecastReportPatientTry : reportDataForecastReportTry;
    }

    const now = moment();
    isPreview ? this.reportDataForecast.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    this.reportDataForecast.dataDownloadPDF.dn = this.reportDataForecast.dataRenderReport.dn = `Reporte_de_pronóstico${reportType === '2' && checkboxIndividualPatient ? '_bandeja' : ''}_del_${moment(startDate).format('YYYYMMDD')}_al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataForecast.dataDownloadExcel.dn = `Reporte_de_pronóstico${reportType === '2' && checkboxIndividualPatient ? '_bandeja' : ''}_del_${moment(startDate).format('YYYYMMDD')}_al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    const rp = JSON.stringify(rpData);
    this.reportDataForecast.data.rp = rp;
    this.reportDataForecast.dataDownloadPDF.rp = rp;
    this.reportDataForecast.dataDownloadExcel.rp = rp;
    this.reportDataForecast.dataRenderReport.rp = rp;
  }

  handleExport(): void {
    const {
      startDate,
      endDate,
      selectedInstitution,
      reportType,
      checkboxIndividualPatient,
      checkboxUnlockedInterval,
      checkboxNoInventary,
      checkboxOnlySelfManaged,
      checkboxsDateOnlyTray,
    } = this.getFormData();

    const dataForm = this.form.getRawValue()
    const currentInstitution = dataForm.selectedInstitution.map((item: any) => item.id).join();

    const jss = {
      FarmaticLinkId: this.authService.getFarmaticId(),
      ReportType: Number(reportType),
      DateFrom: startDate,
      DateUntil: endDate,
      InstitutionList: currentInstitution,
      OnlyTray: reportType == '1' ? String(false) : null,
      OnlyUnblocked: String(checkboxUnlockedInterval),
      OnlyNoStock: String(checkboxNoInventary),
      TipoPedido: 2,
      IdProv: linkData.salesData[this.authService.getFarmaticId()].salesForecastOrderProv,
      IdProtocolo: linkData.salesData[this.authService.getFarmaticId()].idProtocolo || null
    }

    this.modal.openModalCallback(() => {
      this.calls.insertForecastOrder(JSON.stringify(jss)).subscribe((res) => {
        if (res.payload == null || !!res.payload[0]?.ErrorCode) {
          this.modal.openModalErrorInfo('Ocurrió un error al exportar', 'Exportar');
        } else {
          this.calls.openSnack('Se ha exportado correctamente');
        }
      });
    }, `
    Se exportará el pedido que se corresponde con los parámetros seleccionados.

    ¿Está seguro de que desea continuar?
    `, "Exportar pedido", false, () => {});

    
  }

  reload(): void {
    this.loadingService.start('Generando nuevas fechas..');
    this.calls.buildNextForecastTrayDate().subscribe(() => {
      this.getForecastHistoryDate();
      this.loadingService.stop();
    });
  }

  delete(): void {
    this.loadingService.start('Eliminando fechas..');
    this.calls.removeLastForecastTrayHistoryDate().subscribe(() => {
      this.getForecastHistoryDate();
      this.loadingService.stop();
    });
  }
}
