import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormConfigArrayInterface } from '@interfaces/array.form.interface';
import { ImageService } from '@services/image/image.service';
import { CallsService } from '@services/api/calls.service';
import { Document } from '@interfaces/file/document.interface';
import { environment } from '@json/src/environments/environment';
import { DocumentService } from '@services/document.service';
import { ModalService } from '@services/modal.service';

@Component({
    selector: 'app-documents-preview-upload',
    templateUrl: './documents-preview-upload.component.html',
    styleUrls: ['./documents-preview-upload.component.scss'],
})
export class DocumentsPreviewUploadComponent implements OnInit {
    @Input() formConfig: any | FormConfigArrayInterface;
    @Input() fieldControl: FormControl;
    @Input() readOnly?: () => boolean = () => false;

    public documents: Document[] = [];
    public paramId: string;
    public type: string;
    public imgSRC: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private imageService: ImageService,
        private calls: CallsService,
        private documentService: DocumentService,
        private modalService: ModalService
    ) { }

    ngOnInit(): void {
        this.setDocuments();
        this.route.params.subscribe(
            (params) => (this.paramId = params['id']?.toString())
        );
        this.type = this.imageService.getEntityTarget(this.router.url);
        this.imgSRC = this.documentService.getDocumentURL(
            environment.urlBack,
            this.type
        );
    }

    setDocuments(): void {
        this.fieldControl.valueChanges.subscribe(() => {
            const documentsArray = this.fieldControl.value;
            this.documents = documentsArray.map((document: Document) => ({
                idEntity: this.paramId,
                entity: this.type,
                image: `${this.imgSRC}/${this.documentService.getDocFileName(
                    document.name,
                    Number(this.paramId)
                )}`,
                name: document.name,
                date: document.date,
                mimeType: document.mimeType,
            }));
        });
    }

    addNewDocument(event: any): void {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const target = e.target as FileReader;
            const document = new File([target.result as ArrayBuffer], file.name);
            const fileName = file.name;
            const date = new Date().toISOString();

            // Send document to the server
            this.uploadToServer(
                document,
                fileName,
                date,
                this.type,
                false,
                document.type
            );
        };

        reader.readAsArrayBuffer(file);
    }

    uploadToServer(
        document: any,
        fileName: string,
        date: string,
        type: string,
        isDelete: boolean,
        mimeType?: string
    ): void {
        const data = new FormData();
        data.append('file', isDelete ? null : document);
        data.append('data[Token]', localStorage.getItem('access_token') ?? '');
        data.append('data[Id]', this.paramId);
        data.append('data[Target]', type + '\\docs');
        data.append('data[FileName]', fileName);

        this.calls.uploadEntityDoc(data).subscribe((next) => {
            this.modalService.reloadComponent(this.router);
        });
    }

    handleDeleteEvent(document: Document): void {
        this.modalService.openModalCallback(
            () =>
                this.uploadToServer(
                    document,
                    document.name,
                    document.date,
                    this.type,
                    true,
                    document.mimeType
                ),
            '¿Está seguro de que quiere eliminar el documento?',
            'Eliminar documento'
        );
    }

    handleDownloadEvent(document: Document): void {
        this.documentService.download(
            document.image ?? '',
            document.name,
            document.mimeType
        );
    }
}
