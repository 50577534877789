import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';

import { SortService } from '@services/sort.service';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

@Component({
    selector: 'app-box-lock',
    templateUrl: './box-lock.component.html',
    styleUrls: ['./box-lock.component.scss'],
})
export class BoxLockComponent extends DynamicFormComponent implements OnInit, OnDestroy {

    _disabled: boolean = false;
    @Input() set disabled(val: boolean) {
        this._disabled = val;
        if (val) {
            this.schema.forEach((schema: FormItemSchemaInterface) => {
                schema.childs
                    ?.filter((child: FormItemSchemaInterface) => child.type !== 'info')
                    .forEach((child: FormItemSchemaInterface) => child.fieldControl.disable({emitEvent: false, onlySelf: true}));
            });
        }
        else {
            this.schema.forEach((schema: FormItemSchemaInterface) => {
                schema.childs
                    ?.filter((child: FormItemSchemaInterface) => child.type !== 'info')
                    .forEach((child: FormItemSchemaInterface) => child.fieldControl.enable({emitEvent: false, onlySelf: true}));
            });
        }
    };
    get disabled() : boolean {
        return this._disabled;
    }


    constructor(
        protected override sortService: SortService,
        protected override cdr: ChangeDetectorRef
    ) {
        super(sortService, cdr)
    }

    override ngOnInit(): void {

        super.ngOnInit();
        this.schema.forEach(schema => schema.childs?.forEach(child => { this.fieldVisible(child, child) }));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
