<div class="edit-prescription">
  <div class="row mb-2">
    <div class="col-12 edit-prescription__header">
      <mat-expansion-panel 
        #expansionPanel 
        hideToggle 
        [disabled]="!form.getRawValue().medicine"
        (opened)="expansionPanelOpen = true"
        (closed)="expansionPanelOpen = false"
      >
        <mat-expansion-panel-header
          [ngClass]="{
            'no-blister': form.getRawValue().noBlister,
            'medicine-selected': form.getRawValue().medicine,
            'fgp': isFGP
          }"
        >
          <mat-panel-title>
            <div *ngIf="!form.getRawValue().medicine">{{ prescriptionId ? 'Modificar prescripción' : 'Nueva prescripción' }}</div>
            <div *ngIf="form.getRawValue().medicine" class="medicine-title">
              <div class="medicine-name" [matTooltip]="form.getRawValue().medicine.nam">{{ form.getRawValue().medicine.nam }} -&nbsp;</div>
              <span class="drug-pref" matTooltip="Código maestro sustitutorio">{{ !!selectedDrugData ? selectedDrugData.CMS : '' }}</span>
              <ng-container *ngIf="!selectedDrugData?.Obsolete">
                <span *ngIf="selectedDrugData?.CMS">/</span>
                <span class="drug-id" matTooltip="Código maestro">{{ !!selectedDrugData ? selectedDrugData.DrugId : ''}}</span>
              </ng-container>
              <span class="no-blister-tag" *ngIf="form.getRawValue().noBlister && isFGP">NO BLISTER</span>
              <div class="expansion-header-button" (click)="showPosology()">
                <mat-icon matTooltip="Posología" class="posology posology-icon">description</mat-icon>
                <span class="view posology posology-text">Ver posología</span>
              </div>
              <div class="expansion-header-button" (click)="showActiveIngredients()">
                <mat-icon matTooltip="Posología" class="active-ingredient active-ingredient-icon">bubble_chart</mat-icon>
                <span class="view active-ingredient active-ingredient-text">Ver principios activos</span>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <!-- <button mat-icon-button *ngIf="(prescriptionId == undefined || isModification) && form.valid" (click)="savePrescription(); stopPropagation($event)" color="primary"> <mat-icon>check_circle</mat-icon></button> -->
            <!-- <button mat-icon-button *ngIf="prescriptionId == undefined && form.invalid" (click)="form.markAllAsTouched(); stopPropagation($event)" color="primary"> <mat-icon>check_circle</mat-icon></button> -->
            <button mat-icon-button (click)="close.emit()" color="primary"> <mat-icon>close</mat-icon></button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="expansionInfo === 'posology'">
          <h3 class="mt-2 titleExpansion">POSOLOGíA</h3>
          <div class="bodyExpansion"[innerHTML]="posology.length ? posology : defaultPosologyMsg"></div>
        </ng-container>
        <ng-container *ngIf="expansionInfo === 'active-ingredients'">
          <h3 class="mt-2 titleExpansion">PRINCIPIOS ACTIVOS</h3>
          <div class="bodyExpansion">
            <div class="active-ingredients-list">
              <div class="active-ingredients-item" *ngFor="let activeIngredient of medicineActiveIngredients">
                <div class="name">{{activeIngredient.Nombre}}</div>
                <div class="badges">
                  <img
                    *ngIf="activeIngredient.Estupefaciente"
                    class="img-icon hospital" 
                    src="assets/images/gsp/estupefaciente-32.png"
                    matTooltip="Estupefaciente"
                  />
                  <img
                    *ngIf="activeIngredient.Psicotropo"
                    class="img-icon hospital" 
                    src="assets/images/gsp/psicotropo-32.png"
                    matTooltip="Psicótropo"
                  />
                  <img
                    *ngIf="activeIngredient.DMARC"
                    class="img-icon hospital" 
                    src="assets/images/gsp/marc-64.png"
                    matTooltip="MARC"
                  />
                </div>
              </div>

              <div class="no-data" *ngIf="medicineActiveIngredients && !medicineActiveIngredients.length">
                No hay principios activos conocidos
              </div>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="row">
    <div class="col-6 right">

      <div [ngClass]="{
        'no-payMed': !payMed,
        'fgp': isFGP
      }" class="title medicine">
        Medicación 
        <span *ngIf="!payMed">¡Sin cobertura sanitaria!</span>
        <span *ngIf="isFGP">Medicamento fuera de la guía preferente</span>
      </div>

      <!-- Danger group -->
      <div class="danger-group" *ngIf="medicineDangerGroup">
        <ng-container [ngSwitch]="medicineDangerGroup">
          <div *ngSwitchCase="1" class="group">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo1-32.png"
            />
            <span class="text">Medicamento peligroso - Grupo 1</span>
          </div>
          <div *ngSwitchCase="2" class="group">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo2-32.png"
            />
            <span class="text">Medicamento peligroso - Grupo 2</span>
          </div>
          <div *ngSwitchCase="3" class="group">
            <img
              class="img-icon" 
              src="assets/images/meidcamentos-peligrosos/grupo3-32.png"
            />
            <span class="text">Medicamento peligroso - Grupo 3</span>
          </div>
        </ng-container>
      </div>

      <app-form-field
        *ngIf="prescriptionId == undefined && !isModification"
        class="field"
        type="radio"
        label="Tipo de búsqueda"
        [hideLabel]="true"
        [class]="'col-12'"
        [fieldControl]="form.controls['searchType'] | asFormControl"
        [options]="[{label: 'Nombre medicamento', value: true }, {label: 'Principio activo', value: false }]"
        [customStyle]="{ 'padding-bottom': '10px' }"
      ></app-form-field>

      <!-- Active ingredient selector -->
      <div class="active-ingredient-container"
        *ngIf="!form.controls['searchType']?.value">
        <app-form-field
          #activeIngredient
          type="select"
          label="Principio activo"
          class="field"
          [fieldControl]="form.controls['activeIngredientInput'] | asFormControl"
          [options]="activeIngredientsOptions"
          [multipleSelect]="true"
          [allowSelectAll]="false"
          [filterSelect]="true"
          [selectWithChips]="true"
          [selectCleanFilterInput]="true"
          [selectCleanFilterInputAndFocus]="false"
        ></app-form-field>

        <div class="deselect-all">
          <button 
            matTooltip="Deseleccionar todos" 
            [disabled]="!form.controls['activeIngredientInput'].value?.length"
            (click)="deselectAllActiveIngredient()"
          >
            <mat-icon>
              playlist_remove
            </mat-icon>
          </button>
        </div>
      </div>

      <!-- Medicine search -->
      <div class="medicine-container" *ngIf="form.controls['searchType']?.value">
        <div class="no-stock field col-2"
          *ngIf="form.controls['medicineInput']?.value?.flags?.noStock">
          <mat-icon
            [matTooltip]="'Agotado'"
          >error</mat-icon>
        </div>

        <app-form-field
          #medicina
          type="autocomplete"
          label="Nombre medicamento"
          class="field"
          [class]="form.controls['medicineInput'].value?.flags?.noStock ? 'col-10' : 'col-12'"
          [fieldControl]="form.controls['medicineInput'] | asFormControl"
          [options]="medicinesOptions"
          [customFieldData]="{compareStart: true}"
          [hideSingleSelectionIndicator]="true"
        ></app-form-field>
      </div>

      <!-- Filtered medicines by active ingredients count -->
      <div class="medicine-count" *ngIf="form.controls['activeIngredientInput'].value && medicinesOptions.length">
        <span>{{medicinesOptions.length}}</span> medicamentos encontrados
      </div>

      <!-- Active ingredient search -->
      <div class="medicine-container" *ngIf="!form.controls['searchType']?.value && form.controls['activeIngredientInput'].value && form.controls['activeIngredientInput'].value.length">
        <div class="no-stock field col-2"
          *ngIf="form.controls['medicineInput']?.value?.flags?.noStock">
          <mat-icon
            [matTooltip]="'Agotado'"
          >error</mat-icon>
        </div>

        <app-form-field
          *ngIf="medicinesOptions.length"
          #medicina
          type="autocomplete"
          label="Nombre medicamento"
          class="field"
          [class]="form.controls['medicineInput'].value?.flags?.noStock ? 'col-10' : 'col-12'"
          [fieldControl]="form.controls['medicineInput'] | asFormControl"
          [options]="medicinesOptions"
          [hideSingleSelectionIndicator]="true"
        ></app-form-field>

        <div class="loading" *ngIf="loadingMedicinesByActiveIngredients">
          <mat-spinner [diameter]="20"></mat-spinner>
          <span>Cargando medicamentos...</span>
        </div>

        <div class="no-data" *ngIf="!medicinesOptions.length && !loadingMedicinesByActiveIngredients">No hay medicamenos que contengan esos principios activos</div>
      </div>

      <div class="medication radio-container" [ngClass]="{'blocked': formBlocked}">
        <app-form-field
          type="checkbox"
          class="field"
          label="No emblistar"
          [class]="'col-6'"
          [fieldControl]="form.controls['noBlister'] | asFormControl"
        ></app-form-field>
        <app-form-field
          class="field"
          type="checkbox"
          label="Forzar pago"
          [class]="'col-6'"
          [fieldControl]="form.controls['forcePayment'] | asFormControl"
        ></app-form-field>
      </div>
      <div class="medication radio-container" [ngClass]="{'blocked': formBlocked}">
        <app-form-field
          class="field"
          type="checkbox"
          label="Si precisa"
          [class]="'col-6'"
          [fieldControl]="form.controls['ifNeeded'] | asFormControl"
        ></app-form-field>
        <app-form-field
          class="field"
          type="checkbox"
          label="Agudo"
          [class]="'col-6'"
          [fieldControl]="form.controls['severe'] | asFormControl"
        ></app-form-field>
      </div>

      <app-form-field
        type="select"
        label="Vía"
        class="field method"
        [class]="'col-12'"
        [fieldControl]="form.controls['adminRoute'] | asFormControl"
        [options]="adminRouteOptions"
        [ngClass]="{'blocked': formBlocked}"
      ></app-form-field>

      <div *ngIf="isRa" class="ra-container" [ngClass]="{'blocked': formBlocked}">
        <app-form-field
           class="field"
          type="checkbox"
          label="RA"
          [fieldControl]="form.controls['ra'] | asFormControl"
        ></app-form-field>
        <app-form-field
          *ngIf="form.getRawValue().ra"
          type="number"
          class="field"
          label="Cantidad"
          [hideLabel]="true"
          [fieldControl]="form.controls['raNumber'] | asFormControl"
        ></app-form-field>
      </div>
    </div>
    <div class="col-6" [ngClass]="{'blocked': formBlocked}">
      <!-- <h3></h3> -->
      <div class="title">Intervalo de recurrencia</div>
      <div class="radio-container ">
        <app-form-field
          class="field"
          type="radio"
          label="Periodo"
          [hideLabel]="true"
          [class]="'col-8'"
          [fieldControl]="form.controls['forever'] | asFormControl"
          [options]="[{label: 'Por siempre', value: true }, {label: 'Hasta el', value: false }]"
          [customStyle]="{ 'padding-bottom': '10px' }"
        ></app-form-field>
        <app-form-field
          type="date"
          label="Desde"
          class="field"
          [class]="'col-6'"
          [fieldControl]="form.controls['since'] | asFormControl"
          [dateFieldData]="{ disablePreviousDate: today }"
        ></app-form-field>
        <app-form-field
          class="field"
          type="date"
          label="Hasta"
          [class]="'col-6'"
          [fieldControl]="form.controls['until'] | asFormControl"
          [dateFieldData]="{ disablePreviousDate: form.getRawValue().since }"
        ></app-form-field>
      </div>

      <div class="radio-container">
        <app-form-field
          type="checkbox"
          label="PE"
          [class]="'col-3'"
          [fieldControl]="form.controls['pe'] | asFormControl"
          [tooltip]="'Pendiente de evaluación'"
        ></app-form-field>
        <app-form-field
          *ngIf="form.getRawValue().pe"
          class="field"
          type="date"
          [hideLabel]="true"
          [class]="'col-6'"
          [minDate]="form.controls['since'].value"
          [maxDate]="form.controls['until'].value"
          [fieldControl]="form.controls['peDate'] | asFormControl"
        ></app-form-field>
      </div>
    </div>
    <div class="col-6 right" *ngIf="!form.getRawValue().ifNeeded" [ngClass]="{'blocked': formBlocked}">
      <!-- <h3>Meses de actividad</h3> -->
      <div class="title">Meses de actividad</div>
      <div class="radio-container months">
        <app-form-field
          *ngFor="let month of months"
          type="checkbox"
          [label]="month.label"
          [fieldControl]="form.controls[month.value] | asFormControl"
        ></app-form-field>
      </div>
    </div>
    <div class="col-6" *ngIf="!form.getRawValue().ifNeeded" [ngClass]="{'blocked': formBlocked}">
      <!-- <h3></h3> -->
      <div class="title">Patrón de recurrencia</div>

      <div class="radio-container">
        <app-form-field
          class="field pattern"
          type="radio"
          label="Periodo"
          [class]="'col-12'"
          [hideLabel]="true"
          [fieldControl]="form.controls['pattern'] | asFormControl"
          [options]="patterns"
        ></app-form-field>
      </div>
      <div class="section">
        <ng-container *ngIf="form.getRawValue().pattern === 'daily'">
          <app-form-field
            class="field col-6"
            type="select"
            label="Patrón diario"
            [fieldControl]="form.controls['dailyPattern'] | asFormControl"
            [options]="dailyPatterns"
          ></app-form-field>
          <app-form-field
            *ngIf="form.getRawValue().dailyPattern === 'repeat each'"
            class="field col-6"
            type="number"
            [label]="form.getRawValue().dailyRepeat === '1' ? 'Día' : 'Días'"
            [fieldControl]="form.controls['dailyRepeat'] | asFormControl"
            [numberFieldFastButtons]="true"
            [exactPattern]="'^[0-9]$|^[1-9][0-9]$|^[1-2][0-9][0-9]$|^[3][0-5][0-9]$|^[3][6][0-4]$'"
          ></app-form-field>
        </ng-container>
        <ng-container *ngIf="form.getRawValue().pattern === 'weekly'">
          <app-form-field
            class="field col-12"
            type="number"
            [label]="getWeeklyLabel(form.getRawValue().weeklyRepeat)"
            [fieldControl]="form.controls['weeklyRepeat'] | asFormControl"
            [numberFieldFastButtons]="true"
            [exactPattern]="'^[0-9]$|^[1-2][0-9]$|^[3][0-6]$'"
          ></app-form-field>
        </ng-container>
        <ng-container *ngIf="form.getRawValue().pattern === 'monthly'">
          <app-form-field
            class="field col-12"
            type="select"
            [label]="getMonthlyLabel(form.getRawValue().monthlyRepeat)"
            [fieldControl]="form.controls['monthlyRepeat'] | asFormControl"
            [options]="monthlyRepeats"
            ></app-form-field>
        </ng-container>
        <ng-container *ngIf="form.getRawValue().pattern === 'cyclic'">
          <app-form-field
            class="field col-6"
            type="number"
            label="Activo"
            [fieldControl]="form.controls['cyclicActive'] | asFormControl"
            [numberFieldFastButtons]="true"
            ></app-form-field>
          <app-form-field
            class="field col-6"
            type="number"
            label="Descanso"
            [fieldControl]="form.controls['cyclicRest'] | asFormControl"
            [numberFieldFastButtons]="true"
            ></app-form-field>
        </ng-container>
      </div>
    </div>
    <div class="col-12 table-section" [ngClass]="{'blocked': formBlocked}">

      <div class="title">
        Tabla de dosis
        <div class="options">
          <div *ngIf="!form.getRawValue().ifNeeded">
            <app-form-field
              type="checkbox"
              label="Horarios avanzados"
              [fieldControl]="form.controls['advancedSchedule'] | asFormControl"
            ></app-form-field>
          </div>
        </div>

        <div class="table-error">{{form.controls['table'].invalid && form.controls['table'].touched  ? 'Al menos una dosis es requerida.' : ''}}</div>
      </div>


      <div class="table-container">
        <table #doseTable mat-table [dataSource]="scheduleRows">
          <ng-container *ngFor="let column of cols; let i = index" [matColumnDef]="column.value">

            <!-- Header -->
            <th mat-header-cell *matHeaderCellDef>
              {{ column.label }}
            </th>

            <!-- Data -->
            <td mat-cell *matCellDef="let row"
              [class]="column.value +' '+ row.value + ' notranslate'"
              [ngClass]="{
                'firstColumn': column.value === cols[0].value,
                'selectedCell': selectedCell.column === column.value && selectedCell.row === row.value,
                'selectedColumnCell': column.value === 'schedule' && selectedCell.row === row.value,
                'editCell': row.value === 'edit' && column.value === cols[0].value
              }"
              (click)="prescriptionId == undefined || isModification ? onClickCell(column.value, row.value) : undefined"
              [attr.colspan]="row.value === 'edit' ? cols.length : 1"
              [attr.rowspan]="column.value === 'schedule' && selectedCell.row === row.value ? '2' : '1'"
              [ngStyle]="{'display': row.value === 'edit' && column.value !== cols[0].value ? 'none' : 'table-cell'}"
            >
              <ng-container *ngIf="column.value === 'schedule'">
                {{row.label}}

                <ng-container *ngIf="row.value === 'edit'">
                  <div class="edit-form-container"
                    (click)="stopPropagation($event)"
                  >
                    <div class="content">
                      <mat-button-toggle-group
                        class="delete"
                        [ngModel]="editForm.getRawValue()['quantity']"
                        (change)="onToggleChange($event)"
                      >
                        <mat-button-toggle [value]="0">
                          <mat-icon>delete_forever</mat-icon>
                        </mat-button-toggle>
                      </mat-button-toggle-group>

                      <div class="dose">
                        <app-form-field
                          type="text"
                          label="Dosis"
                          [fieldControl]="editForm.controls['quantity'] | asFormControl"
                          [inputPattern]="
                            partiable() ? '^[0-9]|,$' : '^[0-9]$'"
                          [exactPattern]="
                            partiable()
                            ? '^[0-9]{0,3}$|^[0-9]{0,3}[,.]$|^[0-9]{0,3}[,.][0-9]$|^[0-9]{0,3}[,.][0-9][0-9]$'
                            : undefined
                          "
                        ></app-form-field>
                        <mat-button-toggle-group
                          class="fast-buttons"
                          (change)="onToggleDoseFastButton($event)"
                        >
                          <mat-button-toggle [value]="1">-</mat-button-toggle>
                          <mat-button-toggle [value]="2">+</mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>

                      <mat-button-toggle-group
                        [ngModel]="editForm.getRawValue()['quantity']"
                        (change)="onToggleChange($event)"
                      >
                        <mat-button-toggle
                          *ngFor="let option of dosisOptions"
                          [value]="option.value"
                        >
                          {{ option.label }}
                        </mat-button-toggle>
                        <ng-container *ngIf="!dosisOptions.length">
                          <mat-button-toggle [value]="1">1</mat-button-toggle>
                          <mat-button-toggle [value]="2">2</mat-button-toggle>
                        </ng-container>
                      </mat-button-toggle-group>
                      <app-form-field
                        type="select"
                        label="Departamento"
                        [fieldControl]="editForm.controls['department'] | asFormControl"
                        [options]="departmentOptions"
                      ></app-form-field>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="column.value !== 'schedule'">
                <ng-container *ngIf="selectedCell.column === column.value && selectedCell.row === row.value; else elseTemplate">
                  {{ !!form.getRawValue().table[column.value][row.value]?.quantity ? form.getRawValue().table[column.value][row.value]?.quantity : '' }}
                </ng-container>

                <ng-template #elseTemplate>
                  {{ !!form.getRawValue().table[column.value][row.value]?.quantity && form.getRawValue().table[column.value][row.value]?.quantity !== '0' ? form.getRawValue().table[column.value][row.value]?.quantity : '' }}
                </ng-template>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
          <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
        </table>
      </div>
    </div>
    <div class="col-12" [ngClass]="{'blocked': formBlocked}">
      <div class="title marginTop">Otros datos</div>
      <app-form-field
        class="field col-12"
        type="textarea"
        label="Observaciones"
        [fieldControl]="form.controls['observations'] | asFormControl"
      ></app-form-field>
    </div>
  </div>
</div>
