import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordForm } from '@interfaces/forms.interface';
import { AuthService } from '@services/auth.service';
import { matchFieldValueValidator } from '@validators/custom-validators.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  public form: FormGroup<ResetPasswordForm> = new FormGroup({
    password: new FormControl<string>('', { validators: [Validators.required, Validators.pattern('^((?!.*[s])(?=.*[A-Z])(?=.*d).{8,99})')], nonNullable: true}),
    password2: new FormControl<string>('', { validators: [Validators.required, matchFieldValueValidator('password')], nonNullable: true}) // TODO: password repetido, complejo
  });

  constructor(
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.form.valid) {
      this.authService.resetPassword(this.form.getRawValue());
    }
  }
}


