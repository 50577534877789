import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataActus } from '@constants/reports/report-actus';
import { schedulesHalfHourOptions } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { MatDatepicker } from '@angular/material/datepicker';
import {
    DepartmentPharma,
    PatientPharma,
    Report,
    User,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MatRadioChange } from '@angular/material/radio';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-report-actus',
  templateUrl: './report-actus.component.html',
  styleUrls: ['./report-actus.component.scss']
})
export class ReportActusComponent {
    public reportDataActus = reportDataActus;
    public showIssueSelect: boolean = false;
    public showPatientSelect: boolean = false;
    public showPersonalSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showIntervalDay: boolean = false;
    public showSpecificDaySelect: boolean = false;
    public showDepartmentSelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public selectOptionsPatientsWithoutFilter: PatientPharma[];
    public selectOptionsPersonal: SelectOption[] = [];
    public schedulesOptions = schedulesHalfHourOptions;

    private _selectOptionsPatients: PatientPharma[];
    public selectOptionsPatientsAutocomplete: SelectOption[];

    set selectOptionsPatients(value: PatientPharma[]) {
        this._selectOptionsPatients = value;
        this.selectOptionsPatientsAutocomplete = value.map(v => {
            return {
                label: v.nam,
                value: v.id
            }
        })
    }
    get selectOptionsPatients() {
        return this._selectOptionsPatients;
    }

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    public selectOptionsDepartment: SelectOption[] = [];

    // public selectOptionsIssues: Array<{id: string, des: string}> = [];
    public selectOptionsIssues: SelectOption[] = [];

    public languages: SelectOption[] = [];

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService, 
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            reportType: ['master'],
            interval: ['oneWeekAgo'],
            issuesOnly: [false],
            language: [''],
            selectedIssue: [''],
            selectedPatient: [''],
            selectedPersonal: [''],
            selectedDepartment: [''],
            specificDay: [moment()],
            startDate: [moment().subtract(1, 'week')],
            endDate: [moment()],
            week: [moment()],
            month: [moment()],
        });

        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Administración de medicación (ACTUS)`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Administración de medicación (ACTUS)`);

        this.typeGlobal = this.roleManager.isPharma();

        this.getDataForTheSelects();
        this.setListenerSelectDay();
    }

    setListenerSelectDay(): void {
        this.form.get('intervalType')?.valueChanges.subscribe((value) => {
            if (value === 'specificDay') {
                this.showSpecificDaySelect = true;
                this.showIntervalDay = false;
            } else if (value === 'intervalDay') {
                this.showSpecificDaySelect = false;
                this.showIntervalDay = true;
            }
        });
    }

    getDataForTheSelects(): void {
        this.reportService
            .getRepParamsEditDataActus(this.currentInstitution)
            .subscribe((result) => {
                this.languages = result.payload.locale.map((lang: any) => {return {value: lang.lid, label: lang.nam}});
                this.selectOptionsDepartment = result.payload.deps.map((dep: any) => {return {value: dep.id, label: dep.nam}});
                this.selectOptionsPatients = result.payload.pats;
                this.selectOptionsPersonal = result.payload.users.map((user: any) => {return {value: user.id, label: user.nam}});
                this.selectOptionsIssues = result.payload.itkres.map((issue: any) => {return {value: issue.id, label: issue.des}});

                const language = this.form.get('language')?.setValue(this.languages[1].value);
            });
    }

    formValid(): boolean {
        let allOk: boolean = true;

        if ( this.showIssueSelect && !this.form.get('selectedIssue')?.value) {
            allOk = false;
            this.form.get('selectedIssue')?.setErrors({required: true});
            this.form.get('selectedIssue')?.markAsTouched();
        }

        if ( this.showPatientSelect && !this.form.get('selectedPatient')?.value) {
            allOk = false;
            this.form.get('selectedPatient')?.setErrors({required: true});
            this.form.get('selectedPatient')?.markAsTouched();
        }

        if (  this.showPersonalSelect && !this.form.get('selectedPersonal')?.value) {
            allOk = false;
            this.form.get('selectedPersonal')?.setErrors({required: true});
            this.form.get('selectedPersonal')?.markAsTouched();
        }

        if ( this.showDepartmentSelect && !this.form.get('selectedDepartment')?.value) {
            allOk = false;
            this.form.get('selectedDepartment')?.setErrors({required: true});
            this.form.get('selectedDepartment')?.markAsTouched();
        }

        return allOk;
    }

    handlePreviewClick() {
        if (this.formValid()) {
            const {
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month
            } = this.getFormData();

            this.setInfoRP(
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month,
                true
            );

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataActus
            );
        }
    }

    getFormData(): {
        reportType: string,
        interval: string,
        issuesOnly: boolean,
        language: string,
        selectedIssue: string,
        selectedPatient: string,
        selectedPersonal: string,
        selectedDepartment: string,
        specificDay: string,
        startDate: string,
        endDate: string,
        week: string,
        month: string,
    } {
        const reportType = this.form.get('reportType')?.value;
        const interval = this.form.get('interval')?.value;
        const issuesOnly = this.form.get('issuesOnly')?.value;
        const language = this.form.get('language')?.value;
        const selectedIssue = this.form.get('selectedIssue')?.value;
        const selectedPatient = this.form.get('selectedPatient')?.value;
        const selectedPersonal = this.form.get('selectedPersonal')?.value;
        const selectedDepartment = this.form.get('selectedDepartment')?.value;
        const specificDay = this.dateService.formatDateToString(
            this.form.get('specificDay')?.value
        );
        const startDate = this.dateService.formatDateToString(
            this.form.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.form.get('endDate')?.value
        );
        const week = this.dateService.formatDateToString(
            this.form.get('week')?.value
        );
        const month = this.dateService.formatDateToString(
            this.form.get('month')?.value
        );

        return {
            reportType,
            interval,
            issuesOnly,
            language,
            selectedIssue,
            selectedPatient,
            selectedPersonal,
            selectedDepartment,
            specificDay,
            startDate,
            endDate,
            week,
            month,
        };
    }

    handlePrintClick() {
        if (this.formValid()) {
            const {
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month
            } = this.getFormData();

            this.setInfoRP(
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month,
            );
            this.reportService.handlePrintClick(this.reportDataActus);
        }
    }

    handlePdfClick() {
        if (this.formValid()) {
            const {
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month
            } = this.getFormData();
            this.setInfoRP(
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month,
            );
            this.reportService.handlePdfClick(this.reportDataActus);
        }
    }

    handleExcelClick() {
        if (this.formValid()) {
            const {
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month
            } = this.getFormData();

            this.setInfoRP(
                reportType,
                interval,
                issuesOnly,
                language,
                selectedIssue,
                selectedPatient,
                selectedPersonal,
                selectedDepartment,
                specificDay,
                startDate,
                endDate,
                week,
                month,
            );

            this.reportService.handleExcelClick(this.reportDataActus);
        }
    }

    setInfoRP(
        reportType: string,
        interval: string,
        issuesOnly: boolean,
        language: string,
        selectedIssue: string,
        selectedPatient: string,
        selectedPersonal: string,
        selectedDepartment: string,
        specificDay: string,
        startDate: string,
        endDate: string,
        week: string,
        month: string,
        isPreview: boolean = false
    ): void {
        switch (reportType) {
            case 'master':
                this.reportDataActus.data.rpp = '/COROTA/PdaMaster';
                this.reportDataActus.dataDownloadPDF.rpp = '/COROTA/PdaMaster';
                this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/PdaMaster';
                this.reportDataActus.dataRenderReport.rpp = '/COROTA/PdaMaster';
                break;
            case 'staff':
                this.reportDataActus.data.rpp = '/COROTA/PdaResultIssuesByUsers';
                this.reportDataActus.dataDownloadPDF.rpp = '/COROTA/PdaResultIssuesByUsers';
                this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/PdaResultIssuesByUsers';
                this.reportDataActus.dataRenderReport.rpp = '/COROTA/PdaResultIssuesByUsers';
                break;
            case 'patients':
                this.reportDataActus.data.rpp = '/COROTA/PdaResultIssuesByPatients';
                this.reportDataActus.dataDownloadPDF.rpp = '/COROTA/PdaResultIssuesByPatients';
                this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/PdaResultIssuesByPatients';
                this.reportDataActus.dataRenderReport.rpp = '/COROTA/PdaResultIssuesByPatients';
                break;
            case 'issues':
                this.reportDataActus.data.rpp = '/COROTA/PdaResultIssuesByIssues';
                this.reportDataActus.dataDownloadPDF.rpp = '/COROTA/PdaResultIssuesByIssues';
                this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/PdaResultIssuesByIssues';
                this.reportDataActus.dataRenderReport.rpp = '/COROTA/PdaResultIssuesByIssues';
                break;
            case 'departments':
                this.reportDataActus.data.rpp = '/COROTA/PdaResultIssuesByDep';
                this.reportDataActus.dataDownloadPDF.rpp = '/COROTA/PdaResultIssuesByDep';
                this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/PdaResultIssuesByDep';
                this.reportDataActus.dataRenderReport.rpp = '/COROTA/PdaResultIssuesByDep';
                break;
        }

        const rp = JSON.stringify({
            InstId: this.currentInstitution,
            DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate()),
            DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate()),
            LocaleId: language,
            FilterIntakeResult: selectedIssue ? String(selectedIssue) : '',
            FilterUser: selectedPersonal ? String(selectedPersonal) : '',
            FilterPatient: selectedPatient ? String(selectedPatient) : '0',
            FilterDepartment: selectedDepartment ? String(selectedDepartment) : '0',
            IssuesOnly: issuesOnly ? String(true) : String(false),
        });

        this.reportDataActus.data.rp = rp;
        this.reportDataActus.dataDownloadPDF.rp = rp;
        this.reportDataActus.dataDownloadExcel.rp = rp;
        this.reportDataActus.dataRenderReport.rp = rp;

        const now = moment();
        
        if (isPreview) this.reportDataActus.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataActus.dataDownloadPDF.dn = this.reportDataActus.dataRenderReport.dn = `ACTUS_General_administración_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataActus.dataDownloadExcel.dn = `ACTUS_General_administración_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

        console.log('this.reportDataActus', this.reportDataActus);

        // if (this.form.controls['intervalType'].value == 'specificDay') {
        //     startDateRP = this.dateService.transformToDateWithSpecificHours(specificDay, "00:00");
        //     endDateRP = this.dateService.transformDateTimeIncrementHours(specificDay);
        // } else {
        //     startDateRP = Utils.zeroTimeDate(new Date(startDate));
        //     startDateRP = this.dateService.transformToDateWithSpecificHours(
        //         startDate,
        //         selectedOptionFrom
        //     );
        //     endDateRP = this.dateService.transformToDateWithSpecificHours(
        //         endDate,
        //         selectedOptionUntil
        //     );
        // }


        // const rp = JSON.stringify({
        //     LocaleId: '3082',
        //     UserName: selectedPersonal ? String(selectedPersonal) : null,
        //     PatientId: selectedPatient ? String(selectedPatient) : null,
        //     DateFrom: startDateRP,
        //     DateUntil: endDateRP,
        //     ShowNE: checkboxNE ? String(checkboxNE) : null,
        //     ShowSP: checkboxSP ? String(checkboxSP) : null,
        //     ShowPAGO: checkboxPayment ? String(checkboxPayment) : null,
        //     ShowAgudos: checkboxAcute ? String(checkboxAcute) : null,
        //     GroupMedicoSS: checkboxDoctorSS ? String(checkboxDoctorSS) : null,
        // });

        // this.reportDataActus.data.rp = rp;
        // if (isPreview) this.reportDataActus.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        // this.reportDataActus.dataDownloadPDF.rp = rp;
        // this.reportDataActus.dataDownloadExcel.rp = rp;
        // this.reportDataActus.dataRenderReport.rp = rp;

        // const now = moment();
        // if (!this.showPersonalSelect) {
        //     this.reportDataActus.data.rpp = this.reportDataActus.dataDownloadPDF.rpp =
        //     this.reportDataActus.dataRenderReport.rpp = this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/MedChanges';
        // }
        // else {
        //     this.reportDataActus.data.rpp = this.reportDataActus.dataDownloadPDF.rpp =
        //     this.reportDataActus.dataRenderReport.rpp = this.reportDataActus.dataDownloadExcel.rpp = '/COROTA/MedChangesUsers';
        // }

        // this.reportDataActus.dataDownloadPDF.dn = this.reportDataActus.dataRenderReport.dn =
        //     `Reporte_de_cambios_en_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        // this.reportDataActus.dataDownloadExcel.dn = `Reporte_de_cambios_en_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    toggleSelectIssue(): void {
        this.showIssueSelect = !this.showIssueSelect;
        if (!this.showIssueSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelectPersonal(): void {
        this.showPersonalSelect = !this.showPersonalSelect;
        if (!this.showPersonalSelect) {
            this.form.patchValue({
                selectedPersonal: '',
            });
        }
    }

    toggleSelectSpecificDay(): void {
        this.showSpecificDaySelect = !this.showSpecificDaySelect;
        if (!this.showSpecificDaySelect) {
            this.form.patchValue({
                specificDay: '',
            });
        }
    }

    fillDates(): void {
        console.log(this.form.get('interval')?.value);

        switch (this.form.get('interval')?.value) {
            case 'oneWeekAgo':
                this.form.patchValue({
                    startDate: moment().subtract(1, 'week'),
                    endDate: moment(),
                });
                break;
            case 'twoWeeksAgo':
                this.form.patchValue({
                    startDate: moment().subtract(2, 'week'),
                    endDate: moment(),
                });
                break;
            case 'oneMonthAgo':
                this.form.patchValue({
                    startDate: moment().subtract(1, 'month'),
                    endDate: moment(),
                });
                break;
            case 'specificDay':
                this.form.patchValue({
                    startDate: moment(this.form.get('specificDay')?.value).startOf('day'),
                    endDate: moment(this.form.get('specificDay')?.value).endOf('day'),
                });
                break;
            case 'fullWeek':
                this.form.patchValue({
                    startDate: moment(this.form.get('week')?.value).startOf('week'),
                    endDate: moment(this.form.get('week')?.value).endOf('week'),
                });
                break;
            case 'fullMonth':
                this.form.patchValue({
                    startDate: moment(this.form.get('month')?.value).startOf('month'),
                    endDate: moment(this.form.get('month')?.value).endOf('month'),
                });
                break;
        }
    }

    toggleSelectDepartment(): void {
        this.showDepartmentSelect = !this.showDepartmentSelect;
        if (!this.showDepartmentSelect) {
            this.form.patchValue({
                selectedDepartment: '',
            });
        }
    }

    setFullMonth(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>,
        formControlName: string
    ) {
        const ctrlValue = this.form.get(formControlName)?.value;

        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());

        this.form.patchValue({ [formControlName]: ctrlValue });
        datepicker.close();

        this.fillDates();
    }
}

