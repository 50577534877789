import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public message: BehaviorSubject<string> = new BehaviorSubject('');
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor() { }

  start(message?: string): void {
    this.loading.next(true);
    this.message.next(message ?? '')
  }

  stop(): void {
    this.loading.next(false);
    this.message.next('');
  }
}
