<h2 mat-dialog-title *ngIf="mode === FormMode.New">Crear usuario BI</h2>
<h2 mat-dialog-title *ngIf="mode !== FormMode.New">Modificar usuario</h2>

<mat-dialog-content>
  <p class="text">Rellena el formulario para continuar</p>
  
  <app-form-field 
    class="field" 
    label="Usuario" 
    [type]="'text'" 
    [ngClass]="{'disabled': mode !== FormMode.New}"
    [fieldControl]="form.controls['userName'] | asFormControl"
  >
  </app-form-field>

  <app-form-field 
    class="field" 
    label="Contraseña" 
    [type]="'password'" 
    [hideLabel]="false"
    [ngClass]="{'disabled': mode !== FormMode.New}"
    [fieldControl]="form.controls['password'] | asFormControl"
  >
  </app-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button 
    mat-button 
    color="warn" 
    *ngIf="mode !== FormMode.New"
    [disabled]="loading || deleting"
    (click)="delete()"
  >
    Eliminar
    <mat-spinner *ngIf="deleting" [diameter]="20"></mat-spinner>
  </button>

  <button mat-button [disabled]="loading || deleting" (click)="cancel()">Cancelar</button>

  <button 
    mat-button 
    cdkFocusInitial 
    mat-raised-button 
    color="primary" 
    *ngIf="mode === FormMode.New"
    [disabled]="form.invalid || loading || deleting"
    (click)="create()"
  >
    Crear
    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
  </button>

  <button 
    mat-button 
    cdkFocusInitial 
    mat-raised-button 
    color="primary" 
    *ngIf="mode !== FormMode.New"
    [disabled]="form.invalid || loading || deleting"
    (click)="modify()"
  >
    Guardar
    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
  </button>
</mat-dialog-actions>