<div class="container-card"  (click)="navigate()"
    [ngClass]="{
        'greyed': greyed
    }">
    <div class="image-card">
        <!-- Image -->

        <lazy-img 
            [source]="src"
            [alt]="title"
            [contain]="defaultImgSetted || containImage"
            (click)="navigate()"
        >
        </lazy-img>

        <!-- Options -->
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="disabledClick($event)" class="buttonDrop icon-button-small">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let action of actions">
                <div *ngIf="action.hasPermission == undefined || action.hasPermission().length > 0">
                    <ng-container [ngSwitch]="action.id">
                        <!-- Delete -->
                        <ng-container *ngSwitchCase="'delete'">
                            <button  mat-menu-item [title]="action.name" (click)="deletePatient($event)">
                                <mat-icon>{{ action.iconName }}</mat-icon>
                                <span>{{ action.name }}</span>
                            </button>
                        </ng-container>
                        <!-- Print PF -->
                        <ng-container *ngSwitchCase="'pf_print'">
                            <button mat-menu-item [title]="action.name" (click)="printPatientPF($event)">
                                <mat-icon>{{ action.iconName }}</mat-icon>
                                <span>{{ action.name }}</span>
                            </button>
                        </ng-container>
                        <!-- Other actions -->
                        <ng-container *ngSwitchDefault>
                            <button mat-menu-item [title]="action.name"
                                [routerLink]="route + '/' + action.routeLink + '/' + id" [queryParams]="action.queryParam">
                                <mat-icon>{{ action.iconName }}</mat-icon>
                                <span>{{ action.name }}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </mat-menu>
    </div>

    <!-- Data -->
    <div class="data-card">
        <!-- Flags -->
        <div class="flags">
            <ng-container *ngIf="canNavigate">
                <!-- Status -->
                <div class="status-flags danger" 
                    (click)="disabledClick($event)" 
                    *ngIf="colorHeader.includes('ingreso-hospitalario')" 
                    matTooltip="Ingreso hospitalario">
                    <mat-icon>bookmark</mat-icon>
                </div>
                <div class="status-flags warning" 
                    (click)="disabledClick($event)" 
                    *ngIf="colorHeader.includes('autogestion')" 
                    matTooltip="Autogestión">
                    <mat-icon>bookmark</mat-icon>
                </div>
                <div class="status-flags holliday" 
                    (click)="disabledClick($event)" 
                    *ngIf="colorHeader.includes('vacaciones')" 
                    matTooltip="Vacaciones">
                    <mat-icon>bookmark</mat-icon>
                </div>
                <div class="status-flags" 
                    (click)="disabledClick($event)" 
                    *ngIf="colorHeader.includes('grey')" 
                    matTooltip="Baja">
                    <mat-icon>bookmark</mat-icon>
                </div>

                <!-- Flags -->
                <div *ngIf="icons.length || fgp" class="card-flags">
                    <ng-container *ngFor="let icon of icons">
                        <mat-icon 
                            (click)="disabledClick($event)" 
                            *ngIf="icon != undefined" 
                            class="card-flag"
                            [matTooltip]="icon.value" 
                            [ngClass]="icon.id">
                            bookmark
                        </mat-icon>
                    </ng-container>
                    <mat-icon 
                        (click)="disabledClick($event)" 
                        *ngIf="fgp" 
                        class="card-flag fgp"
                        [matTooltip]="'FGP'">
                        bookmark
                    </mat-icon>
                </div>
                <!-- Info button -->
                <button *ngIf="alert"  (click)="disabledClick($event)"  mat-icon-button color="warn" class="card-alert" [matTooltip]="nameTooltip">
                    <mat-icon>info</mat-icon>
                </button>
            </ng-container>
        </div>

        <!-- Number -->
        <ng-content select="[slot=number]"></ng-content>

        <!-- Title -->
        <div class="text1-card" (click)="navigate()">
            {{ title ? title.toUpperCase() : '' }}
        </div>

        <!-- Content -->
        <ng-content select="[slot=content]"></ng-content>

        <!-- Actions (only list mode) -->
        <div class="action-buttons">
            <div *ngFor="let action of actions">
                <div *ngIf="action.hasPermission == undefined || action.hasPermission().length > 0">
                    <ng-container [ngSwitch]="action.id">
                        <!-- Delete -->
                        <ng-container *ngSwitchCase="'delete'">
                            <div *ngIf="typeGlobal !== 1">
                                <button  mat-icon-button color="primary" [title]="action.name" (click)="deletePatient($event)">
                                    <mat-icon>{{ action.iconName }}</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        <!-- Print PF -->
                        <ng-container *ngSwitchCase="'pf_print'">
                            <button mat-icon-button color="primary" [title]="action.name" (click)="printPatientPF($event)">
                               <mat-icon>{{ action.iconName }}</mat-icon>
                            </button>
                        </ng-container>
                        <!-- Other actions -->
                        <ng-container *ngSwitchDefault>
                            <button mat-icon-button color="primary" [title]="action.name"
                                [routerLink]="route + '/' + action.routeLink + '/' + id" [queryParams]="action.queryParam">
                                <mat-icon>{{ action.iconName }}</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
