import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  
  public title: string = '';
  public message: string = '¿Estás seguro?';
  public confirmButtonText: string = 'Aceptar';
  public dismissButtonText: string | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AlertComponent>
  ) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.confirmButtonText = data.confirmButtonText || this.confirmButtonText;
      this.dismissButtonText = data.dismissButtonText || this.dismissButtonText;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onDismissClick(): void {
    this.dialogRef.close(false);
  }
}
