export const reportDataMissingMedicine = {
  data: {
    rpp: '/COROTA/MedicineNoStock',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/MedicineNoStock',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    dn: '',
    fmt: 3,
    pts: 0,
    rp: '',
    rpp: '/COROTA/MedicineNoStock',
  },
  dataRenderReport: {
    dl: false,
    dn: '',
    fmt: 2,
    htmf: false,
    pts: 0,
    rp: '',
    rpp: '/COROTA/MedicineNoStock',
    z: 100,
  },
};

export const reportDataMissingMedicineByPatient = {
  data: {
    dn: '',
    fr: true,
    pts: 1,
    rp: '',
    rpp: '/COROTA/MedicineNoStockByPat',
  },
  dataDownloadPDF: {
    dn: '',
    fmt: 2,
    pts: 0,
    rp: '',
    rpp: '/COROTA/MedicineNoStockByPat',
  },
  dataDownloadExcel: {
    dn: '',
    fmt: 3,
    pts: 0,
    rp: '',
    rpp: '/COROTA/MedicineNoStockByPat',
  },
  dataRenderReport: {
    dl: false,
    dn: '',
    fmt: 2,
    htmf: false,
    pts: 0,
    rp: '',
    rpp: '/COROTA/MedicineNoStockByPat',
    z: 100,
  },
};

export const typesMissingMedicine = [
  { id: 0, label: 'Por medicamentos faltantes ', name: 'missing-medicine' },
  { id: 0, label: 'Por pacientes afectados ', name: 'missing-medicine-patient', },
];
