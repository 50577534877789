import { Injectable } from '@angular/core';
import { CallsService } from '@services/api/calls.service';
import { Observable } from 'rxjs';
import { APIResponseParsed } from '@interfaces/api/api.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private calls: CallsService) {}

  getEntityTarget(route: string): string {
    const mappings = {
      medicines: 'medicines',
      patients: 'patients',
      institutions: 'institutions',
      users: 'users',
    };

    return (
      Object.keys(mappings).find((keyword) => route.includes(keyword)) || ''
    );
  }

  uploadEntityPic(
    file: any,
    id: string,
    type: string
  ): Observable<APIResponseParsed> {
    const data = new FormData();
    data.append('file', file);
    data.append('data[Token]', localStorage.getItem('access_token') ?? '');
    data.append('data[Id]', id);
    data.append('data[Target]', type);

    return this.calls.uploadEntityPic(data);
  }

  formatNumberImage(number: number): string {
    let numberStr = number.toString();
    let leadingZeros = 10 - numberStr.length;
    let formattedNumber = '0'.repeat(leadingZeros) + numberStr;
    return formattedNumber;
  }
}
