import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';

import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    InstitutionSelectAPI,
    InstitutionSelectAPP,
} from '@interfaces/institution/institution.interface';

import { CallsService } from '@services/api/calls.service';
import { RoleManagerService } from '@services/role-manager.service';
import { TokenInfo } from '@interfaces/api/api.interface';
import { decryptToken } from '@constants/funtions-utils';
import { UserInstitution } from '@interfaces/app-user.interface';

@Injectable({
    providedIn: 'root',
})
export class InstitutionService {
    private currentInstitutionId: number | null;
    private currentFarmacyId: number | null;

    public institutionChanged: Subject<boolean> = new Subject();

    public farmacyList: BehaviorSubject<SelectOption[]> = new BehaviorSubject<
        SelectOption[]
    >([]);
    public institutionList: BehaviorSubject<SelectOption[]> = new BehaviorSubject<
        SelectOption[]
    >([]);

    private _fetchingInstitutions: boolean = false;

    constructor(
        private router: Router,
        private calls: CallsService,
        private roleManager: RoleManagerService
    ) {
        const currentInstitution = localStorage.getItem('current_institution');
        if ( currentInstitution ) {
            this.currentInstitutionId = Number(currentInstitution);
        }
    }

    get fetchingInstitutions(): boolean {
        return this._fetchingInstitutions;
    }

    clearStoredData(): void {
        this.currentInstitutionId = null;
        this.currentFarmacyId = null;
        this.farmacyList.next([]);
        this.institutionList.next([]);
    }

    getFarmacies(force = false): void {
        if (!this.farmacyList.getValue().length || force) {
            this.calls.getFarmaciesListAPI().subscribe({
                next: (res) => {
                    const list = res.payload.map((farmacy: any) => {
                        return {
                            label: farmacy.Name,
                            value: farmacy.Id,
                        };
                    });
                    this.farmacyList.next(list);
                },
                error: () => {
                    this.farmacyList.next([]);
                    this.calls.openSnack('Error al obtener las Farmacias');
                },
            });
        }
    }

    getInstitutions(farmacyId?: number, force = false): Promise<void> {        
        return new Promise((resolve, reject) => {
            if (!this.institutionList.getValue().length || force) {
                if (this.roleManager.isPharma()) {
                    const id = farmacyId || this.getCurrentFarmacy();

                    this._fetchingInstitutions = true;
                    this.calls.getInstitutionsListByFarmacyIdAPI(id).subscribe({
                        next: (res) => {

                            const institutions: InstitutionSelectAPI[] = res.payload
                                .filter((institution: any) => institution.id !== 0)
                                .map((institution: any) => {
                                    return {
                                        Id: institution.id,
                                        Name: institution.nam,
                                        ShortName: institution.sn,
                                        GrupoEmpresarial: institution.grupo,
                                        PermitirFGP: institution.PermitirFGP
                                    };
                                });

                            if ( institutions.length ) {
                                // Get the first institution in correct format
                                // this is the same logic as setInstitutions() function
                                const currentInstitution = institutions.map((inst) => {
                                    return {
                                        label: inst.Name,
                                        value: { 
                                            id: inst.Id, 
                                            shortName: inst.ShortName, 
                                            name: inst.Name,
                                            // @ts-ignore: TODO: update API
                                            group: inst.GrupoEmpresarial || inst.GruposEmpresarial || inst.Grupo,
                                            PermitirFGP: inst.PermitirFGP
                                        },
                                    };
                                }).sort((a,b) => a.label.localeCompare(b.label))[0].value;

                                // Current institution has to be set before the institution list
                                this.selectInstitution(currentInstitution);

                                // Set the institutions list
                                this.setInstitutions(institutions);
                            }

                            this._fetchingInstitutions = false;
                            resolve();
                        },
                        error: () => {
                            this.calls.openSnack('Error al obtener los centros');

                            this._fetchingInstitutions = false;
                            reject();
                        },
                    });
                } else {
                    this.calls.getLoggedUserData().subscribe({
                        next: (res) => {
                            this.setInstitutions(res.payload.Institutions);
                            resolve();
                        },
                        error: () => {
                            reject();
                            this.calls.openSnack('Error al obtener los centros');
                        },
                    });
                }
            } else {
                resolve();
            }
        });
    }

    getInstitutionName(): string {
        // if (this.roleManager.isQuantum()) {
            return localStorage.getItem('current_institution_name') + '';
        // } else {
            // return localStorage.getItem('selected_institution_name') + '';
        // }
    }

    getInstitutionDepartments(
        institutionId = this.currentInstitutionId,
        force = false
    ): Observable<SelectOption[]> {
        return this.calls.getInstitutionsDepartmentsAPI(institutionId).pipe(
            map((res) => {
                return res.payload.map((institution: any) => {
                    //TODO: revisar
                    return {
                        label: institution.nam,
                        value: institution.id,
                    };
                });
            })
        );
    }

    getCurrentInstitution(): number {
        // if (this.roleManager.isQuantum()) {
            if (this.currentInstitutionId == undefined) {
                const inst = localStorage.getItem('current_institution');
                if (!inst) {
                    this.router.navigate(['']);
                }
                this.currentInstitutionId = Number(inst);
                return Number(inst);
            } else {
                return this.currentInstitutionId;
            }
        // } else {
        //     return Number(localStorage.getItem('selected_institution'));
        // }
    }

    getCurrentInstitutionBusinessGroup(): number {
        // if (this.roleManager.isQuantum()) {
            const inst = localStorage.getItem('current_institution_business_group');
            return Number(inst);
        // } else {
        //     return Number(localStorage.getItem('selected_institution_business_group'));
        // }
    }
    getCurrentInstitutionAllowFgp(): boolean {
        const inst = localStorage.getItem('current_institution_allow_fgp');
        return inst === 'true';
    }

    getCurrentInstitutionShortName(): string {
        // if (this.roleManager.isQuantum()) {
            if (this.currentInstitutionId != undefined) {
                const inst = localStorage.getItem('current_institution_name');
                if (!inst) {
                    this.router.navigate(['']);
                }
                return inst!;
            } else {
                return '';
            }
        // } else {
        //     return localStorage.getItem('selected_institution_name') + '';
        // }
    }

    setInstitutions(insts: InstitutionSelectAPI[] | null): void {
        if (!insts) return;

        const list = insts.map((inst) => {
            return {
                label: inst.Name,
                value: { 
                    id: inst.Id, 
                    shortName: inst.ShortName, 
                    name: inst.Name,
                    // @ts-ignore: TODO: update API
                    group: inst.GrupoEmpresarial || inst.GruposEmpresarial || inst.Grupo,
                    PermitirFGP: inst.PermitirFGP
                },
            };
        });
        
        list.sort((a,b) => a.label.localeCompare(b.label));

        this.institutionList.next(list);
    }

    getUserInstitution(): UserInstitution | void {
        const token = localStorage.getItem('access_token');
        if (!token) {
            console.log('getUserInstitution');
            this.router.navigate(['']);
        } else {
            const info: TokenInfo = decryptToken(token);
            return {
                name: info.corotaInst,
                id: info.corotaInstId
            };
        }
    }

    setCurrentFarmacy(token: string): void {
        const info: TokenInfo = decryptToken(token);
        this.currentFarmacyId = info.corotaFID;
        localStorage.setItem('current_farmacy', info.corotaFID + '');
    }

    getCurrentFarmacy(): number {
        if (!this.currentFarmacyId) {
            const farmacy = localStorage.getItem('current_farmacy');
            if (!farmacy) {
                console.log('getCurrentFarmacy');
                this.router.navigate(['']);
            }
            return Number(farmacy);
        } else {
            return this.currentFarmacyId;
        }
    }

    async selectInstitution(institution: InstitutionSelectAPP, refreshTokenAndNavigate = true): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.roleManager.isQuantum()) {
                this.calls.selectInstitution(refreshTokenAndNavigate ? parseInt(institution.id) : -1).subscribe({
                    next: (res) => {
                        this.setInstitutionData(institution);
                        if (res)
                            localStorage.setItem('access_token', res.payload.Token);
                        if (refreshTokenAndNavigate) {
                            let shouldRefresh = this.router.url.includes('global-eye/welcome');

                            this.router.navigate(['global-eye/welcome'], {
                                state: {
                                    ignore: true
                                }
                            }).finally(() => {
                                shouldRefresh ? window.location.reload() : null;
                            });
                        }

                        this.institutionChanged.next(true);
                        resolve();
                    },
                    error: () => {
                        reject();
                    },
                });
            } else {
                // isPharma
                this.setInstitutionData(institution);
                this.institutionChanged.next(true);
                resolve();
            }
        });
    }

    selectInstitutionById(id: number): void {
        let institution = this.institutionList.getValue().find((inst) => inst.value.id === id);
        
        if ( institution ) {
            // @ts-ignore
            this.setInstitutionData(institution.value);
            this.institutionChanged.next(true);
        }
    }

    private setInstitutionData(institution: InstitutionSelectAPP) {
        this.currentInstitutionId = parseInt(institution.id);
        localStorage.setItem('current_institution', parseInt(institution.id) + '');
        localStorage.setItem('current_institution_name', institution.shortName + '');
        localStorage.setItem('current_institution_business_group', institution.group + '');
        localStorage.setItem('current_institution_allow_fgp', institution.PermitirFGP + '');
    }
}
