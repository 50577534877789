import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataPatientDiet } from '@constants/reports/report-patient-diet';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    DepartmentPharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-p-diet',
    templateUrl: './report-p-diet.component.html',
    styleUrls: ['./report-p-diet.component.scss'],
})
export class ReportPDietComponent {
    public reportDataPatientDiet = reportDataPatientDiet;
    public showPatientSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showDepartmentSelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public selectOptionsPatientsWithoutFilter: PatientPharma[];
    _selectOptionsPatients: PatientPharma[];
    set selectOptionsPatients(value: PatientPharma[]) {
        this._selectOptionsPatients = value;
        this.selectOptionsPatientsAutocomplete = value.map(v => {
            return {
                label: v.nam,
                value: v.id
            }
        })
    }
    get selectOptionsPatients() {
        return this._selectOptionsPatients;
    }
    selectOptionsPatientsAutocomplete: SelectOption[];
    public selectOptionsDepartment: DepartmentPharma[];


    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    



    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            checkboxControlPatient: [''],
            checkboxControlPatientExitus: [''],
            checkboxControlDepartment: [''],
            selectedDepartment: [''],
            selectedPatient: [''],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Dietas por paciente`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Dietas por paciente`);

        this.typeGlobal = this.roleManager.isPharma();
        
        this.getDataForTheSelects();

    }

    toggleSelectDepartment(): void {
        this.showDepartmentSelect = !this.showDepartmentSelect;
        if (!this.showDepartmentSelect) {
            this.form.patchValue({
                selectedDepartment: '',
            });
        }
    }
    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
        });


        this.reportService
            .getRepParamsEditDataPatDiet(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsDepartment = result.payload.deps;
                this.selectOptionsPatientsWithoutFilter = result.payload.pats;
                this.selectOptionsPatients = this.setFilterExitus(false);
            });
    }

    setFilterExitus(isExitus: boolean): PatientPharma[] {
        // if (isExitus) return this.selectOptionsPatientsWithoutFilter;
        return this.selectOptionsPatientsWithoutFilter.filter(
            (patient) => patient.isEx === isExitus
        );
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { selectedDepartment, selectedPatient, selectedInstitution } = this.getFormData();

            this.setInfoRP(selectedDepartment, selectedPatient, selectedInstitution, true);
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPatientDiet
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    getFormData(): {
        selectedDepartment: string;
        selectedPatient: string;
        selectedInstitution: string
    } {
        const selectedDepartment = this.form.get('selectedDepartment')?.value;
        const selectedPatient = this.form.get('selectedPatient')?.value;

        const {
            selectedInstitution,
        } = this.form.getRawValue();

        return { selectedDepartment, selectedPatient , selectedInstitution};
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { selectedDepartment, selectedPatient , selectedInstitution} = this.getFormData();

            this.setInfoRP(selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handlePrintClick(this.reportDataPatientDiet);
        } else {
             if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { selectedDepartment, selectedPatient , selectedInstitution} = this.getFormData();
            this.setInfoRP(selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handlePdfClick(this.reportDataPatientDiet);
        } else {
             if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { selectedDepartment, selectedPatient, selectedInstitution } = this.getFormData();

            this.setInfoRP(selectedDepartment, selectedPatient, selectedInstitution);
            this.reportService.handleExcelClick(this.reportDataPatientDiet);
        } else {
             if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(selectedDepartment: string, selectedPatient: string, selectedInstitution: any, isPreview: boolean = false): void {
      
        let  currentInstitution;
        if ( this.typeGlobal ) {
            const dataForm = this.form.getRawValue()
            //@ts-ignore
            currentInstitution = dataForm.selectedInstitution.id
        } else {
            currentInstitution = this.institutionService.getCurrentInstitution();
        }

        const rp = JSON.stringify({
            LocaleId: '3082',
            InstitutionId: String(currentInstitution),
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DepartmentId: selectedDepartment ? String(selectedDepartment) : null,
        });

        this.reportDataPatientDiet.data.rp = rp;
        if (isPreview) this.reportDataPatientDiet.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataPatientDiet.dataDownloadPDF.rp = rp;
        this.reportDataPatientDiet.dataDownloadExcel.rp = rp;
        this.reportDataPatientDiet.dataRenderReport.rp = rp;

        const now = moment();
        this.reportDataPatientDiet.dataDownloadPDF.dn = this.reportDataPatientDiet.dataRenderReport.dn =
            `Dietas_por_paciente_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataPatientDiet.dataDownloadExcel.dn = `Dietas_por_paciente_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
