import { Component } from '@angular/core';
import { Router } from '@angular/router';
import packageJson from '@json/package.json';
import { WebSocketsService } from '@services/web-sockets.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public version: string = packageJson.version;
    public termsConditions: string = 'Términos y condiciones';
    public privacyPolicy: string = 'Politica de privacidad';
    public cookiesPolicy: string = 'Politica de cookies';

    public linkTermsConditions: string = 'https://globaleye.es/aviso-legal/';
    public linkPrivacyPolicy: string =
        'https://globaleye.es/politica-de-privacidad/';
    public linkCookiesPolicy: string =
        'https://globaleye.es/politica-de-cookies/';


    hideNotifications = false;
    notifications: Array<{title: string, lines: Array<any>}> = [];


    constructor(
        private router: Router,
        webSockets: WebSocketsService
    ) {
        webSockets.message.subscribe((data) => {
            this.notifications = [];
            Object.keys(data).forEach(key => {
                const value = data[key];
                if (value instanceof Array) {
                    const noti: {title: string, lines: Array<string>} = {title: key, lines: []};
                    value.forEach(line => {
                        noti.lines.push(line);
                    });
                    this.notifications.push(noti);
                }
            });

            if (this.notifications.length > 0) {
                setTimeout(() => {
                    this.hideNotifications = true;
                }, 3000);
            }
        });
    }

    isLogin() {
        return this.router.url == "/login";
    }

    isNotiOnlyTitle(noti: any) {
        return noti.lines[0].display == undefined;
    }

    navigateTo(action: string) {
        this.router.navigate(["/global-eye/welcome"]).then(() => this.router.navigate([action]));
    }
}
