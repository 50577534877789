import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataPatientBoxes } from '@constants/reports/report-patient-boxes';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { PatientPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

@Component({
  selector: 'app-report-patient-boxes',
  templateUrl: './report-patient-boxes.component.html',
  styleUrls: ['./report-patient-boxes.component.scss'],
})
export class ReportPatientBoxesComponent {
  public reportDataPatientBoxes = reportDataPatientBoxes;
  public showPatientSelect: boolean = false;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsPatients: PatientPharma[];
  public selectOptionsInsitutions: SelectOption[];

  public error = 'Debes elegir una institución';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      specificDay: [''],
      selectedPatient: [''],
      selectedInstitution: ['', Validators.required],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setListenerInstitution();
    this.setCurrentDate();
  }

  setCurrentDate(): void {
    this.form.patchValue({ specificDay: moment() });
  }

  setListenerInstitution(): void {
    this.form.get('selectedInstitution')?.valueChanges.subscribe((value) => {
      if (value) {
        this.getPatientsData(value.id);

        this.institutionService.selectInstitutionById(this.form.controls['selectedInstitution'].value.id);
      }
    });
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })

      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }

        this.form.controls['selectedInstitution'].setValue(
          this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
        )
      }
    });
  }

  getPatientsData(institutionId: string): void {
    this.reportService
      .getRepParamsEditDataMedchanges(parseInt(institutionId))
      .subscribe((result) => {
        console.log(result.payload);
        this.selectOptionsPatients = result.payload.pats;
      });
  }

  handlePreviewClick() {
    if (this.form.valid) {
      const { specificDay, selectedPatient, selectedInstitution } =
        this.getFormData();

      this.setInfoRP(specificDay, selectedPatient, selectedInstitution, true);

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataPatientBoxes
      );
    } else {
      this.calls.openSnack(this.error);
    }
  }

  getFormData(): {
    specificDay: string;
    selectedPatient: string;
    selectedInstitution: string;
  } {
    const specificDay = this.dateService.formatDateToString(
      this.form.get('specificDay')?.value
    );

    const { selectedPatient, selectedInstitution } = this.form.getRawValue();

    return {
      specificDay,
      selectedPatient,
      selectedInstitution: selectedInstitution.id,
    };
  }

  handlePrintClick() {
    if (this.form.valid) {
      const { specificDay, selectedPatient, selectedInstitution } =
        this.getFormData();

      this.setInfoRP(specificDay, selectedPatient, selectedInstitution);
      this.reportService.handlePrintClick(this.reportDataPatientBoxes);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handlePdfClick() {
    if (this.form.valid) {
      const { specificDay, selectedPatient, selectedInstitution } =
        this.getFormData();
      this.setInfoRP(specificDay, selectedPatient, selectedInstitution);
      this.reportService.handlePdfClick(this.reportDataPatientBoxes);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handleExcelClick() {
    if (this.form.valid) {
      const { specificDay, selectedPatient, selectedInstitution } =
        this.getFormData();

      this.setInfoRP(specificDay, selectedPatient, selectedInstitution);
      this.reportService.handleExcelClick(this.reportDataPatientBoxes);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  setInfoRP(
    specificDay: string,
    selectedPatient: string,
    selectedInstitution: string,
    isPreview: boolean = false
  ): void {
    const startDateRP =
      this.dateService.transformDateTimeToStartDayMonth00(specificDay);
    const endDateRP = Utils.BUG_FixDateForTimeZone(moment(this.form.get('specificDay')?.value).endOf('day').endOf('month').toDate())?.toISOString().split(".")[0]

    const rp = JSON.stringify({
      LocaleId: '3082',
      DateFrom: startDateRP,
      DateUntil: endDateRP,
      InstitutionId: String(selectedInstitution),
      PatientId: selectedPatient == '' ? null : String(selectedPatient),
    });

    isPreview ? this.reportDataPatientBoxes.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    this.reportDataPatientBoxes.dataDownloadPDF.dn = this.reportDataPatientBoxes.dataRenderReport.dn = `Consumo_de_cajas_medicamentos_paciente_${institution}_del_${moment(startDateRP).format('YYYYMMDD')}_al_${moment(endDateRP).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataPatientBoxes.dataDownloadExcel.dn = `Consumo_de_cajas_medicamentos_paciente_${institution}_del_${moment(startDateRP).format('YYYYMMDD')}_al_${moment(endDateRP).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataPatientBoxes.data.rp = rp;
    this.reportDataPatientBoxes.dataDownloadPDF.rp = rp;
    this.reportDataPatientBoxes.dataDownloadExcel.rp = rp;
    this.reportDataPatientBoxes.dataRenderReport.rp = rp;
  }

  toggleSelectPatient(): void {
    this.showPatientSelect = !this.showPatientSelect;
    if (!this.showPatientSelect) {
      this.form.patchValue({
        selectedPatient: '',
      });
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.form.get('specificDay')?.value;
    ctrlValue.month(normalizedMonth.month());
    this.form.controls['specificDay'].setValue(ctrlValue);
    datepicker.close();
  }
}
