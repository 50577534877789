<ng-container *ngFor="let section of schema; let i = index;">
  <ng-container [ngSwitch]="section.type" *ngIf="visibility[i].visible">
    <div class="section" 
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <ng-container *ngSwitchCase="'custom'">
        <ng-container [ngSwitch]="section.customType">
          <ng-container *ngSwitchCase="'documents-preview-upload'">
            <app-documents-preview-upload
              [fieldControl]="section.fieldControl | asFormControl"
              [readOnly]="section.readOnly">
            </app-documents-preview-upload>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>