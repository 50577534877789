<div id="login-component" class="container-fluid h-100 d-flex flex-column justify-content-between">
    <div class="row"></div>
    <form class="row d-flex justify-content-center flex-grow" [formGroup]="form" (submit)="submit()">
        <div class="col-8 col-md-4">
            <div class="row mb-5">
                <div class="w-100 d-flex justify-content-center">
                    <div class="logo-container"></div>
                </div>
            </div>
            <div class="form-container p-4">
                <div class="row">
                    <h3 class="mb-4">Seleccionar Centro</h3>
                </div>

                <div class="row">
                    <app-form-field 
                        #institutionField
                        type="select" 
                        label="Centro"
                        [fieldControl]="form.controls['institution'] | asFormControl"
                        [options]="institutionOptions"
                        [hideRequiredAsterisk]="true"
                        [filterSelect]="true"
                    >
                    </app-form-field>
                </div>

                <div class="row p-bs">
                    <button mat-raised-button color="primary">Entrar</button>
                </div>
            </div>
        </div>
    </form>
    <app-footer></app-footer>
</div>
