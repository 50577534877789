<ng-container *ngFor="let section of schema; let i = index;">

  <!-- Normal section -->
  <div class="section" 
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">

    <!-- Content -->
    <div class="content">
      <ng-container *ngFor="let child of section.childs">
        <ng-container [ngSwitch]="child.type"  *ngIf="child.visible">

          <ng-container *ngSwitchCase="'info'"></ng-container>

          <!-- Default form field -->
          <ng-container *ngSwitchDefault>
            <app-form-field 
              class="field"
              [class]="child.size || ''"
              [ngClass]="child.customClass"
              [type]="child.type" 
              [label]="child.label" 
              [hideLabel]="child.hideLabel"
              [hintMsg]="child.hintMsg" 
              [placeholder]="child.placeholder"
              [fieldControl]="child.fieldControl | asFormControl" 
              [customFieldData]="child.customFieldData"
              [options]="child.options" 
              [readOnly]="child.readOnly" 
              [min]="child.min" 
              [max]="child.max"
              [inputPattern]="child.inputPattern" 
              [capitalize]="child.capitalize"
              [uppercase]="child.uppercase"
              [errorMsg]="child.errorMsg ?? ''" 
              [group]="child.group" 
              [customStyle]="child.customStyle"
              >
            </app-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>