export const dictionaryBlocks = {
  startDateBlock: 'BlockedFrom',
  endDateBlock: 'BlockedUntil',
  reasonBlock: 'BlockReason',
  derivatedTo: 'DerivatedTo',
  activatedSIRVI: 'IgnoreInPda',
  id: ''
};

export const dictionaryPatternDay = {
  date: 'PatternDay',
};

export const dictionaryDepartments = {
  name: '',
  title: '',
  id: '',
  enableCustomSchedule: '',
  breakfast: 'IntakeTime1',
  lunch: 'IntakeTime2',
  snack: 'IntakeTime3',
  dinner: 'IntakeTime4',
  sleep: 'IntakeTime5',
};
