import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { CallsService } from '@services/api/calls.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';

@Component({
    selector: 'app-ultima-re',
    templateUrl: './ultima-re.component.html',
    styleUrls: ['./ultima-re.component.scss']
})
export class UltimaREComponent {
    @ViewChild("input") input: ElementRef<HTMLInputElement>;
    @ViewChild("editModeButton") button: MatButton;
    @Input() label: string;
    @Input() fieldControl?: FormControl;
    @Input() patientId: number;
    @Input() formChanged: boolean;
    @Input() readOnly?: () => boolean = () => false;
    @Output() save = new EventEmitter<void>();

    lastRe?: string = undefined;
    _editMode = false;
    get editMode(): boolean {
        return this._editMode;
    }
    set editMode(value: boolean) {
        if (!this.editMode && this.formChanged) {
            this.modalService.openModalInfo("Para introducir recetas electrónicas debe guardar primero los datos del paciente utilizando el botón Guardar.", "Guardar datos de paciente");
            return;
        }
        this._editMode = value;
        if (value) {
            this.formControl.setValue(undefined);
            this.formControl.enable();
            this.input.nativeElement.focus();
        }
        else {
            this.formControl.disable();
            this.formControl.setValue(this.lastRe);
        }
    }
    formControl: FormControl = new FormControl({ value: undefined, disabled: !this.editMode }, [Validators.minLength(11)]);

    constructor(
        private calls: CallsService,
        private modalService: ModalService,
        private dialog: MatDialog
    ) { }

    ngAfterViewInit() {
        this.fieldControl?.valueChanges.subscribe((data) => {
            if (!this.editMode)
                this.formControl.setValue(data);
            this.lastRe = data;
        });
    }

    intro(value: string) {
        if (!this.formControl.valid) {
            this.formControl.markAllAsTouched();
            return;
        }
        this.calls.testValidRE(this.patientId, value).subscribe((res) => {
            const lastRE = res.lastRE as string;
            if (lastRE.trim().length > 0) {
                // Error
                const title = 'Agregar receta electrónica';
                let msg = 'La receta electrónica indicada (' + value + ') ';
                let cantSave = true;

                if (lastRE === 'BLANK') {
                    msg += 'está en blanco y no es válida.';
                }
                else if (lastRE === 'DUP1') {
                    msg += 'ya fue introducida con anterioridad para este paciente.';
                }
                else if (lastRE.startsWith('DUP2|')) {
                    var arr = lastRE.split('|');
                    msg += 'ya fue introducida con anterioridad para el paciente "' + arr[1] + '", número ' + arr[2] + ', de la residencia ' + arr[3] + '.';
                    cantSave = false;
                }
                else if (lastRE === 'DUP2') {
                    msg += 'ya fue introducida con anterioridad para otro paciente.';
                    cantSave = false;
                }
                else if (lastRE === 'OVF') {
                    msg += 'tiene un consecutivo muy lejano respecto al último valor de receta electrónica introducido en el sistema.';
                }
                else if (lastRE === 'INVRANGE') {
                    msg += 'tiene un código con un valor fuera del intervalo permitido. El máximo valor para un código es 9223372036854775807.';
                }
                else {
                    msg += 'tiene un consecutivo menor o igual que el de la última receta electrónica del paciente (' + lastRE + ').';
                    cantSave = false;
                }

                if (cantSave) {
                    this.modalService.openModalErrorInfo(msg, title);
                }
                else {
                    this.modalService.openModalCallback(() => {
                        this.saveData(value);
                    }, msg, title);
                }

                this.formControl.setValue(undefined);
            }
            else {
                this.saveData(value);
            }
        });
    }

    saveData(value: string) {
        // this.editMode = false;
        this.formControl.setValue(undefined);
        this.fieldControl?.setValue(value);
        this.save.emit();
    }

    restrictToNumbers(event: KeyboardEvent) {
        if (['Enter', 'Backspace', 'Delete', 'ArrowRight', 'ArrowLeft'].includes(event.key)) return true;
        if (/[0-9]/.test(event.key)) {
            return true;
        }
        return false;
    }

    exitEditMode(event: FocusEvent) {
        if (event.relatedTarget == this.button._elementRef.nativeElement) return;
        if (this.dialog.openDialogs.length > 0) return;
        this.editMode = false;
    }
}
