import { Injectable } from '@angular/core';
import {
    Epigraph,
    EpigraphAPI,
    EpigraphForm,
    EpigraphTextAPI,
} from '@interfaces/medicine/epigraph.interface';
import { CallsService } from '@services/api/calls.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { htmlToText } from "html-to-text";

@Injectable({
    providedIn: 'root',
})
export class EpigraphService {
    constructor(private calls: CallsService) { }

    //interactions
    private _medicineEpigraphText: EpigraphTextAPI[] = [];
    public medicineEpigraphText: BehaviorSubject<EpigraphTextAPI[]> =
        new BehaviorSubject<EpigraphTextAPI[]>([]);

    convertToEpigraph(epigraphs: EpigraphAPI[]): Epigraph[] {
        const res = epigraphs.map((epigraph) => new Epigraph(epigraph));

        return res;
    }

    convertToEpigraphArrayForm(epigraphs: EpigraphAPI[]): EpigraphForm[] {
        const res = epigraphs.map((epigraph) => {
            return {
                epigraph: {
                    title: epigraph.Name,
                    id: epigraph.Id,
                    info: '',
                },
            };
        });

        return res;
    }

    parseTextEpigraph(epigraphText: string) {
        if (epigraphText !== null) {
            return htmlToText(epigraphText, {
                wordwrap: false
            });
            // let plainText = epigraphText.replace(/<[^>]+>/g, '');
            // plainText = plainText
            //     .replace(/&aacute;/g, 'á')
            //     .replace(/&eacute;/g, 'é')
            //     .replace(/&iacute;/g, 'í')
            //     .replace(/&oacute;/g, 'ó')
            //     .replace(/&uacute;/g, 'ú')
            //     .replace(/&ntilde;/g, 'ñ');
            // return plainText;
        }
        return '';
    }

    obtainEpigraphText(medicineId: number, epigraphId: number): Observable<any> {
        return this.calls.getEpigraphText(medicineId, epigraphId);
    }
    transformMedicineInteractiontoApp(epigraph: EpigraphAPI): Epigraph {
        return new Epigraph(epigraph);
    }

    parseEPigraphToFormFormat(): void { }

    getMedicineEpigraphText(idMedicine: number, epigraphId: number): void {
        //FIXME: Arreglar cuando me den feedback de los epigrafes también
        this.calls.getEpigraphText(idMedicine, epigraphId).subscribe({
            next: (res) => {
                this._medicineEpigraphText = res.payload;
                this.medicineEpigraphText.next(this._medicineEpigraphText);
            },
            error: () => {
                this.calls.openSnack(
                    'Error al obtener los epigrafes de los medicamentos'
                );
            },
        });
    }
}
