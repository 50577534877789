import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
    FormBuilderInterface,
    FormConfigInterface,
    FormTableHeaderInterface,
    FormTableRowInterface,
    OptionsObject,
    TabInterface,
} from '@interfaces/dynamic-form.interface';
import {
    BLOCK_REASON_OPTIONS,
    COMPLY_OPTIONS,
    DEREGISTRATION_OPTIONS,
    EDUCATION_OPTIONS,
    GENDER_OPTIONS,
    OCUPATION_OPTIONS,
    PERSON_OPTIONS,
    USAGE_OPTIONS,
} from '@constants/options';
import { Utils } from '../Utils';
import { dniValidator } from '@validators/custom-validators.validators';
import { Permission, PermissionType } from '../enums/PermissionType';

export const tabs: TabInterface[] = [
    { label: 'General', form: 'general', hidden: Utils.hasPermission(Permission.PATIENTS_GENERAL) },
    { label: 'Notas clínicas', form: 'clinicNotes', hidden: Utils.hasPermission(Permission.PATIENTS_CLINICAL) },
    { label: 'Diagnóstico', form: 'diagnostic', hidden: Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC) },
    { label: 'Patrón diario', form: 'dailyPattern', hidden: Utils.hasPermission(Permission.PATIENTS_PATTERN) },
    { label: 'Bloqueos', form: 'blocks', hidden: Utils.hasPermission(Permission.PATIENTS_BLOCKS) },
    { label: 'Prescripciones', form: 'prescriptions', hidden: Utils.hasPermission(Permission.PATIENTS_PRESCRIPTIONS) },
    { label: 'Documentos', form: 'documents', hidden: Utils.hasPermission(Permission.PATIENTS_DOCUMENTS) },
];

const fragilityTests: FormBuilderInterface = {
    date: {
        label: 'Fecha',
        type: 'date',
        value: '',
        validators: [Validators.required],
        size: 'col-10',
        maxDate: Utils.NOW,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    tee: {
        label: 'TEE',
        type: 'number',
        value: undefined,
        validators: [],
        size: 'col-3',
        placeholder: 'Segundos',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    tgug: {
        label: 'TGUG',
        type: 'number',
        value: undefined,
        validators: [],
        size: 'col-3',
        placeholder: 'Segundos',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
};

const haynesTests: FormBuilderInterface = {
    date: {
        label: 'Fecha',
        type: 'date',
        value: '',
        validators: [Validators.required],
        size: 'col-4',
        maxDate: Utils.NOW,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    comply: {
        label: 'Cumple',
        hideLabel: true,
        type: 'radio',
        value: true,
        validators: [],
        size: 'col-7',
        options: COMPLY_OPTIONS,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
};

const handlingTests: FormBuilderInterface = {
    date: {
        label: 'Fecha',
        type: 'date',
        value: '',
        validators: [Validators.required],
        size: 'col-10',
        maxDate: Utils.NOW,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
    },
    usage: {
        label: 'Uso',
        hideLabel: true,
        type: 'radio',
        value: true,
        validators: [],
        size: 'col-12',
        options: USAGE_OPTIONS,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
    },
    person: {
        label: 'Individuo',
        hideLabel: true,
        type: 'radio',
        value: true,
        validators: [],
        size: 'col-12',
        options: PERSON_OPTIONS,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
    },
};

const vitalSigns: FormBuilderInterface = {
    date: {
        label: 'Fecha',
        type: 'date',
        value: '',
        validators: [Validators.required],
        size: 'col-9',
        maxDate: Utils.NOW,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    bloodPresure: {
        type: 'group-label',
        label: 'Tensión arterial',
        childs: {
            max: {
                label: 'Max',
                type: 'number',
                value: undefined,
                validators: [],
                size: 'col-6',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            min: {
                label: 'Min',
                type: 'number',
                value: undefined,
                validators: [],
                size: 'col-6',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
        },
    },
    heartRate: {
        label: 'Frecuencia cardíaca',
        type: 'number',
        value: undefined,
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    bloodO2: {
        label: 'Oxígeno en sangre',
        type: 'number',
        value: undefined,
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    temperature: {
        label: 'Temperatura',
        type: 'number',
        value: undefined,
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
};

const clinicalAnalysys_rows_methabolics: FormTableRowInterface[] = [
    {
        formName: 'glucose',
        label: 'Glucosa',
        units: 'mg/dl',
        normalValue: '70-99',
        value: null,
    },
    {
        formName: 'sodium',
        label: 'Sodio',
        units: 'mEq/L',
        normalValue: '136-144',
        value: null,
    },
    {
        formName: 'potassium',
        label: 'Potasio',
        units: 'mEq/L',
        normalValue: '3,7-5,2',
        value: null,
    },
    {
        formName: 'chloride',
        label: 'Cloruro',
        units: 'mmol/L',
        normalValue: '96-106',
        value: null,
    },
    {
        formName: 'carbonDioxide',
        label: 'Dioxido Carbono',
        units: 'mmol/L',
        normalValue: '20-29',
        value: null,
    },
    {
        formName: 'bun',
        label: 'Nitrogeno ureico en sangre (BUN)',
        units: 'mg/dL',
        normalValue: '7-29',
        value: null,
    },
    {
        formName: 'creatinine',
        label: 'Creatinina',
        units: 'mg/dL',
        normalValue: '0,8-1,4',
        value: null,
    },
    {
        formName: 'calcium',
        label: 'Calcio',
        units: 'mg/dL',
        normalValue: '8,5-10,9',
        value: null,
    },
    {
        formName: 'proteins',
        label: 'Proteinas',
        units: 'g/dL',
        normalValue: '6,3-7,9',
        value: null,
    },
    {
        formName: 'albumin',
        label: 'Albumina',
        units: 'g/dL',
        normalValue: '3,9-5,0',
        value: null,
    },
    {
        formName: 'bilirubin',
        label: 'Bilirrubina',
        units: 'mg/dL',
        normalValue: '0,2-1,9',
        value: null,
    },
    {
        formName: 'alp',
        label: 'Fosfatasa Alcalina (ALP)',
        units: 'IU/L',
        normalValue: '44-147',
        value: null,
    },
];
const clinicalAnalysys_rows_hemogram: FormTableRowInterface[] = [
    {
        formName: 'wbc',
        label: 'Conteo de globulos blancos WBC',
        units: 'glóbulos / mcL',
        normalValue: '4.500-10.000',
        value: null,
    },
    {
        formName: 'rbc',
        label: 'Conteo de globulos rojos RBC',
        units: 'Mill/mcL',
        normalValue: '4,2-5,4',
        value: null,
    },
    {
        formName: 'hemoglobin',
        label: 'Hemoglobina',
        units: 'g/dL',
        normalValue: '12,1-15,1',
        value: null,
    },
    {
        formName: 'hematocrit',
        label: 'Hematocrito',
        units: '%',
        normalValue: '36,1-44,3',
        value: null,
    },
    {
        formName: 'mcv',
        label: 'Volumen globular medio (MCV)',
        units: 'fl',
        normalValue: '80-95',
        value: null,
    },
    {
        formName: 'mch',
        label: 'Hemoglobina Cospuscular MCH',
        units: 'pg',
        normalValue: '27-31',
        value: null,
    },
    {
        formName: 'plateletCount',
        label: 'Conteo plaquetas',
        units: '	Mil/mcL',
        normalValue: '150-400',
        value: null,
    },
];
const clinicalAnalysys_rows_lipidogram: FormTableRowInterface[] = [
    {
        formName: 'totalCholesterol',
        label: 'Colesterol Total',
        units: 'mg/dl',
        normalValue: '<200',
        value: null,
    },
    {
        formName: 'hdl',
        label: 'HDL',
        units: 'mg/dl',
        normalValue: '>40',
        value: null,
    },
    {
        formName: 'ldl',
        label: 'LDL',
        units: 'mg/dl',
        normalValue: '<130',
        value: null,
    },
    {
        formName: 'triglycerides',
        label: 'Trigliceridos',
        units: 'mg/dl',
        normalValue: '<150',
        value: null,
    },
];
const clinicalAnalysys_headers: FormTableHeaderInterface[] = [
    { label: '', fieldName: 'label' },
    { label: 'Unidad', fieldName: 'units' },
    { label: 'Normal', fieldName: 'normalValue' },
    {
        label: 'Valor',
        fieldName: 'value',
        inputSchema: {
            label: 'value',
            hideLabel: true,
            type: 'number',
            // inputPattern: '[0-9,]',
            dynamicError: true,
        },
    },
];

const clinicalAnalysys: FormBuilderInterface = {
    date: {
        label: 'Fecha',
        type: 'date',
        value: '',
        validators: [Validators.required],
        size: 'col-10',
        maxDate: Utils.NOW,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
    },
    tabs: {
        label: '',
        type: 'tabs-group',
        childs: {
            methabolics: {
                label: 'METABOLICAS',
                value: {},
                type: 'custom',
                customType: 'table-form',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    headers: clinicalAnalysys_headers,
                    rows: clinicalAnalysys_rows_methabolics,
                },
            },
            hemogram: {
                label: 'HEMOGRAMA',
                value: {},
                type: 'custom',
                customType: 'table-form',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    headers: clinicalAnalysys_headers,
                    rows: clinicalAnalysys_rows_hemogram,
                },
            },
            lipidgram: {
                label: 'LIPIDOGRAMA',
                value: {},
                type: 'custom',
                customType: 'table-form',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    headers: clinicalAnalysys_headers,
                    rows: clinicalAnalysys_rows_lipidogram,
                },
            },
        },
    },
};

const general = (options: OptionsObject): FormBuilderInterface => {
    return {
        general: {
            type: 'group-label',
            label: 'Datos generales',
            size: 'col-6',
            customClass: ['left'],
            childs: {
                name: {
                    label: 'Nombre',
                    type: 'text',
                    value: '',
                    validators: [Validators.required],
                    size: 'col-4',
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                surname1: {
                    label: 'Primer apellido',
                    type: 'text',
                    value: '',
                    validators: [Validators.required],
                    size: 'col-4',
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                surname2: {
                    label: 'Segundo apellido',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-4',
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                personalId: {
                    label: 'DNI/NIE',
                    type: 'text',
                    value: '',
                    validators: [
                        Validators.pattern('^[0-9]{8,8}[TRWAGMYFPDXBNJZSQVHLCKE' + 'TRWAGMYFPDXBNJZSQVHLCKE'.toLowerCase() + ']$|^(x|y|z|X|Y|Z)[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE' + 'TRWAGMYFPDXBNJZSQVHLCKE'.toLowerCase() + ']$'),
                        Validators.required,
                        dniValidator()
                    ],
                    errorMsg: "No es un DNI/NIE valido",
                    uppercase: true,
                    size: 'col-4',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                educationalLevel: {
                    label: 'Nivel educacional',
                    type: 'select',
                    value: null,
                    validators: [],
                    size: 'col-4',
                    options: EDUCATION_OPTIONS,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                ocupationalLevel: {
                    label: 'Nivel ocupacional',
                    type: 'select',
                    value: null,
                    validators: [],
                    size: 'col-4',
                    options: OCUPATION_OPTIONS,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            }
        },
        birthData: {
            type: 'group-label',
            label: 'Datos de nacimiento',
            size: 'col-6',
            customClass: ['right'],
            childs: {
                place: {
                    label: 'Lugar',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-4',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                city: {
                    label: 'Municipio',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-4',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                province: {
                    label: 'Provincia',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-4',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                country: {
                    label: 'País',
                    type: 'select',
                    value: null,
                    validators: [],
                    size: 'col-4',
                    options: options['countries'],
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                birthday: {
                    label: 'Fecha nacimiento',
                    type: 'date',
                    value: '',
                    validators: [],
                    size: 'col-4',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                gender: {
                    label: 'Género',
                    type: 'radio',
                    value: null,
                    validators: [Validators.required],
                    size: 'col-4',
                    options: GENDER_OPTIONS,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            },
        },
        dates: {
            type: 'group-label',
            label: 'Fechas',
            size: 'col-6',
            customClass: ['left'],
            childs: {
                registrationDate: {
                    label: 'Alta',
                    type: 'date',
                    value: Utils.NOW,
                    validators: [Validators.required],
                    size: 'col-6',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                deregistrationDate: {
                    label: 'Baja',
                    type: 'date',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
              
                deregistrationReason: {
                    label: 'Motivo de baja',
                    type: 'select',
                    value: '',
                    validators: [],
                    size: 'col-12',
                    options: DEREGISTRATION_OPTIONS,
                    conditionalConfig: {
                        mode: 'hide',
                        match: 'exists',
                        filter: 'every',
                        fields: [{ name: 'deregistrationDate', value: undefined }],
                    },
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },

                cancelDeregistrationDate: {
                    label: 'Cancelar baja',
                    type: 'custom',

                    size: 'col-12',
                    customType: 'button-cancel',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            },
        },
        position: {
            type: 'group-label',
            label: 'Ubicación',
            size: 'col-6',
            customClass: ['right'],
            childs: {
                department: {
                    label: 'Departamento',
                    type: 'select',
                    value: null,
                    validators: [Validators.required],
                    size: 'col-6',
                    options: options['departments'],
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                room: {
                    label: 'Habitación',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            },
        },
        contacts: {
            type: 'group-label',
            label: 'Contactos',
            size: 'col-6',
            customClass: ['left'],
            childs: {
                name1: {
                    label: 'Nombre',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                phone1: {
                    label: 'Teléfono',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                name2: {
                    label: 'Nombre',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                phone2: {
                    label: 'Teléfono',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-6',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            },
        },
        medicalData: {
            type: 'group-label',
            label: 'Datos médicos',
            customClass: ['right'],
            size: 'col-6',
            childs: {
                doctorSS: {
                    label: 'Nombre y Apellidos Médico SS',
                    type: 'text',
                    value: '',
                    validators: [],
                    inputPattern: '^[a-zA-Z ñÑá-úà-ù]*$',
                    capitalize: true,
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                checkboxGroup: {
                    type: 'group-checkbox',
                    label: 'Estados',
                    hideLabel: true,
                    childs: {
                        mutuas_muface: {
                            label: 'MUTUAS/MUFACE',
                            type: 'checkbox',
                            value: false,
                            validators: [],
                            size: 'col-6',
                            group: 'seguro',
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                        },
                        private: {
                            label: 'Privado',
                            type: 'checkbox',
                            value: false,
                            validators: [],
                            size: 'col-6',
                            group: 'seguro',
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                        },
                        at_home: {
                            label: 'En casa',
                            type: 'checkbox',
                            value: false,
                            validators: [],
                            size: 'col-6',
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                        },

                        autogetsion: {
                            label: 'Autogestión',
                            type: 'checkbox',
                            value: false,
                            validators: [],
                            size: 'col-6',
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                        },
                    },
                },
            }
        },
        medicalCard: {
            type: 'group-label',
            label: 'Datos Sanitarios',
            customClass: ['left'],
            size: 'col-6',
            childs: {
                cip: {
                    label: 'CIPA',
                    type: 'text',
                    value: '',
                    validators: [
                        Validators.required,
                        Validators.pattern(/^[A-Za-z]{4}\d{10}$/),
                    ],
                    uppercase: true,
                    dynamicError: true,
                    size: 'col-5',
                    errorMsg: "Valor incorrecto. Debe comenzar por 4 letras y continuar con 10 numeros.",
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                lastChronicRE: {
                    label: 'Última RE crónica',
                    type: 'custom',
                    customType: 're-cronica',
                    value: undefined,
                    validators: [],
                    size: 'col-7',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                tsi: {
                    label: 'TSI',
                    type: 'text',
                    value: '',
                    validators: [],
                    size: 'col-5',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                lastSevereRE: {
                    label: 'Última RE aguda',
                    type: 'custom',
                    customType: 're-aguda',
                    value: undefined,
                    validators: [],
                    size: 'col-7',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
                privateInsurance: {
                    label: 'Seguro privado',
                    type: 'text',
                    value: '',
                    validators: [],
                    capitalize: false,
                    size: 'col-5',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.WRITE)
                },
            },
        },
    };
};

const clinicNotes: FormBuilderInterface = {
    observations: {
        type: 'group-label',
        label: 'Observaciones',
        size: 'col-12',
        customType: 'default',
        childs: {
            main: {
                label: '',
                type: 'textarea',
                value: '',
                validators: [],
                size: 'col-12',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
        },
    },
    allergies: {
        type: 'group-label',
        label: 'Alergias',
        size: 'col-12',
        customType: 'allergies',
        childs: {
            main: {
                label: '',
                type: 'select',
                value: '',
                validators: [],
                size: 'col-12',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
        },
    },
    diet: {
        type: 'group-label',
        label: 'Dieta',
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
        customType: 'expansion',
        childs: {
            liquid: {
                label: 'Líquida',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            bland: {
                label: 'Blanda',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            vasal: {
                label: 'Basal',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            hipoprotic: {
                label: 'Hipoproteica',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            hiperproteic: {
                label: 'Hiperproteica',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            hipocaloric: {
                label: 'Hipocalórica',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            hoiposodic: {
                label: 'Hiposódica',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            hipopemiant: {
                label: 'Hipolipemiante',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            diabetic: {
                label: 'Diabética',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            noGluten: {
                label: 'Sin gluten',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            otra: {
                label: 'Otra',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-2',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
            notes: {
                label: 'Observaciones',
                hideLabel: true,
                type: 'textarea',
                customStyle: {
                    'background-color': '#fff',
                    'margin-top': '20px'

                },
                value: '',
                validators: [],
                size: 'col-12',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)
            },
        },
    },
    vitalSigns: {
        type: 'group-label',
        label: 'Constantes vitales',
        customType: 'expansion',
        addable: true,
        childs: {
            results: {
                label: 'Resultados',
                hideLabel: true,
                type: 'custom',
                customType: 'array-form-vitals',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: vitalSigns,
                    calendarConfig: {
                        dateField: 'date',
                        classes: [{ class: 'error', field: 'comply', fieldValue: false }],
                    },
                    sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
                    buttonAtStart: true
                },
            },
        },
    },
    clinicAnalisys: {
        type: 'group-label',
        label: 'Análisis clínicos',
        customType: 'expansion',
        addable: true,
        childs: {
            results: {
                label: '',
                hideLabel: true,
                type: 'custom',
                customType: 'clinical-analysis-form',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: clinicalAnalysys,
                    calendarConfig: {
                        dateField: 'date',
                        classes: [{ class: 'error', field: 'comply', fieldValue: false }],
                    },
                    sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
                    buttonAtStart: true
                },
            },
        },
    },
    dependencyFragilityTests: {
        type: 'group-label',
        label: 'Tests de fragilidad/dependencia',
        customType: 'expansion',
        addable: true,
        childs: {
            results: {
                label: 'Resultados',
                hideLabel: true,
                type: 'custom',
                customType: 'fragility-dependency',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: fragilityTests,
                    calendarConfig: {
                        dateField: 'date',
                        classes: [{ class: 'error', field: 'comply', fieldValue: false }],
                    },
                    sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
                    buttonAtStart: true
                },
            },
        },
    },
    haynesTest: {
        type: 'group-label',
        label: 'Tests de Haynes',
        customType: 'expansion',
        addable: true,
        info: true,
        childs: {
            results: {
                label: 'Resultados',
                hideLabel: true,
                type: 'custom',
                customType: 'test-haynes',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: haynesTests,
                    calendarConfig: {
                        dateField: 'date',
                        classes: [{ class: 'error', field: 'comply', fieldValue: false }],
                    },
                    sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
                    buttonAtStart: true
                },
            },
        },
    },
    handlingHabilityTests: {
        type: 'group-label',
        label: 'Tests de habilidad de manejo',
        customType: 'expansion',
        addable: true,
        info: true,
        childs: {
            results: {
                label: 'Resultados',
                hideLabel: true,
                type: 'custom',
                customType: 'driving-skill',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: handlingTests,
                    calendarConfig: {
                        dateField: 'date',
                        classes: [{ class: 'error', field: 'usage', fieldValue: false }],
                    },
                    sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
                    buttonAtStart: true
                },
            },
        },
    },
    antropometricMeasures: {
        type: 'group-label',
        label: 'Medidas antropométricas',
        customType: 'expansion',
        childs: {
            height: {
                label: 'Altura',
                hintMsg: 'Medida en cm',
                type: 'number',
                customStyle: {
                    'background-color': '#fff',
                },
                value: undefined,
                validators: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                size: 'col-2',
            },
            weight: {
                label: 'Peso',
                hintMsg: 'Peso en Kg',
                type: 'number',
                customStyle: {
                    'background-color': '#fff',
                },
                value: undefined,
                validators: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                size: 'col-2',
            },
            absCircunference: {
                label: 'Circunferencia abdominal',
                hintMsg: 'Medida en cm',
                type: 'number',
                customStyle: {
                    'background-color': '#fff',
                },
                value: undefined,
                validators: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                size: 'col-2',
            },
            kneesHeigth: {
                label: 'Altura rodilla',
                hintMsg: 'Medida en cm',
                customStyle: {
                    'background-color': '#fff',
                },
                type: 'number',
                value: undefined,
                validators: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                size: 'col-2',
            },
            imc: {
                label: 'IMC',
                type: 'custom',
                customType: 'imc',
                value: undefined,
                validators: [],
                size: 'col-4',
                customFieldData: {
                    heigthFieldName: 'height',
                    weightFieldName: 'weight',
                }
            },
        },
    },
    vaccines: {
        type: 'group-label',
        label: 'Vacunas',
        customType: 'vaccines',
        childs: {
            gripe: {
                label: 'Gripe',
                type: 'expand-resume-group',
                childs: {
                    dosis1: {
                        label: 'Dosis 1',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis2: {
                        label: 'Dosis 2',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis3: {
                        label: 'Dosis 3',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis4: {
                        label: 'Dosis 4',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis5: {
                        label: 'Dosis 5',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis6: {
                        label: 'Dosis 6',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis7: {
                        label: 'Dosis 7',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis8: {
                        label: 'Dosis 8',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                },
            },
            neumococo: {
                label: 'Neumococo',
                type: 'expand-resume-group',
                childs: {
                    dosis1: {
                        label: 'Dosis 1',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis2: {
                        label: 'Dosis 2',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis3: {
                        label: 'Dosis 3',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis4: {
                        label: 'Dosis 4',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis5: {
                        label: 'Dosis 5',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis6: {
                        label: 'Dosis 6',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis7: {
                        label: 'Dosis 7',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis8: {
                        label: 'Dosis 8',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                },
            },
            disteriaTetanosTosfeina: {
                label: 'Difteria-Tetanos-Tos ferina',
                type: 'expand-resume-group',
                childs: {
                    dosis1: {
                        label: 'Dosis 1',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis2: {
                        label: 'Dosis 2',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis3: {
                        label: 'Dosis 3',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis4: {
                        label: 'Dosis 4',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis5: {
                        label: 'Dosis 5',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis6: {
                        label: 'Dosis 6',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis7: {
                        label: 'Dosis 7',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis8: {
                        label: 'Dosis 8',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                },
            },
            herpesZoster: {
                label: 'Herpes zóster',
                type: 'expand-resume-group',
                childs: {
                    dosis1: {
                        label: 'Dosis 1',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis2: {
                        label: 'Dosis 2',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis3: {
                        label: 'Dosis 3',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis4: {
                        label: 'Dosis 4',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis5: {
                        label: 'Dosis 5',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis6: {
                        label: 'Dosis 6',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis7: {
                        label: 'Dosis 7',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis8: {
                        label: 'Dosis 8',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                },
            },
            sarsCov2: {
                label: 'SARS-CoV-2',
                type: 'expand-resume-group',
                childs: {
                    dosis1: {
                        label: 'Dosis 1',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis2: {
                        label: 'Dosis 2',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis3: {
                        label: 'Dosis 3',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis4: {
                        label: 'Dosis 4',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis5: {
                        label: 'Dosis 5',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis6: {
                        label: 'Dosis 6',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis7: {
                        label: 'Dosis 7',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                    dosis8: {
                        label: 'Dosis 8',
                        type: 'date',
                        value: '',
                        validators: [],
                        size: 'col-3',
                        maxDate: Utils.NOW,
                        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE),
                        customStyle: {
                            'background-color': '#fff',
                        },
                    },
                },
            },
        },

    },
};
const diagnostic: FormBuilderInterface = {
    diagnogstics: {
        type: 'group-label',
        label: 'Diagnósticos',
        customType: 'default',
        childs: {
            diagnogstics: {
                label: 'diagnogstic-form',
                type: 'custom',
                customType: 'diagnosgtics-form',
                value: [],
                validators: [],
                size: 'col-12',
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC)().includes(PermissionType.WRITE),
            },
        },
    },
    clinicCourse: {
        type: 'group-label',
        label: 'Curso Clínico',
        customType: 'expansion',
        addable: true,
        childs: {
            results: {
                label: 'Curso clínico',
                hideLabel: true,
                type: 'custom',
                customType: 'clinic-course',
                value: [],
                readOnly: () => !Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC)().includes(PermissionType.WRITE),
                customFieldData: {
                    schema: {
                        date: {
                            label: 'Fecha',
                            type: 'date',
                            value: '',
                            validators: [],
                            size: 'col-4',
                            customFieldData: {
                                withDetail: true
                            },
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC)().includes(PermissionType.WRITE)
                        },
                        notes: {
                            label: 'Indicar notas',
                            type: 'textarea',
                            value: '',
                            validators: [Validators.required],
                            size: 'col-7',
                            readOnly: () => !Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC)().includes(PermissionType.WRITE),
                        },
                    },
                    calendarConfig: { dateField: 'date', canSelect: () => true, usedToFilter: true, forceShow: true },
                    sortConfig: {
                        sortField: 'date',
                        sortFunctionName: 'ascending',
                        showSelectedMonthOnly: true,
                    },
                    buttonAtStart: true
                },
            },
        },
    },
};

const dailyPattern: FormBuilderInterface = {
    info1: {
        type: 'info',
        label:
            'En los días marcados aquí la medicación se suministrará en el departamento por defecto del paciente, ignorando cualquier departamento indicado en las tomas de las prescripciones',
    },
    pattern: {
        label: 'Patrón',
        hideLabel: true,
        type: 'custom',
        customType: 'pattern',
        value: [],
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_PATTERN)().includes(PermissionType.WRITE),
        customFieldData: {
            hideAddButton: true,
            schema: {
                date: {
                    label: 'Fecha',
                    hideLabel: true,
                    type: 'custom',
                    customType: 'date-info',
                    value: '',
                    size: 'col-10',
                    readOnly: () => !Utils.hasPermission(Permission.PATIENTS_PATTERN)().includes(PermissionType.WRITE),
                },
            },
            calendarConfig: {
                dateField: 'date',
                canSelect: () => Utils.hasPermission(Permission.PATIENTS_PATTERN)().includes(PermissionType.WRITE),
                forceShow: true,
                hideShowCalendar: true
            },
            sortConfig: { sortField: 'date', sortFunctionName: 'ascending' },
        },
    },
};

const blockInfo: FormBuilderInterface = {
    title: {
        label: 'Bloqueo',
        hideLabel: true,
        type: 'info',
    },

    id: {
        label: 'Id del bloqueo',
        type: 'text',
        hideLabel: true,
        conditionalConfig: {
            mode: 'hide',
            match: 'loose-equals',
            filter: 'some',
            fields: [],
        }
    },

    startDateBlock: {
        label: 'Inicio del bloqueo',
        type: 'date',
        validators: [Validators.required],
        size: 'col-4',
        customType: 'start-date',
        customFieldData: {
            disablePreviousDates: Utils.NOW
        },
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_BLOCKS)().includes(PermissionType.WRITE),
    },

    endDateBlock: {
        label: 'Fecha de desbloqueo',
        type: 'date',
        validators: [],
        size: 'col-4',
        customType: 'end-date',
        conditionalConfig: {
            mode: 'required',
            match: 'loose-equals',
            filter: 'some',
            fields: [{ name: 'reasonBlock', value: 1 }]
        },
        customFieldData: {
            disablePreviousDates: { dateField: "startDateBlock" }
        },
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_BLOCKS)().includes(PermissionType.WRITE),
    },

    reasonBlock: {
        label: 'Razón del bloqueo',
        type: 'select',
        validators: [Validators.required],
        size: 'col-4',
        options: BLOCK_REASON_OPTIONS,
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_BLOCKS)().includes(PermissionType.WRITE),
    },

    derivatedTo: {
        label: 'Derivado a',
        type: 'text',
        validators: [Validators.required],
        size: 'col-12',
        conditionalConfig: {
            mode: 'hide',
            match: 'loose-equals',
            filter: 'some',
            fields: [{ name: 'reasonBlock', value: 3 }],
        },
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_BLOCKS)().includes(PermissionType.WRITE),
    },

    // activatedSIRVI: {
    //     label: 'Activo en SIRVI',
    //     type: 'checkbox',
    //     value: false,
    //     validators: [],
    //     size: 'col-12',
    // },
};

const block: FormBuilderInterface = {
    block: {
        type: 'group-expansion',
        customFieldData: { title: '', newExpanded: true },
        label: '',
        childs: blockInfo,
    },
};

const blocks: FormBuilderInterface = {
    info1: {
        type: 'info',
        label: '¡No utilice un bloqueo para dar EXITUS al paciente!'
    },
    blocks: {
        type: 'custom',
        customType: 'list-form',
        label: 'array',
        customFieldData: { schema: block },
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_BLOCKS)().includes(PermissionType.WRITE)
    },
};

const prescriptions: FormBuilderInterface = {
    prescriptions: {
        type: 'custom',
        customType: 'prescriptions-component',
        label: 'Prescripciones',
        value: [],
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_PRESCRIPTIONS)().includes(PermissionType.WRITE)
    },
};

const documents: FormBuilderInterface = {
    documents: {
        type: 'custom',
        customType: 'documents-preview-upload',
        label: 'Documentos',
        value: [],
        readOnly: () => !Utils.hasPermission(Permission.PATIENTS_DOCUMENTS)().includes(PermissionType.WRITE)
    },
};

export const formsConfig = (options: OptionsObject): FormConfigInterface => {
    return {
        general: general(options),
        clinicNotes: clinicNotes,
        diagnostic,
        dailyPattern,
        blocks,
        prescriptions,
        documents,
    };
};
