//TODO: Averiguar que significan los diferentes campos que devuelve la API

//response of the API (PreviewReport and RenderReport)
export interface ReportAPI {
  fn: string;
  fp: string;
  sz: number;
  dct: string;
  pq: number;
}

//call in the endpoints fp/fn (important, reformat the fp )
export class Report {
  fileName: string; //name of the html
  filePath: string;
  size: number;
  dct: string;
  pq: number;

  constructor(input: ReportAPI) {
    this.fileName = input.fn;
    this.filePath = input.fp;
    this.size = input.sz;
    this.dct = input.dct;
    this.pq = input.pq;
  }
}

export interface PharmacologicPlan {
  pats: PatientPharma[];
  deps: DepartmentPharma[];
}

export interface PatientPharma {
  id: number;
  num: string;
  nam: string;
  isEx: boolean;
}

export interface MedPharma {
  Id: number;
  Mask: number;
  Description: string;
  meds: MedSubtypePharma[];
}

export interface MedSubtypePharma {
  DrugId: string;
  Name: string;
}

export interface DepartmentPharma {
  id: number;
  nam: string;
}

//report medchanges

export interface MedChangesAPI {
  users: User[];
  pats: PatientPharma[];
}
export interface User {
  id: number;
  nam: string;
}

export interface AlertSCP {
  id: number;
  nam: string;
}

// report incotinence
export interface IncontinenceAPI {
  diapers: Diaper[];
  brands: Brand[];
  institutionList: InstitutionList[];
  AlbaranEmpresas: CompanyDeliveryNote[];
  HasDiaperEnabled: boolean;
}

export interface Diaper {
  id: number;
  did: string;
  nam: string;
}
export interface Brand {
  id: number;
  nam: string;
}

export interface InstitutionList {
  id: number;
  nam: string;
  sn: string;
}

export interface CompanyDeliveryNote {
  Id: string;
  Description: string;
}

//call render report with these
export interface RenderReportAPI {
  rpp: string;
  pts: number;
  z: number;
  htmf: boolean;
  fmt: number;
  dl: boolean;
  dn: string;
  rp: string;
}

export interface PreviewReportAPI {
  rpp: string; //path in backend (review constant)
  pts: number; //always 1 (no explication why)
  fr: boolean; //always true (no explication why)
  dn: string; // Name of the html, important: name_{0}.html
  rp: string; //filters etc
}

export interface DonwloadReportAPI {
  rpp: string;
  pts: number;
  fmt: number;
  dn: string;
  rp: string;
}

export interface ThirdPartyDrugPetition {
  DrugIdTP: string;
}

export interface ThirdPartyDrugResponse {
  DrugIdTP: string;
  DrugIdMaster: string;
  DrugIdInvc: string;
  DrugIdMap: DrugIdMap[];
}

export interface DrugIdMap {
  DrugIdMap: string;
  DrugIdMaster: string;
  DrugIdInvc: string;
  Name: string;
  Obsolete: string;
  RO: string;
  NoPrescribe: string;
  NoBlister: string;
}

export interface ReportRA {
  Id: string;
  InstitutionId: number;
  ShortName: string;
  ReportDate: string;
  CreationDate: string;
  ShowDate: string;
  ReviewDate: any;
  ReviewUser: any;
  PrintDate: string;
  PrintUser: string;
  OrderDate: string;
  OrderUser: any;
  Ready: boolean;
  NoShow: boolean;
  $$hashKey?: any;
}

export interface ConfigRA {
  MedicineId: number;
  DrugId: string;
  Name: string;
  RaStatus: number;
  MedTranslate?: number;
  IsModified: boolean;
  IsLinked: boolean;
}
export interface ReportForecastDates {
  lastDates: string[];
  nextDate: string;
}
