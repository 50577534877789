export const reportDataRACount = {
  data: {
    rpp: '/COROTA/RaCount',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
};

export const reportDataRA = {
  data: {
    rpp: '/COROTA/RA',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/RA',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/RA',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/RA',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const RA_OPTIONS = [
  { label: 'No modificado', value: 0, className: 'no-modified' },
  { label: 'Stop', value: 1, className: 'stop' },
  { label: 'Cambiar por', value: 2, className: 'modified' },
];
