<ng-container *ngIf="fieldControl != undefined">

    <mat-form-field 
        #matFormField 
        *ngIf="!nonFormFields.includes(type)" 
        appearance="outline" 
        class="w-100" 
        [ngClass]="{
            'boxWhite': customStyleBox === true,
            'textarea': type === 'textarea',
            'with-hint': hintMsg || label == 'TGUG',
            'with-fast-buttons': numberFieldFastButtons,
            'disabled': fieldControl.disabled,
            'readOnly': readOnly ? readOnly() : false,
            'with-chips': selectWithChips
        }" 
        data-raro="{customStyle}" 
        [ngStyle]="customStyle" 
        [subscriptSizing]="dynamicError ? 'dynamic' : 'fixed'"
        floatLabel="always">

        <div *ngIf="!hideLabel" class="label">{{label}} {{isRequired() && !hideRequiredAsterisk ? '*' : ''}}</div>

        <input *ngIf="regularInputs.includes(type)" matInput [type]="type" [placeholder]="placeholder ?? ''"
            [formControl]="fieldControl" [readonly]="readOnly ? readOnly() : false" [min]="min" [max]="max"
            [minlength]="min ? min : ''" [maxlength]="max ? max : ''" (keydown)="keyPressHandler($event)"
            (keyup)="keyUpHandler($event)"
            [style.textTransform]="uppercase ? 'uppercase' : (capitalize ? 'capitalize' : '')">
        
        <mat-button-toggle-group 
            class="fast-buttons" 
            *ngIf="numberFieldFastButtons"
            (change)="onToggleFastButton($event)"
        >
            <mat-button-toggle [value]="1"><mat-icon>remove</mat-icon></mat-button-toggle>
            <mat-button-toggle [value]="2"><mat-icon>add</mat-icon></mat-button-toggle>
        </mat-button-toggle-group>

        <ng-container *ngIf="dateInputs.includes(type)">
            <ng-container *ngIf="disablePreviousDate; else elseTemplate">
                <input matInput 
                    [formControl]="fieldControl" 
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="disablePreviousDates" 
                    [readonly]="readOnly ? readOnly() : false">
            </ng-container>
            <ng-template #elseTemplate>
                <input matInput 
                    [formControl]="fieldControl" 
                    [matDatepicker]="picker" 
                    [min]="min || minDate" 
                    [max]="max || maxDate"
                    [readonly]="readOnly ? readOnly() : false">
            </ng-template>
        </ng-container>

        <ng-container *ngIf="label == 'TGUG' || label == 'TEE'" matSuffix>
            <mat-icon 
                class="info-icon"
                (click)="isOpen = !isOpen" 
                type="button" 
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin">
                info
            </mat-icon>

            <ng-template *ngIf="label == 'TGUG'" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayHasBackdrop]="true"
                (backdropClick)="isOpen = false" (detach)="isOpen = false">
                <div class="popover">
                    <div style="width: 100%;">
                        <h3 style="margin-bottom: 20px;">Test get up and go (TGUG)</h3>
                        <div style="display: flex;">
                            <div style="padding-right: 15px;width: 66.66666667%;">
                                <ol type="a" style="padding-left: 15px;">
                                    <li>El paciente se sienta en una silla con brazos</li>
                                    <li>Se le indica que se levante (inicio de prueba y de cronometraje), camina 3
                                        metros y
                                        vuelve a sentarse en la silla inicial (fin de cronometraje)</li>
                                </ol>
                                <p>
                                    <strong>Interpretación:<br />&lt;= 20 segundos: Normal<br />&gt; 20 segundos: Riesgo
                                        de
                                        caída aumentado.</strong>
                                </p>
                            </div>
                            <div style="width: 33.33333333%;">
                                <img width="130"
                                    src="/assets/pics/TGUG.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template *ngIf="label == 'TEE'" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayHasBackdrop]="true"
                (backdropClick)="isOpen = false" (detach)="isOpen = false">
                <div class="popover">
                    <div style="width: 100%;">
                        <h3 style="margin-bottom: 20px;">Test de Equilibrio Estático (TEE)</h3>
                        <div style="display: flex;">
                            <div style="width: 66.66666667%;">
                                <p style="margin-bottom: 10px;">
                                    Según se expresa en la figura, la persona debe permanecer sobre un solo pie, el
                                    dominante, descalza,
                                    mientras mantiene los brazos extendidos. El pie que se alza debe de estar sobre el
                                    suelo
                                    sin apoyarse.
                                    El ejercicio termina después de que el sujeto ha permanecido en esa postura.
                                </p>
                                <p style="margin-bottom: 10px;">
                                    <strong>Puntuación: segundos con el pie levantado (máxima puntuación 60
                                        segundos)</strong>
                                </p>
                            </div>
                            <div style="width: 33.33333333%;">
                                <img width="120" src="/assets/pics/TEE.jpg"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <mat-datepicker-toggle *ngIf="dateInputs.includes(type) && (readOnly ? !readOnly() : true)" matIconSuffix
            [for]="picker">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <textarea *ngIf="type === 'textarea'" cdkTextareaAutosize matInput [placeholder]="placeholder ?? ''"
            [formControl]="fieldControl " [readonly]="readOnly ? readOnly() : false"></textarea>

        <ng-container *ngIf="type === 'select'">
            <mat-select #select 
                *ngIf="readOnly ? !readOnly() : true" 
                [formControl]="fieldControl" 
                [multiple]="multipleSelect"
                (openedChange)="onSelectOpen($event)"
                (selectionChange)="onSelectionChange($event)">

                <div class="select-features">
                    <div class="select-all" *ngIf="multipleSelect && allowSelectAll">
                        <mat-checkbox
                            [(ngModel)]="allSelected"
                            (click)="toggleAllSelection()"
                            [matTooltip]="filterSelect ? 'Seleccionar todo' : ''">
                            <span *ngIf="!filterSelect">Marcar todo</span>
                        </mat-checkbox>
                    </div>

                    <div class="select-all" *ngIf="multipleSelect && deselectAllShortcut">
                        <button matTooltip="Deseleccionar todos">
                            <mat-icon 
                                (click)="deselectAllSelection()">
                                playlist_remove
                            </mat-icon>
                        </button>
                    </div>

                    <div class="select-filter" *ngIf="filterSelect">
                        <input #selectFilter type="text" placeholder="Filtrar" [formControl]="filterSelectFormControl" />
                    </div>
                </div>

                <mat-select-trigger *ngIf="selectWithChips">
                    <mat-chip-set>
                        <mat-chip 
                            *ngFor="let option of getSelectedOptions()"
                            [removable]="true"
                            (removed)="onChipRemoved(option)">
                            {{ option }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-set>
                </mat-select-trigger>
                
                <mat-option 
                    *ngFor="let option of filteredSelectOptions" 
                    [value]="option.value" 
                    (click)="onOptionClick(option)"
                    [style.display]="option.hidden ? 'none' : 'flex'"
                    [ngStyle]="option.customStyle">
                    {{ option.label }}
                </mat-option>
            </mat-select>
            <input 
                *ngIf="readOnly ? readOnly() : false" 
                type="text" 
                matInput 
                [value]="getValueFromOptions()"
                readonly 
            />
        </ng-container>

        <ng-container *ngIf="type === 'select-group'">
            <mat-select 
                *ngIf="readOnly ? !readOnly() : true" 
                matNativeControl 
                multiple 
                [formControl]="fieldControl" 
                [compareWith]="comparer">
              <mat-optgroup *ngFor="let group of optionsGroup" [label]="group.name">
                <mat-option *ngFor="let option of group.filter" [value]="option">
                  {{ option.value }}
                </mat-option>
              </mat-optgroup>
            </mat-select>

            <input *ngIf="readOnly ? readOnly() : false" type="text" matInput [value]="getValueFromOptions()"
                readonly />
        </ng-container>

        <ng-container *ngIf="type === 'autocomplete'">
            <input matInput type="text" [formControl]="autocompleteControl" [matAutocomplete]="auto" [readonly]="readOnly ? readOnly() : false">
            <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="hideSingleSelectionIndicator">
                <mat-option 
                    *ngFor="let option of filteredOptions; trackBy: autocompleteTrackByFn" 
                    [value]="option.label"
                    [ngStyle]="option.customStyle">
                    {{ option.label }}

                    <div class="other-data" *ngIf="option.otherData" [innerHTML]="option.otherData"></div>
                </mat-option>
            </mat-autocomplete>
        </ng-container>

        <button *ngIf="type === 'autocomplete' && !hideCloseButton && (readOnly ? !readOnly() : true)" mat-icon-button matSuffix [disabled]="autocompleteControl.disabled"
            (click)="clearAutocompleteValue()">
            <mat-icon>close</mat-icon>
        </button>

        <mat-hint *ngIf="hintMsg"> {{ hintMsg }}</mat-hint>
        <mat-hint *ngIf="label == 'TGUG' && !hintMsg" [style.color]="isTGUGCorrect() ? 'green' : 'red'">
            {{getTGUGHint()}}
        </mat-hint>
        <mat-error *ngIf="fieldControl.errors">{{ fieldControl | getErrorMessage: errorMsg }}</mat-error>
    </mat-form-field>

    <ng-container [ngSwitch]="type">
        <div 
            *ngSwitchCase="'radio'" 
            class="w-100 mb-3 radio-form-field" 
            [ngClass]="{'invalid': fieldControl.invalid && isRequired()}"
            [ngStyle]="customStyle">

            <div *ngIf="!hideLabel" id="radio-mat-label" class="label gender">{{label}} {{isRequired() && !hideRequiredAsterisk ? '*' : ''}}</div>
            <mat-radio-group aria-labelledby="radio-label" [formControl]="fieldControl">

                <!--multiple options radio-->
                <!-- <div *ngIf="options !== undefined && options.length > 4">
                    <mat-radio-button *ngFor="let option of options" class="me-4" (change)="radioChange($event)"
                        [class.override-style]="option.customStyle != undefined" [ngStyle]="option.customStyle"
                        [value]="option.value" [disabled]="readOnly ? readOnly() : false">{{ option.label
                        }}</mat-radio-button>
                </div> -->


                <div class="container-mode" *ngIf="options !== undefined"
                    [style.pointerEvents]="(readOnly ? readOnly() : false) || fieldControl.disabled ? 'none' : ''">
                    <div class="status-mode">
                        <div class="capsule">
                            <div *ngIf="selectorPosition !== -1 && fieldControl.value !== null && fieldControl.value !== undefined" class="active"
                                [ngStyle]="{
                                    'width': 'calc((100% - (14px * ' + options.length + ')) / ' + options.length + ')',
                                    'transform': 'translateX(calc((100% * ' + selectorPosition + ') + (14px * ' + selectorPosition + ')))'
                                }" 
                                [style]="getOptionStyleSelected(options, fieldControl.value)">
                            </div>
                            <div class="container-switch">
                                <div class="item-switch" [ngStyle]="{ 'width': 'calc(100% / ' + options.length + ')'}"
                                    *ngFor="let option of options; let i = index"
                                    [ngClass]="{'selected': fieldControl.value == option.value }"
                                    (click)="radioChangeOption(option.value, i)">{{option.label}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--capsule two options-->
                <!-- <div class="container-mode" *ngIf="options !== undefined && options.length == 2">
          <div class="status-mode">
            <div class="capsule" >
              <div
                [ngClass]="{'right':  fieldControl.value ==  options[1].value  }"
                [ngStyle]="getOptionStyleSelected(options, fieldControl.value)"
                class="active">
              </div>
              <div class="container-switch"  >
                <div class="item-switch"
                [ngStyle]="{ 'width': 'calc((100% - (100px)) / ' +options.length + ')'}"
                 *ngFor="let option of options; let i = index"
                [ngClass]="{'selected': fieldControl.value == option.value }"
                (click)="radioChangeOption(option.value, i)">{{option.label}}</div>
              </div>
            </div>
          </div>
        </div> -->

            </mat-radio-group>
        </div>

        <div *ngSwitchCase="'checkbox'" class="w-100 mb-2 radioBottom d-flex radio-form-field">
            <!-- <mat-checkbox [matTooltip]="tooltip || ''" [formControl]="fieldControl" (change)="stateChange($event)">
        {{ label }}
      </mat-checkbox> -->
            <mat-checkbox *ngIf="readOnly ? !readOnly() : true" [matTooltip]="tooltip || ''"
                [formControl]="fieldControl" (change)="stateChange($event)">
                {{ label }}
            </mat-checkbox>
            <mat-checkbox *ngIf="readOnly ? readOnly() : false" [matTooltip]="tooltip || ''"
                [checked]="fieldControl.value" disabled>
                {{ label }}
            </mat-checkbox>
        </div>

        <div *ngSwitchCase="'calendar'" class="w-100 mb-2">
            <mat-label *ngIf="!hideLabel" id="radio-mat-label" class="me-4">
                {{label}}
            </mat-label>
            <mat-calendar (selectedChange)="selectDate($event)" [(selected)]="calendarValue"></mat-calendar>
        </div>

        <div *ngSwitchCase="'info'" class="info">
            {{ fieldControl.value }}
        </div>
    </ng-container>
</ng-container>
