import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
    formsConfig,
    tabs,
} from '@constants/medicines/forms/medicine-edit.config';
import { TabInterface } from '@interfaces/dynamic-form.interface';
import { EpigraphForm } from '@interfaces/medicine/epigraph.interface';
import {
    MedicineAPI,
    MedicineAdminRoute,
    MedicineEdit,
    MedicineEditEmpty,
    MedicineEditLinqData,
    MedicineForm,
    MedicineInteraction,
    MedicineInteractionText,
} from '@interfaces/medicine/medicine.interface';
import { FormInterface } from '@interfaces/patient/patient-edit.interface';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { CallsService } from '@services/api/calls.service';
import { FormService } from '@services/form.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { MedicinesService } from '@services/medicines.service';
import { EpigraphService } from '@services/medicines/epigraph.service';
import { InteractionService } from '@services/medicines/interaction.service';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { FormDetailAction } from '@interfaces/form/form-detail';
import { MainSection } from '@json/src/app/enums/section';
import { RoleManagerService } from '@services/role-manager.service';
import { ColorLegendItem } from '@interfaces/color-legend-item.interface';
import { ColorLegendItemType } from '@enums/color-legend-item-type';

@Component({
    selector: 'app-medicine-edit',
    templateUrl: './medicine-edit.component.html',
    styleUrls: ['./medicine-edit.component.scss'],
})
export class MedicineEditComponent implements OnInit, OnDestroy {
    Utils = Utils;
    Permission = Permission;
    PermissionType = PermissionType;
    MainSection = MainSection;

    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    public isEdit: boolean = true;

    public forms: FormInterface = {};
    public tabs: TabInterface[] = tabs;
    private formsConfig = formsConfig;

    private subs: Subscription[] = [];
    private currentMedicineSub: Subscription;

    //medicines
    public medicineAdminRouteList: MedicineAdminRoute[];
    public medicineInteractionList: MedicineInteraction[];
    public medicineInteractionListText: MedicineInteractionText;
    public medicineInfo: MedicineForm;
    public medicines: MedicineForm;
    public medicineId: string | null;

    public tabQueryParam: string | null;
    public selectedTabIndex: number;

    public formChanged: boolean = false;

    public currentIdTextInteraction: string;

    public epigraphs: any;

    public formReady: boolean = false;

    public collapsedProfile: boolean = false;

    onMedicineUpdated: (medicine: MedicineForm, medicineId: string | null) => void;

    @ViewChildren("options") options: QueryList<MatOption>;
    farmacias: Array<MedicineEditLinqData> = [];
    medicineList: Array<MedicineAPI>;
    tiposInventario = [
        {
            value: 0,
            label: 'En inventario'
        },
        {
            value: 1,
            label: 'Sin inventario'
        },
        {
            value: 2,
            label: 'Suministro limitado'
        }
    ];

    public onDestroy: () => void;
    public onMedicineId: (patId: string | null) => void;

    colorLegendItems: ColorLegendItem[] = [
        {
            type: ColorLegendItemType.Image,
            description: 'Hospitalario',
            data: {
                src: 'assets/images/gsp/diagnostico-hospitalario-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Estupefaciente',
            data: {
                src: 'assets/images/gsp/estupefaciente-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Psicótropo',
            data: {
                src: 'assets/images/gsp/psicotropo-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'MARC',
            data: {
                src: 'assets/images/gsp/marc-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 1',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo1-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 2',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo2-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 3',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo3-32.png',
            }
        },
    ]

    constructor(
        private route: ActivatedRoute,
        private medicineService: MedicinesService,
        private interactionService: InteractionService,
        private epigraphService: EpigraphService,
        private formService: FormService,
        private sanitizer: DomSanitizer,
        private call: CallsService,
        private institutionService: InstitutionService,
        private loadingService: LoadingService,
        private cdr: ChangeDetectorRef,
        private location: Location,
        private roleManager: RoleManagerService, 
    ) { }

    async ngOnInit(): Promise<void> {
        this.medicineService.clearCurrentMedicine();
        this.formReady = false;
        this.loadingService.start('Cargando Formulario...');
        await this.getMedicineList();
        this.getMedicineId();
        this.getSelectedTab();
        this.getMedicineAdministrationOptions();
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
        if (this.onDestroy) this.onDestroy();
    }

    getMedicineInfo(): void {
        this.medicineService.currentMedicine
        .pipe(
            skipWhile(medicine => medicine instanceof MedicineEditEmpty),
            take(1)
        )
        .subscribe((medicine) => {
            if (medicine.med.LinqData != undefined) {
                const farmatics = medicine.med.LinqData.farmaticLinks;
                this.farmacias = farmatics;
                this.farmacias.forEach(f => {
                    if (f.NoStock == 0) {
                        f.NoStockReplace = null;
                    }
                })
            }

            this.medicines =
                this.medicineService.transformMedicineEditToForm(medicine);
            if (this.medicines.general.name !== '') {
                this.initForms();
                this.setMedicineAdminRoute(medicine);
                this.setMedicineFormInfo();
            }
        })

        if (this.medicineId !== null) {
            this.medicineService.getMedicineEdit(parseInt(this.medicineId));
        }
    }

    getMedicineId(): void {
        this.route.paramMap
        .pipe(take(1))
        .subscribe((params: ParamMap) => {
            this.medicineId = params.get('id');
            if (this.onMedicineId) this.onMedicineId(this.medicineId);
            this.isEdit = !!this.medicineId;

            if (this.medicineId) {
                this.getMedicineInfo();
            }
        })

        this.loadingService.start('Cargando Formulario...');
    }

    getSelectedTab(): void {
        this.route.queryParamMap.subscribe((query) => {
            this.tabQueryParam = query.get('tab');
            this.selectedTabIndex = this.tabQueryParam
                ? parseInt(this.tabQueryParam)
                : 0;
        });
    }

    setMedicineAdminRoute(medicine: MedicineEdit): void {
        //get the medicines admin routes & set the admin route option from the backend
        if (this.medicineAdminRouteList) {
            let optionsAdminRoute = this.medicineAdminRouteList.map((admin) => {
                return this.medicineService.transformMedicineIAdminRouteToSelectOption(
                    admin
                );
            });
            if ( this.forms['general'].schema[0].childs ) {
                this.forms['general'].schema[0].childs[6].options = optionsAdminRoute;
                if (medicine.med.admr) {
                    this.medicines.general.admin = this.medicineService.getSelectedAdminRoute(
                        this.medicineAdminRouteList,
                        medicine.med.admr
                    );
                }
            }
        }
    }

    setMedicineFormInfo(): void {
        this.medicineInfo = this.medicines;
        this.setGeneralFormInfo(this.medicineInfo);
        this.setNotesFormInfo(this.medicineInfo);
        this.setInteractionFormInfo(this.medicineInfo);
        if (this.medicineInfo.epigraphs.epigraphs.length > 0) {
            this.setEpigraphsFormInfo(this.medicineInfo);
        }

        if ( this.roleManager.isPharma() ) {
            this.setRobotFormInfo(this.medicineInfo);

            // If hospital use is set, no embistable should be true
            if ( this.medicineInfo.otherData.hospitalUse ) {
                // @ts-ignore
                this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].disable();
            } else {
                // @ts-ignore
                this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].enable();
            }
        }

        this.formChanged = false;
        this.formReady = true;
        this.loadingService.stop();
    }

    setRobotFormInfo(medicineInfo: MedicineForm): void {
        this.forms['robot'].form.patchValue(medicineInfo.robot);
        this.setRobotListener();
    }

    setRobotListener() {
        this.forms['robot'].form.valueChanges.subscribe(() => {
            this.formChanged = true;
        });
    }

    setGeneralFormInfo(medicineInfo: MedicineForm): void {
        this.forms['general'].form.patchValue(medicineInfo);
        this.setGeneralListener();
    }

    setEpigraphsFormInfo(medicineInfo: MedicineForm): void {
        this.epigraphs = this.epigraphService.convertToEpigraphArrayForm(
            medicineInfo.epigraphs.epigraphs
        );
        this.forms['epigraphs'].form.patchValue({ epigraphs: this.epigraphs });
        this.createArrayOfEpigraphs();
    }

    createArrayOfEpigraphs(): void {
        const completedEpigraphs: EpigraphForm[] = [];

        for (const epigraph of this.epigraphs) {
            if (this.medicineId) {
                this.epigraphService
                    .obtainEpigraphText(+this.medicineId, epigraph.epigraph.id)
                    .subscribe(
                        (reponse) => {
                            const parseText = this.epigraphService.parseTextEpigraph(
                                reponse.payload[0].TEXTO
                            );

                            const completedEpigraph = {
                                epigraph: {
                                    id: epigraph.epigraph.id,
                                    title: epigraph.epigraph.title,
                                    info: parseText,
                                },
                            };
                            completedEpigraphs.push(completedEpigraph);

                            // Check if all epigraphs have information
                            if (completedEpigraphs.length === this.epigraphs.length) {
                                completedEpigraphs.sort((a, b) => a.epigraph.title.localeCompare(b.epigraph.title));
                                this.epigraphs = completedEpigraphs;
                                this.forms['epigraphs'].form.patchValue({
                                    epigraphs: this.epigraphs,
                                });
                            }
                        },
                        (error) => {
                            console.error('Error al obtener el texto:', error);
                        }
                    );
            }
        }
    }

    setNotesFormInfo(medicineInfo: MedicineForm): void {
        this.forms['notes'].form.patchValue(medicineInfo.notes);
        this.setNotesListener();
    }

    setInteractionFormInfo(medicineInfo: MedicineForm): void {
        this.forms['interactions'].form
            .get('interactions')
            ?.patchValue(medicineInfo.interactions);

        if ( this.forms['interactions'].schema[0].childs ) {
            this.forms['interactions'].schema[0].childs[0].options =
                this.medicineService.convertToInteraction(
                    medicineInfo.interactions.interactions
                );
        }
        this.setInteractionListener();
    }

    setNotesListener(): void {
        this.forms['notes'].form.valueChanges.subscribe(() => {
            this.formChanged = true;
        });
    }

    setGeneralListener(): void {
        this.forms['general'].form.valueChanges.subscribe((data) => {
            this.formChanged = true;

            // If hospital use is set, no embistable should be true
            if ( data['otherData'].hospitalUse ) {
                // @ts-ignore
                this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].setValue(true);
                // @ts-ignore
                this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].disable();
            } else {
                // @ts-ignore
                this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].enable();
            }
        });
    }

    lastInteractionValue: { title: string, info: string } = { title: '', info: '' };
    setInteractionListener(): void {
        this.forms['interactions'].form
            .get('main')
            ?.valueChanges.subscribe((change) => {
                if (change != undefined && change.interactions) {
                    this.loadingService.start("Cargando interacciones");
                    this.getMedicineInteraction(change.interactions);
                }
                else {
                    const currValue = this.forms['interactions'].form.get('infoSelectedInteraction')?.value;
                    if (currValue != undefined && currValue.info.trim().length > 0) this.lastInteractionValue = currValue;
                    this.forms['interactions'].form.get('infoSelectedInteraction')?.patchValue({
                        title: '',
                        info: ''
                    });
                }

                // this.formChanged = true;
            });
    }

    getMedicineInteraction(change: any): void {
        if (this.medicineId !== null) {
            this.interactionService.getMedicineInteraction(
                parseInt(this.medicineId),
                change
            ).then(
                (interaction) => {
                    if (interaction) {
                        this.medicineInteractionList = interaction.map((i) =>
                            this.interactionService.transformMedicineInteractiontoApp(i)
                        );
                        if (
                            this.medicineInteractionList.length !== 0 &&
                            this.medicineInteractionList
                        ) {
                            const codeTextInteraction = this.medicineInteractionList[0]?.codeText;
                            if (
                                !this.currentIdTextInteraction ||
                                this.currentIdTextInteraction !== codeTextInteraction
                            ) {
                                this.getInteractionText(codeTextInteraction, this.medicineInteractionList[0].title);
                            }
                            else {
                                this.forms['interactions'].form.get('infoSelectedInteraction')?.patchValue({
                                    title: this.lastInteractionValue.title,
                                    info: this.lastInteractionValue.info
                                });

                                this.loadingService.stop();
                            }

                            this.currentIdTextInteraction = codeTextInteraction;
                        }
                    }
                }
            )
        }
    }

    getInteractionText(idText: string, title: string): void {
        this.interactionService.getMedicineInteractionText(parseInt(idText)).then(
            (interactionText) => {
                if (interactionText) {
                    this.medicineInteractionListText = interactionText;
                    if (this.medicineInteractionListText.text[0] !== '') {
                        this.setInteractionTextIntForm(this.medicineInteractionListText, title);
                    }
                }
                this.loadingService.stop();
            }
        );
    }

    setInteractionTextIntForm(interactionText: MedicineInteractionText, title: string): void {
        var plainText =
            this.interactionService.parseTextInteraction(interactionText);

        this.forms['interactions'].form
            .get('infoSelectedInteraction')
            ?.patchValue({
                title: title,
                info: plainText
            });
    }

    // Admin route is a endpoint that you call when you need to get the options
    // list for "vía de administración" (of medication)
    getMedicineAdministrationOptions(): void {
        this.subs.push(
            this.medicineService.medicineAdminRouteList.subscribe((adminRoutes) => {
                this.medicineAdminRouteList = adminRoutes.map((adminRoute) =>
                    this.medicineService.transformMedicineAdminRouteAPItoApp(adminRoute)
                );
            })
        );
        this.medicineService.getMedicineAdminRoute(
            false,
            false,
            0
        );
    }

    initForms(): void {
        if ( !this.roleManager.isPharma() ) {
            this.tabs = this.tabs.filter(t => t.form !== 'farmacia' && t.form !== 'robot');
        }

        this.tabs.forEach(async (tab) => {
            const formName = tab.form;

            if (formName != 'farmacia') {
                const result = this.formService.buildForm(this.formsConfig[formName]);
                this.forms[formName] = {
                    form: result[0],
                    schema: result[1],
                };
            }
        });
    }

    disableForms(): void {
        this.forms['general'].form.disable();
        this.forms['notes'].form.disable();
    }

    updateMedicineEdit(): void {
        let valid = undefined;
        for (let a = 0; a < this.farmacias.length; a++) {
            const f = this.farmacias[a];
            if (!f.OnlyTray && f.Canister != undefined && f.Canister.trim().length != 3) {
                valid = 'El valor de Tolva debe de ser de 3 dígitos';
                break;
            }
        }
        if (valid) {
            this.call.openSnack(valid, "X");
            return;
        }

        this.loadingService.start("Guardando");
        const medicine = this.getFormInfo();

        this.farmacias.forEach(f => {
            if (f.NoStock == 0) f.NoStockReplace = null;
        })

        this.medicineService.updateMedicine(
            medicine,
            this.medicineId !== null ? +this.medicineId : 0,
            {
                farmaticLinks: this.farmacias
            }
        ).finally(() => {
            this.formChanged = false;
            this.loadingService.stop();
            this.onMedicineUpdated(this.medicineInfo, this.medicineId);
        });
    }

    getFormInfo(): MedicineForm {
        const object: { [key: string]: any } = {};
        this.tabs.forEach((tab) => {
            const formName = tab.form;
            if (formName != "farmacia")
                object[formName] = this.forms[formName].form.getRawValue();
        });

        const { general, ...otherProperties } = object;
        const res: MedicineForm = { ...general, ...otherProperties };


        return res;
    }


    selectAll(attr: string) {
        this.options.filter(o => o._getHostElement().getAttribute("opt-id") == attr).forEach(o => o.select());
    }

    deselectAll(attr: string) {
        this.options.filter(o => o._getHostElement().getAttribute("opt-id") == attr).forEach(o => o.deselect());
    }

    blockInstitution(farmacia: MedicineEditLinqData, evt: MatSelectChange) {
        farmacia.institutionLinks.forEach(f => f.Exclude = false);

        evt.value.forEach((v: number) => {
            const inst = farmacia.institutionLinks.find(f => f.InstitutionId == v);
            if (inst != undefined) {
                inst.Exclude = true;
            }
        });
    }

    getBlockedInstitution(farmacia: MedicineEditLinqData) {
        return farmacia.institutionLinks.filter(f => f.Exclude).map(f => f.InstitutionId);
    }

    async getMedicineList() {
        if (this.medicineList == undefined) {
            if (this.medicineService._medicineList == undefined || this.medicineService._medicineList.length == 0) {
                await this.medicineService.getMedicines();
            }
            this.medicineList = [...this.medicineService._medicineList];
            this.medicineList.unshift({
                id: null,
                name: ''
            } as any)
        }

        return this.medicineList;
    }

    keyPressHandler(event: KeyboardEvent) {
        return new RegExp('^[0-9]{0,3}$').test(event.key);
    }

    selectedIndex: number = 0;
    navChange = false;
    tabIndexChange(index: number) {
        this.selectedIndex = index;

        if (this.navChange) {
            this.navChange = false;
            return;
        }
        this.location.go(location.pathname, "tab=" + index);
    }

    showSave() {
        if (this.tabs[this.selectedIndex].form == "interactions") return false
        return true;
    }

    hasEditPermission() {
        return Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE);
    }
}
