<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de obsolescencia'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">

    
        <!-- <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section> -->

        <section class="filter-container">
          <div class="subtitle">Opciones</div>
          <mat-checkbox formControlName="showOnlyActives" class="col-12">
            Mostrar sólo los que tienen prescripciones activas
          </mat-checkbox>
          <mat-checkbox formControlName="showMissingCF" class="col-12">
            Mostrar los que no tengan CF
          </mat-checkbox>
          <mat-checkbox formControlName="showObsoleteCF" class="col-12">
            Mostrar los que tengan el CF obsoleto
          </mat-checkbox>
          <mat-checkbox formControlName="showNoAction" class="col-12">
            Mostrar los que no necesiten ninguna acción
          </mat-checkbox>
        </section>
        <div class="subtitle">Intervalo</div>
        <section class="interval dates">
          <mat-form-field>
            <div class="label">Desde</div>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <div class="label">Hasta</div>
            <input
              matInput
              [matDatepicker]="endDatePicker"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
