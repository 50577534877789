import { dictionaryDepartments } from '@constants/dictionary-xml';
import { FormGroup } from '@angular/forms';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';
import { objectToXMLString } from '@constants/funtions-utils';

export interface FormInterface {
    [key: string]: {
        form: FormGroup;
        schema: FormItemSchemaInterface[];
    };
}

// Interface for update the institution data
export class InstitutionUpdateAPI {
    Id: number | null;
    ShortName: string;
    Name: string;
    Type: number;
    Address: string;
    Number: string;
    City: string;
    ZipCode: string;
    Phone: any;
    EMail: string;
    EMailRE: any;
    EMailStock: any;
    Doctor: string;
    IntakeTime1: string;
    IntakeTime2: string;
    IntakeTime3: string;
    IntakeTime4: string;
    IntakeTime5: string;
    IntakeRangeBefore: string;
    IntakeRangeAfter: string;
    IntakePrepTimeBefore: string;
    IntakePrepTimeAfter: string;
    Departments: string;
    Colegiado: string;
    Obsolete: boolean;
    PermitirFGP: boolean;
    ExportCf: boolean;
    Doctor2: any;
    Colegiado2: any;
    Modalidad: any;
    TipoloAtencion: any;
    FechaApertura: any;
    RaCutDay: any;
    IgnoreMEC: boolean;
    covid: any;
    raStop: any[];
    raIgnoreMedTransl: any[];
    ibds: Ibd[];
    ib: Ib;
    fi: Fi;
    PmWeekStartDay: number;
    PmEndInterval: number;

    constructor(input: InstitutionForm) {
        this.Id = parseInt(input.id);
        this.ShortName = input.main.nameAbbreviation;
        this.Name = input.main.name;
        this.Type = parseInt(input.main.type);
        this.Address = input.main.address;
        this.Number = input.main.number;
        this.City = input.main.city;
        this.ZipCode = input.main.postalCode;
        this.Phone = input.main.telephone;
        this.EMail = input.emailList.general;
        this.EMailRE = input.emailList.re;
        this.EMailStock = input.emailList.inventary;
        this.Doctor = input.doctors.name1;
        this.Colegiado = input.doctors.collegiate1;
        this.Doctor2 = input.doctors.name2;
        this.Colegiado2 = input.doctors.collegiate2;
        this.IntakeTime1 = input.breakfast;
        this.IntakeTime2 = input.lunch;
        this.IntakeTime3 = input.snack;
        this.IntakeTime4 = input.dinner;
        this.IntakeTime5 = input.sleep;

        this.TipoloAtencion = input.main.typology;

        // Actus (SIRVI)
        this.IntakeRangeBefore = `${("0" + input.actus.validity.before.hours).slice(-2)}:${("0" + input.actus.validity.before.minutes).slice(-2)}`;
        this.IntakeRangeAfter = `${("0" + input.actus.validity.after.hours).slice(-2)}:${("0" + input.actus.validity.after.minutes).slice(-2)}`;
        this.IntakePrepTimeBefore = `${("0" + input.actus.preparation.before.hours).slice(-2)}:${("0" + input.actus.preparation.before.minutes).slice(-2)}`;
        this.IntakePrepTimeAfter = `${("0" + input.actus.preparation.after.hours).slice(-2)}:${("0" + input.actus.preparation.after.minutes).slice(-2)}`;

        this.Modalidad = input.main.modality;
        this.FechaApertura = input.main.openDate;
        this.IgnoreMEC = input.MEC?.state || false;
        this.Obsolete = input.otherData.obsolete;
        this.PermitirFGP = input.otherData.allowFgp;
        this.ExportCf = input.otherData.exportCf;
        this.RaCutDay = input.automaticReposition.frecuency;

        if ( input.id ) {
            console.log('id');
            this.ib = {
                DeliveryMessage: input.incontinence.deliveryDetails,
                AutovalHour: input.autovalidation.hour,
                AutovalWeekDay: input.autovalidation.day,
                InstitutionId: this.Id,
                AutoconGracePeriod: input.autoconfirmation.autoconfirmation || null,
                AutovalGracePeriod: input.autovalidation.type ? input.autovalidation.hours : null,
                UseBonus: input.enableBonification,
            };

            this.fi = {
                NameFarmatic: input.linkFarmatic.prefix,
                IdFarmatic: input.linkFarmatic.idFarmatic,
                IdCorota: parseInt(input.id),
            };
        } else {
            this.ib = {
                DeliveryMessage: input.incontinence.deliveryDetails,
                AutovalHour: input.autovalidation.hour,
                AutovalWeekDay: input.autovalidation.day,
                AutoconGracePeriod: input.autoconfirmation.autoconfirmation || null,
                AutovalGracePeriod: input.autovalidation.type ? input.autovalidation.hours : null,
                UseBonus: input.enableBonification,
            };

            this.fi = {
                NameFarmatic: input.linkFarmatic.prefix,
                IdFarmatic: input.linkFarmatic.idFarmatic,
            };
        }

        this.PmWeekStartDay = input.pm.interval.initialDay;
        this.PmEndInterval = input.pm.interval.intervalDay;

        this.Departments = convertToDepartmenUpdateForm(
            input.departments,
            parseInt(input.id)
        );
    }
}

export interface Ibd {
    InstitutionId: number;
    ItemQty: number;
    BonusQty: number;
    DistributorId: number;
    BonusStartDate: string;
}

export interface Ib {
    InstitutionId?: number | null;
    AutoconGracePeriod: any;
    AutovalGracePeriod: any;
    AutovalHour: string;
    AutovalWeekDay: number;
    DeliveryMessage: string;
    UseBonus: any;
}

export interface Fi {
    IdFarmatic: string;
    IdCorota?: number;
    NameFarmatic: string;
}

export interface Pm {
    PmWeekStartDay: number;
    PmEndInterval: number;
}

export interface InstitutionForm {
    main: GeneralList;
    emailList: EmailList;
    doctors: Doctors;
    otherData: OtherData;
    breakfast: string;
    lunch: string;
    snack: string;
    dinner: string;
    sleep: string;
    departments: { departments: Department[] };
    incontinence: Incontinence;
    autovalidation: Autovalidation;
    autoconfirmation: Autoconfirmation;
    enableBonification: string;
    automaticReposition: AutomaticReposition;
    MEC: Mec;
    linkFarmatic: LinkFarmatic;
    pm: Pm;
    id: string;
    actus: Actus;
}

export interface GeneralList {
    nameAbbreviation: string;
    name: string;
    type: string;
    modality: string;
    typology: string;
    openDate: Date;
    address: string;
    number: string;
    city: string;
    postalCode: string;
    telephone: string;
}

export interface EmailList {
    general: string;
    re: any;
    inventary: any;
}

export interface Doctors {
    name1: any;
    collegiate1: any;
    name2: any;
    collegiate2: any;
}

export interface OtherData {
    obsolete: boolean;
    allowFgp: boolean;
    exportCf: boolean;
}

export interface Department {
    department: Department2;
}

export interface Department2 {
    title: string;
    name: string;
    enableCustomSchedule: boolean;
    breakfast: string;
    lunch: string;
    snack: string;
    dinner: string;
    sleep: string;
    id: number;
}

export interface Incontinence {
    deliveryDetails: string;
}

export interface Autovalidation {
    autoValidationOrder: number;
    type: any;
    day?: any;
    hour?: any;
    hours?: any;
}

export interface Autoconfirmation {
    autoconfirmation: any;
}

export interface AutomaticReposition {
    frecuency: number;
    excludedMedication: string;
    noSustituteMedication: string;
}

export interface Frecuency {
    none: boolean;
    weekly: boolean;
    monthly: boolean;
}

export interface Mec {
    state: boolean;
}

export interface LinkFarmatic {
    prefix: string;
    idFarmatic: string;
    corotaId: any;
}

export interface Pm {
    interval: Interval;
}

export interface Actus {
    validity: {
        after: { hours: number; minutes: number };
        before: { hours: number; minutes: number };
    },
    preparation: {
        after: { hours: number; minutes: number };
        before: { hours: number; minutes: number };
    }
}

export interface Interval {
    initialDay: number;
    intervalDay: number;
}

export function convertToDepartmenUpdateForm(
    departments: {
        departments: Department[];
    },
    id: number
): string {
    let xmlString = '<ArrayOfDepartments>';

    for (let i = 0; i < departments.departments.length; i++) {
        const department = departments.departments[i].department;
        xmlString += '<Department>';

        if (department.id) {
            xmlString += `<Id>${department.id}</Id>`;
        }

        xmlString += `<InstitutionId>${id}</InstitutionId>`;

        if (department.name) {
            xmlString += `<Name>${department.name}</Name>`;
        }

        if (department.enableCustomSchedule) {
            xmlString += objectToXMLString(department, dictionaryDepartments, true);
        }

        xmlString += '</Department>';
    }

    xmlString += '</ArrayOfDepartments>';
    return xmlString;
}
