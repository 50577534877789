import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  showSelect: boolean = false;

  private subs: Subscription[] = [];

  constructor(
    private roleManager: RoleManagerService,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.showSelect = this.roleManager.showHeaderSelectInstitution();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
