import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  reportDataElectronicRecipesConsolidated,
  reportDataElectronicRecipesByIssues,
  reportDataElectronicRecipesByPatients,
  reportDataElectronicRecipesStatus
} from '@constants/reports/report-electronic-recipe';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { setDateTo00, setDateTo2359 } from '@constants/funtions-utils';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
  selector: 'app-report-electronic-recipe',
  templateUrl: './report-electronic-recipe.component.html',
  styleUrls: ['./report-electronic-recipe.component.scss'],
})
export class ReportElectronicRecipeComponent {
  public reportElectronicRecipe = reportDataElectronicRecipesByIssues;
  public currentInstitution: number;
  public showPatientSelect: boolean = false;
  public form: FormGroup;
  public report: Report;
  public pathReportDownload: string;

  public selectOptionsInsitutions: SelectOption[];
  public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

  private currentTab: number = 0;
  
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private calls: CallsService,
    private reportService: ReportsService,
    private documentService: DocumentService,
    private institutionService: InstitutionService,
    private roleManager: RoleManagerService,  
    private titleService: Title,
    private gaService: GoogleAnalyticsService
  ) {
    this.form = this.formBuilder.group({
      checkboxDoctorSS: [''],
      typeReport: ['1'],
      consolidatedType: ['0'],
      selectedInstitution: ['', Validators.required],
      includeExitus: [false],
      includeHistorical: [false],
      date: [moment().subtract(6, 'month'), Validators.required],
      dateReport: [moment(), Validators.required],
    });
    this.currentInstitution = this.institutionService.getCurrentInstitution();
  }

  ngOnInit(): void { 
    this.titleService.setTitle(`Reporte: Receta electrónica`);
    this.gaService.pageView(`${window.location.href}`, `Reporte: Receta electrónica`);

    this.typeGlobal = this.roleManager.isPharma();
    this.getDataForTheSelects();

    this.form.valueChanges.subscribe((data) => {
      this.institutionService.selectInstitutionById(this.form.controls['selectedInstitution'].value.id);
    });
  }

getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
        // Order Alpabetically label and text uppercase
        this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        // this.selectOptionsInsitutions.map((a) =>{
        //     a.label = a.label.toUpperCase();
        // })

      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }
      }

      this.form.controls['selectedInstitution'].setValue(
        this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
      )
    });
}


  handlePreviewClick(): void {
    const { typeReport } = this.getFormData();

    if (this.currentTab === 1 || typeReport === '0' || ( typeReport !== '0' && this.form.controls['selectedInstitution'].valid )) {
       
      if ( this.currentTab === 0 ) {
        switch( typeReport ) {
          case '0': this.reportElectronicRecipe = reportDataElectronicRecipesConsolidated; break;
          case '1': this.reportElectronicRecipe = reportDataElectronicRecipesByIssues; break;
          case '2': this.reportElectronicRecipe = reportDataElectronicRecipesByPatients; break;
        }
      } else {
        this.reportElectronicRecipe = reportDataElectronicRecipesStatus;
      }
        
      this.reportElectronicRecipe.data.rp = this.getInfoRP();
      this.reportElectronicRecipe.data.dn = Utils.buildUniqueFileName + "_{0}.html";
      this.reportService.handlePreviewClick(
        this.router,
        this.reportElectronicRecipe
      );
    } else {
      this.calls.openSnack('Debes seleccionar un centro');
    }
  }

  handlePrintClick(): void {
    const { typeReport } = this.getFormData();
      
    if (this.currentTab === 1 || typeReport === '0' || ( typeReport !== '0' && this.form.controls['selectedInstitution'].valid )) {
      
      const now = moment();
      if ( this.currentTab === 0 ) {
        switch( typeReport ) {
          case '0': 
            this.reportElectronicRecipe = reportDataElectronicRecipesConsolidated; 
            this.reportElectronicRecipe.dataRenderReport.dn= `RevisionRE_incidencias_medicacion_consolidado_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
            break;
          case '1': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByIssues; 
            this.reportElectronicRecipe.dataRenderReport.dn= `RevisionRE_incidencias_medicacion_incidencias_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
            break;
          case '2': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByPatients;
            this.reportElectronicRecipe.dataRenderReport.dn= `RevisionRE_incidencias_medicacion_pacientes_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
          break;
        }
      } else {
        this.reportElectronicRecipe = reportDataElectronicRecipesStatus;
        this.reportElectronicRecipe.dataRenderReport.dn= `GestionRE_Farmacia_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
      }

      this.reportElectronicRecipe.dataRenderReport.rp = this.getInfoRP();
      this.reportService.handlePrintClick(this.reportElectronicRecipe);
    } else {
      this.calls.openSnack('Debes seleccionar un centro');
    }
  }

  handlePdfClick(): void {
    const { typeReport } = this.getFormData();
      
    if (this.currentTab === 1 || typeReport === '0' || ( typeReport !== '0' && this.form.controls['selectedInstitution'].valid )) {

      const now = moment();
      if ( this.currentTab === 0 ) {
        switch( typeReport ) {
          case '0': 
            this.reportElectronicRecipe = reportDataElectronicRecipesConsolidated; 
            this.reportElectronicRecipe.dataDownloadPDF.dn= `RevisionRE_incidencias_medicacion_consolidado_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
            break;
          case '1': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByIssues; 
            this.reportElectronicRecipe.dataDownloadPDF.dn= `RevisionRE_incidencias_medicacion_incidencias_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
            break;
          case '2': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByPatients;
            this.reportElectronicRecipe.dataDownloadPDF.dn= `RevisionRE_incidencias_medicacion_pacientes_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
          break;
        }
      } else {
        this.reportElectronicRecipe = reportDataElectronicRecipesStatus;
        this.reportElectronicRecipe.dataDownloadPDF.dn= `GestionRE_Farmacia_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
      }

      this.reportElectronicRecipe.dataDownloadPDF.rp = this.getInfoRP();
      this.reportService.handlePdfClick(this.reportElectronicRecipe);
    } else {
      this.calls.openSnack('Debes seleccionar un centro');
    }
  }

  handleExcelClick(): void {
    const { typeReport } = this.getFormData();
    
    if (this.currentTab === 1 || typeReport === '0' || ( typeReport !== '0' && this.form.controls['selectedInstitution'].valid )) {

      const now = moment();
      if ( this.currentTab === 0 ) {
        switch( typeReport ) {
          case '0': 
            this.reportElectronicRecipe = reportDataElectronicRecipesConsolidated; 
            this.reportElectronicRecipe.dataDownloadExcel.dn= `RevisionRE_incidencias_medicacion_consolidado_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
            break;
          case '1': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByIssues; 
            this.reportElectronicRecipe.dataDownloadExcel.dn= `RevisionRE_incidencias_medicacion_incidencias_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
            break;
          case '2': 
            this.reportElectronicRecipe = reportDataElectronicRecipesByPatients;
            this.reportElectronicRecipe.dataDownloadExcel.dn= `RevisionRE_incidencias_medicacion_pacientes_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
          break;
        }
      } else {
        this.reportElectronicRecipe = reportDataElectronicRecipesStatus;
        this.reportElectronicRecipe.dataDownloadExcel.dn= `GestionRE_Farmacia_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
      }

      this.reportElectronicRecipe.dataDownloadExcel.rp = this.getInfoRP();
      this.reportService.handleExcelClick(this.reportElectronicRecipe);
    } else {
      this.calls.openSnack('Debes seleccionar un centro');
    }
  }

  getFormData(): {
    checkboxDoctorSS: string;
    typeReport: string;
  } {
    const checkboxDoctorSS = this.form.get('checkboxDoctorSS')?.value;
    const typeReport = this.form.get('typeReport')?.value;

    return { checkboxDoctorSS, typeReport };
  }

  getInfoRP(): string {
    const { checkboxDoctorSS } = this.getFormData();

    let  currentInstitution;
    const dataForm = this.form.getRawValue()
    //@ts-ignore
    currentInstitution = dataForm.selectedInstitution.id

    let startDate = setDateTo00(dataForm.dateReport.toDate());
    let endDate = setDateTo2359(dataForm.dateReport.toDate());

    // Ignore GMT difference
    startDate.setHours(startDate.getHours() - startDate.getTimezoneOffset() / 60)
    endDate.setHours(endDate.getHours() - endDate.getTimezoneOffset() / 60)
    
    let data;

    if ( this.currentTab === 1 ) {
      data = {
        LocaleId: '3082',
        InstitutionList: null,
        IncludeNoBlister: 'true',
        StartDate: startDate,
        EndDate: endDate
      };
    } else if ( dataForm.typeReport === '0' ) {
      data = {
        LocaleId: '3082',
        InstitutionId: null,
        IncludeNoBlister: '1',
        GroupMedicoSS: null,
        IncludePastIssues: null,
        IssueType: dataForm.consolidatedType
      };
    } else {
      data = {
        LocaleId: '3082',
        PatientId: null,
        InstitutionId: String(currentInstitution),
        IncludeNoBlister: '1',
        GroupMedicoSS: String(checkboxDoctorSS) || null,
        IncludePastIssues: dataForm.includeExitus ? '1' : '0',
        PastIssuesCutDate: this.typeGlobal && (dataForm.includeExitus || dataForm.includeHistorical) ? Utils.BUG_FixDateForTimeZone(moment(dataForm.date).startOf('day').toDate())?.toISOString().split('.')[0] : null,
        IncludeHistoryIssues: dataForm.includeHistorical ? 'true' : 'false',
      };
    }

    return JSON.stringify(data);
  }

  tabChanged($event: any) {
    this.currentTab = $event.index;
  }
}
