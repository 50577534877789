import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PAGE_SIZE_OPTIONS } from '@constants/options';
import {
  formsConfig,
  plansColumns,
  pmTreeView,
  processPlanDataRender,
  tabs,
} from '@constants/process/proc-plans';
import { schedulesOptions } from '@constants/schedules';
import {
  OptionsObject,
  TabInterface,
} from '@interfaces/dynamic-form.interface';
import { FormInterface } from '@interfaces/institution/institution-edit.interface';
import { ProcPlan } from '@interfaces/procs/proc-plans';
import { TableColumn } from '@interfaces/table.interface';
import { environment } from '@json/src/environments/environment';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { FormService } from '@services/form.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { ProcsService } from '@services/process/procs.service';
import { Subscription, forkJoin, skip, skipWhile, takeWhile } from 'rxjs';

@Component({
  selector: 'app-proc-plans',
  templateUrl: './proc-plans.component.html',
  styleUrls: ['./proc-plans.component.scss'],
})
export class ProcPlansComponent implements OnInit {
  @ViewChild('previewList', { static: false }) previewList: ElementRef;

  public forms: FormInterface = {};
  public formReady: boolean = false;
  private subs: Subscription[] = [];

  public selectedTabIndex = 0;
  public widthTable: string;

  public tabs: TabInterface[] = tabs;
  private formsConfig = formsConfig;

  public formChanged: boolean = false;

  public departments: any[];

  public options: OptionsObject = {
    institutions: [],
    schedules: schedulesOptions,
  };

  optionsMode: any =[
    {id: 0, name: 'Selecionar', selected: 1},
    {id: 1, name: 'Visualizar', selected: 0},
  ];
  optionsModeSeleced: number = 0;
  optionsTree: boolean = false;


  public isOptionsSelected = false;
  public optionsTreeSelected: any[] | undefined;
  public plans: ProcPlan[] = [];

  public settingFocus: boolean = false;
  public dynamicData: any;
  public patientColumns: TableColumn[] = plansColumns;
  public processPlanDataRender = processPlanDataRender;
  public errors: string[];

  pmTreeViewE = Object.assign({}, pmTreeView);

  public pageSize: number = 15;
  public lowValue: number = 0;
  public highValue: number = this.pageSize;
  public pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  constructor(
    private formService: FormService,
    private loadingService: LoadingService,
    private institutionsService: InstitutionsService,
    private institutionService: InstitutionService,
    private calls: CallsService,
    private documentService: DocumentService,
    private dateService: DateService,
    private procsService: ProcsService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dynamicData = [];
    this.initForms();
  }

  async getInitAll(): Promise<void> {
    this.institutionService.getInstitutions();
    this.institutionsService.getInstitutions(true, null);

    const institutionList = new Promise((resolve, reject) => {
      this.institutionsService.institutionList.subscribe((list) => {
        if (list.length > 0) {
          this.options['institutions'] = list.map((institution) => ({
            label: institution.nam,
            value: institution.id,
          }));

          resolve(true);
        }
      })
    });

    await institutionList;

    const pmReasonType = new Promise((resolve, reject) => {
      this.procsService.getPMReasonType().subscribe(data => {
        this.options['reasons'] = data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));

        resolve(true);
      });
    });

    await pmReasonType;

    return ;
  }

  selectedOption(option: any ){
    this.optionsMode.map((option: any) => option.selected = 0); 

    if (option.id == 1) {
      if ( this.optionsTree ) {
        this.getPreview()
        option.selected = 1; 

        this.optionsModeSeleced = option.id;
        this.calculatePageSizeOptions();
      }
    } else {
      option.selected = 1; 
      this.optionsModeSeleced = option.id;
    }
  }
  setListener(): void {
    this.forms['settings'].form.controls['institution'].valueChanges.subscribe((data) => {
      this.institutionService.selectInstitutionById(data.institution);
      this.resetTree();

      this.loadingService.start('Cargando datos del centro...');
      this.calls.getPMInstitutionData(data.institution).subscribe({
        next: (value) => {
          if ( value.payload !== null ) {
            this.forms['settings'].form.controls['interval']?.get('startDate')?.setValue(value.payload.pmStartDate);
            this.forms['settings'].form.controls['interval']?.get('endDate')?.setValue(value.payload.pmEndDate);
          }

          this.loadingService.stop();
        },
      });
    });
    this.forms['settings'].form.controls['interval'].valueChanges.subscribe((data) => {
      this.resetTree();

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = new Date(data.startDate);

      if ( startDate < today ) {
        // @ts-ignore
        this.forms['settings'].schema[1].childs[2].visible = true;
        // @ts-ignore
        this.forms['settings'].schema[1].childs[3].visible = false;
      } else if (this.dateService.isMoreThanXDays(new Date(), new Date(data.startDate), 4)) {
        // @ts-ignore
        this.forms['settings'].schema[1].childs[2].visible = false;
        // @ts-ignore
        this.forms['settings'].schema[1].childs[3].visible = true;
      } else {
        // @ts-ignore
        this.forms['settings'].schema[1].childs[2].visible = false;
        // @ts-ignore
        this.forms['settings'].schema[1].childs[3].visible = false;
      }

      if (this.dateService.isMoreThanXDays(new Date(data.startDate), new Date(data.endDate), 6)) {
        // @ts-ignore
        this.forms['settings'].schema[1].childs[6].visible = true;
      } else {
        // @ts-ignore
        this.forms['settings'].schema[1].childs[6].visible = false;
      }
    });
    this.forms['settings'].form.controls['branchHide'].valueChanges.subscribe(() => {
      // Handle days hide checkbox
      if ( this.forms['settings'].form.controls['branchHide'].get('hideDays')?.value && this.forms['settings'].form.controls['branchHide'].get('hideHours')?.enabled) {
        this.forms['settings'].form.controls['branchHide'].get('hideHours')?.disable();
        this.forms['settings'].form.controls['branchHide'].get('hideHours')?.setValue(true);
      } else if ( !this.forms['settings'].form.controls['branchHide'].get('hideDays')?.value && this.forms['settings'].form.controls['branchHide'].get('hideHours')?.disabled) {
        this.forms['settings'].form.controls['branchHide'].get('hideHours')?.enable();
        this.forms['settings'].form.controls['branchHide'].get('hideHours')?.setValue(false);
      }

      this.resetTree();
    });
  }

  resetTree(): void {
    this.isOptionsSelected = false;

      //@ts-ignore
      this.optionsMode.map((option) => {
        if (option.id === 0) {
          option.selected = 1; 
        } else {
          option.selected = 0; 
        }
      }); 
      this.optionsModeSeleced = 0;
      this.optionsTree = false;
      this.errors = this.getErrorsDate(this.forms);
  }

  handleRechargeButton(): void {
    this.errors = this.getErrorsDate(this.forms);

    if (this.forms['settings'].form.valid) {
      if (this.errors.length > 0) {
        this.modalService.openModalCallback(
          () => this.getVMTree(),
          this.errors[0],
          'Fechas del plan',
          true
        );
      } else {
        this.getVMTree();
      }
    } else {
      this.forms['settings'].form.markAllAsTouched();
    }
  }

  getErrorsDate(form: any): string[] {
    const errors = [];
    const { startDate, endDate } = this.procsService.extractFormValues(form);
    const currentDate = new Date();
    const firstDate = new Date(startDate);
    const lastDate = new Date(endDate);
    if (
      firstDate < currentDate &&
      !this.dateService.areDatesEqual(firstDate, currentDate)
    ) {
      errors.push('La fecha inicial es menor que la fecha de hoy. ¿Está seguro de que desea utilizar esa fecha?');
    }

    // if (this.dateService.isMoreThanXDays(firstDate, lastDate, 6)) {
    //   errors.push('El intervalo escogido tiene más de 7 días. ¿Está seguro de que desea seguir?');
    // }
    return errors;
  }

  handleTab(event: any): void {
    if (event.index == 1) {
      this.settingFocus = true;
      this.widthTable = '100%';
      this.getPreview();
    } else {
      this.settingFocus = false;
      this.widthTable = '65%';
    }
  }

  handleChangedOptions(): void {
    const { institution, startDate, startHour, endDate, endHour, hideDepartments, hideDays, hideHours } =
      this.procsService.extractFormValues(this.forms);
      
    if ( this.dynamicData[0].checkStatus ) {
      this.optionsTreeSelected = [{
        id: 'I',
        item: this.pmTreeViewE.instid,
        childs: this.getInstitutionChildsOptions(hideDepartments, hideDays, hideHours)
      }]

    } else {
      this.optionsTreeSelected = undefined;
    }

    this.optionsTree = true;
  }

  getInstitutionChildsOptions(hideDepartments: boolean, hideDays: boolean, hideHours: boolean) {
    if ( this.dynamicData[0].checkStatus === 2 ) {
      return [];
    } else {
      if ( hideDepartments ) {
        // Get patients if departments hidden
        return this.dynamicData[0].items
          .filter((patient: any) => patient.checkStatus)
          .map((patient: any) => {return {id: 'P', item: patient.id.substring(1), childs: this.getPatientChildsOptions(patient, hideDays, hideHours)}})
      } else {
        return this.dynamicData[0].items
          .filter((department: any) => department.checkStatus)
          .map((department: any) => {return {id: 'D', item: department.id.substring(1), childs: this.getDepartmentChildsOptions(department, hideDays, hideHours)}})
      }
    }
  }
  getDepartmentChildsOptions(department: any, hideDays: boolean, hideHours: boolean) {
    if ( department.checkStatus === 2 ) {
      return [];
    } else {
      return department.items
        .filter((patient: any) => patient.checkStatus)
        .map((patient: any) => {return {id: 'P', item: patient.id.substring(1), childs: this.getPatientChildsOptions(patient, hideDays, hideHours)}})
    }
  }
  getPatientChildsOptions(patient: any, hideDays: boolean, hideHours: boolean) {
    if ( patient.checkStatus === 2 ) {
      return [];
    } else {
      if ( hideDays ) {
        // Get medicines if days hidden
        return patient.items
          .filter((medicine: any) => medicine.checkStatus)
          .map((medicine: any) => {return {id: 'M', item: medicine.id.substring(1), childs: []}})
      } else {
        return patient.items
          .filter((date: any) => date.checkStatus)
          .map((date: any) => {return {id: 'd', item: date.id.substring(1), childs: this.getDateChildsOptions(date, hideHours)}})
      }
    }
  }
  getDateChildsOptions(date: any, hideHours: boolean) {
    if ( date.checkStatus === 2 ) {
      return [];
    } else {
      if ( hideHours ) {
        // Get medicines if days hours
        return date.items
          .filter((medicine: any) => medicine.checkStatus)
          .map((medicine: any) => {return {id: 'M', item: medicine.id.substring(1), childs: []}})
      } else {
        return date.items
          .filter((hour: any) => hour.checkStatus)
          .map((hour: any) => {return {id: 'H', item: hour.id.substring(1), childs: this.getHourChildsOptions(hour)}})
      }
    }
  }
  getHourChildsOptions(hour: any) {
    if ( hour.checkStatus === 2 ) {
      return [];
    } else {
      return hour.items
        .filter((medicine: any) => medicine.checkStatus)
        .map((medicine: any) => {return {id: 'M', item: medicine.id.substring(1), childs: []}})
    }
  }

  async initForms(): Promise<void> {
    await this.getInitAll();

    this.loadingService.start('Cargando Formulario...');

    this.tabs.forEach(async (tab) => {
      const formName = tab.form;
      const result = this.formService.buildForm(
        this.formsConfig(this.options)[formName]
      );
      this.forms[formName] = {
        form: result[0],
        schema: result[1],
      };
    });

    this.formReady = true;
    this.loadingService.stop();
    this.setListener();

    this.forms['settings'].form.controls['institution'].setValue({
      institution: this.institutionService.getCurrentInstitution()
    });
  }

  getPreview(): void {
    this.loadingService.start('Cargando datos...');
    const { institution, startDate, startHour, endDate, endHour } =
      this.procsService.extractFormValues(this.forms);

    const whclInstrinfy = JSON.stringify(this.optionsTreeSelected);

    if (this.optionsTreeSelected) {
      this.calls
        .getDispensingPlanPreview({
          instid: parseInt(institution),
          dtFrom: this.dateService.convertStringOfDate(
            startDate,
            startHour,
            true
          ),
          dtUntil: this.dateService.convertStringOfDate(endDate, endHour, true),
          inclNB: 0,
          whCl: whclInstrinfy,
        })
        .subscribe({
          next: (value) => {
            const { payload } = value;
            this.plans = payload;
            this.loadingService.stop();
          },
        });
    }
  }

  downloadPlan(): void {
    this.loadingService.start('Cargando datos...');

    const { 
      institution, 
      startDate, 
      startHour, 
      endDate, 
      endHour, 
      type, 
      pills,
      noLockDate,
      noShowDeps,
      noShowDays,
      noShowHours,
      reasonType
    } =
      this.procsService.extractFormValues(this.forms);

    if (this.optionsTreeSelected) {
      const whclInstrinfy = JSON.stringify(this.optionsTreeSelected);

      this.calls
        .getDispensingPlan(
          this.procsService.createDispensingPlanPayload(
            institution,
            startDate,
            startHour,
            endDate,
            endHour,
            type,
            pills,
            whclInstrinfy,
            noLockDate,
            noShowDeps,
            noShowDays,
            noShowHours,
            reasonType,
            this.options
          )
        )
        .subscribe({
          next: (value) => {
            const { fp, fn } = value.payload.planData[0];
            const unsafeURL = this.procsService.parsePathHtml(
              environment.urlBack,
              fp,
              fn
            );

            this.documentService.download(unsafeURL, fn);
            this.loadingService.stop();
          },
        });
    }
  }

  getVMTree(): void {
    this.loadingService.start('Cargando datos...');
    
    this.pmTreeViewE = Object.assign({}, pmTreeView);

  
    const { institution, startDate, startHour, endDate, endHour, hideDepartments, hideDays, hideHours } =
      this.procsService.extractFormValues(this.forms);

    if ( hideDepartments ) {
      this.pmTreeViewE.treelv = 'P';
    }

    const options = this.options['institutions'].filter((inst) => {
      return inst.value == institution;
    });

    let dynamiDataAux = options.map((option) => option.label);

    this.pmTreeViewE.instid = parseInt(institution);
    this.pmTreeViewE.dtFrom = this.dateService.convertStringOfDate(
      startDate,
      startHour,
      true
    );
    this.pmTreeViewE.dtUntil = this.dateService.convertStringOfDate(
      endDate,
      endHour,
      true
    );

    this.calls.getPMTreeViewDat(this.pmTreeViewE).subscribe({
      next: (value) => {
        const { payload } = value;
        this.departments = payload;

        this.dynamicData = [
          {
            title: dynamiDataAux[0],
            items: this.departments
          }
        ]

        this.isOptionsSelected = true;
        this.loadingService.stop();
      },
    });
  }

  downloadPDF(): void {
    this.downloadDocument('pdf');
  }

  downloadExcel(): void {
    this.downloadDocument('excel');
  }

  downloadDocument(format: 'pdf' | 'excel'): void {
    this.loadingService.start('Descargando documento...');
    const petition = this.processPlanDataRender.dataRenderReport;
    const { institution, startDate, endDate } =
      this.procsService.extractFormValues(this.forms);
    const rp = this.procsService.getRPData(
      this.forms,
      this.optionsTreeSelected,
      this.departments
    );

    const dn = this.procsService.getDn(
      this.procsService.getInstitutionName(institution, this.options) ?? '',
      this.dateService.convertDateToFormatYearMonthDay(startDate),
      this.dateService.convertDateToFormatYearMonthDay(endDate),
      format
    );
    petition.rp = rp;
    petition.dn = dn;
    petition.fmt = format === 'pdf' ? 2 : 3;

    this.calls.renderDispensingPlan(petition).subscribe({
      next: (value) => {
        const { fp, fn } = value.payload;
        const unsafeURL = this.procsService.parsePathHtml(
          environment.urlBack,
          fp,
          fn
        );
        this.documentService.download(unsafeURL, fn);
        this.loadingService.stop();
      },
    });
  }

  requestTreeBranch(item: any) {
    let currentBranchType = item.id.substring(0, 1);
    const currentId = item.id.substring(1);

    let nextBranchType = '';
    let date: any = '';

    const { institution, startDate, startHour, endDate, endHour, hideDepartments, hideDays, hideHours } = this.procsService.extractFormValues(this.forms);

    switch ( currentBranchType ) {
      case 'D': 
        nextBranchType = 'P'; 

        // @ts-ignore
        this.pmTreeViewE.depid = Number(currentId);
        this.pmTreeViewE.patid = null;
        this.pmTreeViewE.admdt = null;
        this.pmTreeViewE.admtm = null;
        break;
      case 'P': 
        if ( hideDays ) {
          nextBranchType = 'M'; 
        } else {
          nextBranchType = 'd';
        }

        // @ts-ignore
        this.pmTreeViewE.depid = !hideDepartments ? Number(item.parent.id.substring(1)) : null;
        // @ts-ignore
        this.pmTreeViewE.patid = Number(currentId);
        this.pmTreeViewE.admdt = null;
        this.pmTreeViewE.admtm = null;
        break;
      case 'd': 
        if ( hideHours ) {
          nextBranchType = 'M'; 
        } else {
          nextBranchType = 'H'; 
        }

        // @ts-ignore
        this.pmTreeViewE.depid = !hideDepartments ? Number(item.parent.parent.id.substring(1)) : null;
        // @ts-ignore
        this.pmTreeViewE.patid = Number(item.parent.id.substring(1));

        date = new Date(`${currentId.substring(0, 4)}-${currentId.substring(4, 6)}-${currentId.substring(6, 8)}T00:00:00`);
        date.getTimezoneOffset() < 0
          ? date = date.getTime() + (date.getTimezoneOffset() * 60000 * -1)
          : date = date.getTime() + date.getTimezoneOffset() * 60000;
        // @ts-ignore
        this.pmTreeViewE.admdt = `/Date(${date})/`;

        this.pmTreeViewE.admtm = null;
        break;
      case 'H': 
        nextBranchType = 'M'; 

        // @ts-ignore
        this.pmTreeViewE.depid = !hideDepartments ? Number(item.parent.parent.parent.id.substring(1)) : null;
        // @ts-ignore
        this.pmTreeViewE.patid = Number(item.parent.parent.id.substring(1));

        date = new Date(`${item.parent.id.substring(1).substring(0, 4)}-${item.parent.id.substring(1).substring(4, 6)}-${item.parent.id.substring(1).substring(6, 8)}T00:00:00`);
        date.getTimezoneOffset() < 0
          ? date = date.getTime() + (date.getTimezoneOffset() * 60000 * -1)
          : date = date.getTime() + date.getTimezoneOffset() * 60000;
        // @ts-ignore
        this.pmTreeViewE.admdt = `/Date(${date})/`;

        this.pmTreeViewE.admtm = currentId;
        break;
    }

    this.pmTreeViewE.instid = parseInt(institution);
    this.pmTreeViewE.dtFrom = this.dateService.convertStringOfDate(
      startDate,
      startHour,
      true
    );
    this.pmTreeViewE.dtUntil = this.dateService.convertStringOfDate(
      endDate,
      endHour,
      true
    );
    this.pmTreeViewE.treelv = nextBranchType;

    this.calls.getPMTreeViewDat(this.pmTreeViewE).subscribe({
      next: (value) => {
        const { payload } = value;
        item.items = payload;
      },
    });
  }

  calculatePageSizeOptions() {
    this.cdRef.detectChanges();

    if ( this.previewList ) {
      const layoutHeight = this.previewList.nativeElement.offsetHeight - 80;
      const gridGap = 0; // Grid gap from style in px
      
      const columns = 1;
      const rows = Math.floor((layoutHeight - gridGap) / 40 + gridGap);

      this.pageSize = columns * rows;
      this.highValue = this.pageSize;

      this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3, Math.ceil(this.pageSize * 4 / 100) * 100];
    }
  }
}
