import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataPolymedicationFarma } from '@constants/reports/report-polymedication-farma';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-report-polymedication-farma',
  templateUrl: './report-polymedication-farma.component.html',
  styleUrls: ['./report-polymedication-farma.component.scss'],
})
export class ReportPolymedicationFarmaComponent {
  public reportDataPolymedicationFarma = reportDataPolymedicationFarma;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsInsitutions: SelectOption[];
  public error = 'Debes elegir un centro';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      specificDay: [''],
      selectedInstitution: ['', Validators.required],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setCurrentDate();

    this.form.valueChanges.subscribe((data) => {
      this.institutionService.selectInstitutionById(this.form.controls['selectedInstitution'].value.id);
    });
  }

  setCurrentDate(): void {
    const currentDate = new Date();

    this.form.patchValue({ specificDay: currentDate });
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })

      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }
      }

      this.form.controls['selectedInstitution'].setValue(
        this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
      )
    });
  }

  handlePreviewClick(): void {
    if (this.form.valid) {
      const { specificDay, selectedInstitution } = this.getFormData();

      this.setInfoRP(specificDay, selectedInstitution, true);

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataPolymedicationFarma
      );
    } else {
      this.calls.openSnack(this.error);
    }
  }

  getFormData(): {
    specificDay: string;
    selectedInstitution: string;
  } {

    const specificDay = Utils.BUG_FixDateForTimeZone(moment(this.form.get('specificDay')?.value).startOf('day').toDate())?.toISOString().split(".")[0]
    const { selectedInstitution } = this.form.getRawValue();

    return {
      // @ts-ignore
      specificDay,
      selectedInstitution: selectedInstitution.id,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid) {
      const { specificDay, selectedInstitution } = this.getFormData();

      this.setInfoRP(specificDay, selectedInstitution);
      this.reportService.handlePrintClick(this.reportDataPolymedicationFarma);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handlePdfClick(): void {
    if (this.form.valid) {
      const { specificDay, selectedInstitution } = this.getFormData();
      this.setInfoRP(specificDay, selectedInstitution);
      this.reportService.handlePdfClick(this.reportDataPolymedicationFarma);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handleExcelClick(): void {
    if (this.form.valid) {
      const { specificDay, selectedInstitution } = this.getFormData();

      this.setInfoRP(specificDay, selectedInstitution);
      this.reportService.handleExcelClick(this.reportDataPolymedicationFarma);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  setInfoRP(specificDay: string, selectedInstitution: string, isPreview: boolean = false): void {
    const startDate = specificDay;
    const endDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('specificDay')?.value).endOf('day').toDate())?.toISOString().split(".")[0]
    
    const rp = JSON.stringify({
      LocaleId: '3082',
      StartDate: startDate,
      EndDate: endDate,
      InstitutionId: String(selectedInstitution),
      ReportType: '0',
      PatientId: null,
      IncludeNoBlister: 'false',
    });

    isPreview ? this.reportDataPolymedicationFarma.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    this.reportDataPolymedicationFarma.dataDownloadPDF.dn = this.reportDataPolymedicationFarma.dataRenderReport.dn = `Reporte_polimedicacion_${institution}_${moment(specificDay).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataPolymedicationFarma.dataDownloadExcel.dn = `Reporte_polimedicacion_${institution}_${moment(specificDay).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataPolymedicationFarma.data.rp = rp;
    this.reportDataPolymedicationFarma.dataDownloadPDF.rp = rp;
    this.reportDataPolymedicationFarma.dataDownloadExcel.rp = rp;
    this.reportDataPolymedicationFarma.dataRenderReport.rp = rp;
  }
}
