import { Component } from '@angular/core';

import { BaseComponent } from '../base.component';

import { RoleManagerService } from '@services/role-manager.service';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss', '../../../../base/list/list.component.scss']
})
export class CardsComponent extends BaseComponent {

    constructor(
        override roleManager: RoleManagerService,
    ) {
        super(roleManager);
    }

    

    
}
