import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { DateFormatDirective } from '@directives/date-format.directive';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';

import { AsFormArrayPipe } from '@pipes/as-form-array.pipe';
import { AsFormControlPipe } from '@pipes/as-form-control.pipe';
import { AsFormGroupPipe } from '@pipes/as-form-group.pipe';
import { GetErrorMessagePipe } from '@pipes/get-error-message.pipe';

import { DefaultHttpInterceptor } from '@interceptors/default-http.interceptor';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { ArraySubformComponent } from './array-subform/array-subform.component';
import { CalendarSummaryComponent } from './calendar-summary/calendar-summary.component';
import { CardProcessComponent } from './card-process/card-process.component';
import { CardComponent } from './card/card.component';
import { DateInfoComponent } from './date-info/date-info.component';
import { DatepickerMonthComponent } from './datepicker-month/datepicker-month.component';
import { DiagnogsticsFormComponent } from './diagnogstics-form/diagnogstics-form.component';
import { AlertComponent } from './dialogs/alert/alert.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditReComponent } from './dialogs/edit-re/edit-re.component';
import { FarmaticNumberComponent } from './dialogs/farmatic-number/farmatic-number.component';
import { InteractionsComponent } from './dialogs/interactions/interactions.component';
import { NoteComponent } from './dialogs/note/note.component';
import { OverdoseComponent } from './dialogs/overdose/overdose.component';
import { SendOrderComponent } from './dialogs/send-order/send-order.component';
import { StopPrescriptionComponent } from './dialogs/stop-prescription/stop-prescription.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { DocumentsPreviewUploadComponent } from './documents-preview-upload/documents-preview-upload.component';
import { DropdownSelectInstitutionComponent } from './dropdown-select-institution/dropdown-select-institution.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { EntityReportActionsComponent } from './entity-report-actions/entity-report-actions.component';
import { ExpandResumeGroupComponent } from './expand-resume-group/expand-resume-group.component';
import { ExpandableCardComponent } from './expandable-card/expandable-card.component';
import { ExpansionGroupComponent } from './expansion-group/expansion-group.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormDetailComponent } from './form-detail/form-detail.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormTitleComponent } from './form-title/form-title.component';
import { HeaderComponent } from './header/header.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ImcComponent } from './imc/imc.component';
import { InlineAlertComponent } from './inline-alert/inline-alert.component';
import { InstitutionEditComponent } from './institution-edit/institution-edit.component';
import { ListSubformComponent } from './list-subform/list-subform.component';
import { LoggedUserDetailComponent } from './logged-user-detail/logged-user-detail.component';
import { LoginComponent } from './login/login.component';
import { MedicineEditComponent } from './medicine-edit/medicine-edit.component';
import { MedicineNewComponent } from './medicine-new/medicine-new.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PatientEditComponent } from './patient-edit/patient-edit.component';
import { GeneralComponent as PatientEditGeneralComponent } from './patient-edit/general/general.component';
import { GeneralComponent as MedicineEditGeneralComponent } from './medicine-edit/general/general.component';
import { EpigraphsComponent as MedicineEditEpigraphsComponent } from './medicine-edit/epigraphs/epigraphs.component';
import { NotesComponent as MedicineEditNotesComponent } from './medicine-edit/notes/notes.component';
import { InteractionsComponent as MedicineEditInteractionsComponent } from './medicine-edit/interactions/interactions.component';
import { PharmacyComponent as MedicineEditPharmacyComponent } from './medicine-edit/pharmacy/pharmacy.component';
import { RobotComponent as MedicineEditRobotComponent } from './medicine-edit/robot/robot.component';

import { NotesClinicalComponent as PatientEditNotesClinicalComponent } from './patient-edit/notes-clinical/notes-clinical.component';
import { DiagnosisComponent as PatientEditDiagnosisComponent } from './patient-edit/diagnosis/diagnosis.component';
import { DailyPatternComponent as PatientEditDailyPatternComponent } from './daily-pattern/daily-pattern.component';
import { PrescriptionEditComponent } from './prescription-edit/prescription-edit.component';
import { PrescriptionsTableComponent } from './prescriptions-table/prescriptions-table.component';
import { ProcAuthorizationCancelationComponent } from './proc-authorization-cancelation/proc-authorization-cancelation.component';
import { ProcPlansComponent } from './proc-plans/proc-plans.component';
import { ProcReceptionsComponent } from './proc-receptions/proc-receptions.component';
import { ReIssuesComponent } from './re-issues/re-issues.component';
import { RePatientComponent } from './re-patient/re-patient.component';
import { ReStatusComponent } from './re-status/re-status.component';
import { ReportAnnexComponent } from './report-annex/report-annex.component';
import { ReportAuthBlistersComponent } from './report-auth-blisters/report-auth-blisters.component';
import { ReportAutomaticResetComponent } from './report-automatic-reset/report-automatic-reset.component';
import { ReportBillingComponent } from './report-billing/report-billing.component';
import { ReportChangeMedicationsComponent } from './report-change-medications/report-change-medications.component';
import { ReportClinicComponent } from './report-clinic/report-clinic.component';
import { ReportDebsOrdersComponent } from './report-debs-orders/report-debs-orders.component';
import { ReportElectronicRecipeComponent } from './report-electronic-recipe/report-electronic-recipe.component';
import { ReportForecastComponent } from './report-forecast/report-forecast.component';
import { ReportIefComponent } from './report-ief/report-ief.component';
import { ReportIncontinenceComponent } from './report-incontinence/report-incontinence.component';
import { ReportInstitutionMedComponent } from './report-institution-med/report-institution-med.component';
import { ReportListPatientsComponent } from './report-list-patients/report-list-patients.component';
import { ReportMECComponent } from './report-mec/report-mec.component';
import { ReportMedicamentNoEmblisComponent } from './report-medicament-no-emblis/report-medicament-no-emblis.component';
import { ReportMedicinesMissingComponent } from './report-medicines-missing/report-medicines-missing.component';
import { ReportObsolescenceMedsComponent } from './report-obsolescence-meds/report-obsolescence-meds.component';
import { ReportPDietComponent } from './report-p-diet/report-p-diet.component';
import { ReportPMedicineUseComponent } from './report-p-medicine-use/report-p-medicine-use.component';
import { ReportPMovementsComponent } from './report-p-movements/report-p-movements.component';
import { ReportPatientBoxesComponent } from './report-patient-boxes/report-patient-boxes.component';
import { ReportPescriptionsPEvalComponent } from './report-pescriptions-p-eval/report-pescriptions-p-eval.component';
import { ReportPharmaPlanComponent } from './report-pharma-plan/report-pharma-plan.component';
import { ReportPolimedicationComponent } from './report-polimedication/report-polimedication.component';
import { ReportPolymedicationFarmaComponent } from './report-polymedication-farma/report-polymedication-farma.component';
import { ReportScpComponent } from './report-scp/report-scp.component';
import { ReportSelectComponent } from './report-select/report-select.component';
import { ReportSevereComponent } from './report-severe/report-severe.component';
import { ReportThirdPartiesComponent } from './report-third-parties/report-third-parties.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelectInstitutionComponent } from './select-institution/select-institution.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableFormComponent } from './table-form/table-form.component';
import { TableComponent } from './table/table.component';
import { VitalSignsComponent } from './vital-signs/vital-signs.component';
import { TestSharedComponent } from './test-shared/test-shared.component';
import { TreeComponent } from './tree/tree.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SessionExpireComponent } from './web-sockets/session-expire/session-expire.component';
import { VentasFarmaticComponent } from './ventas-farmatic/ventas-farmatic.component';
import { VersionChangedComponent } from './version-changed/version-changed.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { UltimaREComponent } from './ultima-re/ultima-re.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SubMenuItemComponent } from './sub-menu-item/sub-menu-item.component';
import { ClinicalAnalysisComponent } from './clinical-analysis/clinical-analysis.component';
import { FragilityDependencyComponent } from './fragility-dependency/fragility-dependency.component';
import { TestHaynesComponent } from './test-haynes/test-haynes.component';
import { DrivingSkillComponent } from './driving-skill/driving-skill.component';
import { VaccinesComponent } from './vaccines/vaccines.component';
import { HeaderFilterComponent } from './header-filter/header-filter.component';
import { LazyImgComponent } from './lazy-img/lazy-img.component';
import { DiagnosisComponent } from './patient-edit/diagnosis/diagnosis.component';
import { ClinicCourseComponent } from './clinic-course/clinic-course.component';
import { PatternFormComponent } from './pattern-form/pattern-form.component';
import { LocksComponent } from './patient-edit/locks/locks.component';
import { ListSubformLockComponent } from './list-subform-lock/list-subform-lock.component';
import { BoxLockComponent } from './box-lock/box-lock.component';
import { ListSubformEpigraphsComponent } from './list-subform-epigraphs/list-subform-epigraphs.component';
import { BoxEpigraphsComponent } from './box-epigraph/box-epigraphs.component';
import { ColorLegendComponent } from './color-legend/color-legend.component';

import { BoxDepartamentComponent } from './box-departament/box-departament.component';


import { OperationBarComponent } from './operation-bar/operation-bar.component';
import { DocumentsComponent } from './patient-edit/documents/documents.component';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent as AdminUsersComponent } from './admin/users/users.component';
import { CardsComponent as AdminUsersCardsComponent } from './admin/users/cards/cards.component';
import { ListsComponent as AdminUsersListsComponent } from './admin/users/lists/lists.component';
import { UserComponent as AdminUserComponent } from './admin/user/user.component';
import { GeneralComponent as AdminUserGeneralComponent } from './admin/user/general/general.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { TempPasswordComponent } from './dialogs/temp-password/temp-password.component';


import { GeneralComponent as InstitutionEditGeneralComponent } from './institution-edit/general/general.component';
import { ScheduleComponent as InstitutionEditScheduleComponent } from './institution-edit/schedule/schedule.component';
import { ReComponent as InstitutionEditReComponent } from './institution-edit/re/re.component';
import { PmComponent as InstitutionEditPmComponent } from './institution-edit/pm/pm.component';
import { DocumentsComponent as InstitutionEditDocumentsComponent } from './institution-edit/documents/documents.component';
import { DepartamentsComponent as InstitutionEditDepartamentsComponent } from './institution-edit/departaments/departaments.component';
import { ListSubformDepartamentsComponent } from './list-subform-departaments/list-subform-departaments.component';
import { ProcPlansFormComponent } from './proc-plans/form/form.component';
import { ReportNarcoticsMedicinesComponent } from './report-narcotics-medicines/report-narcotics-medicines.component';
import { ReportMedicationPlanComponent } from './report-medication-plan/report-medication-plan.component';
import { RePatientDebtDetailComponent } from './re-patient-debt-detail/re-patient-debt-detail.component';
import { ReportActusComponent } from './report-actus/report-actus.component';
import { ActusComponent } from './institution-edit/actus/actus.component';
import { ReportFGPPrescriptionsComponent } from './report-fgp-prescriptions/report-fgp-prescriptions.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ChatAssistantComponent } from './chat-assistant/chat-assistant.component';

import { RedZoomModule } from 'ngx-red-zoom';
import { ProcessMedicationRegisterComponent } from './process-medication-register/process-medication-register.component';
import { ModalPatientIncidentsListComponent } from './modal-patient-incidents-list/modal-patient-incidents-list.component';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHttpInterceptor,
      multi: true,
    },
    { 
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, 
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1500,
        disableTooltipInteractivity:true 
      }
    },
    {
      provide: MAT_DATE_FORMATS, 
      useValue: {
        parse: {
          dateInput: 'DD-MM-YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        },
      } 
    }
  ],
  declarations: [
    AsFormControlPipe,
    AsFormGroupPipe,
    AsFormArrayPipe,
    GetErrorMessagePipe,
    TestSharedComponent,
    LoginComponent,
    WelcomeComponent,
    NotFoundComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderFilterComponent,
    FooterComponent,
    MenuItemComponent,
    FormFieldComponent,
    ForgotPasswordComponent,
    SelectInstitutionComponent,
    LoggedUserDetailComponent,
    CardComponent,
    TableComponent,
    PatientEditComponent,
    PatientEditGeneralComponent,
    PatientEditNotesClinicalComponent,
    PatientEditDiagnosisComponent,
    PatientEditDailyPatternComponent,
    MedicineEditGeneralComponent,
    MedicineEditNotesComponent,
    MedicineEditInteractionsComponent,
    MedicineEditEpigraphsComponent,
    MedicineEditEpigraphsComponent,
    MedicineEditPharmacyComponent,
    MedicineEditRobotComponent,
    InstitutionEditScheduleComponent,
    InstitutionEditGeneralComponent,
    InstitutionEditReComponent,
    InstitutionEditPmComponent,
    InstitutionEditDocumentsComponent,
    InstitutionEditDepartamentsComponent,
    DynamicFormComponent,
    ResetPasswordComponent,
    ImcComponent,
    ArraySubformComponent,
    TableFormComponent,
    MedicineEditComponent,
    ExpandResumeGroupComponent,
    CalendarSummaryComponent,
    DateInfoComponent,
    DiagnogsticsFormComponent,
    ConfirmationDialogComponent,
    MedicineNewComponent,
    ConfirmationDialogComponent,
    MedicineNewComponent,
    DateInfoComponent,
    DiagnogsticsFormComponent,
    InstitutionEditComponent,
    ExpansionGroupComponent,
    ListSubformComponent,
    FormDetailComponent,
    ImageModalComponent,
    PrescriptionsTableComponent,
    ReportSelectComponent,
    ReportAuthBlistersComponent,
    ReportViewerComponent,
    ReportPMedicineUseComponent,
    ReportPMovementsComponent,
    ReportPharmaPlanComponent,
    ReportListPatientsComponent,
    ReportMedicinesMissingComponent,
    StopPrescriptionComponent,
    PrescriptionEditComponent,
    ReportSelectComponent,
    ReportAuthBlistersComponent,
    ReportViewerComponent,
    ReportPMedicineUseComponent,
    ReportPMovementsComponent,
    ReportPharmaPlanComponent,
    ReportListPatientsComponent,
    ReportMedicinesMissingComponent,
    ReportPDietComponent,
    ReportPescriptionsPEvalComponent,
    ReportClinicComponent,
    ReportMedicamentNoEmblisComponent,
    ReportPolimedicationComponent,
    ReportIefComponent,
    ReportIncontinenceComponent,
    ReportScpComponent,
    ReportDebsOrdersComponent,
    ReportElectronicRecipeComponent,
    ReportChangeMedicationsComponent,
    PrescriptionsTableComponent,
    DatepickerMonthComponent,
    ProcReceptionsComponent,
    CardProcessComponent,
    DateFormatDirective,
    OverdoseComponent,
    AlertComponent,
    InteractionsComponent,
    EntityReportActionsComponent,
    DocumentsPreviewUploadComponent,
    DocumentCardComponent,
    ProcPlansComponent,
    TreeComponent,
    DropdownSelectInstitutionComponent,
    InlineAlertComponent,
    ReStatusComponent,
    ProcAuthorizationCancelationComponent,
    FarmaticNumberComponent,
    ReportBillingComponent,
    ReportSevereComponent,
    ProcAuthorizationCancelationComponent,
    ReportPatientBoxesComponent,
    ReportObsolescenceMedsComponent,
    ReportMECComponent,
    ReportPolymedicationFarmaComponent,
    ReportInstitutionMedComponent,
    ReportThirdPartiesComponent,
    ReportForecastComponent,
    FormTitleComponent,
    ReportMECComponent,
    ReportPolymedicationFarmaComponent,
    ReportInstitutionMedComponent,
    RePatientComponent,
    EditReComponent,
    RePatientComponent,
    EditReComponent,
    ReIssuesComponent,
    NoteComponent,
    ReportAnnexComponent,
    ReportAutomaticResetComponent,
    ExpandableCardComponent,
    ReportThirdPartiesComponent,
    ReportForecastComponent,
    FormTitleComponent,
    SendOrderComponent,
    SessionExpireComponent,
    VentasFarmaticComponent,
    VersionChangedComponent,
    MaintenanceComponent,
    UltimaREComponent,
    SubMenuItemComponent,
    VitalSignsComponent,
    ListSubformLockComponent,
    ClinicalAnalysisComponent,
    FragilityDependencyComponent,
    TestHaynesComponent,
    DrivingSkillComponent,
    VaccinesComponent,
    LazyImgComponent,
    DiagnosisComponent,
    LocksComponent,
    ClinicCourseComponent,
    BoxLockComponent,
    ListSubformEpigraphsComponent,
    BoxEpigraphsComponent,
    PatternFormComponent,
    OperationBarComponent,
    DocumentsComponent,
    AdminComponent,
    AdminUsersComponent,
    AdminUsersCardsComponent,
    AdminUsersListsComponent,
    AdminUserComponent,
    AdminUserGeneralComponent,
    ListSubformDepartamentsComponent,
    BoxDepartamentComponent,
    TempPasswordComponent,
    ProcPlansFormComponent,
    ReportNarcoticsMedicinesComponent,
    ReportMedicationPlanComponent,
    ColorLegendComponent,
    RePatientDebtDetailComponent,
    ReportActusComponent,
    ActusComponent,
    ReportFGPPrescriptionsComponent,
    ImageViewerComponent,
    ChatAssistantComponent,
    ProcessMedicationRegisterComponent,
    ModalPatientIncidentsListComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatChipsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatProgressBarModule,
    OverlayModule,
    RedZoomModule
  ],
  exports: [
    TestSharedComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SelectInstitutionComponent,
    WelcomeComponent,
    NotFoundComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderFilterComponent,
    FooterComponent,
    CardComponent,
    TableComponent,
    PatientEditComponent,
    PatientEditGeneralComponent,
    PatientEditNotesClinicalComponent,
    PatientEditDiagnosisComponent,
    PatientEditDailyPatternComponent,
    MedicineNewComponent,
    FormDetailComponent,
    ReportSelectComponent,
    ReportAuthBlistersComponent,
    ReportPMedicineUseComponent,
    ReportSelectComponent,
    ReportAuthBlistersComponent,
    ReportPMedicineUseComponent,
    EntityReportActionsComponent,
    FormTitleComponent,
    ReportAutomaticResetComponent,
    LazyImgComponent,
    ClinicCourseComponent,
    PatternFormComponent,
    OperationBarComponent,
    ColorLegendComponent,
    RePatientDebtDetailComponent,
    ChatAssistantComponent
  ],
})
export class SharedModule {}
