export const reportDataListPatients = {
  data: {
    rpp: '/COROTA/PatientList',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/PatientList',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/PatientList',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/PatientList',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

//HAY dos tipos de llamadas, una con clasificación(en casa y mutuas) y otra con categoria ()

export const categoriesMedicaments = [
  { id: 1, label: 'Diabetes', name: 'Diabetes', filter: 'Diabetes' },
  { id: 2, label: 'Alzheimer', name: 'Alzheimer', filter: 'Alzheimer' },
  { id: 3, label: 'Antidepresivos', name: 'NervADP', filter: 'Antidepresivos' },
  { id: 4, label: 'Antipsicóticos', name: 'NervAPS', filter: 'Antipsicoticos' },
  {
    id: 5,
    label: 'Eutimizantes y anticonvulsivantes',
    name: 'NervEUTATC',
    filter: 'EutimizantesAnticonvulsivantes',
  },
  { id: 6, label: 'Ansiolíticos', name: 'NervANS', filter: 'Ansioliticos' },
  { id: 7, label: 'Antibióticos', name: 'Antibio', filter: 'Antibio' },
  {
    id: 8,
    label: 'Anticoagulantes',
    name: 'Anticoag',
    filter: 'Anticoag',
  },
  { id: 9, label: 'Opioides', name: 'Opioide', filter: 'Opioides' },
];

export const clasificationMedicaments = [
  { id: 0, label: 'En casa', name: 'AtHome', filter: 'EnCasa' },
  { id: 1, label: 'MUTUAS', name: 'MUTUAS', filter: 'MUTUAS_MUFACE' },
];
