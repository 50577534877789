<mat-dialog-content class="confirmation-modal-container">
    <h3 class="title info">Cambiar contraseña</h3>
    <div class="content">
        <p *ngIf="!passwordChange">La contraseña que ha utilizado es temporal y debe ser sustituida por una nueva</p>
        <p>
            La contraseña debe tener como mínimo 8 caracteres e incluir al menos una letra máyuscula, una minúscula, un dígito y un caracter especial.
        </p>

        <form>
            <app-form-field type="password" [label]="passwordChange ? 'Contraseña antigua' : 'Código de activación'"
                [fieldControl]="form.controls['activation'] | asFormControl">
            </app-form-field>
            <app-form-field type="password" label="Contraseña nueva"
                [fieldControl]="form.controls['newPwd'] | asFormControl">
            </app-form-field>
            <app-form-field type="password" label="Confirmar contraseña"
                [fieldControl]="form.controls['repeat'] | asFormControl">
            </app-form-field>
        </form>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center" class="confirmation-modal-buttons">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1" [disabled]="!form.valid">
        Cambiar
    </button>
    <button mat-raised-button mat-dialog-close tabindex="-1">
        Cancelar
    </button>
</mat-dialog-actions>
