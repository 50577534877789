<div class="action-content">
    <!-- Date and input type -->
    <div class="input-config" *ngIf="!fieldControl.disabled">
        <app-form-field *ngIf="readOnly ? !readOnly() : true" class="date field" type="date" label="Fecha de diagnóstico"
            [fieldControl]="form.controls['date'] | asFormControl" [readOnly]="readOnly"></app-form-field>

        <mat-button-toggle-group *ngIf="readOnly ? !readOnly() : true" [(ngModel)]="showImportFile">
            <mat-button-toggle [value]="false">Buscar</mat-button-toggle>
            <mat-button-toggle [value]="true">Importar</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <ng-container *ngIf="!fieldControl.disabled">
        <!-- Import -->
        <ng-container *ngIf="showImportFile && (readOnly ? !readOnly() : true); else elseTemplate">
            <div class="import">
                <div class="import-options">
                    <app-form-field class="col-3" type="checkbox" label="Eliminar existentes"
                        [fieldControl]="form.controls['removeCurrent'] | asFormControl"></app-form-field>
                    <app-form-field class="col-4" type="checkbox" label="Usar fecha de diagnóstico"
                        [fieldControl]="form.controls['useDate'] | asFormControl"></app-form-field>
                </div>

                <button mat-stroked-button color="primary" (click)="fileUpload.click()">
                    Archivo
                    <mat-icon fontIcon="upload"></mat-icon>
                </button>
                <input #fileUpload type="file" style="display: none;" accept=".txt,.csv" (change)="uploadFile($event)">
            </div>
        </ng-container>

        <!-- Search -->
        <ng-template #elseTemplate>
            <div class="search">
                <!-- <app-form-field *ngIf="(readOnly ? !readOnly() : true)" class="radio" type="radio" label="Búsqueda"
                    [hideLabel]="true" [fieldControl]="form.controls['searchType'] | asFormControl"
                    [options]="radioOptions"></app-form-field> -->
                <app-form-field *ngIf="(readOnly ? !readOnly() : true)" #autocomplete class="input" type="autocomplete"
                    label="Indique su diagnóstico" [fieldControl]="form.controls['search'] | asFormControl"
                    [dynamicOptions]="{context: getContext(), func: search, debounce: 500}"></app-form-field>
            </div>
        </ng-template>
    </ng-container>
</div>

<div class="table">
    <table mat-table [dataSource]="rows">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th mat-header-cell *matHeaderCellDef>{{column.header}}</th>

            <td mat-cell *matCellDef="let row" class="text-start">
                <ng-container [ngSwitch]="column.columnDef">
                    <!-- Actions column -->
                    <span *ngSwitchCase="'actions'">
                        <button class="delete-button" *ngIf="readOnly ? !readOnly() && !fieldControl.disabled : true" mat-icon-button color="warn"
                            (click)="deleteItem(row)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                    </span>
                    <!-- Date column -->
                    <span *ngSwitchCase="'date'">
                        {{ row[column.columnDef] | date: 'd/M/y' }}
                    </span>
                    <!-- Default column -->
                    <span *ngSwitchDefault>
                        {{ row[column.columnDef] }}
                    </span>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
    </table>
</div>
