<mat-dialog-content class="farmatic-number-modal-container" >
    <h3 class="title">Introducir número FARMATIC</h3>
    <div class="content">
      Introduzca el número de FARMATIC del paciente seleccionado:
      <ul>
        <li>{{patient.PatientName}} ({{patient.PatientNumber}})</li>
      </ul>
      <mat-form-field class="example-full-width">
        <mat-label>Número FARMATIC</mat-label>
        <input matInput
            type="number"
            maxlength="15"
            placeholder="123456789012345"
            [(ngModel)]="farmaticNumber"
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="farmatic-number-modal-buttons">
    <button
      mat-raised-button
      color="primary"
      tabindex="1"
      [disabled]="!farmaticNumber"
      (click)="onConfirmClick()"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      Cancelar
    </button>
  </mat-dialog-actions>
  