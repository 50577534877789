<section [hidden]="loading" style="height: 100%;">
  <section #cards class="cards-wrapper" >
    <ul class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
      <div
        *ngFor="let institution of currentData | slice : lowValue : highValue"
      >
        <app-card
          [title]="institution.name"
          [id]="institution.id"
          [img]=""
          [route]="route"
          [actions]="actions"
          [defaultImg]="'assets/pics/medical_residencia.svg'"
          colorHeader="default"
        >
          <div slot="content">
            <p [routerLink]="route + '/edit/' + institution.id">{{ institution.nameAbbreviation }}</p>
            <p [routerLink]="route + '/edit/' + institution.id">{{ institution.type }}</p>
          </div>
        </app-card>
      </div>
    </ul>
    <ng-container *ngIf="!loading">
      <p *ngIf="currentFilter || currentSearch">
        {{ msgNotFound }}:
        <span class="search-value">{{ currentFilter || currentSearch }}</span>
      </p>
    </ng-container>

    <div class="pagination">
      <mat-paginator
        aria-label="Seleccionar pagina"
        [length]="currentData.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true"
        (page)="getPageEvent($event)"
      >
      </mat-paginator>
    </div>
  </section>
</section>
