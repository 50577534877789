import { CardAction } from '@interfaces/card-actions';

export const routeInstitutions: string = '/global-eye/institutions';

export const actionsInstitution: CardAction[] = [
  {
    id: 'edit',
    name: 'Editar',
    iconName: 'edit',
    routeLink: '/edit',
  },
  {
    id: 'schedules',
    name: 'Horarios',
    iconName: 'schedule',
    routeLink: '/edit',
    queryParam: { tab: 1 },
  },
  {
    id: 'departments',
    name: 'Departamentos',
    iconName: 'apartment',
    routeLink: '/edit',
    queryParam: { tab: 2 },
  },
  {
    id: 'delete',
    name: 'Borrar',
    iconName: 'delete',
    routeLink: '',
  },
];
