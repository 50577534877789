<div class="farmacia">
    <div class="expand" *ngIf="expandAll && !compressAll"  (click)="openExpandedAll()" >expandir todo</div>
    <div class="expand" *ngIf="!expandAll && compressAll" (click)="closedExpandedAll()" >Comprimir todo </div>

    <mat-accordion class="example-headers-align"  multi>
        <mat-expansion-panel *ngFor="let farmacia of farmacias" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{farmacia.Name}}
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon [class.activo-bandeja]="bandeja.checked"
                        matTooltip="Siempre en bandeja">inbox</mat-icon>
                    <mat-icon [class.activo-bloqueo-inst]="$any(bloqueoInst.selected).length > 0"
                        matTooltip="Bloqueado en centros">block</mat-icon>
                    <mat-icon [class.activo-excluir-farmacia]="excluirFarma.checked"
                        matTooltip="Bloqueado en Farmacia">block</mat-icon>
                    <mat-icon
                        [class.activo-sin]="$any(inventario.selected)?.value == 1"
                        [class.activo-limitado]="$any(inventario.selected)?.value == 2"
                        matTooltip="Sin inventario">thumb_down_off_alt</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="content">
                <mat-checkbox #excluirFarma [disabled]="!hasPermission()" [(ngModel)]="farmacia.Exclude" (change)="setFormChanged(true)">Excluir en farmacia</mat-checkbox>
                <mat-form-field subscriptSizing="dynamic">
                    <div class="label">Excluir en residencia</div>
                    <mat-select multiple #bloqueoInst [value]="getBlockedInstitution(farmacia)"
                        (selectionChange)="blockInstitution(farmacia, $event); setFormChanged(true)"
                        placeholder="Seleccione las centros donde se bloqueará el medicamento"
                        [disabled]="!hasPermission()">
                        <div class="select-all" (click)="selectAll('bloq-opt-' + farmacia.FarmaticLinkId)">
                            Seleccionar todo
                        </div>
                        <div class="deselect-all" (click)="deselectAll('bloq-opt-' + farmacia.FarmaticLinkId)">
                            Deseleccionar todo
                        </div>
                        <mat-option #options [attr.opt-id]="'bloq-opt-' + farmacia.FarmaticLinkId" *ngFor="let inst of farmacia.institutionLinks"
                            [value]="inst.InstitutionId">{{inst.ShortName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="inventario">
                    <mat-form-field subscriptSizing="dynamic">
                        <div class="label">Inventario</div>
                        <mat-select #inventario [(value)]="farmacia.NoStock" (selectionChange)="setFormChanged(true)" [disabled]="!hasPermission()">
                            <mat-option
                                *ngFor="let inv of tiposInventario"
                                [value]="inv.value">{{inv.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field subscriptSizing="dynamic"
                        *ngIf="inventario.value != 0 && inventario.value != undefined">
                        <div class="label">Sustituto</div>
                        <mat-select [(value)]="farmacia.NoStockReplace" (selectionChange)="setFormChanged(true)" [disabled]="!hasPermission()">
                            <mat-option
                                *ngFor="let inv of medicineList"
                                [value]="inv.id">{{inv.nam}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-checkbox #bandeja [disabled]="!hasPermission()" [(ngModel)]="farmacia.OnlyTray" (change)="farmacia.Canister = undefined; setFormChanged(true)">Siempre en bandeja</mat-checkbox>
                <mat-form-field subscriptSizing="dynamic" *ngIf="!bandeja.checked">
                    <div class="label">Tolva</div>
                    <input minlength="3" maxlength="3" matInput  [(ngModel)]="farmacia.Canister"
                        placeholder="Código 3 dígitos" (change)="setFormChanged(true)" (keypress)="keyPressHandler($event)" [readonly]="!hasPermission()">
                </mat-form-field>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</div>
