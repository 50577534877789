export type TableType = 'patient' | 'medicine' | 'institution' | 'default';

export const columns: { [key: string]: string } = {
  name: 'Nombre',
  number: 'Número',
  expedition: 'Alta',
  department: 'Departamento',
  imagePath: 'Imagen',
  actions: 'Acciones',
  checkboxs: 'Procesado',
};

export interface TableColumn {
  columnDef: string;
  header: string;
  cell: Function;
}
