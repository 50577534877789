import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionSelectAPI } from '@interfaces/institution/institution.interface';
import { REReviewInstitution } from '@interfaces/re-status.interface';
import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';



const moment = _rollupMoment || _moment;
const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
    },
};

@Component({
    selector: 'app-ventas-farmatic',
    templateUrl: './ventas-farmatic.component.html',
    styleUrls: ['./ventas-farmatic.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class VentasFarmaticComponent implements OnInit {

    public selectedInstitution: REReviewInstitution;
    public institutionOptions: SelectOption[] = [];
    
    tipoVentaOff: 1 | 3 = 1;
    date = new FormControl(moment());
    search?: string = undefined;
    ventas: {ventasNotDone: Array<InstitutionSelectAPI>, ventasDone: Array<InstitutionSelectAPI>};
    toExport: Array<InstitutionSelectAPI> = [];
    @ViewChildren(MatCheckbox) checks: QueryList<MatCheckbox>;

    constructor(
        private institutionService: InstitutionService,
        private calls: CallsService,
        private loading: LoadingService,
        private dialog: ModalService
    ) {
    }

    ngOnInit(): void {
        this.loading.start("Cargando");

        this.calls.getReReviewInstitutions(this.institutionService.getCurrentFarmacy()).subscribe((res) => {
            const institutions: REReviewInstitution[] = res.payload;
            this.institutionOptions = institutions.map(inst => {
              return {
                label: `${inst.Name}`,
                value: inst
              }
            });

            this.institutionOptions = this.institutionOptions.sort((a, b) => a.label.localeCompare(b.label))
            // this.institutionOptions.map((a) =>{
                // a.label = a.label.toUpperCase();
            // })
            this.institutionOptions.unshift({
                label: 'Todos los centros',
                value: 'Todos'
            })

            this.getFarmaticVentasOffDone();
          })

          //@ts-ignore
          this.selectedInstitution =  'Todos';
       
    }

    setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value!;
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.date.setValue(ctrlValue);
        datepicker.close();

        this.getFarmaticVentasOffDone();
    }

    getFarmaticVentasOffDone() {
        if (this.date.value != undefined) {
            this.loading.start("Cargando");
            this.toExport = [];
            this.calls.getFarmaticVentasOffDone(this.date.value.month() + 1, this.date.value.year(), this.tipoVentaOff).subscribe({
                next: (data) => {
                    const ventas: {ventasNotDone: Array<InstitutionSelectAPI>, ventasDone: Array<InstitutionSelectAPI>} = {
                        ventasNotDone: data.payload.ventasNotDone,
                        ventasDone: data.payload.ventasDone
                    };

                    this.ventas = ventas;
                   
                    //@ts-ignore
                    if (this.selectedInstitution !== 'Todos' && this.selectedInstitution !== undefined) {
                        //FILTER Institution
                        this.ventas.ventasDone = this.ventas.ventasDone.filter(c => c.ShortName === this.selectedInstitution.ShortName)
                        this.ventas.ventasNotDone = this.ventas.ventasNotDone.filter(c => c.ShortName === this.selectedInstitution.ShortName)
                    }
                    

                },
                complete: () => this.loading.stop()
            });
        }
    }

    exportar() {
        if (this.date.value == undefined) return;

        this.dialog.openModalCallback(() => {
            this.loading.start("Exportando");
            if (this.date.value != undefined) { // Para que se calle el eslint
                this.calls.insertDebtsVentasOff(this.date.value.month() + 1, this.date.value.year(), this.toExport.map(i => i.Id).join(','), 'PAGO', this.tipoVentaOff).subscribe({
                    next: (data) => {
                        if (data.payload == null || !!data.payload[0]?.ErrorCode) {
                            this.dialog.openModalErrorInfo('Ocurrió un error al exportar las ventas.', 'Error exportando ventas');
                        } else {
                            // TODO Es necesario Farmatic funcionando para saber que hace al finalizar, ahora mismo solo lo movemos
                            const toMove = this.ventas.ventasNotDone.filter(v => this.toExport.includes(v));
                            this.ventas.ventasNotDone = this.ventas.ventasNotDone.filter(v => !toMove.includes(v));
                            this.ventas.ventasDone.push(...toMove);
                        }
                    },
                    error: (err) => {
                        console.error(err);
                    },
                    complete: () => {
                        this.toExport = [];
                        this.checks.forEach(c => c.checked = false);
                        this.loading.stop();
                    }
                });
            }
        }, `
        Se exportarán las ventas del período para los centros seleccionadas.
        ¿Está seguro de que desea continuar?
        `, 'Exportar ventas', false)
    }

    checkChange(evt: MatCheckboxChange, notDone: InstitutionSelectAPI) {
        if (evt.checked) {
            if(!this.toExport.includes(notDone)) this.toExport.push(notDone);
        }
        else {
            this.toExport.splice(this.toExport.indexOf(notDone), 1);
        }
    }

    compareTextWithSearch(shortName: string, id: number) {
        if (this.search == undefined || this.search.trim().length == 0) return true;

        return shortName.toLowerCase().includes(this.search.toLowerCase()) || id.toString().includes(this.search);
    }

    typeChanged(evt: MatTabChangeEvent) {
        if (evt.index == 0) {
            // Pago
            this.tipoVentaOff = 1;
        }
        else if (evt.index == 1) {7
            // Deuda
            this.tipoVentaOff = 3;
        }

        this.getFarmaticVentasOffDone();
    }


    onInstitutionChange(change: MatSelectChange): void {
        // this.loading.start('Cargando centro')
        const institution: REReviewInstitution = change.value;

        this.selectedInstitution = change.value;

        this.calls.getInstitutionReData(institution.Id).subscribe(res => {
      
            this.loading.stop()
            this.getFarmaticVentasOffDone();
        })
      }
}
