import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataBilling } from '@constants/reports/report-billing';
import { showFilters } from '@constants/reports/report-change-medications';
import { schedulesOptions } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { PatientPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

@Component({
  selector: 'app-report-billing',
  templateUrl: './report-billing.component.html',
  styleUrls: ['./report-billing.component.scss'],
})
export class ReportBillingComponent {
  public reportDataBilling = reportDataBilling;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsPatientsWithoutFilter: PatientPharma[];
  public selectOptionsInsitutions: SelectOption[];
  public filtersMedChanges = showFilters;
  public schedulesOptions = schedulesOptions;

  public errorMUTUAS = 'Si eliges MUTUAS, debes de elegir un tipo de receta';

  public dateMax = moment();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {
    this.selectOptionsInsitutions = [];
    this.form = this.formBuilder.group({
      specificDay: [''],
      selectedInstitution: [''],
      reportType: ['0'],
      checkboxPapelRecipe: [''],
      checkboxElectronicRecipe: [''],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setCurrentDate();
    window.addEventListener('lastInvoicingDate', ((evt: CustomEvent) => {
        this.setCurrentDate();
    }) as EventListener);
  }

  setCurrentDate(): void {
    const currentDate = this.reportService.lastInvoicingDate;

    this.form.patchValue({ specificDay: moment(currentDate) });
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })
      if (this.selectOptionsInsitutions.length > 0) {
        const hasAllInstitutionsOption = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (!hasAllInstitutionsOption) {
          this.selectOptionsInsitutions.unshift({
            value: '',
            label: 'Todos los centros',
          });
        }
      }
    });
  }

  handlePreviewClick(): void {
    const reportTypeValid =
      this.form.controls['reportType'].value === '0'
        ? this.form.controls['checkboxPapelRecipe'].value == true ||
          this.form.controls['checkboxElectronicRecipe'].value == true
        : true;
    if (this.form.valid && reportTypeValid) {
      const {
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe,
      } = this.getFormData();

      this.setInfoRP(
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe,
        true
      );

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataBilling
      );
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  getFormData(): {
    specificDay: string;
    selectedInstitutionId: string;
    reportType: string;
    checkboxPapelRecipe: string;
    checkboxElectronicRecipe: string;
  } {
    const specificDay = this.dateService.formatDateToString(
      this.form.get('specificDay')?.value
    );
    const {
      selectedInstitution,
      reportType,
      checkboxPapelRecipe,
      checkboxElectronicRecipe,
    } = this.form.getRawValue();

    const selectedInstitutionId = selectedInstitution ? selectedInstitution.id.toString() : null;

    return {
      specificDay,
      selectedInstitutionId,
      reportType,
      checkboxPapelRecipe,
      checkboxElectronicRecipe,
    };
  }

  handlePrintClick(): void {
    const reportTypeValid =
      this.form.controls['reportType'].value === '0'
        ? this.form.controls['checkboxPapelRecipe'].value == true ||
          this.form.controls['checkboxElectronicRecipe'].value == true
        : true;
    if (this.form.valid && reportTypeValid) {
      const {
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe,
      } = this.getFormData();

      this.setInfoRP(
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe
      );
      this.reportService.handlePrintClick(this.reportDataBilling);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  handlePdfClick(): void {
    const reportTypeValid =
      this.form.controls['reportType'].value === '0'
        ? this.form.controls['checkboxPapelRecipe'].value == true ||
          this.form.controls['checkboxElectronicRecipe'].value == true
        : true;
    if (this.form.valid && reportTypeValid) {
      const {
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe,
      } = this.getFormData();
      this.setInfoRP(
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe
      );
      this.reportService.handlePdfClick(this.reportDataBilling);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  handleExcelClick(): void {
    const reportTypeValid =
      this.form.controls['reportType'].value === '0'
        ? this.form.controls['checkboxPapelRecipe'].value == true ||
          this.form.controls['checkboxElectronicRecipe'].value == true
        : true;
    if (this.form.valid && reportTypeValid) {
      const {
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe,
      } = this.getFormData();

      this.setInfoRP(
        specificDay,
        selectedInstitutionId,
        reportType,
        checkboxPapelRecipe,
        checkboxElectronicRecipe
      );
      this.reportService.handleExcelClick(this.reportDataBilling);
    } else {
      this.calls.openSnack(this.errorMUTUAS);
    }
  }

  setInfoRP(
    specificDay: string,
    selectedInstitution: string,
    reportType: string,
    checkboxPapelRecipe: string,
    checkboxElectronicRecipe: string,
    isPreview: boolean = false
  ): void {
    const rp = JSON.stringify({
      LocaleId: '3082',
      ReportType: parseInt(reportType),
      Month: (moment(specificDay).month() + 1).toString(),
      Year: moment(specificDay).year().toString(),
      InstitutionId: selectedInstitution == '' ? null : selectedInstitution,
      MutuasPaper: checkboxPapelRecipe == '' ? 'false' : 'true',
      MutuasRE: checkboxElectronicRecipe == '' ? 'false' : 'true',
    });

    isPreview ? this.reportDataBilling.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    let type: string = '';
    switch( parseInt(reportType) ) {
      case 0: type = 'MUTUAS'; break;
      case 1: type = 'PAGO'; break;
      case 2: type = 'CAP'; break;
    }

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    this.reportDataBilling.dataDownloadPDF.dn = this.reportDataBilling.dataRenderReport.dn = `Reporte_de_facturacion_${type}_${institution}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataBilling.dataDownloadExcel.dn = `Reporte_de_facturacion_${type}_${institution}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    this.reportDataBilling.data.rp = rp;
    this.reportDataBilling.dataDownloadPDF.rp = rp;
    this.reportDataBilling.dataDownloadExcel.rp = rp;
    this.reportDataBilling.dataRenderReport.rp = rp;
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.form.get('specificDay')?.value;
    ctrlValue.month(normalizedMonth.month());
    this.form.controls['specificDay'].setValue(ctrlValue);
    datepicker.close();
  }
}
