
export abstract class ListComponent {

  abstract currentData: any[];

  currentSort: string | undefined;
  currentSortMode: 'asc' | 'desc' | '' = '';

  /**
   * Prepare sort array of objects by key
   * @param key 
   */
  sortBy(key: string) {
    if ( this.currentSort !== key ) {
      this.currentSortMode = '';
      this.currentSort = key;
    }

    this.currentSortMode === 'asc'
      ? this.currentSortMode = 'desc'
      : this.currentSortMode = 'asc';

    this.sort();
  }

  /**
   * Sort array of objects with current sort configuration
   */
  sort() {
    if ( this.currentSort ) {
      this.currentData = this.currentData.sort((a: any, b: any) => {
        let elementA, elementB;

        // @ts-ignore
        a[this.currentSort] === null ? a[this.currentSort] = '' : null;
        // @ts-ignore
        b[this.currentSort] === null ? b[this.currentSort] = '' : null;

        // @ts-ignore
        if ( a[this.currentSort] !== '' && !isNaN(Number(a[this.currentSort])) ) {
          // @ts-ignore
          elementA = Number(a[this.currentSort]);
          // @ts-ignore
          elementB = Number(b[this.currentSort]);
        } else {
          // @ts-ignore
          elementA = a[this.currentSort].toString().toLowerCase();
          // @ts-ignore
          elementB = b[this.currentSort].toString().toLowerCase();
        }

        if (elementA < elementB) return this.currentSortMode === 'asc' ? -1 : 1
        if (elementA > elementB) return this.currentSortMode === 'asc' ? 1 : -1
        return 0;
      });
    }
  }
}
