import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { replaceLastCharacter } from '@constants/funtions-utils';
import {
  GROUP_LOG_OPTIONS,
  INTEGRATION_LOG_OPTIONS,
  INTERVAL_LOG_OPTIONS,
  LOG_OPTIONS,
} from '@constants/options';
import {
  mapCNColumns,
  reportDataThirdParties,
  rppThirdPartiesBase,
} from '@constants/reports/report-third-parties';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
  DrugIdMap,
  ThirdPartyDrugPetition,
} from '@interfaces/report/report.interface';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { ReportsService } from '@services/reports/reports.service';
import { Subscription } from 'rxjs';
import { TableColumn } from '@interfaces/table.interface';
import { Utils } from '@json/src/app/Utils';
import moment from 'moment';

@Component({
  selector: 'app-report-third-parties',
  templateUrl: './report-third-parties.component.html',
  styleUrls: ['./report-third-parties.component.scss'],
})
export class ReportThirdPartiesComponent {
  public formThirdPartiesLogs: FormGroup;
  public formMapCN: FormGroup;
  public radioOptions: SelectOption[] = INTERVAL_LOG_OPTIONS;
  public logOptions: SelectOption[] = LOG_OPTIONS;
  public groupOptions: SelectOption[] = GROUP_LOG_OPTIONS;
  public integrationOptions: SelectOption[] = INTEGRATION_LOG_OPTIONS;
  public rppThirdPartiesBase = rppThirdPartiesBase;

  public reportDataThirdParties = reportDataThirdParties;
  public formSubscription: Subscription[] = [];

  public tableColumns: TableColumn[] = mapCNColumns;
  public dataTableDrug: DrugIdMap[];
  Utils = Utils;

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportsService,
    private router: Router,
    private dateService: DateService,
    private calls: CallsService
  ) {
    this.formThirdPartiesLogs = this.formBuilder.group({
      integration: [0],
      group: [0],
      log: [0],
      institution: [null],
      interval: [0],
      startDate: [new Date()],
      endDate: [new Date()],
    });

    this.formMapCN = this.formBuilder.group({
      drugIdTP: ['', Validators.minLength(6)],
      drugIdMaster: [''],
      drugIdInv: [''],
    });
    this.setListenerMapCode();
  }

  handlePreviewClick(): void {
    this.updateData(true);
    this.reportService.handlePreviewClick(
      this.router,
      this.reportDataThirdParties
    );
  }

  setListenerMapCode(): void {
    this.formMapCN.controls['drugIdTP'].valueChanges.subscribe((value) => {
      this.resetInfoCNForm();
      if (value?.length === 6) {
        this.getThirdPartyDrugIdMap(value);
      }
    });
  }

  getThirdPartyDrugIdMap(value: string): void {
    const jss: ThirdPartyDrugPetition = { DrugIdTP: value };
    this.calls.getThirdPartyDrugIdMap(JSON.stringify(jss)).subscribe((res) => {
      const { DrugIdMaster, DrugIdInvc, DrugIdMap } = res.payload;

      this.formMapCN.controls['drugIdMaster'].patchValue(DrugIdMaster);
      this.formMapCN.controls['drugIdInv'].patchValue(DrugIdInvc);
      this.dataTableDrug = DrugIdMap;
    });
  }
  handlePrintClick(): void {
    this.updateData();
    this.reportService.handlePrintClick(this.reportDataThirdParties);
  }

  handlePdfClick(): void {
    this.updateData();
    this.reportService.handlePdfClick(this.reportDataThirdParties);
  }

  handleExcelClick(): void {
    this.updateData();
    this.reportService.handleExcelClick(this.reportDataThirdParties);
  }

  getRPP(rppBase: string, logValue: number): string {
    return replaceLastCharacter(rppBase, logValue.toString());
  }

  getFormData(): {
    startDate: string;
    endDate: string;
    log: string;
    interval: string;
  } {
    const { startDate, endDate, log, interval } =
      this.formThirdPartiesLogs.getRawValue();

    return {
      startDate,
      endDate,
      log,
      interval,
    };
  }

  updateData(isPreview: boolean = false): void {
    const { startDate, endDate, log, interval } = this.getFormData();

    isPreview ? this.reportDataThirdParties.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    this.reportDataThirdParties.dataDownloadPDF.dn = this.reportDataThirdParties.dataRenderReport.dn = `TpLog_GCR_Prescr_PrescrSinMapa_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataThirdParties.dataDownloadExcel.dn = `TpLog_GCR_Prescr_PrescrSinMapa_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    const rp = this.getRP(startDate, endDate, parseInt(interval));
    this.reportDataThirdParties.data.rp = rp;
    this.reportDataThirdParties.dataDownloadPDF.rp = rp;
    this.reportDataThirdParties.dataDownloadExcel.rp = rp;
    this.reportDataThirdParties.dataRenderReport.rp = rp;

    const rpp = this.getRPP(this.rppThirdPartiesBase, parseInt(log));
    this.reportDataThirdParties.data.rpp = rpp;
    this.reportDataThirdParties.dataDownloadPDF.rpp = rpp;
    this.reportDataThirdParties.dataDownloadExcel.rpp = rpp;
    this.reportDataThirdParties.dataRenderReport.rpp = rpp;
  }

  getRP(startDate: string, endDate: string, interval: number): string {
    const startDateRP =
      interval == 0
        ? null
        : this.dateService.formatDateToString(new Date(startDate));
    const endDateRP =
      interval == 0
        ? null
        : this.dateService.formatDateToString(new Date(endDate));

    const rp = JSON.stringify({
      LoggedFrom: startDateRP,
      LoggedUntil: endDateRP,
    });
    return rp;
  }

  resetInfoCNForm(): void {
    this.dataTableDrug = [];
    this.formMapCN.controls['drugIdMaster'].patchValue('');
    this.formMapCN.controls['drugIdInv'].patchValue('');
  }
}
