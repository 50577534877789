<!-- <div class="watermark">
    EN DESARROLLO
</div> -->

<div class="header">
    <div class="title">{{title}}</div>
    <div class="subtitle">SISTEMA INTEGRAL DE GESTIÓN FARMACOLÓGICA</div>
</div>

<!-- <div class="institution-selector" *ngIf="isPharma">
    <div class="selector">
        <app-form-field class="field dropdownFilter" [type]="'select'" [label]="'Centro'" [hideLabel]="false"
            [fieldControl]="instSelector | asFormControl" [options]="institutions">
        </app-form-field>
    </div>
</div> -->

<div class="item small with-mini-items">
    <div class="mini-item institution" *ngIf="!isPharma">
        <div class="content">
            {{ currentInstitutionName }}
        </div>
    </div>

    <div class="mini-item institution-selector" *ngIf="isPharma">
        <div class="content">
            <div class="selector">
                <app-form-field class="field dropdownFilter" [type]="'select'" [label]="'Centro'" [hideLabel]="false"
                    [fieldControl]="instSelector | asFormControl" [options]="institutions">
                </app-form-field>
            </div>
        </div>
    </div>

    <div class="mini-item occupation">
        <div class="title">Ocupación (Hoy)</div>
        <div class="content">
            <span class="data" *ngIf="occupationCallEnded">{{occupationToday}}</span>
            <mat-spinner *ngIf="!occupationCallEnded"></mat-spinner>
        </div>
    </div>
</div>

<div class="item medium distribution">
    <div class="title">Distribución (Hoy)</div>
    <div class="content">
        <div class="graph">
            <canvas [hidden]="!occupationCallEnded" id="distribution"></canvas>
        </div>
        <mat-spinner *ngIf="!occupationCallEnded"></mat-spinner>
    </div>
</div>

<div class="item large occupation-evolution">
    <div class="title">Evolución Ocupación</div>
    <div class="content">
        <div class="graph">
            <canvas [hidden]="!occupationCallEnded" id="occupation"></canvas>
        </div>
        <mat-spinner *ngIf="!occupationCallEnded"></mat-spinner>
    </div>
</div>

<div class="item small with-mini-items">
    <div class="mini-item ief">
        <div class="title">IEF</div>
        <div class="content">
            <span class="data" *ngIf="medCallEnded">{{ief || 0}}</span>
            <mat-spinner *ngIf="!medCallEnded"></mat-spinner>
        </div>
    </div>

    <div class="mini-item debt">
        <div class="title">Deuda Farmaceutica (Hoy)</div>
        <div class="content">
            <span class="data" *ngIf="debtCallEnded">{{debt}} €</span>
            <mat-spinner *ngIf="!debtCallEnded"></mat-spinner>
        </div>
    </div>
</div>

<div class="item medium polypharmacy">
    <div class="title">Polimedicación (Hoy)</div>
    <div class="content">
        <div class="subitem">
            <div class="label">%Polimedicados</div>
            <div class="data" *ngIf="medCallEnded">{{polimed}}%</div>
        </div>
        <div class="subitem">
            <div class="label">%Hiperpolimedicados</div>
            <div class="data" *ngIf="medCallEnded">{{hiperpolimed}}%</div>
        </div>
        <mat-spinner *ngIf="!medCallEnded"></mat-spinner>
    </div>
</div>

<div class="item large monthly-evolution">
    <div class="title">Evolución Mensual IEF</div>
    <div class="content">
        <div class="graph">
            <canvas [hidden]="!medCallEnded" id="ief"></canvas>
        </div>
        <mat-spinner *ngIf="!medCallEnded"></mat-spinner>
    </div>
</div>
