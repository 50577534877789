<mat-dialog-content class="overdose-modal-container">
	<h3 class="title">Dosis excesiva</h3>
	<div class="content">
        <ng-container [ngSwitch]="overdoseType">
            <div *ngSwitchCase="'intake'">
                Hay dosis horarias que exceden los {{ maxIntake }} comprimidos y la posologia recomendada por el fabricante.
            </div>
            <div *ngSwitchCase="'day'">
                Hay dosis diarias que exceden los {{ maxDay }} comprimidos y la posologia recomendada por el fabricante.
            </div>
            <div *ngSwitchCase="'both'">
                Las dosis horarias y diarias exceden la posologia recomendada por el fabricante.
            </div>
        </ng-container>

        <div>Puede consultar la posología haciendo clic en el icono verde (<mat-icon>description</mat-icon>) que aparece a la izquierda del código del medicamento, en la parte superior del editor de prescripciones. </div>

        <div>¿Está seguro de que desea guardar la prescripción?</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="overdose-modal-buttons">
	<button mat-raised-button class="button-confirm" (click)="onConfirmClick()" tabindex="1">Sí</button>
	<button mat-raised-button mat-dialog-close tabindex="-1">No</button>
</mat-dialog-actions>