import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

@Component({
  selector: 'app-medicine-edit-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss']
})
export class InteractionsComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  interactionTitle: string | null = null;
  interactionInfo: string = '';

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
  
    this.subs.push(
      this.schema[1].fieldControl.valueChanges.subscribe((data) => {
        this.interactionTitle = data.title;
        this.interactionInfo = data.info;
      })
    )
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
