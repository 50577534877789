import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OriginType } from '@interfaces/api/api.interface';
import { CorotaRole, UserRole } from '../enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class RoleManagerService {

  private _origin: OriginType;

  constructor(
    private router: Router,
  ) { }

  setOrigin(origin: OriginType): void {
    this._origin = origin;
    localStorage.setItem('origin', this._origin);
  }

  getOrigin(): OriginType {
    return this._origin;
  }

  isQuantum(): boolean {
    return this._origin === 'quantum';
  }
  isPharma(): boolean {
    return this._origin === 'pharma';
  }

  navigateLoggedIn(): void {  
    if (this._origin === 'pharma') {
      this.router.navigate(['global-eye/welcome']);
      localStorage.setItem('origin', this._origin);
    } else {
      this.router.navigate(['select-institution']);
      localStorage.setItem('origin', this._origin);
    }
  }

  showHeaderSelectInstitution(): boolean {
    return this._origin === 'quantum';
  }
  showPatientsSelectInstitution(): boolean {
    return this._origin === 'pharma';
  }

  translateCorotaRoles(corotaRoles: CorotaRole[] = []): UserRole[] {
    if ( !corotaRoles ) {
      return [];  // BREAK EXECUTION
    }

    let userRoles: UserRole[] = [];

    if ( this.isPharma() ) {
      // Titular
      if ( corotaRoles.includes(CorotaRole.Admin) ) {
        userRoles.push(UserRole.Titular);
      }
      // Farmaceutico
      if ( corotaRoles.includes(CorotaRole.AdminRE) && corotaRoles.includes(CorotaRole.Reportes) && corotaRoles.includes(CorotaRole.Revisor) ) {
        userRoles.push(UserRole.Farmaceutico);
      }
      // Tecnico
      if ( corotaRoles.includes(CorotaRole.Adm) ) {
        userRoles.push(UserRole.Tecnico);
      }
      // Admin
      if ( corotaRoles.includes(CorotaRole.Admin) && corotaRoles.includes(CorotaRole.AdminRE) && corotaRoles.length === 2 ) {
        userRoles = [];
        userRoles.push(UserRole.Admin);
      }
    } else {
      // Admin
      if ( corotaRoles.includes(CorotaRole.Admin) ) {
        userRoles.push(UserRole.Admin);
      }
      // Adm
      if ( corotaRoles.includes(CorotaRole.Adm) ) {
        userRoles.push(UserRole.Adm);
      }
      // Incontinencia
      if ( corotaRoles.includes(CorotaRole.Incontinencia) ) {
        userRoles.push(UserRole.Incontinencia);
      }
      // Medicacion ó Due (solo puede ser una de las dos)
      if ( corotaRoles.includes(CorotaRole.Medicacion) ) {
        userRoles.push(UserRole.Medicacion);
      } else if ( corotaRoles.includes(CorotaRole.Due) ) {
        userRoles.push(UserRole.Due);
      }
      // PDA
      if ( corotaRoles.includes(CorotaRole.PDA) ) {
        userRoles.push(UserRole.PDA);
      }
      // Reportes
      if ( corotaRoles.includes(CorotaRole.Reportes) ) {
        userRoles.push(UserRole.Reportes);
      }
      // Revisor
      if ( corotaRoles.includes(CorotaRole.Revisor) ) {
        userRoles.push(UserRole.Revisor);
      }
      // IPF
      if ( corotaRoles.includes(CorotaRole.IPF) ) {
        userRoles.push(UserRole.IPF);
      }
    }

    return userRoles;
  }
  translateUserRoles(userRoles: UserRole[] = []): CorotaRole[] {
    if ( !userRoles ) {
      return [];  // BREAK EXECUTION
    }

    const corotaRoles: CorotaRole[] = [];

    if ( this.isPharma() ) {
      // Titular
      if ( userRoles.includes(UserRole.Titular) ) {
        corotaRoles.push(CorotaRole.Admin);
      }
      // Farmaceutico
      if ( userRoles.includes(UserRole.Farmaceutico) ) {
        corotaRoles.push(CorotaRole.AdminRE);
        corotaRoles.push(CorotaRole.Reportes);
        corotaRoles.push(CorotaRole.Revisor);
      }
      // Admin
      if ( userRoles.includes(UserRole.Admin) ) {
        corotaRoles.push(CorotaRole.Admin);
        corotaRoles.push(CorotaRole.AdminRE);
      }
      // Tecnico
      if ( userRoles.includes(UserRole.Tecnico) ) {
        corotaRoles.push(CorotaRole.Adm);
      }
    } else {
      // Admin
      if ( userRoles.includes(UserRole.Admin) ) {
        corotaRoles.push(CorotaRole.Admin);
      }
      // Adm
      if ( userRoles.includes(UserRole.Adm) ) {
        corotaRoles.push(CorotaRole.Adm);
      }
      // Incontinencia
      if ( userRoles.includes(UserRole.Incontinencia) ) {
        corotaRoles.push(CorotaRole.Incontinencia);
      }
      // Medicacion ó Due (solo puede ser una de las dos)
      if ( userRoles.includes(UserRole.Medicacion) ) {
        corotaRoles.push(CorotaRole.Medicacion);
      } else if ( userRoles.includes(UserRole.Due) ) {
        corotaRoles.push(CorotaRole.Due);
      }
      // PDA
      if ( userRoles.includes(UserRole.PDA) ) {
        corotaRoles.push(CorotaRole.PDA);
      }
      // Reportes
      if ( userRoles.includes(UserRole.Reportes) ) {
        corotaRoles.push(CorotaRole.Reportes);
      }
      // Revisor
      if ( userRoles.includes(UserRole.Revisor) ) {
        corotaRoles.push(CorotaRole.Revisor);
      }
      // IPF
      if ( userRoles.includes(UserRole.IPF) ) {
        corotaRoles.push(CorotaRole.IPF);
      }
    }

    return corotaRoles;
  }
}
