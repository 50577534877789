<div class="subform-array">
    <div class="calendar-column" *ngIf="hasCalendar">
        <div class="subform-array__calendar-container" [class]="showCalendar ? 'col-3' : ''">
            <div class="d-flex align-items-center ms-2" *ngIf="!hideShowCalendar">
                <mat-icon fontIcon="event" color="primary" class="me-3"></mat-icon>
                <mat-slide-toggle [(ngModel)]="showCalendar"></mat-slide-toggle>
            </div>
            <app-calendar-summary *ngIf="showCalendar" [formArray]="formArray" [config]="formConfig.calendarConfig"
                (dateSelected)="updateDate($event)" (viewChanges)="filterItems($event)"></app-calendar-summary>
        </div>
    </div>
    <div class="controls-columns">
        <button mat-mini-fab *ngIf="!hideAddButton && buttonAtStart && (readOnly ? !readOnly() : true)" color="primary" class="subform-array__button-add"
            (click)="addOne()">
            <mat-icon>add</mat-icon>
        </button>
        <ng-container *ngFor="let item of formArray.controls; let i = index">
            <div *ngIf="applyFilter(item)" [style.order]="itemPositions[i]"
                [style.display]="itemVisibility[i] ? 'unset' : 'none'" class="subform-array__subform">
                <app-dynamic-form [schema]="forms[i].schema"></app-dynamic-form>
                <button *ngIf="canDelete && (readOnly ? !readOnly() : true)" mat-mini-fab class="subform-array__subform__button-delete" color="primary" (click)="delete(i)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
        </ng-container>

        <button mat-mini-fab *ngIf="!hideAddButton && !buttonAtStart && (readOnly ? !readOnly() : true)" color="primary" class="subform-array__button-add"
            (click)="addOne()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
