<div id="login-component" class="container-fluid h-100 d-flex flex-column justify-content-between">
  <div class="row"></div>
  <form class="row d-flex justify-content-center flex-grow" [formGroup]="form" (submit)="submit()">
    <div class="col-8 col-md-4">
      <div class="row mb-5">
        <div class="w-100 d-flex justify-content-center">
          <div class="logo-container"></div>
        </div>
      </div>

      <div class="form-container p-4">
        <div class="row">
          <h3 class="mb-4">Iniciar sesión</h3>
        </div>

        <div class="row">
          <app-form-field 
            type="text" 
            label="Usuario" 
            placeholder="Usuario" 
            errorMsg="'Usuario' es un campo requerido"
            [fieldControl]="form.controls['user'] | asFormControl"
            [hideRequiredAsterisk]="true">
          </app-form-field>
        </div>

        <div class="row">
          <app-form-field 
            type="password" 
            label="Contraseña" 
            errorMsg="'Contraseña' es un campo requerido"
            [fieldControl]="form.controls['password'] | asFormControl"
            [hideRequiredAsterisk]="true">
          </app-form-field>
        </div>

        <!-- <div class="row p-bs cannot-access">
          <a class="text-end text-xs" [routerLink]="[ '/forgot-password' ]">¿No puede acceder a su cuenta?
          </a>
        </div> -->

        <div class="row p-bs">
          <button mat-raised-button color="primary">Entrar</button>
        </div>
      </div>
    </div>
  </form>
  <app-footer></app-footer>

  <div id="loading-spinner">
    <mat-spinner></mat-spinner>
    <div class="loading-spinner__message">Cargando</div>
  </div>
</div>
