export enum PermissionType {
    READ,
    WRITE
}

export enum Permission {
    DASHBOARD,
    PATIENTS,
    PATIENTS_GENERAL,
    PATIENTS_CLINICAL,
    PATIENTS_DIAGNOSTIC,
    PATIENTS_PATTERN,
    PATIENTS_BLOCKS,
    PATIENTS_PRESCRIPTIONS,
    PATIENTS_DOCUMENTS,
    PATIENTS_PF,
    PROCS_MENU,
    PROCS_PLANS,
    PROCS_ALTAS,
    PROCS_VENTAS,
    PROCS_RE,
    REPORTS,
    REPORTS_PATIENTS,
    REPORTS_RESI,
    REPORTS_FARMA,
    REPORT_INCONTINENCIA,
    REPORTS_MED,
    REPORT_FGP,
    INSTITUTIONS,
    INSTITUTIONS_GENERAL,
    INSTITUTIONS_SCHEDULES,
    INSTITUTIONS_ACTUS,
    INSTITUTIONS_DEPARTMENTS,
    INSTITUTIONS_RE,
    INSTITUTIONS_PM,
    INSTITUTIONS_DOCUMENTS,
    INSTITUTIONS_FGP,
    VADEMECUM,
    VADEMECUM_GENERAL,
    VADEMECUM_NOTES,
    VADEMECUM_EPIGRAPHS,
    VADEMECUM_INTERACTIONS,
    VADEMECUM_PHARMACY,
    VADEMECUM_ROBOT,
    USERS,
    ADMINISTRATION,
    PROCESSES,
    PROCESSES_MEDICATION_REGISTER,
}
