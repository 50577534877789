import { DateService } from '@services/date.service';
import {
    Component,
    ViewChild,
    Input,
    OnInit,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    AfterViewInit,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatCalendar, MatCalendarUserEvent } from '@angular/material/datepicker';
import {
    CalendarEventInterface,
    FormConfigCalendarSummaryInterface,
} from '@interfaces/array.form.interface';
import { Subscription } from 'rxjs';
import moment, { Moment } from 'moment';
import { Utils } from '@json/src/app/Utils';

@Component({
    selector: 'app-calendar-summary',
    templateUrl: './calendar-summary.component.html',
    styleUrls: ['./calendar-summary.component.scss'],
})
export class CalendarSummaryComponent
    implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() formArray: FormArray;
    @Input() config?: FormConfigCalendarSummaryInterface;
    @Output() dateSelected = new EventEmitter<CalendarEventInterface>();
    @Output() viewChanges = new EventEmitter<Date | null>();

    @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;

    public dateField: string;
    public canSelect: () => boolean;
    public usedToFilter: boolean;
    selected?: Moment = undefined;

    private subs: Subscription[] = [];
    constructor(private dateService: DateService) { }

    ngOnInit(): void {
        this.dateField = this.config?.dateField ?? 'date';
        this.canSelect = this.config?.canSelect ?? (() => false);
        this.usedToFilter = this.config?.usedToFilter ?? false;
        if (this.usedToFilter) this.selected = moment();
    }

    ngOnDestroy(): void {
        this.clearSubs();
    }

    ngAfterViewInit(): void {
        this.setSubs();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formArray']) {
            this.clearSubs();
            this.setSubs();
        }
    }

    setSubs(): void {
        this.subs.push(
            this.formArray.valueChanges.subscribe(() => {
                this.calendar.updateTodaysDate();
            })
        );
        if (this.calendar) {
            this.subs.push(
                this.calendar.stateChanges.subscribe(() => this.emitViewDate())
            );
        }
    }

    clearSubs(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    getClass(data: { [key: string]: any }): string {
        let result = 'selected ';
        const classes = this.config?.classes ?? [];
        Object.keys(data).forEach((fieldName) => {
            const fieldClasses = classes.filter(
                (c) => c.field === fieldName && data[fieldName] === c.fieldValue
            );
            fieldClasses.forEach((c) => {
                result += `${c.class} `;
            });
        });
        return result;
    }

    isSelected = (event: Date): string => {
        const date = this.formArray.value.find((x: any) => {
            return (
                this.getDateString(x[this.dateField]) === this.getDateString(event)
            );
        });
        return date ? this.getClass(date) : '';
    };

    select(event: Date | null): void {
        if (event && this.canSelect()) {
            const index = this.formArray.value.findIndex((x: any) => {
                return (
                    this.getDateString(x[this.dateField]) === this.getDateString(event)
                );
            });
            if (index < 0) {
                this.dateSelected.emit({ action: 'add', date: event, index: 0 });
            } else {
                this.dateSelected.emit({ action: 'remove', date: event, index });
            }
        }
        if (this.usedToFilter) {
            this.calendar.selected = event;
        }
    }

    getDateString(date: any | null): string {
        if (!(date instanceof Date) && date) {
            date = this.dateService.transformObjectToDate(date);
        }
        return date
            ? date.getFullYear() +
            '-' +
            ('00' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + date.getDate()).slice(-2)
            : '';
    }

    emitViewDate(): void {
        this.viewChanges.emit(this.calendar.activeDate);
    }

    selectDate(date: Moment) {
        this.selected = date
    }
}
