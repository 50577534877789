<mat-dialog-content class="confirmation-modal-container">
  <h3 class="title" [ngClass]="type">{{ title }}</h3>
  <p class="content">
    Se exportará el pedido que se corresponde con los parámetros seleccionados.
    Si no desea exportar el pedido al Distribuidor, seleccione la casilla de
    abajo.
  </p>

  <form>
    <app-form-field
      type="checkbox"
      label="No enviar orden al Distribuidor"
      [fieldControl]="form.controls['useDate'] | asFormControl"
    >
    </app-form-field>
  </form>

  <p>
    Este pedido ya se exportó anteriormente y podría duplicarse ¿Está seguro de
    que desea continuar?
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="confirmation-modal-buttons">
  <button
    mat-raised-button
    [color]="colorButtonOk"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ confirmButtonText }}
  </button>
  <button
    *ngIf="!data.onlyInfo"
    mat-raised-button
    mat-dialog-close
    tabindex="-1"
  >
    {{ cancelButtonText }}
  </button>
</mat-dialog-actions>
