<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de agudos'"
    [disabled]="!!errorInterval"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
  <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section>

      
        <section class="interval">
        <p class="subtitle">Intervalo</p>

          <mat-form-field class="marginBottom">
            <div class="label">Desde</div>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <div class="label">Hasta</div>
            <input
              matInput
              [matDatepicker]="endDatePicker"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </section>
        <section class="errors">
          <app-inline-alert
            *ngIf="!!errorInterval"
            type="error"
            [message]="errorInterval"
          ></app-inline-alert>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
