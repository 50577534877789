export const reportDataActus = {
  data: {
    rpp: '',  // /COROTA/PdaMaster || /COROTA/PdaResultIssuesByUsers || /COROTA/PdaResultIssuesByPatients || /COROTA/PdaResultIssuesByIssues || /COROTA/PdaResultIssuesByDep
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '',  // /COROTA/PdaMaster || /COROTA/PdaResultIssuesByUsers || /COROTA/PdaResultIssuesByPatients || /COROTA/PdaResultIssuesByIssues || /COROTA/PdaResultIssuesByDep
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '',  // /COROTA/PdaMaster || /COROTA/PdaResultIssuesByUsers || /COROTA/PdaResultIssuesByPatients || /COROTA/PdaResultIssuesByIssues || /COROTA/PdaResultIssuesByDep
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '',  // /COROTA/PdaMaster || /COROTA/PdaResultIssuesByUsers || /COROTA/PdaResultIssuesByPatients || /COROTA/PdaResultIssuesByIssues || /COROTA/PdaResultIssuesByDep
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};
