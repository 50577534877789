import { DateService } from '@services/date.service';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    ArrayFormSortConfigInterface,
    CalendarEventInterface,
    FormConfigArrayInterface,
} from '@interfaces/array.form.interface';
import {
    FormItemSchemaInterface,
    FormBuilderInterface,
} from '@interfaces/dynamic-form.interface';
import { FormService } from '@services/form.service';
import { SortService } from '@services/sort.service';
import { Subscription } from 'rxjs';
import moment, { isMoment } from 'moment';
import { sameErrorValidator } from '@validators/custom-validators.validators';
import { TableFormComponent } from '@shared/table-form/table-form.component';

@Component({
    selector: 'app-array-subform',
    templateUrl: './array-subform.component.html',
    styleUrls: ['./array-subform.component.scss'],
})
export class ArraySubformComponent implements OnInit, OnDestroy {
    @Input() formConfig: any | FormConfigArrayInterface;
    @Input() fieldControl: FormControl;
    @Input() readOnly?: () => boolean = () => false;
    buttonAtStart = false;
    calenderUsedForFilter = false;
    selectedDate = moment();

    private schema: FormBuilderInterface = {};
    public forms: { form: FormGroup; schema: FormItemSchemaInterface[] }[] = [];
    public formArray: FormArray = new FormArray<any>([]);
    filteredControls: Array<AbstractControl<any>> = [];

    public hasCalendar: boolean = false;
    public showCalendar: boolean = false;
    hideShowCalendar: boolean = false;
    public hideAddButton: boolean = false;
    public lastCalendarDate: Date = new Date();

    public sortConfig: ArrayFormSortConfigInterface;
    public itemPositions: number[] = [];
    public itemVisibility: boolean[] = [];

    protected subs: { [key: string]: Subscription } = {};

    canDelete = true;

    constructor(
        protected formsService: FormService,
        protected sortService: SortService,
        protected dateService: DateService
    ) {
    }

    ngOnInit(): void {
        this.schema = this.formConfig.schema;
        this.sortConfig = this.formConfig.sortConfig;
        this.hideAddButton = this.formConfig.hideAddButton;
        this.hasCalendar = !!this.formConfig.calendarConfig;
        this.buttonAtStart = this.formConfig.buttonAtStart ?? false;
        this.showCalendar = this.formConfig.calendarConfig?.forceShow ?? false;
        this.hideShowCalendar = this.formConfig.calendarConfig?.hideShowCalendar ?? false;
        this.canDelete = this.formConfig.canDelete ?? true;

        this.fieldControl.addValidators(sameErrorValidator(this.formArray));
        this.fieldControl.updateValueAndValidity();

        this.subs['fieldControl'] = this.fieldControl.valueChanges.subscribe(
            (value) => {
                this.updateFormArray(value);
            }
        );
        this.subscribeArray();

        this.sortArray();
        this.calenderUsedForFilter = this.formConfig?.calendarConfig?.usedToFilter ?? false;
    }

    ngOnDestroy(): void {
        Object.values(this.subs).forEach((s) => s.unsubscribe());
    }

    subscribeArray(): void {
        this.subs['formArray'] = this.formArray.valueChanges.subscribe((val) => {
            this.fieldControl.setValue(this.formArray.getRawValue());

            this.sortArray();
        });
    }

    unsuscribeArray(): void {
        this.subs['formArray'].unsubscribe();
    }

    addOne(): void {
        const [form, schema] = this.formsService.buildForm(this.schema);

        this.fieldControl.registerOnDisabledChange((disabled) => {
            disabled ? form.disable() : form.enable();
        })

        if (!this.buttonAtStart) {
            this.forms.push({ form, schema });
            this.formArray.push(form);
        }
        else {
            this.forms.unshift({ form, schema });
            this.formArray.insert(0, form);
        }

        this.filterItems(this.lastCalendarDate);
    }

    delete(index: number): void {
        this.formArray.removeAt(index);
        this.forms.splice(index, 1);
    }

    flag = false;
    updateFormArray(value: any[] = []): void {
        if (!this.formArray.value.length && !!value.length) {
            this.unsuscribeArray();
            const array = this.formArray.value ?? [];
            let valueToPatch: any[] = [...array];

            valueToPatch = [
                ...valueToPatch,
                ...value.filter((vItem: { [key: string]: any }) =>
                    value.some((item) => JSON.stringify(item) === JSON.stringify(vItem))
                ),
            ];

            valueToPatch.forEach(() => {
                this.addOne();
            });

            setTimeout(() => {
                this.formArray.patchValue(valueToPatch);
                this.subscribeArray();
                this.filterItems(this.lastCalendarDate);

                this.sortArray();
            }, 0); // FIX: El componente tabs-group parece que tarda algo en responder y no actualiza los datos bien sin esto
        }
    }

    fixDateFlag = true;
    updateDate(event: CalendarEventInterface): void {
        if (this.calenderUsedForFilter) {
            this.selectedDate = moment(event.date);
        }
        else if (this.formConfig?.calendarConfig?.dateField) {
            if (this.fixDateFlag && moment(event.date).isSame(moment(), 'day')) return;
            this.fixDateFlag = false;
            switch (event.action) {
                case 'add':
                    const defaultValue = this.schema[this.formConfig.calendarConfig.dateField].value;
                    this.schema[this.formConfig.calendarConfig.dateField].value = event.date;
                    this.addOne();
                    this.schema[this.formConfig.calendarConfig.dateField].value = defaultValue;
                    break;

                case 'remove':
                    this.delete(event.index);
                    break;
            }
        }
    }

    filterItems(date: Date | null): void {
        if (isMoment(date)) {
            date = date.toDate();
        }

        if (date && this.sortConfig?.showSelectedMonthOnly) {
            this.lastCalendarDate = date;

            this.itemVisibility = this.formArray.value.map((item: any) => {
                let iDate = item[this.formConfig.calendarConfig.dateField];
                if (!(iDate instanceof Date) && iDate !== '') {
                    iDate = this.dateService.transformObjectToDate(iDate);
                }
                return (
                    !iDate ||
                    (iDate.getMonth() === date!.getMonth() &&
                        iDate.getFullYear() === date!.getFullYear())
                );
            });
        } else {
            this.itemVisibility = this.displayAll(this.formArray.value);
        }
    }

    displayAll(data: any[]): boolean[] {
        return data.map(() => true);
    }

    applyFilter(ctrl: any) {
        if (this.calenderUsedForFilter && this.showCalendar) {
            const date = ctrl.value[this.formConfig.calendarConfig.dateField];
            if (date == '') return true;
            if (moment(date).isSame(this.selectedDate, 'day')) {
                return true;
            }

            return false;
        }
        return true;
    }

    sortArray() {
        if (this.sortConfig) {
            this.itemPositions = [];

            const values = this.formArray.value.map(
                (val: any) => {
                    const v = val[this.sortConfig.sortField];
                    if (isMoment(v)) return v.toDate();
                    return v;
                }
            );

            this.itemPositions = this.sortService.getOrders(
                values,
                this.sortConfig.sortFunctionName
            );
        }
    }
}
