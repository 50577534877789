

<ng-container class="report-custom-container"><app-report-select [title]="'Prescripciones FGP'"
    (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()">
    <div class="container-options" slot="content">
        <form [formGroup]="dateForm" class="container-form" >

            <section class="filter-container" *ngIf="typeGlobal" >
                <div class="subtitle">Centro</div>
                <app-form-field
                  *ngIf="!resetInstitutionsDropdown"
                  class="field"
                  [type]="'select'"
                  [label]="multipleInstitutionSelect ? 'Selecciona los centros' : 'Selecciona un centro'"
                  [fieldControl]="dateForm.controls['selectedInstitution'] | asFormControl"
                  [options]="selectOptionsInsitutions"
                  [multipleSelect]="multipleInstitutionSelect">
                </app-form-field>
            </section>

            <section class="filter-container interval-days">
                <div class="subtitle">Intervalo</div>

                <mat-form-field class="field col-4">
                    <div class="label">Desde</div>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="field col-4">
                    <div class="label">Hasta</div>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </section>

            <section class="filter-container">
                <p class="subtitle">Filtrar por</p>
               
                <mat-checkbox 
                    class="marginBottom"
                    formControlName="checkboxControlPatient" 
                    (change)="toggleSelectPatient()">
                    Paciente
                </mat-checkbox>
                <app-form-field 
                    *ngIf="showPatientSelect" 
                    class="marginBottom"
                    label="Selecciona una opción" 
                    type="autocomplete" 
                    [options]="selectOptionsPatientsAutocomplete" 
                    [fieldControl]="dateForm.controls['selectedPatient'] | asFormControl">
                </app-form-field>
            </section>
        </form>
    </div>
</app-report-select>
</ng-container>