import { TokenInfo } from '@interfaces/api/api.interface';
export function createBinaryString(nMask: number): string {
    // Copiadita del internete
    // nMask must be between -2147483648 and 2147483647
    if (nMask > 2 ** 31 - 1)
        throw "number too large. number shouldn't be > 2**31-1"; //added
    if (nMask < -1 * 2 ** 31)
        throw "number too far negative, number shouldn't be < -(2**31)"; //added
    for (
        var nFlag = 0, nShifted = nMask, sMask = '';
        nFlag < 32;
        nFlag++, sMask += String(nShifted >>> 31), nShifted <<= 1
    );
    // sMask=sMask.replace(/\B(?=(.{8})+(?!.))/g, " ") // added
    return sMask;
}

export function decryptToken(token: string): TokenInfo {
    return JSON.parse(atob(token.split('.')[1]));
}

export function getDateStringYMD(date: Date): string {
    const d = new Date(date);
    return '' + d.getFullYear() + d.getMonth() + d.getDate();
}

export function getTimeStringHM(date: Date): string {
    const d = new Date(date);
    return '' + d.getHours() + d.getMinutes();
}

export function setDateTo00(date: Date): Date {
    const d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
}
export function setDateTo2359(date: Date): Date {
    const d = new Date(date);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
}

export function objectToXMLString(
    object: { [key: string]: any },
    valueDictionary?: { [key: string]: string },
    skipEmptyValues: boolean = false
): string {
    let result = '';
    Object.keys(object).forEach((key) => {
        const value = object[key];
        let flag = true;
        let xmlValue = JSON.stringify(value);
        if (valueDictionary && valueDictionary.hasOwnProperty(key)) {
            key = valueDictionary[key];
            if (key == '') {
                flag = false;
            }
            xmlValue = JSON.parse(xmlValue);
            if (skipEmptyValues && (xmlValue == undefined || String(xmlValue).trim().length == 0)) flag = false;
        }
        if (flag) {
            result += `<${key}>`;
            result += xmlValue;
            result += `</${key}>`;
        }
    });
    return result;
}

export function getReportReasonDescription(
    reportReasonCode: number,
    blockReason: number | null,
    exitusReason: number | undefined,
    abbreviate: boolean
): string {
    switch (reportReasonCode) {
        case 1:
            return 'ALTA';
        case 2:
            return 'BAJA';
        case 3:
            return abbreviate ? 'RE MOD' : 'RE MODIFICADA';
        case 4:
            return (
                (abbreviate ? 'EXIT-' : 'EXITUS - ') +
                getExitusReasonNotes(exitusReason, abbreviate)
            );
        case 5:
            return (
                (abbreviate ? 'BLOQ-' : 'BLOQUEADO - ') +
                getBlockReasonNote(blockReason, reportReasonCode, abbreviate)
            );
        case 6:
            return (
                (abbreviate ? 'DESBLOQ-' : 'DESBLOQUEADO - ') +
                getBlockReasonNote(blockReason, reportReasonCode, abbreviate)
            );
        case 7:
            return (
                (abbreviate ? 'BLOQ MOD-' : 'BLOQUEO MODIFICADO - ') +
                getBlockReasonNote(blockReason, reportReasonCode, abbreviate)
            );
        case 8:
            return abbreviate ? 'ALTA (EXITUS)' : 'ALTA DESDE EXITUS';
    }

    return '';
}

export function getBlockReasonNote(
    blockReason: number | null,
    reportReason: number,
    abbreviaton: boolean
) {
    switch (blockReason) {
        case 0:
            return 'OTRO';
        case 1:
            return abbreviaton ? 'VAC' : 'VACACIONES';
        case 2:
            return reportReason === 6
                ? abbreviaton
                    ? 'REGR'
                    : 'REGRESO'
                : abbreviaton
                    ? 'INGR'
                    : 'INGRESO';
        case 3:
            return abbreviaton ? 'DERIV' : 'DERIVACION';
        default:
            return '?';
    }
}

export function getExitusReasonNotes(
    exitusReason: number | undefined,
    abbreviature: boolean
) {
    switch (exitusReason) {
        case 0:
            return abbreviature ? 'DEF' : 'DEFUNCION';
        case 1:
            return abbreviature ? 'VOL' : 'VOLUNTARIO';
        case 2:
            return abbreviature ? 'CAMBIO' : 'CAMBIO RESIDENCIA';
        case 3:
            return abbreviature ? 'INCORR' : 'USO INCORRECTO';
        default:
            return '?';
    }
}

export function replaceLastCharacter(
    originalString: string,
    newCharacter: string
): string {
    return originalString.slice(0, -1) + newCharacter;
}

export function filter(array: any[], valueToFind: string) {
    return array.filter((obj) =>
        Object.values(obj).some((attributeValue) =>
            String(attributeValue).toLowerCase().includes(valueToFind.toLowerCase())
        )
    );
}

export function shortenWithEllipsis(str: string, maxLength: number) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + '...';
    }
}
