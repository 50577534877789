import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { createBinaryString, getDateStringYMD, getReportReasonDescription, getTimeStringHM, setDateTo00, setDateTo2359 } from '@constants/funtions-utils';
import { APIResponseParsed } from '@interfaces/api/api.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { DailyChange, PatientREChangesAPI, PatientREHistoryAPI, PatientReFix, PatientReviewPrescriptionsREQuantityFilter, PrescriptionResponseREAPI, ReviewWeekAPI } from '@interfaces/patient/patient.interface';
import { DayChanges, REInstitutionDataAPI, REPatient, REPatientAPP, REReviewInstitution, REReviewPatientsPayload, REfilters } from '@interfaces/re-status.interface';
import { RenderReportAPI } from '@interfaces/report/report.interface';
import { inOutFade, inOutFromBottom } from '@json/src/app/animations/general';
import { CallsService } from '@services/api/calls.service';
import { AuthService } from '@services/auth.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { PatientsService } from '@services/patients.service';
import { ReportsService } from '@services/reports/reports.service';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { FarmaticNumberComponent } from '@shared/dialogs/farmatic-number/farmatic-number.component';
import { Observable, Subscription } from 'rxjs';
import moment, { isMoment } from 'moment';
import { Utils } from '@json/src/app/Utils';
import { DateService } from '@services/date.service';

@Component({
  selector: 'app-re-status',
  templateUrl: './re-status.component.html',
  styleUrls: ['./re-status.component.scss'],
  animations: [inOutFade, inOutFromBottom]
})
export class ReStatusComponent implements OnInit, OnDestroy{

  @ViewChild('debtDetail') debtDetail: ElementRef;

  public institutionOptions: SelectOption[] = [];
  public selectedInstitution: REReviewInstitution;
  public selectedDate: Date = new Date();
  public filtersSelected: REfilters[] = [];
  public searchValue: string = '';
  public patientLoading = false;
  public patientDailyChanges = false;

  public reInstitutionData: REInstitutionDataAPI | undefined;

  public patients: REPatientAPP[] | undefined;
  public reviewWeeks: {[key: string]: ReviewWeekAPI | undefined } = {};

  public get filteredPatients(): REPatientAPP[] | undefined {
    const nextDispensePatients = this.patients?.filter((patient: REPatientAPP) => patient.NextDispenseDate && new Date(patient.NextDispenseDate) <= this.selectedDate ) || [];
    const dailyChangesPatients = this.patients?.filter((patient: REPatientAPP) => patient.DailyChanges?.length ) || [];
    const allNextDispensePatients = this.patients?.filter((patient: REPatientAPP) => patient.NextDispenseDate ) || [];
    const activePatients = this.patients?.filter((patient: REPatientAPP) => !!patient.NotInInterval ) || [];
    const blockedPatients = this.patients?.filter((patient: REPatientAPP) => !!patient.blq && patient.blq.length ) || [];
    const mutuasPatients = this.patients?.filter((patient: REPatientAPP) => patient.Mutuas ) || [];

    return [...new Set([
      ...nextDispensePatients,
      ...dailyChangesPatients,
      ...(this.filtersSelected.includes('next') ? allNextDispensePatients : []),
      ...(this.filtersSelected.includes('active') ? activePatients : []),
      ...(this.filtersSelected.includes('bloqued') ? blockedPatients : []),
      ...(this.filtersSelected.includes('mutuas') ? mutuasPatients : []),
    ])]
    .filter((p: REPatientAPP) => this.filterPatient(p, this.filtersSelected))
    .filter((p: REPatientAPP) => JSON.stringify(p).toLowerCase().includes(this.searchValue.toLowerCase()))
    .sort((a: REPatientAPP, b: REPatientAPP) => 
      a.DailyChanges.length > b.DailyChanges.length ? -1 : 1 || a.NextDispenseDate > b.NextDispenseDate ? 1 : -1
    );
  }

  public patientSelected: REPatientAPP | undefined;

  public filters = [
    { label: 'Mostrar', options: [
      {label: 'Bloqueados por ingreso o derivación', value: 'bloqued'},
      {label: 'Próximas dispensaciones', value: 'next'},
      {label: 'Mutuas', value: 'mutuas'},
      {label: 'Todos los activos', value: 'active'},
    ]},
    { label: 'Filtrar', options: [
      {label: 'Exitus/bajas', value: 'exitus'},
      {label: 'No revisados', value: 'no-revised'},
      {label: 'Incidencias 1+2', value: 'issues1-2'},
      {label: 'Solo incidencias', value: 'issues'},
      {label: 'Solo modificados', value: 'modified'},
      {label: 'Solo deudas', value: 'debt'},
      {label: 'Solo CAP externo', value: 'external-cap'},
      {label: 'Solo problemáticos', value: 'problematic'},
    ]}
  ]

  patientIdCurrentDebtDetail: number | undefined;
  patientCurrentDebtDetailPosition: any = {};

  private subs: Subscription[] = [];

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.debtDetail?.nativeElement.contains(event.target)) {
      this.patientIdCurrentDebtDetail = undefined;  
    }
  }

  constructor(
    private authService: AuthService,
    private institutionService: InstitutionService,
    private callsService: CallsService,
    private modalService: ModalService,
    private loading: LoadingService,
    private router: Router,
    private reportsService: ReportsService,
    private patientService: PatientsService,
    private detected: ChangeDetectorRef,
    private dateService: DateService,
  ) {

  }

  ngOnInit(): void {
    this.institutionService.getInstitutions();
    this.callsService.getReReviewInstitutions(this.institutionService.getCurrentFarmacy()).subscribe((res) => {
      const institutions: REReviewInstitution[] = res.payload;

      institutions.sort((a, b) => a.Qty + b.Qty || a.Name.localeCompare(b.Name));

      this.institutionOptions = institutions.map(inst => {
        return {
          label: `${inst.Name} (${inst.Qty})`,
          value: inst,
        }
      })

      // @ts-ignore
      this.selectedInstitution = this.institutionOptions.find(i => i.value.Id === this.institutionService.getCurrentInstitution())?.value;
      // @ts-ignore
      this.onInstitutionChange({value: this.selectedInstitution});
    })

  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  preventPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  onInstitutionChange(change: MatSelectChange): void {
    this.reInstitutionData = undefined;
    this.patients = undefined;
    this.loading.start('Cargando centro')
    const institution: REReviewInstitution = change.value;

    this.institutionService.selectInstitutionById(institution.Id);


    this.callsService.getInstitutionReData(institution.Id).subscribe(res => {
      this.reInstitutionData = {
        ...res.payload,
      };

      this.loading.start('Cargando residentes')
      this.getREReviewPatients().subscribe(res => {
        this.patients = res.payload.pats.map((p: REPatient) => {
          return {
            ...p,
            forceShow: false
          };
        });
        this.loading.stop();
      })
    })
  }

  getREReviewPatients(): Observable<APIResponseParsed> {
    const dateFrom = Utils.toStartfDate(new Date());
    const dateUntil = Utils.toEndOfDate(dateFrom);

    const data: REReviewPatientsPayload = {
      dtFrom: `/Date(${this.dateService.convertStringOfDate(dateFrom)})/`,
      dtUntil: `/Date(${this.dateService.convertStringOfDate(dateUntil)})/`,
      patid: null,
      instid: this.selectedInstitution.Id,
      inclNB: 1
    }
    return this.callsService.getReReviewPatients(data)
  }

  filterPatient(patient: REPatientAPP, filters: REfilters[]): boolean {
    let result = true;
    let flag: any, l: number;

    if ( patient.IssueType ) {
      flag = createBinaryString(patient.IssueType);
      l = flag.length - 1;
    }

    filters.forEach(filter => {
      switch (filter) {
        case 'exitus':
          result = result && !!patient.ex;
          break;
        case 'no-revised':
          result = result && !patient.Reviewed;
          break;
        case 'issues1-2':
          result = result && !!patient.IssueType && (flag[l - 0] === '1' || flag[l - 1] === '1');
          break;
        case 'issues':
          result = result && !!patient.IssueType;
          break;
        case 'modified':
          result = result && patient.DailyChanges.some((change: DayChanges) => change.ReportReasonCode === 3);
          break;
        case 'debt':
          result = result && !!patient.Deuda;
          break;
        case 'external-cap':
          result = result && !!patient.CapExt;
          break;
        case 'problematic':
          result = result && !!patient.Trouble;
          break;
      }
    })


    return result;
  }

  goToREReport():void {
    const urlTree = this.router.createUrlTree(['/global-eye/reports/restat']);
    const url = urlTree.toString();
    window.open(url, '_blank');
  }

  goToDebtReport():void {
    const urlTree = this.router.createUrlTree(['/global-eye/reports/medidebt']);
    const url = urlTree.toString();
    window.open(url, '_blank');
  }

  goToReport() {
    const today = new Date();
    const currentDate = getDateStringYMD(today);
    const currentTime = getTimeStringHM(today);
    const data: RenderReportAPI  = {
      rpp: "/COROTA/ReGeneralNote",
      pts: 0,
      z: 100,
      htmf: false,
      fmt: 2,
      dl: false,
      dn: `Notas_estado_RE_${this.selectedInstitution.ShortName}(${this.selectedInstitution.Qty})_${currentDate}_${currentTime}.pdf`,
      rp: `{\"LocaleId\":\"3082\",\"InstitutionId\":\"${this.selectedInstitution.Id}\"}`
    }
    this.reportsService.handlePrintClick({dataRenderReport: data})
  }

  getTag(day: DayChanges): string {
    const text = getReportReasonDescription(
      day.ReportReasonCode,
      day.BlockReason,
      day.ExitusReason,
      true
  )
    return text;
  }


  onClickShowData(patient: REPatientAPP): void {
    this.subs.push(
      this.modalService.openModalSubs(ConfirmationDialogComponent, {
        title: 'Mostrar datos de facturación',
        message: `
        Este paciente tiene un estado de baja/exitus y no se le pueden facturar medicamentos.
        Si decide mostrar sus datos de facturación, quedará registrada una alerta en el sistema.
        ¿Está seguro que desea mostrarlos?
        `,
        type: 'error'
      }).subscribe(res => {
        const institutionInfo = this.institutionService.getUserInstitution();
        const userInfo = this.authService.getUserInfo();
        if (res && institutionInfo && userInfo) {
          this.callsService.logShowREInvoiceData({
            insidSrc: institutionInfo.id,
            uid: userInfo.name,
            insid: this.selectedInstitution.Id,
            pid: patient.PatientId
          }).subscribe()

          const clickedPatient = this.patients?.find(p => p.PatientId = patient.PatientId);

          if (clickedPatient) {
            clickedPatient.forceShow = true;
            patient.forceShow = true;
          }
        }

      })
    );
  }

  onClickFarmaticNumber(patient: REPatientAPP): void {
    this.subs.push(
      this.modalService.openModalSubs(FarmaticNumberComponent, { patient }).subscribe(inputNumber => {
        if (inputNumber && typeof inputNumber === 'number') {
          this.callsService.updateFarmaticNumber({
            pid: patient.PatientId,
            fmtn: inputNumber + ''
          }).subscribe({
            next: (res) => {
              if (res) {
                const clickedPatient = this.patients?.find(p => p.PatientId = patient.PatientId);
                if (clickedPatient) {
                  clickedPatient.FarmaticNumber = inputNumber;
                  patient.FarmaticNumber = inputNumber;
                }
              }
            },
            error: () => {
              this.callsService.openSnack('Error al guardar el número FARMATIC');
            }
          })
        }
      })
    );
  }

  onOpenPatient(patient: REPatientAPP, index?: number): void {


      this.patients
      ?.filter((p: REPatientAPP) => {p.infoDailyChanges = false});
      patient.infoDailyChanges = false;

      // if (patient.hasChanges) return;
      this.reviewWeeks[patient.PatientId] = undefined;
      this.patientLoading = true;
      this.subs.push(
        this.patientService.getPatientReviewPrescriptionsRE({
          patientId: patient.PatientId,
          dateFrom: setDateTo00(this.selectedDate),
          dateUntil: setDateTo2359(this.selectedDate)
        }).subscribe((res) => {
          let filter = {
            StartDate: setDateTo00(this.selectedDate),
            InstitutionId: this.selectedInstitution.Id,
            FarmaticNumber: patient.FarmaticNumber,
            PrescrList: res.prs.map((pres: PrescriptionResponseREAPI) => {
              return {
                DrugId: pres.DrugId,
                DrugIdInvc: pres.DrugIdInvc
              }
            })
          }
          this.patientService.getPatientReviewPrescriptionsREQuantity(filter)
          .subscribe(quantities => {
            res.prs = res.prs.map(presc => {
              const qty = quantities.prescrList.find(p => p.DrugId === presc.DrugId)
              return {
                ...presc,
                QtySale: qty ? qty.QtySale : -1
              }
            })
  
            this.reviewWeeks[patient.PatientId] = JSON.parse(JSON.stringify(res)); ;
            this.patientSelected = JSON.parse(JSON.stringify(patient));
            this.patientLoading = false;
          })
  

        })
      )
  }

  onPatientChange(changed: boolean, patient: REPatientAPP): void {
    patient.hasChanges = changed;
  }

  onRefreshPage(){
    this.callsService.getInstitutionReData( this.selectedInstitution.Id,).subscribe(res => {
      this.reInstitutionData = {
        ...res.payload,
      };

      this.loading.start('Cargando residentes')
      this.getREReviewPatients().subscribe(res => {
        this.patients = res.payload.pats.map((p: REPatient) => {
          return {
            ...p,
            forceShow: false
          };
        });
        this.loading.stop();
      })
    })
  }
  onPatientSave(data: { patient: REPatientAPP; reviewWeek: ReviewWeekAPI; }, currentPatient: REPatientAPP): void {
    this.patientLoading = true;
    const { patient, reviewWeek } = data;

    // Preprocesamos lo que vamos a enviar para que sea valido, si se ha quitado un issue tenemos que devolverlo como 0 (pero solo en los que hemos quitado)
    this.reviewWeeks[currentPatient.PatientId]?.prs.forEach(i => {
      const prescr = reviewWeek.prs.find(i1 => i1.PrescriptionId == i.PrescriptionId);
      if (prescr) {
        if (prescr.IssueType == 0 && i.IssueType == undefined) {
          (prescr as any).IssueType = undefined;
        }
      }
    });

    const filter: PatientREChangesAPI = {
      PatientId:      patient.PatientId,
      ReIssues:       this.getREIssues(reviewWeek.prs),
      FarmaticNumber: patient.FarmaticNumber,
      ReNote:         patient.ReNote,
      Reviewed:       patient.Reviewed,
      DailyChanges:   this.getDailyChanges(data.patient.DailyChanges),
      ReFixes:        this.getReFixes(reviewWeek.reHistory, patient.PatientId),
      DNI:            patient.DNI,
      Trouble:        patient.Trouble,
      CapExt:         patient.CapExt,
      Estupes:        [],
    };

    this.patientService.updatePatientReviewRE(filter).subscribe(res => {
      if (res.payload === 1) {
        if (this.patients) {
          let index = this.patients.findIndex(p => p.PatientId === currentPatient.PatientId)
          const patients = [...this.patients];
          this.patients = [];
          patients[index] = data.patient;
          this.patients = [...patients];
          this.detected.detectChanges();
        }
      }

      this.getREReviewPatients().subscribe(res => {
        this.patients = res.payload.pats.map((p: REPatient) => {
          return {
            ...p,
            forceShow: false
          };
        });
        this.patientLoading = false;
        this.detected.detectChanges();
      })

      this.onOpenPatient(data.patient);
    })
  }

  getREIssues(pres: PrescriptionResponseREAPI[]): string {
    let result = '<ArrayOfReReview>';

    pres.forEach(p => {
      result += '<ReReview>';

      result += p.PrescriptionId ? `<PrescriptionId>${p.PrescriptionId}</PrescriptionId>` : '';

      if ( p.IssueType ) {
        result += p.IssueDate ? `<IssueDate>${new Date(p.IssueDate).toISOString()}</IssueDate>` : '';
        result += p.IssueType ? `<IssueType>${p.IssueType}</IssueType>` : '';
        result += p.IssueNote ? `<IssueNote>${p.IssueNote}</IssueNote>` : '';
      } else {
        result += `<IssueType>0</IssueType>`;
      }

      result += '</ReReview>';
    })
    
    result += '</ArrayOfReReview>';

    return result;
  }

  getDailyChanges(changes: DayChanges[]): DailyChange[] {
    return changes.filter(change => change.Flag === 1);
  }

  getReFixes(reList: PatientREHistoryAPI[], patientId: number): PatientReFix[] {
    return reList.map(re => {
      return {
        PatientId: patientId,
        RE: re.RE,
        REFix: re.REFix ? String(re.REFix) : null,
        IsRE2: re.IsRE2,
        NextDispenseDate: re.NextDispenseDate,
        Forget: re.Forget
      }
    })
  }

  closePatient(patient: any, index: any){
    patient.infoDailyChanges = false;
  }


  closeFormReviewWeeks(){

    this.reviewWeeks[this.patientSelected!.PatientId] = undefined;
    this.patientSelected = undefined;
  }

  getDebtDetail($event: any, patient: REPatientAPP) {
    $event.stopPropagation();

    this.patientIdCurrentDebtDetail = patient.PatientId;

    this.patientCurrentDebtDetailPosition.top = `${$event.target.offsetTop - 5}px`; 
    this.patientCurrentDebtDetailPosition.left = `${$event.target.offsetLeft + $event.target.offsetWidth + 5}px`;

    this.callsService.getPatientReviewDebtDetail(patient.PatientId).subscribe(res => {
      patient.DebtDetail = res.payload;
    });
  }
}
