import { environment } from 'src/environments/environment';

export interface ApiCallParams {
  [x: string]: any;
}

export function createCall(call: string, id?: string, params?: ApiCallParams) {
  let url = `${environment.urlBack}${call}`;
  if (id) url += `${id}/`;
  if (params) {
    url += '?';
    for (const key in params) {
      url += `${key}=${params[key]}&`;
    }
    url = url.slice(0, -1);
  }
  return url;
}
