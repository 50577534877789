<div class="operation-bar" 
  [ngClass]="{'bad-solution': onListPage()}"
  [ngStyle]="{'display': onListPage() || institution?.isEdit ? 'block' : 'none'}">
  <app-operation-bar
    [arrowNavitation]="showArrows()"
    [listMode]="true"
    [canDelete]="false"
    [canCreate]="false"
    [reportActions]="false"
    [customButtonToggle]="false"
    [search]="true"
    [searchLabel]="'Búsqueda'"
    [filter]="false"
    [dropdownFilter]="true"
    [dropdownOptions]="institutionSelecFilter"
    [dropdownInitialSelect]="selectedFilters"
    [dropdownFilterLabel]="'Centros'"
    [alertButton]="false"
    [totalElements]="totalElements"
    [currentElementIndex]="currentElementIndex"
    [showResults]="onEditPage() && resultsRefreshed"
    [filterResults]="filteredResults"
    (onAction)="onOperationBarAction($event)"
    (onFilter)="filter($event)"
    (onFilterResultSelected)="onFilterResultSelected($event)">
  </app-operation-bar>
</div>

<section class="content" 
  *ngIf="!initLoading" 
  [ngClass]="{'bad-solution': onEditPage() && institution?.institutionId}">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</section>
