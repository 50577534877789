import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

import { inOutFade } from '@json/src/app/animations/general';

@Component({
  selector: 'app-patient-edit-daily-pattern',
  templateUrl: './daily-pattern.component.html',
  styleUrls: ['./daily-pattern.component.scss']
})
export class DailyPatternComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
