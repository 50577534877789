import { Component } from '@angular/core';

@Component({
  selector: 'app-test-shared',
  templateUrl: './test-shared.component.html',
  styleUrls: ['./test-shared.component.scss']
})
export class TestSharedComponent {

}
