import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private router: Router, private _dialog: MatDialog) { }

    openModalRoute(
        route: string,
        routeValue: string,
        message: string,
        title?: string
    ): void {
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            data: {
                title,
                message,
                buttonText: {
                    ok: 'Si',
                    cancel: 'No',
                },
                onlyInfo: false,
            },
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.navigateTo(route, routeValue);
            }
        });
    }

    openModalInfo(message: string, title?: string): void {
        this._dialog.open(ConfirmationDialogComponent, {
            data: {
                title,
                message,
                buttonText: {
                    ok: 'Vale',
                },
                onlyInfo: true
            },
        });
    }

    openModalErrorInfo(message: string, title?: string): void {
        this._dialog.open(ConfirmationDialogComponent, {
            data: {
                title,
                message,
                buttonText: {
                    ok: 'Vale',
                },
                onlyInfo: true,
                type: 'error',
            },
        });
    }

    openModalCallback(
        callback: () => any,
        message: string,
        title?: string,
        error?: boolean,
        cancelCallback?: () => any,
        auxButtonText?: string,
        auxCallback?: () => any
    ): void {
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            data: {
                title,
                message,
                buttonText: {
                    ok: 'Si',
                    cancel: 'No',
                    aux: auxButtonText,
                },
                onlyInfo: false,
                type: error ? 'error' : '',
            },
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean | string) => {
            if ( confirmed === true ) {
                callback();
            }
            else if ( confirmed === false ) {
                if (cancelCallback) cancelCallback();
            } else if ( confirmed === 'aux' ) {
                if (auxCallback) auxCallback();
            }
        });
    }

    openModalCallbackCustomComponent(
        component: any,
        callback: (data: any, id?: any) => any,
        id?: number,
        title?: string,
        message?: string,
        error?: boolean,
        cancelCallback?: () => void,
        componentData?: any
    ): void {
        const dialogRef = this._dialog.open(component, {
            data: {
                title,
                id,
                message,
                buttonText: {
                    ok: 'Si',
                    cancel: 'No',
                },
                onlyInfo: false,
                type: error ? 'error' : '',
                componentData
            },
        });
        dialogRef.afterClosed().subscribe((info: any) => {
            const { confirm, data, id } = info;
            if (confirm) {
                callback(data, id);
            }
            else {
                if (cancelCallback)
                    cancelCallback();
            }
        });
    }

    openModalSubs(component: any, data: any): Observable<any> {
        const dialogRef = this._dialog.open(component, {
            data,
        });
        return dialogRef.afterClosed();
    }

    openModalConfirmationWhenClicks(
        route: string,
        value: string,
        nameButton: string
    ): void {
        // let saveButton = document.getElementById(nameButton) as HTMLButtonElement;
        // if (!saveButton.disabled) {
        //     this.openModalRoute(
        //         route,
        //         value,
        //         '¿Está seguro de que desea salir?',
        //         'No se han guardado los datos.'
        //     );
        // } else {
            this.navigateTo(route, value);
        // }
    }

    navigateTo(route: string, value: string): void {
        // It is necessary to decode the URI because otherwise
        // it will come out in an invalid format.
        const decodedValue = decodeURIComponent(value);
        const decodedRoute = decodeURIComponent(route);
        this.router.navigate([decodedRoute, decodedValue]);
    }

    reloadComponent(router: Router, tabIndex: number = -1): void {
        const pathName = location.pathname;
        const search = location.search;

        const searchParams = new URLSearchParams(search);
        if (tabIndex >= 0 && searchParams.has("tab")) {
            searchParams.set("tab", tabIndex.toString())
        }

        router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            router.navigateByUrl(pathName + "?" + searchParams.toString());
        });
    }

    changeView(
        event: MatButtonToggleChange,
        route: string,
        nameButton: string
    ): void {
        let { value } = event;

        let href = this.router.url;
        if (href.includes('edit') || href.includes('new')) {
            this.openModalConfirmationWhenClicks(route, value, nameButton);
        } else {
            this.navigateTo(route, value);
        }
    }
}
