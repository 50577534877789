<h2 mat-dialog-title>{{selectImageText}}</h2>
<mat-dialog-content>
  <input type="file" (change)="handleImageUpload($event)" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{cancelButtonText}}</button>
  <button
    mat-raised-button
    color="primary"
    (click)="uploadImage()"
    [disabled]="!selectedImage"
  >
   {{okButtonText}}
  </button>
</mat-dialog-actions>
