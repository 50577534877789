import { ModalService } from '@services/modal.service';
import { DateService } from '@services/date.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
    OptionsObject,
    TabInterface,
} from '@interfaces/dynamic-form.interface';
import { BlockResult, HandlingHabilityTestsResult } from '@interfaces/patient/patient.interface';
import { FormService } from '@services/form.service';
import { PatientsService } from '@services/patients.service';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';

import { FormGroup } from '@angular/forms';
import { formsConfig, tabs } from '@constants/patients-forms.config';
import { FormInterface } from '@interfaces/patient/patient-edit.interface';
import { PatientFormData } from '@interfaces/patient/patient.interface';
import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { errorBlocks } from '@constants/modal';
import { routePatients } from '@services/mocks/patient';
import { MatTabGroup } from '@angular/material/tabs';
import moment, { Moment, isMoment } from 'moment';
import { PrescriptionsTableComponent } from '@shared/prescriptions-table/prescriptions-table.component';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { FormDetailAction } from '@interfaces/form/form-detail';
import { inOutFade } from '@json/src/app/animations/general';
import { ColorLegendItem } from '@interfaces/color-legend-item.interface';
import { ColorLegendItemType } from '@json/src/app/enums/color-legend-item-type';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {Title} from "@angular/platform-browser";
@Component({
    selector: 'app-patient-edit',
    templateUrl: './patient-edit.component.html',
    styleUrls: ['./patient-edit.component.scss'],
    animations: [ inOutFade ]
})
export class PatientEditComponent implements OnInit, OnDestroy {

    @ViewChild(PrescriptionsTableComponent) prescriptionEdit: PrescriptionsTableComponent;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    selectedTab = 0;

    public patientId: string | null;
    public patientInstitutionId: number | undefined;
    public patientDefaultDep: number | undefined;
    public patientLink: string | undefined;
    public exportCf: boolean | undefined;
    public allergies: string | undefined;
    public patientCipaPattern: string | undefined;
    public patientCipaError: string | undefined;
    public isEdit: boolean = true;

    public forms: FormInterface = {};
    public formReady: boolean = false;

    public tabs: TabInterface[] = tabs;
    private formsConfig = formsConfig;

    public options: OptionsObject = {
        countries: [],
    };
    public formChanged: boolean = false;
    public tabsChanged: string[] = [];
    private subs: Subscription[] = [];
    public formSubscription: Subscription[] = [];
    public errorsBlock = errorBlocks;
    patientData: PatientFormData | undefined;
    public onDestroy: () => void;
    public onPatientId: (patId: string | null) => void;

    Utils = Utils;
    Permission = Permission;
    PermissionType = PermissionType;

    public collapsedProfile: boolean = false;

    private prescriptionsAditionalActions?: FormDetailAction[] = [
        {
            id: '3',
            name: 'GUARDAR',
            iconName: 'save',
            callback: () => { this.savePrescription() },
            customClass: ['save']
        }
    ];

    private newPatientAditionalActions?: FormDetailAction[] = [
        {
            id: '4',
            name: 'CANCELAR FICHA',
            iconName: 'close',
            callback: () => { this.cancelNew() },
            customClass: ['cancel']
        },
    ];
  
    currentTab: number = 0;

    updatePatientTimeout: any;

    colorLegendItems: ColorLegendItem[] = [
        {
            type: ColorLegendItemType.Color,
            description: 'Patrón de recurrencia',
            color: '#337ab7'
        },
        {
            type: ColorLegendItemType.Color,
            description: 'Dosis: planta actual',
            color: '#5bc0de'
        },
        {
            type: ColorLegendItemType.Color,
            description: 'Dosis: cambio de planta',
            color: '#ee97fa'
        },
        {
            type: ColorLegendItemType.Color,
            description: 'Si precisa',
            color: '#d9534f'
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Desayuno',
            color: '',
            data: {
                text: 'DES'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Comida',
            color: '',
            data: {
                text: 'COM'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Merienda',
            color: '',
            data: {
                text: 'MER'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Cena',
            color: '',
            data: {
                text: 'CEN'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Dormir',
            color: '',
            data: {
                text: 'DOR'
            }
        },
        {
            type: ColorLegendItemType.Background,
            description: 'Día actual',
            color: 'var(--color-current-day)'
        },
        {
            type: ColorLegendItemType.Background,
            description: 'Emblistado',
            color: 'white',
            data: {
                customStyle: {'border': '1px solid var(--color-default-border)'}
            }
        },
        {
            type: ColorLegendItemType.Background,
            description: 'No emblistado',
            color: 'var(--color-no-blister)'
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'Vía / Observaciones',
            color: '',
            data: {
                icon: 'info'
            }
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'De pago',
            color: 'red',
            data: {
                icon: 'euro_symbol'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Pendiente de evaluación',
            color: '#cd00cb',
            data: {
                text: 'PE'
            }
        },
        {
            type: ColorLegendItemType.Text,
            description: 'Fuera de guía preferente',
            color: 'var(--color-fgp-medicine)',
            data: {
                text: 'FGP'
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Hospitalario',
            data: {
                src: 'assets/images/gsp/diagnostico-hospitalario-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Estupefaciente',
            data: {
                src: 'assets/images/gsp/estupefaciente-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Psicótropo',
            data: {
                src: 'assets/images/gsp/psicotropo-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'MARC',
            data: {
                src: 'assets/images/gsp/marc-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 1',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo1-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 2',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo2-32.png',
            }
        },
        {
            type: ColorLegendItemType.Image,
            description: 'Medicamento peligroso - Grupo 3',
            data: {
                src: 'assets/images/meidcamentos-peligrosos/grupo3-32.png',
            }
        },
    ]

    constructor(
        private route: ActivatedRoute,
        private patientService: PatientsService,
        private calls: CallsService,
        private formService: FormService,
        private loadingService: LoadingService,
        private institutionService: InstitutionService,
        private dateService: DateService,
        private modalService: ModalService,
        private router: Router,
        private location: Location,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.route.queryParamMap.subscribe((data) => {
            const index = Number(data.get("tab"));
            if (!isNaN(index) && index != undefined) {
                this.selectedTab = index;
                this.currentTab = index;

                this.titleService.setTitle(`Paciente: ${this.tabs[index].label}`)
                this.gaService.pageView(`${window.location.href}`, `Paciente: ${this.tabs[index].label}`);
            }
        });
    }

    ngOnInit(): void {
        this.formReady = false;
        this.forms = {};
        this.initAll();
        this.initForms();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
        this.formSubscription.forEach((f) => f.unsubscribe());
        if (this.onDestroy) this.onDestroy();
    }

    getAditionalActions() {
        if ( this.prescriptionInited() ) {
            return this.prescriptionsAditionalActions;
        } else if ( !this.patientId ) {
            return this.newPatientAditionalActions;
        } else {
            return [];
        }
    }

    prescriptionInited() {
        const status = this.prescriptionEdit?.showEditForm && 
            (this.prescriptionEdit.prescriptionToEdit === undefined || this.prescriptionEdit.isModification) && 
            (this.prescriptionEdit.prescriptionEdit ? this.prescriptionEdit.prescriptionEdit.form.valid : false);

        return status === undefined ? false : status;
    }

    async initAll(): Promise<void> {
        this.loadingService.start('Cargando Formulario...');
        this.patientData = await this.getPatientId();

        const instId = this.institutionService.getCurrentInstitution();

        this.patientInstitutionId = this.patientData?.general.institutionId ?? instId;
        this.patientDefaultDep = this.patientData?.rawData?.pat.dep;
        this.patientLink = this.patientData?.prescriptions?.link;
        this.exportCf = this.patientData?.prescriptions?.exportCf;
        this.allergies = this.patientData?.prescriptions?.allergies;

        if ( !this.patientData ) {
            this.getNewPatientEditData(instId);
        } else {
            this.patientCipaPattern = this.patientData.rawData?.pat.cipaPattern;
            this.patientCipaError = this.patientData.rawData?.pat.cipaError;
        }

        await this.getCountries();

        await this.getDepartments(this.patientInstitutionId);

        this.loadFormData(this.patientData);

        // We need to wait for the form to be initialized
        setTimeout(() => {
            this.setFormListeners();
        })

        this.formReady = true;
        this.loadingService.stop();
    }

    async getCountries(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.subs.push(
                this.patientService.getCountries().subscribe({
                    next: (res) => {
                        this.options['countries'] = res;
                        resolve(true);
                    },
                    error: () => {
                        this.calls.openSnack('Error al obtener los Países');
                        reject();
                    },
                })
            );
        });
    }

    async getDepartments(institutionId: number | undefined): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.subs.push(
                this.institutionService
                    .getInstitutionDepartments(institutionId)
                    .subscribe({
                        next: (res) => {
                            this.options['departments'] = res;
                            resolve(true);
                        },
                        error: () => {
                            this.calls.openSnack('Error al obtener los Departamentos');
                            reject();
                        },
                    })
            );
        });
    }

    async getPatientId(): Promise<PatientFormData | undefined> {
        return new Promise<PatientFormData | undefined>((resolve, reject) => {
            this.subs.push(
                this.route.paramMap.subscribe((params: ParamMap) => {
                    this.patientId = params.get('id')?.split('?')[0] ?? null;
                    if (this.onPatientId) this.onPatientId(this.patientId);

                    this.isEdit = !!this.patientId;
                    if (this.patientId) {
                        this.patientService.getPatientData(this.patientId).subscribe({
                            next: (data) => {
                                resolve(data);
                            },
                            error: (err) => {
                                this.router.navigate([routePatients], { replaceUrl: true }); // Paciente no existe
                                reject(err);
                            },
                        });
                    } else {
                        resolve(undefined);
                    }
                })
            );
        });
    }

    async getNewPatientEditData(institutionId: number) {
        this.patientService.getNewPatientEditData(institutionId).subscribe({
            next: (data) => {
                this.patientCipaPattern = data.rawData?.pat.cipaPattern;
                this.patientCipaError = data.rawData?.pat.cipaError;
            },
            error: (err) => {},
        })
    }

    savePrescription(): void {
        this.prescriptionEdit.prescriptionEdit.savePrescription();
    }

    initForms(): void {
        this.tabs.forEach(async (tab) => {
            const formName = tab.form;
            const result = this.formService.buildForm(
                this.formsConfig(this.options)[formName]
            );
            this.forms[formName] = {
                form: result[0],
                schema: result[1],
            };
        });

        this.forms['general'].form.markAllAsTouched();
        this.forms['blocks'].form.markAllAsTouched();
    }

    loadFormData(data: PatientFormData | void): void {
        if (data) {
            this.forms['general'].form.patchValue(data['general']);
            this.forms['clinicNotes'].form.patchValue(data['clinicNotes']);
            this.forms['diagnostic'].form.patchValue(data['diagnostic']);
            this.forms['dailyPattern'].form.patchValue(data['dailyPattern']);
            this.forms['blocks'].form.patchValue(data['blocks']);
            this.forms['documents'].form.patchValue(data['documents']);
        }

        //FIXME:There is currently a problem when setting the data.
        //If we set the departments and countries before the initforms function,
        // the arrayForms does not work. We will have to see another way
        // to fix this problem in the future.
        this.setOptions();

        if (!this.patientId) {
            // @ts-ignore
            this.forms['general'].form.controls['medicalCard'].controls['lastChronicRE'].disable();
            // @ts-ignore
            this.forms['general'].form.controls['medicalCard'].controls['lastSevereRE'].disable();
            this.formChanged = true;
        }
    }

    setOptions(): void {
        const countriesOptions = this.forms['general'].schema[1].childs;
        const departmentsOptions = this.forms['general'].schema[3].childs;

        if (countriesOptions) {
            countriesOptions[3].options = this.options['countries'];
        }
        if (departmentsOptions) {
            departmentsOptions[0].options = this.options['departments'];
        }
    }

    handleUpdatePatient(refresh: boolean = true): void {
        const formGeneral = this.forms['general'].form;
        const formClinic = this.forms['clinicNotes'].form;
        const formDiagnostic = this.forms['diagnostic'].form;
        const formDailyPattern = this.forms['dailyPattern'].form;
        const formBlocks = this.forms['blocks'].form;

        // Esto es para quitar el check de sirvi sin romper nada más
        formBlocks.controls['blocks'].value?.forEach((b: any) => {
            b.block.activatedSIRVI = false;
        });
        formBlocks.value.blocks?.forEach((b: any) => {
            if (b.block.startDateBlock) b.block.startDateBlock = moment(b.block.startDateBlock).startOf('day');
            if (b.block.endDateBlock) b.block.endDateBlock = moment(b.block.endDateBlock).startOf('day');
        });

        const formDocuments = this.forms['documents'].form;
        const form: PatientFormData = {
            general: formGeneral.value,
            clinicNotes: formClinic.value,
            diagnostic: formDiagnostic.value,
            dailyPattern: formDailyPattern.value,
            blocks: formBlocks.value,
            documents: formDocuments.value,
            rawData: this.patientData?.rawData
        };

        if (!this.patientId) {
            this.handleNewPatient(formGeneral, form);
        } else {
            this.handleEditPatient(
                formGeneral,
                formClinic,
                formDiagnostic,
                formDailyPattern,
                formBlocks,
                form,
                refresh
            );
        }
    }

    cancelChanges(): void {
        const pathName = location.pathname;
        const search = location.search;
        // Reload page
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(pathName + search);
        });
    }

    handleNewPatient(formGeneral: FormGroup<any>, form: PatientFormData): void {
        if (formGeneral.valid) {
            this.updatePatient(form);
        } else {
            if (!(formGeneral.controls['general'] as FormGroup).controls['name']?.valid)
                this.errorUpdatePatient('El nombre es obligatorio');
            else if (!(formGeneral.controls['general'] as FormGroup).controls['surname1']?.valid)
                this.errorUpdatePatient('El primer apellido es obligatorio');
            else if (!(formGeneral.controls['general'] as FormGroup).controls['personalId']?.valid)
                this.errorUpdatePatient('El DNI/NIE es incorrecto');
            else if ((formGeneral.controls['birthData'] as FormGroup).controls['gender']?.value == undefined)
                this.errorUpdatePatient('Es obligatorio seleccionar el género');
            else if ((formGeneral.controls['position'] as FormGroup).controls['department']?.value == undefined)
                this.errorUpdatePatient('Es obligatorio seleccionar el departamento');
            else if (!(formGeneral.controls['medicalCard'] as FormGroup).controls['cip']?.valid)
                this.patientCipaError ? this.errorUpdatePatient(this.patientCipaError) : null;
            else
                this.errorUpdatePatient('Existen errores en el formulario');
        }
    }

    handleEditPatient(
        formGeneral: FormGroup<any>,
        formClinic: FormGroup<any>,
        formDiagnostic: FormGroup<any>,
        formDailyPattern: FormGroup<any>,
        formBlocks: FormGroup<any>,
        form: PatientFormData,
        refresh: boolean = true
    ): void {
        const formBlocksValues = formBlocks.value.blocks;

        if ( formGeneral.valid ) {
            formClinic.enable();
            formDiagnostic.enable();
            formDailyPattern.enable();
            formBlocks.enable();
 
            this.forms['prescriptions'].form.enable();
            this.forms['documents'].form.enable();
        }

        if ( this.forms['general'].form.controls['dates'].get('deregistrationDate')?.value !== null &&
            // @ts-ignore
            !this.forms['general'].form.controls['dates'].get('deregistrationReason')?.disabled && !this.forms['general'].form.controls['dates'].get('deregistrationReason')?.valid ) {

            this.errorUpdatePatient('Indique un motivo de baja');
        } else if ( this.forms['general'].form.controls['dates'].get('deregistrationReason')?.valid &&
                    this.forms['general'].form.controls['dates'].get('deregistrationReason')?.value == 0 && 
                    this.forms['general'].form.controls['dates'].get('deregistrationDate')?.value > Utils.NOW ) {

            this.errorUpdatePatient('La fecha de defunción no puede ser posterior a la actual');
        } else if (
            this.institutionService.getCurrentInstitutionBusinessGroup() !== 1 ? formGeneral.valid : true &&
            formClinic.valid &&
            formDiagnostic.valid &&
            formDailyPattern.valid &&
            formBlocks.valid
        ) {
            // Comprobamos que en los tests de manejo no tengamos 2 dias iguales
            const values = (formClinic.controls['handlingHabilityTests'] as FormGroup).controls["results"].value as HandlingHabilityTestsResult[];
            for (let a = 0; a < values.length; a++) {
                const test = values[a];
                if (isMoment(test.date)) {
                    test.date = (test.date as Moment).toDate()
                }

                if (!(test.date instanceof Date)) {
                    this.calls.openSnack('Test de habilidad de manejo con fecha invalida', 'X', 5000);
                    return;
                }

                const coincidencia = values.find(v => v != test && moment(v.date).isSame(test.date, 'day'));
                if (coincidencia) {
                    this.calls.openSnack('No se puede introducir mas de un Test de habilidad de manejo por día', 'X', 5000);
                    return;
                }
            }

            if (this.checkValidBlocks(formBlocksValues)) {
                if (
                    formBlocksValues.length > 0 &&
                    this.checkBlockCollisions(formBlocksValues)
                ) {
                    this.modalService.openModalErrorInfo(
                        errorBlocks.collision.message,
                        errorBlocks.collision.title
                    );
                } else if ( formBlocksValues.slice(0, -1).some((item: any) => !item.block.endDateBlock) ) {
                    this.modalService.openModalErrorInfo(
                        errorBlocks.blockNotUnlocked.message,
                        errorBlocks.blockNotUnlocked.title
                    );
                } else {
                    if (!(formGeneral.controls['medicalCard'] as FormGroup).controls['cip']?.valid) {
                        this.patientCipaError ? this.errorUpdatePatient(this.patientCipaError) : null;
                        this.loadingService.start('Guardando Ficha...');

                        clearTimeout(this.updatePatientTimeout);
                        this.updatePatientTimeout = setTimeout(() => {
                            this.updatePatient(form, refresh);
                            this.loadingService.stop();
                        }, 1000)
                    } else {
                        this.updatePatient(form, refresh);
                    }
                    
                }
            } else {
                this.modalService.openModalErrorInfo(
                    errorBlocks.notValidBlock.message,
                    errorBlocks.collision.title
                );
            }
        } else if (!formGeneral.valid) {
            const controls = Object.keys(formGeneral.controls)
                .filter(control => !formGeneral.controls[control].valid)
                .map(control => (formGeneral.controls[control] as FormGroup).controls);

            // Check if errors in more than one controls
            if (controls.length > 1) {
                this.errorUpdatePatient('Existen errores en el formulario');
            } else {
                // Give more specific error
                // First get all subcontrols to check if more than one are invalid
                let subcontrols: any = [];
                controls.forEach(control => {
                    Object.keys(control)
                        .filter(subcontrol => !control[subcontrol].valid)
                        .forEach(subcontrol => {
                            subcontrols.push(control[subcontrol]);
                        })
                })

                if (subcontrols.length > 1) {
                    this.errorUpdatePatient('Existen errores en el formulario');
                } else {
                    controls.forEach(control => {
                        Object.keys(control)
                            .filter(subcontrol => !control[subcontrol].valid)
                            .forEach(subcontrol => {
                                switch (subcontrol) {
                                    case 'name': this.errorUpdatePatient('El nombre es obligatorio'); break;
                                    case 'surname1': this.errorUpdatePatient('El primer apellido es obligatorio'); break;
                                    case 'gender': this.errorUpdatePatient('Es obligatorio seleccionar el género'); break;
                                    case 'department': this.errorUpdatePatient('Es obligatorio seleccionar el departamento'); break;
                                    case 'deregistrationReason': this.errorUpdatePatient('Indique un Motivo de Baja'); break;;
                                    case 'personalId': this.errorUpdatePatient('El DNI/NIE es incorrecto'); break;
                                    case 'cip': this.patientCipaError ? this.errorUpdatePatient(this.patientCipaError) : null; break;
                                    default: this.errorUpdatePatient('Existen errores en el formulario'); break;
                                }
                            })
                    })
                }
            }
        } else {
            this.errorUpdatePatient('Existen errores en el formulario');
        }
    }

    checkValidBlocks(formBlocksValues: { block: BlockResult }[]): boolean {
        return formBlocksValues.every(({ block }) => {
            if (block.derivatedTo == null || block.reasonBlock != 3) block.derivatedTo = '';
            return (block.startDateBlock != null &&
                (moment(block.startDateBlock).isSameOrAfter(moment(), 'day') || block.id != undefined) &&
                ((block.endDateBlock ? moment(block.startDateBlock).isSameOrBefore((moment(block.endDateBlock)), 'day') : true) || block.id != undefined) &&
                block.reasonBlock != null &&
                (block.reasonBlock == 3 ? block.derivatedTo != null && block.derivatedTo.trim().length > 0 : true) &&
                (block.reasonBlock == 1 ? block.endDateBlock != null : true));
        });
    }

    checkBlockCollisions(formBlocksValues: { block: BlockResult }[]): boolean {
        const lastBlock = formBlocksValues[formBlocksValues.length - 1].block;

        for (let i = 0; i < formBlocksValues.length - 1; i++) {
            const block = formBlocksValues[i].block;
            const startDate1 = new Date(lastBlock.startDateBlock);
            const endDate1 = new Date(lastBlock.endDateBlock);
            const startDate2 = new Date(block.startDateBlock);
            const endDate2 = new Date(block.endDateBlock);

            if (
                this.dateService.checkCollision(
                    startDate1,
                    endDate1,
                    startDate2,
                    endDate2
                )
            ) {
                return true;
            }
        }

        return false;
    }

    errorUpdatePatient(message: string): void {
        this.forms['general'].form.markAllAsTouched();
        this.forms['blocks'].form.markAllAsTouched();
        this.calls.openSnack(message, 'X', 5000);
    }

    updatePatient(form: PatientFormData, refresh: boolean = true): void {
        this.patientService.updatePatientAPI(
            form,
            this.patientId !== null ? +this.patientId : null,
            this.tabGroup.selectedIndex ?? 0,
            refresh
        );
        this.formChanged = false;
        this.tabsChanged = [];
    }

    setFormListeners(): void {
        // if ( this.patientIncomplete() ) {
        //     this.forms['clinicNotes'].form.disable();
        //     this.forms['diagnostic'].form.disable();
        //     this.forms['dailyPattern'].form.disable();
        //     this.forms['blocks'].form.disable();
        //     this.forms['prescriptions'].form.disable();
        //     this.forms['documents'].form.disable();
        // }

        this.formSubscription.push(
            this.forms['general'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
                this.tabsChanged.push('general')

                /*
                 * Configure CIPA validation
                 */
                if ( !!this.forms['general'].form.controls['medicalCard'].get('privateInsurance')?.value &&
                      !this.forms['general'].form.controls['medicalCard'].get('cip')?.value ) {
                    this.forms['general'].form.controls['medicalCard'].get('cip')?.setValidators([]);
                    this.forms['general'].form.controls['medicalCard'].get('cip')?.setErrors(null);
                } else {
                    this.forms['general'].form.controls['medicalCard'].get('cip')?.setValidators([
                        Validators.required,
                        // @ts-ignore
                        Validators.pattern(this.patientCipaPattern)
                    ]);
                    this.forms['general'].form.controls['medicalCard'].get('cip')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                }

                // @ts-ignore
                if ( this.forms['general'].form.controls.dates.controls.deregistrationDate.value !== null ) {
                    // @ts-ignore
                    this.forms['general'].form.controls.dates.controls.deregistrationReason.setValidators([Validators.required]);
                    // @ts-ignore
                    this.forms['general'].form.controls.dates.controls.deregistrationReason.markAsTouched();
                } else {
                    // @ts-ignore
                    this.forms['general'].form.controls.dates.controls.deregistrationReason.removeValidators([Validators.required]);
                }
            })
        );

        this.formSubscription.push(
            this.forms['clinicNotes'].form.valueChanges.subscribe((val) => {
                this.formChanged = true;
                this.tabsChanged.push('clinicNotes')
            })
        );

        this.formSubscription.push(
            this.forms['diagnostic'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
                this.tabsChanged.push('diagnostic')
            })
        );

        this.formSubscription.push(
            this.forms['dailyPattern'].form.valueChanges.subscribe(() => {
                this.formChanged = true;
                this.tabsChanged.push('dailyPattern')
            })
        );

        this.formSubscription.push(
            this.forms['blocks'].form.valueChanges.subscribe((d) => {
                this.formChanged = true;
                this.tabsChanged.push('blocks')
            })
        );
        this.formChanged = false;
    }

    saveRe() {
        this.handleUpdatePatient(false);
    }

    navChange = false;
    tabIndexChange(index: number) {
        this.currentTab = index;

        if (this.navChange) {
            this.navChange = false;
            return;
        }
        this.location.go(location.pathname, "tab=" + index);

        this.titleService.setTitle(`Paciente: ${this.tabs[index].label}`)
        this.gaService.pageView(`${location.pathname} tab=${index}`, `Paciente: ${this.tabs[index].label}`);
        
        this.prescriptionEdit?.closeEdit();
    }

    @HostListener('window:popstate', ["$event"])
    test(event: any) {
        this.navChange = true;
    }

    checkCanNavigate() {
        if ( this.formChanged || this.prescriptionInited() ) {
            this.errorUpdatePatient('Hay cambios en el formulario, guarda la ficha antes de seguir')
        }
    }

    patientIncomplete() {
        return this.forms['general'] && this.forms['general'].form.invalid && this.patientId;
    }

    cancelNew() {
        this.modalService.openModalCallback(
            () => {
                this.router.navigate(["/global-eye/patients/cards"])
            },
            '¿Está seguro de volver sin guardar?',
            'Cancelar Ficha'
        );
    }
}
