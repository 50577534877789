import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IntakeTable } from "@interfaces/patient/patient.interface";

function matchFieldValueValidator(fieldToMatch: string): ValidatorFn {
    return (field: AbstractControl): ValidationErrors | null => {
        const valueToMatch = field.parent?.getRawValue()[fieldToMatch];
        return valueToMatch !== field.value ? { mismatch: true } : null;
    };
}

function intakeTableValidator(): ValidatorFn {
    return (field: AbstractControl): ValidationErrors | null => {
        const table: IntakeTable = field.value;
        if (!table) return null;

        const result = Object.values(table).some((day) => {
            return Object.values(day).some((time) => {
                return !!time.quantity && Number(time.quantity.toString().replace(',','.')) > 0 && Number(time.quantity.toString().replace(',','.')) <= 500
                    && !!time.department
            })
        })

        return result ? null : { required: true };
    };
}

function dniValidator(): ValidatorFn {
    return (field: AbstractControl): ValidationErrors | null => {
        const letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        const dni = field.value as string;

        if ( dni === null || dni === undefined || dni === '' ) {
            return null;
        }
        if (dni === undefined || dni.length != 9) return {
            incorrectDniLength: dni?.length | 0
        };

        const firstChar = dni.slice(0, 1);
        const isNie = firstChar.toLowerCase() === 'x' || firstChar.toLowerCase() === 'y' || firstChar.toLowerCase() === 'z';

        let numbers;

        if ( isNie ) {
            switch( firstChar.toLowerCase() ) {
                case 'y': numbers = `1${dni.slice(1, 8)}`; break;
                case 'z': numbers = `2${dni.slice(1, 8)}`; break;
                default: numbers = `0${dni.slice(1, 8)}`
            }
        } else {
            numbers = dni.slice(0, 8);
        }

        if (isNaN(Number.parseInt(numbers))) return {
            incorrectDniNumber: false
        }

        const letter = dni.slice(-1);
        const correctLetter = letters.charAt(Number.parseInt(numbers) % 23);
        if (letters.indexOf(letter.toUpperCase()) < 0 || letter.toUpperCase() != correctLetter) return {
            incorrectDniLetter: letter
        }
        return null;
    };
}

function sameErrorValidator(fieldControl: AbstractControl): ValidatorFn {
    return (field: AbstractControl): ValidationErrors | null => {
        if (fieldControl instanceof FormArray) {
            const errors: {}[] = findErrors(fieldControl.controls as any);
            if (errors == null) return fieldControl.errors;
            fieldControl.markAllAsTouched();
            return Object.assign({}, ...errors);
        }
        return fieldControl.errors;
    };
}

function findErrors(controls: AbstractControl<any, any>[], errors: ValidationErrors[] = []) : any {
    controls = controls.flat();
    if (controls instanceof FormControl) {
        return controls.errors;
    }

    if (controls == undefined || controls.length == 0) return null;

    controls.forEach((ctrl: any) => {
        const ctrls: any = ctrl['controls'];
        if (ctrls == undefined) {
            errors.push([...Object.values(ctrl).map((c: any) => c.errors).filter(c => c != undefined)]);
        }
        else {
            findErrors([ctrls], errors);
        }
    });

    return errors.flat();
}

export { matchFieldValueValidator, intakeTableValidator, sameErrorValidator, dniValidator }
