import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-expansion-group',
    templateUrl: './expansion-group.component.html',
    styleUrls: ['./expansion-group.component.scss'],
})
export class ExpansionGroupComponent {
    @Input() value: string;
    @Input() label: string;
    @Input() fieldControl: FormGroup;
    @Input() schema: FormItemSchemaInterface[];
    @Input() fieldsData?: any | { title: '' };

    private subs: Subscription[] = [];
    isNew = false;
    newExpanded = false;

    ngOnInit(): void {
        this.isNew = this.fieldsData?.new ?? false;
        if (this.fieldsData != undefined) this.fieldsData.new = false;
        this.newExpanded = this.fieldsData?.newExpanded ?? null;
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
