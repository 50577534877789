import { Component, ChangeDetectorRef } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { Subscription, skipWhile, take } from 'rxjs';

import { CardsComponent } from './cards/cards.component';
import { InstitutionEditComponent } from '@shared/institution-edit/institution-edit.component';

import { routeInstitutions } from '@services/mocks/institution';

import { InstitutionService } from '@services/institution.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { LoadingService } from '@services/loading.service';

import { Inst, Institution } from '@interfaces/institution/institution.interface';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { ModalService } from '@services/modal.service';
import { ListsComponent } from './lists/lists.component';
import { AuthService } from '@services/auth.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-institutions-quantum',
  templateUrl: './institutions-quantum.component.html',
  styleUrls: ['./institutions-quantum.component.scss']
})
export class InstitutionsQuantumComponent {

  public route: string = routeInstitutions;
  institution?: InstitutionEditComponent;

  public static currentData: Institution[] = [];
  nextInstitution?: number = undefined;
  prevInstitution?: number = undefined;
  public institutions: Institution[] = [];
  private filteredInstitutions: Institution[] = [];

  public filteredResults: any[] = [];

  public institutionsFilters: any;

  public institutionSelecFilter: FilterSelect[] = [];
  public selectedFilters: FilterOption[] = [];

  private subs: Subscription[] = [];

  public totalElements: number | undefined = undefined;
  public currentElementIndex: number | undefined = undefined;

  public resultsRefreshed: boolean = false;

  cardsRef: CardsComponent;

  public initLoading: boolean = false;

  private sub: any = {};

  constructor(
    private router: Router,
    private institutionService: InstitutionService,
    private institutionsService: InstitutionsService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private gaService: GoogleAnalyticsService
  ){
  }

  ngOnInit() {
    this.loadingService.start('Cargando...');
    this.initLoading = true;

    this.getInstitutions();
    this.getInstitutionsTypes();

    this.titleService.setTitle(`Instituciones`)
    this.gaService.pageView(`${window.location.href}`, `Instituciones`);
  }

  getInstitutions(): void {
    this.subs.push(
      this.institutionsService.institutionList
      .pipe(skipWhile(data => !data.length))
      .subscribe((institutions) => {
        if (institutions.length > 0) {
          this.institutions = institutions.map((i) =>
            this.institutionsService.transformInstitutionAPItoApp(i)
          );
          this.filteredInstitutions = [...this.institutions];
          this.totalElements = this.filteredInstitutions.length;
          this.currentElementIndex = 1;

          if ( this.cardsRef ) {
            this.cardsRef.currentData = this.filteredInstitutions;
            this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
            this.cardsRef.loading = false;
          }

          this.initLoading = false;
          if ( !this.onEditPage() || (this.onEditPage() && this.institution && this.institution.formReady) ) {
            this.loadingService.stop();
          }
        }
      })
    );

    this.institutionsService.getInstitutions(
      false,
      // Hay que especificar la residencia actual si no eres pharma (aunque luego te devuelva todas a las que tiene acceso el usuario)
      AuthService.decodedToken.corotaIsFarmacia ? null : this.institutionService.getCurrentInstitution()
    );
  }

  getInstitutionsTypes(): void {
    this.subs.push(
      this.institutionsService.institutionTypeList
      .pipe(
        skipWhile(data => !data.length),
        take(1))
      .subscribe(
        (institutionsType) => {
          this.institutionsFilters = institutionsType.map((iT) =>
            this.institutionsService.transformInstitutionTypeAPItoApp(iT)
          );
          this.institutionSelecFilter =
            this.institutionsService.transformInstitutionTypeToSelectGroup(
              this.institutionsFilters
            );
          this.selectedFilters =
            this.institutionsService.tranformInstitutionTYpeToOption(
              this.institutionsFilters
            );
        }
      )
    );

    this.institutionsService.getInstitutionsType();
  }

  changeView(event: MatButtonToggleChange): void {
    this.modalService.changeView(event, this.route, '');
  }

  onOperationBarAction($event: MatButtonToggleChange) {
    switch( $event.value ) {
        case 'first': this.gotToByIndex(0); break;
        case 'last': this.gotToByIndex(this.filteredInstitutions.length - 1); break;
        case 'next': this.siguiente(); break;
        case 'prev': this.anterior(); break;
        case 'delete': /* this.borrar(); */ break;
        default: this.changeView($event);
    }
  }

  onActivate(componentRef: any) {
    if (componentRef instanceof InstitutionEditComponent) {
      this.resultsRefreshed = false;
      this.institution = componentRef;

      if (componentRef.institutionId == null) {
        componentRef.onInstitutionId = (id) => {
          this.updateControlButtons(id, this.filteredInstitutions);
        };
      }
      else {
        this.updateControlButtons(componentRef.institutionId, this.filteredInstitutions);
      }
    }
    else if (componentRef instanceof CardsComponent) {
      this.cardsRef = componentRef;
      this.cardsRef.currentData = this.filteredInstitutions;
      this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
      this.cardsRef.loading = false;
    }
  }

  updateControlButtons(id: string | null, data: Institution[]) {
    if (id != null) {
      const index = data.findIndex(v => String(v.id) == id);

      this.totalElements = data.length;
      this.currentElementIndex = index + 1;   // Because array starts on 0

      if (index >= 0) {
        this.nextInstitution = data[index + 1]?.id;
        this.prevInstitution = data[index - 1]?.id;
        this.cdr.detectChanges();
      }
    }
  }

  anterior(evt?: MatButtonToggleChange) {
    const params = new URLSearchParams(location.search);
    let queryParams: any = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    // this.router.navigate(["/global-eye/welcome"]).then((r) => {
    //     if (r) {
            this.institution?.ngOnDestroy();
            this.router.navigate([this.route, 'edit', this.prevInstitution], {
                queryParams: queryParams,
                state: {
                    ignore: false
                }
            }).then(() => this.institution?.ngOnInit())
    //     }
    // });
    if (evt != undefined) evt.source.checked = false;
  }

  siguiente(evt?: MatButtonToggleChange) {

    const params = new URLSearchParams(location.search);
    let queryParams: any = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    // this.router.navigate(["/global-eye/welcome"]).then((r) => {
    //     if (r) {
            this.institution?.ngOnDestroy();
            this.router.navigate([this.route, 'edit', this.nextInstitution], {
                queryParams: queryParams,
                state: {
                    ignore: false
                }
            }).then(() => this.institution?.ngOnInit())
    //     }
    // });
    if (evt != undefined) evt.source.checked = false;
  }

  gotToByIndex(index: number, evt?: MatButtonToggleChange) {
    const params = new URLSearchParams(location.search);
    let queryParams: any = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    this.institution?.ngOnDestroy();
    this.router.navigate([this.route, 'edit', this.filteredInstitutions[index].id], {
        queryParams: queryParams,
        state: {
            ignore: false
        }
    }).then(() => this.institution?.ngOnInit())

    if (evt != undefined) evt.source.checked = false;
  }

  showArrows() {
    return this.router.url.includes("/institutions/edit");
  }

  onListPage() {
    return this.router.url.includes("/institutions/cards") || this.router.url.includes("/institutions/list");
  }
  onEditPage() {
    return this.router.url.includes("/institutions/edit");
  }

  borrar(evt?: MatButtonToggleChange) {
    if (evt != undefined) evt.source.checked = false;
  }

  onFilterResultSelected($event: any) {
    const params = new URLSearchParams(location.search);
    let queryParams: any = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    this.router.navigate([`${this.route}/edit/${$event}`], {
      queryParams: queryParams,
      state: {
        ignore: true
      }
    }).finally(() => this.institution?.ngOnInit())
  }

  filter($event: any) {
    if ( $event.filter.trim().length > 0 || $event.forceFilterRequest ) {
      this.sub.filter?.unsubscribe();
      this.filterByConfig($event, this.institutions);
    } else {
      this.filterByConfig($event, this.institutions);
    }
  }

  filterByConfig(filters: any, institutions: Institution[]) {
    /*
     * Filter patients by selected filters
     */
    const pharmacy = filters.select.find((item: FilterOption) => item.id === '389') ? true : false;
    const hospital = filters.select.find((item: FilterOption) => item.id === '387') ? true : false;
    const residency = filters.select.find((item: FilterOption) => item.id === '388') ? true : false;
    const other = filters.select.find((item: FilterOption) => item.id === '390') ? true : false;

    this.filteredInstitutions = institutions;

    let asPharmacy: Institution[] = institutions.filter(i => i.type.toLocaleLowerCase() === 'farmacia');
    let asHospital: Institution[] = institutions.filter(i => i.type.toLocaleLowerCase() === 'hospital');
    let asResidency: Institution[] = institutions.filter(i => i.type.toLocaleLowerCase() === 'residencia');
    let asOther: Institution[] = institutions.filter(i => i.type.toLocaleLowerCase() !== 'farmacia' && i.type.toLocaleLowerCase() !== 'hospital' && i.type.toLocaleLowerCase() !== 'residencia');

    if ( !pharmacy ) {
      asPharmacy = [];
    }
    if ( !hospital ) {
      asHospital = [];
    }
    if ( !residency ) {
      asResidency = [];
    }
    if ( !other ) {
      asOther = [];
    }

    this.filteredInstitutions = [...new Set([...asPharmacy, ...asHospital, ...asResidency, ...asOther])];

    this.filteredInstitutions = this.filteredInstitutions.filter((institution: Institution) => 
      (filters.search.trim().length == 0 ? true : Object.values(institution).some((val) => filters.search.trim().length > 0 && val?.toString().toLowerCase().includes(filters.search.toLowerCase())))
    )

    // Sort filtered result
    this.filteredInstitutions = this.filteredInstitutions.sort((a: Institution, b: Institution) => a.id - b.id);

    // Update cards patients
    if ( this.cardsRef ) {
      this.cardsRef.currentData = this.filteredInstitutions;
      this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
      this.cardsRef.paginator.firstPage();
    }

    this.filteredResults = this.filteredInstitutions.map((institution: Institution) => {
      return { id: institution.id, label: institution.name }
    });

    if ( (this.onEditPage() && this.institution && this.institution.formReady) ) {
      this.resultsRefreshed = false;
      this.cdr.detectChanges();
      this.resultsRefreshed = true;
      this.cdr.detectChanges();
    }
  }
}
