import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor() { }
    download(url: string, fileName: string, mimeType?: string): void {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                if (mimeType) a.type = mimeType;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    printFile(url: string, fileName?: string): void {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;

                iframe.onload = function () {
                    const listener = (evt: Event) => {
                        window.removeEventListener('focus', listener);
                        document.body.removeChild(iframe);
                        window.URL.revokeObjectURL(url);
                    };
                    window.addEventListener('focus', listener);

                    iframe.contentWindow?.print();
                };

                document.body.appendChild(iframe);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    getDocumentURL(environment: string, type: string): string {
        return `${environment}/resources/assets/docs/${type}/docs/e00`;
    }

    getDocFileName(name: string, id?: number): string {
        return id !== undefined && id > 0
            ? this.padLeft(id, '0', 10) + '_' + name
            : name;
    }

    padLeft(str: string | number, padChar: string, totalLength: number): string {
        str = String(str);
        while (str.length < totalLength) {
            str = padChar + str;
        }
        return str;
    }
}
