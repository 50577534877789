<ng-container class="report-custom-container">
    <app-report-select [title]="'Reportes de alertas'"
        (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
        (excelClicked)="handleExcelClick()">
        <div slot="content">
            <form [formGroup]="form">
                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
            
                  </section>
                  
                <section class="interval">
                    <p class="subtitle"> Tipo de reporte</p>
                    <mat-radio-group aria-label="Selecciona una opción">
                        <mat-radio-button value="1" [checked]="true">Listado de alertas
                        </mat-radio-button>
                        <mat-radio-button value="2" [checked]="false" [disabled]="true">Estadística del período
                        </mat-radio-button>
                    </mat-radio-group>
                </section>

                <section class="filter-container">
                    <p class="subtitle">Filtrar por</p>

                    <mat-checkbox class="col-12" (change)="toggleSelectInterval()">
                        Intervalo
                    </mat-checkbox>
                    <div *ngIf="showIntervalSelect" class="interval"  class="select col-12">
                        <mat-form-field>
                            <div class="label">Desde</div>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" />
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <div class="label">Hasta</div>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <mat-checkbox class="col-12" (change)="toggleSelectPatient()">
                        Paciente
                    </mat-checkbox>
                    <app-form-field  
                        class="select col-12" 
                        [dynamicError]="true" 
                        *ngIf="showPatientSelect" 
                        label="" 
                        type="autocomplete" 
                        [options]="selectOptionsPatientsAutocomplete" 
                        [fieldControl]="form.controls['selectedPatient'] | asFormControl">
                    </app-form-field>

                    <mat-checkbox class="col-12" (change)="toggleSelectFloor()">
                        Planta/comedor
                    </mat-checkbox>
                    <mat-form-field *ngIf="showDepartmentSelect" class="select col-12">
                        <!-- <div class="label">Selecciona una opción</div> -->
                        <mat-select formControlName="selectedDepartment">
                            <mat-option *ngFor="let option of selectOptionsDepartment" [value]="option.id">
                                {{ option.nam }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox class="col-12" (change)="toggleSelectPrescriptor()">
                        Personal prescriptor
                    </mat-checkbox>
                    <mat-form-field *ngIf="showPrescriptorSelect"  class="select col-12">
                        <!-- <div class="label">Selecciona una opción</div> -->
                        <mat-select formControlName="selectedPersonalPrescriptor">
                            <mat-option *ngFor="let option of selectOptionsPersonalPrescriptor" [value]="option.id">
                                {{ option.nam }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox class="col-12" (change)="toggleSelectTypologyAlert()">
                        Tipologia de alerta
                    </mat-checkbox>
                    <mat-form-field *ngIf="showTypologyAlertSelect"  class="select col-12">
                        <!-- <div class="label">Selecciona una o varias opciones</div> -->
                        <mat-select multiple formControlName="selectedTypologyAlert">
                            <mat-option *ngFor="let option of selectOptionsTypologyAlert" [value]="option.id">
                                {{ option.nam }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>
            </form>
        </div>
    </app-report-select>
</ng-container>
