import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { EventType } from '@json/src/app/enums/event-type';
import { Events } from '@services/events.service';
import { InstitutionService } from '@services/institution.service';
import { ModalService } from '@services/modal.service';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dropdown-select-institution',
    templateUrl: './dropdown-select-institution.component.html',
    styleUrls: ['./dropdown-select-institution.component.scss']
})
export class DropdownSelectInstitutionComponent implements OnInit, OnDestroy {

    @ViewChild('selectFilter') selectFilter: ElementRef;

    @Input() isExpanded: boolean = false;

    public currentInstitution: any = undefined; // Poner el tipado de las instituciones
    public optionSelected: any = undefined;
    public initials: string = '';
    public institutionOptions: SelectOption[] = [];
    public filteredInstitutionOptions: SelectOption[] = [];
    public infoSelectIntitution: string =
        'Selecciona una centro en el menú desplegable.';

    private subs: Subscription[] = [];

    filterSelectFormControl: FormControl = new FormControl();

    constructor(
        private institutionService: InstitutionService,
        private modalService: ModalService,
        private events: Events
    ) { }

    ngOnInit(): void {
        this.institutionService.getInstitutions(
            this.institutionService.getCurrentFarmacy()
        );
        this.subs.push(
            this.institutionService.institutionList.subscribe((list) => {
                this.institutionOptions = list;
                this.filteredInstitutionOptions = list;
                this.initInstitution();
            }),
            this.filterSelectFormControl.valueChanges.subscribe((value) => {
                this.filterSelectOptions(value);
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    initInstitution(): void {
        this.optionSelected = undefined;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
        const option = this.institutionOptions.find(
            (inst) => inst.value.id === this.currentInstitution
        );

        setTimeout(() => {
            this.optionSelected = option ? option.value : undefined;
        }, 0); // Hay que sacarlo del flujo para que el select se entere del cambio
    }

    loadInstitution(optionSelected: any): void {
        this.events.publish(EventType.InstitutionChange, optionSelected);
        this.institutionService.selectInstitution(optionSelected).then(() => {
            this.initInstitution();
        });
    }

    openConfirm(change: SelectOption): void {
        const institution = change.value;
        const name = this.institutionOptions.find(i => i.value === institution)?.label

        if ( institution.id !== this.currentInstitution){
            this.modalService.openModalSubs(ConfirmationDialogComponent, {
                title: '¿Cambiar centro?',
                message: `¿Está seguro de que desea cambiar al centro ${name}?`,
                buttonText: {
                    ok: 'Si',
                    cancel: 'No',
                },
            }).subscribe(res => {
                res ? this.loadInstitution(institution) : this.initInstitution();
            })
        }
    }

    getInitials() {
        if (this.optionSelected && this.optionSelected.name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...this.optionSelected.name.matchAll(rgx)] || [];

            initials = (
                (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        } else {
            return "";
        }
    }

    filterSelectOptions(event: string) {
        this.filteredInstitutionOptions = this.institutionOptions?.filter((option) => 
            event.split(' ').every((search: string) => {
                return option.label
                    ?.toString()
                    .toLowerCase()
                    .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                    .includes(search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
            })
        );
    }

    onMenuOpen() {
        setTimeout(() => {
            this.selectFilter.nativeElement.focus();
        });
    }
}
