<ng-container *ngIf="item">
  <ng-container [ngSwitch]="item.type">

    <!-- Direct link item -->
    <ng-container *ngSwitchCase="'link'">
      <mat-list-item
        routerLinkActive="is-active"
        [activated]="true"
        (click)="isExpanded = false; isExpandedChange.emit(false); navigateTo(item.link)"
        [ngClass]="{'no-icon': !item.iconName}"
        (mouseenter)="onMouseEnter($event, item)"
        (mouseleave)="onMouseLeave($event, item)"
      >
        <div class="list-item">
          <mat-icon mat-list-icon *ngIf="item.iconName">{{ item.iconName }}</mat-icon>
          <p *ngIf="isExpanded">{{ item.label }}</p>
        </div>

        <div class="tooltip"
          *ngIf="item.tooltipStatus && !isExpanded"
          [@inOutFadeFromLeft]="{value: true, params: {duration: '100ms'}}"
          [ngStyle]="{
            'top': tooltipStyle.top,
            'left': tooltipStyle.left,
            'height': tooltipStyle.height,
          }">
          <span>{{ item.label }}</span>
        </div>
      </mat-list-item>
    </ng-container>

    <!-- Submenu item -->
    <ng-container *ngSwitchCase="'menu'">
      <mat-list-item
        [matMenuTriggerFor]="menu.childMenu"
        (mouseenter)="onMouseEnter($event, item)"
        (mouseleave)="onMouseLeave($event, item)"
      >
        <div class="list-item">
          <mat-icon mat-list-icon>{{ item.iconName }}</mat-icon>
          <p *ngIf="isExpanded">{{ item.label }}</p>
        </div>

        <div class="tooltip"
          *ngIf="item.tooltipStatus && !isExpanded"
          [@inOutFadeFromLeft]="{value: true, params: {duration: '150ms'}}"
          [ngStyle]="{
            'top': tooltipStyle.top,
            'left': tooltipStyle.left,
            'height': tooltipStyle.height,
          }">
          <span>{{ item.label }}</span>
        </div>
      </mat-list-item>
      <app-sub-menu-item #menu [items]="item.items"></app-sub-menu-item>
    </ng-container>
  </ng-container>
</ng-container>
