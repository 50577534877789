import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrescriptionApp } from '@interfaces/patient/patient.interface';
import moment, { Moment, isMoment } from 'moment';

@Component({
    selector: 'app-stop-prescription',
    templateUrl: './stop-prescription.component.html',
    styleUrls: ['./stop-prescription.component.scss']
})
export class StopPrescriptionComponent {

    public prescription: PrescriptionApp;
    public today: Moment;
    public yesterday: Moment;
    public mode: 'stop' | 'modify';

    public form: FormGroup = new FormGroup({
        date: new FormControl(new Date())
    })

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { prescription: PrescriptionApp, mode: 'stop' | 'modify' },
        private dialogRef: MatDialogRef<StopPrescriptionComponent>
    ) {
        this.today = moment();

        this.yesterday = moment();
        this.yesterday.add(-1, 'day');
        if (data) {
            this.prescription = data.prescription;
            this.mode = data.mode;
        }
    }

    clickAccept(): void {
        if ( this.form.valid ) {
            let date = this.form.value.date;

            if (isMoment(date)) {
                date = date.toDate()
            }
            this.dialogRef.close(date);
        }
    }
}
