import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from '@json/src/app/Utils';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-session-expire',
    templateUrl: './session-expire.component.html',
    styleUrls: ['./session-expire.component.scss']
})
export class SessionExpireComponent {

    percent = 0;
    mytimeout: any;

    constructor(
        public dialogRef: MatDialogRef<SessionExpireComponent>,
        public auth: AuthService
    ) {
        var counter = 0;
        var startTime = Date.now();
        var onTimeout = () => {
            counter = Date.now() - startTime;
            // Si se llega al timeout sin apretar ningún botón, se hace Logout
            if (counter > 60000) {
                clearTimeout(this.mytimeout);
                this.dialogRef.close(false);
            }
            this.percent = Utils.mapRange(counter, 0, 60000, 0, 100);

            this.mytimeout = setTimeout(onTimeout, 100);
        }

        this.mytimeout = setTimeout(onTimeout, 100);
    }

    cerrar() {
        clearTimeout(this.mytimeout);
        this.dialogRef.close(false);
    }

    continuar() {
        clearTimeout(this.mytimeout);
        this.dialogRef.close(true);
    }
}
