import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, authGuardRol } from '@guards/auth.guard';
import { ForgotPasswordComponent } from '@shared/forgot-password/forgot-password.component';
import { InstitutionEditComponent } from '@shared/institution-edit/institution-edit.component';
import { LoginComponent } from '@shared/login/login.component';
import { MedicineEditComponent } from '@shared/medicine-edit/medicine-edit.component';
import { MedicineNewComponent } from '@shared/medicine-new/medicine-new.component';
import { NotFoundComponent } from '@shared/not-found/not-found.component';
import { PatientEditComponent } from '@shared/patient-edit/patient-edit.component';
import { ReportAuthBlistersComponent } from '@shared/report-auth-blisters/report-auth-blisters.component';
import { ReportChangeMedicationsComponent } from '@shared/report-change-medications/report-change-medications.component';
import { ReportClinicComponent } from '@shared/report-clinic/report-clinic.component';
import { ReportDebsOrdersComponent } from '@shared/report-debs-orders/report-debs-orders.component';
import { ReportElectronicRecipeComponent } from '@shared/report-electronic-recipe/report-electronic-recipe.component';
import { ReportIefComponent } from '@shared/report-ief/report-ief.component';
import { ReportIncontinenceComponent } from '@shared/report-incontinence/report-incontinence.component';
import { ReportListPatientsComponent } from '@shared/report-list-patients/report-list-patients.component';
import { ReportMedicamentNoEmblisComponent } from '@shared/report-medicament-no-emblis/report-medicament-no-emblis.component';
import { ReportMedicinesMissingComponent } from '@shared/report-medicines-missing/report-medicines-missing.component';
import { ReportPDietComponent } from '@shared/report-p-diet/report-p-diet.component';
import { ReportPMedicineUseComponent } from '@shared/report-p-medicine-use/report-p-medicine-use.component';
import { ReportPMovementsComponent } from '@shared/report-p-movements/report-p-movements.component';
import { ReportPescriptionsPEvalComponent } from '@shared/report-pescriptions-p-eval/report-pescriptions-p-eval.component';
import { ReportPharmaPlanComponent } from '@shared/report-pharma-plan/report-pharma-plan.component';
import { ReportPolimedicationComponent } from '@shared/report-polimedication/report-polimedication.component';
import { ReportScpComponent } from '@shared/report-scp/report-scp.component';
import { ReportViewerComponent } from '@shared/report-viewer/report-viewer.component';
import { ResetPasswordComponent } from '@shared/reset-password/reset-password.component';
import { SelectInstitutionComponent } from '@shared/select-institution/select-institution.component';
import { WelcomeComponent } from '@shared/welcome/welcome.component';
import { CardsComponent as InstitutionCardsComponent } from './components/institutions-quantum/cards/cards.component';
import { InstitutionsQuantumComponent } from './components/institutions-quantum/institutions-quantum.component';
import { ListsComponent as InstitutionListsComponent } from './components/institutions-quantum/lists/lists.component';
import { LayoutQuantumComponent } from './components/layout-quantum/layout-quantum.component';
import { CardsComponent as MedicineCardsComponent } from './components/medicines-quantum/cards/cards.component';
import { ListsComponent as MedicineListsComponent } from './components/medicines-quantum/lists/lists.component';
import { MedicinesQuantumComponent } from './components/medicines-quantum/medicines-quantum.component';
import { CardsComponent as PatientCardsComponent } from './components/patients-quantum/cards/cards.component';
import { ListsComponent as PatientListsComponent } from './components/patients-quantum/lists/lists.component';
import { PatientsQuantumComponent } from './components/patients-quantum/patients-quantum.component';
import { UsavedChangesGuard as UnsavedChangesGuard } from '@guards/unsaved-changes.guard';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { AdminComponent } from '@shared/admin/admin.component';
import { UserComponent as AdminUserComponent } from '@shared/admin/user/user.component';
import { UsersComponent as AdminUsersComponent } from '@shared/admin/users/users.component';
import { CardsComponent as AdminUsersCardsComponent } from '@shared/admin/users/cards/cards.component';
import { ListsComponent as AdminUserListsComponent } from '@shared/admin/users/lists/lists.component';
import { ReportActusComponent } from '@shared/report-actus/report-actus.component';
import { ReportFGPPrescriptionsComponent } from '@shared/report-fgp-prescriptions/report-fgp-prescriptions.component';
import { ProcessMedicationRegisterComponent } from '@shared/process-medication-register/process-medication-register.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
//   { path: 'forgot-password', component: ForgotPasswordComponent },
//   { path: 'reset-password/:token', component: ResetPasswordComponent },
  {
    path: 'select-institution',
    component: SelectInstitutionComponent,
    canActivate: [authGuard],
  },
  {
    path: 'global-eye',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    component: LayoutQuantumComponent,
    children: [
      { path: '', redirectTo: '', pathMatch: 'full' },
      { 
        path: 'welcome', 
        component: WelcomeComponent,
        canActivate: [authGuardRol(Permission.DASHBOARD)],
      },
      {
        path: 'patients',
        component: PatientsQuantumComponent,
        canActivate: [authGuardRol(Permission.PATIENTS)],
        children: [
          { path: '', redirectTo: 'cards', pathMatch: 'full' },
          { path: 'cards', component: PatientCardsComponent },
          { path: 'list', component: PatientListsComponent },
          { path: 'edit', component: PatientEditComponent, canActivate: [authGuardRol(Permission.PATIENTS, PermissionType.WRITE)] },
          { path: 'edit/:id', component: PatientEditComponent, canDeactivate: [UnsavedChangesGuard] },
        ],
      },
      {
        path: 'institutions',
        canActivate: [authGuardRol(Permission.INSTITUTIONS)],
        component: InstitutionsQuantumComponent,
        children: [
          { path: '', redirectTo: 'cards', pathMatch: 'full' },
          { path: 'cards', component: InstitutionCardsComponent },
          { path: 'list', component: InstitutionListsComponent },
          { path: 'edit', component: InstitutionEditComponent,  canActivate: [authGuardRol(Permission.INSTITUTIONS, PermissionType.WRITE)]},
          { path: 'edit/:id', component: InstitutionEditComponent, canDeactivate: [UnsavedChangesGuard] },
        ],
      },
      {
        path: 'medicines',
        component: MedicinesQuantumComponent,
        canActivate: [authGuardRol(Permission.VADEMECUM)],
        children: [
          { path: '', redirectTo: 'cards', pathMatch: 'full' },
          { path: 'cards', component: MedicineCardsComponent },
          { path: 'list', component: MedicineListsComponent },
          { path: 'edit', component: MedicineNewComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [authGuardRol(Permission.VADEMECUM, PermissionType.WRITE)] },
          { path: 'edit/:id', component: MedicineEditComponent, canDeactivate: [UnsavedChangesGuard] },
        ],
      },
      {
        path: 'process',
        canActivate: [authGuardRol(Permission.PROCESSES)],
        children: [
          { path: '', redirectTo: 'medication-register', pathMatch: 'full' },
          { path: 'medication-register', component: ProcessMedicationRegisterComponent },
        ]
      },
      {
        path: 'reports',
        canActivate: [authGuardRol(Permission.REPORTS)],
        children: [
          { path: '', redirectTo: 'autorblis', pathMatch: 'full' },
          { path: 'viewer', component: ReportViewerComponent },
          { path: 'autorblis', component: ReportAuthBlistersComponent },
          { path: 'pathmeds', component: ReportPMedicineUseComponent },
          { path: 'patientlist', component: ReportListPatientsComponent },
          { path: 'patchgs', component: ReportPMovementsComponent },
          { path: 'pharmaplan', component: ReportPharmaPlanComponent },
          { path: 'patdiet', component: ReportPDietComponent },
          { path: 'mednostock', component: ReportMedicinesMissingComponent },
          { path: 'nelist', component: ReportMedicamentNoEmblisComponent },
          { path: 'prescrpe', component: ReportPescriptionsPEvalComponent },
          { path: 'cursocl', component: ReportClinicComponent },
          { path: 'medchanges', component: ReportChangeMedicationsComponent },
          { path: 'medidebt', component: ReportDebsOrdersComponent },
          { path: 'restat', component: ReportElectronicRecipeComponent },
          { path: 'ief', component: ReportIefComponent },
          { path: 'diapers', component: ReportIncontinenceComponent },
          { path: 'polymed', component: ReportPolimedicationComponent },
          { path: 'pharmalert', component: ReportScpComponent },
          { path: 'actus', component: ReportActusComponent },
          { path: 'fgpprescriptions', component: ReportFGPPrescriptionsComponent },
        ],
      },
      {
        path: 'admin',
        component: AdminComponent,
        children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          { 
            path: 'users', 
            component: AdminUsersComponent, 
            children: [
              { path: '', redirectTo: 'cards', pathMatch: 'full' },
              { path: 'cards', component: AdminUsersCardsComponent, canActivate: [authGuardRol(Permission.USERS, PermissionType.READ)] },
              { path: 'list', component: AdminUserListsComponent, canActivate: [authGuardRol(Permission.USERS, PermissionType.READ)] },
              { path: 'new', component: AdminUserComponent, canActivate: [authGuardRol(Permission.USERS, PermissionType.WRITE)] },
              { path: 'edit/:id', component: AdminUserComponent },
            ] 
          },
        ]
      }
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
