import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { APIResponse, APIResponseParsed } from '@interfaces/api/api.interface';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            map((event) => {
                // gestionar respuestas
                if (event instanceof HttpResponse) {
                    // try { // como el backend a veces responde cosas raras...
                    let response: any = event.body;
                    if (response.Header == undefined) return event;
                    response = this.parse(response);

                    let copy = event.clone({
                        body: response
                    });

                    if (response.header.HasErrors) {
                        this.handle200Error(response);
                        // console.error({
                        //     error: response.payload.ErrorMessage,
                        //     headers: event.headers,
                        //     status: response.payload.ErrorCode,
                        //     url: event.url as string
                        // });
                        throw new HttpErrorResponse({
                            error: response.payload.ErrorMessage,
                            headers: event.headers,
                            status: response.payload.ErrorCode,
                            url: event.url as string
                        });
                    }

                    if (response.header.HasNewToken && !!response.header.NewToken) {
                        this.authService.setToken(response.header.NewToken, true);
                    }

                    return copy;

                } else {
                    return event;
                }
            }),
            catchError((error) => {
                return throwError(() => error);
            })
        );
    }

    private parse(response: APIResponse): APIResponseParsed {
        return {
            header: JSON.parse(response.Header),
            payload: JSON.parse(response.Payload),
        };
    }

    private handle200Error(response: APIResponseParsed) {
        console.log('handle200Error', response);
        switch (response.payload.ErrorCode) {
            case 250:
            case 266:
                this.router.navigate(['']);
                break;
        }
    }
}
