<mat-dialog-content class="confirmation-modal-container" >
  <h3 class="title" [ngClass]="type">{{ title }}</h3>
  <p class="content">
    {{ message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="confirmation-modal-buttons">
  <button
    mat-raised-button
    [color]="colorButtonOk"
    (click)="onConfirmClick()"
    tabindex="1"
  >
    {{ confirmButtonText }}
  </button>
  <button
    *ngIf="!data.onlyInfo"
    mat-raised-button
    (click)="onCancelClick()"
    tabindex="-1"
  >
    {{ cancelButtonText }}
  </button>
  <button
    *ngIf="!data.onlyInfo && auxButtonText"
    mat-raised-button
    (click)="onAuxClick()"
    tabindex="-1"
  >
    {{ auxButtonText }}
  </button>
</mat-dialog-actions>
