import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataClinic } from '@constants/reports/report-clinic';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    DepartmentPharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-clinic',
    templateUrl: './report-clinic.component.html',
    styleUrls: ['./report-clinic.component.scss'],
})
export class ReportClinicComponent {
    public reportDataClinic = reportDataClinic;
    public showPatientSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showIntervalDay: boolean = false;
    public showSpecificDaySelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public selectOptionsPatients: SelectOption[];
    public selectOptionsDepartment: DepartmentPharma[];

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService, 
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            specificDay: [moment()],
            startDate: [moment().subtract(1, 'week')],
            endDate: [moment().add(1, 'week')],
            selectedDepartment: [''],
            selectedPatient: [''],
            intervalType: ['specificDay'],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Curso clínico`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Curso clínico`);

        this.typeGlobal = this.roleManager.isPharma();

        this.getDataForTheSelects();
        this.setListenerSelectDay();
        this.setCurrentDate();
    }

    setCurrentDate(): void {
    }

    setListenerSelectDay(): void {
        this.form.get('intervalType')?.valueChanges.subscribe((value) => {
            if (value === 'specificDay') {
                this.showSpecificDaySelect = true;
                this.showIntervalDay = false;
            } else if (value === 'intervalDay') {
                this.showSpecificDaySelect = false;
                this.showIntervalDay = true;
            }
        });
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelectSpecificDay(): void {
        this.showSpecificDaySelect = !this.showSpecificDaySelect;
        if (!this.showSpecificDaySelect) {
            this.form.patchValue({
                specificDay: '',
            });
        }
    }

    toggleSelectIntervalDay(): void {
        this.showIntervalDay = !this.showIntervalDay;
        if (!this.showIntervalDay) {
            this.form.patchValue({
                startDate: '',
                endDate: '',
            });
        }
    }


    getDataForTheSelects(): void {


        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());
               // Order Alpabetically label and text uppercase
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
                // this.selectOptionsInsitutions.map((a) =>{
                //     a.label = a.label.toUpperCase();
                // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
          });

        this.reportService
            .getRepParamsEditDataCursoClin(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsPatients = result.payload.pats.map((patient: PatientPharma) => { return { value: patient.id, label: patient.nam } });
            });
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate, selectedPatient } =
                this.getFormData();

            this.setInfoRP(specificDay, startDate, endDate, selectedPatient, true);

            this.reportService.handlePreviewClick(this.router, this.reportDataClinic);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    getFormData(): {
        specificDay: string;
        startDate: string;
        endDate: string;
        selectedPatient: string;
    } {
        const specificDay = this.dateService.formatDateToString(
            this.form.get('specificDay')?.value
        );
        const startDate = this.dateService.formatDateToString(
            this.form.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.form.get('endDate')?.value
        );
        const selectedPatient = this.form.get('selectedPatient')?.value;

        return { specificDay, startDate, endDate, selectedPatient };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate, selectedPatient } =
                this.getFormData();

            this.setInfoRP(specificDay, endDate, startDate, selectedPatient);
            this.reportService.handlePrintClick(this.reportDataClinic);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate, selectedPatient } =
                this.getFormData();
            this.setInfoRP(specificDay, startDate, endDate, selectedPatient);
            this.reportService.handlePdfClick(this.reportDataClinic);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate, selectedPatient } =
                this.getFormData();

            this.setInfoRP(specificDay, startDate, endDate, selectedPatient);
            this.reportService.handleExcelClick(this.reportDataClinic);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(
        specificDay: string,
        startDate: string,
        endDate: string,
        selectedPatient: string,
        isPreview: boolean = false
    ): void {
        let startDateRP;
        let endDateRP;

        if (this.form.controls['intervalType'].value == 'specificDay') {
            startDateRP = specificDay;
            endDateRP = this.dateService.transformDateTimeIncrementHours(specificDay);
        } else {
            startDateRP = startDate;
            endDateRP = endDate;
        }

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id


        const rp = JSON.stringify({
            LocaleId: '3082',
            InstitutionId: String(currentInstitution),
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDateRP).startOf('day').toDate())?.toISOString().split(".")[0],
            DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDateRP).endOf('day').toDate())?.toISOString().split(".")[0],
        });

        this.reportDataClinic.data.rp = rp;
        if (isPreview) this.reportDataClinic.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataClinic.dataDownloadPDF.rp = rp;
        this.reportDataClinic.dataDownloadExcel.rp = rp;
        this.reportDataClinic.dataRenderReport.rp = rp;

        const now = moment();
        this.reportDataClinic.dataDownloadPDF.dn = this.reportDataClinic.dataRenderReport.dn =
            `Reporte_de_curso_clinico_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;

        this.reportDataClinic.dataDownloadExcel.dn = `Reporte_de_curso_clinico_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
