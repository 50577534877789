import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordForm } from '@interfaces/forms.interface';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public form: FormGroup<ForgotPasswordForm> = new FormGroup({
    user: new FormControl<string>('', { validators: [Validators.required], nonNullable: true})
  });

  constructor(
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.form.valid) {
      const { user } = this.form.getRawValue();
      this.authService.forgotPassword(user);
    }
  }
}

