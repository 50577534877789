import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { debounce, of, skipWhile, timer } from 'rxjs';

import { FilterService } from '@services/filter/filter.service';

import { FilterOption, FilterSelect } from '@interfaces/filter.interface';

import { inOutFade } from '@json/src/app/animations/general';
import { SelectOption } from '@interfaces/input-select-option.interface';

import { CustomButtonFilterStatus } from '@enums/custom-button-filter-status';
import { Router } from '@angular/router';

@Component({
    selector: 'app-operation-bar',
    templateUrl: './operation-bar.component.html',
    styleUrls: ['./operation-bar.component.scss'],
    animations: [inOutFade]
})
export class OperationBarComponent implements OnInit, OnDestroy, OnChanges{

  @Input() arrowNavitation: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canCreate: boolean = true;

  @Input() customButtonToggle: boolean = false;
  @Input() customButtonIcon: string = '';
  @Input() customButtonIconTooltip: string = '';
  @Input() customButtonStatus: CustomButtonFilterStatus = CustomButtonFilterStatus.Hide;

  @Input() customButtonToggle2: boolean = false;
  @Input() customButtonIcon2: string = '';
  @Input() customButtonIconTooltip2: string = '';
  @Input() customButtonStatus2: CustomButtonFilterStatus = CustomButtonFilterStatus.Hide;

  @Input() customButtonToggle3: boolean = false;
  @Input() customButtonIcon3: string = '';
  @Input() customButtonIconTooltip3: string = '';
  @Input() customButtonStatus3: CustomButtonFilterStatus = CustomButtonFilterStatus.Hide;

  @Input() infoButton?: {
    icon?: string,
    label: string,
    url: string
  } = undefined;

  @Input() auxiliaryImageButton?: {
    image?: string,
    label: string,
    route: string
  } = undefined;

  @Input() filter: boolean = false;
  @Input() filterQuery: string = '';
  @Input() dropdownFilter: boolean = false;
  @Input() dropdownOptions: FilterSelect[] = [];
  @Input() dropdownInitialSelect: FilterOption[] = [];

  @Input() dropdownInstitutionFilter: boolean = false;
  @Input() dropdownInstitutionOptions: SelectOption[] = [];
  @Input() dropdownInstitutionInitialSelect: FilterOption[] = [];

  @Input() search: boolean = false;
  @Input() alertButton: boolean = false;
  @Input() alertButtonStatus: boolean = false;
  @Input() listMode: boolean = true;
  @Input() reportInstitutionId: number | null;
  @Input() reportActions: boolean = true;
  @Input() currentPatientsData: string;
  @Input() filterLabel: string = 'Filtro';
  @Input() dropdownInstitutionFilterLabel: string = 'Centros';
  @Input() dropdownFilterLabel: string = 'Filtros';
  @Input() searchLabel: string = 'Búsqueda';
  @Input() totalElements: number | undefined = undefined;
  @Input() currentElementIndex: number | undefined = undefined;
  @Input() showResults: boolean = false;
  @Input() filterResults: any = [];
  @Input() otherFilters: {name:string, label: string, control: FormControl}[] = [];
  @Input() advancedFilters: boolean = false;

  @Output() onAction: EventEmitter<MatButtonToggleChange> = new EventEmitter();
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() onFilterResultSelected: EventEmitter<any> = new EventEmitter();

  CustomButtonFilterStatus = CustomButtonFilterStatus;

  form: FormGroup = new FormGroup({});

  sub: any = {};

  dropdownInstitutionInitialSelectUpdated: boolean = false;

  advancedFilterSelect: {filterId: string, mode: 'exclude' | 'include' | 'exclusive'}[] = [];

  constructor( 
    public filterService: FilterService,
    private router: Router,
  ) {}

  ngOnInit(): void {

    this.form.addControl('institution', new FormControl(this.dropdownInstitutionInitialSelect));
    this.form.addControl('select', new FormControl(this.dropdownInitialSelect));
    this.form.addControl('search', new FormControl(null));
    this.form.addControl('filter', new FormControl(this.filterQuery));

    this.sub.select = this.form.controls['select'].valueChanges
      .pipe(debounce(ev => this.form.controls['filter'].value?.trim().length > 0 ? timer(1000) : of({})))
      .subscribe(data => {
      this.runFilter();
    })


    this.sub.institution = this.form.controls['institution'].valueChanges
      .pipe(
        skipWhile(data => {
          if ( this.dropdownInstitutionInitialSelectUpdated ) {
            this.dropdownInstitutionInitialSelectUpdated = false;
            return true;
          }
          return false;
        }),
        debounce(ev => this.form.controls['filter'].value?.trim().length > 0 ? timer(1000) : of({}))
      )
      .subscribe(data => {
      this.runFilter();
    })

    this.sub.search = this.form.controls['search'].valueChanges
      .pipe(debounce(ev => this.form.controls['filter'].value?.trim().length > 0 ? timer(1000) : of({})))
      .subscribe(data => {
        this.runFilter();
    })

    this.sub.filter = this.form.controls['filter'].valueChanges
      .pipe(debounce(ev => this.form.controls['filter'].value?.trim().length > 0 ? timer(1000) : of({})))
      .subscribe(data => {
        this.runFilter(true);
    })

    this.otherFilters.forEach(filter => {
      this.sub[filter.name] = filter.control.valueChanges
        .pipe(debounce(ev => this.form.controls['filter'].value?.trim().length > 0 ? timer(1000) : of({})))
        .subscribe(data => {
          this.runFilter();
      })
    })
  }
  ngOnDestroy(): void {
    Object.keys(this.sub).forEach(key => {
      this.sub[key]?.unsubscribe();
    });
  }
  ngOnChanges(changes: SimpleChanges) {


    // @ts-ignore
    if ( changes.dropdownInitialSelect ) {
      this.form.controls['select']?.setValue(this.dropdownInitialSelect);
    }
    // @ts-ignore
    if ( changes.dropdownInstitutionInitialSelect ) {
      this.dropdownInstitutionInitialSelectUpdated = true;
      this.form.controls['institution']?.setValue(this.dropdownInstitutionInitialSelect);
    }
  }

  onButtonAction($event: MatButtonToggleChange) {
    $event.source.checked = false;

    switch($event.value) {
      case 'alert':
        this.alertButtonStatus = !this.alertButtonStatus;
        this.runFilter();
        break;
      default:
        this.onAction.emit($event);
      break;
    }
  }

  onCustomButtonAction(button: number, status: CustomButtonFilterStatus) {
    switch(button) {
      case 1:
        this.customButtonStatus = status;
        this.runFilter();
        break;
      case 2:
        this.customButtonStatus2 = status;
        this.runFilter();
        break;
      case 3:
        this.customButtonStatus3 = status;
        this.runFilter();
        break;
    }

  }

  runFilter(forceFilterRequest: boolean = false) {
    this.onFilter.emit({
      institution: this.form.controls['institution'].value || [],
      select: this.form.controls['select'].value || [],
      search: this.form.controls['search'].value || '',
      filter: this.form.controls['filter'].value || '',
      custom: this.customButtonStatus,
      custom2: this.customButtonStatus2,
      custom3: this.customButtonStatus3,
      alert: this.alertButtonStatus,
      forceFilterRequest,
      otherFilters: this.otherFilters
        .reduce((acc: any, curr: any)=> (acc[curr.name] = curr.control.value, acc),{}),
      advancedFilter: this.advancedFilterSelect
    })
  }

  openLink(): void {
    if (this.infoButton == undefined)return;
    window.open(this.infoButton?.url, '_blank');
  }

  navigate(route: string): void {
    this.router.navigate([route])
  }
}
