

<ng-container class="report-custom-container"><app-report-select [title]="'Consumo de medicamentos por paciente'"
    (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()">
    <div class="container-options" slot="content">
        <form [formGroup]="dateForm" class="container-form" >

            <section class="filter-container" *ngIf="typeGlobal">
                <div class="subtitle">Tipo de reporte</div>

                <mat-radio-group formControlName="reportType" class="col-6">
                    <mat-radio-button value="byPatients" class="col-4">
                        Por pacientes
                    </mat-radio-button>

                    <mat-radio-button value="byMedicines" class="col-4">
                        Por medicamentos
                    </mat-radio-button>
                </mat-radio-group>
            </section>

            <section class="filter-container" *ngIf="typeGlobal">
                <div class="subtitle">Medicamentos</div>

                <mat-radio-group formControlName="medicineType" class="col-6">
                    <mat-radio-button value="machine" class="col-4">
                        Máquina
                    </mat-radio-button>

                    <mat-radio-button value="tray" class="col-4">
                        Bandeja
                    </mat-radio-button>

                    <mat-radio-button value="machineAndTry" class="col-4">
                        Máquina y bandeja
                    </mat-radio-button>
                </mat-radio-group>
            </section>

            <section class="filter-container" *ngIf="typeGlobal" >
                <div class="subtitle">Centro</div>
                <app-form-field
                  *ngIf="!resetInstitutionsDropdown"
                  class="field"
                  [type]="'select'"
                  [label]="multipleInstitutionSelect ? 'Selecciona los centros' : 'Selecciona un centro'"
                  [fieldControl]="dateForm.controls['selectedInstitution'] | asFormControl"
                  [options]="selectOptionsInsitutions"
                  [multipleSelect]="multipleInstitutionSelect">
                </app-form-field>
            </section>
      

            <section class="filter-container">
                <div class="subtitle">Intervalo</div>

                <mat-form-field class="field col-4">
                    <div class="label">Desde</div>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="field col-4">
                    <div class="label">Hasta</div>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </section>
        </form>
    </div>
</app-report-select>
</ng-container>