<mat-card class="clickable" >
  <div class="header" (click)="toggleCardState()">
    <mat-card-title>{{
      cardState === 'expanded' ? title : shortedTitle(15)
    }}</mat-card-title>
    <div class="actions">
      <button
        mat-icon-button
        *ngFor="let action of actions"
        [title]="action.name"
        [id]="id"
        (click)="action.callback($event, id)"
      >
        <mat-icon>{{ action.iconName }}</mat-icon>
      </button>
    </div>
  </div>

  <mat-card-content>
      <p [ngClass]="descriptionClass">{{ description }}</p>
      <p *ngIf=" cardState == 'collapsed'">
        <ng-content select="[slot=descOnlyWhenCollapsed]"></ng-content>
      </p>
   
  </mat-card-content>

  <div [@expandCollapse]>
    <mat-card-content *ngIf="cardState === 'expanded'">
      <ng-content select="[slot=content]"></ng-content>
    </mat-card-content>
  </div>
</mat-card>

