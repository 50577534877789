<div class="button" 
  (click)="toggle()"
  [ngClass]="{'expanded': isExpanded}">
  <button
    mat-fab
    color="primary"
    aria-label="Button icon to open user detail"
  >
    <mat-icon>person</mat-icon>
    <!-- {{ userName }} -->
  </button>

  <span class="name" *ngIf="isExpanded">{{name}} {{firstName}}</span>
</div>

<section *ngIf="isOpen" [@inOutFadeFromLeft]="{value: true, params: {duration: '200ms'}}" class="detail-wrapper">
  <mat-card>
    <mat-card-header class="detail-header">
      <img
        mat-card-lg-image
        [src]="imgPath"
        alt="user photo"
        class="user-img"
      />

     <p>{{name}} {{firstName}}</p>

    </mat-card-header>

    <mat-card-content class="detail-content">
      <p>{{ input.inst }}</p>
      <p>{{ input.loginDate }}</p>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions class="detail-actions">
      <ng-container *ngFor="let route of routeArray">
        <a
          *ngIf="hasPermission(route)"
          mat-button
          routerLinkActive="router-link-active"
          [routerLink]="route.link"
          (click)="toggle()"
        >
          {{ route.label }}
        </a>
      </ng-container>
      <a mat-button (click)="logOut()">Cerrar sesión</a>
    </mat-card-actions>
    <mat-divider></mat-divider>
    <mat-card-content class="detail-content">
      <p>Versión: {{ version }}</p>
    </mat-card-content>
  </mat-card>
  <div class="logged-user-overlay" *ngIf="isOpen" (click)="toggle()"></div>
</section>
