<section [hidden]="loading" style="height: 100%;">
  <section #cards class="cards-wrapper">
    <div class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
      <div class="list-header">
        <div class="element space"></div>
        <div class="element sortable code" (click)="sortBy('code')" [ngClass]="{'sorted': currentSort === 'number'}">
          Código
          <mat-icon *ngIf="currentSort === 'code' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'code' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable name" (click)="sortBy('name')">
          Nombre
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable supplier" (click)="sortBy('supplier')">
          Distribuidor
          <mat-icon *ngIf="currentSort === 'supplier' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'supplier' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable quantity" (click)="sortBy('quantity')">
          Cantidad
          <mat-icon *ngIf="currentSort === 'quantity' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'quantity' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element actions">Acciones</div>
      </div>

      <app-card 
        class="list-mode"
        *ngFor="let medicine of currentData | slice : lowValue : highValue"
        [title]="medicine.name" 
        [id]="medicine.id" 
        [img]="defaultImg" 
        [route]="routes"
        [actions]="actions" 
        [flag]="medicine.flag" 
        [fgp]="isFGP(medicine)"
        [greyed]="isObsolete(medicine)"
        [typeGlobal]="typeGlobal"
        [colorHeader]="getColorHeader(medicine.flag)"
        [alert]="hasAlert(medicine)"
        [nameTooltip]="alertReason(medicine)"
        [containImage]="true"
        (deleteButtonCLicked)="handleDelete($event)">
        <div class="number" slot="number">
          {{ medicine.code }}
        </div>
        <div class="content" slot="content">
          <span class="supplier">{{ medicine.supplier }}</span>
          <span class="quantity">{{ medicine.quantity && medicine.quantity > 0 ? medicine.quantity : '' }}</span>
        </div>
      </app-card>
    </div>
    <ng-container *ngIf="!loading">
      <p *ngIf="currentFilter || currentSearch">
        {{ msgNotFound }}:
        <span class="search-value">{{ currentFilter || currentSearch }}</span>
      </p>
    </ng-container>

    <div class="pagination">
      <app-color-legend 
        class="legend" 
        [items]="colorLegendItems" 
        [legendPosition]="'top'"
      >
      </app-color-legend>

      <mat-paginator #paginator aria-label="Seleccionar pagina" [length]="currentData.length"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPageEvent($event)"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </section>
</section>
