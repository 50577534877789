<ng-container class="report-custom-container">
    <app-report-select [title]="'Dietas por paciente'" (previewClicked)="handlePreviewClick()"
        (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()" (excelClicked)="handleExcelClick()">
        <div slot="content"  class="container-options" >
            <form [formGroup]="form" class="container-form">

                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
        
                  </section>
      
                <section class="filter-container">
                <p class="subtitle">Filtrar por</p>

                    <mat-checkbox formControlName="checkboxControlPatient" class="col-12"(change)="toggleSelectPatient()">
                        Paciente
                    </mat-checkbox>
                    <div *ngIf="showPatientSelect" class="select col-12 ">
                        <app-form-field class="col-6" label="" type="autocomplete" [options]="selectOptionsPatientsAutocomplete" [fieldControl]="form.controls['selectedPatient'] | asFormControl"></app-form-field>
                    </div>

                    <mat-checkbox formControlName="checkboxControlDepartment"  class="col-12" (change)="toggleSelectDepartment()">
                        Departamento
                    </mat-checkbox>
                    <div *ngIf="showDepartmentSelect" class="select col-12">
                        <mat-form-field   class="col-6">
                            <!-- <div class="label">Selecciona una opción</div> -->
                              <mat-select formControlName="selectedDepartment">
                                  <mat-option *ngFor="let option of selectOptionsDepartment" [value]="option.id">
                                      {{ option.nam }}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                    </div>
                    
                </section>
            </form>
        </div>
    </app-report-select>
</ng-container>
