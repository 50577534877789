import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

@Component({
  selector: 'app-proc-plans-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class ProcPlansFormComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  @Input() cipaPattern: string | undefined;
  @Input() canGeneratePlan: boolean = false;

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {

    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
    // Enable all fields
    this.schema.forEach(schema => schema.visible = true);
    // Hide robot type
    this.schema[3].visible = false;
    // Hide request type
    this.schema[4].visible = false;
    
    this.schema.map(schema =>{
      if ( schema.label === 'Centros') {

        schema.childs?.forEach(child => {
          child.options = child.options?.sort((a, b) => a.label.localeCompare(b.label))
          // child.options?.map((a) =>{
              // a.label = a.label.toUpperCase();
          // })
        })
      }
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(): void {
    if ( this.canGeneratePlan ) {
      this.schema[3].visible = true;
      this.schema[4].visible = true;
    } else {
      this.schema[3].visible = false;
      this.schema[4].visible = false;
    }
  }
}
