<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de facturación'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
    [disabled]="form.controls['reportType'].value === '0' && !(form.controls['checkboxPapelRecipe'].value || form.controls['checkboxElectronicRecipe'].value)"
  >
  <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="type  col-12">
          <!-- <app-form-title type="h5" title="Tipo de reporte"></app-form-title> -->
          <p class="subtitle">Intervalo</p>
          <mat-radio-group formControlName="reportType" class="col-12">
            <mat-radio-button
              value="0"
              
            >
              MUTUAS
            </mat-radio-button>

            <mat-radio-button
              value="1"
            >
              PAGO
            </mat-radio-button>
            <mat-radio-button
              value="2"
            >
              CAP externo
            </mat-radio-button>
          </mat-radio-group>

          <section *ngIf="form.controls['reportType'].value === '0'"  class="col-12">
            <mat-checkbox formControlName="checkboxPapelRecipe"  class="col-12">
                Receta en papel
              </mat-checkbox>

              <mat-checkbox formControlName="checkboxElectronicRecipe"  class="col-12">
                Receta electronica
              </mat-checkbox>
          </section>
        </section>

       

        <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section>


        <section class="col-12">
          <!-- <app-form-title type="h5" title="Intervalo"></app-form-title> -->
          <p class="subtitle">Intervalo</p>
            <mat-form-field
            appDateFormat
            class="col-4"
            >
              <!-- <div class="label">Intervalo</div> -->
              <input
                matInput
                [matDatepicker]="specificDay"
                formControlName="specificDay"
                [max]="dateMax"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="specificDay"></mat-datepicker-toggle>
              <mat-datepicker 
                #specificDay 
                startView="year"
                (monthSelected)="chosenMonthHandler($event, specificDay)"
              >
              </mat-datepicker>
            </mat-form-field>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
