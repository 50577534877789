<section [hidden]="loading" style="height: 100%;">
  <section #cards class="cards-wrapper" >
    <div class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
      <div class="list-header">
        <div class="element space"></div>
        <div class="element sortable name" (click)="sortBy('name')">
          Nombre
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>

        
        <div class="element sortable abbreviation" (click)="sortBy('nameAbbreviation')">
          Abreviatura
          <mat-icon *ngIf="currentSort === 'nameAbbreviation' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'nameAbbreviation' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>


        <div class="element sortable type" (click)="sortBy('type')">
          Tipo
          <mat-icon *ngIf="currentSort === 'type' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'type' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element actions">Acciones</div>
      </div>

      <app-card
        class="list-mode"
        *ngFor="let institution of currentData | slice : lowValue : highValue"
        [title]="institution.name"
        [id]="institution.id"
        [img]=""
        [route]="route"
        [actions]="actions"
        [defaultImg]="'assets/pics/medical_residencia.svg'"
        colorHeader="default"
      >
   
        <div class="content" slot="content">
          <span>{{ institution.nameAbbreviation }}</span>
          <span>{{ institution.type }}</span>
        </div>
      </app-card>
    </div>
    <ng-container *ngIf="!loading">
      <p *ngIf="currentFilter || currentSearch">
        {{ msgNotFound }}:
        <span class="search-value">{{ currentFilter || currentSearch }}</span>
      </p>
    </ng-container>

    <div class="pagination">
      <mat-paginator
        aria-label="Seleccionar pagina"
        [length]="currentData.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true"
        (page)="getPageEvent($event)"
      >
      </mat-paginator>
    </div>
  </section>
</section>
