import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { CallsService } from '@services/api/calls.service';
import { CRUDService } from '@services/api/crud/crud.service';
import { AuthService } from '@services/auth.service';
import { InstitutionService } from '@services/institution.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Chart, registerables } from 'chart.js';
import { BehaviorSubject, Subscription, filter, skipWhile, take } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

Chart.register(...registerables);

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {
    public title: string;

    occupationToday = 0;
    distribucion: BehaviorSubject<{
        Residencia: number;
        Hospital: number;
        Vacaciones: number;
        OtroCentro: number;
        Otros: number;
    } | undefined> = new BehaviorSubject<{
        Residencia: number;
        Hospital: number;
        Vacaciones: number;
        OtroCentro: number;
        Otros: number;
    } | undefined>(undefined);
    evolucionOcupacion: BehaviorSubject<{ [key: number]: string; } | undefined> = new BehaviorSubject<{ [key: number]: string; } | undefined>(undefined);
    evolucionIef: BehaviorSubject<{ [key: number]: number | null; } | undefined> = new BehaviorSubject<{ [key: number]: number | null; } | undefined>(undefined);
    debt = "0";
    polimed = "0";
    hiperpolimed = "0";
    ief = "0";

    occupationCallEnded = false;
    medCallEnded = false;
    debtCallEnded = false;

    instSelector = new FormControl<any>(undefined);
    institutions: SelectOption[] = [
        {
            label: "Todos",
            value: "1",
        }
    ];
    isPharma = this.roleManager.isPharma(); // Para iniciar el valor nos guiamos por este (aunque no sea fiable)

    currentInstitutionName: string = '';

    constructor(
        private api: CallsService,
        private roleManager: RoleManagerService,
        private instService: InstitutionService,
        private auth: AuthService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService        
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.auth.decodedTokenSub.subscribe(token =>  {
                if (token != undefined) {
                    // Aquí le asignamos el valor fiable que es el devuelto por la token del servidor
                    this.isPharma = token.corotaIsFarmacia;
                    if (token.corotaIsFarmacia) {
                        this.instSelector.valueChanges.subscribe(val => {
                            if (val != undefined) {
                                this.instService.selectInstitution(val);
                                this.loadStatistics(val.id);
                            }
                        });

                        !this.instService.fetchingInstitutions ? this.instService.getInstitutions() : null;
                        this.instService.institutionList
                        .pipe(
                            filter(list => list.length > 0),
                            take(1)
                        )
                        .subscribe(insts => {
                            this.institutions = insts;
                            if (this.instSelector.value == undefined) {
                                const inst = this.institutions.find(i => i.value.shortName == this.instService.getCurrentInstitutionShortName());
                                this.instSelector.setValue(inst?.value, { emitEvent: false});
                                this.loadStatistics(this.instService.getCurrentInstitution());
                            }
                        });
                    }
                    else {
                        this.loadStatistics(this.instService.getCurrentInstitution());
                    }
                }
            })
        );

        if (this.roleManager.isPharma()) {
            this.title = 'Quantum Pharma';
        }
        if (this.roleManager.isQuantum()) {
            this.title = 'Quantum Global';
        }

        this.titleService.setTitle(this.title)
        this.gaService.pageView(`${window.location.href}`, this.title);

        this.instService.institutionList
        .pipe(
            skipWhile(list => !list.length),
            take(1)
        )
        .subscribe((list) => {
            this.currentInstitutionName = list.find(i => i.value.id == this.instService.getCurrentInstitution())?.label ?? '';
        });
    }

    loadStatistics(instId: number) {
        this.occupationCallEnded = this.medCallEnded = this.debtCallEnded = false;

        this.api.getStatisticsOccupation(instId).subscribe(data => {
            if ( data ) {
                this.occupationToday = data.Actual;
                this.distribucion.next(data.Distribucion);
                this.evolucionOcupacion.next(data.Evolucion);
                this.occupationCallEnded = true;
            }
        });

        this.api.getStatisticsMed(instId).subscribe(data => {
            if ( data ) {
                this.polimed = data.RatioVsPoblacion.toLocaleString(undefined, { maximumFractionDigits: 2 });
                this.hiperpolimed = data.RatioVsHiperpolimed.toLocaleString(undefined, { maximumFractionDigits: 2 });
                this.ief = data.IEF?.toLocaleString(undefined, { maximumFractionDigits: 2 });
                this.evolucionIef.next(data.IEFByMonth);
                this.medCallEnded = true;
            }
        });

        this.api.getStatisticsDebt(instId).subscribe(data => {
            if ( data ) {
                this.debt = data.Deuda.toLocaleString(undefined, { maximumFractionDigits: 2 });
                this.debtCallEnded = true;
            }
        });
    }

    subs: Subscription[] = [];
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.subs.push(this.distribucion.subscribe(data => {
                if (data != undefined) {
                    const chart = Chart.getChart(document.getElementById("distribution") as HTMLCanvasElement);
                    if (chart == undefined) {
                        new Chart(
                            document.getElementById("distribution") as HTMLCanvasElement,
                            {
                                type: 'pie',
                                data: {
                                    labels: [
                                        ...Object.keys(data)
                                    ],
                                    datasets: [
                                        {
                                            data: Object.values(data)
                                        }
                                    ]
                                },
                                options: {
                                    plugins: {
                                        legend: { display: true, position: 'right' },
                                        tooltip: {
                                            enabled: true
                                        }
                                    },
                                    responsive: true,
                                    aspectRatio: 3 / 2
                                },
                            }
                        );
                    }
                    else {
                        chart.data = {
                            labels: [
                                ...Object.keys(data)
                            ],
                            datasets: [
                                {
                                    data: Object.values(data)
                                }
                            ]
                        };
                        chart.update();
                    }
                }
            }));
            this.subs.push(this.evolucionOcupacion.subscribe(data => {
                if (data != undefined) {
                    const chart = Chart.getChart(document.getElementById("occupation") as HTMLCanvasElement);
                    if (chart == undefined) {
                        new Chart(
                            document.getElementById("occupation") as HTMLCanvasElement,
                            {
                                type: 'line',
                                data: {
                                    labels: [
                                        ...(Object.keys(data).map(m => {
                                            return Intl.DateTimeFormat(undefined, { month: 'long' }).format(new Date((Number(m) + 1).toString())).toUpperCase();
                                        }))
                                    ],
                                    datasets: [
                                        {
                                            data: Object.values(data).map(v => Number(v))
                                        }
                                    ]
                                },
                                options: {
                                    plugins: {
                                        legend: { display: false },
                                        tooltip: {
                                            enabled: true
                                        }
                                    },
                                    responsive: true,
                                    aspectRatio: 16 / 6
                                },
                            }
                        );
                    }
                    else {
                        chart.data = {
                            labels: [
                                ...(Object.keys(data).map(m => {
                                    return Intl.DateTimeFormat(undefined, { month: 'long' }).format(new Date((Number(m) + 1).toString())).toUpperCase();
                                }))
                            ],
                            datasets: [
                                {
                                    data: Object.values(data).map(v => Number(v))
                                }
                            ]
                        };
                        chart.update();
                    }
                }
            }));
            this.subs.push(this.evolucionIef.subscribe(data => {
                if (data != undefined) {
                    const chart = Chart.getChart(document.getElementById("ief") as HTMLCanvasElement);
                    if (chart == undefined) {
                        new Chart(
                            document.getElementById("ief") as HTMLCanvasElement,
                            {
                                type: 'line',
                                data: {
                                    labels: [
                                        ...(Object.keys(data).map(m => {
                                            return Intl.DateTimeFormat(undefined, { month: 'long' }).format(new Date((Number(m) + 1).toString())).toUpperCase();
                                        }))
                                    ],
                                    datasets: [
                                        {
                                            data: Object.values(data)
                                        }
                                    ]
                                },
                                options: {
                                    plugins: {
                                        legend: { display: false },
                                        tooltip: {
                                            enabled: true
                                        }
                                    },
                                    responsive: true,
                                    aspectRatio: 16 / 6
                                },
                            }
                        );
                    }
                    else {
                        chart.data = {
                            labels: [
                                ...(Object.keys(data).map(m => {
                                    return Intl.DateTimeFormat(undefined, { month: 'long' }).format(new Date((Number(m) + 1).toString())).toUpperCase();
                                }))
                            ],
                            datasets: [
                                {
                                    data: Object.values(data)
                                }
                            ]
                        };
                        chart.update();
                    }
                }
            }));
        }, 0);
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
        let chart = Chart.getChart(document.getElementById("distribution") as HTMLCanvasElement);
        chart?.destroy();
        chart = Chart.getChart(document.getElementById("occupation") as HTMLCanvasElement);
        chart?.destroy();
        chart = Chart.getChart(document.getElementById("ief") as HTMLCanvasElement);
        chart?.destroy();
    }
}
