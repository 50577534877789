<div class="layout-container no-scroll">
  <mat-drawer-container autosize class="drawer-container"
    [ngClass]="{ 'no-scroll': loading }"
  >
    <!-- Sidebar -->
    <mat-drawer #sidebar mode="side" opened="true" disableClose="true">
      <app-sidebar  class="sidebar" [config]="sidebarConfig" [(isExpanded)]="opened"></app-sidebar>
    </mat-drawer>

    <!-- Loading -->
    <div *ngIf="loading" class="loading-spinner">
      <mat-spinner></mat-spinner>
      <div class="loading-spinner__message">{{ loadingMessage ? loadingMessage : 'Cargando...' }}</div>
    </div>

    <!-- Router outlet -->
    <router-outlet></router-outlet>

    <!-- Chat assistant -->
     <app-chat-assistant></app-chat-assistant>
  </mat-drawer-container>
</div>
