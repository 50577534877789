import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PAGE_SIZE_OPTIONS } from '@constants/options';
import { CardAction } from '@interfaces/card-actions';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { Institution } from '@interfaces/institution/institution.interface';
import { FilterService } from '@services/filter/filter.service';
import { InstitutionService } from '@services/institution.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { LoadingService } from '@services/loading.service';
import {
  actionsInstitution,
  routeInstitutions,
} from '@services/mocks/institution';
import { alertInfo } from '@services/mocks/patient';
import { PaginationService } from '@services/pagination/pagination.service';
import { debounceTime, Subscription } from 'rxjs';

import { ListComponent } from '@json/src/app/components/base/list/list.component';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent extends ListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('cards', { static: true }) cards: ElementRef;

  public route: string = routeInstitutions;
  public actions: CardAction[] = actionsInstitution;

  public institutions: Institution[] = [];
  public currentData: Institution[] = [];

  public pageIndex: number = 0;
  public pageSize: number = 15;
  public lowValue: number = 0;
  public highValue: number = this.pageSize;
  public pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  private subs: Subscription[] = [];
  public currentFilter: string = '';
  public currentSearch: string = '';
  public loading: boolean = true;

  public isToggled: boolean = false;
  public msgNotFound: string =
    'No hay institutiones que coincidan con la búsqueda';
  public inputLabel: string = 'Instituciones';

  public inputSearchLabel: string = 'Búsqueda';
  public inputFilterLabel: string = 'Filtrar';
  public alertString = alertInfo;

  public dueTime: number = 1000;
  public institutionsFilters: any;
  public selectedFilters: FilterOption[] = [];
  public institutionSelecFilter: FilterSelect[] = [];
  public options: FilterOption[] = [];

  public formFilterFront: FormGroup = new FormGroup({
    search: new FormControl(''),
    select: new FormControl(this.selectedFilters),
  });

  public formFilterBack: FormGroup = new FormGroup({
    filter: new FormControl(''),
  });

  constructor(
    protected router: Router,
    protected institutionsService: InstitutionsService,
    protected filterService: FilterService,
    protected institutionService: InstitutionService,
    protected loadingService: LoadingService,
    protected paginationService: PaginationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.calculatePageSizeOptions();
  }

  calculatePageSizeOptions() {
    if ( this.cards ) {
        const layoutWidth = this.cards.nativeElement.offsetWidth;
        const layoutHeight = this.cards.nativeElement.offsetHeight - (this.onListMode() ? 52 : 0);
        const gridGap = this.onListMode() ? 0 : 8  // Grid gap from style in px
        const minElementWidth = this.onListMode() ? layoutWidth : 240 // Min element width is 15rem and document font-size is 16px (15*16)
        
        const columns = Math.floor((layoutWidth - gridGap) / (minElementWidth + gridGap));
        const rows = Math.floor((layoutHeight - gridGap) / ((this.onListMode() ? 51 : 94) + gridGap));

        this.pageSize = columns * rows;
        this.highValue = this.pageSize;

        this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3, Math.ceil(this.pageSize * 4 / 100) * 100];
    }
  }

  onListMode() {
    return this.router.url.includes("/institutions/list");
  }

  getInstitutionsTypes(): void {
    this.subs.push(
      this.institutionsService.institutionTypeList.subscribe(
        (institutionsType) => {
          this.institutionsFilters = institutionsType.map((iT) =>
            this.institutionsService.transformInstitutionTypeAPItoApp(iT)
          );
          this.institutionSelecFilter =
            this.institutionsService.transformInstitutionTypeToSelectGroup(
              this.institutionsFilters
            );
          this.selectedFilters =
            this.institutionsService.tranformInstitutionTYpeToOption(
              this.institutionsFilters
            );

          this.formFilterFront.get('select')?.setValue(this.selectedFilters);
          this.applyFiltersFront();
        }
      )
    );

    this.institutionsService.getInstitutionsType();
  }

  getInstitutions(): void {
    this.subs.push(
      this.institutionsService.institutionList.subscribe((institutions) => {
        if (institutions.length > 0) {
          this.institutions = institutions.map((i) =>
            this.institutionsService.transformInstitutionAPItoApp(i)
          );
          this.currentData = [...this.institutions];
          this.loading = false;
          this.loadingService.stop();
        }
      })
    );

    this.institutionsService.getInstitutions(
      false,
      this.institutionService.getCurrentInstitution()
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  getPageEvent(event: PageEvent): PageEvent {
    const previousPageIndex = this.pageIndex;
    const pageSizeChanged = event.pageSize !== this.pageSize;

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    const { lowValue, highValue, pageIndex, pageSize } =
      this.paginationService.getPageValues(
        previousPageIndex,
        this.lowValue,
        this.highValue,
        this.pageIndex,
        this.pageSize,
        this.pageIndex,
        pageSizeChanged
      );

    this.pageIndex = pageIndex;
    this.lowValue = lowValue;
    this.highValue = highValue;
    this.pageSize = pageSize;

    return new PageEvent();
  }

  toggle(): void {
    this.isToggled = !this.isToggled;
    this.applyFiltersFront();
  }

  applyFiltersFront(): void {
    const filterValue = this.formFilterFront.value['search'];
    const selectOptions = this.formFilterFront.value['select'];
    this.currentSearch = filterValue;

    this.currentData = this.institutions.filter((item) => {
      const values = Object.values(item);
      const selectedFilters =
        this.institutionsService.getSelectedFilters(selectOptions);

      return values.some((val) => {
        const type = String(item.type).toLowerCase();
        const conditions = this.institutionsService.getInstitutionsConditions(
          selectedFilters,
          type
        );
        const {
          pharmacyCondition,
          hospitalCondition,
          residencyCondition,
          otherCondition,
        } = conditions;

        return (
          this.matchFilter(val, filterValue) &&
          pharmacyCondition &&
          hospitalCondition &&
          residencyCondition &&
          otherCondition
        );
      });
    });
  }

  matchFilter(val: string, filterValue: string): boolean {
    const lowerCaseVal = val?.toString().toLowerCase();
    return lowerCaseVal?.includes(filterValue);
  }

  addFormFilterBackListener(): void {
    this.formFilterBack.valueChanges
      .pipe(debounceTime(this.dueTime))
      .subscribe((res) => {
        this.currentFilter = res.filter;

        this.subs.push(
          this.institutionsService.institutionList.subscribe((institutions) => {
            this.institutions = institutions.map((i) =>
              this.institutionsService.transformInstitutionAPItoApp(i)
            );

            this.currentData = [...this.institutions];
          })
        );

        this.institutionsService.getInstitutionsByFilter(this.currentFilter);
      });
  }

  clearFiltersBack(): void {
    this.formFilterBack.get('filter')?.setValue('');
  }
}
