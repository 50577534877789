<ng-container *ngFor="let section of schema; let i = index;">
  <ng-container [ngSwitch]="section.type" *ngIf="visibility[i].visible">
    <div class="section"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <ng-container *ngSwitchCase="'info'">
        <div class="message info" *ngIf="!section.hideLabel" [ngStyle]="section.customStyle">{{ section.label }}</div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <!-- Title -->
        <div class="title">{{section.label}}</div>

        <!-- Content -->
        <div class="content">
          <ng-container [ngSwitch]="section.customType">
            <!-- Pattern form -->
            <ng-container *ngSwitchCase="'pattern'">
              <app-pattern-form
                class="field col-12"
                [fieldControl]="section.fieldControl | asFormControl"
                [formConfig]="section.customFieldData"
                [readOnly]="section.readOnly">
              </app-pattern-form>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
