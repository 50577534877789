<ng-container *ngFor="let section of schema; let i = index;">
  <ng-container *ngIf="section.visible !== false">
    <div class="section" 
      *ngIf="visibility[i]?.visible" 
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">
      <!-- Title -->
      <div class="title">{{section.label}}</div>
      <!-- Content -->
      <div class="content"  *ngIf="section.childs;">
        <ng-container *ngFor="let child of section.childs">
          <ng-container [ngSwitch]="child.type">

            <!-- Group checkbox field -->
            <ng-container *ngSwitchCase="'group-label' || 'text'">
              <div class="subtitle">{{child.label}}</div>

              <app-form-field 
                  *ngFor="let check of child.childs"
                  class="field"
                  [class]="check.size || ''"
                  [ngClass]="check.customClass"
                  [ngStyle]="check.customStyle"
                  [type]="check.type" 
                  [label]="check.label" 
                  [hideLabel]="check.hideLabel"
                  [hintMsg]="check.hintMsg" 
                  [placeholder]="check.placeholder"
                  [fieldControl]="check.fieldControl | asFormControl" 
                  [customFieldData]="check.customFieldData"
                  [options]="check.options" 
                  [readOnly]="check.readOnly" 
                  [min]="check.min" 
                  [max]="check.max"
                  [inputPattern]="check.inputPattern" 
                  [capitalize]="check.capitalize"
                  [uppercase]="check.uppercase"
                  [errorMsg]="check.errorMsg ?? ''" 
                  [group]="check.group" 
                  [multipleSelect]="check.multipleSelect" 
                  [customStyle]="check.customStyle">
                </app-form-field>
            </ng-container>
            <!-- Default form field -->
            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="child.visible">
                <app-form-field 
                  class="field"
                  [class]="child.size || ''"
                  [ngClass]="child.customClass"
                  [ngStyle]="child.customStyle"
                  [type]="child.type" 
                  [label]="child.label" 
                  [hideLabel]="child.hideLabel"
                  [hintMsg]="child.hintMsg" 
                  [placeholder]="child.placeholder"
                  [fieldControl]="child.fieldControl | asFormControl" 
                  [customFieldData]="child.customFieldData"
                  [options]="child.options" 
                  [readOnly]="child.readOnly" 
                  [min]="child.min" 
                  [max]="child.max"
                  [inputPattern]="child.inputPattern" 
                  [capitalize]="child.capitalize"
                  [uppercase]="child.uppercase"
                  [errorMsg]="child.errorMsg ?? ''" 
                  [group]="child.group" 
                  [multipleSelect]="child.multipleSelect" 
                  [customStyle]="child.customStyle">
                </app-form-field>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="!section.childs">
          <app-form-field [type]="section.type" [label]="section.label" [hideLabel]="section.hideLabel"
              [hintMsg]="section.hintMsg" [placeholder]="section.placeholder"
              [fieldControl]="section.fieldControl | asFormControl" [customFieldData]="section.customFieldData"
              [options]="section.options" [readOnly]="section.readOnly" [min]="section.min" [max]="section.max"
              [inputPattern]="section.inputPattern" [capitalize]="section.capitalize"
              [uppercase]="section.uppercase"
              [errorMsg]="section.errorMsg ?? ''" 
              [group]="section.group" 
              [multipleSelect]="section.multipleSelect" 
              [customStyle]="section.customStyle"></app-form-field>
      </ng-container>

      <ng-template #elseTemplate> No element found </ng-template>
    
    </div>
  </ng-container>
</ng-container>