<ng-container *ngFor="let section of schema; let i = index;">
  <div class="section" 
    *ngIf="visibility[i]?.visible" 
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">
    <!-- Title -->
    <div class="title" *ngIf="!section.hideLabel">{{section.label}}</div>

    <!-- Content -->
    <div class="content">
      <ng-container *ngFor="let child of section.childs">
        <ng-container [ngSwitch]="child.type">
      
          <ng-container *ngSwitchCase="'info'">
            <div class="message info" *ngIf="!child.hideLabel" [ngStyle]="child.customStyle">{{ child.label }}</div>
          </ng-container>

          <div *ngSwitchCase="'sub-group'" class="sub-group">
            <div class="title subtitle" *ngIf="!child.hideLabel">{{child.label}}</div>
          
            <div class="content subcontent">
              <ng-container *ngFor="let subchild of child.childs">
                <ng-container>
                  <app-form-field 
                    class="field"
                    [class]="subchild.size || ''"
                    [ngClass]="subchild.customClass"
                    [ngStyle]="subchild.customStyle"
                    [type]="subchild.type" 
                    [label]="subchild.label" 
                    [hideLabel]="subchild.hideLabel"
                    [hintMsg]="subchild.hintMsg" 
                    [placeholder]="subchild.placeholder"
                    [fieldControl]="subchild.fieldControl | asFormControl" 
                    [customFieldData]="subchild.customFieldData"
                    [options]="subchild.options" 
                    [readOnly]="subchild.readOnly"   
                    [min]="subchild.min" 
                    [max]="subchild.max"
                    [inputPattern]="subchild.inputPattern" 
                    [exactPattern]="subchild.exactPattern" 
                    [numberFieldFastButtons]="subchild.numberFieldFastButtons" 
                    [capitalize]="subchild.capitalize"
                    [uppercase]="subchild.uppercase"
                    [errorMsg]="subchild.errorMsg ?? ''" 
                    [group]="subchild.group" 
                    [customStyle]="subchild.customStyle">
                  </app-form-field>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
