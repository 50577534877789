import { Component } from '@angular/core';

import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss', '../../../../../../../src/app/components/base/list/list.component.scss']
})
export class ListsComponent extends BaseComponent {

}
