import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ReportsService } from '@services/reports/reports.service';
import { DateService } from '@services/date.service';

import { Utils } from '@json/src/app/Utils';

import { reportDataNarcoticsConsolidated, reportDataNarcoticsDetailed } from '@constants/reports/report-narcotics';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ModalService } from '@services/modal.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-report-narcotics-medicines',
  templateUrl: './report-narcotics-medicines.component.html',
  styleUrls: ['./report-narcotics-medicines.component.scss']
})
export class ReportNarcoticsMedicinesComponent {

  public form: FormGroup;
  public reportDataNarcotics: any;

  constructor( 
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private reportService: ReportsService,
    private modal: ModalService,
  ) {
    const date = new Date();
    this.form = this.formBuilder.group({
      reportType: ['0'],
      checkboxShowAsProcessed: [false],
      startDate: [
        moment().startOf('month').toDate(),
        Validators.required,
      ],
      endDate: [new Date(), Validators.required],
      checkboxShowProcessed: [false],
    });

    this.form.controls['checkboxShowAsProcessed'].valueChanges.subscribe((value) => {
      if (value) {
        this.modal.openModalErrorInfo('Al seleccionar esta opción, se marcarán como procesados a todos los medicamentos hasta la fecha de hoy al hacer click sobre cualquiera de los botones inferiores.', 'Precaución')
      }
    });
  }

  getFormData(): {
    reportType: string;
    startDate: string;
    endDate: string;
    showProcessed: boolean;
    showAsProcessed: boolean;
} {
    const startDate = this.dateService.formatDateToString(
        this.form.get('startDate')?.value
    );
    const endDate = this.dateService.formatDateToString(
        this.form.get('endDate')?.value
    );

    const reportType = this.form.get('reportType')?.value;
    const showProcessed = this.form.get('checkboxShowProcessed')?.value;
    const showAsProcessed = this.form.get('checkboxShowAsProcessed')?.value;

    return { reportType, startDate, endDate, showProcessed, showAsProcessed };
  }

  async handlePreviewClick(): Promise<void> {
    let sholdGenerate = true;
    const { reportType, startDate, endDate, showProcessed, showAsProcessed } = this.getFormData();

    if ( showAsProcessed )
      sholdGenerate = await this.checkShowAsProcessedAlert();

    if ( sholdGenerate ) {
      this.reportDataNarcotics = reportType === '0' ? reportDataNarcoticsConsolidated : reportDataNarcoticsDetailed;
      this.setInfoRP(reportType, startDate, endDate, showProcessed, showAsProcessed, true);
      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataNarcotics
      );
    }
  }
  async handlePrintClick(): Promise<void> {
    let sholdGenerate = true;
    const { reportType, startDate, endDate, showProcessed, showAsProcessed } = this.getFormData();

    if ( showAsProcessed )
      sholdGenerate = await this.checkShowAsProcessedAlert();

    if ( sholdGenerate ) {
      this.reportDataNarcotics = reportType === '0' ? reportDataNarcoticsConsolidated : reportDataNarcoticsDetailed;
      this.setInfoRP(reportType, startDate, endDate, showProcessed, showAsProcessed);
      this.reportService.handlePrintClick(this.reportDataNarcotics);
    }
  }
  async handlePdfClick(): Promise<void> {
    let sholdGenerate = true;
    const { reportType, startDate, endDate, showProcessed, showAsProcessed } = this.getFormData();

    if ( showAsProcessed )
      sholdGenerate = await this.checkShowAsProcessedAlert();

    if ( sholdGenerate ) {
      this.reportDataNarcotics = reportType === '0' ? reportDataNarcoticsConsolidated : reportDataNarcoticsDetailed;
      this.setInfoRP(reportType, startDate, endDate, showProcessed, showAsProcessed);
      this.reportService.handlePdfClick(this.reportDataNarcotics);
    }
  }
  async handleExcelClick(): Promise<void> {
    let sholdGenerate = true;
    const { reportType, startDate, endDate, showProcessed, showAsProcessed } = this.getFormData();

    if ( showAsProcessed )
      sholdGenerate = await this.checkShowAsProcessedAlert();

    if ( sholdGenerate ) {
      this.reportDataNarcotics = reportType === '0' ? reportDataNarcoticsConsolidated : reportDataNarcoticsDetailed;
      this.setInfoRP(reportType, startDate, endDate, showProcessed, showAsProcessed);
      this.reportService.handleExcelClick(this.reportDataNarcotics);
    }
  }

  checkShowAsProcessedAlert(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modal.openModalCallback(() => {
        resolve(true);
      }, `
      Se marcarán como procesados a todos los medicamentos hasta la fecha de hoy.

      ¿Está seguro de que desea continuar?
      `, "No", true, () => reject());
    })
  }

  setInfoRP(
    reportType: string, 
    startDate: string, 
    endDate: string, 
    showProcessed: boolean, 
    showAsProcessed: boolean, 
    isPreview: boolean = false): void {
    
    const rp = JSON.stringify({
        LocaleId: '3082',
        DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split(".")[0],
        DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate())?.toISOString().split(".")[0],
        repType: reportType,
        FarmaticLinkId: 0,
        Reviewed: showProcessed ? true : false,
        SetAsReviewed: showAsProcessed ? true : false,
    });
    
    this.reportDataNarcotics.data.rp = rp;
    if (isPreview) this.reportDataNarcotics.data.dn = Utils.buildUniqueFileName + "_{0}.html";
    this.reportDataNarcotics.dataDownloadPDF.rp = rp;
    this.reportDataNarcotics.dataDownloadExcel.rp = rp;
    this.reportDataNarcotics.dataRenderReport.rp = rp;

    let type: string = '';
    switch (Number(reportType)) {
      case 0: 
        type = 'cons';
      break;
      case 1:
        type = 'detail';
      break;
    }

    const now = moment();
    this.reportDataNarcotics.dataDownloadPDF.dn = this.reportDataNarcotics.dataRenderReport.dn =
        `Estupef_${type}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataNarcotics.dataDownloadExcel.dn =
        `Estupef_${type}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
  }
}
