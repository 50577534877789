import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';

@Component({
  selector: 'app-institution-edit-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  public options: SelectOption[] 
  public institutionsFilters: any;

  public institutionSelecFilter: any = [];
  public selectedFilters: any = [];


  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef,
    protected institutionsService: InstitutionsService,
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();
    
   
    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
    this.getInstitutionsTypes();
    

    
  }

  getInstitutionsTypes(): void {
    this.subs.push(
      this.institutionsService.institutionTypeList
      .subscribe(
        (institutionsType) => {
         

          this.institutionsFilters = institutionsType.map((iT) =>
            this.institutionsService.transformInstitutionTypeAPItoApp(iT)
          );
          this.institutionSelecFilter =
            this.institutionsService.transformInstitutionTypeToSelectGroup(
              this.institutionsFilters
          );
          this.selectedFilters =
            this.institutionsService.tranformInstitutionTYpeToOption(
              this.institutionsFilters
            );

            //@ts-ignore
            this.options =  this.selectedFilters.map(inst => {
              return {
                  label: inst.value,
                  value: inst.id
              }
            })

            this.schema.forEach(schema => schema.childs?.forEach(child => {
              if ( child.label === 'Institucion' ) {
                child.options =  this.options
              }
            }));
        }
      )
    );

    this.institutionsService.getInstitutionsType();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
