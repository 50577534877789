import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  procAuthCancelColumns,
  processAuthCancelDataRender,
} from '@constants/process/proc-auth-cancel';
import { CheckboxAction } from '@interfaces/card-actions';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionSelectAPP } from '@interfaces/institution/institution.interface';
import {
  ProcAuthCancelAPI,
  ProcessAuthCancelRP,
} from '@interfaces/procs/proc-auth-cancel';
import { TableColumn } from '@interfaces/table.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { AuthService } from '@services/auth.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { LoadingService } from '@services/loading.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Subscription } from 'rxjs';
import moment from "moment";
import { PageEvent } from '@angular/material/paginator';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-proc-authorization-cancelation',
  templateUrl: './proc-authorization-cancelation.component.html',
  styleUrls: ['./proc-authorization-cancelation.component.scss'],
})
export class ProcAuthorizationCancelationComponent
  implements OnInit, OnDestroy
{
  @ViewChild('table', { static: false }) table: TableComponent;

  public institutionOptions: InstitutionSelectAPP[] = [];
  public showSelectInstitution: boolean = false;
  public selectedInstitution: string;

  public data: ProcAuthCancelAPI[];
  public dataFiltered: any[];

  public dataColumns: TableColumn[] = procAuthCancelColumns;

  private subs: Subscription[] = [];
  public form: FormGroup;

  public processAuthCancelDataRender = processAuthCancelDataRender;

  public checkboxs: CheckboxAction[] = [];

  currentPage: PageEvent | undefined = undefined;

  public intervalOptions: SelectOption[] = [
    { value: 1, label: '1 mes' },
    { value: 3, label: '3 mes' },
    { value: 6, label: '6 mes' },
    { value: 12, label: '12 meses' }
  ]
  public selectedInterval: number = 1;

  constructor(
    private institutionService: InstitutionService,
    private roleManager: RoleManagerService,
    private calls: CallsService,
    private dateService: DateService,
    private institutionsService: InstitutionsService,
    private reportService: ReportsService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.form = this.formBuilder.group({
      checkboxProcessed: [false],
    });
  }

  ngOnInit(): void {
    if (this.roleManager.isPharma()) {
      this.getInfo();
    }
    this.listenerProcessed();
  }

  private getInfo() {
    this.institutionsService.getInstitutions(true, null);

    this.subs.push(
      this.institutionsService.institutionList.subscribe((list) => {
        if (list.length > 0) {
          this.institutionOptions = list.map((institution) => ({
            name: institution.nam,
            id: institution.id,
            shortName: ''
          }));

          this.institutionOptions = this.institutionOptions.sort((a, b) => a.name.localeCompare(b.name))
        //  this.institutionOptions.map((a) =>{
        //      a.name = a.name.toUpperCase();
        //  })

          if (this.institutionOptions.length > 0) {
            this.institutionOptions.unshift({
              id: '0',
              name: 'Todos los centros',
              shortName: ''
            });
          }

          this.selectedInstitution = this.institutionOptions[0].id;
          this.showSelectInstitution = true;
          this.getDailyChanges(this.selectedInstitution);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  onIntervalChange(change: MatSelectChange): void {
    this.getDailyChanges(this.selectedInstitution);
    this.cdRef.detectChanges();
    this.table.changePagination();
  }
  onInstitutionChange(change: MatSelectChange): void {
    this.selectedInstitution = change.value;

    this.institutionService.selectInstitutionById(Number(this.selectedInstitution));

    this.getDailyChanges(change.value);
  }

  listenerProcessed(): void {
    this.subs.push(
      this.form.controls['checkboxProcessed'].valueChanges.subscribe(
        (value) => {
          this.filterData(value);
        }
      )
    );
  }

  onPageChange($event: PageEvent) {
    this.currentPage = $event;
    this.calculateCheckboxes(this.dataFiltered.slice(this.currentPage.pageIndex * this.currentPage.pageSize, (this.currentPage.pageIndex + 1) * this.currentPage.pageSize));
  }

  filterData(value: any): void {
    this.dataFiltered = [];
    if (value) {
      this.dataFiltered = this.data.filter((item) => item);
    } else {
      this.dataFiltered = this.data.filter((item) => item.Flag != 3);
    }

    this.calculateCheckboxes(this.dataFiltered);
  }

  calculateCheckboxes(data: any) {
    // In the data exists 3 types of row:
    // If the flag == 0 => normal row
    // If the flag == 1 => not processed row, color yellow
    // If the flag == 3 => proccessed row, color blue
    this.checkboxs = data.map((row: { OpId: any; Flag: any, BlockReason: any }) => ({
      value: row.Flag,
      checked: row.Flag == 3,
      // disabled: row.Flag == 0 || row.BlockReason != null,
      disabled: row.Flag == 0,
      id: row.OpId,
      callback: (event: any) => this.handleCheckboxProcess(event),
    }));
  }

  getDailyChanges(instId: string | null): void {
    //TODO: NO tienen más datos, solo apartir de esa fecha,
    // Cuando lo subamos, entiendo que tendremos que poner
    // la fecha de hoy, no esta "harcoded"
    //FIXME: COmo debería de estar:
    // const dateFrom = new Date();
    // const dateUntil = new Date(dateFrom);

    const dateFrom: Date = moment(Utils.toStartfDate(new Date())).subtract(this.selectedInterval, "M").toDate()
    const dateUntil = Utils.toEndOfDate(new Date());

    instId = instId == '' || instId == '0' ? null : instId;

    const data = {
      fl: this.authService.getFarmaticId(),
      ins: instId,
      de: this.dateService.convertStringOfDate(dateFrom, undefined, true),
      a: this.dateService.convertStringOfDate(dateUntil, undefined, true),
    };

    this.calls.getDailyChange(data).subscribe((next) => {
      // Ocultamos los que tengan el ReportReasonCode == 3 (RE MOD)
      this.data = next.payload.filter((item: ProcAuthCancelAPI) => item.ReportReasonCode !== 3);
      this.filterData(this.form.controls['checkboxProcessed'].value);
      this.cdRef.detectChanges();
    });
  }

  handlePrint(): void {
    this.loadingService.start('Descargando documento...');
    const dateFrom: Date = Utils.toStartfDate(new Date());
    const dateUntil = Utils.toEndOfDate(dateFrom);

    dateUntil.setDate(dateUntil.getDate() + 1);

    const rp: ProcessAuthCancelRP = {
      LocaleId: '3082',
      InstitutionId: this.selectedInstitution,
      DateFrom: `${this.dateService.formatDateToString(dateFrom)}`,
      DateUntil: `${this.dateService.formatDateToString(dateUntil)}`,
      UserType: '3',
      OpIdList: this.data ? this.getOpIdList() : '',
    };

    const data = processAuthCancelDataRender;

    data.dataRenderReport.dn = Utils.buildUniqueFileName + "_{0}.pdf";

    data.dataRenderReport.rp = JSON.stringify(rp);

    this.reportService.handlePrintClick(data);
    this.loadingService.stop();
  }
  getOpIdList(): string {
    const res = this.dataFiltered?.map((process) => process.OpId);
    return res.join(',');
  }

  handleCheckboxProcess(event: any): void {
    const { id, _checked } = event.source;
    const flag = _checked ? 3 : 1;

    const rowChecked = this.data.find((row) => row.OpId === id);
    if (!rowChecked) return; // Handle case when the row is not found

    rowChecked.Flag = flag;

    const checkboxesWithFlagOneOrThree = this.data
      .filter((row) => row.Flag === 1 || row.Flag === 3)
      .map((item) => ({ OpId: item.OpId, Flag: item.Flag }));

    this.calls
      .saveDailyChangesMarks(JSON.stringify(checkboxesWithFlagOneOrThree))
      .subscribe(() => {
        this.getDailyChanges(this.selectedInstitution);
      });
  }
}
