import { SelectOption } from '@interfaces/input-select-option.interface';

export const EDUCATION_OPTIONS: SelectOption[] = [
    { label: '', value: '' },
    { label: 'Elemental', value: 0 },
    { label: 'Grado profesional', value: 1 },
    { label: 'Universitaria', value: 2 },
    { label: 'Sin escolarizar', value: 3 },
];

export const BLOCK_REASON_OPTIONS: SelectOption[] = [
    { label: 'Vacaciones', value: 1 },
    { label: 'Ingreso hospitalario', value: 2 },
    { label: 'Derivación a otro centro', value: 3 },
    { label: 'Otro', value: 4 },
];
export const OCUPATION_OPTIONS: SelectOption[] = [
    { label: '', value: '' },
    { label: 'Directores y gerentes', value: 0 },
    { label: 'Profesionales científicos e intelectuales', value: 1 },
    { label: 'Técnicos profesionales de nivel medio', value: 2 },
    { label: 'Personal de apoyo administrativo', value: 3 },
    {
        label: 'Trabajadores de los servicios y vendedores de comercios y mercados',
        value: 4,
    },
    {
        label:
            'Agricultores y trabajadores calificados agropecuarios, forestales y pesqueros',
        value: 5,
    },
    {
        label:
            'Oficiales, operarios y artesanos de artes mecánicas y de otros oficios',
        value: 6,
    },
    { label: 'Operadores de instalaciones y máquinas y ensambladores', value: 7 },
    { label: 'Ocupaciones elementales/labores del hogar', value: 8 },
    { label: 'Ocupaciones militares', value: 9 },
];
export const DEREGISTRATION_OPTIONS: SelectOption[] = [
    { label: '', value: '' },
    { label: 'Baja por defunción', value: 0 },
    { label: 'Baja voluntaria', value: 1 },
    { label: 'Cambio de residencia', value: 2 },
    { label: 'Uso incorrecto', value: 3 },
];
export const DEPARTMENT_OPTIONS: SelectOption[] = [
    // TODO: API
    { label: '', value: '' },
    { label: 'Bombolla 3', value: 'bombolla_3' },
    { label: 'Carpa', value: 'carpa' },
];
export const GENDER_OPTIONS: SelectOption[] = [
    { label: 'M', value: 'M' },
    { label: 'F', value: 'F' },
];

export const COMPLY_OPTIONS: SelectOption[] = [
    { label: 'Cumple', value: true, customStyle: { 'background':'#008000' ,  'color': '#fff'} },
    { label: 'No cumple', value: false, customStyle: { 'background': 'red' ,  'color': '#fff'} },
];

export const TYPE_REPORT: SelectOption[] = [
    { label: 'Por pacientes', value: true },
    { label: 'Por medicamentos', value: false },
];

export const TYPE_SEARCH_REPORT: SelectOption[] = [
    { label: 'Enlazados', value: true },
    { label: 'Modificados', value: false },
];

export const USAGE_OPTIONS: SelectOption[] = [
    { label: 'Uso Correcto', value: true, customStyle: { 'background':'#008000' ,  'color': '#fff'} },
    { label: 'Uso Incorrecto', value: false, customStyle: { 'background': 'red' ,  'color': '#fff'} },
];

export const PERSON_OPTIONS: SelectOption[] = [
    //   { label: 'Usuario', value: 'usuario' },
    //   { label: 'Cuidador', value: 'cuidador' },
    { label: 'Usuario', value: true },
    { label: 'Cuidador', value: false },
];

export const TYPE_UNITS_OPTIONS: SelectOption[] = [
    { label: 'Cantidad', value: 0 },
    { label: 'Medida', value: 1 },
];

export const TYPE_AUTOEVALUATION_OPTIONS: SelectOption[] = [
    { label: 'Estática', value: 0 },
    { label: 'Dinámica', value: 1 },
];

export const MODALITY_OPTIONS: SelectOption[] = [
    { label: 'Pública', value: 0 },
    { label: 'Privada', value: 1 },
    { label: 'Mixta', value: 2 },
    { label: 'Gestión subrogada', value: 3 },
];

export const TYPOLOGY_OPTIONS: SelectOption[] = [
    { label: 'Geriátrica', value: 0 },
    { label: 'Conductual/psiquiátrica', value: 1 },
];

export const RADIO_OPTIONS: SelectOption[] = [
    { label: 'Por descripción', value: 1 },
    { label: 'Por código', value: 0 },
];

export const FRECUENCY_OPTIONS: SelectOption[] = [
    { label: 'Ninguna', value: 0 },
    { label: 'Semanal', value: 1 },
    { label: 'Mensual', value: 11 },
];

export const WEEK_DAY_OPTOINS: SelectOption[] = [
    { label: 'Lunes', value: 1 },
    { label: 'Martes', value: 2 },
    { label: 'Miércoles', value: 3 },
    { label: 'Jueves', value: 4 },
    { label: 'Viernes', value: 5 },
    { label: 'Sábado', value: 6 },
    { label: 'Domingo', value: 7 },
];

export const TYPE_ROBOT_OPTIONS: SelectOption[] = [
    { label: 'TMCA', value: 0 },
    { label: 'FILIA', value: 1 },
];

export const INTERVAL_LOG_OPTIONS: SelectOption[] = [
    { label: 'Todos', value: 0 },
    { label: 'Intervalo de fechas', value: 1 },
];

export const INTEGRATION_LOG_OPTIONS: SelectOption[] = [
    { label: 'CGR', value: 0 },
];

export const GROUP_LOG_OPTIONS: SelectOption[] = [
    { label: 'Prescripciones', value: 0 },
];

export const LOG_OPTIONS: SelectOption[] = [
    { label: 'Medicinas sin mapa', value: 0 },
    { label: 'Pacientes no válidos', value: 1 },
    { label: 'Prescripciones no válidas', value: 2 },
    { label: 'Prescripciones huérfanas', value: 3 },
    { label: 'Mapeo de códigos nacionales', value: 4 },
];

export const PAGE_SIZE_OPTIONS: number[] = [15, 30, 60, 100];

export const TYPE_AUTOMATIC_REPORT: SelectOption[] = [
    { label: 'Por pacientes', value: 0 },
    { label: 'Por medicamentos', value: 1 },
];
