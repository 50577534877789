export interface EpigraphAPI {
  Id: number;
  Name: string;
}

export class Epigraph {
  public label: string;
  public value: number;

  constructor(input: EpigraphAPI) {
    this.value = input.Id;
    this.label = input.Name;
  }
}

export interface EpigraphForm {
  epigraph: {
    title: string;
    id: number;
    info: string;
  };
}

export interface EpigraphsForm {
  epigraphs: {
    epigraph: EpigraphForm;
  };
}

export interface EpigraphTextAPI {
  TEXTO: string;
}
