<div *ngFor="let item of formArray.controls; let i = index" [style.order]="itemPositions[i]"
    [style.display]="itemVisibility[i] ? 'unset' : 'none'">
    <mat-expansion-panel #expansionPanel>
        <!-- HEADER EXPANSION PANEL -->
        <mat-expansion-panel-header>
            <div class="title">
                {{getTitle(item)}}

                <button class="delete-button" *ngIf="canDelete && canBeDeleted(forms[i].schema) && !fieldControl.disabled" mat-icon-button
                    color="warn" (click)="delete(i)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>
        </mat-expansion-panel-header>
        <!-- CONTENT EXPANSION PANEL -->
        <div class="contenedor-expansionpanel">
            <div class="content">
                <app-box-lock [schema]="forms[i].schema" [disabled]="!canBeModified(forms[i].schema) || fieldControl.disabled">
                </app-box-lock>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<div class="add" [ngClass]="{'disabled': added}" *ngIf="!hideAddButton && !fieldControl.disabled && (readOnly ? !readOnly() : true)" (click)="addNewElement()">
    <button mat-icon-button class="add-button">
        <mat-icon>add</mat-icon>
    </button>
    <span>Añadir bloqueo</span>
</div>
