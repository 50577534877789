export const linkData = { 
  // Datos propios de los Links de Farmatic
  // TODO: Esto debe ir a una tabla de SQL!!!
  id: 0,
  // Constantes de datos para generar las ventas en Farmatic
  salesData: [
    { salesOrderRa: 'RA', debtSalesOff: 'R-FERRAN', salesForecastOrderProv: '0326', salesRaOrderProv: '0465' },
    { salesOrderRa: 'RA', debtSalesOff: 'PAGO', salesForecastOrderProv: '0092', salesRaOrderProv: '0092' },
    { salesOrderRa: 'P-RA', debtSalesOff: 'PAGO', salesForecastOrderProv: '0062', salesRaOrderProv: '0062', idProtocolo: 95 },
    { salesOrderRa: 'P-RA', debtSalesOff: 'PAGO', salesForecastOrderProv: '0062', salesRaOrderProv: '0062', idProtocolo: 95 },
    { salesOrderRa: 'P-RA', debtSalesOff: 'PAGO', salesForecastOrderProv: '0062', salesRaOrderProv: '0062', idProtocolo: 95 }
  ],
  // Nombre de las farmacias de cada Link. Corresponden a la farmacia en la tarjeta del usuario
  // TODO: Esto debe ir a una tabla de SQL!!!
  farmaciaName: [
    'Farmacia Vilassar de Dalt',
    'Farmacia Llacuna',
    'Farmacia Bolivia',
    'Farmacia Imagen',
    'Farmacia Guadalema'
  ],
  // Dirección de la farmacia
  // Los campos de dbo.Institution no coinciden con la dirección de Barcelona, así que se pone aquí
  farmaAddr: {
    // Esto depende del Site Id y del Farmatic Link Id!!!
    e00: [
      'FARMACIA BENJAMIN SANCHEZ SOLER\nAVDA STA MARIA, 2\n08339 VILASSAR DE DALT\nBARCELONA\nTEL 937530801',
      'FARMACIA LLACUNA\nCarolina García del Cerro\nC/Llacuna, 100\n08018 BARCELONA',
      'FARMACIA BOLIVIA\nBlanca Tapia Fernández',
      'FARMACIA IMAGEN\nJUAN PÉREZ',
      'FARMACIA GUADALEMA\nJUAN PÉREZ'
    ],
    e01: ['', '', '', ''],
    dev1: [
      'FARMACIA BENJAMIN SANCHEZ SOLER\nAVDA STA MARIA, 2\n08339 VILASSAR DE DALT\nBARCELONA\nTEL 937530801',
      'FARMACIA LLACUNA\nCarolina García del Cerro\nC/Llacuna, 100\n08018 BARCELONA'
    ],
    dev2: [
      'FARMACIA BENJAMIN SANCHEZ SOLER\nAVDA STA MARIA, 2\n08339 VILASSAR DE DALT\nBARCELONA\nTEL 937530801',
      'FARMACIA LLACUNA\nCarolina García del Cerro\nC/Llacuna, 100\n08018 BARCELONA'
    ],
    dev3: [
      'FARMACIA BENJAMIN SANCHEZ SOLER\nAVDA STA MARIA, 2\n08339 VILASSAR DE DALT\nBARCELONA\nTEL 937530801',
      'FARMACIA LLACUNA\nCarolina García del Cerro\nC/Llacuna, 100\n08018 BARCELONA'
    ]
  },
  // Colegiados del pie de PF y pantalla de Pacientes
  colegiado: {
    // Esto depende del Site Id y del Farmatic Link Id!!!
    e00: [
      'BENJAMIN SANCHEZ SOLER CON Nº COLEGIADO 10396 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'CAROLINA GARCÍA DEL CERRO CON Nº COLEGIADO 18026 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'BLANCA TAPIA FERNÁNDEZ CON Nº COLEGIADO: 17103 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'BEGOÑA FERNANZ MUÑOZ CON Nº COLEGIADO: 10724 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'PURIFICACIÓN MUÑOZ FERNÁNDEZ CON Nº COLEGIADO: 3637 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD'
    ],
    e01: [
      'MIGUEL SOLA CON Nº COLEGIADO ????? COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      '','',''
    ],
    dev1: [
      'BENJAMIN SANCHEZ SOLER CON Nº COLEGIADO 10396 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'CAROLINA GARCÍA DEL CERRO CON Nº COLEGIADO 18026 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD'
    ],
    dev2: [
      'BENJAMIN SANCHEZ SOLER CON Nº COLEGIADO 10396 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'CAROLINA GARCÍA DEL CERRO CON Nº COLEGIADO 18026 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD'
    ],
    dev3: [
      'BENJAMIN SANCHEZ SOLER CON Nº COLEGIADO 10396 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD',
      'CAROLINA GARCÍA DEL CERRO CON Nº COLEGIADO 18026 COMO FARMACEUTICO RESPONSABLE PARA LA PREPARACION DEL SISTEMA SPD'
    ]
  },
  urlLinks: {
    groga: [
      { url: 'https://www.targetagroga.cat/', descr: 'targetagroga.cat' },
      { url: 'https://www.targetagroga.cat/', descr: 'targetagroga.cat' },
      { url: 'https://www.notificaram.es/Pages/CCAA.aspx#no-back-button', descr: 'notificaram.es' },
      { url: 'https://www.notificaram.es/Pages/CCAA.aspx#no-back-button', descr: 'notificaram.es' },
      { url: 'https://www.notificaram.es/Pages/CCAA.aspx#no-back-button', descr: 'notificaram.es' }
    ]
  },
  inputPatterns: {
    colegiado: [
      { re: '[0-9]{2,2}\/[0-9]{5,5}', descr: '12/34567' },
      { re: '[0-9]{2,2}\/[0-9]{5,5}', descr: '12/34567' },
      { re: '[0-9]{2,2}\/[0-9]{7,7}', descr: '12/3456789' },
      { re: '[0-9]{2,2}\/[0-9]{7,7}', descr: '12/3456789' },
      { re: '[0-9]{2,2}\/[0-9]{7,7}', descr: '12/3456789' }
    ]
  }
};
