export const reportDataIEF = {
  data: {
    rpp: '/COROTA/IefEvolutive',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },

  dataDownloadPDF: {
    rpp: '/COROTA/IefEvolutive',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/IefEvolutive',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/IefEvolutive',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const rppIEF = [
  { type: 'numIEF', value: '/COROTA/IefNumeric' },
  { type: 'evolutiveIEF', value: '/COROTA/IefEvolutive' },
  { type: 'evolutiveIEFmg', value: '/COROTA/IefEvolutiveMg' },
];
