import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-expand-resume-group',
  templateUrl: './expand-resume-group.component.html',
  styleUrls: ['./expand-resume-group.component.scss']
})
export class ExpandResumeGroupComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() fieldControl: FormGroup;
  @Input() schema: FormItemSchemaInterface[];

  private subs: Subscription[] = [];
  public statusList: boolean[] = [];

  ngOnInit(): void {
    this.statusList = this.getStatus(this.fieldControl.value);
    this.subs.push(
      this.fieldControl.valueChanges.subscribe((val) => {
        this.statusList = this.getStatus(val);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getStatus(value: {[key: string]: any}): boolean[] {
    return Object.values(value).map(val => !!val);
  }
}
