import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import {
    ArrayFormSortConfigInterface,
    CalendarEventInterface,
    FormConfigArrayInterface,
} from '@interfaces/array.form.interface';
import {
    FormBuilderInterface,
    FormItemSchemaInterface,
} from '@interfaces/dynamic-form.interface';
import { FormService } from '@services/form.service';
import { SortService } from '@services/sort.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-list-subform',
    templateUrl: './list-subform.component.html',
    styleUrls: ['./list-subform.component.scss'],
})
export class ListSubformComponent implements OnInit, OnDestroy {
    @Input() formConfig: any | FormConfigArrayInterface;
    @Input() fieldControl: FormControl;
    @Input() readOnly?: () => boolean = () => false;

    private schema: FormBuilderInterface = {};
    public forms: { form: FormGroup; schema: FormItemSchemaInterface[] }[] = [];
    public formArray: FormArray = new FormArray<any>([]);
    public hasCalendar: boolean = false;
    public showCalendar: boolean = false;
    public hideAddButton: boolean = false;
    public lastCalendarDate: Date = new Date();
    public sortConfig: ArrayFormSortConfigInterface;
    public itemPositions: number[] = [];
    public itemVisibility: boolean[] = [];
    private subs: { [key: string]: Subscription } = {};

    canDelete = true;

    constructor(
        protected formsService: FormService,
        protected sortService: SortService
    ) { }
    ngOnInit(): void {
        this.schema = this.formConfig.schema;
        this.sortConfig = this.formConfig.sortConfig;
        this.hideAddButton = this.formConfig.hideAddButton;
        this.hasCalendar = !!this.formConfig.calendarConfig;
        this.canDelete = this.formConfig.canDelete ?? true;

        this.subs['fieldControl'] = this.fieldControl.valueChanges.subscribe(
            (value) => {
                this.updateFormArray(value);
            }
        );
        this.subscribeArray();
    }
    ngOnDestroy(): void {
        Object.values(this.subs).forEach((s) => s.unsubscribe());
    }
    subscribeArray(): void {
        this.subs['formArray'] = this.formArray.valueChanges.subscribe(() => {
            this.fieldControl.setValue(this.formArray.getRawValue());
            if (this.sortConfig) {
                this.itemPositions = [];
                const values = this.formArray.value.map(
                    (val: any) => val[this.sortConfig.sortField]
                );
                this.itemPositions = this.sortService.getOrders(
                    values,
                    this.sortConfig.sortFunctionName
                );
            }
        });
    }
    unsuscribeArray(): void {
        this.subs['formArray'].unsubscribe();
    }
    addOne(newSchema:boolean = false): void {
        const [form, schema] = this.formsService.buildForm(this.schema);
        if (newSchema) {
            schema.forEach(s => {
                if(s.customFieldData != undefined) {
                    s.customFieldData['new'] = true;
                }
            });
        }
        this.forms.push({ form, schema });
        this.formArray.push(form);
        this.filterItems(this.lastCalendarDate);
    }
    delete(index: number): void {
        this.formArray.removeAt(index);
        this.forms.splice(index, 1);
    }
    updateFormArray(value: any[] = []): void {
        if (!this.formArray.value.length && !!value.length) {
            this.unsuscribeArray();
            const array = this.formArray.value ?? [];
            let valueToPatch: any[] = [...array];
            valueToPatch = [
                ...valueToPatch,
                ...value.filter((vItem: { [key: string]: any }) =>
                    value.some((item) => JSON.stringify(item) === JSON.stringify(vItem))
                ),
            ];
            valueToPatch.forEach(() => {
                this.addOne();
            });
            setTimeout(() => {
                this.formArray.patchValue(valueToPatch);
                this.subscribeArray();
                this.filterItems(this.lastCalendarDate);
            }, 0); // FIX: El componente tabs-group parece que tarda algo en responder y no actualiza los datos bien sin esto
        }
    }
    updateDate(event: CalendarEventInterface): void {
        if (this.formConfig?.calendarConfig?.dateField) {
            switch (event.action) {
                case 'add':
                    const defaultValue =
                        this.schema[this.formConfig.calendarConfig.dateField].value;
                    this.schema[this.formConfig.calendarConfig.dateField].value =
                        event.date;
                    this.addOne();
                    this.schema[this.formConfig.calendarConfig.dateField].value =
                        defaultValue;
                    break;
                case 'remove':
                    this.delete(event.index);
                    break;
            }
        }
    }
    filterItems(date: Date | null): void {
        if (date && this.sortConfig?.showSelectedMonthOnly) {
            this.lastCalendarDate = date;
            this.itemVisibility = this.formArray.value.map((item: any) => {
                let iDate: Date = item[this.formConfig.calendarConfig.dateField];
                return (
                    !iDate ||
                    (iDate.getMonth() === date.getMonth() &&
                        iDate.getFullYear() === date.getFullYear())
                );
            });
        } else {
            this.itemVisibility = this.displayAll(this.formArray.value);
        }
    }
    displayAll(data: any[]): boolean[] {
        return data.map(() => true);
    }
}
