
<div class="content">
  <div class="container-report">
    <app-report-select
      class="report-custom-container"
      [title]="'Listado de pacientes'"
      (previewClicked)="handlePreviewClick()"
      (printClicked)="handlePrintClick()"
      (pdfClicked)="handlePdfClick()"
      (excelClicked)="handleExcelClick()"
      [disabled]="(!typeGlobal && (!meds?.expanded && !class.expanded)) || (typeGlobal && !class.expanded)"
    >
      <div slot="content" class="container-options">

        <form [formGroup]="form" class="container-form" *ngIf="typeGlobal" >

          <section class="filter-container" >
            <div class="subtitle">Centro</div>
            <app-form-field
              class="field"
              [type]="'select'"
              label="Selecciona un centro"
              [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
              [options]="selectOptionsInsitutions">
            </app-form-field>

          </section>

      </form>
      
        <section class="filter-container" >
          <p class="subtitle">Filtrado por</p>
          <mat-accordion *ngIf="!typeGlobal">
            <mat-expansion-panel #meds  (opened)="class?.close()">
              <mat-expansion-panel-header>
                <div class="titleExpansion"> Categoría de medicamentos </div>
              </mat-expansion-panel-header>
              <form [formGroup]="categoryMedicationForm">
                <mat-radio-group 
                  formControlName="selectedCategory"
                  (change)="clearClassification()"
                >
                  <mat-radio-button
                    *ngFor="let medication of categoriesMedicaments"
                    [value]="medication.name" 
                  >
                    {{ medication.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
  
          <mat-accordion>
            <mat-expansion-panel #class  (opened)="meds?.close()">
              <mat-expansion-panel-header>
                <div class="titleExpansion"> Clasificación </div>
              </mat-expansion-panel-header>
              <form [formGroup]="classificationMedicationForm">
                <mat-radio-group
                 
                  formControlName="selectedClassification"
                  (change)="clearCategory()"
                >
                  <mat-radio-button
                  class="col-3"
                    *ngFor="let clasification of clasificationMedicaments"
                    [value]="clasification.name"
                  >
                    {{ clasification.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </form>
            </mat-expansion-panel>
          </mat-accordion>
        </section>
      
      </div>
    </app-report-select>
  </div>
</div>
