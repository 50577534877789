import { Injectable } from '@angular/core';
import { PaginationAttributes } from '@interfaces/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  constructor() {}

  getPageValues(
    previousPageIndex: number,
    lowValue: number,
    highValue: number,
    currentPageIndex: number,
    pageSize: number,
    pageIndex: number,
    pageSizeChanged: boolean
  ): PaginationAttributes {
    lowValue = pageIndex * pageSize
    highValue = (pageIndex * pageSize) + pageSize;

    return {
      previousPageIndex,
      lowValue,
      highValue,
      currentPageIndex,
      pageSize,
      pageIndex,
    };
  }
}
