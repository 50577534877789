<div class="prescription-container container-fluid">
  <div *ngIf="!showEditForm" class="row">
    <div class="col-12">
      <div class="prescription-container__header">
        <div class="first-group">
          <div class="title">Tabla semanal</div>
          <div class="container-arrows">
            <div (click)="previousWeek()"> <mat-icon>keyboard_arrow_left</mat-icon></div>
            <div (click)="nextWeek()"> <mat-icon>keyboard_arrow_right</mat-icon></div>
          </div>
          <app-form-field
            type="date"
            class="field date"
            label="Fecha"
            [dynamicError]="true"
            [hideLabel]="true"
            [class]="'col-2'"
            [fieldControl]="form.controls['date'] | asFormControl"
          ></app-form-field>
          <div class="week-range">{{ getMonday(form.value.date) | date: 'd/M/y'}} - {{ getSunday(form.value.date) | date: 'd/M/y'}}</div>
          <app-form-field
            type="checkbox"
            class="interval"
            label="Fuera de intervalo"
            [dynamicError]="true"
            [fieldControl]="form.controls['showAll'] | asFormControl"
          ></app-form-field>
        </div>

        <div class="second-group">
          <div
            matTooltip="Plan Nacional Resistencia Antibióticos"
            (click)="openPNRALink()"
            class="buttonHeader pnra-button"
            color="primary">
            <img class="pnra-logo" src="/assets/images/logo_plan_nacional_resistencia_antibioticos.png" alt="" />
          </div>
          <div
            matTooltip="Guía de Soporte a la Prescripción"
            (click)="openGSPLink()"
            class="buttonHeader"
            color="primary">
            <mat-icon svgIcon="steto"></mat-icon>
          </div>
          <div mat-button matTooltip="Imprimir" [matMenuTriggerFor]="menu"  class="buttonHeader" color="primary">
            <mat-icon>print</mat-icon>
            <mat-menu #menu="matMenu"  class="col-2" >
              <button mat-menu-item (click)="printPF()">P.F.</button>
              <button mat-menu-item (click)="printInstructions()">HOJA DE INSTRUCCIONES</button>
            </mat-menu>
          </div>
          <div
            [matTooltip]="patientLink"
            *ngIf="patientLink"
            (click)="openLink()"
            class="buttonHeader"
            color="primary">
            <mat-icon>public</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <table mat-table [dataSource]="currentRows">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">

          <th mat-header-cell *matHeaderCellDef
            [ngClass]="{'today': days[column.columnDef] ? days[column.columnDef].today : false }"
            [style.textAlign]="column.align"
          >
            <ng-container [ngSwitch]="column.columnDef">

              <span *ngSwitchCase="'LUN'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'MAR'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'MIE'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'JUE'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'VIE'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'SAB'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchCase="'DOM'">{{ days[column.columnDef].label }}</span>
              <span *ngSwitchDefault>
                {{column.header}}
              </span>

            </ng-container>

          </th>
          <td mat-cell *matCellDef="let row"
            [class]="column.columnDef"
            [ngClass]="{'today': days[column.columnDef] ? days[column.columnDef].today : false }"
            [style.textAlign]="column.align"
            [style.cursor]="!['actions', 'info'].includes(column.columnDef) ? 'pointer' : ''"
            (click)="!['actions', 'info'].includes(column.columnDef) && (!!forms['prescriptions'] && !!forms['prescriptions'].form ? !forms['prescriptions'].form.disabled : false) ? openPrescription(row) : undefined"
          >
            <ng-container [ngSwitch]="column.columnDef">

              <span *ngSwitchCase="'DrugName'" class="d-flex" style="max-width: 250px;">
                <span [matTooltip]="row.Obsolete ? row.CMS || '' : row.DrugId">
                  {{ row['DrugName'] }}
                </span>
                <mat-icon
                  *ngIf="row.NoStock"
                  [matTooltip]="'agotado'"
                >error</mat-icon>
              </span>
              <span *ngSwitchCase="'actions'" class="d-flex">
                <ng-container *ngIf="canShowPrescription(row) && (forms['prescriptions'].schema[0].readOnly ? !forms['prescriptions'].schema[0].readOnly() : true) && !forms['prescriptions']?.form?.disabled">
                  <ng-container *ngIf="row.flags.canEdit && !isExitus">
                    <button
                      mat-icon-button
                      color="primary"
                      class="m-1 edit"
                      [matTooltip]="'Modificar'"
                      (click)="modifyPrescription(row)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  </ng-container>

                  <ng-container *ngIf="row.flags.canDelete && !isExitus; else elseTemplate">
                    <button
                      mat-icon-button
                      class="m-1 delete"
                      matTooltip="Eliminar"
                      (click)="deletePrescription(row)"
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <ng-container *ngIf="row.flags.canStop && !isExitus">
                      <button
                        mat-icon-button
                        color="primary"
                        class="m-1 stop"
                        matTooltip="Detener"
                        (click)="stopPrescription(row)"
                      >
                        <mat-icon>stop</mat-icon>
                      </button>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </span>

              <span *ngSwitchCase="'info'" class="d-flex">
                <mat-icon color="primary"
                  [matTooltip]="'Vía: ' + row.AdminRoute + (row.Observaciones ? '. Observaciones: ' + row.Observaciones : '') + (row.Obsolete && !row.CMS ? '. Medicamento obsoleto' : '')"
                  [ngClass]="{'danger': row.Obsolete && !row.CMS}"
                  matTooltipPosition="left"
                  >info
                </mat-icon>
                <mat-icon
                  *ngIf="!row.PayMed || row.ForcedPayment == 1"
                  color="primary"
                  class="euro"
                  matTooltip="De pago"
                >
                  euro_symbol
                </mat-icon>
                <img
                  *ngIf="row.HospitalUse"
                  class="img-icon hospital" 
                  src="assets/images/gsp/diagnostico-hospitalario-32.png"
                  matTooltip="Hospitalario"
                />
                <img
                  *ngIf="hasNarcotics(row)"
                  class="img-icon" 
                  src="assets/images/gsp/estupefaciente-32.png"
                  matTooltip="Estupefaciente"
                />
                <img
                  *ngIf="hasPsychotropics(row)"
                  class="img-icon" 
                  src="assets/images/gsp/psicotropo-32.png"
                  matTooltip="Psicótropo"
                />
                <img
                  *ngIf="hasMARC(row)"
                  class="img-icon marc" 
                  src="assets/images/gsp/marc-64.png"
                  matTooltip="MARC"
                />
                <img
                  *ngIf="isDangerous(row) && isDangerous(row) === 1"
                  class="img-icon" 
                  src="assets/images/meidcamentos-peligrosos/grupo1-32.png"
                  matTooltip="Antineoplásico"
                />
                <img
                  *ngIf="isDangerous(row) && isDangerous(row) === 2"
                  class="img-icon" 
                  src="assets/images/meidcamentos-peligrosos/grupo2-32.png"
                  matTooltip="No antineoplásico"
                />
                <img
                  *ngIf="isDangerous(row) && isDangerous(row) === 3"
                  class="img-icon" 
                  src="assets/images/meidcamentos-peligrosos/grupo3-32.png"
                  matTooltip="Riesgo para el proceso reproductivo"
                />
                <span *ngIf="row.PE"
                  class="pe"
                  [matTooltip]="getPETooltip(row)"
                >PE</span>
                <span *ngIf="row.FGP"
                  class="fgp"
                  [matTooltip]="'Medicamento fuera de guía preferente'"
                >FGP</span>
              </span>

              <span *ngSwitchCase="'StartDate'">
                {{ row[column.columnDef] | date: 'd/M/y' }}
              </span>
              <span *ngSwitchCase="'EndDate'">
                {{ row[column.columnDef] | date: 'd/M/y' }}
              </span>
              <span *ngSwitchCase="'dosis'" class="tags-container">
                <ng-container *ngFor="let tag of row.tags">
                  <span
                    class="tag"
                    [matTooltip]="tag.tooltip"
                    [class]="tag.class"
                  >
                    {{ tag.label }}
                  </span>
                </ng-container>
              </span>
              <span *ngSwitchDefault>
                {{ row[column.columnDef] === 0 ? '' : round(row[column.columnDef]) }}
              </span>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"
          [ngClass]="{
            'disabled': !canShowPrescription(row, true),
            'no-blister': row.NoBlister === 1
          }"
        ></tr>
      </table>

      <!-- <div class="new-prescription mt-3">
        <button
          mat-icon-button
          color="primary"
          matTooltip="Nueva prescripción"
          (click)="newPrescription()"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div> -->

      <div class="add"  (click)="newPrescription()" *ngIf="(forms['prescriptions'].schema[0].readOnly ? !forms['prescriptions'].schema[0].readOnly() : true) && !forms['prescriptions']?.form?.disabled && !isExitus">
        <button
          mat-icon-button
          matTooltip="Nueva prescripción"
          class="add-button"
        >
          <mat-icon>add</mat-icon>

        </button>

        <span>Añadir Prescripción médica</span>
      </div>

      <div class="alergies">
        <div class="subTitle">Alergias</div>
        <div>{{ allergies }}</div>
      </div>
      <div class="observations">
        <div class="subTitle">Observaciones</div>
        <span style="white-space: pre-line">{{ clinicNotes.value.observations['main'] }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="showEditForm" class="row">
    <app-prescription-edit
      [patientData]="generalData.value"
      [patientId]="patientId"
      [patientInstitutionId]="patientInstitutionId"
      [prescriptionId]="prescriptionToEdit"
      [stopDate]="stopDate"
      [isModification]="isModification"
      [forms]="forms"
      (close)="closeEdit()"
    ></app-prescription-edit>
  </div>
</div>
