<div class="expand" *ngIf="expandAll && !compressAll"  (click)="openExpandedAll()" >expandir todo</div>
<div class="expand" *ngIf="!expandAll && compressAll" (click)="closedExpandedAll()" >Comprimir todo </div>

<mat-accordion class="example-headers-align"  multi>
  <div 
    *ngFor="let item of formArray.controls; let i = index"
    [style.order]="itemPositions[i]"
    [style.display]="itemVisibility[i] ? 'unset' : 'none'"
  >
    <mat-expansion-panel #expansionPanel>
      <!-- HEADER EXPANSION PANEL -->
      <mat-expansion-panel-header>
        <div class="title" >
          {{getTitle(item)}}
          
          <button class="delete-button"
            *ngIf="canDelete"
            mat-icon-button 
            color="warn" 
            (click)="delete(i)"
          >
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <!-- CONTENT EXPANSION PANEL -->
      <div class="contenedor-expansionpanel">
        <div class="content">
          <app-box-epigraphs 
            [schema]="forms[i].schema">
          </app-box-epigraphs >
        </div>
      </div>
    </mat-expansion-panel> 
  </div>

  <div class="add" *ngIf="!hideAddButton" (click)="addNewElement()">
    <button 
      mat-icon-button
      class="add-button"
    >
      <mat-icon>add</mat-icon>
    </button>
    <span>Añadir Epígrafe</span>
  </div>
</mat-accordion>