<app-report-select
  class="report-custom-container"
  [title]="'Reposición automatica'"
  [disabled]="expandedCardIdHistoric || expandedCardIdActive ? false : true"
  (previewClicked)="handlePreviewClick()"
  (printClicked)="handlePrintClick()"
  (pdfClicked)="handlePdfClick()"
  (excelClicked)="handleExcelClick()"
>
<div slot="content" class="container-options">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Activos">
        <div class="map-cn-wrapper">

          <div class="active-main-wrapper">
            <div class="subtitle">Reportes disponibles</div>
          </div>
          
          <div class="active-main-wrapper">
            <app-form-field
              class="col-4"
              type="date"
              label=""
              [fieldControl]="form.controls['reportDate'] | asFormControl"
            >
            </app-form-field>

            <app-form-field
              class="col-4"
              type="text"
              label="Búsqueda"
              [fieldControl]="form.controls['filterReports'] | asFormControl"
            >
            </app-form-field>

          </div>

         
            <div class="active-main-wrapper">
              <app-form-field
              class="col-2"
              type="checkbox"
              label="Mostrar todo"
              [fieldControl]="form.controls['showAll'] | asFormControl"
            >
            </app-form-field>
            <button
            type="button"
            mat-raised-button
            color="primary"
            aria-label="Mostrar contadores"
            aria-pressed="Contadores mostrados"
            (click)="handlePreviewCLickCount()"
          >
            Contadores
          </button>
              <button
              class="reload"
                type="button"
                mat-icon-button
                matSuffix
                title="Refrescar"
                (click)="getRaData()"
              >
                <mat-icon>refresh</mat-icon>
              </button>
          
          </div>

          <div class="subtitle">Tipo de reporte</div>
          <app-form-field
            class="col-6"
            type="radio"
            label=""
            [fieldControl]="form.controls['typeReport'] | asFormControl"
            [options]="typeReportOptions"
          >
          </app-form-field>

          <div class="expandable-card-wrapper">
            <app-expandable-card
              *ngFor="let active of reportsActive"
              [id]="active.Id"
              [title]="active.ShortName"
              [description]="active.Id"
              [actions]="actionsActive"
              [isExpanded]="active.Id === expandedCardIdActive"
              (toggle)="toggleCard(active.Id, 'active')"
            >
              <div slot="descOnlyWhenCollapsed">
                <p>
                  <span>Creado:</span>
                  <ng-container *ngIf="active.CreationDate">
                    {{ active.CreationDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Actualizado:</span>
                  <ng-container *ngIf="active.ReportDate">
                  {{ active.ReportDate | date : "dd/MM/yyyy" }}
                </ng-container>
                </p>
              </div>
              <div slot="content">
                <p>
                  <span>Creado:</span>
                  <ng-container *ngIf="active.CreationDate">
                    {{ active.CreationDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Mostrado:</span>
                  <ng-container *ngIf="active.ShowDate">
                    {{ active.ShowDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Enviado:</span>
                  <ng-container *ngIf="active.OrderDate">
                    {{ active.OrderDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Actualizado:</span>
                  <ng-container *ngIf="active.ReportDate">
                  {{ active.ReportDate | date : "dd/MM/yyyy" }}
                </ng-container>
                </p>
                <p>
                  <span>Revisado:</span>
                  {{ active.ReviewUser ? "" : "SIN REVISAR" }}
                  <ng-container *ngIf="active.ReviewDate">
                    {{ active.ReviewDate | date : "dd/MM/yyyy" }} ({{
                      active.ReviewUser
                    }})
                  </ng-container>
                </p>
                <p>
                  <span>Impreso:</span>
                  <ng-container *ngIf="active.PrintDate">
                    {{ active.PrintDate | date : "dd/MM/yyyy" }}
                    {{ active.PrintUser ? "(" + active.PrintUser + ")" : "" }}
                  </ng-container>
                </p>
              </div>
            </app-expandable-card>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Histórico">
        <div class="map-cn-wrapper">
        

        <div class="subtitle">Reportes disponibles</div>
          <div class="historic-main-wrapper">
            <app-form-field
              class="col-3"
              type="text"
              label="Búsqueda"
              [fieldControl]="form.controls['filterReports'] | asFormControl"
            >
            </app-form-field>
            <button
              type="button"
              mat-icon-button
              matSuffix
              title="Refrescar"
              (click)="getRaData()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>

          <div class="expandable-card-wrapper">
            <app-expandable-card
              *ngFor="let historic of reportsHistoric"
              [id]="historic.Id"
              [title]="historic.ShortName"
              [description]="historic.Id"
              [actions]="actionsHistoric"
              [isExpanded]="historic.Id === expandedCardIdHistoric"
              (toggle)="toggleCard(historic.Id, 'historic')"
            >
              <div slot="descOnlyWhenCollapsed">
                <p>
                  <ng-container *ngIf="historic.CreationDate">
                  <span>Creado:</span>
                  {{ historic.CreationDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <ng-container *ngIf="historic.ReportDate">
                  <span>Actualizado:</span>
                  {{ historic.ReportDate | date : "dd/MM/yyyy" }}
                </ng-container>
                </p>
              </div>

              <div slot="content">
                <p>
                  <ng-container *ngIf="historic.CreationDate">
                  <span>Creado:</span>
                  {{ historic.CreationDate | date : "dd/MM/yyyy" }}
                </ng-container>
                </p>
                <p>
                  <span>Mostrado:</span>
                  <ng-container *ngIf="historic.ShowDate">
                  {{ historic.ShowDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Enviado:</span>
                  <ng-container *ngIf="historic.OrderDate">
                  {{ historic.OrderDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Actualizado:</span>
                  <ng-container *ngIf="historic.OrderDate">
                  {{ historic.OrderDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                </p>
                <p>
                  <span>Revisado:</span>

                  {{ historic.ReviewUser ? "" : "SIN REVISAR"
                  }}
                  <ng-container *ngIf="historic.ReviewDate">
                  {{ historic.ReviewDate | date : "dd/MM/yyyy" }} ({{
                    historic.ReviewUser
                  }})
                </ng-container>
                </p>
                <p>
                  <span>Impreso:</span>
                  <ng-container *ngIf="historic.PrintDate">
                  {{ historic.PrintDate | date : "dd/MM/yyyy" }}
                  {{ historic.PrintUser ? "(" + historic.PrintUser + ")" : "" }}
                </ng-container>
                </p>
              </div>
            </app-expandable-card>
          </div>
          <div class="subtitle">Tipo de reporte</div>
          <app-form-field
            class="col-6"
            type="radio"
            label=""
            [fieldControl]="form.controls['typeReport'] | asFormControl"
            [options]="typeReportOptions"
          >
          </app-form-field>
        </div>
      </mat-tab>

      <mat-tab label="Configuración">
        <div class="map-cn-wrapper">
          <app-form-field
            class="col-6"
            type="text"
            label="Búsqueda"
            [fieldControl]="form.controls['filterReports'] | asFormControl"
          >
          </app-form-field>

          <app-form-field
            class="col-6"
            type="checkbox"
            label="Enlazados"
            [fieldControl]="form.controls['linked'] | asFormControl"
          >
          </app-form-field>

          <app-form-field
            class="col-6"
            type="checkbox"
            label="Modificados"
            [fieldControl]="form.controls['modified'] | asFormControl"
          >
          </app-form-field>

          <div class="expandable-card-wrapper" *ngIf="medicineRASelectOptions">
            <app-expandable-card
              *ngFor="let config of reportRAConfig; let index = index"
              [id]="config.DrugId"
              [title]="config.Name"
              [description]="
                getTextDescriptionRaReport(config, medicineRASelectOptions)
              "
              [descriptionClass]="raOptions[config.RaStatus].className"
              [actions]="actionsRaConfig"
              [isExpanded]="config.DrugId === expandedCardIdConfig"
              (toggle)="toggleCard(config.DrugId, 'config')"
            >
              <div slot="content">
                <form [formGroup]="formsGroups[index]">
                  <mat-radio-group
                    aria-labelledby="radio-label"
                    [value]="config.RaStatus"
                    class="config-options-wrapper"
                    formControlName="raStatus"
                  >
                    <mat-radio-button
                      *ngFor="let option of raOptions"
                      [value]="option.value"
                      >{{ option.label }}</mat-radio-button
                    >
                  </mat-radio-group>

                  <mat-form-field
                    appearance="fill"
                    subscriptSizing="dynamic"
                    *ngIf="
                      this.formsGroups[index].controls['raStatus'].value == 2
                    "
                  >
                    <mat-select
                      [value]="config.MedTranslate"
                      formControlName="medTranslate"
                    >
                      <mat-option
                        *ngFor="let medicineRA of medicineRASelectOptions"
                        [value]="medicineRA.value"
                      >
                        {{ medicineRA.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </app-expandable-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-report-select>
