<div class="section col-12">
    <div class="title">Datos generales</div>

    <div class="content" *ngIf="formReady">
        <app-form-field class="field col-3" [type]="'text'" [label]="'Usuario'" [hideLabel]="false"
            [ngClass]="{'disabled': mode !== FormMode.New}"
            [fieldControl]="form.controls['username'] | asFormControl"
            [readOnly]="Utils.BOOLEAN(mode === FormMode.View)">
        </app-form-field>

        <app-form-field class="field col-3" [type]="'text'" [label]="'Nombre'" [hideLabel]="false"
            [fieldControl]="form.controls['name'] | asFormControl" [readOnly]="Utils.BOOLEAN(mode === FormMode.View)">
        </app-form-field>

        <!-- GENERO -->
        <app-form-field class="field col-2" [type]="'radio'" [label]="'Género'" [hideLabel]="false"
            [fieldControl]="form.controls['gender'] | asFormControl" [options]="genderOptions"
            [readOnly]="Utils.BOOLEAN(mode === FormMode.View)">
        </app-form-field>

        <!-- LANGUAGE -->
        <app-form-field class="field col-4" [type]="'select'" [label]="'Idioma'" [hideLabel]="false"
            [options]="languageOptions" [fieldControl]="form.controls['language'] | asFormControl"
            [readOnly]="Utils.BOOLEAN(mode === FormMode.View)">
        </app-form-field>

    </div>
</div>
<div class="section col-12" *ngIf="formReady">

    <div class="title">Tipo Usuario</div>
    <div class="content checkbox roles">
        <!-- ROLES -->
        <app-form-field 
            class="field" 
            *ngFor="let role of roles"
            [type]="'checkbox'" 
            [label]="role.label" 
            [fieldControl]="getRoleFormControl(role) | asFormControl" 
            [readOnly]="Utils.BOOLEAN(mode === FormMode.View)">
        </app-form-field>
    </div>
</div>

<div class="section col-12" *ngIf="!isPharma && formReady">
    <div class="title">Institución</div>
    <app-form-field class="field" [type]="'select'" label="Selecciona los centros"
        [fieldControl]="form.controls['selectedInstitution'] | asFormControl" [options]="selectOptionsInsitutions"
        [multipleSelect]="true">
    </app-form-field>
</div>

<div class="section col-12" *ngIf="user.id == 0 && tempPwd == undefined">
    <div class="message alert-orange">El campo usuario no podrá cambiarse una vez que sea creado.</div>
</div>
<div class="section col-12" *ngIf="user.id != 0">
    <div class="message alert">El usuario fue dado de alta el {{user.crdt.toLocaleDateString()}}</div>
</div>
<div class="section col-12" *ngIf="user.id != 0 && tempPwd == undefined">
    <!-- TODO Mostrar si la constraseá expiró o dentro de cuanto expirará -->
    <div class="message success">{{getPasswordExpireTime()}}</div>
</div>
<div class="section col-12" *ngIf="tempPwd">
    <div class="message alert-red">
        Se ha creado una contraseña temporal para este usuario con valor:<br>
        <h3>{{tempPwd}}</h3>
        Por favor, tome nota de ella e infórmele al usuario que tiene 72 horas para acceder al sistema y crearse una
        nueva contraseña. Una vez que deje la pantalla de edición de este usuario no se volverá a mostrar más la
        contraseña temporal.
    </div>
</div>

<div class="buttons">
    <button class="password-button" *ngIf="user.id != 0 && canResetPassword" mat-raised-button color="primary"
        (click)="resetPassword()">
        Resetear contraseña
    </button>

    <button class="password-button" *ngIf="user.id != 0 && canChangePassword" mat-raised-button color="primary"
        (click)="changePassword()">
        Cambiar contraseña
    </button>
</div>

