import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { PatientsService } from '@services/patients.service';
import { InstitutionsService } from '@services/institutions/institutions.service';
import { MedicinesService } from '@services/medicines.service';
import { RoleManagerService } from '@services/role-manager.service';

import { LoginDataAPP, TokenInfo } from '@interfaces/api/api.interface';
import { decryptToken } from '@constants/funtions-utils';
import { UserData } from '@interfaces/app-user.interface';
import { UserDetail } from '@interfaces/user/user-detail.interface';
import { formsConfig } from '@constants/patients-forms.config';
import { ModalService } from './modal.service';
import { TempPasswordComponent } from '@shared/dialogs/temp-password/temp-password.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Token } from '@angular/compiler';
import { CorotaRole, UserRole } from '../enums/user-role';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    loggedUserId: number | undefined;
    private loggedUserName: string;
    public static userRoles: UserRole[];
    public static decodedToken: TokenInfo;
    decodedTokenSub: BehaviorSubject<TokenInfo | undefined> = new BehaviorSubject<TokenInfo | undefined>(undefined);
    corotaRoles: Array<{ name: string, descr: string, mask: number }> = [];
    // customRoles: Array<{ name: string, containingRoles: string[]}> = [
    //     { name: 'Titular', containingRoles: ["CorotaAdmin", "CorotaBlisters"]},
    //     { name: 'Farmaceutico', containingRoles: ["CorotaAdminRE", "CorotaReportes", "CorotaRevisor"]},
    //     { name: 'Admin', containingRoles: ["CorotaAdm"]},
    //     { name: 'Custodio', containingRoles: ["CorotaAdmin", "CorotaBlisters"]}
    // ];
    corotaPerms: any;

    public get user(): UserDetail | undefined {
        const token = this.getToken();
        if (token == null) return undefined;

        const tokenInfo = decryptToken(token);
        return {
            inst: tokenInfo.corotaInst,
            loginDate: new Date(tokenInfo.nbf * 1000).toLocaleString(),
            name: tokenInfo.corotaUID,
            siteID: '', // FIXME En Corota es una constante que depende del siteId (hardcoded en front)
            //pharmacyName: '' // TODO Falta por añadir, en corota es una constante, igual que siteId, pero llamada farmaciaName
            imagePath: encodeURI(`https://ui-avatars.com/api/?name=${tokenInfo.corotaUID}`)
        };
    };

    constructor(
        private router: Router,
        private calls: CallsService,
        private institutionService: InstitutionService,
        private institutionsService: InstitutionsService,
        private patientsService: PatientsService,
        private medicinesService: MedicinesService,
        private roleManager: RoleManagerService,
        private modal: ModalService
    ) {
        const token = this.getToken();
        if (token != undefined) {
            if (this.corotaRoles.length == 0) {
                Promise.resolve().finally(async () => {
                    await this.preLogIn();
                    this.extractRoles(token);
                });
            }
        }

        // Set logged user id
        const user = localStorage.getItem('current_user');
        if ( !isNaN(Number(user)) ) {
            this.loggedUserId = Number(user);
        }
    }

    recoverSession() {
        //TODO: Igual hay que mover todo lo relativo al local storage a un servicio propio que lo gestione
    }

    loginFromToken(token: string, callback?: (res: boolean) => void) {
        if (token) {
            if (callback) {
                this.calls.getLoggedUserData(token).subscribe({
                    next: (data) => {
                        const parsed = {
                            payload: {
                                Token: token,
                                loggedUserStatus: {
                                    pwdExpireDate: data.payload.pwdExpireDate,
                                    pwdDaysToExpire: data.payload.pwdDaysToExpire,
                                    userFlag: data.payload.userFlag,
                                    userId: data.payload.userId
                                },
                                Institutions: data.payload.Institutions
                            }
                        };
                        this.parseLoginResponse(parsed, (val: any) => {
                            if (val == true) {
                                callback(true);
                            }
                        }, undefined);
                    },
                    error: (err) => callback(false)
                });
            }
            this.setToken(token, true);
        }
        else {
            this.logOut();
        }
    }

    logIn(data: LoginDataAPP, redirect: boolean = true): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const { user } = data;
            this.calls
                .loginAPI({
                    ...data,
                    origin: this.roleManager.getOrigin(),
                })
                .subscribe({
                    next: async (res) => {
                        await this.parseLoginResponse(res, resolve, data, redirect);
                    },
                    error: (error) => {
                        resolve(false);
                        this.calls.openSnack('Error: Usuario o Contraseña incorrecta');
                    },
                });
        });
    }

    async parseLoginResponse(res: any, resolve?: any, data?: LoginDataAPP, redirect: boolean = true) {
        const token = res.payload.Token;
        const decodedToken = decryptToken(token);

        if (decodedToken.corotaIsTempPwd) {
            const flag = await new Promise<any>((resolve) => {
                this.modal.openModalCallbackCustomComponent(TempPasswordComponent, (modalData: { activation: string, newPwd: string }, id: any) => {
                    this.calls.changeUserPassword(token, modalData.newPwd, modalData.activation, decodedToken.corotaUIDDB).subscribe((correct) => {
                        if (!correct.correct) {
                            // Error
                            resolve(correct.msg);
                        }
                        else {
                            // Correcto
                            this.logIn({
                                user: data?.user ?? decodedToken.corotaUID,
                                password: modalData.newPwd
                            });
                            resolve(true);
                        }
                    });
                }, undefined, undefined, undefined, undefined, () => resolve(false));
            });
            if (flag instanceof HttpErrorResponse && data && resolve != undefined) {
                this.modal.openModalErrorInfo(flag.status == 252 ? 'La contraseña anterior es incorrecta.' : flag.error, 'Error al cambiar contraseña');
                resolve(false);
            }

            if (flag == false) {
                resolve(undefined);
            }

            return;
        }

        this.loggedUserId = res.payload.loggedUserStatus.userId;
        localStorage.setItem(
            'current_user',
            String(this.loggedUserId!)
        );

        //ADDED the name of the user logged too
        this.loggedUserName = decodedToken.corotaUID;
        localStorage.setItem('current_user_name', decodedToken.corotaUID);

        this.setToken(token);
        this.institutionService.setCurrentFarmacy(token);

        res.payload.Institutions
            ? this.institutionService.setInstitutions(res.payload.Institutions)
            : this.institutionService.getInstitutions();

        this.preLogIn().finally(() => {
            this.extractRoles(token);
            resolve(true);
            if (data != undefined)
                redirect ? this.roleManager.navigateLoggedIn() : null;
            else {
                const inst = res.payload.Institutions.find((i: any) => i.Id == String(decodedToken.corotaInstId));
                this.institutionService.selectInstitution({
                    id: String(inst.Id),
                    name: inst.Name,
                    shortName: inst.ShortName,
                    group: inst.GruposEmpresarial,
                    PermitirFGP: inst.PermitirFGP
                }, false);
            }
        });
    }

    setToken(token: string, extractRoles = false) {
        localStorage.setItem('access_token', token);
        if (extractRoles) {
            this.extractRoles(token);
        }
    }

    getToken() {
        return localStorage.getItem('access_token');
    }

    onLogout: EventEmitter<void> = new EventEmitter();
    logOut(customRoute?: string): void {
        this.loggedUserId = undefined;

        localStorage.clear();
        this.institutionService.clearStoredData();
        this.institutionsService.clearStoredData();
        this.patientsService.clearStoredData();
        this.medicinesService.clearStoredData(false);
        this.onLogout.emit();
        customRoute ? this.router.navigate([customRoute]) : this.router.navigate(['']);
    }

    forgotPassword(data: any): void {
        // TODO:
        console.log(data);
    }

    resetPassword(data: any): void {
        // TODO:
        console.log(data);
    }

    changePassword(): void {
        this.modal.openModalCallbackCustomComponent(TempPasswordComponent, (modalData: { activation: string, newPwd: string }, id: any) => {
            this.calls.changeUserPassword(this.getToken() as string, modalData.newPwd, modalData.activation, AuthService.decodedToken.corotaUIDDB).subscribe((correct) => {
                if (!correct.correct) {
                    // Error
                    this.modal.openModalErrorInfo(correct.msg.status == 252 ? 'La contraseña anterior es incorrecta.' : correct.msg.error, 'Error al cambiar contraseña');
                }
                else {
                    this.calls.openSnack('Se ha actualizado la contraseña correctamente');

                    // Correcto
                    this.logIn({
                        user: AuthService.decodedToken.corotaUID,
                        password: modalData.newPwd
                    }, false);
                }
            });
        }, undefined, undefined, undefined, undefined, () => {}, { passwordChange: true });
    }

    getLoggedUser(): number {
        if (!this.loggedUserId) {
            const user = localStorage.getItem('current_user');
            if (!user) {
                console.log('getCurrentUser');
                this.router.navigate(['']);
            }
            return Number(user);
        } else {
            return this.loggedUserId;
        }
    }

    getLoggedUserName(): string {
        if (!this.loggedUserName) {
            const user = localStorage.getItem('current_user_name');
            if (!user) {
                this.router.navigate(['']);
            }
            return user ?? '';
        } else {
            return this.loggedUserName;
        }
    }

    getUserInfo(): UserData | void {
        const token = localStorage.getItem('access_token');
        if (!token) {
            console.log('getUserInfo');
            this.router.navigate(['']);
        } else {
            const info: TokenInfo = decryptToken(token);
            return {
                name: info.corotaUID,
                id: info.corotaUIDDB
            };
        }
    }

    getTokenInfo(): TokenInfo | void {
        const token = localStorage.getItem('access_token');
        if (!token) {
            this.router.navigate(['']);
        } else {
            const info: TokenInfo = decryptToken(token);
            return info;
        }
    }

    getFarmaticId(): number {
        return this.getTokenInfo()?.corotaFID ?? 0;
    }

    //#region Roles and perms
    preLogIn(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.calls.preLogin().subscribe({
                next: (res) => {
                    this.corotaRoles = res.payload.corotaRoles;
                    this.corotaPerms = res.payload.corotaUserPermissions;
                    resolve(res);
                },
                error: (error) => console.error(error)
            });
        });
    }

    async extractRoles(token: string) {
        const decodedToken = decryptToken(token);
        AuthService.userRoles = [];
        AuthService.decodedToken = decodedToken;
        this.decodedTokenSub.next(decodedToken);

        let corotaRoles: string[] = [];
        this.corotaRoles.forEach((itm, idx) => {
            if (1 << idx & decodedToken['corotaRoles']) {
                corotaRoles.push(itm.name);
            }
        });
        
        AuthService.userRoles = this.roleManager.translateCorotaRoles(corotaRoles.map(r => r as CorotaRole));

        // if (decodedToken.corotaIsFarmacia) {
        //     this.customRoles.forEach(c => {
        //         if (c.containingRoles.every(r => AuthService.userRoles.includes(r))) {
        //             AuthService.userRoles = AuthService.userRoles.filter(r => !c.containingRoles.includes(r));
        //             AuthService.userRoles.push(c.name);
        //         }
        //     });

        //     console.log(AuthService.userRoles);
        // }
    }

    // testUserPermissions(perm: number, exact: boolean = false) {
    //     const token = this.getToken();
    //     if (token) {
    //         const decodedToken = decryptToken(token);
    //         return (decodedToken.corotaPerm & perm) > 0 && (exact || false ? (decodedToken.corotaPerm & perm) === decodedToken.corotaPerm : true);
    //     }

    //     return false;
    // }

    // testUserRole(role: string, exact: boolean = false) {
    //     return AuthService.userRoles.indexOf(role) >= 0 && (!(exact || false) || AuthService.userRoles.length === 1);
    // }
    //#endregion
}
