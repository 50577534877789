import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { sidebarConfigItem } from '@interfaces/sidebar.interface';
import { MatExpansionPanel } from '@angular/material/expansion';

import { inOutFadeFromLeft } from '@json/src/app/animations/general';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    animations: [ inOutFadeFromLeft ]
})
export class MenuItemComponent implements OnChanges {

    // @ViewChild("p") p: ElementRef<HTMLElement>;
    @Input() isExpanded: boolean = true
    @Output() isExpandedChange = new EventEmitter<boolean>();
    @Input() item?: sidebarConfigItem;
    @ViewChild(MatExpansionPanel) expansion: MatExpansionPanel;

    @ViewChild('childMenu') public childMenu: any;

    public collapsed: boolean = true;

    tooltipStyle: any = {};

    constructor( 
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdRef: ChangeDetectorRef 
    ) {}

    ngOnChanges() {
        this.cdRef.detectChanges();
    }

    openAccordionPanel() {
        setTimeout(() => {
            this.expansion.open();
        }, 100)
    }

    testPerm(item: sidebarConfigItem) {
        if (typeof(item.hasPermission) == 'function') {
            return item.hasPermission().length > 0; // Puede leer o escribir así que se debe mostrar
        }

        return item.hasPermission.length > 0; // Puede leer o escribir así que se debe mostrar
    }

    onMouseEnter($event: MouseEvent, sidebarConfigItem: sidebarConfigItem) {
        // @ts-ignore
        const elementRect = $event?.target?.getBoundingClientRect();

        if ( elementRect ) {
            this.tooltipStyle.height = elementRect.height + 'px';
            this.tooltipStyle.left = (elementRect.left + elementRect.width + 4) + 'px';
            this.tooltipStyle.top = elementRect.top + 'px';

            sidebarConfigItem.tooltipStatus = true;
        }
    }

    onMouseLeave($event: MouseEvent, sidebarConfigItem: sidebarConfigItem) {
        // @ts-ignore
        sidebarConfigItem.tooltipStatus = false;
    }

    navigateTo(link: string) {
        this.router.navigateByUrl('/global-eye', {skipLocationChange: false}).then(() => {
            this.router.navigate([`./${link}`], {relativeTo: this.activatedRoute});
        })
    }
}
