import { SelectOption } from '@interfaces/input-select-option.interface';
import { EpigraphAPI } from '../medicine/epigraph.interface';

export interface MedicineAPI {
    id: number;
    nam: string;
    sup: string;
    did: string;
    cf1: string;
    cf2: string;
    flg: number;
    ppb: number;
    nstk: number;
    nimg: number;
    FGP: boolean;
    CMS: string;
    PA: ActiveIngredient[];
    GrupoPeligro: number;
}

export interface AdministrationRoute {
    Id: number; // 6858
    Name: string; // "AEROSOL/NEBULIZADOR"
}
export interface MedicineDropdownAPI {
    id: number; // 6
    nam: string; // "PREGABALINA 150MG"
    did: string; // "754804"
    flg: number; // 6
}

export interface MedicineDropdownApp {
    id: number; // 6
    nam: string; // "PREGABALINA 150MG"
    CF1: string;
    CF2: string;
    did: string; // "754804"
    flags: MedicineFlags;
    flg: number;
    Obsolete: boolean;
    FGP: boolean;
    Presentacion: string;
    CMS: string;
    PA: ActiveIngredient[];
    GrupoPeligro: number;
}

export interface MedicineFlags {
    noBlister: boolean;
    payMed: boolean;
    noPartial: boolean;
    noTray: boolean;
    noStock: boolean;
    noHalf: boolean;
    ra: boolean;
    mec: boolean;
    diaper: boolean;
}

export interface MedicineDosisData {
    AdminRoute: number;
    LimitPerIntake: number | null;
    LimitPerDay: number | null;
    DrugId: string;
    DrugIdInvc: string;
    Obsolete?: boolean;
    CMS?: string;
}

export interface OverdoseDataAPI {
    insidSrc: number; // id institución del usuario
    uid: string; // id usuario
    insid: number; // id de la institución del paciente
    pid: number; // id paciente
    mid: number; // id medicamento
    overdosedType: number; // tipo de sobredosis 3-intake, 4-diario
    qtyLimit: number; // límite de cantidad
    qtyAdministered: number; // cantidad administrada
    force?: boolean; // si le da al sí (quiero continuar) esto a true
}

export interface IncompatibilityDataAPI {
    insidSrc: number; // id institución del usuario
    uid: string; // id usuario
    insid: number; // id de la institución del paciente
    pid: number; // id paciente
    mid: number; // id medicamento
    data: string; // xml array
    couldOverride: boolean; // siempre a false? ¯\_(ツ)_/¯
    force: boolean;
}

export interface InvalidHalfDataAPI {
    insidSrc: number; // id institución del usuario
    uid: string; // id usuario
    insid: number; // id de la institución del paciente
    pid: number; // id paciente
    mid: number; // id medicamento
}


export type OverdoseType = 'intake' | 'day' | 'both';
export interface Overdosis {
    intake: boolean;
    day: boolean;
    maxIntake: number;
    maxDay: number;
};

export interface MedicineOption extends SelectOption {
    label: string;
    value: MedicineDropdownApp;
}

export interface MedicineAdminRouteAPI {
    Id: string;
    Name: string;
}

export class MedicineAdminRoute {
    public id: string;
    public name: string;

    constructor(input: MedicineAdminRouteAPI) {
        this.id = input.Id;
        this.name = input.Name;
    }
}

export class Medicine {
    public id: number;
    public name: string;
    public supplier: string;
    public code: string;
    public cf1: string;
    public cf2: string;
    public flag: number;
    public quantity: number;
    public nstk: number; //no stock
    public numberImage: number;
    public FGP: boolean;
    public alert?: boolean;
    public CMS?: string;
    public PA: ActiveIngredient[];
    public dangerGroup: number;

    constructor(input: MedicineAPI) {
        this.id = input.id;
        this.name = input.nam;
        this.code = input.did;
        this.cf1 = input.cf1;
        this.cf2 = input.cf2;
        this.supplier = input.sup;
        this.flag = input.flg;
        this.quantity = input.ppb;
        this.nstk = input.nstk;
        this.numberImage = input.nimg;
        this.FGP = input.FGP;
        this.CMS = input.CMS;
        this.PA = input.PA;
        this.dangerGroup = input.GrupoPeligro;
    }
}

export interface MedicineFieldsAPI {
    AdminRoute: number;
    LimitPerIntake: number;
    LimitPerDay: number;
    DrugId: string;
    DrugIdInvc: string;
    CMS?: string;
}

export interface MedicineInteractionText {
    text: string[];
}

export interface MedicineInteractionAPI {
    titulo: string;
    CODIGOTEXTO: string;
}

export class MedicineInteraction {
    public title: string;
    public codeText: string;

    constructor(input: MedicineInteractionAPI) {
        this.title = input.titulo; // id of the medication to be searched for
        this.codeText = input.CODIGOTEXTO;
    }
}

export interface MedicineEdit {
    med: {
        id: number;
        did: string;
        CMS: string;
        didIvc: string;
        didIvcS1: any;
        nam: string;
        Presentacion: string;
        supl: any;
        strength: any;
        note1: any;
        note2: any;
        paymed: boolean;
        hospit: boolean;
        ppb: number;
        obsol: boolean;
        nobl: boolean;
        epigr: any[];
        und: boolean;
        mg1: number;
        noptl: boolean;
        onlytray: boolean;
        can: any;
        inter: any[];
        nostk: number;
        dpr: boolean;
        distr: any;
        nohalf: boolean;
        iefAlz: boolean;
        iefAdp: boolean;
        iefAps: boolean;
        iefEUA: boolean;
        iefAns: boolean;
        iefDia: boolean;
        iefAbt: boolean;
        iefAcg: boolean;
        iefOpi: boolean;
        iefAINE: boolean;
        iefIBP: boolean;
        nostkr: any;
        ra: boolean;
        UnitType: any;
        mec: boolean;
        ro: boolean;
        FGP: boolean;
        npr: boolean;
        prf: any;
        parf: boolean;
        admr: number;
        pvwc: any;
        heal: boolean;
        ralck: boolean;
        lpi: any;
        lpd: any;
        nimg: number;
        LinqData?: {farmaticLinks: Array<MedicineEditLinqData>};
        PA: ActiveIngredient[];
        GrupoPeligro: number;
    };
}

export interface MedicineEditLinqData {
    Canister: any,
    Exclude: boolean,
    FarmaticLinkId: number,
    Name: string,
    NoStock: number,
    NoStockReplace: number | null,
    OnlyTray: boolean,
    institutionLinks: Array<{
        Exclude: boolean,
        FarmaticLinkId: number,
        InstitutionId: number,
        ShortName: string
    }>
}

export class MedicineUpdateAPI {
    public Id: number;
    public DrugId: string;
    public CMS: string | undefined;
    public Name: string;
    public Display: string | undefined;
    public Supplier: any;
    public Strength: any;
    public Prescription1: any;
    public Prescription2: any;
    public PayMed: boolean;
    public HospitalUse: boolean;
    public PillsPerBox: number;
    public Obsolete: boolean;
    public Alzheimer: boolean;
    public NoBlister: boolean;
    public Unidosis: boolean;
    public Mg1: number;
    public NoPartial: boolean;
    public OnlyTray: boolean;
    public Canister: any;
    public NoStock: number;
    public Diaper: boolean;
    public DrugIdInvc: string;
    public DrugIdInvcS1: any;
    public NoHalf: boolean;
    public Distributor: any;
    public NervADP: boolean;
    public NervAPS: boolean;
    public NervEUTATC: boolean;
    public NervANS: boolean;
    public Diabetes: boolean;
    public Antibio: boolean;
    public Anticoag: boolean;
    public Opioide: boolean;
    public NoStockReplace: any;
    public RA: boolean;
    public UnitType: any;
    public MEC: boolean;
    public RO: boolean;
    public FGP: boolean;
    public NoPrescribe: boolean;
    public Profit: any;
    public PF: boolean;
    public AdminRoute: number;
    public LimitPerIntake: any;
    public LimitPerDay: any;
    public PreviewConversion: any;
    public HealMat: boolean;
    public LinqData?: {farmaticLinks: Array<MedicineEditLinqData>};

    constructor(input: MedicineForm) {
        this.DrugId = input.general.masterCode;
        this.CMS = input.general.CMS;
        this.Name = input.general.name;
        this.Display = input.general.display;
        this.Supplier = input.general.supplier;
        this.Strength = input.general.strength;
        this.Antibio = input.classification.antibiotics;
        this.Anticoag = input.classification.anticoagulants;
        this.Alzheimer = input.classification.alzheimer;
        this.Diabetes = input.classification.diabetes;
        this.Diaper = input.otherData.diaper;
        this.HealMat = input.otherData.cureMaterial;
        this.MEC = input.otherData.MEC;
        this.Obsolete = input.otherData.obsolete;
        this.Opioide = input.classification.opioids;
        this.PayMed = input.otherData.cobert;
        this.HospitalUse = input.otherData.hospitalUse;
        this.RA = input.otherData.RA;
        this.RO = input.otherData.RO;
        this.Mg1 = parseInt(input.units.dose?.toString());
        this.PillsPerBox = parseInt(input.units.box?.toString());
        this.DrugIdInvc = input.codesFacturation.primary;
        this.DrugIdInvcS1 = input.codesFacturation.secondary;
        this.Prescription1 = input.notes.observations1;
        this.Prescription2 = input.notes.observations2;
        this.Diaper = input.otherData.diaper;
        this.FGP = input.otherData.FGP;
        this.NoPrescribe = input.otherData.notPrescribe;
        this.NervADP = input.classification.antidepressants;
        this.NervANS = input.classification.anxiolytics;
        this.NervAPS = input.classification.antipsychotics;
        this.NervEUTATC = input.classification.anticonvulsants;
        this.AdminRoute = parseInt(input.general.admin?.toString());
        this.PF = input.otherData.parapharmacy;

        //No reference to the "Ninguna" checkbox, check it in the front end
        //robot
        this.Canister = input.robot.tolva;
        this.NoHalf = input.robot.robotOptions.noHalves;
        this.NoPartial = input.robot.robotOptions.indivisible;
        this.Unidosis = input.robot.robotOptions.uniDose;
        this.OnlyTray = input.robot.robotOptions.alwaysPlate;
        this.NoBlister = input.robot.robotOptions.noEmbistable;
    }
}
//Medicine Form
export class MedicineForm {
    public general: GeneralData;
    public codesFacturation: CodesFacturation;
    public activeIngredients: ActiveIngredient[];
    public units: Units;
    public classification: Classification;
    public inventary: Inventary;
    public otherData: OtherData;
    public notes: Observations;
    public epigraphs: Epigraphs;
    public interactions: InteractionsForm;
    public robot: RobotForm;

    constructor(input: MedicineEdit, asNew: boolean = false) {
        const { med } = input;

        this.general = {
            masterCode: med.did,
            CMS: med.CMS,
            name: med.nam,
            display: med.Presentacion,
            supplier: med.supl,
            strength: med.strength,
            admin: med.admr?.toString(),
            dangerGroup: med.GrupoPeligro
        }

        this.codesFacturation = {
            primary: med.didIvc,
            secondary: med.didIvcS1,
        };

        this.activeIngredients = med.PA;

        this.classification = {
            none: asNew ? false : !(
                med.iefAlz ||
                med.iefAdp ||
                med.iefAbt ||
                med.iefAcg ||
                med.iefAns ||
                med.iefAps ||
                med.iefAps ||
                med.iefEUA ||
                med.iefDia ||
                med.iefOpi
            ),
            alzheimer: med.iefAlz,
            anxiolytics: med.iefAns,
            antibiotics: med.iefAbt,
            anticoagulants: med.iefAcg,
            antidepressants: med.iefAdp, //the same, problem
            antipsychotics: med.iefAps,
            anticonvulsants: med.iefEUA,
            diabetes: med.iefDia,
            opioids: med.iefOpi,
            aine: med.iefAINE,
            ibp: med.iefIBP,
        };

        this.otherData = {
            obsolete: med.obsol,
            cobert: med.paymed,
            hospitalUse: med.hospit,
            diaper: med.dpr,
            parapharmacy: med.parf,
            cureMaterial: med.heal,
            RA: med.ra,
            RO: med.ro,
            FGP: med.FGP,
            notPrescribe: med.npr,
            MEC: med.mec,
        };

        this.units = {
            box: med.ppb,
            dose: med.mg1,
            type: med.UnitType ?? 0,
        };

        this.inventary = {
            inventary: med.nostk,
        };

        this.notes = {
            observations1: med.note1,
            observations2: med.note2,
        };

        this.epigraphs = {
            info: '',
            epigraphs: med.epigr,
        };

        this.interactions = {
            interactions: med.inter,
            infoSelectedInteraction: '',
        };

        this.robot = {
            robotOptions: {
                noEmbistable: med.nobl,
                uniDose: med.und,
                indivisible: med.noptl,
                alwaysPlate: med.onlytray,
                noHalves: med.nohalf,
            },
            tolva: med.can,
        };
    }
}

export interface RobotForm {
    robotOptions: RobotOptions;
    tolva: any;
}

export interface RobotOptions {
    noEmbistable: boolean;
    uniDose: boolean;
    indivisible: boolean;
    alwaysPlate: boolean;
    noHalves: boolean;
}

export interface InteractionsForm {
    interactions: any;
    infoSelectedInteraction: string;
}

export interface MedicineValidations {

    OverlappedPrescriptions: OverlappedPrescription[];
    MaxQtyPerIntake: number;
    HasOverdosisItk: boolean;
    MaxQtyPerDay: number;
    HasOverdosisDay: boolean;
    HasInvalidDosis: boolean;
    HasInvalidHalf: boolean;
}

export interface OverlappedPrescription {
    StartDate: Date;
    EndDate: Date | null;
    OverlapMedicineId: number;
    CouldOverride: boolean;
    OverlapType: number;
    DrugId: string;
    Name: string;
    PrescrId: number;
}

export interface Observations {
    observations1: string;
    observations2: string;
}

export interface Epigraphs {
    info: string;
    epigraphs: EpigraphAPI[];
}

export interface GeneralData {
    masterCode: string;
    CMS?: string;
    name: string;
    display?: string;
    supplier: string;
    strength: string;
    admin: string;
    dangerGroup?: number;
}
export interface CodesFacturation {
    primary: string;
    secondary: string;
}

export interface Units {
    box: number | string;
    dose: number | string;
    type: any;
}

export interface Classification {
    none: boolean;
    alzheimer: boolean;
    anxiolytics: boolean;
    antibiotics: boolean;
    anticoagulants: boolean;
    antidepressants: boolean;
    antipsychotics: boolean;
    anticonvulsants: boolean;
    diabetes: boolean;
    opioids: boolean;
    aine: boolean;
    ibp: boolean;
}

export interface Inventary {
    inventary: any;
}

export interface OtherData {
    obsolete: boolean;
    cobert: boolean;
    hospitalUse: boolean;
    diaper: boolean;
    parapharmacy: boolean;
    cureMaterial: boolean;
    MEC: boolean;
    RA: boolean;
    RO: boolean;
    FGP: boolean;
    notPrescribe: boolean;
}

export interface MedicineFilterCondition {
    roCondition: boolean;
    raCondition: boolean;
    mecCondition: boolean;
    alzheimerCondition: boolean;
    obsoleteCondition: boolean;
    noPrescriptionCondition: boolean;
    unitDoseCondition: boolean;
    notCobertCondition: boolean;
    embistableCondition: boolean;
    noInventaryCondition: boolean;
    noneCondition: boolean;
}

export interface MedicineSelectFilter {
    cobert: boolean;
    noEmbistable: boolean;
    alzheimer: boolean;
    unitDose: boolean;
    RA: boolean;
    MEC: boolean;
    RO: boolean;
    obsolete: boolean;
    noInventary: boolean;
    noPrescription: boolean;
    hospitalUse: boolean;
    none: boolean;
}

export class MedicineEditEmpty {
    med = {
        id: 0,
        did: '',
        CMS: '',
        didIvc: '',
        didIvcS1: 'any',
        nam: '',
        Presentacion: 'any',
        supl: 'any',
        strength: 'any',
        note1: 'any',
        note2: 'any',
        paymed: false,
        hospit: false,
        ppb: 0,
        obsol: false,
        nobl: false,
        epigr: [],
        und: false,
        mg1: 0,
        noptl: false,
        onlytray: false,
        can: 'any',
        inter: [],
        nostk: 0,
        dpr: false,
        distr: 'any',
        nohalf: false,
        iefAlz: false,
        iefAdp: false,
        iefAps: false,
        iefEUA: false,
        iefAns: false,
        iefDia: false,
        iefAbt: false,
        iefAcg: false,
        iefOpi: false,
        iefAINE: false,
        iefIBP: false,
        nostkr: 'any',
        ra: false,
        UnitType: 'any',
        mec: false,
        ro: false,
        FGP: false,
        npr: false,
        prf: 'any',
        parf: false,
        admr: 0,
        pvwc: 'any',
        heal: false,
        ralck: false,
        lpi: 'any',
        lpd: 'any',
        nimg: 0,
        PA: [],
        GrupoPeligro: 0
    };
}

export interface ActiveIngredient {
    Id: number;
    Nombre: string;
    DMARC: boolean;
    Estupefaciente: boolean;
    Psicotropo: boolean;
}
