import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';

import { REPatientAPP } from '@interfaces/re-status.interface';

@Component({
  selector: 'app-re-patient-debt-detail',
  templateUrl: './re-patient-debt-detail.component.html',
  styleUrls: ['./re-patient-debt-detail.component.scss']
})
export class RePatientDebtDetailComponent implements OnInit, OnChanges {

  @Input() patient: REPatientAPP;
  @Input() dataReady: boolean;

  @Output() shouldTranslateY = new EventEmitter<number>();

  @HostBinding('style.transform') public transform: string = '';

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if ( this.dataReady ) {
      this.cdRef.detectChanges();
      this.getTransformStyle();
    }
  }

  getTransformStyle() {
    if (this.el) {
      const tableHeight = this.el.nativeElement.getBoundingClientRect().height;
      const tableTop = this.el.nativeElement.getBoundingClientRect().top;
      const tableBottom = tableHeight + tableTop;

      if ( window.innerHeight < tableBottom ) {
        this.transform = `translateY(-${(tableBottom - window.innerHeight) + 10}px)`;
      }
    }
  }
}
