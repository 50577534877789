import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { FormInterface } from '@interfaces/institution/institution-edit.interface';
import { MedicineOption } from '@interfaces/medicine/medicine.interface';
import { PrescriptionApp, PrescriptionResponseAPI, PrescriptionTag } from '@interfaces/patient/patient.interface';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { MedicinesService } from '@services/medicines.service';
import { ModalService } from '@services/modal.service';
import { PatientsService } from '@services/patients.service';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { StopPrescriptionComponent } from '@shared/dialogs/stop-prescription/stop-prescription.component';
import { Subscription, take } from 'rxjs';
import { CallsService } from '@services/api/calls.service';
import { ReportsService } from '@services/reports/reports.service';
import { RenderReportAPI } from '@interfaces/report/report.interface';
import { getDateStringYMD, getTimeStringHM, setDateTo00, setDateTo2359 } from '@constants/funtions-utils';
import moment from 'moment';
import { Utils } from '@json/src/app/Utils';
import { PrescriptionEditComponent } from '@shared/prescription-edit/prescription-edit.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DateService } from '@services/date.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AuthService } from '@services/auth.service';
import { InstitutionsService } from '@services/institutions/institutions.service';

@Component({
    selector: 'app-prescriptions-table',
    templateUrl: './prescriptions-table.component.html',
    styleUrls: ['./prescriptions-table.component.scss']
})
export class PrescriptionsTableComponent implements OnInit, OnDestroy {
    @Input() patientId: string | null;
    @Input() patientInstitutionId: number | undefined;
    @Input() patiendDefaultDep: number | undefined;
    @Input() patientLink: string | undefined;
    @Input() exportCf: boolean | undefined = true;
    @Input() allergies: string | undefined;
    @Input() forms: FormInterface;

    @ViewChild(PrescriptionEditComponent) prescriptionEdit: PrescriptionEditComponent;

    public generalData: FormGroup;
    public clinicNotes: FormGroup;

    public showEditForm: boolean = false;
    public prescriptionToEdit: number | undefined;
    isModification: boolean = false;
    stopDate: Date | null;

    public form: FormGroup = new FormGroup({
        date: new FormControl(null),
        showAll: new FormControl(false)
    });

    getPatientPrescriptionWeekSubs: Subscription | undefined;

    public columns: { header: string, columnDef: string, align: string }[] = [
        { header: 'Medicina', columnDef: 'DrugName', align: 'left' },
        { header: '', columnDef: 'LUN', align: 'right' },
        { header: '', columnDef: 'MAR', align: 'right' },
        { header: '', columnDef: 'MIE', align: 'right' },
        { header: '', columnDef: 'JUE', align: 'right' },
        { header: '', columnDef: 'VIE', align: 'right' },
        { header: '', columnDef: 'SAB', align: 'right' },
        { header: '', columnDef: 'DOM', align: 'right' },
        { header: 'ALTA', columnDef: 'StartDate', align: 'center' },
        { header: 'BAJA', columnDef: 'EndDate', align: 'center' },
        { header: 'DOSIS', columnDef: 'dosis', align: 'left' },
        { header: 'INFO', columnDef: 'info', align: 'center' },
        { header: '', columnDef: 'actions', align: 'center' },
    ];

    public days: { [key: string]: { label: string, today: boolean } } = {
        LUN: { label: '', today: false },
        MAR: { label: '', today: false },
        MIE: { label: '', today: false },
        JUE: { label: '', today: false },
        VIE: { label: '', today: false },
        SAB: { label: '', today: false },
        DOM: { label: '', today: false }
    };

    public columnNames: string[] = this.columns.map(c => c.columnDef);
    public rows: PrescriptionApp[] = [];

    get currentRows(): PrescriptionApp[] {
        return this.rows.filter(row => {
            return this.canShowPrescription(row);
        });
    }

    private subs: Subscription[] = [];

    isExitus: boolean = false;


    constructor(
        private loadingService: LoadingService,
        private patientsService: PatientsService,
        private medicinesService: MedicinesService,
        private institutionService: InstitutionService,
        private institutionsService: InstitutionsService,
        private calls: CallsService,
        private modalService: ModalService,
        private reportsService: ReportsService,
        private matIconRegistry: MatIconRegistry,
        private domSaninitzer: DomSanitizer,
        private dateService: DateService,
        private gaService: GoogleAnalyticsService,
        private authService: AuthService
    ) {
        matIconRegistry.addSvgIcon('steto', domSaninitzer.bypassSecurityTrustResourceUrl("/assets/images/stethoscope.svg"));
    }

    ngOnInit(): void {
        if (this.forms) {
            this.clinicNotes = this.forms['clinicNotes'].form;
            this.generalData = this.forms['general'].form;

            // Check if patient is exitus
            this.generalData.valueChanges
            .pipe(take(1))
            .subscribe((data) => {
                if (this.forms['general'].form.controls['dates'].get('deregistrationDate')?.value) {
                    this.isExitus = this.forms['general'].form.controls['dates'].get('deregistrationReason')?.value === 0;
                }
            })
        }
        if (this.patientId) {
            this.subs.push(
                // this.medicinesService.getMedicinesOptions().subscribe(res => {
                //     // Filter medicines that ar obsolete and master id equals cf id
                //     this.medicinesOptions = res.filter(item => !(item.value.Obsolete && item.value.did === item.value.CF1));

                //     // Add presentation to medicine label
                //     this.medicinesOptions.forEach(medicine => {
                //         medicine.value.Presentacion && (medicine.label += ` - ${medicine.value.Presentacion}`);
                //     })

                //     // Filter FGP medicines if not allowed
                //     if ( !this.institutionService.getCurrentInstitutionAllowFgp() ) {
                //         this.medicinesOptions = this.medicinesOptions.filter(item => !item.value.FGP);
                //     }

                //     // Filter all obsolete medicines if no CMS
                //     this.filterAllObsoleteMedicinesIfNoCMS();

                //     this.medicinesOptions.forEach(medicine => {
                //         if ( medicine.value.FGP ) {
                //             medicine.customStyle = {
                //                 'background-color': '#a5a5a5',
                //                 'color': 'white'
                //             }
                //         }
                //     })
                // }),
                // this.medicinesService.getMedicinesAdministrationRoutesOptions().subscribe(res => this.administrationOptions = res),
                this.form.controls['date'].valueChanges.subscribe((date) => {
                    this.update(date);
                })
            );
        }
        this.form.controls['date'].setValue(new Date());
        // this.getDepartments();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        this.getPatientPrescriptionWeekSubs?.unsubscribe();
    }

    // filterAllObsoleteMedicinesIfNoCMS() {
    //     this.medicinesOptions = this.medicinesOptions.filter(item => !item.value.Obsolete || (item.value.Obsolete && !!item.value.CMS));
    // }

    update(date: Date): void {
        this.getPatientPrescriptionWeekSubs?.unsubscribe();
        this.getPatientPrescriptionWeekSubs = this.patientsService.getPatientPrescriptionWeek({
            patientId: Number(this.patientId),
            dateFrom: this.getMonday(date),
            dateUntil: this.getSunday(date),
        }).subscribe((res) => {
            let today = new Date();
            let days = this.getWeekDays(this.getMonday(date));
            Object.keys(this.days).forEach((day, index) => {
                this.days[day] = {
                    label: days[index],
                    today: `${today.getDate()}/${today.getMonth() + 1}` === days[index]
                }
            });
            this.rows = res.map(row => {
                const a: PrescriptionApp = {
                    ...row,
                    tags: this.getTags(row),
                    flags: {
                        canDelete: row.StartDate && moment(row.StartDate).isSameOrAfter(moment(), 'day'),
                        canStop: ((row.CurrentDate || row.ActivePeriods || moment(row.EndDate).isAfter(moment(this.getMonday(new Date())), 'day') || (row.EndDate === null && !row.OccurrenceCount)) &&
                            (!row.EndDate || moment(row.EndDate).isAfter(moment(), 'day') || moment(row.EndDate).isSame(moment(), 'day') && !!row.PE) ||
                            moment(row.StartDate).isAfter(moment(), 'day')),
                        canEdit: (row.CurrentDate || row.ActivePeriods || moment(row.EndDate).isAfter(moment(this.getMonday(new Date())), 'day') || (row.EndDate === null && !row.OccurrenceCount)) &&
                            (!row.EndDate || moment(row.EndDate).isAfter(moment(), 'day'))
                    }
                };
                return a;
            });
        })
    }

    // getDepartments(): void {
    //     this.subs.push(
    //         this.institutionService
    //             .getInstitutionDepartments(this.institutionService.getCurrentInstitution())
    //             .subscribe({
    //                 next: (res) => {
    //                     this.departmentOptions = res;
    //                 },
    //                 error: () => {
    //                     this.calls.openSnack('Error al obtener los Departamentos');
    //                 },
    //             })
    //     );
    // }

    getMonday(date: Date): Date {
        return moment(date).startOf('isoWeek').toDate();
    }

    getSunday(date: Date): Date {
        return moment(date).endOf('isoWeek').seconds(0).toDate();
    }

    getWeekDays(monday: Date): string[] {
        let res = [];
        for (let i = 0; i < 7; i++) {
            let date = new Date(monday)
            date.setDate(date.getDate() + i);
            res.push(`${date.getDate()}/${date.getMonth() + 1}`)
        }
        return res;
    }

    previousWeek(): void {
        let res = new Date(this.form.value.date);
        let diff = res.getDate() - 7;
        this.form.controls['date'].setValue(new Date(res.setDate(diff)));
    }

    nextWeek(): void {
        let res = new Date(this.form.value.date);
        let diff = res.getDate() + 7;
        this.form.controls['date'].setValue(new Date(res.setDate(diff)));
    }

    getTags(prescription: PrescriptionResponseAPI): PrescriptionTag[] {
        let result: PrescriptionTag[] = [];
        let text = '';
        let period = `${prescription.OccurrenceCount ? prescription.OccurrenceCount : ''}c${prescription.RecurrenceInterval}`
        switch (prescription.RecurrencePattern) {
            case 1: // Diario
                text += `Diario${prescription.RecurrenceInterval > 1 ? ' (' + period + ')' : ''}`;
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            case 3: // Dairio pares
                text += 'Días pares';
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            case 4: // Dairio impares
                text += 'Días impares';
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            case 2: // Semanal
                text += `Semanal${prescription.RecurrenceInterval > 1 ? ' (' + period + ')' : ''}`;
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            case 5: // Mensual
                text += `Mensual${prescription.RecurrenceInterval > 1 ? ' (' + period + ')' : ''}`;
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            case 6: // Si precisa
                result.push({ class: 'sp', label: 'S/P', tooltip: '' });
                break;
            case 7: // Cíclico
                text += `Ciclo ${period}`;
                result.push({ class: 'pattern', label: text, tooltip: '' });
                break;
            default:
                break;
        }

        if (prescription.itks.length) {
            const days = ['', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM'];
            prescription.itks.forEach(itk => {
                if (itk.DayCode) {
                    let label = prescription.RecurrencePattern === 2 ? days[itk.DayCode] : itk.DayCode;
                    result.push({
                        label: `${label}`,
                        class: 'day',
                        tooltip: ''
                    })
                }
                itk.Dosis.forEach(dosis => {
                    let label: string = '';
                    
                    if ( dosis.TimeName.includes('Solamente si precisa') ) {
                        label += 'MAX';
                    } else if ( !dosis.TimeName.includes(':') ) {
                        label += dosis.TimeName.slice(0, 3).toUpperCase();
                    } else {
                        label += dosis.TimeName
                    }

                    result.push({
                        label: `${label} ${dosis.Qty}`,
                        class: dosis.DepId == this.patiendDefaultDep ? 'dosis' : 'dosis-other-dep',
                        tooltip: dosis.DepName
                    })
                })
            })
        }
        return result;
    }

    isTodayDate(date: Date): boolean {
        return moment(date).isSame(moment(), 'day');
    }

    isBeforeEndDate(date: Date | null): boolean {
        if (!date) return true;
        date = new Date(date);
        let today = new Date();
        return date.getDate() >= today.getDate()
            && date.getMonth() >= today.getMonth()
            && date.getFullYear() >= today.getFullYear();
    }

    openLink(): void {
        window.open(this.patientLink, '_blank');
    }

    stopPrescription(prescription: PrescriptionApp): void {
        this.modalService.openModalSubs(StopPrescriptionComponent, {
            prescription,
            mode: 'stop'
        }).subscribe((endDate) => {
            if (endDate instanceof Date) {
                endDate = Utils.BUG_FixDateForTimeZone(moment(endDate).startOf("day").toDate());
                this.loadingService.start('Deteniendo prescripción');
                this.patientsService.stopPrescription(
                    prescription.PrescriptionId,
                    endDate
                ).subscribe({
                    next: (res) => {
                        this.update(this.form.controls['date'].value);
                    },
                    complete: () => {
                        this.loadingService.stop();
                        this.gaService.event('stop_prescripción', 'click', 'Detener prescripción');
                    }
                });
            }
        });
    }

    deletePrescription(prescription: PrescriptionApp): void {
        this.modalService.openModalSubs(ConfirmationDialogComponent, {
            title: 'Eliminar prescripción',
            message: '¿está seguro de que desea eliminar la prescripción?',
            buttonText: {
                ok: 'Si',
                cancel: 'No',
            }
        }).subscribe((res) => {
            if (res) {
                this.loadingService.start('Borrando prescripción');
                this.patientsService.deletePrescription(
                    prescription.PrescriptionId
                )
                    .subscribe({
                        next: (res) => {
                            this.update(this.form.controls['date'].value);
                        },
                        complete: () => {
                            this.loadingService.stop();
                        }
                    })
            }
        })
    }

    modifyPrescription(prescription: PrescriptionApp): void {
        if (moment(prescription.StartDate).isSameOrAfter(moment(), 'day')) {
            this.prescriptionToEdit = prescription.PrescriptionId;
            this.isModification = true;
            this.showEditForm = true;
        } else {
            this.modalService.openModalSubs(StopPrescriptionComponent, {
                prescription,
                mode: 'modify'
            }).subscribe((endDate) => {
                if (endDate instanceof Date) {
                    this.stopDate = Utils.BUG_FixDateForTimeZone(moment(endDate).startOf("day").toDate());
                    this.prescriptionToEdit = prescription.PrescriptionId;
                    this.isModification = true;
                    this.showEditForm = true;
                    this.gaService.event('modificar_prescripción', 'click', 'Modificar prescripción');
                }
            });
        }
    }

    newPrescription(): void {
        this.prescriptionToEdit = undefined;
        this.showEditForm = true;

        this.gaService.event('nueva_prescripción', 'click', 'Nueva prescripción');
    }

    closeEdit(): void {
        if ( this.showEditForm ) {
            this.update(this.form.controls['date'].value);
            this.form.controls['date'].setValue(new Date());
        }

        this.prescriptionToEdit = 0;
        this.isModification = false;
        this.showEditForm = false;
    }

    printPF(): void {
        const today = new Date();
        const nextMonth = new Date();
        nextMonth.setMonth(today.getMonth() + 1);
        const instName = this.institutionService.getInstitutionName();
        const currentDate = getDateStringYMD(today);
        const currentTime = getTimeStringHM(today);
        const data: RenderReportAPI = {
            rpp: '/COROTA/PharmacologicReport',
            pts: 0,
            z: 100,
            htmf: false,
            fmt: 2,
            dl: false,
            dn: `Plan_farmacologico_${instName}_${currentDate}_${currentDate}_${currentTime}.pdf`,
            rp: JSON.stringify({
                LocaleId: "3082",
                StartDate: Utils.BUG_FixDateForTimeZone(moment(today).startOf('day').toDate())?.toISOString().split('.')[0],
                EndDate: this.dateService.transformDateTimeIncrementMonth(today.toISOString()),
                PatientId: this.patientId,
                InstitutionId: this.institutionService.getCurrentInstitution(),
                IncludeNoBlister: "1",
                Colegiado: ""
            })
        };
        this.reportsService.handlePrintClick({ dataRenderReport: data })
    }

    printInstructions(): void {
        const data: RenderReportAPI = {
            rpp: "/COROTA/BlankAnnex11",
            pts: 0,
            z: 100,
            htmf: false,
            fmt: 2,
            dl: false,
            dn: "Autorizacion.pdf",
            rp: "{}"
        };
        this.reportsService.handlePrintClick({ dataRenderReport: data })
    }

    canShowPrescription(row: PrescriptionApp, disableShow: boolean = false) {
        const showAll = disableShow ? false : this.form.value.showAll;
        if (typeof row.EndDate == 'string') row.EndDate = moment(row.EndDate).toDate();
        return row.CurrentDate !== null || (row.EndDate === null || row.EndDate.getTime() > this.getMonday(this.form.value.date).getTime() && !row.OccurrenceCount) || showAll;
    }

    openPrescription(row: PrescriptionApp) {
        this.prescriptionToEdit = row.PrescriptionId;
        this.showEditForm = true;
    }

    round(value: string) {
        return parseFloat(parseFloat(value).toFixed(2));
    }

    openGSPLink() {
        window.open('https://api.globaleye.es/resources/content/GSP.pdf', '_blank');
    }
    openPNRALink() {
        window.open('https://www.resistenciaantibioticos.es/es/guia-terapeutica-antimicrobiana-del-sns-salud-humana', '_blank');
    }

    getPETooltip(row: PrescriptionApp): string {
        if (!!row.PE) {
            return `Pendiente de evaluación (${moment(row.PE).format('DD/MM/YYYY')})`;
        } else {
            return '';
        }
    }

    hasNarcotics(row: PrescriptionApp): boolean {
        return row.PA.some(pa => pa.Estupefaciente);
    }
    hasPsychotropics(row: PrescriptionApp): boolean {
        return row.PA.some(pa => pa.Psicotropo);
    }
    hasMARC(row: PrescriptionApp): boolean {
        return row.PA.some(pa => pa.DMARC);
    }
    isDangerous(row: PrescriptionApp): number {
        return row.GrupoPeligro;
    }

    hasBiAccess(): boolean {
        return this.authService.hasBiAccess();
    }
    accessBi() {
        this.institutionsService.getQlikOTT()
        .then((res) => {
            console.log(res);
        }).catch((err) => {});
    }
}
