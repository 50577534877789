<section [hidden]="loading" style="height: 100%;">
  <section #cards class="cards-wrapper">
    <div class="cards" style="height: 100%; overflow-x: hidden; overflow-y: auto;">
      <div class="no-data"  *ngIf="!currentData?.length">Ningún residente encontrado</div>

      <div class="list-header">
        <div class="element space"></div>
        <div class="element sortable number" (click)="sortBy('number')" [ngClass]="{'sorted': currentSort === 'number'}">
          Número
          <mat-icon *ngIf="currentSort === 'number' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'number' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable name" (click)="sortBy('name')">
          Nombre 
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'name' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable department" (click)="sortBy('department')">
          Departamento
          <mat-icon *ngIf="currentSort === 'department' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'department' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element sortable init" (click)="sortBy('birthday')">
          Alta
          <mat-icon *ngIf="currentSort === 'birthday' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="currentSort === 'birthday' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
        </div>
        <div class="element actions">
          Acciones
        </div>
      </div>

      <app-card
        class="list-mode"
        *ngFor="let patient of currentData | slice : lowValue : highValue"
        [title]="patient.name"
        [id]="patient.id"
        [img]="patient.imagePath"
        [route]="route"
        [actions]="actions"
        [alert]="(patient.alert || incorrectData(patient))"
        [colorHeader]="getPatientCardHeaderColor(patient)"
        [greyed]="isExitus(patient)"
        [defaultImg]="patient.gender == 'M' ? '/assets/pics/male.png' : '/assets/pics/female.png'"
        (deleteButtonCLicked)="handleDelete($event)"
        [nameTooltip]="getAlertTooltip(patient)"
        [canNavigate]="canNavigate"
      >
        <div class="number" slot="number">
          {{ patient.number }}
        </div>
        <div class="content" slot="content">
          <span>{{ patient.department }}</span>
          <span>
            {{ patient.birthday | date : "dd/MM/yyyy" }}
            {{
              patient.expedition
                ? " - " + (patient.expedition | date : "dd/MM/yyyy")
                : ""
            }}
          </span>
        </div>
      </app-card>
    </div>
    <ng-container *ngIf="!loading">
      <p *ngIf="currentFilter || currentSearch">
        {{ msgNotFound }}:
        <span class="search-value">{{ currentFilter || currentSearch }}</span>
      </p>
    </ng-container>

    <div class="pagination">
      <app-color-legend 
        class="legend" 
        [items]="colorLegendItems" 
        [legendPosition]="'top'"
      >
      </app-color-legend>
      
      <mat-paginator #paginator aria-label="Seleccionar pagina" [length]="currentData.length"
        [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPageEvent($event)"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </section>
</section>
