import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { filter } from '@constants/funtions-utils';
import { TYPE_AUTOMATIC_REPORT } from '@constants/options';
import {
    RA_OPTIONS,
    reportDataRA,
    reportDataRACount,
} from '@constants/reports/report-ra';
import { CardActionRA } from '@interfaces/card-actions';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { ReportInsertRAOrder } from '@interfaces/report/report-automatic-reset.interface';
import {
    ConfigRA,
    Report,
    ReportRA,
} from '@interfaces/report/report.interface';
import { CallsService } from '@services/api/calls.service';
import { AuthService } from '@services/auth.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ModalService } from '@services/modal.service';
import { ReportsService } from '@services/reports/reports.service';
import { WebSocketsService } from '@services/web-sockets.service';
import { SendOrderComponent } from '@shared/dialogs/send-order/send-order.component';
import { debounceTime, Subscription } from 'rxjs';
import moment from 'moment';
import { Utils } from '@json/src/app/Utils';

@Component({
    selector: 'app-report-automatic-reset',
    templateUrl: './report-automatic-reset.component.html',
    styleUrls: ['./report-automatic-reset.component.scss'],
})
export class ReportAutomaticResetComponent {
    private subs: Subscription[] = [];
    public form: FormGroup;
    public formsGroups: FormGroup[] = [];
    public initialFormValues: any[] = [];

    public expandedCardIdActive: string | null;
    public expandedCardIdHistoric: string | null;
    public expandedCardIdConfig: string | null;
    public currentlyExpandedCardId: string | null = null;

    public reportDataRA = reportDataRA;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;

    public reportsActiveCopy: ReportRA[] = [];
    public reportsActive: ReportRA[] = [];
    public reportsHistoricCopy: ReportRA[] = [];
    public reportsHistoric: ReportRA[] = [];

    public reportRAConfig: ConfigRA[] = [];
    public reportRAConfigCopy: ConfigRA[] = [];

    public typeReportOptions: SelectOption[] = TYPE_AUTOMATIC_REPORT;
    public raOptions: any[] = RA_OPTIONS;
    public dueTime: number = 500;


    public selectOptionsInsitutions: SelectOption[];

    public currentTab: number = 0;

    public medicineRASelectOptions: SelectOption[];

    public actionsActive: CardActionRA[] = [
        {
            id: '0',
            name: 'Pasar a Historico',
            iconName: 'done',
            callback: (event: any, id: any) => this.passToHistoric(id),
        },
        {
            id: '1',
            name: 'Enviar orden',
            iconName: 'arrow_outward',
            callback: (event: any, id: any) => this.sendOrder(id),
        },
    ];

    public actionsHistoric: CardActionRA[] = [
        {
            id: '0',
            name: 'Pasar a Activo',
            iconName: 'done',
            callback: (event: any, id: any) => this.passToActive(id),
        },
        {
            id: '1',
            name: 'Enviar orden',
            iconName: 'arrow_outward',
            callback: (event: any, id: any) => this.sendOrder(id),
        },
    ];

    public actionsRaConfig: CardActionRA[] = [
        {
            id: '0',
            name: 'Guardar cambios',
            iconName: 'done',
            callback: (event: any, id: any) => this.modifyConfig(id),
        },
        {
            id: '1',
            name: 'Descartar cambios',
            iconName: 'close',
            callback: (event: any, id: any) => this.discardChanges(id),
        },
    ];

    constructor(
        private router: Router,
        private reportService: ReportsService,
        private formBuilder: FormBuilder,
        private calls: CallsService,
        private dateService: DateService,
        private authService: AuthService,
        private modalService: ModalService,
        private institutionService: InstitutionService,
        websocket: WebSocketsService
    ) {
        websocket.broadcastMessage.subscribe((data) => {
            if (data.name == 'ra.report.status.updated') {
                this.getRaData();
            }
        });
        this.form = this.formBuilder.group({
            filterReports: [],
            typeReport: [0],
            showAll: [],
            reportDate: [new Date()],
            raOption: [],
            medicineSelected: [],
            linked: [],
            modified: [],
        });
    }
    ngOnInit(): void {
        this.getReportConfig();
        this.getRaData();
        this.listenerFilterInfo();
        this.getDataForTheSelects();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

               // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }
          });

   
    }

    
    listenerFilterInfo(): void {



        // Subscribe to the value changes of 'filterReports' control
        this.form.controls['filterReports'].valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((value) => {
                this.filterReports(value);
            });

        // Subscribe to the value changes of 'linked' control
        this.form.controls['linked'].valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((value) => {
                this.handleLinkedValueChanges(value);
            });

        // Subscribe to the value changes of 'modified' control
        this.form.controls['modified'].valueChanges
            .pipe(debounceTime(this.dueTime))
            .subscribe((value) => {
                this.handleModifiedValueChanges(value);
            });
    }

    filterReports(value: any): void {
        this.reportsActive = filter(this.reportsActiveCopy, value);
        this.reportsHistoric = filter(this.reportsHistoricCopy, value);
        this.reportRAConfig = filter(this.reportRAConfigCopy, value);
    }

    onTabChanged(event: any): void {
        this.currentTab = event.index;
    }

    handleLinkedValueChanges(value: any): void {
        if (value) {
            this.reportRAConfig = this.reportRAConfigCopy.filter(
                (report) => report.RaStatus === 0 || report.RaStatus === 2
            );
        } else {
            this.reportRAConfig = this.reportRAConfigCopy;
        }
    }

    handleModifiedValueChanges(value: any): void {
        if (value) {
            this.reportRAConfig = this.reportRAConfigCopy.filter(
                (report) => report.RaStatus === 1 || report.RaStatus === 2
            );
        } else {
            this.reportRAConfig = this.reportRAConfigCopy;
        }
    }

    createFormGroupMedicinesRA(): void {
        for (const data of this.reportRAConfig) {
            const formGroup = this.formBuilder.group({
                raStatus: [data.RaStatus],
                medTranslate: [data.MedTranslate],
            });
            this.formsGroups.push(formGroup);
        }
    }

    passToHistoric(id: number): void {
        const passToHistoric = this.reportsActive.filter(
            (report) => report.Id == id.toString()
        )[0];
        const currentDate = new Date().toISOString();

        passToHistoric.Ready = true;
        passToHistoric.CreationDate = currentDate;
        passToHistoric.ReviewDate = currentDate;
        this.calls
            .updateRaReportStatus(JSON.stringify(passToHistoric))
            .subscribe(() => {
                this.getRaData();
            });
    }

    passToActive(id: number): void {
        const passToActive = this.reportsHistoric.filter(
            (report) => report.Id == id.toString()
        )[0];

        const currentDate = new Date().toISOString();

        passToActive.Ready = false;
        passToActive.CreationDate = currentDate;
        passToActive.ReviewDate = currentDate;

        this.calls
            .updateRaReportStatus(JSON.stringify(passToActive))
            .subscribe(() => {
                this.getRaData();
            });
    }

    getReportConfig(): void {
        this.calls.getRaConfig().subscribe((res) => {
            const { payload } = res;
            this.reportRAConfig = payload.map((res: any) => res);
            this.reportRAConfigCopy = this.reportRAConfig;

            this.medicineRASelectOptions = this.reportRAConfig.map((ra) => ({
                value: ra.MedicineId,
                label: ra.Name,
            }));

            this.initialFormValues = this.reportRAConfig.map((config) => {
                return {
                    raStatus: config.RaStatus,
                    medTranslate: config.MedTranslate,
                };
            });

            if (this.reportRAConfig.length > 0) {
                this.createFormGroupMedicinesRA();
            }
        });
    }

    sendOrder(id: number): void {
        this.modalService.openModalCallbackCustomComponent(
            SendOrderComponent,
            (data: any, id: any) => this.sendOrdertoBack(data, id),
            id,
            'Exportar pedido'
        );
    }

    sendOrdertoBack(checkboxInfo: any, id: any): void {
        const report = this.getReportInfo(id);

        if (report) {
            const raOrder: ReportInsertRAOrder = {
                InstitutionId: report.InstitutionId,
                ReportDate: report.ReportDate,
                TipoPedido: checkboxInfo ? -1 : 2, // If the checkbox (checked the export to distirbutor) => type -1, else type => 2',
                IdProv: '0465', // TODO: Search where find the idProv, probably in the loggin info "0326 (data.service..farmatic)
                insertRaVentasOffDTO: { RaReportId: id, NombreVend: 'P-RA' },
            };
            this.calls.insertRaOrder(JSON.stringify(raOrder)).subscribe((res) => {
                //TODO: When the system is uploaded in production, it will be necessary
                // to implement what to do with this information returned by the back
            });
        }
    }

    getRaData(): void {
        const { reportDate } = this.form.getRawValue();
        const cutDate = this.dateService.formatDateToString(reportDate);

        const jssActive = JSON.stringify({ onlyReady: false, cutDate: cutDate });
        const jssHistoric = JSON.stringify({ onlyReady: true, cutDate: cutDate });

   

        // Collect new information from the RA report of type active
        this.calls.getRaReports(jssActive).subscribe((res) => {
            const { payload } = res;
            this.reportsActive = payload.map((report: any) => report);
            this.reportsActiveCopy = this.reportsActive;
        });

        // Collect new information from the RA report of type historic
        this.calls.getRaReports(jssHistoric).subscribe((res) => {
            const { payload } = res;
            this.reportsHistoric = payload.map((report: any) => report);
            this.reportsHistoricCopy = this.reportsHistoric;
        });
    }

    
    toggleCard(cardId: string | null, type: string): void {
        // Information about the card that is selected, depending
        // on each of the types that exist in this report.
        switch (type) {
            case 'active':
                this.expandedCardIdActive =
                    this.expandedCardIdActive === cardId ? null : cardId;
                break;
            case 'historic':
                this.expandedCardIdHistoric =
                    this.expandedCardIdHistoric === cardId ? null : cardId;
                break;
            case 'config':
                this.expandedCardIdConfig =
                    this.expandedCardIdConfig === cardId ? null : cardId;
                break;
        }
    }

    getTextDescriptionRaReport(
        report: ConfigRA,
        options: SelectOption[]
    ): string {
        return this.reportService.getTextDescriptionRaReport(report, options);
    }

    modifyConfig(id: number): void {
        const index = this.getIndexForm(id);

        const report = this.reportRAConfig.find((ra) => parseInt(ra.DrugId) == id);
        if (report) {
            const formGroup = this.formsGroups[index];
            const { raStatus, medTranslate } = formGroup.value;
            report.RaStatus = raStatus;
            if (report.RaStatus == 2) {
                report.MedTranslate = medTranslate;
            }
        }

        if (report) {
            this.calls.updateRaConfig(JSON.stringify(report)).subscribe(() => {
                this.getReportConfig();
            });
        }
    }

    discardChanges(id: number): void {
        this.expandedCardIdConfig = id.toString();

        const index = this.getIndexForm(id);

        this.formsGroups[index].patchValue(this.initialFormValues[index]);
    }

    handlePreviewCLickCount(): void {
        console.log('preview');

        const rp = JSON.stringify({ LocaleId: '3082', InstitutionId: null });
        const reportCount = reportDataRACount;
        reportCount.data.rp = rp;
        this.reportService.handlePreviewClick(this.router, reportCount);
    }

    handlePreviewClick(): void {
        const report =
            this.currentTab == 0
                ? this.getReportActiveInfo()
                : this.getReportHistoricInfo();

        if (report) {
            const rp = this.getRInfo(report);

            report.ReviewDate = new Date().toISOString();
            report.ReviewUser = this.authService.getLoggedUserName();
            this.reportDataRA.data.rp = rp;
            this.setInfoRP(true);
            this.reportService.handlePreviewClick(this.router, this.reportDataRA);
            this.calls.updateRaReportStatus(JSON.stringify(report)).subscribe(() => {
                this.getRaData();
            });
        }
    }

    handlePrintClick(): void {
        const report =
            this.currentTab == 0
                ? this.getReportActiveInfo()
                : this.getReportHistoricInfo();

        if (report) {
            const rp = this.getRInfo(report);

            report.PrintDate = new Date().toISOString();
            report.PrintUser = this.authService.getLoggedUserName();
            this.reportDataRA.dataRenderReport.rp = rp;
            this.setInfoRP();
            this.reportService.handlePrintClick(this.reportDataRA);
            this.calls.updateRaReportStatus(JSON.stringify(report)).subscribe(() => {
                this.getRaData();
            });
        }
    }

    // In the PDF and excel  downloads: changes the printdate & print user too
    handlePdfClick(): void {
        const report =
            this.currentTab == 0
                ? this.getReportActiveInfo()
                : this.getReportHistoricInfo();
        if (report) {
            const rp = this.getRInfo(report);
            report.PrintDate = new Date().toISOString();
            report.PrintUser = this.authService.getLoggedUserName();
            this.setInfoRP();
            this.reportDataRA.dataDownloadPDF.rp = rp;
            this.reportService.handlePdfClick(this.reportDataRA);
        }
    }

    handleExcelClick(): void {
        const report =
            this.currentTab == 0
                ? this.getReportActiveInfo()
                : this.getReportHistoricInfo();
        if (report) {
            const rp = this.getRInfo(report);
            report.PrintDate = new Date().toISOString();
            report.PrintUser = this.authService.getLoggedUserName();
            this.reportDataRA.dataDownloadExcel.rp = rp;
            this.setInfoRP();
            this.reportService.handleExcelClick(this.reportDataRA);
        }
    }

    getReportActiveInfo(): ReportRA | undefined {
        return this.reportsActive.find(
            (active) => this.expandedCardIdActive == active.Id
        );
    }

    getReportInfo(id: number): ReportRA | undefined {
        return this.reportsActive
            .concat(this.reportsHistoric)
            .find((ra) => id.toString() == ra.Id);
    }

    getReportHistoricInfo(): ReportRA | undefined {
        return this.reportsHistoric.find(
            (active) => this.expandedCardIdHistoric == active.Id
        );
    }

    getIndexForm(id: number): number {
        return (
            this.reportRAConfig.findIndex((ra) => parseInt(ra.DrugId) == id) || 0
        );
    }

    getRInfo(report: ReportRA): string {
        return JSON.stringify({
            LocaleId: '3082',
            ReportDate: report.CreationDate,
            InstitutionId: report.InstitutionId,
            ReportType: this.form.controls['typeReport'].value,
        });
    }

    setInfoRP(isPreview: boolean = false): void {
        isPreview ? this.reportDataRA.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
        const now = moment();

        this.reportDataRA.dataDownloadPDF.dn = this.reportDataRA.dataRenderReport.dn = `Reposicion_automatica_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataRA.dataDownloadExcel.dn = `Reposicion_automatica_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
