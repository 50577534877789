<div class="content" [ngClass]="{'with-footer': footerOnScreen}">
  <div class="chat-box" *ngIf="open" @inOutChatbox>
    <button class="clear" (click)="clearChat()" *ngIf="opened" @inOutMessages>
      <mat-icon>cleaning_services</mat-icon>
    </button>

    <div #messagesContainer class="messages" *ngIf="opened" @inOutMessages>
      <div class="message" 
        [ngClass]="message.sender"
        *ngFor="let message of messages"
      >
        <div class="avatar">
          <img 
            class="assistant"
            src="assets/images/chatbot-icon.png" 
            alt="Chatbot" 
            *ngIf="message.sender === 'assistant'"
          >
          <mat-icon class="human" *ngIf="message.sender === 'human'">person</mat-icon>
        </div>
        <ng-container *ngIf="message.text.trim() !== ''">
          <div class="text" [innerHTML]="message.text"></div>
        </ng-container>
        <ng-container *ngIf="message.text.trim() === ''">
          <div class="text">
            <img 
              class="loading" 
              src="assets/gifs/ai-loading.gif" 
            />
          </div>
        </ng-container> 
      </div>

      <ul class="sample-questions" *ngIf="messages.length === 1">
        <li class="question" *ngFor="let item of sampleQuestions" (click)="askSampleQuestion(item.question)">
          <span>{{item.title || item.question}}</span>
          <!-- <span class="subheader">{{item.subheader}}</span> -->
        </li>
      </ul>
    </div>

    <div class="input" *ngIf="opened && !errored" @inOutInput>
      <input 
        #input
        type="text" 
        placeholder="Escribe tu consulta" 
        (keyup.enter)="sendMessage()"
        [(ngModel)]="userInput"
      >
      <button (click)="sendMessage()" [disabled]="this.userInput.trim() === ''">
        <mat-icon>send</mat-icon>
      </button>
    </div>

    <div class="error" *ngIf="errored" @inOutInput>
      <mat-icon>error_outline</mat-icon>
      <span>Vaya, parece que algo no va bien. Por favor, inténtalo de nuevo más tarde.</span>
    </div>
  </div>

  <div class="toggle-button" (click)="toggleChatbox()">
    <div class="icon">
      <img src="assets/images/chatbot-icon.png" alt="Chatbot" *ngIf="!open" @inOutToggleIcon>
      <mat-icon *ngIf="open" @inOutToggleIcon>close</mat-icon>
    </div>
  </div>
</div>