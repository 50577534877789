
<div class="subform-array">
  <div class="container-calendar">
    <app-calendar-summary
      [formArray]="formArray"
      [config]="formConfig.calendarConfig"
      (dateSelected)="updateDate($event)"
      (viewChanges)="filterItems($event)">
    </app-calendar-summary>
  </div>
  <div class="controls-columns">
    <ng-container *ngFor="let item of formArray.controls; let i = index">
      <div class="item"
        class="item subform-array__subform"
        *ngIf="applyFilter(item)"
        [style.order]="itemPositions[i]"
        [style.display]="itemVisibility[i] ? 'flex' : 'none'">

        <ng-container *ngFor="let section of forms[i].schema; let i = index;">
          <ng-container [ngSwitch]="section.type">
            <!-- Default item -->
            <ng-container *ngSwitchDefault>
              <app-date-info
                class="field"
                [class]="section.size || ''"
                [ngClass]="section.customClass"
                [ngStyle]="section.customStyle"
                [label]="section.label"
                [hideLabel]="section.hideLabel"
                [fieldControl]="section.fieldControl | asFormControl"
                [readOnly]="section.readOnly">
              </app-date-info>
            </ng-container>
          </ng-container>
        </ng-container>

        <button class="delete-button"
          mat-icon-button
          color="warn"
          *ngIf="canDelete && !fieldControl.disabled"
          (click)="delete(i)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
