import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { RoleManagerService } from '@services/role-manager.service';
import { WebSocketsService } from '@services/web-sockets.service';

import { sidebarConfigItem } from '@interfaces/sidebar.interface';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    _expanded: boolean = true;
    get isExpanded(): boolean {
        return this._expanded;
    }
    @Input() set isExpanded(value: boolean) {
        this._expanded = value;
        this.isExpandedChange.emit(value);
    }
    @Output() isExpandedChange = new EventEmitter<boolean>();
    @Input() config: sidebarConfigItem[] = [];

    showSelect: boolean = false;

    hideNotifications = false;
    notifications: Array<{title: string, lines: Array<any>}> = [];

    contrastStatus: boolean = false;

    helpLink: string = '';
    manualLink: string = '';

    constructor(
        private roleManager: RoleManagerService,
        private router: Router,
        private webSockets: WebSocketsService,
        public auth: AuthService
    ) {

        if ( this.roleManager.isQuantum() ) {
            this.helpLink = `https://ayuda.globaleye.es/login?system=global&token=${auth.getToken()}`;
            this.manualLink = 'https://api.globaleye.es/resources/content/QuantumGlobal.pdf';
        } else if ( this.roleManager.isPharma() ) {
            this.helpLink = `https://ayuda.globaleye.es/login?system=pharma&token=${auth.getToken()}`;
            this.manualLink = 'https://api.globaleye.es/resources/content/QuantumGlobal.pdf';
        }

        if ( this.router.url.includes('/reports/viewer') ) {
            this.hideNotifications = true;
        }

        webSockets.message.subscribe((data) => {
            this.notifications = [];
            Object.keys(data).forEach(key => {
                const value = data[key];
                if (value instanceof Array) {
                    const noti: {title: string, lines: Array<string>} = {title: key, lines: []};
                    value.forEach(line => {
                        noti.lines.push(line);
                    });
                    this.notifications.push(noti);
                }
            });

            if (this.notifications.length > 0) {
                setTimeout(() => {
                    this.hideNotifications = true;
                }, 3000);
            }
        });

        const contrast = window.localStorage.getItem('contrast');

        if ( contrast === '1' ) {
            this.contrastStatus = true;
            this.setContrast(this.contrastStatus);
        }
    }

    ngOnInit(): void {
        this.showSelect = this.roleManager.showHeaderSelectInstitution();
    }

    isNotiOnlyTitle(noti: any) {
        return noti.lines[0].display == undefined;
    }

    navigateTo(action: string) {
        // Get query params
        const queryParams = action.split('?')[1];
        // Translate query params to object
        const params = queryParams ? queryParams.split('&').reduce((acc, param) => {
            const [key, value] = param.split('=');
            // @ts-ignore
            acc[key] = value;
            return acc;
        }, {}) : {};

        // Remove query params from action
        action = action.split('?')[0];

        this.router.navigate(["/global-eye/welcome"]).then(() => this.router.navigate([action], queryParams ? {queryParams: params} : {}));
    }

    testPerm(item: sidebarConfigItem) {
        if (typeof(item.hasPermission) == 'function') {
            return item.hasPermission().length > 0; // Puede leer o escribir así que se debe mostrar
        }

        return item.hasPermission.length > 0; // Puede leer o escribir así que se debe mostrar
    }

    onContrastChange() {
        this.setContrast(this.contrastStatus);
        window.localStorage.setItem('contrast', this.contrastStatus ? '1' : '0');
    }

    setContrast(mode: boolean) {
        var root = document.getElementsByTagName( 'html' )[0];

        mode
            ? root.classList.add('contrast-mode')
            : root.classList.remove('contrast-mode');
    }
}
