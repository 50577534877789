import { CallsService } from '@services/api/calls.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CardAction } from '@interfaces/card-actions';
import { Patient } from '@interfaces/patient/patient.interface';
import { FilterService } from '@services/filter/filter.service';
import { LoadingService } from '@services/loading.service';
import {
    actionsPatient,
    alertInfo,
    patientFilterSelect,
    patientFilterSelected,
    routePatients,
} from '@services/mocks/patient';
import { ModalService } from '@services/modal.service';
import { PatientsService } from '@services/patients.service';
import { debounceTime, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PaginationService } from '@services/pagination/pagination.service';
import { RoleManagerService } from '@services/role-manager.service';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionService } from '@services/institution.service';
import { InstitutionSelectAPP } from '@interfaces/institution/institution.interface';
import { MatSelectChange } from '@angular/material/select';
import { PAGE_SIZE_OPTIONS } from '@constants/options';

import { ListComponent } from '@json/src/app/components/base/list/list.component';
import { ColorLegendItem } from '@interfaces/color-legend-item.interface';
import { ColorLegendItemType } from '@json/src/app/enums/color-legend-item-type';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@enums/PermissionType';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss'],
})
export class CardsComponent extends ListComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('cards', { static: true }) cards: ElementRef;

    public route: string = routePatients;
    public actions: CardAction[] = actionsPatient;

    public patients: Patient[] = [];
    public currentData: Patient[] = [];
    public onCurrentDataUpdate: (data: Patient[]) => void;

    public pageIndex: number = 0;
    public pageSize: number = 15;
    public lowValue: number = 0;
    public highValue: number = this.pageSize;
    public pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

    private subs: Subscription[] = [];
    public currentFilter: string = '';
    public currentSearch: string = '';
    public loading: boolean = true;

    public isToggled: boolean = false;
    public msgNotFound: string = 'No hay pacientes que coincidan con la búsqueda';
    public inputLabel: string = 'Filtros';

    public inputSearchLabel: string = 'Búsqueda residentes';
    public inputFilterLabel: string = 'Filtro medicamentos';
    public alertString = alertInfo;

    public dueTime: number = 1000;
    public patientFilters = patientFilterSelect;
    public selectedFilters = patientFilterSelected;

    public idDeletePatient: number;
    public currentPatientsData: string;

    public formFilterFront: FormGroup = new FormGroup({
        search: new FormControl(''),
        select: new FormControl(this.selectedFilters),
        filter: new FormControl(''),
    });

    public institutionOptions: SelectOption[] = [];
    public showSelectInstitution: boolean = false;
    public selectedInstitution: InstitutionSelectAPP;

    public canNavigate: boolean = Utils.hasPermission(Permission.PATIENTS_GENERAL)().includes(PermissionType.READ);

    colorLegendItems: ColorLegendItem[] = [
        {
            type: ColorLegendItemType.Icon,
            description: 'Alertas',
            color: '',
            data: {
                icon: 'info',
                iconStyle: {'color': '#ba1a1a'}
            }
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'Ingreso hospitalario',
            color: '',
            data: {
                icon: 'bookmark',
                iconStyle: {'color': '#e95353'}
            }
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'Autogestión',
            color: '',
            data: {
                icon: 'bookmark',
                iconStyle: {'color': '#daa520'}
            }
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'Vacaciones',
            color: '',
            data: {
                icon: 'bookmark',
                iconStyle: {'color': '#f87fff'}
            }
        },
        {
            type: ColorLegendItemType.Icon,
            description: 'Baja',
            color: '',
            data: {
                icon: 'bookmark',
                iconStyle: {'color': 'black'}
            }
        }
    ]

    constructor(
        protected patientsService: PatientsService,
        protected filterService: FilterService,
        protected loadingService: LoadingService,
        protected modalService: ModalService,
        protected calls: CallsService,
        protected router: Router,
        protected paginationService: PaginationService,
        protected roleManager: RoleManagerService,
        protected institutionService: InstitutionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.calculatePageSizeOptions();

        this.institutionService.getInstitutions().then(() => {
            if (this.loading) {
                this.loadingService.start('Cargando residentes...');
                this.currentData = [];
            }
            this.paginator._intl.itemsPerPageLabel = `${this.inputLabel} por página`;

            if (this.roleManager.isQuantum()) {
                this.showSelectInstitution = false;
                // this.getPatients();
            }
            if (this.roleManager.isPharma()) {
                this.institutionOptions =
                    this.institutionService.institutionList.getValue();
                this.selectedInstitution = this.institutionOptions.find((option) => {
                    return (
                        option.value.id === this.institutionService.getCurrentInstitution()
                    );
                })?.value;
                this.showSelectInstitution = true;
                // this.getPatients();
            }

            // this.addFormFilterFrontListener();
        });
    }

    calculatePageSizeOptions() {
        if ( this.cards ) {
            const layoutWidth = this.cards.nativeElement.offsetWidth;
            const layoutHeight = this.cards.nativeElement.offsetHeight - (this.onListMode() ? 52 : 0);
            const gridGap = this.onListMode() ? 0 : 8  // Grid gap from style in px
            const minElementWidth = this.onListMode() ? layoutWidth : 240 // Min element width is 15rem and document font-size is 16px (15*16)
            
            const columns = Math.floor((layoutWidth - gridGap) / (minElementWidth + gridGap));
            const rows = Math.floor((layoutHeight - gridGap) / ((this.onListMode() ? 51 : 94) + gridGap));

            this.pageSize = columns * rows;
            this.highValue = this.pageSize;

            this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3, Math.ceil(this.pageSize * 4 / 100) * 100];
        }
    }

    onListMode() {
        return this.router.url.includes("/patients/list");
    }

    getPatients(): void {
        this.loadingService.start('Cargando residentes...');
        this.currentData = [];
        this.loading = true;
        this.patientsService.getPatients();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    getPageEvent(event: PageEvent): PageEvent {
        const previousPageIndex = this.pageIndex;
        const pageSizeChanged = event.pageSize !== this.pageSize;

        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        const { lowValue, highValue, pageIndex, pageSize } =
            this.paginationService.getPageValues(
                previousPageIndex,
                this.lowValue,
                this.highValue,
                this.pageIndex,
                this.pageSize,
                this.pageIndex,
                pageSizeChanged
            );

        this.pageIndex = pageIndex;
        this.lowValue = lowValue;
        this.highValue = highValue;
        this.pageSize = pageSize;

        return new PageEvent();
    }

    toggle(): void {
        this.isToggled = !this.isToggled;
        this.applyFiltersFront();
    }

    applyFiltersFront(): void {
        const filterValue = this.formFilterFront.value['search'];
        this.currentSearch = filterValue;
        const mutuas = this.filterService.getFilterMutuas(this.formFilterFront);
        const gender = this.filterService.getFilterGender(this.formFilterFront);
        const exitus = this.filterService.getFilterExitus(this.formFilterFront);
        const bloqVacation = this.filterService.getFilterBloqVacation(
            this.formFilterFront
        );
        const bloqHospitalization = this.filterService.getFilterBloqHospitalization(
            this.formFilterFront
        );
        const selfManaged = this.filterService.getFilterSelfManaged(this.formFilterFront);
        const none = this.filterService.getFilterNone(this.formFilterFront);

        this.currentData = this.filterPatients(
            filterValue,
            mutuas,
            gender,
            exitus,
            bloqVacation,
            bloqHospitalization,
            selfManaged,
            none
        );

        this.currentPatientsData = this.currentData
            .map((patient) => patient.id)
            .join(',');
    }

    filterPatients(
        filterValue: string,
        mutuas: boolean,
        genderFilter: string,
        exitusSelectedFilter: boolean,
        bloqVacationSelectedFilter: boolean,
        bloqHospitalizationSelectedFilter: boolean,
        selfManaged: boolean,
        none: boolean
    ): Patient[] {

        if (genderFilter == 'X') return [];

        const withMutua: Patient[] = this.patients.filter(p => mutuas && p.mm == 1 && p.expedition == null);
        const withExitus: Patient[] = this.patients.filter(p => exitusSelectedFilter && p.expedition != null);
        const witBlockHospital: Patient[] = this.patients.filter(p => bloqHospitalizationSelectedFilter && p.blq != undefined && p.blq == 2 && p.expedition == null);
        const withBlockVacation: Patient[] = this.patients.filter(p => bloqVacationSelectedFilter && p.blq != undefined && (p.blq == 1 || p.blq == 4) && p.expedition == null);
        const withSelfManaged: Patient[] = this.patients.filter(p => selfManaged && p.sm && p.expedition == null);

        let all = [...new Set([...withMutua, ...withExitus, ...witBlockHospital, ...withBlockVacation, ...withSelfManaged])];
        const rest = this.patients.filter(p => none && p.expedition == null && !all.includes(p));
        all.push(...rest);
        all = all.filter(p =>
            (genderFilter.trim().length == 0 || genderFilter == p.gender) &&
            (this.isToggled ? p.alert : true) &&
            (filterValue.trim().length == 0 ? true : Object.values(p).some((val) => filterValue.trim().length > 0 && val?.toString().toLowerCase().includes(filterValue.toLowerCase())))
        );

        return all;
    }
    // addFormFilterFrontListener(): void {
    //     this.formFilterFront.valueChanges
    //         .pipe(debounceTime(this.dueTime))
    //         .subscribe((res) => {
    //             this.currentFilter = res.filter;

    //             this.subs.push(
    //                 this.patientsService.patientList.subscribe((patients) => {
    //                     this.patients = patients.map((p) =>
    //                         this.patientsService.transformPatientAPItoApp(p)
    //                     );
    //                     this.currentData = [...this.patients];
    //                     this.applyFiltersFront();
    //                     if (this.onCurrentDataUpdate) this.onCurrentDataUpdate(this.currentData);
    //                 })
    //             );

    //             this.patientsService.getPatientsByFilter(this.currentFilter);
    //         });
    // }

    clearFiltersBack(): void {
        this.formFilterFront.get('filter')?.setValue('');
        // this.applyFiltersFront();
    }

    handleDelete(id: number): void {
        this.modalService.openModalCallback(() => {
            this.calls.deletePatient(Number(id), 0).subscribe({
                next: (data) => {
                    if (data.payload == -1) {
                        this.modalService.openModalInfo("No se puede eliminar porque se perderán sus prescripciones y el historial de consumo almacenado.", "Este paciente tiene prescripciones vinculadas.");
                    }
                    else {
                        this.calls.openSnack('Se ha eliminado el paciente correctamente');
                        this.modalService.reloadComponent(this.router);
                        this.getPatients();
                    }
                }
            });
        }, "¿Está seguro de que desea eliminar el paciente?", "Eliminar paciente", false)
    }

    onInstitutionChange(change: MatSelectChange): void {
        this.institutionService.selectInstitution(change.value);
        this.getPatients();
    }

    getPatientCardHeaderColor(patient: Patient) {
        let colorHeader: string = '';

        if (patient.expedition) return 'grey';
        if (patient.sm && patient.expedition == null) {
            colorHeader = 'autogestion'
        }
        if (patient.blq && patient.blq == 2 && patient.expedition == null) {
            colorHeader += ' ingreso-hospitalario'
        } 
        if (patient.blq && (patient.blq == 1 || patient.blq == 4) && patient.expedition == null) {
            colorHeader += ' vacaciones'
        }

        return colorHeader.length ? colorHeader.trim() : 'default';
    }

    isExitus(patient: Patient): boolean {
        return patient.expedition !== null;
    }

    /**
     * Check if the patient has incorrect data (currently only cip and private insurance)
     * @param patient 
     * @returns is incorrect data
     */
    incorrectData(patient: Patient): boolean {
        if ( !patient.privateInsurance && patient.cipaPattern ) {
            const cipaRegex = new RegExp(patient.cipaPattern);

            if ( patient.cip  ) {
                return !patient.cip.match(cipaRegex);
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    /**
     * Get the alert tooltip for the patient
     * @param patient 
     * @returns string with the alert tooltip
     */
    getAlertTooltip(patient: Patient): string {
        const incorrectData = this.incorrectData(patient);

        if ( patient.alert && incorrectData ) {
            return 'Datos incorrectos / Sin documentos';
        } else if ( patient.alert ) {
            return 'Sin documentos';
        } else if ( incorrectData ) {
            return 'Datos incorrectos';
        } else {
            return '';
        }
    }

    navigate(patient: Patient): void {
        if (this.canNavigate) {
            this.router.navigate([this.route + '/edit/' + patient.id]);
        }
    }
}
