import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';
import { MedicinesService } from '@services/medicines.service';

import { inOutFade } from '@json/src/app/animations/general';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';
import { MatAccordion } from '@angular/material/expansion';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';

import { SelectOption } from '@interfaces/input-select-option.interface';
import { ActiveIngredient } from '@interfaces/medicine/medicine.interface';

@Component({
  selector: 'app-patient-edit-notes-clinical',
  templateUrl: './notes-clinical.component.html',
  styleUrls: ['./notes-clinical.component.scss'],
  animations: [inOutFade]
})
export class NotesClinicalComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  @ViewChild('vitalSigns') vitalSigns: ElementRef;
  @ViewChild('clinicalAnalysis') clinicalAnalysis: ElementRef;
  @ViewChild('fragilityDependency') fragilityDependency: ElementRef;
  @ViewChild('testHaynes') testHaynes: ElementRef;
  @ViewChild('drivingSkill') drivingSkill: ElementRef;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  Utils = Utils;
  Permission = Permission;
  PermissionType = PermissionType;

  expandAll: boolean = true;
  compressAll: boolean = false;

  showInfo: any = {
    testhaynes: false,
    drivingskill: false
  }

  public activeIngredientsOptions: SelectOption[] = [];

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef,
    private medicinesService: MedicinesService,
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));

    this.medicinesService.getActiveIngredientsList().subscribe((res: ActiveIngredient[]) => {
      this.activeIngredientsOptions = res.map((item: ActiveIngredient) => {
          return { label: item.Nombre, value: item.Id }
      }).sort((a, b) => a.label.localeCompare(b.label));
  });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addNewItem($event: any, section: any) {
    $event.stopPropagation();

    let element: ElementRef;

    switch( section.childs[0].customType ) {
      case 'array-form-vitals': element = this.vitalSigns; break;
      case 'clinical-analysis-form': element = this.clinicalAnalysis; break;
      case 'fragility-dependency': element = this.fragilityDependency; break;
      case 'test-haynes': element = this.testHaynes; break;
      case 'driving-skill': element = this.drivingSkill; break;
    }

    // All this elements extends from ArraySubformComponent so we
    // can ignore ts error here
    // @ts-ignore
    element?.addOne();
  }

  openInfo($event: any, section: any) {
    $event.stopPropagation();

    if ( section.childs[0].customType == 'test-haynes') {
        this.showInfo.testhaynes = true;
    } else if (section.childs[0].customType == 'driving-skill'){
      this.showInfo.drivingskill = true;
    }

  }

  openCalendar($event: any, section: any) {
    $event.stopPropagation();

    let element: ElementRef;


    switch( section.childs[0].customType ) {
      case 'array-form-vitals': element = this.vitalSigns; break;
      case 'clinical-analysis-form': element = this.clinicalAnalysis; break;
      case 'fragility-dependency': element = this.fragilityDependency; break;
      case 'test-haynes': element = this.testHaynes; break;
      case 'driving-skill': element = this.drivingSkill; break;
    }

    // All this elements extends from ArraySubformComponent so we
    // can ignore ts error here
    // @ts-ignore
    element.showCalendar = !element.showCalendar;
  }
  hasCalendar(schema: FormItemSchemaInterface){

   
    //@ts-ignore
    return schema?.childs[0]?.customFieldData?.calendarConfig;
  }

  openExpandedAll(){
    this.schema.map(section=>{
      section.expanded = true
    });

    this.expandAll = false;
    this.compressAll = true;
    this.accordion.openAll()
  }
  closedExpandedAll(){
    this.schema.map(section=>{
      section.expanded = false
    });

    this.compressAll = false;
    this.expandAll = true;
    this.accordion.closeAll()
  }
}
