
<div class="report-custom-container">
  <mat-tab-group>
    <mat-tab label="Mapa CN">
      <div class="map-cn-wrapper">
        <p class="subtitle">Traductor de códigos</p>
        <app-form-field
          class="col-6"
          type="text"
          label="Código medicina (Mapa)"
          [options]="integrationOptions"
          [fieldControl]="formMapCN.controls['drugIdTP'] | asFormControl"
        >
        </app-form-field>
  
        <app-form-field
          class="col-6"
          type="text"
          [readOnly]="Utils.TRUE"
          label="Código medicina (Maestro)"
          [options]="integrationOptions"
          [fieldControl]="formMapCN.controls['drugIdMaster'] | asFormControl"
        >
        </app-form-field>
  
        <app-form-field
          class="col-6"
          type="text"
          [readOnly]="Utils.TRUE"
          label="Código medicina (Facturación)"
          [options]="integrationOptions"
          [fieldControl]="formMapCN.controls['drugIdInv'] | asFormControl"
        >
        </app-form-field>
  
          <app-table
          *ngIf="dataTableDrug && dataTableDrug.length > 0"
          [input]="dataTableDrug"
          [columns]="tableColumns"
          [pageSize]="5"
  
          tableType="default"
          positionFilters="static"
        >
        </app-table>
  
  
      </div>
    </mat-tab>
  
    <mat-tab label="Logs de terceros" class="paddingCero">
      <app-report-select
        [title]="'Logs de terceros'"
        (previewClicked)="handlePreviewClick()"
        (printClicked)="handlePrintClick()"
        (pdfClicked)="handlePdfClick()"
        (excelClicked)="handleExcelClick()"
      >
        <div slot="content"  class="container-options">
        <p class="subtitle">Selección del LOG</p>

          <app-form-field
            class="col-6"
            type="select"
            label="Integración"
            [options]="integrationOptions"
            [fieldControl]="
              formThirdPartiesLogs.controls['integration'] | asFormControl
            "
          >
          </app-form-field>
          <app-form-field
            class="col-6"
            type="select"
            label="Grupo de Log"
            [options]="groupOptions"
            [fieldControl]="
              formThirdPartiesLogs.controls['group'] | asFormControl
            "
          >
          </app-form-field>
  
          <app-form-field
            class="col-6"
            type="select"
            label="LOG"
            [options]="logOptions"
            [fieldControl]="formThirdPartiesLogs.controls['log'] | asFormControl"
          >
          </app-form-field>
  
          <p class="subtitle">Centro</p>
  
          <app-form-field
            class="col-6"
            type="select"
            label="Centro"
            [fieldControl]="
              formThirdPartiesLogs.controls['institution'] | asFormControl
            "
          >
          </app-form-field>
          <p class="subtitle">Intervalo</p>
          <app-form-field
            class="col-6"
            type="radio"
            label=""
            [fieldControl]="
              formThirdPartiesLogs.controls['interval'] | asFormControl
            "
            [options]="radioOptions"
          >
          </app-form-field>
  
          <div *ngIf="formThirdPartiesLogs.controls['interval'].value == 1">
            <app-form-field
              class="col-6"
              type="date"
              label="Desde"
              [fieldControl]="
                formThirdPartiesLogs.controls['startDate'] | asFormControl
              "
            >
            </app-form-field>
  
            <app-form-field
              class="col-6"
              type="date"
              label="Hasta"
              [fieldControl]="
                formThirdPartiesLogs.controls['endDate'] | asFormControl
              "
            >
            </app-form-field>
          </div>
        </div>
      </app-report-select>
    </mat-tab>
  </mat-tab-group>
</div>



