export const errorBlocks = {
    collision: {
        title: 'Colisión de bloqueos',
        message: `El bloqueo que acaba de modificar colisiona con otros bloqueos existentes. \n
      Debe modificar las fechas para que los bloqueos sean consecutivos y no se superpongan.`,
    },
    notValidBlock: {
        title: 'Error en los bloqueos',
        message: `Los campos inicio y razón de un bloqueo son requeridos.
        La fecha de inicio del bloqueo no puede ser anterior al dia actual.
        Si has elegido como razón de bloqueo: Derivación a otro centro, tienes que añadir el centro derivado
        Si has elegido como razon de bloqueo: Vacaciones, se debe especificar la fecha de fin del bloqueo`,
    },
    blockNotUnlocked: {
        title: 'Bloqueos sin desbloqueo',
        message: `Hay bloqueos que todavía no han sido desbloqueados. \n
            Para generar un nuevo bloqueo, todos los bloqueos tienen que estar desbloqueados.`,
    },
};
