import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, Input, ViewChildren, QueryList, ViewChild, Output, EventEmitter } from '@angular/core';


import { MedicineEditComponent } from '../medicine-edit.component';
import { ActivatedRoute } from '@angular/router';
import { MedicinesService } from '@services/medicines.service';
import { InteractionService } from '@services/medicines/interaction.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CallsService } from '@services/api/calls.service';
import { FormService } from '@services/form.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { EpigraphService } from '@services/medicines/epigraph.service';
import { Location } from '@angular/common';
import { MedicineAPI, MedicineEditLinqData } from '@interfaces/medicine/medicine.interface';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';

@Component({
    selector: 'app-medicine-edit-pharmacy',
    templateUrl: './pharmacy.component.html',
    styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit, OnDestroy {

    @Input() farmacias: Array<MedicineEditLinqData> = [];
    @Input() medicineList: Array<MedicineAPI>;
    @Input() tiposInventario: any;

    @Input() formChanged: boolean;

    @Output() onFormChanged = new EventEmitter<boolean>();

    @ViewChild(MatAccordion) accordion: MatAccordion;


    expandAll: boolean = true;
    compressAll: boolean = false;

    @ViewChildren("options") options: QueryList<MatOption>;

    constructor(
        private medicineService: MedicinesService,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }


    selectAll(attr: string) {
        this.options.filter(o => o._getHostElement().getAttribute("opt-id") == attr).forEach(o => o.select());
    }

    deselectAll(attr: string) {
        this.options.filter(o => o._getHostElement().getAttribute("opt-id") == attr).forEach(o => o.deselect());
    }

    blockInstitution(farmacia: MedicineEditLinqData, evt: MatSelectChange) {
        farmacia.institutionLinks.forEach(f => f.Exclude = false);

        evt.value.forEach((v: number) => {
            const inst = farmacia.institutionLinks.find(f => f.InstitutionId == v);
            if (inst != undefined) {
                inst.Exclude = true;
            }
        });
    }

    getBlockedInstitution(farmacia: MedicineEditLinqData) {
        return farmacia.institutionLinks.filter(f => f.Exclude).map(f => f.InstitutionId);
    }

    async getMedicineList() {
        if (this.medicineList == undefined) {
            if (this.medicineService._medicineList == undefined || this.medicineService._medicineList.length == 0) {
                await this.medicineService.getMedicines();
            }
            this.medicineList = this.medicineService._medicineList;
            this.medicineList.unshift({
                id: null,
                name: ''
            } as any)
        }

        return this.medicineList;
    }

    keyPressHandler(event: KeyboardEvent) {
        return new RegExp('^[0-9]{0,3}$').test(event.key);
    }
    openExpandedAll() {

        this.expandAll = false;
        this.compressAll = true;
        this.accordion.openAll()
    }
    closedExpandedAll() {
        this.compressAll = false;
        this.expandAll = true;
        this.accordion.closeAll()
    }

    hasPermission() {
        return Utils.hasPermission(Permission.VADEMECUM_PHARMACY)().includes(PermissionType.WRITE);
    }

    setFormChanged(status: boolean) {
        this.formChanged = status;
        this.onFormChanged.emit(status);
    }
}
