import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BaseComponent as ListBaseComponent } from './base.component';
import { UserComponent } from '../user/user.component';

import { CallsService } from '@services/api/calls.service';
import { ModalService } from '@services/modal.service';
import { LoadingService } from '@services/loading.service';

import { User } from '@interfaces/user/user.interface';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';

import { UserRole } from '@json/src/app/enums/user-role';

import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { RoleManagerService } from '@services/role-manager.service';
import { PHARMA_ROLES, QUANTUM_ROLES } from '@constants/roles';

import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    Utils = Utils;
    Permission = Permission;
    PermissionType = PermissionType;

    route: string = '/global-eye/admin/users';

    totalElements: number | undefined = undefined;
    currentElementIndex: number | undefined = undefined;

    // User list
    users: User[] = [];
    filteredUsers: User[] = [];
    filteredResults: any[] = [];
    userFilters: FilterSelect[] = [];

    nextUser?: number = undefined;
    prevUser?: number = undefined;

    resultsRefreshed: boolean = false;

    listRef: ListBaseComponent;
    userRef: UserComponent;

    constructor(
        private router: Router,
        private calls: CallsService,
        private modalService: ModalService,
        private loading: LoadingService,
        private cdr: ChangeDetectorRef,
        private roleManager: RoleManagerService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle(`Admin`);
        this.gaService.pageView(`${window.location.href}`, `Admin`);

        this.getUsers();

        if ( this.roleManager.isPharma() ) {
            this.userFilters = [
                {
                    name: 'Rol',
                    filter: PHARMA_ROLES.map((role) => { return { id: role.label.toString(), value: role.label } })
                }
            ]
        } else {
            this.userFilters = [
                {
                    name: 'Rol',
                    filter: QUANTUM_ROLES.map((role) => { return { id: role.label.toString(), value: role.label } })
                }
            ]
        }
    }

    async getUsers() {
        return new Promise((resolve, reject) => {
            this.calls.getUserDisplayList().subscribe({
                next: (data) => {
                    this.users = [...data];
                    this.filteredUsers = this.users;
    
                    if ( this.listRef ) {
                        this.listRef.currentData = this.filteredUsers;
                        this.listRef.currentSortMode = 'desc';
                        this.listRef.sortBy('nam');
                    }

                    resolve(true);
                },
                error: (err) => {
                    console.log(err);
                    reject(err);
                }
            });
        });
    }

    onActivate(componentRef: any) {
        if (componentRef instanceof UserComponent) {
            this.resultsRefreshed = false;
            this.userRef = componentRef;

            if (this.userRef.user == null) {
                componentRef.onUserId = (id) => {
                    this.updateControlButtons(id, this.filteredUsers);
                };
                componentRef.onUserCreated = (id) => {
                    this.getUsers().then(() => {
                        this.updateControlButtons(id, this.filteredUsers);
                    });
                }
            }
            else {
                this.updateControlButtons(this.userRef.user?.id?.toString(), this.filteredUsers);
            }

        } else if (componentRef instanceof ListBaseComponent) {
            this.listRef = componentRef;
            this.listRef.currentData = this.filteredUsers;
            this.listRef.currentSortMode = 'desc';
            this.listRef.sortBy('nam');
            this.listRef.onDelete = (id: number) => {
                this.deleteUser(id);
            }
        }
    }

    onOperationBarAction($event: MatButtonToggleChange) {
        switch ($event.value) {
            case 'first': this.gotToByIndex(0); break;
            case 'last': this.gotToByIndex(this.filteredUsers.length - 1); break;
            case 'next': this.siguiente(); break;
            case 'prev': this.anterior(); break;
            case 'delete': this.deleteUser(); break;
            case 'edit':
                this.router.navigate(["/global-eye/admin/users/new"]);
                break;
            default: this.changeView($event);
        }
    }

    changeView(event: MatButtonToggleChange): void {
        this.modalService.changeView(event, this.route, '');
    }

    anterior(evt?: MatButtonToggleChange) {
        if ( this.prevUser ) {
            const params = new URLSearchParams(location.search);
            let queryParams: any = {};
            params.forEach((value, key) => {
                if ( !key.includes('temp') ) {
                    queryParams[key] = value;
                }
            });

            this.userRef?.ngOnDestroy();
            this.router.navigate([this.route, 'edit', this.prevUser], {
                queryParams: queryParams,
                state: {
                    ignore: false
                }
            }).then(() => this.userRef?.ngOnInit())

            if (evt != undefined) evt.source.checked = false;
        } else {
            this.router.navigate(['/global-eye/admin/users/']);
        }
    }

    siguiente(evt?: MatButtonToggleChange) {
        if ( this.nextUser ) {
            const params = new URLSearchParams(location.search);
            let queryParams: any = {};
            params.forEach((value, key) => {
                if ( !key.includes('temp') ) {
                    queryParams[key] = value;
                }
            });

            this.userRef?.ngOnDestroy();
            this.router.navigate([this.route, 'edit', this.nextUser], {
                queryParams: queryParams,
                state: {
                    ignore: false
                }
            }).then(() => this.userRef?.ngOnInit())

            if (evt != undefined) evt.source.checked = false;
        } else {
            this.router.navigate(['/global-eye/admin/users/']);
        }
    }

    gotToByIndex(index: number, evt?: MatButtonToggleChange) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            if ( !key.includes('temp') ) {
                queryParams[key] = value;
            }
        });

        this.userRef?.ngOnDestroy();
        this.router.navigate([this.route, 'edit', this.filteredUsers[index].id], {
            queryParams: queryParams,
            state: {
                ignore: false
            }
        }).then(() => this.userRef?.ngOnInit())

        if (evt != undefined) evt.source.checked = false;
    }

    onFilterResultSelected($event: any) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            if ( !key.includes('temp') ) {
                queryParams[key] = value;
            }
        });

        this.userRef?.ngOnDestroy();
        this.router.navigate([`${this.route}/edit/${$event}`], {
            queryParams: queryParams,
            state: {
                ignore: false
            }
        }).then(() => this.userRef?.ngOnInit())
    }

    deleteUser(id?: number) {
        let u: number | undefined = undefined;

        if (id !== undefined) {
            u = id;
        } else if (this.userRef?.user?.id !== undefined) {
            u = this.userRef?.user?.id;
        }

        if ( u !== undefined ) {
            this.modalService.openModalCallback(() => {
                this.loading.start('Eliminando');
                // @ts-ignore
                this.calls.deleteUser(u).subscribe({
                    next: (data) => {
                        if ( this.onListPageListMode() ) {
                            this.router.navigate(['/global-eye/admin/users/list']);
                        } else {
                            this.router.navigate(['/global-eye/admin/users']);
                        }
                    },
                    error: (err) => console.error(err),
                    complete: () => {
                        this.getUsers();
                        this.loading.stop()
                    }
                });
            }, "¿Está seguro de que desea eliminar el usuario?", "Eliminar usuario", false)
        }
    }

    onListPage() {
        return this.router.url.includes("/users/cards") || this.router.url.includes("/users/list");
    }
    onListPageCardsMode() {
        return this.router.url.includes("/users/cards");
    }
    onListPageListMode() {
        return this.router.url.includes("/users/list");
    }
    onEditPage() {
        return this.router.url.includes("/users/edit") || this.router.url.includes("/users/new");
    }

    operationBarVisible(): boolean {
        return !(this.onEditPage() && (!!this.userRef ? !this.userRef.isEdit : true)) && Utils.hasPermission(Permission.USERS)().includes(PermissionType.WRITE);
    }

    updateControlButtons(id: string | undefined | null, data: User[]) {
        if (id != null) {
            const index = data.findIndex(v => String(v.id) == id);

            this.totalElements = data.length;
            this.currentElementIndex = index + 1;   // Because array starts on 0

            if (index >= 0) {
                this.nextUser = data[index + 1]?.id;
                this.prevUser = data[index - 1]?.id;

                this.cdr.detectChanges();
            }
        }
    }

    filter($event: any) {
        this.filterByConfig($event, this.users);
    }

    filterByConfig(filters: any, users: User[], showResults: boolean = true) {
        /*
        * Filter patients by selected filters
        */
        const adminFilter = filters.select.find((item: FilterOption) => item.id === 'Admin') ? true : false;
        const admFilter = filters.select.find((item: FilterOption) => item.id === 'Adm') ? true : false;
        const incontinenciaFilter = filters.select.find((item: FilterOption) => item.id === 'Incontinencia') ? true : false;
        const medicacionFilter = filters.select.find((item: FilterOption) => item.id === 'Medicacion') ? true : false;
        const reportesFilter = filters.select.find((item: FilterOption) => item.id === 'Reportes') ? true : false;
        const revisorFilter = filters.select.find((item: FilterOption) => item.id === 'Revisor') ? true : false;
        const titularFilter = filters.select.find((item: FilterOption) => item.id === 'Titular') ? true : false;
        const farmaceuticoFilter = filters.select.find((item: FilterOption) => item.id === 'Farmaceutico') ? true : false;
        const tecnicoFilter = filters.select.find((item: FilterOption) => item.id === 'Tecnico') ? true : false;

        const adminUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Admin));
        const admUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Adm));
        const incontinenciaUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Incontinencia));
        const medicacionUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Medicacion));
        const reportesUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Reportes));
        const revisorUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Revisor));
        const titularUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Titular));
        const farmaceuticoUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Farmaceutico));
        const tecnicoUsers: User[] = users.filter((user: User) => user.roles.includes(UserRole.Tecnico));

        if ( adminFilter || admFilter || incontinenciaFilter || 
             medicacionFilter || reportesFilter || revisorFilter ||
             titularFilter || farmaceuticoFilter || tecnicoFilter
            ) {
            this.filteredUsers = [];

            if ( adminFilter ) 
                this.filteredUsers = [...new Set([...this.filteredUsers, ...adminUsers])];
            if ( admFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...admUsers])];
            if ( incontinenciaFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...incontinenciaUsers])];
            if ( medicacionFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...medicacionUsers])];
            if ( reportesFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...reportesUsers])];
            if ( revisorFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...revisorUsers])];
            if ( titularFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...titularUsers])];
            if ( farmaceuticoFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...farmaceuticoUsers])];
            if ( tecnicoFilter )
                this.filteredUsers = [...new Set([...this.filteredUsers, ...tecnicoUsers])];
        } else {
            this.filteredUsers = users;
        }

        this.filteredUsers = this.filteredUsers.filter((user: User) =>
            (filters.search.trim().length == 0 ? true : Object.values(user).some((val) => 
                filters.search.trim().length > 0 && 
                val
                    ?.toString()
                    .toLowerCase()
                    .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                    .includes(filters.search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
            ))
        )

        // Update cards patients
        if ( this.listRef ) {
            this.listRef.currentData = this.filteredUsers;
            this.listRef.currentSortMode = 'desc';
            this.listRef.sortBy('nam');
        }

        this.filteredResults = this.filteredUsers.map((user: User) => {
            return { id: user.id, label: user.nam }
        });

        if ( showResults ) {
            this.resultsRefreshed = false;
            this.cdr.detectChanges();
            this.resultsRefreshed = true;
            this.cdr.detectChanges();
        }
    }
}
