import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { validationErrors } from '@constants/validations-errors';
import { ValidationErrorTexts } from '../interfaces/validationErrorTexts.interface';

@Pipe({
  name: 'getErrorMessage',
})
export class GetErrorMessagePipe implements PipeTransform {
  private defaultErrors: { [key: string]: ValidationErrorTexts } = {
    min: {
      text: 'Minimun value is {min}',
      errorValueKeys: ['min', 'actual'],
    },
    max: {
      text: 'Maximun value is {max}',
      errorValueKeys: ['max', 'actual'],
    },
    required: {
      text: 'This field is required',
      errorValueKeys: [],
    },
    email: {
      text: 'Invalid email address',
      errorValueKeys: [],
    },
    minlength: {
      text: 'Minimun length is {requiredLength}',
      errorValueKeys: ['requiredLength', 'actualLength'],
    },
    maxlength: {
      text: 'Maximun length is {requiredLength}',
      errorValueKeys: ['requiredLength', 'actualLength'],
    },
    pattern: {
      text: 'Invalid value. Value must follow pattern: {requiredPattern}',
      errorValueKeys: ['requiredPattern', 'actualValue'],
    },
    nullValidator: {
      text: 'This field is invalid',
      errorValueKeys: [],
    },
    compose: {
      text: 'This field is invalid',
      errorValueKeys: [],
    },
    composeAsync: {
      text: 'This field is invalid',
      errorValueKeys: [],
    },
    incorrectDniLength: {
        text: 'El DNI debe tener una longitud exacta de 8 numeros y una letra',
        errorValueKeys: []
    },
    incorrectDniNumber: {
        text: 'El numero del DNI es incorrecto',
        errorValueKeys: []
    },
    incorrectDniLetter: {
        text: 'La letra del DNI no es correcta',
        errorValueKeys: []
    }
  };

  private userErrors: { [key: string]: ValidationErrorTexts } =
    validationErrors;

  getErrorText(errorName: string | undefined, control: FormControl): string {
    let result = '';
    if (!errorName) return result;

    const errorSettings = this.userErrors[errorName]
      ? this.userErrors[errorName]
      : this.defaultErrors[errorName];

    result = errorSettings ? errorSettings.text : `Error: ${errorName}`;

    const error = control.errors?.[errorName];
    if (error && errorSettings) {
      errorSettings.errorValueKeys.forEach((key, index) => {
        result = result.replaceAll(`{${key}}`, error[key]);
      });
    }

    return result;
  }

  transform(value: any, text: string, concat: boolean = false): unknown {
    let errorText = '';
    if (value.errors) {
      const errorList = Object.keys(value.errors) ?? [];
      if (errorList.length && true) {
        errorList.forEach((error) => {
          errorText += errorText
            ? ', ' + this.getErrorText(error, value)
            : this.getErrorText(error, value);
        });
      } else {
        errorText = this.getErrorText(errorList[0], value);
      }
    }

    return !!text ? text : errorText;
  }
}
