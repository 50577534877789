<ng-container *ngFor="let section of schema; let i = index;">
  <app-form-field 
    class="field"
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle"
    [type]="section.type" 
    [label]="section.label" 
    [hideLabel]="section.hideLabel"
    [hintMsg]="section.hintMsg" 
    [placeholder]="section.placeholder"
    [fieldControl]="section.fieldControl | asFormControl" 
    [customFieldData]="section.customFieldData"
    [options]="section.options" 
    [readOnly]="section.readOnly" 
    [min]="section.min" 
    [max]="section.max"
    [inputPattern]="section.inputPattern" 
    [capitalize]="section.capitalize"
    [uppercase]="section.uppercase"
    [errorMsg]="section.errorMsg ?? ''" 
    [group]="section.group" 
    [customStyle]="section.customStyle">
  </app-form-field>
  </ng-container>