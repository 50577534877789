import { AuthService } from "@services/auth.service";
import moment from "moment";
import { Permission, PermissionType } from "./enums/PermissionType";
import { UserRole } from "./enums/user-role";
import { getPermissions } from "./utils/roles";

export class Utils {

    public static get NOW() {
        return moment();
    }

    public static BUG_FixDateForTimeZone(date: Date | null) {
        return date ? moment(date).add(-date.getTimezoneOffset(), "m").toDate() : null;
    }

    public static zeroTimeDate(date: Date | null) {
        return date ? moment(date).startOf("day").toDate() : null;
    }

    public static toStartfDate(date: Date) {
        return moment(date).startOf("day").toDate();
    }

    public static toEndOfDate(date: Date) {
        return moment(date).endOf("day").toDate();
    }

    public static toSQLDate(t: Date | null) {
        return t ? moment(t).format('YYYY-MM-DDTHH:mm:ss') : null
    }

    public static mapRange(value: number, low1: number, high1: number, low2: number, high2: number) {
        return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
    }

    public static randomIntFromInterval(min: number, max: number) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    public static toMsJSON(n: Date, t?: boolean) {
        return n ? "/Date(" + (n.getTime() - ((t || !1) ? 0 : n.getTimezoneOffset() * 6e4)) + ")/" : null
    }

    public static capitalize(label: string, onlyFirst: boolean = false) {
        if (onlyFirst) {
            return label.toLowerCase().slice(0, 1).toUpperCase() + label.toLowerCase().slice(1);
        }

        let words = label.split(' ');
        words.forEach((word, i) => {
            words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return words.join(' ');
    }

    public static allProcs = [Permission.PROCS_MENU, Permission.PROCS_ALTAS, Permission.PROCS_PLANS, Permission.PROCS_RE, Permission.PROCS_VENTAS];

    public static hasPermission(perm: Permission) {
        return (): PermissionType[] => {
            if (AuthService.userRoles == undefined) return [];

            // Get origin
            const origin = <'quantum' | 'pharma'>localStorage.getItem('origin');

            // Get if fgp allowed
            const allowFgp = localStorage.getItem('current_institution_allow_fgp') === 'true';
            
            // Initialize permissions
            const readWrite: Permission[] = [];
            const read: Permission[] = [];

            // Get permissions for each role
            AuthService.userRoles.forEach(role => {
                const { readWrite: rw, read: r } = getPermissions(origin, role, allowFgp);
                readWrite.push(...rw);
                read.push(...r);
            })

            // Check if the permission is in the list
            if ( readWrite.includes(perm) ) {
                return [PermissionType.READ, PermissionType.WRITE];
            } else if ( read.includes(perm) ) {
                return [PermissionType.READ];
            } else {
                return [];
            }
        }
    }

    public static TRUE() {
        return true;
    }

    public static FALSE() {
        return false;
    }

    public static BOOLEAN(val: boolean) {
        return () => val;
    }

    public static get buildUniqueFileName() {
        return moment().format('x');
    }
}
