import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';

import { SortService } from '@services/sort.service';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-box-epigraphs',
  templateUrl: './box-epigraphs.component.html',
  styleUrls: ['./box-epigraphs.component.scss'],
})
export class BoxEpigraphsComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean = false;

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    if ( this.disabled ) {
      this.schema.forEach((schema: FormItemSchemaInterface) => {
        schema.childs
          ?.filter((child: FormItemSchemaInterface) => child.type !== 'info')
          .forEach((child: FormItemSchemaInterface) => child.fieldControl.disable());
      })
    }

    super.ngOnInit();
    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
