<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte uso de medicamentos por centro'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
  <div slot="content" class="container-options">
      <form [formGroup]="form">


        <!-- <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section> -->

        <section class="filter-container">
           <!-- <app-form-title type="h5" title="Medicamento"></app-form-title> -->
          <div class="subtitle">Medicamento</div>
          <mat-radio-group formControlName="medicamentRadio"  class="col-12">
            <mat-radio-button value="0">
              Nombre del medicamento
            </mat-radio-button>

            <mat-radio-button value="1">
              Código del medicamento
            </mat-radio-button>
          </mat-radio-group>
          <app-form-field
            *ngIf="form.controls['medicamentRadio'].value === '0'"
            type="autocomplete"
            label="Medicina"
            class="col-12 marginTop"
            [fieldControl]="form.controls['medicine'] | asFormControl"
            [options]="medicinesNameOptions"
          ></app-form-field>

          <app-form-field
            *ngIf="form.controls['medicamentRadio'].value === '1'"
            type="autocomplete"
            class="col-12 marginTop"
            label="Código medicina"
            [fieldControl]="form.controls['medicine'] | asFormControl"
            [options]="medicinesCodeOptions"
          ></app-form-field>
        </section>
       
      </form>
    </div>
  </app-report-select>
</ng-container>
