import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormService } from '@services/form.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

import { SortService } from '@services/sort.service';
import { ListSubformComponent } from '@shared/list-subform/list-subform.component';

import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';

@Component({
  selector: 'app-list-subform-epigraphs',
  templateUrl: './list-subform-epigraphs.component.html',
  styleUrls: ['./list-subform-epigraphs.component.scss'],
})
export class ListSubformEpigraphsComponent extends ListSubformComponent implements OnInit, OnDestroy   {
  
  expandAll: boolean = true;
  compressAll: boolean = false;

  @ViewChildren(MatExpansionPanel) expansionPanel: QueryList<MatExpansionPanel>[];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    private cdRef: ChangeDetectorRef,
    protected override   formsService: FormService,
    protected override  sortService: SortService,
  ) {
    super(formsService, sortService)
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
  override ngOnDestroy(): void {
  }

  getTitle(item: AbstractControl) {
    return item.value.epigraph.title;
  }

  addNewElement() {
    super.addOne(true);

    this.cdRef.detectChanges();
    // @ts-ignore
    this.expansionPanel.toArray().pop().open();
  }
  openExpandedAll(){

    this.expandAll = false;
    this.compressAll = true;
    this.accordion.openAll()
  }
  closedExpandedAll(){
    this.compressAll = false;
    this.expandAll = true;
    this.accordion.closeAll()
  }
}
