  export const reportDataMedicationPlanConsolidated = {
  data: {
    rpp: '/COROTA/PmOperationsCons',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },

  dataDownloadPDF: {
    rpp: '/COROTA/PmOperationsCons',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },

  dataDownloadExcel: {
    rpp: '/COROTA/PmOperationsCons',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/PmOperationsCons',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const reportDataMedicationPlanTable = {
  data: {
    rpp: '/COROTA/PmOperationsByType',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  
  dataDownloadPDF: {
    rpp: '/COROTA/PmOperationsByType',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },

  dataDownloadExcel: {
    rpp: '/COROTA/PmOperationsByType',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/PmOperationsByType',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const reportDataMedicationPlanGraph = {
  data: {
    rpp: '/COROTA/PmOperationsGraphic',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },

  dataDownloadPDF: {
    rpp: '/COROTA/PmOperationsGraphic',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },

  dataDownloadExcel: {
    rpp: '/COROTA/PmOperationsGraphic',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/PmOperationsGraphic',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

