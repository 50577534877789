<mat-icon color="primary" (mouseenter)="legendStatus = true" (mouseleave)="legendStatus = false">color_lens</mat-icon>

<div class="legend" 
  [class]="legendPosition"
  *ngIf="legendStatus"
  [@inOutFade]="{value: true, params: {duration: '100ms'}}">
  <div class="title">Leyenda</div>

  <div class="list">
    <div class="item" *ngFor="let item of items">
      <ng-container [ngSwitch]="item.type">
        <div class="content" *ngSwitchCase="ColorLegendItemType.Color">
          <span class="color-indicator">
            <div class="dot" [ngStyle]="{'background-color': item.color}"></div>
          </span>
          <span class="description">{{ item.description}}</span>
        </div>

        <div class="content" *ngSwitchCase="ColorLegendItemType.Background">
          <span class="background-indicator" [ngStyle]="getBackgroundStyle(item)"></span>
          <span class="description">{{ item.description}}</span>
        </div>

        <div class="content" *ngSwitchCase="ColorLegendItemType.Icon">
          <mat-icon color="primary" [ngStyle]="getIconStyle(item)">{{ item.data.icon }}</mat-icon>
          <span class="description">{{ item.description}}</span>
        </div>

        <div class="content" *ngSwitchCase="ColorLegendItemType.Text">
          <span class="text" [ngStyle]="{'color': item.color}">{{ item.data.text }}</span>
          <span class="description">{{ item.description}}</span>
        </div>

        <div class="content" *ngSwitchCase="ColorLegendItemType.Image">
          <img class="image" [src]="item.data.src" />
          <span class="description">{{ item.description}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>