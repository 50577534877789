import { CardAction } from '@interfaces/card-actions';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { Patient } from '@interfaces/patient/patient.interface';
import { Utils } from '../../Utils';
import { Permission, PermissionType } from '../../enums/PermissionType';

export const routePatients: string = '/global-eye/patients';

export const alertInfo: string = 'Filtrar alertas';

export const patientFilterSelect: FilterSelect[] = [
    {
        name: 'Estado',
        filter: [
            {
                id: 'mutuas',
                value: 'MUTUAS',
            },
            // {
            //     id: 'exitus',
            //     value: 'Exitus/Baja',
            // },
            {
                id: 'hospitalization',
                value: 'Bloqueados por ingreso hospitalario',
            },
            {
                id: 'vacations',
                value: 'Bloqueados por vacaciones/otro',
            },
            {
                id: 'self_management',
                value: 'Autogestión',
            },
            // {
            //     id: 'none',
            //     value: 'Ninguno anterior',
            // },
        ],
    },
    {
        name: 'Genero',
        filter: [
            {
                id: 'male',
                value: 'Hombre',
            },
            {
                id: 'female',
                value: 'Mujer',
            },
        ],
    },
];

export const patientFilterSelected: FilterOption[] = [
    // {
    //     id: 'mutuas',
    //     value: 'MUTUAS',
    // },
    // {
    //     id: 'vacations',
    //     value: 'Bloqueados por vacaciones/otro',
    // },
    // {
    //     id: 'self_management',
    //     value: 'Autogestión',
    // },
    // {
    //     id: 'male',
    //     value: 'Hombre',
    // },
    // {
    //     id: 'female',
    //     value: 'Mujer',
    // },
    // {
    //     id: 'none',
    //     value: 'Ninguno anterior',
    // },
];

export const actionsPatient: CardAction[] = [
    {
        id: 'edit',
        name: 'Editar',
        iconName: 'edit',
        routeLink: '/edit',
        queryParam: {
            tab: 0
        },
        // hasPermission: Utils.hasPermission(Permission.PATIENTS_GENERAL)
        hasPermission: () => {
            const perms = Utils.hasPermission(Permission.PATIENTS_GENERAL)();
            if (!perms.includes(PermissionType.WRITE)) return [];
            return perms;
        }
    },
    {
        id: 'prescription',
        name: 'Prescripciones',
        iconName: 'calendar_month',
        routeLink: '/edit',
        queryParam: {
            tab: 5
        },
        hasPermission: Utils.hasPermission(Permission.PATIENTS_PRESCRIPTIONS)
    },
    {
        id: 'block',
        name: 'Bloqueos',
        iconName: 'block',
        routeLink: '/edit',
        queryParam: {
            tab: 4
        },
        hasPermission: Utils.hasPermission(Permission.PATIENTS_BLOCKS)
    },
    {
        id: 'delete',
        name: 'Borrar',
        iconName: 'delete',
        routeLink: '',
        // Para eliminar hay que tener permiso de escritura si o sí en patients
        hasPermission: () => {
            const perms = Utils.hasPermission(Permission.PATIENTS)();
            if (!perms.includes(PermissionType.WRITE)) return [];
            return perms;
        }
    },
    {
        id: 'pf_print',
        name: 'Imprimir plan farmacológico',
        iconName: 'print',
        hasPermission: Utils.hasPermission(Permission.PATIENTS_PF),
    },
];

