import { Validators } from '@angular/forms';
import {
    FormBuilderInterface,
    FormConfigInterface,
    TabInterface,
} from '@interfaces/dynamic-form.interface';
import { inventary } from '../../inventary';
import { TYPE_UNITS_OPTIONS } from '@constants/options';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';

export const tabs: TabInterface[] = [
    { label: 'General', form: 'general', hidden: Utils.hasPermission(Permission.VADEMECUM_GENERAL)  },
    { label: 'Notas', form: 'notes', hidden: Utils.hasPermission(Permission.VADEMECUM_NOTES)  },
    { label: 'Epígrafes', form: 'epigraphs', hidden: Utils.hasPermission(Permission.VADEMECUM_EPIGRAPHS)  },
    { label: 'Interacciones', form: 'interactions', hidden: Utils.hasPermission(Permission.VADEMECUM_INTERACTIONS)  },
    { label: 'Farmacia', form: 'farmacia', hidden: Utils.hasPermission(Permission.VADEMECUM_PHARMACY) },
    { label: 'Robot', form: 'robot', hidden: Utils.hasPermission(Permission.VADEMECUM_ROBOT) },
];

const general: FormBuilderInterface = {


    general: {
        label: 'Datos generales',
        type: 'group-label',
        size: 'col-12',
        childs: {
            masterCode: {
                label: 'Código Maestro',
                type: 'text',
                value: '',
                validators: [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            name: {
                label: 'Nombre',
                type: 'text',
                value: '',
                validators: [Validators.required],
                size: 'col-9',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            CMS: {
                label: 'Código Maestro Sustitutorio',
                type: 'text',
                value: '',
                validators: [
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            display: {
                label: 'Presentación',
                type: 'text',
                value: '',
                validators: [],
                size: 'col-9',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            supplier: {
                label: 'Suministrador',
                type: 'text',
                value: '',
                validators: [],
                size: 'col-6',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            strength: {
                label: 'Fortaleza',
                type: 'text',
                value: '',
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
            admin: {
                label: 'Vía de administración',
                type: 'select',
                value: '',
                options: [],
                validators: [Validators.required],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE)
            },
        }
    },

    codesFacturation: {
        type: 'group-label',
        label: 'Códigos preferentes',
        childs: {
            primary: {
                label: 'Primario',
                type: 'text',
                value: '',
                placeholder: 'Código de 6 dígitos',
                validators: [],
                size: 'col-6',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            secondary: {
                label: 'Secundario',
                type: 'text',
                value: '',
                placeholder: 'Código de 6 dígitos',
                validators: [],
                size: 'col-6',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
        },
    },
    activeIngredients: {
        label: 'Principios activos',
        type: 'active-ingredients',
        value: []
    },
    units: {
        type: 'group-label',
        label: 'Unidades',
        childs: {
            box: {
                label: 'Caja',
                type: 'text',
                value: null,
                validators: [],
                size: 'col-4',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            dose: {
                label: 'Dosis',
                type: 'text',
                value: null,
                validators: [],
                size: 'col-4',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            type: {
                label: 'Tipo',
                type: 'radio',
                value: true,
                validators: [],
                size: 'col-4',
                options: TYPE_UNITS_OPTIONS,
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
        },
    },

    classification: {
        type: 'group-checkbox',
        label: 'Clasificación IEF',
        validators: [Validators.required],
        customStyle: {'padding-bottom': '10px'},
        childs: {
            none: {
                label: 'Ninguna',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            alzheimer: {
                label: 'Alzheimer',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            anxiolytics: {
                label: 'Ansiolíticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            antibiotics: {
                label: 'Antibióticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            anticoagulants: {
                label: 'Anticoagulantes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            antidepressants: {
                label: 'Antidepresivos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },

            antipsychotics: {
                label: 'Antipsicoticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            diabetes: {
                label: 'Diabetes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            opioids: {
                label: 'Opioides',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            anticonvulsants: {
                label: 'Eutimizantes/Anticonvulsivantes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            aine: {
                label: 'AINE',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            ibp: {
                label: 'IBP',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
        },
    },

    inventary: {
        type: 'group-label',
        label: 'Inventario',
        customStyle: {'margin-bottom': '10px', 'margin-top': '10px'},
        childs: {
            inventary: {
                label: 'Inventario',
                type: 'select',
                value: null,
                validators: [],
                options: inventary,
                size: 'col-4',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
        },
    },

    otherData: {
        type: 'group-checkbox',
        label: 'Otros datos',
        childs: {
            obsolete: {
                label: 'Obsoleto',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            hospitalUse: {
                label: 'Hospitalario',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            cobert: {
                label: 'Cobertura sanitaria',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            diaper: {
                label: 'Pañal',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            parapharmacy: {
                label: 'Parafarmacia',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            cureMaterial: {
                label: 'Material de cura',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            RA: {
                label: 'R.A',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            MEC: {
                label: 'M.E.C',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            RO: {
                label: 'R.O',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            notPrescribe: {
                label: 'No prescribir',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
            FGP: {
                label: 'FGP',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_GENERAL)().includes(PermissionType.WRITE),
            },
        },
    },
};

const notes: FormBuilderInterface = {
    observations1: {
        label: 'Comentario 1',
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_NOTES)().includes(PermissionType.WRITE),
    },
    observations2: {
        label: 'Comentario 2',
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_NOTES)().includes(PermissionType.WRITE),
    },
};

const epigraphInfo: FormBuilderInterface = {
    title: {
        label: 'Epígrafes',
        hideLabel: true,
        type: 'info',
    },
    info: {
        label: 'Información del epigrafe',
        hideLabel: true,
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
        readOnly: () => true,
    },
};

const epigraph: FormBuilderInterface = {
    epigraph: {
        type: 'group-expansion',
        customFieldData: { title: '' },
        label: '',
        childs: epigraphInfo,
        readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_EPIGRAPHS)().includes(PermissionType.WRITE),
    },
};

const epigraphs: FormBuilderInterface = {
    status: {
        type: 'info',
        label: 'Epigrafes',
    },

    epigraphs: {
        type: 'custom',
        customType: 'list-form',
        label: '',
        customFieldData: { schema: epigraph, canDelete: false, hideAddButton: true },
        readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_EPIGRAPHS)().includes(PermissionType.WRITE),
    },
};

const interactions: FormBuilderInterface = {

    main: {
        type: 'group-label',
        label: 'Interacciones',
        customType: 'default',
        childs: {
            interactions: {
                label: 'Interacciones',
                type: 'autocomplete',
                hideLabel: true,
                value: null,
                validators: [],
                size: 'col-12',
                customFieldData: { compareStart: true },
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_INTERACTIONS)().includes(PermissionType.WRITE),
            },
        },
    },

    infoSelectedInteraction: {
        type: 'group-expansion',
        label: 'Información de la interacción',
        readOnly: () => true,
        hideLabel: true,
        // customFieldData: { title: '' },
        conditionalConfig: {
            mode: 'hide',
            match: 'exists',
            filter: 'every',
            fields: [{ name: 'title', value: undefined }],
        },
        childs: {
            title: {
                label: 'Interacciones',
                hideLabel: true,
                type: 'info',
            },
            info: {
                label: 'Información de la interacción',
                type: 'textarea',
                value: '',
                validators: [],
                size: 'col-12',
                readOnly: () => true,
            },
        },
    },
};

const robot: FormBuilderInterface = {
    robotOptions: {
        type: 'group-checkbox',
        label: '',
        hideLabel: true,
        customStyle: {
            "flex-direction": "column"
        },
        childs: {
            noEmbistable: {
                label: 'No emblistable',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
            },
            uniDose: {
                label: 'Unidosis',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
            },
            indivisible: {
                label: 'Indivisible',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
            },
            alwaysPlate: {
                label: 'Siempre en bandeja',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
            },
            noHalves: {
                label: 'No mitades',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
                readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
            },
        },
    },
    tolva: {
        label: 'Tolva',
        type: 'text',
        value: null,
        size: 'col-4',
        inputPattern: '^[0-9]{0,3}$',
        max: 3,
        min: 3,
        errorMsg: 'Debe tener una longitud de 3 dígitos',
        readOnly: () => !Utils.hasPermission(Permission.VADEMECUM_ROBOT)().includes(PermissionType.WRITE),
    },
};

export const formsConfig: FormConfigInterface = {
    general,
    notes,
    epigraphs,
    interactions,
    robot,
};
