<div class="re-status-container">
    <div class="page-title">
        Estado de RE
    </div>

    <div class="toolbar">
        <div class="filters">
            <mat-form-field appearance="fill">
                <div class="label">Centro</div>
                <mat-select 
                  [(value)]="selectedInstitution"
                  (selectionChange)="onInstitutionChange($event)"
                >
                  <mat-option *ngFor="let option of institutionOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- *ngIf="patients"  -->
            <mat-form-field *ngIf="patients">
                <div class="label">Filtrar</div>
                <mat-select [(value)]="filtersSelected" multiple>
                  <mat-optgroup *ngFor="let group of filters" [label]="group.label">
                    <mat-option *ngFor="let option of group.options" [value]="option.value">
                      {{ option.label }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
            </mat-form-field>
    
            <!-- *ngIf="patients" -->
            <mat-form-field *ngIf="patients">
                <div class="label">Buscar</div>
                <input matInput [(ngModel)]="searchValue">
            </mat-form-field>
    
            <mat-form-field appearance="fill">
                <div class="label">Fecha</div>
                <input matInput 
                    [matDatepicker]="datepicker"
                    [(ngModel)]="selectedDate"
                >
                <mat-datepicker-toggle matIconSuffix [for]="datepicker">
                    <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                </mat-datepicker>
            </mat-form-field>
        </div>

        <div class="optionbuttons">

               <!-- Custom button -->
               <mat-button-toggle-group 
                (click)="goToREReport()">
                <mat-button-toggle 
                    mat-icon-button 
                    class="custom-button" 
                    value="custom"
                    matTooltip="Abrir reportes de RE">
                    <mat-icon>insert_chart_outlined</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

            <ng-container *ngIf="reInstitutionData">

                <!-- Custom button -->
                <mat-button-toggle-group 
                    (click)="goToReport()">
                    <mat-button-toggle 
                        mat-icon-button 
                        class="custom-button" 
                        value="custom"
                        matTooltip="Imprimir">
                        <mat-icon>print</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <mat-button-toggle-group 
                (click)="goToDebtReport()">
                    <mat-button-toggle 
                        matTooltip="Abrir reportes de RE"
                        [matTooltip]="reInstitutionData.ReNote || 'No hay notas'"
                        mat-icon-button 
                        class="custom-button" 
                        value="custom"
                        [ngClass]=" { hasNote: !!reInstitutionData.ReNote }"
                        matTooltip="Imprimir">
                        <mat-icon>chat_bubble</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <mat-button-toggle-group 
                    (click)="goToDebtReport()">
                    <mat-button-toggle 
                        mat-icon-button 
                        class="custom-button" 
                        matTooltip="Abrir reporte de deudas"
                        [ngClass]=" { hasDebt: !!reInstitutionData.DeudaTotal }"
                        value="custom">
                        {{ reInstitutionData.DeudaTotal | number }}€
                    </mat-button-toggle>
                </mat-button-toggle-group>

            </ng-container>
        
            <ng-container *ngIf="patients">
                <div class="personsList">{{ filteredPatients?.length }} / {{ patients.length }}</div>
            </ng-container>

            <ng-container *ngIf="patients">
                <mat-button-toggle-group 
                (click)="onRefreshPage()">
                    <mat-button-toggle 
                        mat-icon-button 
                        class="custom-button" 
                        matTooltip="Refrescar más filas"
                        value="custom">
                        <mat-icon>refresh</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </ng-container>
        </div>
        
    </div>

    <div *ngIf="patients" class="container-table" (scroll)="patientIdCurrentDebtDetail = undefined">
            <div *ngFor="let patient of filteredPatients; let i = index" class="container-matpannel">
                <div class="mat-expansion-panel"
                    [ngClass]="{
                        unsaved: patient.hasChanges,
                        disabled: !patient.FarmaticNumber
                    }"
                    (click)="patient.FarmaticNumber ?  onOpenPatient(patient, i) : null"
                >
                    <div class="mat-expansion-panel-header"
                        [ngClass]="{ 
                            'problematic': patient.Trouble,
                            'at-home': patient.AtHome
                        }"
                    >
                        <div class="mat-panel-title">
                            <div class="patient-info">
                                <div>
                                    {{ patient.PatientName }} 
                                    <span *ngIf="patient.Deuda" matTooltip="Deuda" (click)="getDebtDetail($event, patient)">{{patient.Deuda}}</span>
                                </div>
                                <div matTooltip="Número GlobalEye"> {{ patient.PatientNumber }} </div>

                                <div #debtDetail 
                                    class="debt-detail"
                                    *ngIf="patientIdCurrentDebtDetail === patient.PatientId"
                                    [ngStyle]="{
                                        'top': patientCurrentDebtDetailPosition.top,
                                        'left': patientCurrentDebtDetailPosition.left
                                    }"
                                >
                                    <app-re-patient-debt-detail [patient]="patient" [dataReady]="!!patient.DebtDetail"></app-re-patient-debt-detail>
                                </div>
                            </div>
                            <div class="patient-farma-info">
                                <button *ngIf="patient.ex && !patient.forceShow; else elseExitusTemplate"
                                        mat-flat-button
                                        color="accent"
                                        matTooltip="Mostrar los datos de facturación"
                                        (click)="preventPropagation($event); onClickShowData(patient)"
                                    >
                                        Mostrar
                                </button>
                                <ng-template #elseExitusTemplate>
                                    <button *ngIf="!patient.FarmaticNumber"
                                            class="buttonWarn"
                                            mat-flat-button
                                            color="warn"
                                            matTooltip="Introducir número de FARMATIC"
                                            (click)="preventPropagation($event); onClickFarmaticNumber(patient)"
                                        >
                                            N.º de Farmatic
                                        </button>
                                    <div>
                                        <span *ngIf="patient.FarmaticNumber"
                                            matTooltip="Número FARMATIC"    
                                        >{{ patient.FarmaticNumber }}</span>
                                        
                                        <div matTooltip="CIP"> {{ patient.CIP }} </div>
                                        <div matTooltip="TSI"> {{ patient.TSI }} </div>
                                    </div>
                                </ng-template>
                                
                            </div>
                            <div class="patient-flags">
                                <mat-icon *ngIf="patient.blq.length" class="card-flag blocked" matTooltip="Bloqueado">bookmark</mat-icon>
                                <mat-icon *ngIf="patient.ex" class="card-flag exitus" matTooltip="Exitus">bookmark</mat-icon>
                                <mat-icon *ngIf="patient.SelfManaged" class="card-flag selfmanaged" matTooltip="Autogestión">bookmark</mat-icon>
                                <mat-icon *ngIf="patient.CapExt" class="card-flag capexterno" matTooltip="CAP Externo">bookmark</mat-icon>
                                <mat-icon *ngIf="patient.Mutuas === 1" class="card-flag mutuas" matTooltip="MUTUAS">bookmark</mat-icon>
                            </div>
                            <div class="patient-re-info">
                                <div matTooltip="Última RE crónica" class="cronica"> {{ patient.RE }} </div>
                                <div matTooltip="Última RE aguda"  class="aguda"> {{ patient.RE2 }} </div>
                            </div>
                            <div class="patient-daily-changes">
                                <div class="containerBox">
                                    <ng-container *ngIf="patient?.DailyChanges?.length">
                                        <div *ngFor="let day of patient.DailyChanges"
                                            class="tag"
                                            [ngClass]="{
                                                alta: [1,2,8].includes(day.ReportReasonCode),
                                                blocked: [3].includes(day.ReportReasonCode),
                                                exitus: [4].includes(day.ReportReasonCode),
                                                re: [5,6,7].includes(day.ReportReasonCode),
                                            }"
                                            matTooltip="{{day.Fecha | date: 'dd/MM/yyyy'}}"
                                        >
                                            {{getTag(day)}}
                                        </div>
                                    </ng-container>
                            
                                </div>
                            </div>
                            <div class="patient-next-date">
                                <div matTooltip="Próxima dispensación"> {{ patient.NextDispenseDate | date: 'dd/MM/yyyy' }} </div>
                            </div>
                            <div class="patient-note">
                                <mat-icon 
                                    [matTooltip]="patient.ReNote || 'Notas generales'"
                                    [ngClass]=" { hasNote: !!patient.ReNote }"
                                >chat_bubble</mat-icon>
                            </div>
                            <app-re-issues
                                [disabled]="true"
                                [issueType]="patient.IssueType"
                            ></app-re-issues>
                            <div class="patient-reviewed">
                                <mat-icon  *ngIf="patient.Reviewed"
                                    color="primary"
                                    matTooltip="{{
                                        patient.Reviewed ? 'Revisado: ' + (patient.Reviewed | date: 'dd/MM/yyyy') : ''
                                    }}"
                                >
                                    check_circle
                                </mat-icon>
                            </div>
                            <div class="patient-reviewed">
                                <mat-icon  *ngIf="patient.FarmaticNumber"
                                >
                                keyboard_arrow_down
                                </mat-icon>
                            </div>
                        </div>
                    </div>

               
                 
                </div>

               
                <div class="layerDark" *ngIf="patient.infoDailyChanges"  [@inOutFade]="{value: true, params: {duration: '200ms'}}"></div>
                <div  class="containerBoxDailyChanges" *ngIf="patient.infoDailyChanges"   [@inOutFromBottom]="{value: true, params: {duration: '200ms'}}" >
                    <div>
                        <div class="namePerson">
                            <div class="col-12 right name">{{ patient.PatientName }}</div>
                            <div class="col-12 right"> {{ patient.PatientNumber }} </div>
                            <mat-icon class="close" (click)="closePatient(patient, i)">
                                close
                            </mat-icon>
                        </div> 
                        <ng-container *ngIf="patient?.DailyChanges?.length">
                           
                            <div class="listDailyChanges">
                                <div *ngFor="let day of patient.DailyChanges"
                                class="tag"
                                [ngClass]="{
                                    alta: [1,2,8].includes(day.ReportReasonCode),
                                    blocked: [3].includes(day.ReportReasonCode),
                                    exitus: [4].includes(day.ReportReasonCode),
                                    re: [5,6,7].includes(day.ReportReasonCode),
                                }"
                                matTooltip="{{day.Fecha | date: 'dd/MM/yyyy'}}"
                            >
                                {{getTag(day)}}
                            </div>
                            </div>
                           
                        </ng-container>
                    </div>
                </div>
            </div>
    </div>
</div>
<div *ngIf="patientLoading" class="patient-loading">
    <mat-spinner></mat-spinner>
</div>

<div class="container-reviewWeeks" *ngIf="patientSelected && reviewWeeks[patientSelected.PatientId]" [@inOutFromBottom]="{value: true, params: {duration: '200ms'}}" >
    <ng-container  >
        <app-re-patient
            [patient]="patientSelected"
            [weekData]="reviewWeeks[patientSelected.PatientId]"
            (changed)="onPatientChange($event, patientSelected)"
            (close)="reviewWeeks[patientSelected.PatientId] = undefined"
            (save)="onPatientSave($event, patientSelected)"
        ></app-re-patient>
    </ng-container>
</div>