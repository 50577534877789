import { UserRole } from "../enums/user-role";
import { Permission, PermissionType } from "../enums/PermissionType";

export function getPermissions(
  origin: 'quantum' | 'pharma', 
  role: UserRole, 
  allowFgp: boolean = false,
  businessGroup: number = 0
): { readWrite: Permission[], read: Permission[] } {
  if ( origin === 'quantum' ) {
    switch (role) {
      case UserRole.Adm:
        return {
          readWrite: [
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_DOCUMENTS
          ],
          read: [
            Permission.DASHBOARD
          ]
        }
        break;
      case UserRole.Admin:
        return {
          readWrite: [
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_CLINICAL,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.PATIENTS_DOCUMENTS,
            Permission.INSTITUTIONS,
            Permission.INSTITUTIONS_GENERAL,
            Permission.INSTITUTIONS_SCHEDULES,
            Permission.INSTITUTIONS_ACTUS,
            Permission.INSTITUTIONS_DEPARTMENTS,
            Permission.INSTITUTIONS_RE,
            Permission.INSTITUTIONS_PM,
            Permission.INSTITUTIONS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORTS_MED,
            Permission.REPORT_INCONTINENCIA,
            Permission.ADMINISTRATION,
            Permission.USERS,
            Permission.PROCESSES,
            Permission.PROCESSES_MEDICATION_REGISTER
          ].concat(allowFgp ? [Permission.REPORT_FGP] : []),
          read: [
            Permission.DASHBOARD,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS
          ]
        }
        break;
      case UserRole.Medicacion:
        return {
          readWrite: [
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_CLINICAL,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.PATIENTS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS
          ],
          read: [
            Permission.DASHBOARD,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORTS_MED,
            Permission.REPORT_INCONTINENCIA
          ].concat(allowFgp ? [Permission.REPORT_FGP] : [])
        }
        break;
      case UserRole.Due:
        return {
          readWrite: [
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_CLINICAL,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.PROCESSES,
            Permission.PROCESSES_MEDICATION_REGISTER
          ],
          read: [
            Permission.DASHBOARD,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORTS_MED,
            Permission.REPORT_INCONTINENCIA
          ].concat(allowFgp ? [Permission.REPORT_FGP] : [])
        }
        break;
      case UserRole.Incontinencia:
        return {
          readWrite: [],
          read: [
            Permission.DASHBOARD,
            Permission.REPORTS,
            Permission.REPORTS_FARMA,
            Permission.REPORT_INCONTINENCIA
          ]
        }
        break;
      case UserRole.Reportes:
        return {
          readWrite: [],
          read: [
            Permission.DASHBOARD,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.PROCESSES,
            Permission.PROCESSES_MEDICATION_REGISTER
          ]
        }
        break;
      case UserRole.Revisor:
        return {
          readWrite: [],
          read: [
            Permission.DASHBOARD,
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_CLINICAL,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PATTERN,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORTS_MED,
            Permission.REPORT_INCONTINENCIA
          ].concat(allowFgp ? [Permission.REPORT_FGP] : [])
        }
        break;
      case UserRole.PDA:
        return {
          readWrite: [],
          read: []  
        }
        break;
      case UserRole.IPF:
        return {
          readWrite: [],
          read: [
            Permission.PATIENTS,
            Permission.PATIENTS_PF
          ]
        }
        break;
      default:
        return {
          readWrite: [],
          read: []
        }
        break;
    }
  } else if ( origin === 'pharma' ) {
    switch (role) {
      case UserRole.Adm:
        return {
          readWrite: [
            Permission.PATIENTS_DOCUMENTS,
            Permission.INSTITUTIONS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.PROCS_MENU,
            Permission.PROCS_ALTAS,
            Permission.PROCS_VENTAS
          ],
          read: [
            Permission.DASHBOARD,
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.INSTITUTIONS,
            Permission.INSTITUTIONS_GENERAL,
            Permission.INSTITUTIONS_SCHEDULES,
            Permission.INSTITUTIONS_DEPARTMENTS,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
            Permission.VADEMECUM_PHARMACY,
            Permission.VADEMECUM_ROBOT,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_MED,
            Permission.REPORTS_FARMA,
            Permission.REPORT_INCONTINENCIA
          ]
          .concat(allowFgp ? [Permission.REPORT_FGP] : []),
        }
        break;
      case UserRole.Farmaceutico:
        return {
          readWrite: [
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_DOCUMENTS,
            Permission.INSTITUTIONS_RE,
            Permission.INSTITUTIONS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.PROCS_MENU,
            Permission.PROCS_RE,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_MED,
            Permission.REPORTS_FARMA,
            Permission.REPORT_INCONTINENCIA
          ]
          .concat(businessGroup !== 1 ? [Permission.INSTITUTIONS] : [])
          .concat(businessGroup !== 1 ? [Permission.INSTITUTIONS_GENERAL] : [])
          .concat(businessGroup !== 1 ? [Permission.INSTITUTIONS_SCHEDULES] : [])
          .concat(businessGroup !== 1 ? [Permission.INSTITUTIONS_DEPARTMENTS] : [])
          .concat(allowFgp ? [Permission.REPORT_FGP] : []),
          read: [
            Permission.DASHBOARD,
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
            Permission.VADEMECUM_PHARMACY,
            Permission.VADEMECUM_ROBOT,
          ]
          .concat(businessGroup === 1 ? [Permission.INSTITUTIONS] : [])
          .concat(businessGroup === 1 ? [Permission.INSTITUTIONS_GENERAL] : [])
          .concat(businessGroup === 1 ? [Permission.INSTITUTIONS_SCHEDULES] : [])
          .concat(businessGroup === 1 ? [Permission.INSTITUTIONS_DEPARTMENTS] : [])
        }
        break;
      case UserRole.Titular:
        return {
          readWrite: [
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_DOCUMENTS,
            Permission.INSTITUTIONS,
            Permission.INSTITUTIONS_GENERAL,
            Permission.INSTITUTIONS_SCHEDULES,
            Permission.INSTITUTIONS_DEPARTMENTS,
            Permission.INSTITUTIONS_RE,
            Permission.INSTITUTIONS_DOCUMENTS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.VADEMECUM_PHARMACY,
            Permission.VADEMECUM_ROBOT,
            Permission.PROCS_MENU,
            Permission.PROCS_ALTAS,
            Permission.PROCS_VENTAS,
            Permission.PROCS_RE,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORTS_MED,
            Permission.REPORT_INCONTINENCIA,
            Permission.ADMINISTRATION,
            Permission.USERS
          ]
          .concat(businessGroup !== 1 ? [Permission.PATIENTS] : [])
          .concat(businessGroup !== 1 ? [Permission.PATIENTS_GENERAL] : [])
          .concat(businessGroup !== 1 ? [Permission.PATIENTS_BLOCKS] : [])
          .concat(allowFgp ? [Permission.REPORT_FGP] : []),
          read: [
            Permission.DASHBOARD,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
          ]
          .concat(businessGroup === 1 ? [Permission.PATIENTS] : [])
          .concat(businessGroup === 1 ? [Permission.PATIENTS_GENERAL] : [])
          .concat(businessGroup === 1 ? [Permission.PATIENTS_BLOCKS] : [])

        }
        break;
      case UserRole.Admin:
        return {
          readWrite: [
            Permission.PATIENTS,
            Permission.PATIENTS_GENERAL,
            Permission.PATIENTS_CLINICAL,
            Permission.PATIENTS_DIAGNOSTIC,
            Permission.PATIENTS_PATTERN,
            Permission.PATIENTS_BLOCKS,
            Permission.PATIENTS_PRESCRIPTIONS,
            Permission.PATIENTS_DOCUMENTS,
            Permission.PROCS_MENU,
            Permission.PROCS_PLANS,
            Permission.PROCS_ALTAS,
            Permission.PROCS_VENTAS,
            Permission.PROCS_RE,
            Permission.REPORTS,
            Permission.REPORTS_PATIENTS,
            Permission.REPORTS_RESI,
            Permission.REPORTS_FARMA,
            Permission.REPORT_INCONTINENCIA,
            Permission.REPORTS_MED,
            Permission.REPORT_FGP,
            Permission.INSTITUTIONS,
            Permission.INSTITUTIONS_GENERAL,
            Permission.INSTITUTIONS_SCHEDULES,
            Permission.INSTITUTIONS_ACTUS,
            Permission.INSTITUTIONS_DEPARTMENTS,
            Permission.INSTITUTIONS_RE,
            Permission.INSTITUTIONS_PM,
            Permission.INSTITUTIONS_DOCUMENTS,
            Permission.INSTITUTIONS_FGP,
            Permission.VADEMECUM,
            Permission.VADEMECUM_GENERAL,
            Permission.VADEMECUM_NOTES,
            Permission.VADEMECUM_EPIGRAPHS,
            Permission.VADEMECUM_INTERACTIONS,
            Permission.VADEMECUM_PHARMACY,
            Permission.VADEMECUM_ROBOT,
            Permission.USERS,
            Permission.ADMINISTRATION
          ],
          read: [
            Permission.DASHBOARD
          ]
        }
        break;
      default:
        return {
          readWrite: [],
          read: []
        }
        break;
    }
  } else {
    return {
      readWrite: [],
      read: []
    }
  }
}
