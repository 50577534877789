import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataMEC } from '@constants/reports/report-mec';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import moment from 'moment';

@Component({
  selector: 'app-report-mec',
  templateUrl: './report-mec.component.html',
  styleUrls: ['./report-mec.component.scss'],
})
export class ReportMECComponent {
  public reportDataMEC = reportDataMEC;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsInsitutions: SelectOption[];
  public error = 'Debes elegir un centro';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      selectedInstitution: [''],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })
      if (this.selectOptionsInsitutions.length > 0) {
        const hasAllInstitutionsOption = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (!hasAllInstitutionsOption) {
          this.selectOptionsInsitutions.unshift({
            value: '',
            label: 'Todos los centros',
          });
        }
      }
    });
  }

  handlePreviewClick(): void {
    if (this.form.valid) {
      const { selectedInstitution } = this.getFormData();

      this.setInfoRP(selectedInstitution);

      this.reportService.handlePreviewClick(this.router, this.reportDataMEC);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  getFormData(): {
    selectedInstitution: string;
  } {
    const { selectedInstitution } = this.form.getRawValue();

    return {
      selectedInstitution: selectedInstitution.id,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid) {
      const { selectedInstitution } = this.getFormData();

      this.setInfoRP(selectedInstitution);
      this.reportService.handlePrintClick(this.reportDataMEC);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handlePdfClick(): void {
    if (this.form.valid) {
      const { selectedInstitution } = this.getFormData();
      this.setInfoRP(selectedInstitution);
      this.reportService.handlePdfClick(this.reportDataMEC);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  handleExcelClick(): void {
    if (this.form.valid) {
      const { selectedInstitution } = this.getFormData();

      this.setInfoRP(selectedInstitution);
      this.reportService.handleExcelClick(this.reportDataMEC);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  setInfoRP(selectedInstitution: string, isPreview: boolean = false): void {
    console.log(selectedInstitution);

    const rp = JSON.stringify({
      LocaleId: '3082',
      InstitutionId: !selectedInstitution || selectedInstitution == '' ? null : String(selectedInstitution),
    });

    isPreview ? this.reportDataMEC.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    this.reportDataMEC.dataDownloadPDF.dn = this.reportDataMEC.dataRenderReport.dn = `Contadores_mec_${institution}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataMEC.dataDownloadExcel.dn = `Contadores_mec_${institution}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataMEC.data.rp = rp;
    this.reportDataMEC.dataDownloadPDF.rp = rp;
    this.reportDataMEC.dataDownloadExcel.rp = rp;
    this.reportDataMEC.dataRenderReport.rp = rp;
  }
}
