import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PreviewReportAPI, Report } from '@interfaces/report/report.interface';
import { CustomPaginatorIntl } from '@json/src/app/utils/custom-paginator';
import { environment } from '@json/src/environments/environment';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { ReportsService } from '@services/reports/reports.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-report-viewer',
    templateUrl: './report-viewer.component.html',
    styleUrls: ['./report-viewer.component.scss'],
    providers: [{provide: MatPaginatorIntl, useClass: CustomPaginatorIntl}],
})
export class ReportViewerComponent implements OnInit, OnDestroy {
    reportId: number;
    private subs: Subscription[] = [];
    public report: Report;
    public htmlContent: string;
    public iframePath: string;
    public safeURL: SafeUrl;
    public pageIndex: number;
    public pageSize: number = 1;
    public length: number;

    public prevData: PreviewReportAPI;
    public printData: any;
    public pdfData: any;
    public excelData: any;

    constructor(
        private http: HttpClient,
        private reportService: ReportsService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private loadingService: LoadingService,
        private modal: ModalService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle(`Vista Previa de Reporte`);
        this.gaService.pageView(`${window.location.href}`, `Vista Previa de Reporte`);

        this.getDataParams();
        this.getPreviewReport();
    }

    getDataParams(): void {
        this.route.queryParams.subscribe((params) => {
            this.reportId = params['id'] as number;
            const json = sessionStorage.getItem('report-' + this.reportId);
            if (json == null) {
                this.modal.openModalErrorInfo('No se ha podido encontrar la información del reporte, cierre la pestaña y vuelva a generar el reporte.', 'Error');
                return;
            }
            const report = JSON.parse(json);

            const prevData = JSON.parse(report?.data);
            this.printData = {
                dataRenderReport: JSON.parse(report?.print)
            }
            this.pdfData = {
                dataDownloadPDF: JSON.parse(report?.pdf)
            }
            this.excelData = {
                dataDownloadExcel: JSON.parse(report?.excel)
            }
            this.prevData = {
                rpp: prevData['rpp'],
                pts: prevData['pts'],
                fr: prevData['fr'],
                dn: prevData['dn'],
                rp: prevData['rp'],
            };
        });
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    dynamicTemplate: SafeHtml;
    getPreviewReport(): void {
        this.loadingService.start('Cargando Reporte...');
        this.subs.push(
            this.reportService.reportList.subscribe((report) => {
                if (report.fn != '' && report) {
                    this.report =
                        this.reportService.transformReportAPItoReportAPP(report);
                    const unsafeURL = this.reportService.parsePathHtml(
                        environment.urlBack,
                        this.report.filePath,
                        this.report.fileName
                    );
                    this.iframePath = unsafeURL;
                    this.safeURL =
                        this.sanitizer.bypassSecurityTrustResourceUrl(unsafeURL);
                    fetch(unsafeURL, {method: 'get'}).then(async data  => {
                        this.dynamicTemplate = this.sanitizer.bypassSecurityTrustHtml(await data.text());
                        this.loadingService.stop();
                    });
                    this.length = this.report.pq;
                }
            })
        );

        this.reportService.previewReport(this.prevData);
    }

    handlePageEvent(e: PageEvent): void {
        this.pageIndex = e.pageIndex;

        const unsafeURL = this.reportService.changePageHtml(
            this.iframePath,
            e.pageIndex + 1
        );

        this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeURL);
        fetch(unsafeURL, {method: 'get'}).then(async data  => {
            this.dynamicTemplate = this.sanitizer.bypassSecurityTrustHtml(await data.text());
        });
    }

    fetchHtmlDocument(path: string): void {
        this.http.get(path, { responseType: 'text' }).subscribe((html: string) => {
            this.htmlContent = html;
        });
    }

    print() {
        this.reportService.handlePrintClick(this.printData);
    }

    pdf() {
        this.reportService.handlePdfClick(this.pdfData);
    }

    excel() {
        this.reportService.handleExcelClick(this.excelData);
    }
}
