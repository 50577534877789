import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  formatDateToString(date: Date, hour?: string): string {
    const newDate = new Date(date);

    // If an hour is provided, set it in the time object
    if (hour) {
      const [hours, minutes] = hour.split(':').map(Number);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
    }

    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    const seconds = newDate.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  transformDateTimeIncrementMonth(input: string) {
    const date = new Date(input);

    // Increment the month by 1
    date.setMonth(date.getMonth() + 1);

    date.setHours(23, 59, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  transformDateTimeIncrementHours(input: string) {
    const date = new Date(input);

    date.setHours(23, 59, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  convertTimeStringToNumbers(time: string): { hours: number; minutes: number } {
    const parts = time.split(':');

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    return {
      hours: hours,
      minutes: minutes,
    };
  }

  transformToDateWithSpecificHours(input: string, time: string) {
    const { hours, minutes } = this.convertTimeStringToNumbers(time);
    const date = new Date(input);

    date.setHours(hours, minutes, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  transformDateTimeToStartDayMonth(input: string) {
    const date = new Date(input);

    date.setDate(1);
    date.setHours(23, 59, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  transformDateTimeToStartDayMonth00(input: string) {
    const date = new Date(input);

    date.setDate(1);
    date.setHours(0, 0, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  transformDateTimeToEndDayMonth(input: string) {
    const date = new Date(input);

    const month = date.getMonth();
    const year = date.getFullYear();

    const lastDay = new Date(year, month + 1, 0).getDate();

    date.setDate(lastDay);
    date.setHours(23, 59, 0);

    // Format the transformed date as a string in ISO format (YYYY-MM-DDTHH:mm:ss)
    const transformedDate = this.formatDateToString(date);
    return transformedDate;
  }

  transformObjectToDate(date: any): Date {
    return new Date(date._d);
  }

  diffDates(startDate: Date, endDate: Date): number {
    const oneDaySeconds = 1000 * 60 * 60 * 24;
    return Math.round(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        oneDaySeconds
    );
  }

  checkCollision(
    startDate1: Date,
    endDate1: Date,
    startDate2: Date,
    endDate2: Date
  ): boolean {
    const range1: [number, number] = [startDate1.getTime(), endDate1.getTime()];
    const range2: [number, number] = [startDate2.getTime(), endDate2.getTime()];

    // Check for overlap by comparing the start and end dates
    if (
      (range1[0] >= range2[0] && range1[0] <= range2[1]) || // start date 1 is within range 2
      (range2[0] >= range1[0] && range2[0] <= range1[1]) // start date 2 is within range 1
    ) {
      return true; // Collision detected
    }

    return false; // No collision
  }

  convertStringOfDate(date: any, hour?: string, backFormat?: boolean): string {
    const time = date == null ? new Date() : new Date(date);

    // If an hour is provided, set it in the time object
    if (hour) {
      const [hours, minutes] = hour.split(':').map(Number);
      time.setHours(hours);
      time.setMinutes(minutes);
    }

    const unixTimestamp = time.getTime();

    return backFormat ? `/Date(${unixTimestamp})/` : `${unixTimestamp}`;
  }

  convertDateToFormatYearMonthDay(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getUTCFullYear().toString();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');

    return year + month + day;
  }

  isMoreThanXDays(date1: Date, date2: Date, num: number) {
    // Extract day, month, and year from the first date
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    // Extract day, month, and year from the second date
    const day2 = date2.getDate();
    const month2 = date2.getMonth();
    const year2 = date2.getFullYear();

    // Check if the dates have the same day, month, and year
    if (day1 === day2 && month1 === month2 && year1 === year2) {
      return false; // Dates are the same
    }

    // Convert both dates to the same day of the year
    const convertedDate1 = new Date(year1, month1, day1);
    const convertedDate2 = new Date(year2, month2, day2);

    // Calculate the difference in milliseconds
    const timeDiffMilliseconds = Math.abs(
      convertedDate2.getTime() - convertedDate1.getTime()
    );

    // Calculate the number of days in the difference
    const daysDifference = timeDiffMilliseconds / (1000 * 60 * 60 * 24);

    // Check if it's more than num days
    return daysDifference > num;
  }

  areDatesEqual(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
}
