import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardAction } from '@interfaces/card-actions';
import { MedicinesService } from '@services/medicines.service';
import { ImageService } from '@services/image/image.service';
import { environment } from '@json/src/environments/environment';
import { medicineFilters } from '@services/mocks/medicine';
import { FilterOption } from '@interfaces/filter.interface';
import { PermissionType } from '@json/src/app/enums/PermissionType';
import { InstitutionService } from '@services/institution.service';
import { RenderReportAPI } from '@interfaces/report/report.interface';
import { getDateStringYMD, getTimeStringHM } from '@constants/funtions-utils';
import { Utils } from '@json/src/app/Utils';
import moment from 'moment';
import { DateService } from '@services/date.service';
import { ReportsService } from '@services/reports/reports.service';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
    @Input() id: number = 0;
    @Input() title: string;
    @Input() body: string[];
    @Input() route: string = '';
    @Input() typeGlobal: number = 0;
    @Input() img?: string;
    @Input() actions: CardAction[];
    @Input() alert: boolean = false;
    @Input() flag: number | undefined = undefined;
    @Input() fgp: boolean = false;
    @Input() colorHeader: string = '';
    @Input() nameTooltip: string = 'Sin documentos';
    @Input() greyed: boolean = false;
    @Input() containImage: boolean = false;
    @Input() canNavigate: boolean = true;

    @Output() deleteButtonCLicked = new EventEmitter<number>();

    @Input() defaultImg: string = `/assets/images/medical_pills2.svg`;
    public icons: (FilterOption | undefined)[] = [];
    public type: string;
    public src: string;
    PermissionType = PermissionType;

    defaultImgSetted: boolean = false;

    constructor(
        public medicineService: MedicinesService,
        public router: Router,
        public imageService: ImageService,
        private institutionService: InstitutionService,
        private dateService: DateService,
        private reportsService: ReportsService
    ) { }

    ngOnInit(): void {

        this.setIcons();
        this.setImage();
    }

    disabledClick($event: any): void {
        $event.stopPropagation()
        $event.preventDefault();
    }
    deletePatient($event: any): void {
        $event.stopPropagation()
        $event.preventDefault();
        this.deleteButtonCLicked.emit(this.id);
    }

    setIcons(): void {
        if ( this.flag !== undefined ) {
            const ids = this.medicineService.getBookmarkFlags(this.flag);

            this.icons = ids.map(i => {
                return medicineFilters.find(m => m.id == i)
            });
        }
    }

    setImage(): void {
        this.type = this.imageService.getEntityTarget(this.router.url);
        const formatedID = this.imageService.formatNumberImage(Number(this.id));
        const imageUrl = `${environment.urlBack}resources/assets/pics/${this.type}/e00/${formatedID}.jpg`;
        let name = this.title;
        if (name?.includes(',')) {
            const parts = name.split(',').map(s => s.trim());
            name = parts.slice(-1)[0] + ' ' + parts.slice(0, -1).join(' ');
        }
        this.loadImage(imageUrl, name?.split(' '));
    }

    loadImage(imageUrl: string, name: string[]): void {
        const img = new Image();
        const timestamp = new Date().getTime();
        const url = `${imageUrl}?timestamp=${timestamp}`;

        img.onload = () => {
            this.src = url;
        };

        img.onerror = () => {
            // If there is an error, set the URL of the alternative image
            this.src = this.defaultImg;
            this.defaultImgSetted = true;
        };

        img.src = url;
    }

    printPatientPF($event: any): void {
        $event.stopPropagation()
        $event.preventDefault();
        
        const today = new Date();
        const nextMonth = new Date();
        nextMonth.setMonth(today.getMonth() + 1);
        const instName = this.institutionService.getInstitutionName();
        const currentDate = getDateStringYMD(today);
        const currentTime = getTimeStringHM(today);
        const data: RenderReportAPI = {
            rpp: '/COROTA/PharmacologicReport',
            pts: 0,
            z: 100,
            htmf: false,
            fmt: 2,
            dl: false,
            dn: `Plan_farmacologico_${instName}_${currentDate}_${currentDate}_${currentTime}.pdf`,
            rp: JSON.stringify({
                LocaleId: "3082",
                StartDate: Utils.BUG_FixDateForTimeZone(moment(today).startOf('day').toDate())?.toISOString().split('.')[0],
                EndDate: this.dateService.transformDateTimeIncrementMonth(today.toISOString()),
                PatientId: this.id,
                InstitutionId: this.institutionService.getCurrentInstitution(),
                IncludeNoBlister: "1",
                Colegiado: ""
            })
        };
        console.log(data);
        this.reportsService.handlePrintClick({ dataRenderReport: data })
    }

    navigate() {
        if (this.canNavigate) {
            this.router.navigate([this.route + '/edit/' + this.id]);
        }
    }
}
