import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientREHistoryAPI } from '@interfaces/patient/patient.interface';
import { REPatientAPP } from '@interfaces/re-status.interface';

@Component({
  selector: 'app-edit-re',
  templateUrl: './edit-re.component.html',
  styleUrls: ['./edit-re.component.scss']
})
export class EditReComponent {

  public patient: REPatientAPP;
  public re: PatientREHistoryAPI = {
    InputDate: new Date(),
    RE: '',
    IsRE2: false,
    REFix: '',
    NextDispenseDate: null,
    Forget: false,
    Flag: 1
  };
  public isEdit: boolean = false;
  public create: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditReComponent>
  ) {
    if (data) {
      this.patient = data.patient;
      this.isEdit = !!data.reData;
      if (this.isEdit) {
        this.re = { ...data.reData };

        if ( !this.re.REFix ) {
          this.re.REFix = this.re.RE;
        }

      } else {
        this.re.RE = data.newNumber;
      }

      this.create = data.create;
    }
  }

  onConfirmClick(): void {
    if (!this.re.REFix?.toString().trim()) return;

    if (this.re.REFix === this.re.RE) this.re.REFix = null;
    this.re.Flag = this.re.REFix ? 0 : 1;
    this.dialogRef.close(this.re);
  }
}
