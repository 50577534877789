<div class="expand" *ngIf="expandAll && !compressAll"  (click)="openExpandedAll()" >expandir todo</div>
<div class="expand" *ngIf="!expandAll && compressAll" (click)="closedExpandedAll()" >Comprimir todo </div>

<mat-accordion class="example-headers-align"  multi>
  <ng-container *ngFor="let section of schema; let i = index;">
    <!-- Normal section -->
    <div class="section normal"
      *ngIf="section.customType === 'default'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">
      <!-- Title -->
      <div class="title">{{section.label}}</div>

      <!-- Content -->
      <div class="content">
        <ng-container *ngFor="let child of section.childs">
          <app-form-field
            class="field"
            [class]="child.size || ''"
            [ngClass]="child.customClass"
            [ngStyle]="child.customStyle"
            [type]="child.type"
            [label]="child.label"
            [hideLabel]="child.hideLabel"
            [hintMsg]="child.hintMsg"
            [placeholder]="child.placeholder"
            [fieldControl]="child.fieldControl | asFormControl"
            [customFieldData]="child.customFieldData"
            [options]="child.options"
            [readOnly]="child.readOnly"
            [min]="child.min"
            [max]="child.max"
            [inputPattern]="child.inputPattern"
            [capitalize]="child.capitalize"
            [uppercase]="child.uppercase"
            [errorMsg]="child.errorMsg ?? ''"
            [group]="child.group"
            [customStyle]="child.customStyle">
          </app-form-field>
        </ng-container>
      </div>
    </div>

    <!-- Allergies section -->
    <div class="section normal"
      *ngIf="section.customType === 'allergies'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">
      <!-- Title -->
      <div class="title">{{section.label}}</div>

      <!-- Content -->
      <div class="content">
        <ng-container *ngFor="let child of section.childs">
          <ng-container *ngIf="activeIngredientsOptions.length">
            <app-form-field
              type="select"
              label=""
              class="field col-12"
              [fieldControl]="child.fieldControl | asFormControl"
              [options]="activeIngredientsOptions"
              [multipleSelect]="true"
              [allowSelectAll]="false"
              [filterSelect]="true"
              [selectWithChips]="true"
              [selectCleanFilterInput]="true"
              [selectCleanFilterInputAndFocus]="false"
            ></app-form-field>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- Expansion panel -->
    <div class="section"
      *ngIf="section.customType === 'expansion'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <mat-expansion-panel (opened)="section.expanded = true" (closed)="section.expanded = false">
        <!-- HEADER EXPANSION PANEL -->
        <mat-expansion-panel-header>
          <div class="title" >
            {{section.label}}
            <button mat-icon-button
              #addButton
              class="add-button"
              *ngIf="section.addable && !section.fieldControl.disabled && section.expanded && Utils.hasPermission(Permission.PATIENTS_CLINICAL)().includes(PermissionType.WRITE)"
              [@inOutFade]="{value: true, params: {duration: '100ms'}}"
              (click)="addNewItem($event, section)">
              <mat-icon>add</mat-icon>
            </button>

            <!-- <mat-icon class="info" (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin
                #trigger="cdkOverlayOrigin">
                info
            </mat-icon> -->
            <button mat-icon-button
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              class="info-button"
              *ngIf="section.info && section.expanded"
              [@inOutFade]="{value: true, params: {duration: '100ms'}}"
              (click)="openInfo($event, section)"
              >
              <mat-icon>info</mat-icon>


              <ng-template *ngIf="showInfo.testhaynes"  cdkConnectedOverlay  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="showInfo.testhaynes" [cdkConnectedOverlayHasBackdrop]="true"
                  (backdropClick)="showInfo.testhaynes = false" (detach)="showInfo.testhaynes = false">
                  <div class="popover">
                    <div style="width: 100%;">
                      <h3 style="margin-bottom: 20px;">TEST DE HAYNES</h3>
                      <div style="display: flex;">
                        <div style="width: 90%;line-height: 23px;">
                          <ul type="a" style="padding-left: 15px;">
                            <li>Esta técnica se basa en preguntar al enfermo sobre su nivel de cumplimiento del tratamiento4.</li>
                            <li> Consta de 2 partes:</li>
                            <li> En la primera, se evita interrogar de forma directa al paciente sobre la toma de medicación, se intenta crear un ambiente adecuado de conversación, y se le comenta al paciente la dificultad de los enfermos para tomar la medicación mediante la siguiente frase: <b>«la mayoría de pacientes tienen dificultades en tomar todos sus comprimidos»</b></li>
                            <li>En la segunda parte del test se realiza la siguiente pregunta: <b>«¿tiene usted dificultades en tomar los suyos?».</b> <br> Si la respuesta es afirmativa, el paciente es incumplidor, será un método fiable y podrán utilizarse las medidas o intervenciones que se consideren necesarias. <br>Si responde que no, es posible que no diga la verdad por diversas causas4.<br> Entonces se insistirá preguntando: «¿cómo los toma?»: todos los días, muchos días, algunos días, pocos días o rara vez.</li>
                            <li> Finalmente, se realiza una tercera pregunta y se recoge lo que el paciente mencione sobre la siguiente reflexión: «Muchas personas tienen dificultad en seguir los tratamientos, ¿por qué no me comenta cómo le va a usted?».</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
              </ng-template> 

              <ng-template *ngIf="showInfo.drivingskill"  cdkConnectedOverlay  [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="showInfo.drivingskill" [cdkConnectedOverlayHasBackdrop]="true"
                (backdropClick)="showInfo.drivingskill = false" (detach)="showInfo.drivingskill = false">
                <div class="popover">
                  <div style="width: 100%;">
                    <h3 style="margin-bottom: 20px;">TEST DE HABILIDAD DE MANEJO</h3>
                    <div style="display: block;">
                      <div style="    width: 90%;line-height: 23px;">
                        <ol type="a" style="padding-left: 15px;">
                          <li>¿Deja de tomar alguna vez los medicamentos para tratar su enfermedad?</li>
                          <li>¿Toma los medicamentos a las horas indicadas?</li>
                          <li> Cuando se encuentra bien, ¿deja de tomar la medicación?</li>
                          <li> Si alguna vez le sienta mal, ¿deja usted de tomarla?/li>
                          
                          </ol>
                      </div>

                      <div style="width: 90%;line-height: 23px;">
                       <b> Se considera cumplidor si responde de forma correcta a las 4 preguntas: no/sí/no/no.</b>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

            </button>
          </div>
        </mat-expansion-panel-header>
        <!-- CONTENT EXPANSION PANEL -->
        <div class="contenedor-expansionpanel">
          <div class="content">
            <ng-container *ngFor="let child of section.childs">
              <ng-container [ngSwitch]="child.type">
                <ng-container  *ngSwitchCase="'checkbox'">
                  <app-form-field
                    class="field"
                    [class]="child.size || ''"
                    [ngClass]="child.customClass"
                    [ngStyle]="child.customStyle"
                    [type]="child.type"
                    [label]="child.label"
                    [hideLabel]="child.hideLabel"
                    [hintMsg]="child.hintMsg"
                    [placeholder]="child.placeholder"
                    [fieldControl]="child.fieldControl | asFormControl"
                    [customFieldData]="child.customFieldData"
                    [options]="child.options"
                    [readOnly]="child.readOnly"
                    [min]="child.min"
                    [max]="child.max"
                    [inputPattern]="child.inputPattern"
                    [capitalize]="child.capitalize"
                    [uppercase]="child.uppercase"
                    [errorMsg]="child.errorMsg ?? ''"
                    [group]="child.group"
                    [customStyle]="child.customStyle">
                  </app-form-field>
                </ng-container>

                <!-- Custom form field -->
                <ng-container *ngSwitchCase="'custom'">
                  <ng-container [ngSwitch]="child.customType">

                    <!-- Vital signs -->
                    <ng-container *ngSwitchCase="'array-form-vitals'">
                      <app-vital-signs
                        #vitalSigns
                        [fieldControl]="child.fieldControl | asFormControl"
                        [formConfig]="child.customFieldData"
                        [readOnly]="child.readOnly">
                      </app-vital-signs>
                    </ng-container>

                    <!-- Clinical analysis -->
                    <ng-container *ngSwitchCase="'clinical-analysis-form'">
                      <app-clinical-analysis
                        #clinicalAnalysis
                        [fieldControl]="child.fieldControl | asFormControl"
                        [formConfig]="child.customFieldData"
                        [readOnly]="child.readOnly">
                      </app-clinical-analysis>
                    </ng-container>

                    <!-- Fragility Dependency -->
                    <ng-container *ngSwitchCase="'fragility-dependency'">
                      <app-fragility-dependency
                        #fragilityDependency
                        [fieldControl]="child.fieldControl | asFormControl"
                        [formConfig]="child.customFieldData"
                        [readOnly]="child.readOnly">
                      </app-fragility-dependency>
                    </ng-container>

                    <!--Test Haynes -->
                    <ng-container *ngSwitchCase="'test-haynes'">
                      <app-test-haynes
                        #testHaynes
                        [fieldControl]="child.fieldControl | asFormControl"
                        [formConfig]="child.customFieldData"
                        [readOnly]="child.readOnly">
                      </app-test-haynes>
                    </ng-container>

                    <!--Driving Skill -->
                    <ng-container *ngSwitchCase="'driving-skill'">
                      <app-driving-skill
                        #drivingSkill
                        [fieldControl]="child.fieldControl | asFormControl"
                        [formConfig]="child.customFieldData"
                        [readOnly]="child.readOnly">
                      </app-driving-skill>
                    </ng-container>

                    <!-- IMC -->
                    <ng-container *ngSwitchCase="'imc'">
                      <app-imc
                        class="field"
                        [class]="child.size || ''"
                        [ngClass]="child.customClass"
                        [ngStyle]="child.customStyle"
                        [label]="child.label"
                        [hintMsg]="child.hintMsg"
                        [fieldControl]="child.fieldControl | asFormControl"
                        [fieldsData]="child.customFieldData">
                      </app-imc>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <!-- Default form field -->
                <ng-container *ngSwitchDefault>
                    <app-form-field
                      class="field"
                      [class]="child.size || ''"
                      [ngClass]="child.customClass"
                      [type]="child.type"
                      [label]="child.label"
                      [hideLabel]="child.hideLabel"
                      [hintMsg]="child.hintMsg"
                      [placeholder]="child.placeholder"
                      [fieldControl]="child.fieldControl | asFormControl"
                      [customFieldData]="child.customFieldData"
                      [options]="child.options"
                      [readOnly]="child.readOnly"
                      [min]="child.min"
                      [max]="child.max"
                      [inputPattern]="child.inputPattern"
                      [capitalize]="child.capitalize"
                      [uppercase]="child.uppercase"
                      [errorMsg]="child.errorMsg ?? ''"
                      [group]="child.group"
                      [customStyle]="child.customStyle"
                      >
                    </app-form-field>

                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
    </div>

    <!-- Vaccines section -->
    <div class="section vaccines"
      *ngIf="section.customType === 'vaccines'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">
      <!-- Title -->
      <div class="title">{{section.label}}</div>

      <!-- Content -->
      <div class="content vaccines">
        <app-vaccines [schema]="section"></app-vaccines>
      </div>
    </div>
  </ng-container>
</mat-accordion>
