import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'lazy-img',
  templateUrl: './lazy-img.component.html',
  styleUrls: ['./lazy-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyImgComponent implements OnChanges {
  @ViewChild('image') image: ElementRef | undefined = undefined;

  @Input() source = '';
  @Input() alt = '';
  @Input() size: number;
  @Input() contain: boolean = false;
  @Input() lens: boolean = false;

  @Output() onLoad: EventEmitter<HTMLImageElement> = new EventEmitter();
  @Output() onError: EventEmitter<null> = new EventEmitter();

  public isLoaded = false;

  public isSvg: boolean = false;

  constructor(private cdf: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // @ts-ignore
    if ( changes.source ) {
      this.isSvg = this.source?.includes('.svg');
    }
  }

  markForChange() {
    this.isLoaded = true;
    this.cdf.markForCheck();
    this.onLoad.emit(this.image?.nativeElement);
  }
}
