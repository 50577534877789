import { trigger, transition, animate, style } from '@angular/animations';

export const inOutFade = trigger('inOutFade', [ 
   transition(':enter', [
      style({opacity: '0'}),
      animate('{{ duration }}', style({opacity: '1'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }}', style({opacity: '0'}))
   ]) 
]);
 
export const inOutFromBottom = trigger('inOutFromBottom', [ 
   transition(':enter', [
      style({transform: 'translateY(100%)'}),
      animate('{{ duration }} ease', style({transform: 'translateY(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({transform: 'translateY(100%)'}))
   ]) 
]);
 
export const inOutFromTop = trigger('inOutFromTop', [ 
   transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('{{ duration }} ease', style({transform: 'translateY(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({transform: 'translateY(-100%)'}))
   ]) 
]);
 
export const inOutFromLeft = trigger('inOutFromLeft', [ 
   transition(':enter', [
      style({transform: 'translateX(-100%)'}),
      animate('{{ duration }} ease', style({transform: 'translateX(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({transform: 'translateX(-100%)'}))
   ])
]);
 
export const inOutFromRight = trigger('inOutFromRight', [ 
   transition(':enter', [
      style({transform: 'translateX(+100%)'}),
      animate('{{ duration }} ease', style({transform: 'translateX(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({transform: 'translateX(+100%)'}))
   ]) 
]);

export const inOutFadeFromBottom = trigger('inOutFadeFromBottom', [ 
   transition(':enter', [
      style({opacity: '0', transform: 'translateY(30%)'}),
      animate('{{ duration }} ease', style({opacity: '1', transform: 'translateY(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({opacity: '0', transform: 'translateY(30%)'}))
   ]) 
]);
 
export const inOutFadeFromTop = trigger('inOutFadeFromTop', [ 
   transition(':enter', [
      style({opacity: '0', transform: 'translateY(-30%)'}),
      animate('{{ duration }} ease', style({opacity: '1', transform: 'translateY(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({opacity: '0', transform: 'translateY(-30%)'}))
   ]) 
]);
 
export const inOutFadeFromLeft = trigger('inOutFadeFromLeft', [ 
   transition(':enter', [
      style({opacity: '0', transform: 'translateX(-30%)'}),
      animate('{{ duration }} ease', style({opacity: '1', transform: 'translateX(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({opacity: '0', transform: 'translateX(-30%)'}))
   ])
]);
 
export const inOutFadeFromRight = trigger('inOutFadeFromRight', [ 
   transition(':enter', [
      style({opacity: '0', transform: 'translateX(+30%)'}),
      animate('{{ duration }} ease', style({opacity: '1', transform: 'translateX(0%)'})) 
   ]),
   transition(':leave', [
      animate('{{ duration }} ease', style({opacity: '0', transform: 'translateX(+30%)'}))
   ]) 
]);
