import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataIncontinence, reportDataIncontinenceRappelTena, reportDataIncontinenceProfit } from '@constants/reports/report-incontinence';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    Brand,
    CompanyDeliveryNote,
    Diaper,
    MedPharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-incontinence',
    templateUrl: './report-incontinence.component.html',
    styleUrls: ['./report-incontinence.component.scss'],
})
export class ReportIncontinenceComponent {
    public reportDataIncontinence: any;
    public currentInstitution: number;
    public showPatientSelect: boolean = false;
    public showMedicineGroupSelect: boolean = false;
    public showOrders: boolean = false;
    public selectOptionsPatients: PatientPharma[];
    public selectOptionsGroupMedicine: MedPharma[];
    public selectedOptionsBrandDiapers: Brand[];
    public selectedOptionsBrandDiapersOptions: SelectOption[];
    public selectedOptionsRefDiapers: Diaper[];
    public selectedOptionsRefDiapersOptions: SelectOption[];
    public selectedOptionsCompanies: CompanyDeliveryNote[];
    public form: FormGroup;
    public report: Report;
    public pathReportDownload: string;

    public selectOptionsInsitutions: SelectOption[];
    
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            startDate: [moment()],
            endDate: [moment().add(1, 'week')],
            typeReport: ['1'],
            groupData: ['1'],
            selectedPatient: [''],
            selectedRefDiapers: [''],
            selectedBrandDiapers: [''],
            stateDateFilter: [''],
            startSeq: [''],
            endSeq: [''],
            selectedInstitution: ['', Validators.required],
            refDiapersCheck: [false],
            brandDiapersCheck: [false],
            dateIntervalCheck: [false],
            orderIntervalCheck: [false],
            selectedCompany: [''],
            deliveryNoteCheck: [false],
            deliveryNoteFrom: [],
            deliveryNoteTo: [],
            groupBrandsCheck: [false]
        });
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Incontinencia`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Incontinencia`);

        this.getDataForTheSelects();
        this.typeGlobal = this.roleManager.isPharma();

        this.form.valueChanges.subscribe((data) => {
            this.institutionService.selectInstitutionById(this.form.controls['selectedInstitution'].value.id);
        });
    }

    handlePreviewClick(): void {
        if (this.form.valid || this.form.controls['typeReport'].value === '3') {
            this.reportDataIncontinence = this.getReportInterface();
            this.reportDataIncontinence.data.rp = this.getInfoRP();
            this.reportDataIncontinence.data.dn = Utils.buildUniqueFileName + "_{0}.html";
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataIncontinence
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());
            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            this.form.controls['selectedInstitution'].setValue(
                this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
            )
          });

        this.reportService.getRepParamsEditDataDiapers(null).subscribe((result) => {
            const { payload } = result;
            this.selectedOptionsRefDiapers = payload.diapers;
            this.selectedOptionsBrandDiapers = payload.brands;
            this.selectedOptionsCompanies = payload.AlbaranEmpresas;

            this.selectedOptionsRefDiapersOptions = this.selectedOptionsRefDiapers.map((item: Diaper) => {
                return { label: item.nam, value: item.id };
            })

            this.selectedOptionsBrandDiapersOptions = this.selectedOptionsBrandDiapers.map((item: Brand) => {
                return { label:item.nam, value: item.id };
            })
        });
    }

    // toggleSelectPatient(): void {
    //     this.showPatientSelect = !this.showPatientSelect;
    //     if (!this.showPatientSelect) {
    //         this.form.patchValue({
    //             selectedPatient: '',
    //         });
    //     }
    // }

    // toggleSelectMedicine(): void {
    //     this.showMedicineGroupSelect = !this.showMedicineGroupSelect;
    //     if (!this.showMedicineGroupSelect) {
    //         this.form.patchValue({
    //             selectedGroupMedicine: '',
    //         });
    //     }
    // }

    // toggleSelectOrders(): void {
    //     this.showOrders = !this.showOrders;
    //     if (!this.showOrders) {
    //         this.form.patchValue({
    //             startSeq: '',
    //             endSeq: '',
    //         });
    //     }
    // }

    handlePrintClick(): void {
        if (this.form.valid || this.form.controls['typeReport'].value === '3' ) {
            this.reportDataIncontinence = this.getReportInterface();
            this.reportDataIncontinence.dataRenderReport.rp = this.getInfoRP();
            this.reportService.handlePrintClick(this.reportDataIncontinence);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid || this.form.controls['typeReport'].value === '3') {
            this.reportDataIncontinence = this.getReportInterface();
            this.reportDataIncontinence.dataDownloadPDF.rp = this.getInfoRP();
            this.reportService.handlePdfClick(this.reportDataIncontinence);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid || this.form.controls['typeReport'].value === '3') {
            this.reportDataIncontinence = this.getReportInterface();
            this.reportDataIncontinence.dataDownloadExcel.rp = this.getInfoRP();
            this.reportService.handleExcelClick(this.reportDataIncontinence);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    getReportInterface() {
        let report: any;
        
        switch( this.form.controls['typeReport'].value ) {
            case '1':
            case '2':
                report = reportDataIncontinence;
                break;
            case '3':
                report = reportDataIncontinenceRappelTena;
                break;
            case '4':
                report = reportDataIncontinenceProfit;
                break;
        }

        const now = moment();
        report.dataDownloadPDF.dn = report.dataRenderReport.dn =
            `Reporte_incontinencia_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        report.dataDownloadExcel.dn = `Reporte_incontinencia_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

        return report;
    }

    getFormData(): {
        startDate: string | null;
        endDate: string | null;
        selectedRefDiapers: string;
        selectedBrandDiapers: string;
        typeReport: string;
        groupData: string;
        startSeq: string;
        endSeq: string;
        stateDateFilter: string;
        refDiapersCheck: boolean,
        brandDiapersCheck: boolean,
        dateIntervalCheck: boolean,
        orderIntervalCheck: boolean,
        selectedCompany: string,
        deliveryNoteCheck: boolean,
        deliveryNoteFrom: number,
        deliveryNoteTo: number,
        groupBrandsCheck: boolean
    } {
        const startDateForm = this.form.get('startDate')?.value;
        const startDate =
            startDateForm === ''
                ? null
                : this.dateService.formatDateToString(startDateForm);

        const endDateForm = this.form.get('endDate')?.value;
        const endDate =
            endDateForm === ''
                ? null
                : this.dateService.formatDateToString(endDateForm);
        const typeReport = this.form.get('typeReport')?.value;
        const groupData = this.form.get('groupData')?.value;

        const startSeq = this.form.get('startSeq')?.value;
        const endSeq = this.form.get('endSeq')?.value;

        const stateDateFilter = this.form.get('stateDateFilter')?.value;

        const refDiapersCheck = this.form.get('refDiapersCheck')?.value;
        const brandDiapersCheck = this.form.get('brandDiapersCheck')?.value;
        const dateIntervalCheck = this.form.get('dateIntervalCheck')?.value;
        const orderIntervalCheck = this.form.get('orderIntervalCheck')?.value;
        const selectedCompany = this.form.get('selectedCompany')?.value;
        const deliveryNoteCheck = this.form.get('deliveryNoteCheck')?.value;
        const deliveryNoteFrom = this.form.get('deliveryNoteFrom')?.value;
        const deliveryNoteTo = this.form.get('deliveryNoteTo')?.value;
        const groupBrandsCheck = this.form.get('groupBrandsCheck')?.value;

        const selectedRefDiapers = refDiapersCheck && typeReport === '1' ? this.form.get('selectedRefDiapers')?.value : '';
        const selectedBrandDiapers = brandDiapersCheck && (typeReport === '1' || typeReport === '4') ? this.form.get('selectedBrandDiapers')?.value : '';

        return {
            startDate,
            endDate,
            selectedRefDiapers,
            selectedBrandDiapers,
            typeReport,
            groupData,
            startSeq,
            endSeq,
            stateDateFilter,
            refDiapersCheck,
            brandDiapersCheck,
            dateIntervalCheck,
            orderIntervalCheck,
            selectedCompany,
            deliveryNoteCheck,
            deliveryNoteFrom,
            deliveryNoteTo,
            groupBrandsCheck
        };
    }

    getInfoRP(): string {
        const {
            startDate,
            endDate,
            selectedRefDiapers,
            selectedBrandDiapers,
            typeReport,
            groupData,
            startSeq,
            endSeq,
            stateDateFilter,
            refDiapersCheck,
            brandDiapersCheck,
            dateIntervalCheck,
            orderIntervalCheck,
            selectedCompany,
            deliveryNoteCheck,
            deliveryNoteFrom,
            deliveryNoteTo,
            groupBrandsCheck
        } = this.getFormData();

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id

        console.log(selectedCompany, deliveryNoteFrom, deliveryNoteTo);

        let data;

        if ( typeReport !== '3' ) {
            data = {
                LocaleId: '3082',
                ReportType: typeReport,
                GroupType: groupData,
                GroupByBrandDistr: groupBrandsCheck || 'false',
                InstitutionList: currentInstitution,
                // : this.institutionService.getCurrentInstitution() + '',
                OrderStatus: stateDateFilter || '1',
                FilterFlag: '0',
                StartDate: startDate ? Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split(".")[0] : null,
                EndDate: endDate ? Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate())?.toISOString().split(".")[0] : null,
                StartSeqId: startSeq ? String(startSeq) : null,
                EndSeqId: endSeq ? String(endSeq) : null,
                DiaperList: selectedRefDiapers.toString() || null,
                BrandList: selectedBrandDiapers.toString() || null,
                BrandDistributorName: null,
            };
        } else if ( typeReport === '3' ) {
            data = {
                LocaleId: '3082',
                StartAlbaranId: deliveryNoteFrom ? String(deliveryNoteFrom) : null,
                EndAlbaranId: deliveryNoteTo ? String(deliveryNoteTo) : null,
                AlbaranEmpresa: selectedCompany ? String(selectedCompany) : String(null)
            };
        }

        return JSON.stringify(data);
    }
}
