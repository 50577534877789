import { Utils } from "../../Utils";

export const reportDataAuthBlisters = {
  data: {
    rpp: '/COROTA/BlankAnnexCI',
    pts: 1,
    rp: '{}',
    dn: '',
    fr: true,
  },
  dataDownloadPDF: {
    rpp: '/COROTA/BlankAnnexCI',
    pts: 0,
    fmt: 2,
    dn: 'Autorizacion_de_blisters_paciente.pdf',
    rp: '{}',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/BlankAnnexCI',
    pts: 0,
    fmt: 3,
    dn: 'Autorizacion_de_blisters_paciente.xls',
    rp: '{}',
  },
  dataRenderReport: {
    rpp: '/COROTA/BlankAnnexCI',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: 'Autorizacion_de_blisters_paciente.pdf',
    rp: '{}',
  },
};
