<ng-container *ngFor="let section of schema; let i = index;">
  <!-- Normal section -->
  <div class="section"
    *ngIf="section.customType === 'default'"
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">

    <div class="title" >{{section.label}}</div>
    <!-- Content -->
    <div class="content">
      <ng-container *ngFor="let child of section.childs">
        <app-form-field
          class="field"
          [class]="child.size || ''"
          [ngClass]="child.customClass"
          [ngStyle]="child.customStyle"
          [type]="child.type"
          [label]="child.label"
          [hideLabel]="child.hideLabel"
          [hintMsg]="child.hintMsg"
          [placeholder]="child.placeholder"
          [fieldControl]="child.fieldControl | asFormControl"
          [customFieldData]="child.customFieldData"
          [options]="child.options"
          [readOnly]="child.readOnly"
          [min]="child.min"
          [max]="child.max"
          [inputPattern]="child.inputPattern"
          [capitalize]="child.capitalize"
          [uppercase]="child.uppercase"
          [errorMsg]="child.errorMsg ?? ''"
          [group]="child.group"
          [customStyle]="child.customStyle">
        </app-form-field>
      </ng-container>
    </div>
  </div>

    <!-- Normal section -->
  <div class="section"
    *ngIf="section.customType !== 'default' && interactionTitle"
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">

    <div class="title" >{{interactionTitle}}</div>

    <!-- Content -->
    <div class="content">
      <textarea class="description" cdkTextareaAutosize readonly>{{ interactionInfo }}</textarea>
    </div>
  </div>

</ng-container>
