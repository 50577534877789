import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IMCFieldsData } from '@interfaces/dynamic-form.interface';
import { Utils } from '@json/src/app/Utils';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-imc',
    templateUrl: './imc.component.html',
    styleUrls: ['./imc.component.scss'],
})
export class ImcComponent implements OnInit, OnDestroy {
    @Input() label: string;
    @Input() fieldsData?: any | IMCFieldsData;
    @Input() fieldControl?: FormControl;
    @Input() hintMsg?: string;

    Utils = Utils;

    public control = new FormControl('');

    private texts = {
        0: { label: 'Peso insuficiente', condition: '<18.5' },
        1: { label: 'Normopeso', condition: '<25' },
        2: { label: 'Sobrepeso grado I', condition: '<27' },
        3: { label: 'Sobrepeso grado II (preobesidad)', condition: '<30' },
        4: { label: 'Obesidad de tipo I', condition: '<35' },
        5: { label: 'Obesidad de tipo II', condition: '<40' },
        6: { label: 'Obesidad de tipo III (mórbida)', condition: '<50' },
        7: { label: 'Obesidad de tipo IV (extrema)', condition: '+50' },
    };

    private subs: Subscription[] = [];

    ngOnInit(): void {
        const fieldWName = this.fieldsData?.weightFieldName;
        const fieldHName = this.fieldsData?.heigthFieldName;
        if (fieldWName && fieldHName) {
            const controls =
                this.fieldControl?.parent?.controls ?? new FormControl('');
            const controlH: AbstractControl =
                controls[fieldHName as keyof typeof controls];
            const controlW: AbstractControl =
                controls[fieldWName as keyof typeof controls];
            this.subs.push(
                controlH.valueChanges.subscribe((v) => {
                    this.updateIMC(controlH.value, controlW.value);
                }),
                controlW.valueChanges.subscribe((v) => {
                    this.updateIMC(controlH.value, controlW.value);
                })
            );
        }

        // this.control.disable();
        this.control.setValue('');
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    updateIMC(height: number, weight: number): void {
        const heightMeters = height / 100;
        const imc = weight / heightMeters ** 2;
        const imcNumber = Number.isNaN(imc) ? 0 : imc;
        const imcText = this.imcText(imcNumber);
        const text = `${imcNumber.toFixed(2)} - ${imcText}`;
        this.control.setValue(text);
    }

    imcText(imc: number): string {
        let result = '';

        if (imc >= 50) result = this.texts[7].label;
        if (imc < 50) result = this.texts[6].label;
        if (imc < 40) result = this.texts[5].label;
        if (imc < 35) result = this.texts[4].label;
        if (imc < 30) result = this.texts[3].label;
        if (imc < 27) result = this.texts[2].label;
        if (imc < 25) result = this.texts[1].label;
        if (imc < 18.5) result = this.texts[0].label;

        return result;
    }
}
