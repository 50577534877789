import { Component, Input } from '@angular/core';
import { entityDataReportButtons } from '@constants/reports/entity-report-buttons';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import moment from 'moment';

@Component({
  selector: 'app-entity-report-actions',
  templateUrl: './entity-report-actions.component.html',
  styleUrls: ['./entity-report-actions.component.scss'],
})
export class EntityReportActionsComponent {
  public entityDataReportButtons = entityDataReportButtons;
  public rp: string = '';
  @Input() dataItems: string = '';
  @Input() exportExcelLabel?: string = 'Exportar Excel';
  @Input() institutionId: number | null;

  constructor(
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {}

  handlePrint(): void {
    const now = moment();

    this.rp = this.getInfoRP();
    this.entityDataReportButtons.dataRenderReport.rp = this.rp;
    this.entityDataReportButtons.dataRenderReport.dn = `Listado_de_residentes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;

    this.reportService.handlePrintClick(this.entityDataReportButtons);
  }

  handleExcel(): void {
    const now = moment();
    
    this.rp = this.getInfoRP();
    this.entityDataReportButtons.dataDownloadExcel.rp = this.rp;
    this.entityDataReportButtons.dataDownloadExcel.dn = `Listado_de_residentes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    this.reportService.handleExcelClick(this.entityDataReportButtons);
  }

  getInfoRP(): string {
    const rp = JSON.stringify({
      LocaleId: '3082',
      TitleLocale: 'ListadoDePacientes',
      InstitutionId: this.institutionId,
      Items: this.dataItems,
      AtHome: null,
      Mutuas: null,
      SelectFilter: '',
    });
    return rp;
  }
}
