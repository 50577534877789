// FIXME: Cuando tengamos las llamadas a la api, crear una Patient response y una clase que iguale

import { ActiveIngredient, MedicineDropdownApp } from '@interfaces/medicine/medicine.interface';
import { FormControl } from '@angular/forms';
import { objectToXMLString } from '@constants/funtions-utils';
import {
    dictionaryBlocks,
    dictionaryPatternDay,
} from '@constants/dictionary-xml';
import { Utils } from '../../Utils';

// las respuestas del back con las del front
export interface Patient {
    number: string;
    name: string;
    department: string;
    expedition?: Date | null;
    birthday: Date;
    id: number;
    gender?: Gender;
    imagePath?: string;
    siteID: string;
    inst: string;
    loginDate: string;
    alert?: boolean;
    sm?: boolean;
    mm?: number;
    blq?: number | null;
    cip?: string;
    cipaPattern?: string;
    privateInsurance?: boolean;
}

export enum Gender {
    F = 'F',
    M = 'M',
}
export interface PatientAPI {
    //TODO: Por definir
    blq: null;
    bth: string; // date
    dep: string;
    ex: null;
    gnd: Gender;
    id: number;
    ins: string;
    mm: number | null; // preguntar que es
    nam: string;
    num: string;
    nimg: number;
    alrt: 0 | 1;
    cip: string;
    DNI: string;
    TSI: null; // preguntar que es
    sm: boolean;
    CipaPattern: string;
    SeguroPrivado: boolean;
}

export interface PatientPrescriptionFilter {
    patientId: number;
    dateFrom: Date;
    dateUntil: Date;
}

export interface PatientReviewPrescriptionsREQuantityFilter {
    StartDate: Date;         // "2023-07-25T22:00:00.000Z"
    InstitutionId: number;       // 218
    FarmaticNumber: number;       // 39332
    PrescrList: PrescrList[]; //
}
export interface PrescrList {
    DrugId: string;         // "688969"
    DrugIdInvc: null | string;  // "732521"
}

export type StopPrescriptionResponseAPI = {
    NewPrescriptionId: number;
}[];
export interface PrescriptionResponseAPI {
    PrescriptionId: number; // 458108
    StartDate: Date; // "2021-01-25T00:00:00.000Z"
    EndDate: Date;
    CurrentDate: Date;
    DrugName: string; // "ACFOL 5MG"
    DrugId: string; // "939579"
    DrugIdInvc: string; // "725579"
    Obsolete: boolean;
    NoBlister: number; // 0
    RecurrencePattern: number; // Recurrence pattern:
    // 1- Diario cada X días
    // 2- Semanal
    // 3- Diario pares
    // 4- Diario impares
    // 5- Mensual
    // 6- Si precisa (S/P)
    // 7- Cíclico
    OccurrenceCount: number; // 2 - Ciclico 2c3 pues esto es el 2
    RecurrenceInterval: number; // 1 (cada x)
    ActivePeriods: null; //
    LUN: number; // 1
    MAR: number;
    MIE: number;
    JUE: number;
    VIE: number;
    SAB: number;
    DOM: number;
    NoStock: null; //
    PE: Date; //
    QtyMEC: null; //
    PayMed: boolean; // true
    AdminRoute: string; // "Oral"
    Observaciones: null; //
    ForcedPayment: number; // 0
    itks: Itk[];
    FGP: boolean;
    HospitalUse: boolean;
    PA: ActiveIngredient[];
    GrupoPeligro: number;
}

export interface PrescriptionResponseREAPI {
    PrescriptionId: number;  // 788801
    PrescrStartDate: Date;    // "2023-03-03T00:00:00.000Z"
    DrugId: string;  // "152729"
    DrugIdInvc: string;  // "152729"
    DrugName: string;  // "TALQUISTINA"
    NoStock: null;    // ??
    Opioide: boolean; // false
    MedicineIdEstup: null;    // ??
    MedicineDrugIdEstup: null;    // ??
    MedicineNameEstup: null;    // ??
    QtyEstup: null;    // ??
    ReviewedEstup: null;    // ??
    NoBlister: number;  // 1
    PPB: null;    // ??
    PeriodQty: number;  // 30
    Boxes: number;  // -1
    RecurrencePattern: number;  // 6
    IssueDate: Date | null;    // "2023-07-31T00:00:00.000Z"
    IssueType: number | null;  // 128
    IssueNote: string | null;  // "patata"
    QtySale: number;  // -1
    QtyMEC: null;    // ??
    ForcedPayment: number;  // 1
    Agudo: boolean; // false
    itks: Itk[];   // ??
    hasItksOod: boolean; // false
}

export interface PatientREChangesAPI {
    PatientId: number; // 30783
    ReIssues: string; // <ArrayOfReReview><ReReview><PrescriptionId>511385</PrescriptionId></ReReview><ReReview><PrescriptionId>528863</PrescriptionId></ReReview><ReReview><PrescriptionId>509499</PrescriptionId></ReReview><ReReview><PrescriptionId>666779</PrescriptionId></ReReview></ArrayOfReReview>"
    FarmaticNumber: number; // 28930
    ReNote: string; // "NO SERVIR BOI K, hasta nuevo aviso"
    Reviewed: Date | null; // "2023-05-04T07:43:00.000Z"
    DailyChanges: DailyChange[];
    ReFixes: PatientReFix[];
    DNI: string; // "36891443H"
    Trouble: boolean; // true
    CapExt: boolean; // false
    Estupes: any[]; // ??
}
export interface DailyChange {
    OpId: number; // 391328
    Flag: number; // 1
}
export interface PatientReFix { // Casi como PatientREHistoryAPI pero no
    PatientId: number; // 30758
    RE: string; // "63787718254"
    REFix: string | null; // "1111222222222"
    IsRE2: boolean; // false
    NextDispenseDate: Date | null; // "2023-05-09T22:00:00.000Z"
    Forget: boolean; // false
}

export interface PatientREHistoryAPI {
    InputDate: Date;           // "2023-03-14T15:30:00.000Z"
    RE: string;         // "64165619400"
    IsRE2: boolean;        // false -> si es aguda
    REFix: string | null;  // "64165619422" -> se muestra este y se pone naranja
    NextDispenseDate: Date | null;    // "2023-05-05T22:00:00.000Z"
    Forget: boolean;        // false -> deshabilitada
    Flag: number;         // 1 -> (APF)
}

export interface ReviewWeekAPI {
    prs: PrescriptionResponseREAPI[];
    reHistory: PatientREHistoryAPI[];
}

export interface ReviewWeetQuantitiesAPI {
    FarmaticNumber: number;
    prescrList: QuantitiesAPI[]
}

export interface QuantitiesAPI {
    DrugId: string;  // '0001'
    QtySale: number; // 2
}

export interface PrescriptionApp extends PrescriptionResponseAPI {
    tags: PrescriptionTag[];
    flags: {
        canDelete: boolean;
        canEdit: boolean;
        canStop: boolean;
    };
}

export interface PrescriptionEditAPI {
    result1: Result1[];
    result2: Result2[];
}
export class PrescriptionInteractionDataAPI {
    public Id: number | null; // id prescripción
    public PatientId: number;
    public MedicineId: number;
    public StartDate: Date;
    public EndDate: Date | null;
    public OccurrenceCount: number | null;
    public RecurrencePattern: number;
    public RecurrenceInterval: number;
    public IntakeSchedule: string; // xml string
    public ActivePeriods: number | null;

    constructor(
        input: PrescriptionEditApp,
        patientId: number,
        prescriptionId?: number
    ) {
        this.Id = prescriptionId || null;
        this.PatientId = patientId;
        this.MedicineId = input.medicine.id;
        this.StartDate = Utils.BUG_FixDateForTimeZone(Utils.zeroTimeDate(input.since)) as Date;
        this.EndDate = Utils.BUG_FixDateForTimeZone(Utils.zeroTimeDate(input.until));
        this.OccurrenceCount =
            input.pattern === "cyclic" && !input.ifNeeded ? Number(input.cyclicActive) : null;
        this.RecurrencePattern = this.getReccurrencePattern(input);
        this.RecurrenceInterval = this.getRecurrenceInterval(input);
        this.IntakeSchedule = this.getIntakeSchedule(input.table);
        this.ActivePeriods = this.getActivePeriods(input);
    }

    getReccurrencePattern(input: PrescriptionEditApp): number {
        let result = 0;

        switch (input.pattern) {
            case 'daily':
                switch (input.dailyPattern) {
                    case 'repeat each':
                        result = 1;
                        break;
                    case 'even days':
                        result = 3;
                        break;
                    case 'odd days':
                        result = 4;
                        break;
                }
                break;
            case 'weekly':
                result = 2;
                break;
            case 'monthly':
                result = 5;
                break;
            case 'cyclic':
                result = 7;
                break;
        }
        if (input.ifNeeded) result = 6;

        return result;
    }
    getRecurrenceInterval(input: PrescriptionEditApp): number {
        let result: number = 1;
        switch (input.pattern) {
            case 'daily':
                switch (input.dailyPattern) {
                    case 'repeat each':
                        result = Number(input.dailyRepeat);
                        break;
                }
                break;
            case 'weekly':
                result = Number(input.weeklyRepeat);
                break;
            case 'monthly':
                result = Number(input.monthlyRepeat);
                break;
            case 'cyclic':
                result = Number(input.cyclicRest);
                break;
        }
        if (input.ifNeeded) result = 1;
        return result;
    }

    getIntakeSchedule(input: IntakeTable): string {
        const openArray = '<ArrayOfIntakeMoment>',
            closeArray = '</ArrayOfIntakeMoment>',
            openMoment = '<IntakeMoment>',
            closeMoment = '</IntakeMoment>',
            department = '<DepartmentId>{value}</DepartmentId>',
            timeOfDay = '<TimeOfDay>{value}</TimeOfDay>',
            dayCode = '<DayCode>{value}</DayCode>',
            quantity = '<Qty>{value}</Qty>';

        let result = '' + openArray;
        Object.keys(input).forEach((dayName) => {
            Object.keys(input[dayName]).forEach((timeName) => {
                const data = input[dayName][timeName];
                if (data.quantity !== '0') {
                    result += openMoment;

                    result += department.replace('{value}', data.department + '');
                    result += timeOfDay.replace('{value}', this.getSTDFromTime(timeName));
                    result += dayCode.replace('{value}', this.getDayCode(dayName));
                    result += quantity.replace('{value}', data.quantity);

                    result += closeMoment;
                }
            });
        });
        return result + closeArray;
    }

    getDayCode(day: string): string {
        const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        if (isNaN(Number(day)) && !weekdays.includes(day)) {
            return '0';
        } else if (weekdays.includes(day)) {
            return weekdays.indexOf(day) + 1 + '';
        } else {
            return day;
        }
    }
    getSTDFromTime(time: string): string {
        switch (time) {
            case 'breakfast':
                return 'STD-1';
            case 'lunch':
                return 'STD-2';
            case 'snack':
                return 'STD-3';
            case 'dinner':
                return 'STD-4';
            case 'sleep':
                return 'STD-5';
            case 'ifNeeded':
                return 'S/P';
            default:
                return time;
        }
    }
    getActivePeriods(input: PrescriptionEditApp): number | null {
        const months: months[] = [
            'gen',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ];
        let flag = 0;
        months.forEach((m, index) => {
            flag += Math.pow(2, index) * (input[m] ? 1 : 0);
        });
        return !!flag ? flag : null;
    }
}
type months =
    | 'gen'
    | 'feb'
    | 'mar'
    | 'apr'
    | 'may'
    | 'jun'
    | 'jul'
    | 'aug'
    | 'sep'
    | 'oct'
    | 'nov'
    | 'dec';

export class InsertPrescriptionAPI extends PrescriptionInteractionDataAPI {
    public ForcedPayment: number;
    public ForcedMutua: null;
    public Doctor: null;
    public NoBlister: number;
    public Observaciones: string | null;
    public PE: Date;
    public Agudo: boolean;
    public Motivo: null;
    public raData: string | null;
    public AdminRoute: number;

    constructor(
        input: PrescriptionEditApp,
        patientId: number,
        prescriptionId?: number
    ) {
        super(input, patientId, prescriptionId);
        this.ForcedPayment = input.forcePayment ? 1 : 0;
        this.ForcedMutua = null;
        this.Doctor = null;
        this.NoBlister = input.noBlister ? 1 : 0;
        this.Observaciones = input.observations;
        this.PE = input.peDate || null;
        this.Agudo = input.severe || false;
        this.Motivo = null;
        this.raData = input.ra ? input.raNumber : null;
        this.AdminRoute = input.adminRoute;
    }
}

export interface Result1 {
    MedicineId: number;
    DrugId: string;
    Name: string;
    PayMed: boolean;
    MedNoBlister: boolean;
    StartDate: Date;
    EndDate: Date;
    OccurrenceCount: number;
    RecurrencePattern: number;
    RecurrenceInterval: number;
    IsAdvanced: number;
    ForcedPayment: number;
    ForcedMutua: null; //??
    NoBlister: number;
    ActivePeriods: null | number;
    Observaciones: null; //??
    PE: Date;
    Agudo: boolean;
    Motivo: null; //??
    raData: null | {
        PrescriptionId: number;
        Qty: number;
    };
    mecData: null; //??
    AdminRoute: number;
    CMS: string; //??
    Presentacion: string; //??
    FGP: boolean;
    Obsolete: boolean;
    NoHalf: boolean;
    NoPartial: boolean;
    PA: ActiveIngredient[];
    GrupoPeligro: number;
}

export interface Result2 {
    DepId: number;
    DepName: string;
    TimeOfDay: string;
    TimeName: string;
    DayCode: number;
    Qty: number;
}

export interface PrescriptionEditApp {
    medicine: MedicineDropdownApp;
    noBlister: boolean;
    forcePayment: boolean;
    payMed: boolean,
    ifNeeded: boolean;
    severe: boolean;
    adminRoute: number;
    ra: boolean;
    raNumber: string;

    since: Date;
    forever: boolean;
    until: Date;
    pe: boolean;
    peDate: Date;

    gen: boolean;
    feb: boolean;
    mar: boolean;
    apr: boolean;
    may: boolean;
    jun: boolean;
    jul: boolean;
    aug: boolean;
    sep: boolean;
    oct: boolean;
    nov: boolean;
    dec: boolean;

    pattern: string;
    dailyPattern: string;
    dailyRepeat: string;
    weeklyRepeat: string;
    monthlyRepeat: string;
    cyclicActive: string;
    cyclicRest: string;

    advancedSchedule: boolean;
    table: IntakeTable;

    observations: string;
}

export interface IntakeTable {
    [key: string]: {
        // dia o cantidad
        [key: string]: {
            // horario
            quantity: string;
            department: number;
        };
    };
}

export interface Patterns {
    pattern: Pattern;
    dailyPattern: DailyPattern;
    dailyRepeat: string; // en verdad son números
    weeklyRepeat: string;
    monthlyRepeat: string;
    cyclicActive: string;
    cyclicRest: string;
}

export type Pattern = 'daily' | 'monthly' | 'weekly' | 'cyclic';
export type DailyPattern = 'repeat each' | 'even days' | 'odd days';

export interface Itk {
    DayCode: number; // 0 (día el cual se administra del mes o de la semana) 1-> Lunes o día 1
    Dosis: Dosi[];
}

export interface Dosi {
    DepId: number; // 850
    DepName: string; // "CARPA" // Sale en el tooltip
    TimeOfDay: string; // "13:00"
    TimeName: string; // "Comida"
    Qty: number; // 1
    hasItksOod: boolean; // false
}

export interface PrescriptionTag {
    class: string;
    label: string;
    tooltip: string;
}

export interface DiagnogsticsForm {
    date: FormControl<Date | null>;
    removeCurrent: FormControl<boolean>;
    useDate: FormControl<boolean>;
    // @deprecated
    // searchType: FormControl<DiagnogsticsQueryType>;
    search: FormControl<DiagnogsticsItem | null>;
    // resultSelected: FormControl<DiagnogsticsItem | null>;
}

export interface DiagnogsticsData {
    date: Date;
    removeCurrent: boolean;
    useDate: boolean;
    patientId: string;
}

export interface DiagnogsticsDataAPI {
    Token: string;
    Id: number;
    FileName: string;
    DiagDate: string;
    OvrwCies: boolean;
    OvrwDates: boolean;
}
export interface DiagnogsticsQuery {
    query: string;
    // @deprecated
    // type: DiagnogsticsQueryType;
}

// @deprecated
// export type DiagnogsticsQueryType = 0 | 1;

export interface DiagnogsticsPayloadAPI {
    Id: string;
    Name: string;
}
export interface DiagnogsticsItem {
    code: string;
    description: string;
}

export interface PatientDataAPIInterface {
    pat: {
        Alergias: {Id: number; Nombre: string}[];
        id: number; // 30758
        insid: number; // 218
        num: string; // "0005"
        fnam: string; // "ANGELARIA"
        lnam: string; // "BASTO"
        mnam: string; // "TURA"
        dob: Date; // "2020-12-02T00:00:00.000Z"
        doe: Date; // "2023-05-30T00:00:00.000Z"
        dep: number; // 850
        room: any; // null
        cip: string; // "BATU1330405009"
        cipa: any; // null
        gnd: string; // "F"
        rec: string; // "63787718254"
        rea: string; // null
        obs: string; // ""
        alr: string; // "NAMC"
        ins: string; // "DEMO1"
        mumu: any; // null,
        diab: boolean; // false,
        alzh: boolean; // false,
        tsi: string; // "803401BATU13304050090612",
        ctnam: string; // "PILAR"
        ctphn: string; // 696784165,
        ctnam2: any; // null,
        ctphn2: any; // null,
        exr: number; // 2,
        dobr: Date; // "1933-04-05T00:00:00.000Z",
        ath: boolean; // false,
        mumup: boolean; // false,
        IsPrivate: boolean; // false,
        SelfManaged: boolean; // false,
        blocks: {
            id: number; // 20016,
            blkf: string; // "2023-05-20T00:00:00.000Z",
            blku: string; // "2023-05-31T00:00:00.000Z",
            blkr: number; // 1,
            blkipda: boolean; // false,
            blkdvt: any; // null
        }[];
        blkd: boolean; // true,
        exit: boolean; // false,
        dayp: {
            day: string; // "2023-05-10T00:00:00.000Z",
            dayf: number; // 0
        }[];
        extdat: {
            day: string; // "2021-02-18T00:00:00.000Z",
            hasPics: boolean; // false
        }[];
        docs: Document[];
        cies: {
            PatientId: number; //30758,
            DiagDate: Date; //"2021-01-14T09:26:00.000Z",
            CieId: string; //"E66.9",
            Name: string; //"Obesidad, no especificada"
        }[];
        covid: {
            patientCovid: {
                PatientId: number; // 30758,
                Positivo: boolean; // false,
                Sintomatico: boolean; // false,
                FechaIniContagio: string; // null,
                FechaFinContagio: string; // null,
                Confidential: boolean; // true,
                Gravedad: any; // null,
                Cognitivos: any; // null,
                Patient: any; // null
            };
            patientCovidTests: any[]; // TODO: a saber que va aquí
            patientCovidTratam: {
                covidTratamientoId: number; // 0,
                covidTratamientoName: string; // "Ninguno",
                isCheck: boolean; // false
            }[];
            patientCovidMuestras: any[]; // TODO: a saber que va aquí
            patientCovidCvSpa: any[]; // TODO: a saber que va aquí
        };
        LinqData: {
            Altura: number; // 1,
            Peso: number; // 2,
            CircunfAbdom: number; // 3,
            AlturaRodilla: number; // 4,
            BirthPlace: string; // "lugar",
            BirthMunic: string; // "municipio",
            BirthProv: string; // "provincia",
            BirthCtry: number; // 4,
            BirthFirst10: any; // null,
            NivelEduc: number; // 0,
            NivelOcup: number; // 0,
            MedicoSS: string; // "medico",
            patientCv: {
                PatientId: number; // 35649,
                FechaAnalisis: Date; // 2021-10-12T22:00:00.000Z,
                FrecCardiaca: number; // 74,
                OxigenoSangre: number; // 99,
                Temperatura: number; // 36.2,
                TensionArterialMax: number; // 140,
                TensionArterialMin: number; // 100,
                Patient: any; // null
            }[];
            patientTestFD: {
                PatientId: number;
                FechaAnalisis: Date;
                TEE: number;
                TGUG: number;
                Patient: any;
            }[];
            patientAnlCli: {
                PatientId: number;
                FechaAnalisis: Date;
                Glucosa: number;
                Sodio: number;
                Potasio: number;
                Cloruro: number;
                DioxidoCarbono: number;
                NitrogenoUreico: number;
                Creatinina: number;
                Calcio: number;
                Proteinas: number;
                Albumina: number;
                Bilirrubina: number;
                FosfatasaAlcalina: number;
                ConteoGlobBlancos: number;
                ConteoGlobRojos: number;
                Hemoglobina: number;
                Hematocrito: number;
                VolGlobularMed: number;
                HemoglobCospusc: number;
                Plaquetas: number;
                ColestTotal: number;
                HDL: number;
                LDL: number;
                Triglicer: number;
                Patient: any;
            }[];
            patientClinical: {
                TipoVacuna: number; // 1,
                Nombre: string; // "Gripe",
                FechaDosis: Date[]; // ["2021-11-01T23:00:00.000Z"] 8 items siempre
            }[];
            patientMultiTest: {
                PatientId: number; // 30758,
                NumLinea: number; // 1,
                TipoTest: number; // 51,
                FechaTest: Date; // "2023-05-17T22:00:00.000Z",
                MultiResult: number; // 1,
                MultiResultAux1: number; // null,
                Patient: any; // null
            }[];
            patientReData?: {
                PatientId: number; // 30758,
                FarmaticNumber?: number; // 28922,
                ReNote?: any; // null,
                Reviewed?: string; // "2023-05-04T07:24:00.000Z",
                DNI?: string; // "37025289G",
                Trouble?: boolean; // false,
                CapExt?: boolean; // false,
                Patient?: any; // null
            };
            patientCursoClin: {
                PatientId: number; // 30758,
                Fecha: Date; // "2021-02-04T12:14:00.000Z",
                Nota: string; // "DUE: BEG .HDM estable .",
                Patient: any; // null
            }[];
            patientDiet: {
                PatientId: number; // 30758,
                Dieta: string; // "DIETA HIPOCALORICA",
                DietaLiquida: boolean; // false,
                DietaBlanda: boolean; // false,
                DietaBasal: boolean; // true,
                DietaHipoproteica: boolean; // false,
                DietaHiperproteica: boolean; // false,
                DietaHipocalorica: boolean; // false,
                DietaHiposodica: boolean; // false,
                DietaHipolipemiante: boolean; // false,
                DietaDiabetica: boolean; // false,
                DietaSinGluten: any; // null,
                DietaOtra: any,
                Patient: any; // null
            };
        };
        nimg: number; // 1
        enlaceAviso: string;
        cipaPattern: string;
        cipaError: string;
        SeguroPrivado: string;
        ExportarCF: boolean;
    };
}

export interface PatientFormData {
    general: PatientGeneralForm;
    clinicNotes: PatientClinicNotesForm;
    diagnostic: PatientDiagnogsticForm;
    dailyPattern: PatientDailyPatternForm;
    blocks: PatientBlocksForm;
    prescriptions?: PatientPrescriptionsForm;
    documents: PatientDocumentsForm;
    rawData?: PatientDataAPIInterface;
}

export interface PatientGeneralForm {
    id: number; // añadido para usarlo en las prescripciones
    institutionId: number; // añadido para usarlo en las prescripciones
    general: {
        name: string;
        surname1: string;
        surname2: string;
        personalId: string;
        educationalLevel: number;
        ocupationalLevel: number;
    },
    birthData: {
        place: string;
        city: string;
        province: string;
        country: number;
        birthday: Date;
        gender: string;
    };
    dates: {
        registrationDate: Date;
        deregistrationDate: Date;
        deregistrationReason: number;
    };
    contacts: {
        name1: string;
        phone1: string;
        name2: string;
        phone2: string;
    };
    position: {
        department: number;
        room: string;
    };
    medicalCard: {
        cip: string;
        tsi: string;
        lastChronicRE: string;
        lastSevereRE: string;
        privateInsurance: string;
    };
    medicalData: {
        doctorSS: string;
        checkboxGroup: {
            mutuas_muface: boolean;
            at_home: boolean;
            private: boolean;
            autogetsion: boolean;
        };
    }
}
export interface PatientClinicNotesForm {
    observations: {
        main: string;
    },
    allergies: {
        main: number[];
    },
    diet: {
        liquid: boolean;
        bland: boolean;
        vasal: boolean;
        hipoprotic: boolean;
        hiperproteic: boolean;
        hipocaloric: boolean;
        hoiposodic: boolean;
        hipopemiant: boolean;
        diabetic: boolean;
        noGluten: boolean;
        otra: boolean;
        notes: string;
    };
    vitalSigns: { results: VitalSignsResult[] };
    clinicAnalisys: { results: ClinicAnalisysResult[] };
    dependencyFragilityTests: { results: DependencyFragilityTestsResult[] };
    haynesTest: { results: HaynesTestResult[] };
    handlingHabilityTests: { results: HandlingHabilityTestsResult[] };
    antropometricMeasures: {
        height: string;
        weight: string;
        absCircunference: string;
        kneesHeigth: string;
        imc: null;
    };
    vaccines: {
        gripe: Vaccine;
        neumococo: Vaccine;
        disteriaTetanosTosfeina: Vaccine;
        herpesZoster: Vaccine;
        sarsCov2: Vaccine;
    };
}

export interface VitalSignsResult {
    date: Date;
    bloodPresure: {
        max: string;
        min: string;
    };
    heartRate: string;
    bloodO2: string;
    temperature: string;
}

export interface ClinicAnalisysResult {
    date: Date;
    tabs: {
        methabolics: {
            glucose: { value: string };
            sodium: { value: string };
            potassium: { value: string };
            chloride: { value: string };
            carbonDioxide: { value: string };
            bun: { value: string };
            creatinine: { value: string };
            calcium: { value: string };
            proteins: { value: string };
            albumin: { value: string };
            bilirubin: { value: string };
            alp: { value: string };
        }; // TODO: revisar
        hemogram: {
            wbc: { value: string };
            rbc: { value: string };
            hemoglobin: { value: string };
            hematocrit: { value: string };
            mcv: { value: string };
            mch: { value: string };
            plateletCount: { value: string };
        };
        lipidgram: {
            totalCholesterol: { value: string };
            hdl: { value: string };
            ldl: { value: string };
            triglycerides: { value: string };
        };
    };
}
export interface DependencyFragilityTestsResult {
    date: Date;
    tee: string;
    tgug: string;
}
export interface HaynesTestResult {
    date: Date;
    comply: boolean;
}

export interface HandlingHabilityTestsResult {
    date: Date;
    usage: boolean;
    person: boolean;
}
export interface Vaccine {
    dosis1: Date | null;
    dosis2: Date | null;
    dosis3: Date | null;
    dosis4: Date | null;
    dosis5: Date | null;
    dosis6: Date | null;
    dosis7: Date | null;
    dosis8: Date | null;
}
export interface PatientDiagnogsticForm {
    diagnogstics: { diagnogstics: Diagnogstic[] };
    clinicCourse: { results: clinicCourseResult[] };
}
export interface Diagnogstic {
    date: Date;
    code: string;
    description: string;
}
export interface clinicCourseResult {
    date: Date;
    notes: string;
}

export interface PatientDailyPatternForm {
    pattern: { date: Date }[];
}
export interface PatientBlocksForm {
    info1: string;
    blocks: { block: BlockResult }[];
}

export interface Blocks {
    block: BlockResult;
}

export interface BlockResult {
    id?: number;
    title?: string;
    startDateBlock: string;
    endDateBlock: string;
    reasonBlock: number;
    derivatedTo: string;
    activatedSIRVI: boolean;
}

export interface PatientPrescriptionsForm {
    link: string;
    exportCf: boolean;
    allergies: string;
}
export interface PatientDocumentsForm {
    documents: Document[];
}

export class PatientUpdateAPI {
    Id: any;
    PatientNumber: string | null;
    InstitutionID: number | null;
    FirstName: string | null;
    MiddleName: string | null;
    LastName: string | null;
    BirthDate: string | null;
    Gender: number | null;
    Address: any;
    Number: any;
    City: any;
    ZipCode: any;
    CIP: string | null;
    RE: any;
    Exitus: string | null;
    ExitusReason: number | null;
    DateOfBirthReal: string | null;
    MutuasMuface: number | null;
    Diabetes: boolean;
    Alzheimer: boolean;
    TSI: string | null;
    AlergiasPA: any;
    RE2: any;
    DepartmentId: number | null;
    Observaciones: any;
    Dieta: any;
    DayPattern: string | null;
    PatientBlocks: string | null;
    ContactName: string | null;
    ContactPhone: string | null;
    ContactName2: string | null;
    ContactPhone2: string | null;
    AtHome: boolean;
    MutuasPaper: boolean;
    IsPrivate: boolean;
    SelfManaged: boolean;
    CIPA: any;
    Room: string | null;
    covid: any;
    LinqData: LinqData;
    room: string | null;
    CIEs: CIES[] | null;
    SeguroPrivado: string | null;

    constructor(input: PatientFormData, id?: number | null) {
        const { general, clinicNotes, diagnostic, dailyPattern, blocks } = input;
        const {
            gripe,
            neumococo,
            disteriaTetanosTosfeina,
            herpesZoster,
            sarsCov2,
        } = clinicNotes.vaccines;
        this.Id = id; // Null for create patient
        this.InstitutionID = 0;
        this.FirstName = general.general.name;
        this.MiddleName = general.general.surname1;
        this.LastName = general.general.surname2;
        this.BirthDate =
            transformDateToBackFormat(general.dates.registrationDate) ||
            new Date().toDateString();
        // Important: When we receive a gender it has value 'F' or 'M', depending on the gender of the patient.
        // But when we send it, we have to send 1 if it is male and 2 if it is female.
        this.Gender = general.birthData.gender === 'M' ? 1 : 2;
        this.Address = general.birthData.place || null;
        this.Number = null;
        this.City = null;
        this.ZipCode = null;
        this.Exitus = transformDateToBackFormat(general.dates.deregistrationDate);
        this.DateOfBirthReal = transformDateToBackFormat(
            general.birthData.birthday
        );

        this.ExitusReason = general.dates.deregistrationReason;
        this.CIP = general.medicalCard.cip || null;
        this.TSI = general.medicalCard.tsi || null;
        this.RE = general.medicalCard.lastChronicRE || null;
        this.RE2 = general.medicalCard.lastSevereRE || null;
        this.ContactName = general.contacts.name1 || null;
        this.ContactName2 = general.contacts.name2 || null;
        this.ContactPhone = general.contacts.phone1 || null;
        this.ContactPhone2 = general.contacts.phone2 || null;
        this.LinqData = {
            NivelEduc: general.general.educationalLevel || null,
            NivelOcup: general.general.ocupationalLevel || null,
            BirthPlace: general.birthData.place || null,
            BirthMunic: general.birthData.city || null,
            BirthProv: general.birthData.province || null,
            BirthCtry: general.birthData.country || null,
            patientReData: {
                    DNI: general.general.personalId,
                    PatientId: id ? this.Id : undefined,
                    FarmaticNumber: id ? input.rawData?.pat.LinqData?.patientReData?.FarmaticNumber || null : undefined,
                    ReNote: id ? input.rawData?.pat.LinqData?.patientReData?.ReNote || null : undefined,
                    Reviewed: id ? input.rawData?.pat.LinqData?.patientReData?.Reviewed || null : undefined,
                    Trouble: id ? input.rawData?.pat.LinqData?.patientReData?.Trouble || null : undefined,
                    CapExt: id ?  input.rawData?.pat.LinqData?.patientReData?.CapExt || null : undefined,
                    Patient: id ? input.rawData?.pat.LinqData?.patientReData?.Patient || null : undefined,
                },
            MedicoSS: general.medicalData.doctorSS || null,
            Altura: parseFloat(clinicNotes.antropometricMeasures.height) ?? null,
            Peso: parseFloat(clinicNotes.antropometricMeasures.weight) ?? null,
            CircunfAbdom:
                parseFloat(clinicNotes.antropometricMeasures.absCircunference) ?? null,
            AlturaRodilla:
                parseFloat(clinicNotes.antropometricMeasures.kneesHeigth) ?? null,
            patientDiet:
                this.Id && clinicNotes.diet
                    ? {
                        PatientId: id || 0,
                        Dieta: clinicNotes.diet.notes || '',
                        DietaLiquida: clinicNotes.diet.liquid || false,
                        DietaBlanda: clinicNotes.diet.bland || false,
                        DietaBasal: clinicNotes.diet.vasal || false,
                        DietaHipoproteica: clinicNotes.diet.hipoprotic || false,
                        DietaHiperproteica: clinicNotes.diet.hiperproteic || false,
                        DietaHipocalorica: clinicNotes.diet.hipocaloric || false,
                        DietaHiposodica: clinicNotes.diet.hoiposodic || false,
                        DietaHipolipemiante: clinicNotes.diet.hipopemiant || false,
                        DietaDiabetica: clinicNotes.diet.diabetic || false,
                        DietaSinGluten: clinicNotes.diet.noGluten || false,
                        DietaOtra: clinicNotes.diet.otra || false,
                        Patient: null,
                    }
                    : null,
            patientCv:
                this.Id && clinicNotes.vitalSigns ? getVitalSigns(clinicNotes, id) : [],
            patientAnlCli:
                this.Id && clinicNotes.clinicAnalisys.results.length > 0
                    ? getClinicalAnalysis(clinicNotes, id)
                    : [],
            patientTestFD:
                this.Id && clinicNotes.dependencyFragilityTests.results.length > 0
                    ? getPatientTestFD(clinicNotes, id)
                    : [],

            // Importante: En este objeto, entrarán tanto los tests de haynes, como los test de habilidad de manejo
            patientMultiTest:
                this.Id &&
                    (clinicNotes.haynesTest.results.length > 0 ||
                        clinicNotes.handlingHabilityTests.results.length > 0)
                    ? getMultipleTests(
                        clinicNotes.haynesTest.results,
                        clinicNotes.handlingHabilityTests.results,
                        this.Id
                    )
                    : [],
            patientCursoClin:
                diagnostic.clinicCourse.results.length > 0
                    ? getClinicCourse(diagnostic, this.Id)
                    : null,
            patientClinical: [
                {
                    TipoVacuna: 1,
                    Nombre: 'Gripe',
                    FechaDosis: [
                        transformDateToBackFormat(gripe.dosis1),
                        transformDateToBackFormat(gripe.dosis2),
                        transformDateToBackFormat(gripe.dosis3),
                        transformDateToBackFormat(gripe.dosis4),
                        transformDateToBackFormat(gripe.dosis5),
                        transformDateToBackFormat(gripe.dosis6),
                        transformDateToBackFormat(gripe.dosis7),
                        transformDateToBackFormat(gripe.dosis8),
                    ],
                },
                {
                    TipoVacuna: 2,
                    Nombre: 'Neumococo',
                    FechaDosis: [
                        transformDateToBackFormat(neumococo.dosis1),
                        transformDateToBackFormat(neumococo.dosis2),
                        transformDateToBackFormat(neumococo.dosis3),
                        transformDateToBackFormat(neumococo.dosis4),
                        transformDateToBackFormat(neumococo.dosis5),
                        transformDateToBackFormat(neumococo.dosis6),
                        transformDateToBackFormat(neumococo.dosis7),
                        transformDateToBackFormat(neumococo.dosis8),
                    ],
                },
                {
                    TipoVacuna: 3,
                    Nombre: 'Difteria-Tetanos-Tos ferina',
                    FechaDosis: [
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis1),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis2),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis3),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis4),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis5),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis6),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis7),
                        transformDateToBackFormat(disteriaTetanosTosfeina.dosis8),
                    ],
                },
                {
                    TipoVacuna: 4,
                    Nombre: 'Herpes zóster',
                    FechaDosis: [
                        transformDateToBackFormat(herpesZoster.dosis1),
                        transformDateToBackFormat(herpesZoster.dosis2),
                        transformDateToBackFormat(herpesZoster.dosis3),
                        transformDateToBackFormat(herpesZoster.dosis4),
                        transformDateToBackFormat(herpesZoster.dosis5),
                        transformDateToBackFormat(herpesZoster.dosis6),
                        transformDateToBackFormat(herpesZoster.dosis7),
                        transformDateToBackFormat(herpesZoster.dosis8),
                    ],
                },
                {
                    TipoVacuna: 5,
                    Nombre: 'SARS-CoV-2',
                    FechaDosis: [
                        transformDateToBackFormat(sarsCov2.dosis1),
                        transformDateToBackFormat(sarsCov2.dosis2),
                        transformDateToBackFormat(sarsCov2.dosis3),
                        transformDateToBackFormat(sarsCov2.dosis4),
                        transformDateToBackFormat(sarsCov2.dosis5),
                        transformDateToBackFormat(sarsCov2.dosis6),
                        transformDateToBackFormat(sarsCov2.dosis7),
                        transformDateToBackFormat(sarsCov2.dosis8),
                    ],
                },
            ],
        };
        this.covid = {
            patientCovid: {
                PatientId: 49661,
                Positivo: false,
                Sintomatico: false,
                FechaIniContagio: null,
                FechaFinContagio: null,
                Confidential: true,
                Gravedad: null,
                Cognitivos: null,
                Patient: null,
            },
            patientCovidTests: [],
            patientCovidTratam: [
                {
                    covidTratamientoId: 0,
                    covidTratamientoName: 'Ninguno',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 5,
                    covidTratamientoName: 'Anakinra',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 6,
                    covidTratamientoName: 'Cloroquina',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 7,
                    covidTratamientoName: 'Hidroxicloroquina',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 3,
                    covidTratamientoName: 'Interferon beta 1-b',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 1,
                    covidTratamientoName: 'Lopinavir',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 4,
                    covidTratamientoName: 'Remdesivir',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 2,
                    covidTratamientoName: 'Ritonavir',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 8,
                    covidTratamientoName: 'Tocilizumab',
                    isCheck: false,
                },
                {
                    covidTratamientoId: 9,
                    covidTratamientoName: 'Trastuzumab',
                    isCheck: false,
                },
            ],
            patientCovidMuestras: [],
            patientCovidCvSpa: [],
        };

        this.Observaciones = clinicNotes.observations.main || '';
        this.AlergiasPA = clinicNotes.allergies.main || '';

        this.AtHome = general.medicalData.checkboxGroup.at_home || false;
        this.MutuasMuface = general.medicalData.checkboxGroup.mutuas_muface ? 1 : 0;
        this.IsPrivate = general.medicalData.checkboxGroup.private || false;
        this.SelfManaged = general.medicalData.checkboxGroup.autogetsion || false;
        this.SeguroPrivado = general.medicalCard.privateInsurance || null;

        //FIXME: Habrá que decirle al back que existen 2 atributos
        // que son lo mismo
        this.Room = general.position.room || null;
        this.room = general.position.room || null;
        this.DepartmentId = general.position.department || null;
        //FIXME: Esperar a que se añada esta información por parte de Rubén
        this.DayPattern = convertToDailyPatterUpdateForm(dailyPattern.pattern, 0);
        this.PatientBlocks =
            this.Id && blocks.blocks
                ? convertToBlockUpdateForm(blocks, true)
                : '<ArrayOfPatientBlock></ArrayOfPatientBlock>';
        this.CIEs = diagnostic.diagnogstics.diagnogstics.map((diagnostic) => ({
            PatientId: this.Id,
            DiagDate:
                transformDateToBackFormat(diagnostic.date) || new Date().toISOString(),
            CieId: diagnostic.code,
            Name: diagnostic.description,
        }));
    }
}

function getClinicCourse(
    diagnostic: PatientDiagnogsticForm,
    id: number | null | undefined
): PatientCursoClin[] | null | undefined {
    return diagnostic.clinicCourse.results.map((clinicCourse) => ({
        PatientId: id || 0,
        Fecha: clinicCourse.date
            ? clinicCourse.date.toISOString()
            : new Date().toISOString(),
        Nota: clinicCourse.notes,
        Patient: null,
    }));
}
function getPatientTestFD(
    clinicNotes: PatientClinicNotesForm,
    id: number | null | undefined
): PatientTestF[] | undefined {
    return clinicNotes.dependencyFragilityTests.results.map((tests) => ({
        PatientId: id || 0,
        FechaAnalisis: tests.date,
        TEE: parseFloat(tests.tee),
        TGUG: parseFloat(tests.tgug),
        Patient: null,
    }));
}

function getClinicalAnalysis(
    clinicNotes: PatientClinicNotesForm,
    id: number | null | undefined
): PatientAnlCli[] | null | undefined {
    return clinicNotes.clinicAnalisys.results.map((analysis) => {
        const { methabolics, lipidgram, hemogram } = analysis.tabs;
        return {
            PatientId: id || 0,
            FechaAnalisis: analysis.date,
            Glucosa: parseFloat(methabolics.glucose.value),
            Sodio: parseFloat(methabolics.sodium.value),
            Potasio: parseFloat(methabolics.potassium.value),
            Cloruro: parseFloat(methabolics.chloride.value),
            DioxidoCarbono: parseFloat(methabolics.carbonDioxide.value),
            NitrogenoUreico: parseFloat(methabolics.bun.value),
            Creatinina: parseFloat(methabolics.creatinine.value),
            Calcio: parseFloat(methabolics.calcium.value),
            Proteinas: parseFloat(methabolics.proteins.value),
            Albumina: parseFloat(methabolics.albumin.value),
            Bilirrubina: parseFloat(methabolics.bilirubin.value),
            FosfatasaAlcalina: parseFloat(methabolics.alp.value),
            ConteoGlobBlancos: parseFloat(hemogram.wbc.value),
            ConteoGlobRojos: parseFloat(hemogram.rbc.value),
            Hemoglobina: parseFloat(hemogram.hemoglobin.value),
            Hematocrito: parseFloat(hemogram.hematocrit.value),
            VolGlobularMed: parseFloat(hemogram.mcv.value),
            HemoglobCospusc: parseFloat(hemogram.mch.value),
            Plaquetas: parseFloat(hemogram.plateletCount.value),
            ColestTotal: parseFloat(lipidgram.totalCholesterol.value),
            HDL: parseFloat(lipidgram.hdl.value),
            LDL: parseFloat(lipidgram.ldl.value),
            Triglicer: parseFloat(lipidgram.triglycerides.value),
            Patient: null,
        };
    });
}

function getVitalSigns(
    clinicNotes: PatientClinicNotesForm,
    id: number | null | undefined
): PatientCV[] | null | undefined {
    return clinicNotes.vitalSigns.results.map((vitalSign) => ({
        PatientId: id || 0,
        FechaAnalisis: vitalSign.date.toISOString(),
        FrecCardiaca: parseFloat(vitalSign.heartRate),
        OxigenoSangre: parseFloat(vitalSign.bloodO2),
        Temperatura: parseFloat(vitalSign.temperature),
        TensionArterial: null,
        TensionArterialMax: parseFloat(vitalSign.bloodPresure.max),
        TensionArterialMin: parseFloat(vitalSign.bloodPresure.min),
        Patient: null,
    }));
}

function transformDateToBackFormat(dateInput: Date | null): string | null {
    if (dateInput == null) return null;

    const date = new Date(dateInput);
    return isNaN(date.getTime()) ? null : date.toISOString();
}

// Cuando es Test de Hayne:
// TipoTest: 51
// Multiresult: se refiere a si es (cumple 1 o no cumple 2)

// Cuando es test de habilidad de manejo:
// TipoTest: 52

// Multiresult es (uso correcto 1, uso incorrecto 2)
// MultiResultAux1 ( usuario 1, cuidador 2)
function getMultipleTests(
    hayneTests: HaynesTestResult[],
    handlingHabilityTests: HandlingHabilityTestsResult[],
    id: number
): PatientMultiTest[] {
    const arrayHayneTest = hayneTests.length
        ? (hayneTests.map((hayneTest) => ({
            PatientId: id,
            TipoTest: 51,
            FechaTest: hayneTest.date.toISOString(),
            MultiResult: hayneTest.comply ? 1 : 2,
            isOpen: true,
            MultiResultAux1: null,
        })) as PatientMultiTest[])
        : [];
    const arrayHandlingHabilityTest = handlingHabilityTests.length
        ? (handlingHabilityTests.map((habilityTest) => ({
            PatientId: id,
            TipoTest: 52,
            FechaTest: habilityTest.date.toISOString(),
            MultiResult: habilityTest.usage ? 1 : 2,
            isOpen: true,
            MultiResultAux1: habilityTest.person ? 1 : 2,
        })) as PatientMultiTest[])
        : [];

    return arrayHayneTest.concat(arrayHandlingHabilityTest);
}

export interface LinqData {
    NivelEduc: number | null;
    NivelOcup: number | null;
    BirthPlace: string | null;
    BirthMunic: string | null;
    BirthProv: string | null;
    BirthCtry: number | null;
    MedicoSS: string | null;
    Altura?: any;
    Peso?: any;
    CircunfAbdom?: any;
    AlturaRodilla?: any;
    BirthFirst10?: any;
    patientCv?: PatientCV[] | null;
    patientTestFD?: PatientTestF[];
    patientAnlCli?: PatientAnlCli[] | null;
    patientClinical?: PatientClinical[] | null;
    patientMultiTest?: PatientMultiTest[] | null;
    patientReData?: PatientReData | null;
    patientCursoClin?: PatientCursoClin[] | null;
    patientDiet?: PatientDiet | null;
}

export interface CIES {
    PatientId: number; //30758,
    DiagDate: string; //"2021-01-14T09:26:00.000Z",
    CieId: string; //"E66.9",
    Name: string; //"Obesidad, no especificada"
}

export interface PatientCV {
    PatientId: number;
    FechaAnalisis: string;
    TensionArterial: any;
    FrecCardiaca: number;
    OxigenoSangre: number;
    Temperatura: number;
    $$hashKey?: string;
    isOpen?: boolean;
    Patient?: any;
    TensionArterialMax: number;
    TensionArterialMin: number;
}

export interface PatientTestF {
    PatientId: number;
    FechaAnalisis: Date;
    TEE: number;
    TGUG: number;
    Patient: any;
}

export interface PatientAnlCli {
    PatientId: number;
    FechaAnalisis: Date;
    Glucosa: number;
    Sodio: number;
    Potasio: number;
    Cloruro: number;
    DioxidoCarbono: number;
    NitrogenoUreico: number;
    Creatinina: number;
    Calcio: number;
    Proteinas: number;
    Albumina: number;
    Bilirrubina: number;
    FosfatasaAlcalina: number;
    ConteoGlobBlancos: number;
    ConteoGlobRojos: number;
    Hemoglobina: number;
    Hematocrito: number;
    VolGlobularMed: number;
    HemoglobCospusc: number;
    Plaquetas: number;
    ColestTotal: number;
    HDL: number;
    LDL: number;
    Triglicer: number;
    Patient: any;
}

export interface PatientReData {
    PatientId: number | null | undefined;
    FarmaticNumber: number | null | undefined;
    ReNote: any | null | undefined;
    Reviewed: string | null | undefined;
    DNI: string | null;
    Trouble: boolean | null | undefined;
    CapExt: boolean | null | undefined;
    Patient: any | null | undefined;
}

export interface PatientClinical {
    TipoVacuna: number;
    Nombre: string;
    FechaDosis: (string | null)[] | undefined[] | null[];
}

export interface PatientMultiTest {
    PatientId: number;
    TipoTest: number;
    FechaTest: string;
    Multiresult?: any;
    isOpen: boolean;
    MultiResult: number;
    MultiResultAux1: number | null;
    // Añadido por angularjs para llevar el tracking del objeto, es como una clave única, por lo tanto no sirve para nada realmente. Es algo interno de angularjs
    $$hashKey?: string;
}

export interface PatientCursoClin {
    PatientId: number;
    Fecha: string;
    Nota: string;
    Patient: any;
}

export interface PatientDiet {
    PatientId: number;
    Dieta: string;
    DietaLiquida: boolean;
    DietaBlanda: boolean;
    DietaBasal: boolean;
    DietaHipoproteica: boolean;
    DietaHiperproteica: boolean;
    DietaHipocalorica: boolean;
    DietaHiposodica: boolean;
    DietaHipolipemiante: boolean;
    DietaDiabetica: boolean;
    DietaSinGluten: any;
    DietaOtra: boolean;
    Patient: any;
}

export function convertToDailyPatterUpdateForm(
    dailyPatterns: { date: Date | string }[],
    flag: number
): string {
    let xmlString = '<ArrayOfDayPattern>';

    dailyPatterns.forEach((dailyPattern) => {
        dailyPattern.date = new Date(dailyPattern.date).toISOString().split('.')[0];
        xmlString += '<DayPattern>';
        xmlString += objectToXMLString(dailyPattern, dictionaryPatternDay);
        xmlString += `<PatternDayFlag>${flag}</PatternDayFlag>`;
        xmlString += '</DayPattern>';
    });
    xmlString += '</ArrayOfDayPattern>';
    return xmlString;
}

export function convertToBlockUpdateForm(
    blocks: PatientBlocksForm,
    skipEmptyValues: boolean = false
): string {
    let xmlString = '<ArrayOfPatientBlock>';
    blocks.blocks.forEach((block) => {
        delete block.block.title;
        block.block.startDateBlock = Utils.toSQLDate(block.block.startDateBlock as any) as string;
        if (block.block.endDateBlock != null)
            block.block.endDateBlock = Utils.toSQLDate(block.block.endDateBlock as any) as string;
        else
            delete (block.block as any).endDateBlock; // FIXME endDateBlock debería de ser opcional, pero cactus no lo tuvo en cuenta en varias zonas
        xmlString += '<PatientBlock>';
        xmlString += `<Id>${block.block.id || 0}</Id>`;
        xmlString += objectToXMLString(block.block, dictionaryBlocks, skipEmptyValues);
        xmlString += '</PatientBlock>';
    });

    xmlString += '</ArrayOfPatientBlock>';
    return xmlString;
}
