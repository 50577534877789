<div *ngIf="prevData && safeURL">
    <div class="bar">
        <div class="action-buttons">
            <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">Exportar</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="pdf()">PDF</button>
                <button mat-menu-item (click)="excel()">EXCEL</button>
            </mat-menu>
            <button mat-icon-button color="primary" class="icon-button" aria-label="Example icon button with a home icon" (click)="print()">
                <mat-icon>print</mat-icon>
            </button>
        </div>
        <div class="paginator">
            <mat-paginator #paginator class="demo-paginator" aria-label="Selecionar pagina" [pageSize]="pageSize"
                [hidePageSize]="true"
                [pageIndex]="pageIndex" (page)="handlePageEvent($event)" [length]="length"
                style="width: fit-content; margin-left: auto;">
            </mat-paginator>
        </div>
    </div>

    <div class="html-document" data>
        <div [innerHTML]="dynamicTemplate"></div>
    </div>
</div>
