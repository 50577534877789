<ng-container *ngFor="let section of schema; let i = index;">

  <!-- Normal section -->
  <div class="section"
    *ngIf="visibility[i]?.visible && section.customType === 'default'"
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">
    <!-- Title -->
    <div class="title">{{section.label}}</div>

    <!-- Content -->
    <div class="content">
      <ng-container *ngFor="let child of section.childs">
        <ng-container [ngSwitch]="child.customType">
          <!-- Custom form field -->
          <ng-container *ngSwitchCase="'diagnosgtics-form'">
            <ng-container *ngIf="child.visible">
              <app-diagnogstics-form
                class="field col-12"
                [fieldControl]="child.fieldControl | asFormControl"
                [readOnly]="child.readOnly">
              </app-diagnogstics-form>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Expansion panel -->
  <div class="section"
    *ngIf="visibility[i]?.visible && section.customType === 'expansion'"
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">

    <mat-expansion-panel (opened)="section.expanded = true" (closed)="section.expanded = false">
      <!-- HEADER EXPANSION PANEL -->
      <mat-expansion-panel-header>
        <div class="title" >
          {{section.label}}
          <button mat-icon-button
            #addButton
            class="add-button"
            *ngIf="section.addable && !section.fieldControl.disabled && section.expanded && Utils.hasPermission(Permission.PATIENTS_DIAGNOSTIC)().includes(PermissionType.WRITE)"
            [@inOutFade]="{value: true, params: {duration: '100ms'}}"
            (click)="addNewItem($event, section)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <!-- CONTENT EXPANSION PANEL -->
      <div class="contenedor-expansionpanel">
        <div class="content">
          <ng-container *ngFor="let child of section.childs">
            <ng-container [ngSwitch]="child.type">
              <!-- Custom form field -->
              <ng-container *ngSwitchCase="'custom'">
                <ng-container [ngSwitch]="child.customType">

                  <!-- Clinic course -->
                  <ng-container *ngSwitchCase="'clinic-course'">
                    <app-clinic-course
                      #clinicCourse
                      class="field col-12"
                      [fieldControl]="child.fieldControl | asFormControl"
                      [formConfig]="child.customFieldData"
                      [readOnly]="child.readOnly">
                    </app-clinic-course>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</ng-container>
