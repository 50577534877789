import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit, OnChanges {

  @Input() dynamicData: any;
  @Input() parentCheckStatus: number = 0;
  
  @Output() requestData = new EventEmitter<any>();
  @Output() onItemChecked = new EventEmitter<any>();
  
  constructor( private cdRef: ChangeDetectorRef ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // @ts-ignore
    if ( changes.parentCheckStatus ) {
      if ( this.parentCheckStatus === 2 ) {
        this.dynamicData.forEach((item: any) => !item.blocked ? item.checkStatus = 2 : null);
      } else if ( this.parentCheckStatus === 0 ) {
        this.dynamicData.forEach((item: any) => !item.blocked ? item.checkStatus = 0 : null);
      }
    }
  }

  toggleItem(item: any) {
    item.isExpanded = !item.isExpanded;

    if ( item.isExpanded && item.items && item.items[0].id == '') {
      this.generateRequestData(item);
    }
  }

  generateRequestData(child: any, item?: any) {
    item && !child.parent ? child.parent = item : null;

    this.requestData.emit(child);
  }

  toggleItemCheck($event: MatCheckboxChange, item: any) {
    if ( !item.blocked ) {
      if ( $event.checked ) {
        item.checkStatus = 2;
      } else {
        item.checkStatus = 0;
      }

      this.cdRef.detectChanges();
      this.onItemChecked.emit();
    }
  }

  checkItemCheckStatus(item: any) {
    if ( !item.blocked ) {
      const childCound = item.items.length;
      const checkedChilds = item.items.filter((subitem: any) => subitem.checkStatus === 2).length;
      const indeterminateChilds = item.items.filter((subitem: any) => subitem.checkStatus === 1).length;

      if ( indeterminateChilds || (checkedChilds && childCound !== checkedChilds) ) {
        item.checkStatus = 1;
      } else if ( childCound === checkedChilds ) {
        item.checkStatus = 2;
      } else {
        item.checkStatus = 0;
      }

      this.onItemChecked.emit();
      this.cdRef.detectChanges();
    }
  }
}
