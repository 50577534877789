import { InstitutionService } from '@services/institution.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { RecepAPI, ReceptionAPI } from '@interfaces/process/reception';
import { ProcReceptionsService } from '@services/process/proc-receptions.service';

@Component({
  selector: 'app-proc-receptions',
  templateUrl: './proc-receptions.component.html',
  styleUrls: ['./proc-receptions.component.scss'],
})
export class ProcReceptionsComponent implements OnInit {
  public receptionData: ReceptionAPI;
  public optionsSelectReceptions: SelectOption[];

  public cardProcesses: { title: string; content: string }[] = [];
  public cardReceptions: { title: string; content: string }[] = [
    {
      title: 'A importar',
      content: '0',
    },
    {
      title: 'Importados',
      content: '0',
    },
    {
      title: 'Duplicados',
      content: '0',
    },
  ];

  public form: FormGroup;
  constructor(
    private procService: ProcReceptionsService,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      dayReception: [new Date()],
      selectReceptions: [''],
    });
  }

  ngOnInit(): void {
    this.getReceptions('{"FarmaticLinkId":0}');
    this.getFarmaticReceps();
  }

  getReceptions(jss: string): void {
    this.procService.getReceptions(jss).subscribe((result) => {
      this.receptionData = result.payload;
      this.setContent(this.receptionData);
    });
  }

  getFarmaticReceps(): void {
    const reception = JSON.stringify({
      FarmaticLinkId: this.institutionService.getCurrentFarmacy(),
      TargetDay: this.form.controls['dayReception'].value,
    });
    this.procService.getFarmaticRecep(reception).subscribe((result) => {
      const { Receps } = result.payload;
      if (Receps) {
        this.optionsSelectReceptions = Receps.map((reception: RecepAPI) => {
          return {
            label: reception.IdAlbaran,
            value: reception.IdRecepcion,
          };
        });
      }
    });
  }

  importReception(): void {
    if (this.form.valid) {
      const idRecep = this.form.controls['selectReceptions'].value || 130089;
      const jss = `{"FarmaticLinkId":0,"IdRecepList":"${idRecep}"}`;
      this.procService.importFarmaticRecep(jss).subscribe((result) => {
        console.log(result.payload);
      });
    }
  }

  setContent(receptionData: ReceptionAPI): void {
    this.cardProcesses = [
      {
        title: 'Importados',
        content: receptionData.Importados.toString(),
      },
      {
        title: 'Vendidos',
        content: receptionData.Vendidos.toString(),
      },
      {
        title: 'Devueltos',
        content: receptionData.Devueltos.toString(),
      },
    ];
  }
}
