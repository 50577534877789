import { Department } from '@constants/institutions-forms.config';


export interface InstitutionSelectAPP {
  id: string;
  name: string;
  shortName: string;
  group?: string;
  PermitirFGP?: boolean;
}
export interface InstitutionSelectAPI {
  Id: string;
  Name: string;
  ShortName: string;
  GruposEmpresarial: string;
  PermitirFGP: boolean;
}
export interface InstitutionAPI {
  typ: string;
  id: string;
  nam: string;
  addrcty: string;
  city: string;
  sn: string;
  type: string;
  obs: boolean;
  nimg: string;
}

export class Institution {
  public id: number;
  public name: string;
  public city: string;
  public addrcty: string;

  public nameAbbreviation: string;
  public type: string;
  public obs: boolean; //obsoleto
  public numImage: string;

  constructor(input: InstitutionAPI) {
    this.id = parseInt(input.id);
    this.name = input.nam;
    this.nameAbbreviation = input.sn;
    this.city = input.city;
    this.addrcty  = input.addrcty;
    this.type = input.typ;
    this.obs = input.obs;
    this.numImage = input.nimg;
  }
}

export interface InstitutionTypeAPI {
  Id: number;
  Name: string;
}

export class InstitutionType {
  public id: number;
  public name: string;

  constructor(input: InstitutionTypeAPI) {
    this.id = input.Id;
    this.name = input.Name;
  }
}

export interface InstitutionSelectFilter {
  pharmacy: boolean;
  hospital: boolean;
  residency: boolean;
  other: boolean;
}

export interface InstitutionSelectCondition {
  pharmacyCondition: boolean;
  hospitalCondition: boolean;
  residencyCondition: boolean;
  otherCondition: boolean;
}

export class InstitutionEditForm {
  emailList: EmailList;
  main: InstitutionMain;
  doctors: Doctors;
  otherData: OtherData;
  schedule: Schedule;
  incontinence: Incontinence;
  autovalidation: Autovalidation;
  autoconfirmation: Autoconfirmation;
  enableBonification: string;
  automaticReposition: AutomaticReposition;
  MEC: Mec;
  linkFarmatic: LinkFarmatic;
  interval: Interval;
  info: string;
  documents: Document[];
  departments: { department: Department }[];
  actus: Actus;

  constructor(input: InstitutionEditAPI) {
    const { inst, fcprefixs, instype, CA, PermitirFGP, GrupoEmpresarial } = input;
    this.main = {
      nameAbbreviation: inst.sn,
      name: inst.nam,
      //TODO: TypeInst
      type: inst.type.toString(),
      modality: inst.modalidad,
      typology: inst.tipoatencion,
      openDate: new Date(inst.fechapert),
      address: inst.addrstrt,
      number: inst.addrnum,
      city: inst.addrcty,
      postalCode: inst.addrzip,
      telephone: inst.phone
    }
    this.doctors = {
      name1: inst.doctor,
      collegiate1: inst.coleg,
      name2: inst.doctor2,
      collegiate2: inst.coleg2,
    };
    this.emailList = {
      general: inst.email,
      re: inst.emailre,
      inventary: inst.emailstk,
    };

    this.otherData = {
      ccaa: CA?.Name,
      businessGroup: GrupoEmpresarial?.Name,
      obsolete: inst.obsol,
      allowFgp: PermitirFGP,
      exportCf: inst.exportCf
    };
    this.automaticReposition = {
      frecuency: inst.RaCutDay,
      excludedMedication: '',
      noSustituteMedication: '',
    };

    this.incontinence = {
      deliveryDetails: inst.ib.DeliveryMessage,
    };

    this.autovalidation = {
      autoValidationOrder: inst.ib.AutovalGracePeriod !== null || (inst.ib.AutovalHour !== null && inst.ib.AutovalWeekDay !== null),
      type: inst.ib.AutovalGracePeriod !== null ? true : false,
      day: inst.ib.AutovalWeekDay,
      hour: inst.ib.AutovalHour,
      hours: inst.ib.AutovalGracePeriod,
    },

    this.autoconfirmation = {
      autoconfirmation: inst.ib.AutoconGracePeriod
    };

    this.enableBonification = inst.ib.UseBonus;

    this.linkFarmatic = {
      prefix: inst.fi.NameFarmatic,
      idFarmatic: inst.fi.IdFarmatic,
      corotaId: inst.fi.IdCorota
    };

    this.interval = {
      initialDay: inst.PmWeekStartDay,
      intervalDay: inst.PmEndInterval
    };

    this.MEC = {
      state: inst.IgnoreMEC,
    };

    this.schedule = {
      breakfast: inst.itkms[0]?.Time,
      lunch: inst.itkms[1]?.Time,
      snack: inst.itkms[2]?.Time,
      dinner: inst.itkms[3]?.Time,
      sleep: inst.itkms[4]?.Time
    }

    this.departments = inst.deps.map((obj) => {
      const newDepartment = {
        department: {
          title:
            obj.itkms.length > 0
              ? obj.nam + ' (Horario personalizado)'
              : obj.nam,
          name: obj.nam,
          enableCustomSchedule: obj.itkms.length > 0,
          breakfast: '',
          lunch: '',
          snack: '',
          dinner: '',
          sleep: '',
          id: obj.id,
        },
      };

      // this.actus = {
      //   IntakeRangeBeforeHour: Number(inst.irangeb.split(':')[0]),
      //   IntakeRangeBeforeMinute: Number(inst.irangeb.split(':')[1]),
      //   IntakeRangeAfterHour: Number(inst.irangea.split(':')[0]),
      //   IntakeRangeAfterMinute: Number(inst.irangea.split(':')[1]),
      //   IntakePrepTimeBeforeHour: Number(inst.ipreptb.split(':')[0]),
      //   IntakePrepTimeBeforeMinute: Number(inst.ipreptb.split(':')[1]),
      //   IntakePrepTimeAfterHour: Number(inst.iprepta.split(':')[0]),
      //   IntakePrepTimeAfterMinute: Number(inst.iprepta.split(':')[1]),
      // };

      this.actus = {
        validity: {
          before: {
            hours: Number(inst.irangeb.split(':')[0]),
            minutes: Number(inst.irangeb.split(':')[1])
          },
          after: {
            hours: Number(inst.irangea.split(':')[0]),
            minutes: Number(inst.irangea.split(':')[1])
          }
        },
        preparation: {
          before: {
            hours: Number(inst.ipreptb.split(':')[0]),
            minutes: Number(inst.ipreptb.split(':')[1])
          },
          after: {
            hours: Number(inst.iprepta.split(':')[0]),
            minutes: Number(inst.iprepta.split(':')[1])
          }
        }
        // IntakeRangeBeforeMinute: Number(inst.irangeb.split(':')[1]),
        // IntakeRangeAfterHour: Number(inst.irangea.split(':')[0]),
        // IntakeRangeAfterMinute: Number(inst.irangea.split(':')[1]),

        // IntakePrepTimeBeforeHour: Number(inst.ipreptb.split(':')[0]),
        // IntakePrepTimeBeforeMinute: Number(inst.ipreptb.split(':')[1]),
        // IntakePrepTimeAfterHour: Number(inst.iprepta.split(':')[0]),
        // IntakePrepTimeAfterMinute: Number(inst.iprepta.split(':')[1]),
      };

      obj.itkms.forEach((item: Itkm) => {
        const time = item.Time;
        const standardMoment = item.StandardMoment;

        switch (standardMoment) {
          case 1:
            newDepartment.department.breakfast = time;
            break;
          case 2:
            newDepartment.department.lunch = time;
            break;
          case 3:
            newDepartment.department.snack = time;
            break;
          case 4:
            newDepartment.department.dinner = time;
            break;
          case 5:
            newDepartment.department.sleep = time;
            break;
        }
      });

      return newDepartment;
    });

    this.documents = inst.docs.map((doc) => doc);
  }
}

export interface DepartmentForm {
  title: string;
  name: string;
  enableCustomSchedule: null | boolean;
  breakfast: string;
  lunch: string;
  snack: string;
  dinner: string;
  sleep: string;
}

export interface EmailList {
  general: string;
  re: string;
  inventary: string;
}

export interface InstitutionMain {
  nameAbbreviation: string;
  name: string;
  type: string;
  modality: string;
  typology: string;
  openDate: Date;
  address: string;
  number: string;
  city: string;
  postalCode: string;
  telephone: string;
}

export interface Doctors {
  name1: any;
  collegiate1: any;
  name2: any;
  collegiate2: any;
}

export interface OtherData {
  ccaa: string | null;
  businessGroup: string | undefined;
  obsolete: boolean;
  allowFgp: boolean;
  exportCf: boolean;
}

export interface Schedule {
  breakfast: string;
  lunch: string;
  snack: string;
  dinner: string;
  sleep: string;
}

export interface Incontinence {
  deliveryDetails: any;
}

export interface Autovalidation {
  autoValidationOrder: any;
  type: boolean;
  day?: any;
  hour?: any;
  hours?: any;
}

export interface Autoconfirmation {
  autoconfirmation: any;
}

export interface AutomaticReposition {
  frecuency: number;
  excludedMedication: any;
  noSustituteMedication: any;
}

export interface Mec {
  state: any;
}

export interface LinkFarmatic {
  prefix: any;
  idFarmatic: any;
  corotaId: any;
}

export interface Interval {
  initialDay: any;
  intervalDay: any;
}

//API
export interface InstitutionEditAPI {
  inst: Inst;
  instype: Instype[];
  fcprefixs: Fcprefix[];
  CA: CA;
  PermitirFGP: boolean;
  GrupoEmpresarial: GrupoEmpresarial | undefined;
}

export interface CA {
  Id: number;
  Name: string;
}

export interface GrupoEmpresarial {
    Id: number;
    Name: string;
  }

export interface Inst {
  id: number;
  sn: string;
  nam: string;
  type: number;
  addrstrt: string;
  addrnum: string;
  addrcty: string;
  addrzip: string;
  phone: any;
  email: string;
  emailre: any;
  emailstk: any;
  doctor: string;
  irangeb: string;
  irangea: string;
  ipreptb: string;
  iprepta: string;
  deps: Dep[];
  itkms: Itkm[];
  docs: any[];
  coleg: string;
  obsol: boolean;
  exportCf: boolean;
  doctor2: any;
  coleg2: any;
  modalidad: any;
  tipoatencion: any;
  fechapert: any;
  RaCutDay: any;
  IgnoreMEC: boolean;
  covid: any;
  ib: Ib;
  ibds: Ibd[];
  raStop: any[];
  raIgnoreMedTransl: any[];
  fi: Fi;
  nimg: number;
  PmWeekStartDay: number;
  PmEndInterval: number;
}

export interface Dep {
  id: number;
  nam: string;
  itkms: any[];
}

export interface Itkm {
  Time: string;
  StandardMoment: number;
}

export interface Ib {
  InstitutionId: number;
  AutoconGracePeriod: any;
  AutovalGracePeriod: any;
  AutovalHour: string | null;
  AutovalWeekDay: number | null;
  DeliveryMessage: string;
  UseBonus: any;
}

export interface Ibd {
  InstitutionId: number;
  ItemQty: number;
  BonusQty: number;
  DistributorId: number;
  BonusStartDate: string;
}

export interface Fi {
  IdFarmatic: string;
  IdCorota: number;
  NameFarmatic: string;
}

export interface Instype {
  id: number;
  nam: string;
}

export interface Fcprefix {
  Prefix: string;
}

export interface Actus {
  validity: {
    after: { hours: number; minutes: number };
    before: { hours: number; minutes: number };
  },
  preparation: {
    after: { hours: number; minutes: number };
    before: { hours: number; minutes: number };
  }
}

//empty

export class InstitutionEditEmpty {
  inst = {
    id: 0,
    sn: '',
    nam: '',
    type: 0,
    addrstrt: '',
    addrnum: '',
    addrcty: '',
    addrzip: '',
    phone: null,
    email: '',
    emailre: null,
    emailstk: null,
    doctor: '',
    irangeb: '',
    irangea: '',
    ipreptb: '',
    iprepta: '',
    deps: [],
    itkms: [],
    docs: [],
    coleg: '',
    obsol: false,
    exportCf: false,
    doctor2: null,
    coleg2: null,
    modalidad: null,
    tipoatencion: null,
    fechapert: null,
    RaCutDay: null,
    IgnoreMEC: false,
    covid: null,
    ib: {
      InstitutionId: 0,
      AutoconGracePeriod: null,
      AutovalGracePeriod: null,
      AutovalHour: null,
      AutovalWeekDay: null,
      DeliveryMessage: '',
      UseBonus: null,
    },
    ibds: [],
    raStop: [],
    raIgnoreMedTransl: [],
    fi: { IdFarmatic: '', IdCorota: 0, NameFarmatic: '' },
    nimg: 0,
    PmWeekStartDay: 0,
    PmEndInterval: 0,
  };
  instype = [];
  fcprefixs = [];
  CA = { Id: 0, Name: '' };
  PermitirFGP = false;
  GrupoEmpresarial: GrupoEmpresarial | undefined = undefined;
}

export interface InstitutionSelectListAPI {
  flds: number;
  flt: number;
  sid: any;
  fl: number;
}

export interface InstitutionSelectListResponse {
  id: number;
  nam: any;
  sn: string;
}
