import { AppConfig } from '@interfaces/app-config.interface';
import { sidebarConfigItem } from '@interfaces/sidebar.interface';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { Utils } from '@json/src/app/Utils';

export const appConfig: AppConfig = {
  origin: 'pharma',
};

// Side menu config
export const sidebarConfig: sidebarConfigItem[] = [
  { link: 'welcome', label: 'Inicio', type: 'link', iconName: 'home' ,hasPermission: [PermissionType.READ, PermissionType.WRITE] },
  { link: 'patients', label: 'Residentes', type: 'link', iconName: 'group', hasPermission: Utils.hasPermission(Permission.PATIENTS) },
  {
    link: 'institutions',
    label: 'Centros',
    type: 'link',
    iconName: 'apartment',
    hasPermission: Utils.hasPermission(Permission.INSTITUTIONS)
  },
  {
    link: 'medicines',
    label: 'Vademecum',
    type: 'link',
    iconName: 'medication',
    hasPermission: Utils.hasPermission(Permission.VADEMECUM)
  },
  {
    link: 'procs',
    label: 'Procesos',
    iconName: 'account_tree',
    type: 'menu',
    hasPermission: Utils.hasPermission(Permission.PROCS_MENU),
    items: [
      {
        link: 'procs/plans',
        label: 'Planes',
        type: 'link',
        iconName: 'library_books',
        hasPermission: Utils.hasPermission(Permission.PROCS_PLANS),
      },
      {
        link: 'procs/in-out-re',
        label: 'Altas/Bajas/RE',
        type: 'link',
        iconName: 'swap_vert',
        hasPermission: Utils.hasPermission(Permission.PROCS_ALTAS),
      },
      {
        link: 'procs/ventas-farmatic',
        label: 'Ventas Farmatic',
        type: 'link',
        iconName: 'timeline',
        hasPermission: Utils.hasPermission(Permission.PROCS_VENTAS),
      },
      {
        link: 'procs/re-status',
        label: 'Estado de RE',
        type: 'link',
        iconName: 'personal_injury',
        hasPermission: Utils.hasPermission(Permission.PROCS_RE),
      },
    ],
  },

  //README: Path names are taken from demo.corota.net
  {
    link: 'reports',
    label: 'Reportes',
    iconName: 'library_books',
    type: 'menu',
    hasPermission: Utils.hasPermission(Permission.REPORTS),
    items: [
      {
        link: 'reports',
        label: 'Gestión de pacientes',
        iconName: 'group',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS),
        items: [
          {
            link: 'reports/pathmeds',
            label: 'Consumo por pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/patientlist',
            label: 'Listado de pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/patchgs',
            label: 'Movimientos de pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
        ],
      },
      {
        link: 'reports',
        label: 'Gestión residencial',
        iconName: 'apartment',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_RESI),
        items: [
          {
            link: 'reports/mednostock',
            label: 'Medicamentos faltantes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
        ],
      },
      {
        link: 'reports',
        label: 'Gestión farmacológica',
        iconName: 'medical_services',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
        items: [
          {
            link: 'reports/medidebt',
            label: 'Deudas agudos/adelantos/pedidos',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/restat',
            label: 'Receta electrónica',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/diapers',
            label: 'Incontinencia',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORT_INCONTINENCIA)
          },
          {
            link: 'reports/fgpprescriptions',
            label: 'Prescripciones FGP',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
        ],
      },
      {
        link: 'reports',
        label: 'Gestión farmacia',
        iconName: 'local_pharmacy',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
        items: [
          {
            link: 'reports/billing',
            label: 'Facturación',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/forecast',
            label: 'Pronóstico',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/severes',
            label: 'Agudos',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/instmed',
            label: 'Medicinas por institucion',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/patboxes',
            label: 'Caja por pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/polymedication',
            label: 'Polimedicación',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/ra',
            label: 'Reposición automática',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/mec',
            label: 'MEC',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/logs',
            label: 'Terceros',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/obsomeds',
            label: 'Obsolescencia',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/estupef',
            label: 'Estupefacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
          {
            link: 'reports/pmoperations',
            label: 'Operaciones del PM',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
          },
        ],
      },
    ],
  },
];
