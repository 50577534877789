import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';
import { ModalService } from '@services/modal.service';
import { LoadingService } from '@services/loading.service';
import { CallsService } from '@services/api/calls.service';
import { RoleManagerService } from '@services/role-manager.service';
import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';

@Component({
  selector: 'app-medicine-edit-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  @Input() dangerGroup: number | undefined = 0;

  constructor(
    private modalService: ModalService,
    private loadingService: LoadingService,
    private calls: CallsService,
    private roleManager: RoleManagerService,  
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    // Hide CF codes on quantum
    if ( this.roleManager.isQuantum() ) {
      this.schema[1].visible = false;
    }

    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));

    // IEF Clasification change subscription
    this.schema[3].fieldControl.valueChanges.subscribe(data => {
      // Get quantity of options selected (without none)
      const quantityWithoutNoneSelected = Object.keys(data).filter(key => key !== 'none' && data[key] === true).length;

      if ( quantityWithoutNoneSelected === 0 ) {
        // If no option selected, set none to true
        this.schema[3].fieldControl.get('none')?.setValue(true, {emitEvent: false});
      } else if ( quantityWithoutNoneSelected > 0 && data.none ) {
        // If some option selected and none is true, set none to false
        this.schema[3].fieldControl.get('none')?.setValue(false, {emitEvent: false});
      }

      // Remove error class
      this.schema[3].customClass = this.schema[3].customClass?.filter(item => item !== 'error');
    })

    // IEF Clasification (NONE) change subscription
    this.schema[3].fieldControl.get('none')?.valueChanges.subscribe(data => {
      if ( data ) {
        // If none is true, open modal
        this.modalService.openModalCallback(() => {
          // Action on accept
          // Set all options to false except none
          Object.keys(this.schema[3].fieldControl.value)
          .filter(key => key !== 'none')
          .forEach(key => {
            this.schema[3].fieldControl.get(key)?.setValue(false, {emitEvent: false});
          });

          // Set none to true
          this.schema[3].fieldControl.get('none')?.setValue(true, {emitEvent: false});
        }, "¿Está seguro de que desea limpiar la clasificación IEF existente?", "Clasificación IEF", false, () => {
          // Action on cancel
          // Set none to false
          this.schema[3].fieldControl.get('none')?.setValue(false, {emitEvent: false});
        })

        // Remove error class
        this.schema[3].customClass = this.schema[3].customClass?.filter(item => item !== 'error');
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  isMedicineHospitalUse(section: FormItemSchemaInterface): boolean {
    return section.fieldControl.get('hospitalUse')?.value === true;
  }
}
