<div class="re w-100 gap0">
    <mat-form-field appearance="outline" class="w-100">
        <div class="label">{{label}}</div>
        <input #input (blur)="exitEditMode($event)" type="text" matInput [formControl]="formControl" (keydown)="restrictToNumbers($event)" (keydown.enter)="intro($any($event.target).value)">
        <mat-error *ngIf="formControl.invalid">Debe tener minimo 11 digitos</mat-error>
    </mat-form-field>
    <button *ngIf="readOnly ? !readOnly() : true" #editModeButton mat-icon-button (click)="editMode = !editMode" [class.finalizar]="editMode">

        <mat-icon *ngIf="!editMode">add</mat-icon>
        <span class="label" *ngIf="editMode">FINALIZAR</span>

    </button>
</div>
<!-- <mat-form-field appearance="outline" class="w-100">

    <div class="container-button">
        <div class="container-input">
            <input #input (blur)="exitEditMode($event)" type="text" matInput [formControl]="formControl" (keydown)="restrictToNumbers($event)" (keydown.enter)="intro($any($event.target).value)">
            <mat-error *ngIf="formControl.invalid">Debe tener minimo 11 digitos</mat-error>

        </div>
        <div class="container-button">
            <button #editModeButton mat-button (click)="editMode = !editMode" [class.finalizar]="editMode">
                {{
                    editMode ? 'Finalizar' : 'Agregar'
                }}
            </button>
        </div>
    </div>
    <mat-error *ngIf="formControl.invalid">Debe tener minimo 11 digitos</mat-error>
    </mat-form-field> -->
