export interface WSReceivedMessage {
  notifyData: Array<AlertData> | string | null | any;
  notifyType: NotificationType;
  socketKey: string;
  timestamp: Date | string;
}

export interface AlertData {
  Field01: string;
  Field02: string;
  Field03: string;
  Field04: string;
  Field05: string;
  Field06: string;
  Field07: string;
  Field08: string;
  Field09: string;
  Field10: string;
  Field11: string;
  Field12: string;
  Field13: string;
  Field14: string;
  Field15: string;
  Field16: string;
  Field17: string;
  Field18: string;
  Field19: string;
  Field20: string;
  Flag: number;
  Id: number;
  InstIdDst: number;
  InstIdSrc: number;
  RoleNameDst: Date | string;
  Timestamp: string;
  UserIdDst: number;
  UserIdSrc: number;
}

export enum NotificationType {
    /// <summary>Ninguna notificación.</summary>
    None = 0,
    /// <summary>Notificación a la SPA para enviar una alerta.</summary>
    Alert,
    /// <summary>Notificación a la SPA para enviar un mensaje.</summary>
    Message,
    /// <summary>Notificación al servicio de que el cache no es válido porque se han modifciado datos.</summary>
    CacheFault,
    /// <summary>Notificación a la SPA de que la fecha de la última facturación ha sido modificada.</summary>
    LastInvoicingDate,
    /// <summary>Notificación al servicio para test de Websockets. Permite conocer si el socket aun existe.</summary>
    Test,
    /// <summary>Notificación al servicio de sesión expirada.</summary>
    Timeout,
    /// <summary>Notificación a la SPA del cambio de versión en el servidor.</summary>
    SpaVerChanged,
    /// <summary>Notificación al servicio para indicar que la SPA aun está "viva" o para verificar validez del token por timeout.</summary>
    ClientPing,
    /// <summary>Notificación a la SPA para que renueve el token.</summary>
    RefreshToken,
    /// <summary>Notificación a la SPA para indicarle que el token no es válido.</summary>
    InvalidToken,
    /// <summary>Notificación al servicio para devolver notificaciones pendientes.</summary>
    RequestPendingNotifications,
    /// <summary>Notificación a la SPA de cambios en estado de Exitus de pacientes.</summary>
    ExitusChange,
    /// <summary>Notificación a la SPA de cambios en documentación de pacientes.</summary>
    DocumentsChange,
    /// <summary>Notificación a la SPA de que la sesión está próxima a espirar.</summary>
    NextToExpire,
    /// <summary>Notificación al servicio de cambios en una orden de incontinencia.</summary>
    DiaperOrderChange,
    /// <summary>Notificación al servicio de cambios en datos de incontinencia.</summary>
    DiaperDataChange,
    /// <summary>Notificación personalizada para todos los clientes SPA activos.</summary>
    BroadcastAll,
    /// <summary>Notificación al servicio para indicar que la SPA entró en modo de "mantenimiento".</summary>
    MaintenanceModeChange,
    /// <summary>Notificación al servicio para indicar que un medicamento está sin inventario.</summary>
    MedicineOutOfStock,
    /// <summary>Notificación al servicio para indicar los medicamentos que tienen prescripciones activas y están obsoletos.</summary>
    ObsoleteMeds
}
