import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardsComponent } from '../cards/cards.component';

import { PatientsService } from '@services/patients.service';
import { FilterService } from '@services/filter/filter.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { CallsService } from '@services/api/calls.service';
import { Router } from '@angular/router';
import { PaginationService } from '@services/pagination/pagination.service';
import { RoleManagerService } from '@services/role-manager.service';
import { InstitutionService } from '@services/institution.service';

@Component({
    selector: 'app-lists',
    templateUrl: './lists.component.html',
    styleUrls: ['./lists.component.scss', '../../../../../../../src/app/components/base/list/list.component.scss'],
})
export class ListsComponent extends CardsComponent implements OnInit, OnDestroy {
    
    constructor(
        protected override patientsService: PatientsService,
        protected override filterService: FilterService,
        protected override loadingService: LoadingService,
        protected override modalService: ModalService,
        protected override calls: CallsService,
        protected override router: Router,
        protected override paginationService: PaginationService,
        protected override roleManager: RoleManagerService,
        protected override institutionService: InstitutionService
    ) { 
        super(
            patientsService, 
            filterService,
            loadingService,
            modalService,
            calls,
            router,
            paginationService,
            roleManager,
            institutionService
        )
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
