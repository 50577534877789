<app-report-select
  class="report-custom-container"
  [title]="'Anexos de tratamiento a pacientes'"
  (previewClicked)="handlePreviewClick()"
  (printClicked)="handlePrintClick()"
  (pdfClicked)="handlePdfClick()"
  (excelClicked)="handleExcelClick()"
>
  <div slot="content">
    <p>Tipo de anexo</p>
    <mat-radio-group aria-label="Selecciona una opción" class="annex-radio-group"
      [(ngModel)]="selectedRadio"
    >
      <mat-radio-button value="5" [checked]="true"
        >Anexo 5 - Detecció PRM
      </mat-radio-button>
      <mat-radio-button value="8" [checked]="false"
        >Anexo 8 - Full d'interconsulta
      </mat-radio-button>
    </mat-radio-group>
  </div>
</app-report-select>
