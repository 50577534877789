import { Component, Input, OnInit } from '@angular/core';
import { Link } from '@interfaces/router-link.interface';
import { UserDetail } from '@interfaces/user/user-detail.interface';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import packageJson from '@json/package.json';

import { AuthService } from '@services/auth.service';

import { inOutFadeFromLeft } from '@json/src/app/animations/general';
import { UserRole } from '@json/src/app/enums/user-role';
import { environment } from '@json/src/environments/environment';
import { ImageService } from '@services/image/image.service';

@Component({
  selector: 'app-logged-user-detail',
  templateUrl: './logged-user-detail.component.html',
  styleUrls: ['./logged-user-detail.component.scss'],
  animations: [ inOutFadeFromLeft ]
})
export class LoggedUserDetailComponent implements OnInit {
  @Input() input: UserDetail;
  @Input() isExpanded: boolean = false;

  public version: string = packageJson.version;

  public isOpen: boolean = false;
  public imgPath: string = '';
  public routeArray: Link[] = [];
  public userName: string = '';

  public name: string = '';
  public firstName: string = '';
  public secondName: string = '';

  constructor(
    private authService: AuthService,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.setRoutes();
    this.setImage();
    this.parseName();
  }

  parseName(): void {
    //replace => delete the comma and add and space
    let completeName = this.input.name.replace(',', '').split(' ');
    this.firstName = completeName[1];//completeName.split(' ').slice(0, 1).join();
    this.secondName = completeName[2];//completeName.split(' ').slice(1, 2).join();
    this.name = completeName[0];//completeName.split(' ').slice(-1).join();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  logOut(): void {
    this.authService.logOut();
  }

  getUserData(): void {
    // FIXME: datos reales
    this.userName = 'Usuario';
  }

  setImage(): void {
    const formatedID = this.imageService.formatNumberImage(Number(this.authService.getLoggedUser()));
    const imageUrl = `${environment.urlBack}resources/assets/pics/users/e00/${formatedID}.jpg`;
    this.loadImage(imageUrl, []);
  }

  loadImage(imageUrl: string, name: string[]): void {
    const img = new Image();
    const timestamp = new Date().getTime();
    const url = `${imageUrl}?timestamp=${timestamp}`;

    img.onload = () => {
      this.imgPath = url;
    };

    img.onerror = () => {
      // If there is an error, set the URL of the alternative image
      this.imgPath = this.input.imagePath || '/assets/images/default-logged-user.png';
    };

    img.src = url;
  }

  setRoutes(): void {
    this.routeArray = [];
    this.routeArray.push({ link: '/global-eye/admin', label: 'Administrar' });
    this.routeArray.push({ link: `/global-eye/admin/users/edit/${this.authService.getLoggedUser()}`, label: 'Mi perfil' });
  }

  hasPermission(route: Link): boolean {
    if ( route.label === 'Administrar' ) {
      return Utils.hasPermission(Permission.ADMINISTRATION)().includes(PermissionType.READ)
    } else {
      return true;
    }
  }
}
