<div class="container-fluid">
    <div class="profile">
      <app-form-detail 
        [title]="(!!medicineInfo && !!medicineInfo.general ? medicineInfo.general.name : '')" 
        [subTitle]="(!!medicineInfo && !!medicineInfo.general ? medicineInfo.general.masterCode : '')"
        [canSave]="true"
        [formChanged]="formChanged"
        [collapsedLayout]="collapsedProfile"
        (collapseStatus)="collapsedProfile = $event"
        (saveChanges)="updateMedicineEdit()">
      </app-form-detail>
    </div>
    <div class="content" [ngClass]="{'full-width': collapsedProfile}">
      <mat-tab-group 
              style="height: 100%;" 
              animationDuration="0ms" 
              [selectedIndex]="selectedTabIndex" 
              (selectedIndexChange)="indexChange($event)"
          >
        <ng-container *ngFor="let tab of tabs; let i = index">
          <mat-tab 
            *ngIf="tab.hidden == undefined || tab.hidden().length > 0" 
            [disabled]="!isEdit && tab.form !== 'general'">
            <ng-template mat-tab-label>
              <mat-icon *ngIf="forms[tab.form] && forms[tab.form].form.invalid && forms[tab.form].form.touched" 
              color="warn" 
              class="example-tab-icon me-2">
              info
              </mat-icon>
              {{ tab.label }}
            </ng-template>
            <ng-container [ngSwitch]="tab.form">
              <!-- General -->
              <ng-container *ngSwitchCase="'general'">
                <app-medicine-edit-general
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateMedicineEdit()"
                >
                </app-medicine-edit-general>
              </ng-container>
  
              <!-- Notes -->
              <ng-container *ngSwitchCase="'notes'">
                <app-medicine-edit-notes
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateMedicineEdit()"
                >
                </app-medicine-edit-notes>
              </ng-container>
  
              <!-- Robot -->
              <ng-container *ngSwitchCase="'robot'">
                <app-medicine-edit-robot
                  *ngIf="forms[tab.form]"
                  [schema]="forms[tab.form].schema" 
                  [formChanged]="formChanged" 
                  (save)="updateMedicineEdit()"
                >
                </app-medicine-edit-robot>
              </ng-container>
            </ng-container>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>