import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataInstitutionMed } from '@constants/reports/report-institution-med';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Medicine } from '@interfaces/medicine/medicine.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { MedicinesService } from '@services/medicines.service';
import { ReportsService } from '@services/reports/reports.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

@Component({
  selector: 'app-report-institution-med',
  templateUrl: './report-institution-med.component.html',
  styleUrls: ['./report-institution-med.component.scss'],
})
export class ReportInstitutionMedComponent {
  public reportDataInstitutionMed = reportDataInstitutionMed;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public medicinesNameOptions: SelectOption[];
  public medicinesCodeOptions: SelectOption[];

  public error = 'Debes elegir una medicina';
  public subs: Subscription[] = [];

  public selectOptionsInsitutions: SelectOption[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private calls: CallsService,
    private reportService: ReportsService,
    private medicineService: MedicinesService,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      medicine: ['', Validators.required],
      medicamentRadio: ['0'],
      // selectedInstitution: ['', Validators.required],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setCurrentDate();
  }



  setCurrentDate(): void {
    const currentDate = new Date();

    this.form.patchValue({ specificDay: currentDate });
  }

  getDataForTheSelects(): void {

    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
          Object.assign([], this.institutionService.institutionList.getValue());
           // Order Alpabetically label and text uppercase
           this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
          //  this.selectOptionsInsitutions.map((a) =>{
          //      a.label = a.label.toUpperCase();
          //  })
          if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
              );
              if (hasEmptyValue) {
              this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
              );
              }
          }
      });

    this.subs.push(
      this.medicineService.medicineListSelect.subscribe((medicines) => {
        const medicineList = medicines.map((p) =>
          this.medicineService.transformMedicineAPItoApp(p)
        );

        if (medicineList) {
          this.medicinesNameOptions =
            this.createMedicinesNameOptions(medicineList);
          this.medicinesCodeOptions =
            this.createMedicinesCodeOptions(medicineList);
        }
      })
    );

    this.medicineService.getMedicinesList(0);
  }

  createMedicinesNameOptions(medicineList: Medicine[]): SelectOption[] {
    return medicineList
      .map((medicine) => ({
        label: `${medicine.name} - (${medicine.code})`,
        value: medicine.id,
      }))
      .sort((a: SelectOption, b: SelectOption) => {
        return a.label.localeCompare(b.label);
      });
  }

  createMedicinesCodeOptions(medicineList: Medicine[]): SelectOption[] {
    return medicineList
      .map((medicine) => ({
        label: `${medicine.code} - (${medicine.name})`,
        value: medicine.id,
      }))
      .sort((a: SelectOption, b: SelectOption) => {
        return a.label.localeCompare(b.label);
      });
  }

  handlePreviewClick(): void {
    if (this.form.valid) {
      const { medicine } = this.getFormData();
      this.setInfoRP(medicine, true);

      this.reportService.handlePreviewClick(
        this.router,
        this.reportDataInstitutionMed
      );
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //     this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack(this.error);
      // }
    }
  }

  getFormData(): {
    medicine: string;
  } {
    const { medicine } = this.form.getRawValue();

    return {
      medicine,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid) {
      const { medicine } = this.getFormData();
      this.setInfoRP(medicine);

      this.reportService.handlePrintClick(this.reportDataInstitutionMed);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack(this.error);
      // }
    }
  }

  handlePdfClick(): void {
    if (this.form.valid) {
      const { medicine } = this.getFormData();
      this.setInfoRP(medicine);

      this.reportService.handlePdfClick(this.reportDataInstitutionMed);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack(this.error);
      // }
    }
  }

  handleExcelClick(): void {
    if (this.form.valid) {
      const { medicine } = this.getFormData();
      this.setInfoRP(medicine);

      this.reportService.handleExcelClick(this.reportDataInstitutionMed);
    } else {
      // if ( this.form.value.selectedInstitution == ''){
      //   this.calls.openSnack('Debes seleccionar un centro');
      // } else {
        this.calls.openSnack(this.error);
      // }
    }
  }

  setInfoRP(medicine: string, isPreview: boolean = false): void {

    let  currentInstitution = null;
    const dataForm = this.form.getRawValue()
    //@ts-ignore
    // currentInstitution = dataForm.selectedInstitution.id

    const rp = JSON.stringify({ LocaleId: '3082', MedicineId: medicine,  InstitutionId: String(currentInstitution) });

    isPreview ? this.reportDataInstitutionMed.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    this.reportDataInstitutionMed.dataDownloadPDF.dn = this.reportDataInstitutionMed.dataRenderReport.dn = `Medicacion_por_centro_${medicine}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataInstitutionMed.dataDownloadExcel.dn = `Medicacion_por_centro_${medicine}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataInstitutionMed.data.rp = rp;
    this.reportDataInstitutionMed.dataDownloadPDF.rp = rp;
    this.reportDataInstitutionMed.dataDownloadExcel.rp = rp;
    this.reportDataInstitutionMed.dataRenderReport.rp = rp;
  }
}
