import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

@Component({
  selector: 'app-medicine-edit-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends DynamicFormComponent implements OnInit, OnDestroy {

  constructor(
    protected override sortService: SortService,
    protected override cdr: ChangeDetectorRef
  ) {
    super(sortService, cdr)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.schema.forEach(schema => schema.childs?.forEach(child => {this.fieldVisible(child, child)}));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
