<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.items && child.items.length > 0 && testPerm(child)">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu" (mouseenter)="onMouseEnter($event)">
        <mat-icon mat-list-icon *ngIf="child.iconName">{{child.iconName}}</mat-icon>
        <span>{{child.label}}</span>
      </button>
      <app-sub-menu-item #menu [items]="child.items"></app-sub-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="(!child.items || child.items.length === 0) && testPerm(child)">
      <button mat-menu-item [routerLink]="'./' + child.link">
        <mat-icon mat-list-icon *ngIf="child.iconName">{{child.iconName}}</mat-icon>
        <span>{{child.label}}</span>
      </button>
    </span>
  </span>
</mat-menu>
