import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {

  source: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: {thumbnail: string, full: string}) {
    this.source = data.full;
  }

  onError() {
    this.source = this.data.thumbnail;
  }
}
