export interface InteractionAPI {
  Id: number;
  Name: string;
  DrugId: string;
}

export class Interaction {
  public id: string;
  public label: string;
  public value: number;

  constructor(input: InteractionAPI) {
    this.value = input.Id; // id of the medication to be searched for
    this.label = input.Name;
    this.id = input.DrugId;
  }
}
