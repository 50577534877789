<div class="stop-prescription-modal">
    <mat-dialog-content class="stop-prescription-modal__container">
        <h3 *ngIf="mode === 'stop'" class="title">Detener prescripción</h3>
        <h3 *ngIf="mode === 'modify'" class="title">Modificar prescripción</h3>
        <div class="content">
            <ng-container *ngIf="mode === 'stop'">
                <span>Detener la prescripción {{ prescription.DrugName }} con fecha inicial {{ prescription.StartDate | date: 'dd/MM/yyyy' }}.</span>
                <span>Indique la fecha en que desea detener la prescripción.</span>
                <span>La prescripción existente estará activa hasta el día de la fecha indicada. Un día después, la prescripción ya no estará activa.</span>
            </ng-container>
            <ng-container *ngIf="mode === 'modify'">
                <span>Modificar la prescripción {{ prescription.DrugName }} con fecha inicial {{ prescription.StartDate | date: 'dd/MM/yyyy' }}.</span>
                <span>Indique la fecha en que desea modificar la prescripción.</span>
                <span>Esta fecha indica el día en que se detiene la prescripción actual. La prescripción modificada se iniciará al día siguiente.</span>
            </ng-container>
            <div class="date-field">
                <app-form-field
                    type="date"
                    label="Fecha"
                    [hideLabel]="true"
                    [fieldControl]="form.controls['date'] | asFormControl"
                    [dateFieldData]="{ disablePreviousDate: mode === 'stop' ? today : yesterday }"
                ></app-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="stop-prescription-modal__buttons">
        <button mat-raised-button color="primary" (click)="clickAccept()" tabindex="1">Aceptar</button>
        <button mat-raised-button mat-dialog-close tabindex="-1">Cancelar</button>
    </mat-dialog-actions>
</div>