import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataDebsOrders } from '@constants/reports/report-debs-orders';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-debs-orders',
    templateUrl: './report-debs-orders.component.html',
    styleUrls: ['./report-debs-orders.component.scss'],
})
export class ReportDebsOrdersComponent {
    public reportDataDebsOrders = reportDataDebsOrders;
    public currentInstitution: number;
    public showPatientSelect: boolean = false;
    public form: FormGroup;
    public report: Report;
    public pathReportDownload: string;

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            checkboxDoctorSS: [''],
            consolidate: true,
            typeReport: ['1'],
            selectedInstitution: [''],
            OnlyExitus: false
        });
        this.currentInstitution = this.institutionService.getCurrentInstitution();

        this.reportDataDebsOrders.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        const now = moment();
        this.reportDataDebsOrders.dataDownloadPDF.dn = this.reportDataDebsOrders.dataRenderReport.dn =
            `Deuda_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataDebsOrders.dataDownloadExcel.dn = `Deuda_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Deudas agudos/adelantos/pedidos`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Deudas agudos/adelantos/pedidos`);

        this.typeGlobal = this.roleManager.isPharma();
        this.getDataForTheSelects();
    }


    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
          this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());
            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
            
          if (this.selectOptionsInsitutions.length > 0) {
            const hasAllInstitutionsOption = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
            );
            if (!hasAllInstitutionsOption) {
              this.selectOptionsInsitutions.unshift({
                value: '',
                label: 'Todos los centros',
              });
            }
          }
        });


        if ( !this.typeGlobal ) {
            this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
          }
      }
      


    handlePreviewClick(): void {
        if (this.form.valid) {
            this.reportDataDebsOrders.data.rp = this.getInfoRP();
            this.reportDataDebsOrders.data.rpp = this.getInfoRPP();
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataDebsOrders
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            this.reportDataDebsOrders.dataRenderReport.rp = this.getInfoRP();
            this.reportDataDebsOrders.dataRenderReport.rpp = this.getInfoRPP();
            this.reportService.handlePrintClick(this.reportDataDebsOrders);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            this.reportDataDebsOrders.dataDownloadPDF.rp = this.getInfoRP();
            this.reportDataDebsOrders.dataDownloadPDF.rpp = this.getInfoRPP();
            this.reportService.handlePdfClick(this.reportDataDebsOrders);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            this.reportDataDebsOrders.dataDownloadExcel.rp = this.getInfoRP();
            this.reportDataDebsOrders.dataDownloadExcel.rpp = this.getInfoRPP();
            this.reportService.handleExcelClick(this.reportDataDebsOrders);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    getFormData(): {
        checkboxDoctorSS: string;
        typeReport: string;
    } {
        const checkboxDoctorSS = (!this.typeGlobal || this.form.controls['selectedInstitution'].value !== '') ? this.form.get('checkboxDoctorSS')?.value : false;
        const typeReport = this.form.get('typeReport')?.value;

        return { checkboxDoctorSS, typeReport };
    }

    getInfoRP(): string {

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        if (dataForm.selectedInstitution.id ) {
            //@ts-ignore
            currentInstitution = dataForm.selectedInstitution.id.toString();
        } else {
            currentInstitution = null;
        }

        const { checkboxDoctorSS, typeReport } = this.getFormData();

        const Exitus = this.form.get('OnlyExitus')?.value;
        const data = {
            LocaleId: '3082',
            DateUntil: null,
            InstitutionId: currentInstitution,
            FilterType: typeReport,
            GroupMedicoSS: checkboxDoctorSS ? String(checkboxDoctorSS) : null,
            OnlyExitus: this.typeGlobal ? String(Exitus) : "false",
        };

        return JSON.stringify(data);
    }
    getInfoRPP(): string {
        if ( this.typeGlobal ) {
            if ( this.form.get('consolidate')?.value && this.form.controls['selectedInstitution'].value === '' ) {
                return '/COROTA/MediDebtCons';
            } else {
                return '/COROTA/MediDebtByPat';
            }
        } else {
            return '/COROTA/MediDebtByPat';
        }
    }
}
