import { Component } from '@angular/core';
import { RoleManagerService } from '@services/role-manager.service';
import { appConfig } from '../config-quantum';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'quantum-app';

    constructor(private manager: RoleManagerService) { }

    ngOnInit(): void {
        // setear configuración de la app
        this.manager.setOrigin(appConfig.origin);
    }
}
