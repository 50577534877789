<mat-form-field appearance="outline" floatLabel="always">
  <div class="label">Filtros</div>

  <mat-select [formControl]="filter" multiple>
    <mat-select-trigger>
      <span *ngIf="filter.value && filter.value.length === 1">{{filter.value?.[0]}}</span>
      <span *ngIf="filter.value && filter.value.length > 1">{{filter.value?.length}} grupos incluidos</span>
    </mat-select-trigger>
    <mat-optgroup *ngFor="let group of options">
      <div class="group-label">
        <span>{{group.name}}</span>
        <div class="buttons">
          <button class="include" (click)="setGroupState($event, group.name, 'include')" [ngClass]="{'active': getGroupState(group.name) === 'include'}">Incluir</button>
          <button class="exclusive">Mostrar solo</button>
          <button class="exclude" (click)="setGroupState($event, group.name, 'exclude')" [ngClass]="{'active': getGroupState(group.name) === 'exclude'}">No mostrar</button>
        </div>
      </div>

      <mat-option *ngFor="let item of group.filter" [value]="item.value" disabled>
        <div class="option">
          <span>
            {{item.value}}
          </span>
          <div class="buttons">
            <button class="include" (click)="setItemState($event, item, 'include')" [ngClass]="{'active': getItemState(item) === 'include'}">Incluir</button>
            <button class="exclusive" (click)="setItemState($event, item, 'exclusive')" [ngClass]="{'active': getItemState(item) === 'exclusive'}">Mostrar solo</button>
            <button class="exclude" (click)="setItemState($event, item, 'exclude')" [ngClass]="{'active': getItemState(item) === 'exclude'}">No mostrar</button>
          </div>
        </div>
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>