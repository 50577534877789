<div class="container" [ngClass]="{'collapsed': collapsedLayout}">
    <section class="user" (click)="collapseStatus.emit(!collapsedLayout)">
        <mat-icon class="collapse-hint">first_page</mat-icon>
        <div class="avatar" (click)="avatarZoomable && zoomAvatar($event)">
            <lazy-img 
                *ngIf="paramId" 
                [source]="src"  
                [alt]="'Detalle de ' + title"
                [ngClass]="{'isVademecum': isVademecum}"
            >
            </lazy-img>
        </div>

        <!-- Patient info -->
        <div class="info" *ngIf="section === MainSection.Patient ">
            <div class="data" *ngIf="title">
                <span class="key" *ngIf="!collapsedLayout" @inOutFade>NOMBRE</span>
                <span class="value" *ngIf="!collapsedLayout" @inOutFade>{{ capitalize(title) }}  {{ capitalize(subTitle) }}</span>
                <span class="value" *ngIf="collapsedLayout" @inOutFade>{{ initials }}</span>
            </div>

            <div class="data" *ngIf="patientData && !collapsedLayout" @inOutFade>
                <span class="key">RESIDENCIA</span>
                <span class="value">{{ patientData.pat.ins + " - " + patientData.pat.num }} </span>
            </div>
        </div>

        <!-- Medicine info -->
        <div class="info" *ngIf="section === MainSection.Vademecum">
            <div class="data" *ngIf="title">
                <span class="key" *ngIf="!collapsedLayout" @inOutFade>NOMBRE</span>
                <span class="value" *ngIf="!collapsedLayout" @inOutFade>{{ capitalize(title) }}</span>
                <span class="value" *ngIf="collapsedLayout" @inOutFade>{{ initials }}</span>
            </div>

            <div class="data" *ngIf="!collapsedLayout" @inOutFade>
                <span class="key">COD. MAESTRO</span>
                <span class="value">{{ capitalize(subTitle) }}</span>
            </div>
        </div>

        <!-- Institution info -->
        <div class="info" *ngIf="section === MainSection.Institution">
            <div class="data" *ngIf="title">
                <span class="key" *ngIf="!collapsedLayout" @inOutFade>NOMBRE</span>
                <span class="value" *ngIf="!collapsedLayout" @inOutFade>{{ capitalize(title) }}</span>
                <span class="value" *ngIf="collapsedLayout" @inOutFade>{{ initials }}</span>
            </div>

            <div class="data" *ngIf="!collapsedLayout" @inOutFade>
                <span class="key">Abreviatura</span>
                <span class="value">{{ capitalize(subTitle) }}</span>
            </div>
        </div>
    </section>

    <ng-container *ngFor="let action of actions ?? defaultActions">
        <button mat-button class="action"
            *ngIf="(action.id !== '2' && action.id !== '3' && !hideImageActions) || (action.id === '2' && canSave) || (action.id === '3' && canUndo)"
            (click)="action?.callback()"
            [disabled]="(action.id === '2' && !formChanged) || (action.id === '3' && !formChanged) || (action.disabled || disabledAllActions) && (action.id != '2' && canSave)"
            [ngClass]="{
                'save': action.id === '2',
                'undo': action.id === '3'
            }">
            <mat-icon>{{ action.iconName }}</mat-icon><span *ngIf="!collapsedLayout" @inOutFade>{{ action.name }}</span>
        </button>
    </ng-container>

    <ng-container *ngFor="let action of aditionalActions">  
        <button mat-button class="action"
            (click)="action?.callback()"
            [disabled]="action.disabled"
            [ngClass]="action.customClass">
            <mat-icon>{{ action.iconName }}</mat-icon>
            <span *ngIf="!collapsedLayout" @inOutFade>{{ action.name }}</span>
        </button>
    </ng-container>
</div>
