import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataFGPPrescriptions } from '@constants/reports/report-fgp-prescriptions';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { PatientPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-fgp-prescriptions',
    templateUrl: './report-fgp-prescriptions.component.html',
    styleUrls: ['./report-fgp-prescriptions.component.scss'],
})
export class ReportFGPPrescriptionsComponent implements OnInit{
    public reportDataFGPPrescriptions = reportDataFGPPrescriptions;
    public currentRoute: string;
    public dateForm: FormGroup;
    public report: Report;
    public pathReportDownload: string;
    public showPatientSelect: boolean = false;
    public currentInstitution: number;

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

    _selectOptionsPatients: PatientPharma[];
    selectOptionsPatientsAutocomplete: SelectOption[];
    set selectOptionsPatients(value: PatientPharma[]) {
        this._selectOptionsPatients = value;
        this.selectOptionsPatientsAutocomplete = value.map(v => {
            return {
                label: v.nam,
                value: v.id
            }
        })
    }
  
    multipleInstitutionSelect: boolean = false;

    resetInstitutionsDropdown: boolean = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private cdRef: ChangeDetectorRef,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.dateForm = this.formBuilder.group({
            startDate: [moment().subtract(7, 'days'), Validators.required],
            endDate: [moment(), Validators.required],
            selectedInstitution: ['', Validators.required],
            checkboxControlPatient: [''],
            selectedPatient: [''],
        });
        this.currentRoute = this.router.url;

        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Prescripciones FGP`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Prescripciones FGP`);

        this.typeGlobal = this.roleManager.isPharma();
        this.getDataForTheSelects();
    }

    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
          this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

          if (this.selectOptionsInsitutions) {
            const hasEmptyValue = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
            );
            if (hasEmptyValue) {
              this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                (item) => item.value !== ''
              );
            }
          }

                
            this.dateForm.controls['selectedInstitution'].setValue(
                this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
            )
        });

        this.reportService
            .getRepParamsEditDataPharmacologicPlan(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsPatients = result.payload.pats;
            });
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.dateForm.patchValue({
                selectedPatient: '',
            });
        }
    }

    getFormData(): { startDate: string; endDate: string; selectedInstitution: string, selectedPatient: string} {
        const startDate = this.dateService.formatDateToString(
            moment(this.dateForm.get('startDate')?.value).startOf('day').toDate()
        );
        const endDate = this.dateService.formatDateToString(
            moment(this.dateForm.get('endDate')?.value).endOf('day').toDate()
        );

        const {
            selectedInstitution,
          } = this.dateForm.getRawValue();
        
        const selectedPatient = this.dateForm.get('selectedPatient')?.value;
        

        return { startDate, endDate ,  selectedInstitution, selectedPatient};
    }

    handlePreviewClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate,  selectedInstitution, selectedPatient } = this.getFormData();
            this.setInfoRP(startDate, endDate , selectedInstitution, selectedPatient,  true);
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataFGPPrescriptions
            );
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handlePrintClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, selectedPatient } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, selectedPatient);
            this.reportService.handlePrintClick(this.reportDataFGPPrescriptions);
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handlePdfClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, selectedPatient } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, selectedPatient);
            this.reportService.handlePdfClick(this.reportDataFGPPrescriptions);
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handleExcelClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, selectedPatient } = this.getFormData();
            this.setInfoRP(startDate, endDate, selectedInstitution, selectedPatient);
            this.reportService.handleExcelClick(this.reportDataFGPPrescriptions);
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    setInfoRP(
        startDate: string, 
        endDate: string,   
        selectedInstitution: any, 
        selectedPatient: string,
        isPreview: boolean = false
    ): void {

        let  currentInstitution;

        if ( this.typeGlobal ) {    // Is Pharma
            const dataForm = this.dateForm.getRawValue()

            currentInstitution = dataForm.selectedInstitution.id.toString();
        } else {    // Is Quantum
            currentInstitution = this.institutionService.getCurrentInstitution();
        }

        const rp = {
            LocaleId: '3082',
            InstitutionId: currentInstitution,
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DateFrom: startDate,
            DateUntil: endDate,
        };
        const stringRP = JSON.stringify(rp);
        this.reportDataFGPPrescriptions.data.rp = stringRP;
        if (isPreview) this.reportDataFGPPrescriptions.data.dn = Utils.buildUniqueFileName + '_{0}.html';
        this.reportDataFGPPrescriptions.dataDownloadPDF.rp = stringRP;
        this.reportDataFGPPrescriptions.dataDownloadExcel.rp = stringRP;
        this.reportDataFGPPrescriptions.dataRenderReport.rp = stringRP;

        const now = moment();
        this.reportDataFGPPrescriptions.dataDownloadPDF.dn = this.reportDataFGPPrescriptions.dataRenderReport.dn =
            `Prescripciones_FGP_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataFGPPrescriptions.dataDownloadExcel.dn = `Prescripciones_FGP_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    
}
