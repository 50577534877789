import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HeadersService } from '../headers/headers.service';
import { CRUDCallInterface } from '@interfaces/crud-helpers.interface';
import { createCall } from '../api-call/api-call.service';
import { environment } from '@json/src/environments/environment';

export type ApiMethodType = 'create' | 'read' | 'update' | 'patch' | 'delete';

@Injectable({
  providedIn: 'root',
})
export class CRUDService extends HeadersService {
  constructor(
    private http: HttpClient
  ) {
    super(http);
  }

  /**
   * One function to perform all of 4 actions.
   * A.K.A -> CRUD
   *
   * @param method action to perform
   * @param call ${backurl}/api/v1/[call]
   * @param headerType See headers.service.ts for more info
   * @param id ${backurl}/api/v1/${call}/[id]
   * @param params Url params. Has to be an object with key: value. like: {access: asdf-1234-zxvc-6789, param: true} -> ${backurl}/api/v1/${call}/${id}?access=asdf-1234-zxvc-6789&param=true
   * @param data it could be an object with key value or FormData in case the data has to save any file.
   * @returns Observable with backend response
   */
  run<T>({
    method,
    call,
    headerType,
    id,
    params,
    data,
    specificUrl,
    token
  }: CRUDCallInterface): Observable<any> {
    if (!method) method = 'read';
    if (!headerType) headerType = 'common';
    if (!params) {
      params = {};
    }

    if (environment.urlBack == 'http://localhost/') call = call.replace('CorotaWebService', 'CorotaClient');

    let url = specificUrl ? specificUrl : createCall(call, id, params);
    let headers = this.getHeaderByType(headerType);
    if (token != undefined) {
        if (headers.headers && headers.headers instanceof HttpHeaders) {
            let h = headers.headers as HttpHeaders;
            if (h.has('Authorization')) {
                h = h.set('Authorization', `Basic ${token}`);
            }
            headers = {headers: h};
        }
    }

    switch (method) {
      case 'create':
        return this.http.post<T>(url, data, headers);
      case 'read':
        return this.http.get<T>(url, headers);
      case 'update':
        return this.http.put<T>(url, data, headers);
      case 'patch':
        return this.http.patch<T>(url, data, headers);
      case 'delete':
        return this.http.delete<T>(url, { ...headers, body: data });
      default:
        return throwError(new Error(`No call method for ${call}`));
    }
  }
}
