import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Report } from '@interfaces/report/report.interface';
import { CallsService } from '@services/api/calls.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import {
    categoriesMedicaments,
    clasificationMedicaments,
    reportDataListPatients,
} from '@constants/reports/report-list-patients';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Utils } from '@json/src/app/Utils';
import moment from 'moment';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-report-list-patients',
    templateUrl: './report-list-patients.component.html',
    styleUrls: ['./report-list-patients.component.scss'],
})
export class ReportListPatientsComponent implements OnInit {

    @ViewChild("meds", { static: false }) meds: MatExpansionPanel | undefined;
    @ViewChild("class", { static: false }) class: MatExpansionPanel;
    public reportDataListPatients = reportDataListPatients;
    public categoriesMedicaments = categoriesMedicaments;
    public clasificationMedicaments = clasificationMedicaments;
    currentRoute: string;
    report: Report;
    pathReportDownload: string;
    safeURL: SafeUrl;
    categoryMedicationForm: FormGroup<any>;
    classificationMedicationForm: FormGroup<any>;


    public selectOptionsInsitutions: SelectOption[];
    public form: FormGroup;
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  

    
    constructor(
        private router: Router,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private calls: CallsService,
        private formBuilder: FormBuilder,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.currentRoute = this.router.url;
        this.categoryMedicationForm = this.formBuilder.group({
            selectedCategory: ['Diabetes'],
        });

        this.form = this.formBuilder.group({
            selectedInstitution: [''],
        });

        this.classificationMedicationForm = this.formBuilder.group({
            selectedClassification: ['AtHome'],
        });

        const now = moment();
        this.reportDataListPatients.dataDownloadPDF.dn = this.reportDataListPatients.dataRenderReport.dn =
            `Listado_de_pacientes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataListPatients.dataDownloadExcel.dn = `Listado_de_pacientes_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Listado de pacientes`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Listado de pacientes`);

        this.getInstitution();
        this.typeGlobal = this.roleManager.isPharma();
    }
    getInstitution(): void {
        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }

                this.selectOptionsInsitutions.unshift({
                    label: 'Todos los centros',
                    value: ''
                });
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
              }
        });
    }
    
    handlePreviewClick(): void {


        if (this.form.valid) {

            const category = this.categoryMedicationForm.get('selectedCategory');
            const classification = this.classificationMedicationForm.get(
                'selectedClassification'
            );
            if (category && this.meds?.expanded) {
                const filter = category.value;
                const updatedRP = this.getCategoryRP(filter);

                this.reportDataListPatients.data.rpp = '/COROTA/PatientList'
                this.reportDataListPatients.data.rp = updatedRP;
                this.reportDataListPatients.data.dn = Utils.buildUniqueFileName + "_{0}.html";

                this.reportService.handlePreviewClick(
                    this.router,
                    this.reportDataListPatients
                );
            }
            else if (classification && this.class.expanded) {
                const filter = classification.value;
                const updatedRP = this.getClassificationRP(filter);

                this.reportDataListPatients.data.rpp = '/COROTA/PatientListClasif'
                this.reportDataListPatients.data.rp = updatedRP;
                this.reportDataListPatients.data.dn = Utils.buildUniqueFileName + "_{0}.html";

                this.reportService.handlePreviewClick(
                    this.router,
                    this.reportDataListPatients
                );
            }
        } else{
            this.calls.openSnack('Debes seleccionar un centro');
        }
    }

    clearCategory(): void {
        this.categoryMedicationForm.patchValue({
            selectedCategory: '',
        });
    }

    clearClassification(): void {
        this.classificationMedicationForm.patchValue({
            selectedClassification: '',
        });
    }

    handlePrintClick(): void {
        const category = this.categoryMedicationForm.get('selectedCategory');
        const classification = this.classificationMedicationForm.get(
            'selectedClassification'
        );
        if (category && this.meds?.expanded) {
            const filter = category.value;
            const updatedRP = this.getCategoryRP(filter);
            this.reportDataListPatients.dataRenderReport.rpp = '/COROTA/PatientList'
            this.reportDataListPatients.dataRenderReport.rp = updatedRP;
            this.printDocument();
        }
        else if (classification && this.class.expanded) {
            const filter = classification.value;
            const updatedRP = this.getClassificationRP(filter);
            this.reportDataListPatients.dataRenderReport.rpp = '/COROTA/PatientListClasif'
            this.reportDataListPatients.dataRenderReport.rp = updatedRP;
            this.printDocument();
        }
    }

    printDocument(): void {
        this.reportService.handlePrintClick(this.reportDataListPatients);
    }

    downloadPDFDocument(): void {
        this.reportService.handlePdfClick(this.reportDataListPatients);
    }

    handlePdfClick(): void {
        const category = this.categoryMedicationForm.get('selectedCategory');
        const classification = this.classificationMedicationForm.get(
            'selectedClassification'
        );
        if (category && this.meds?.expanded) {
            const filter = category.value;
            const updatedRP = this.getCategoryRP(filter);
            this.reportDataListPatients.dataDownloadPDF.rpp = '/COROTA/PatientList'
            this.reportDataListPatients.dataDownloadPDF.rp = updatedRP;
            this.downloadPDFDocument();
        }
        else if (classification && this.class.expanded) {
            const filter = classification.value;
            const updatedRP = this.getClassificationRP(filter);
            this.reportDataListPatients.dataDownloadPDF.rpp = '/COROTA/PatientListClasif'
            this.reportDataListPatients.dataDownloadPDF.rp = updatedRP;
            this.downloadPDFDocument();
        }
    }

    handleExcelClick(): void {
        const category = this.categoryMedicationForm.get('selectedCategory');
        const classification = this.classificationMedicationForm.get(
            'selectedClassification'
        );
        if (category && this.meds?.expanded) {
            const filter = category.value;
            const updatedRP = this.getCategoryRP(filter);
            this.reportDataListPatients.dataDownloadExcel.rpp = '/COROTA/PatientList'
            this.reportDataListPatients.dataDownloadExcel.rp = updatedRP;
            this.downloadExcelDocument();
        }
        else if (classification && this.class.expanded) {
            const filter = classification.value;
            const updatedRP = this.getClassificationRP(filter);
            this.reportDataListPatients.dataDownloadExcel.rpp = '/COROTA/PatientListClasif'
            this.reportDataListPatients.dataDownloadExcel.rp = updatedRP;
            this.downloadExcelDocument();
        }
    }

    downloadExcelDocument(): void {
        this.reportService.handleExcelClick(this.reportDataListPatients);
    }

    getClassificationRP(filter: string): string {
        const rp =
            '{"LocaleId":"3082","TitleLocale":"ListadoDePacientes","InstitutionId":"","Items":null,"SelectFilter":"","AtHome":"false","MUTUAS":"false"}';
        const validSelectFilter = this.searchClassification(filter);
        const updatedRP = this.updateFilter(rp, filter, validSelectFilter);
        return updatedRP;
    }

    getCategoryRP(filter: string): string {
        const rp =
            '{"LocaleId":"3082","TitleLocale":"ListadoDePacientes","InstitutionId":"","Items":null,"SelectFilter":"","Diabetes":"false","Alzheimer":"false","NervADP":"false","NervAPS":"false","NervEUTATC":"false","NervANS":"false","Antibio":"false","Anticoag":"false","Opioide":"false"}';
        const validSelectFilter = this.searchCategory(filter);
        const updatedRP = this.updateFilter(rp, filter, validSelectFilter);
        return updatedRP;
    }

    updateFilter(rp: string, filter: string, validSelectFilter: string): string {
        const data = JSON.parse(rp);

        // Update SelectValue && institutionId

        data.SelectFilter = validSelectFilter;

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        if (dataForm.selectedInstitution.id ) {
            //@ts-ignore
            currentInstitution = dataForm.selectedInstitution.id
            data.InstitutionId = currentInstitution.toString();
        } else {
            data.InstitutionId = null;
        }

        // data.InstitutionId = this.institutionService.getCurrentInstitution().toString();

        // Search for the filter name and change it to "true".
        for (const key in data) {
            if (data.hasOwnProperty(key) && key === filter) {
                data[key] = 'true';
            }
        }

        return JSON.stringify(data);
    }

    searchCategory(filter: string): string {
        const category = this.categoriesMedicaments.find(
            (object) => object.name === filter
        );
        return category?.filter ?? '';
    }

    searchClassification(filter: string): string {
        const classification = this.clasificationMedicaments.find(
            (object) => object.name === filter
        );
        return classification?.filter ?? '';
    }
}
