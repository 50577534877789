import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AlertData, NotificationType, WSReceivedMessage } from '@interfaces/web-sockets/wsreceived-message';
import { environment } from '@json/src/environments/environment';
import { FooterComponent } from '@shared/footer/footer.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpireComponent } from '@shared/web-sockets/session-expire/session-expire.component';
import { ReportsService } from './reports/reports.service';

@Injectable({
    providedIn: 'root'
})
export class WebSocketsService {

    toShow: any = {};
    message: BehaviorSubject<any> = new BehaviorSubject<any>(this.toShow);
    socket?: WebSocket = undefined;
    socketKey: string | null;
    constructor(
        private snack: MatSnackBar,
        private router: Router,
        private auth: AuthService,
        private dialog: MatDialog,
        private reportService: ReportsService
    ) {
        this.auth.onLogout.subscribe(() => {
            this.disconnect();
        });

        setTimeout(() => {
            this.broadcastMessageSubject.next({
                name: "ra.report.status.updated",
                args: undefined
            });
        }, 5000);
    }

    connect() {
        if (this.socket != undefined && [0, 1].indexOf(this.socket.readyState) > -1) {
            // Ya estamos conectando
            return;
        }
        else if (this.socket != undefined) {
            console.log("socket " + this.socket.readyState);
            this.socket.close();
            this.socket = undefined;
            this.socketKey = null;
        }


        const token = localStorage.getItem("access_token");
        if (token != undefined) {
            const now = Date.now();
            this.socketKey = token + "." + now;
            this.socket = new WebSocket(`${environment.urlWS}/CorotaWebSocket.svc?token=${token}&Ts=${now}`);//new WebSocket(`ws://${environment.urlBack.split('://')[1]}/CorotaWebSocket.svc?token=${token}&Ts=${now}`);
            this.socket.addEventListener("open", (ev) => this.onOpen(ev));
            this.socket.addEventListener("close", (ev) => this.onClose(ev));
            this.socket.addEventListener("error", (ev) => this.onError(ev));
            this.socket.addEventListener("message", (ev) => this.onMessage(ev));
        }
    }

    disconnect() {
        // Clean messages
        this.message.next({});

        if (this.socket != undefined) {
            this.socket.close(3001);
            this.socket = undefined;
            this.socketKey = null;
        }
        clearInterval(this.requestTimer);
    }

    private onOpen(evt: Event) {
        this.setRequestTimer();
    }

    private onClose(evt: CloseEvent) {
        if (evt.code != 3001) this.connect();
        console.log(evt);
    }

    private onError(evt: Event) {
        console.log(evt);
    }

    private broadcastMessageSubject: Subject<{ name: string, args?: Array<any> }> = new Subject<{ name: string, args?: Array<any> }>();
    broadcastMessage = this.broadcastMessageSubject.asObservable()

    private onMessage(evt: MessageEvent) {
        const msg = JSON.parse(evt.data, (key: string, value: any) => {
            if (key == "timestamp") {
                return new Date(value);
            }
            return value;
        }) as WSReceivedMessage;

        switch (msg.notifyType) {
            case NotificationType.Alert:
                this.setRequestTimer();
                this.processAlert(msg);
                break;
            case NotificationType.Message:
                this.setRequestTimer();
                this.processMessage(msg);
                break;
            // case NotificationType.CacheFault:
            //     break;
            case NotificationType.LastInvoicingDate:
                const d = new Date(msg.notifyData);
                this.reportService.lastInvoicingDate = new Date(d.getTime() - d.getTimezoneOffset() * -60000);
                window.dispatchEvent(new CustomEvent("lastInvoicingDate", {
                    detail: { date: this.reportService.lastInvoicingDate }
                }));
                break;
            case NotificationType.SpaVerChanged:
                this.auth.logOut("version");
                break;
            case NotificationType.MaintenanceModeChange:
                this.auth.logOut("maintenance");
                break;
            case NotificationType.Timeout:
                this.snack.open("La sesión ha caducado. Por favor, introduzca sus credenciales nuevamente.", undefined, {
                    duration: 2000
                });
                this.auth.logOut();
                break;
            case NotificationType.InvalidToken:
                this.snack.open("El token de acceso enviado no es válido.", undefined, {
                    duration: 2000
                });
                this.auth.logOut();
                break;
            case NotificationType.RefreshToken:
                var newToken = JSON.parse(msg.notifyData as string);
                console.log('Token refreshed! : ' + newToken);
                this.auth.loginFromToken(newToken);
                break;
            case NotificationType.ExitusChange:
                this.requestPendingNotifications();
                break;
            case NotificationType.DocumentsChange:
                this.requestPendingNotifications();
                break;
            case NotificationType.NextToExpire:
                this.showNextToExpire();
                break;
            case NotificationType.MedicineOutOfStock:
                this.requestPendingNotifications();
                break;
            case NotificationType.BroadcastAll:
                /**
                 * De servidor solo se recibe 'ra.report.status.updated'
                 */
                this.broadcastMessageSubject.next({
                    name: msg.notifyData.broadcastMsg,
                    args: msg.notifyData.broadcastData || null
                });
                break;
            case NotificationType.ObsoleteMeds:
                msg.notifyData.ObsoleteMeds.forEach((med: string) => {
                    if (this.toShow['Medicamentos obsoletos con prescripción activa'] == undefined) this.toShow['Medicamentos obsoletos con prescripción activa'] = [];
                    this.toShow['Medicamentos obsoletos con prescripción activa'].push({
                        display: med,
                        action: `/global-eye/patients/cards?med="${med}"`,
                    });
                })
                this.message.next(this.toShow);
                break;
        }
    }

    //#region Funciones de las notificaciones
    processAlert(msg: any) {
        this.toShow = {};
        (msg.notifyData as Array<AlertData>).forEach(alert => {
            if (alert.Flag & 4096) {
                const warningLevel = (alert.Flag & 3072) >> 10;

                switch ((alert.Flag & 1008) >> 4) {
                    case 0: // Incidencia en RE
                        if (this.toShow[ShowTypes.INCIDENCIAS_RE] == undefined) this.toShow[ShowTypes.INCIDENCIAS_RE] = [];
                        this.toShow[ShowTypes.INCIDENCIAS_RE].push({
                            action: "/global-eye/reports/restat",
                            color: this.colorFromWarningLevel(warningLevel)
                        });
                        break;
                    case 1: // Incidencia de PRM
                        if (this.toShow[ShowTypes.INCIDENCIAS_PRM] == undefined) this.toShow[ShowTypes.INCIDENCIAS_PRM] = [];
                        this.toShow[ShowTypes.INCIDENCIAS_PRM].push({
                            action: "/global-eye/reports/restat",
                            color: this.colorFromWarningLevel(warningLevel)
                        });
                        break;
                    case 2: // Incidencia de Exitus
                        if (this.toShow[ShowTypes.INCIDENCIAS_EXITUS] == undefined) this.toShow[ShowTypes.INCIDENCIAS_EXITUS] = [];
                        this.toShow[ShowTypes.INCIDENCIAS_EXITUS].push({
                            display: alert.Field02.replace(/\|/g, ", "),
                            action: "/global-eye/procs/re-status",
                            color: this.colorFromWarningLevel(warningLevel)
                        });
                        break;
                    case 3: // Modificación a documentos de pacientes
                        // console.log(alert.Field03 + ": " + alert.Field01.trim() + " (" + alert.Field02 + ")");
                        if (this.toShow[ShowTypes.DOCUMENTOS_MODIFICADOS] == undefined) this.toShow[ShowTypes.DOCUMENTOS_MODIFICADOS] = [];
                        this.toShow[ShowTypes.DOCUMENTOS_MODIFICADOS].push({
                            display: alert.Field03 + ": " + (alert.Field01 ? alert.Field01.trim() : '') + " (" + alert.Field02 + ")",
                            action: "/global-eye/patients/edit/" + alert.Field04,
                            color: this.colorFromWarningLevel(warningLevel)
                        });
                        break;
                    // case 4: // Ordenes de pañales sin procesar
                    //     break;
                    case 5: // Prescripciones con medicamentos faltantes
                        if (this.toShow[ShowTypes.PRESCRIPCIONES_MED_FALTANTES] == undefined) this.toShow[ShowTypes.PRESCRIPCIONES_MED_FALTANTES] = [];
                        this.toShow[ShowTypes.PRESCRIPCIONES_MED_FALTANTES].push({
                            action: "/global-eye/reports/mednostock",
                            color: this.colorFromWarningLevel(warningLevel)
                        });
                        break;
                }
            }
        });

        this.message.next(this.toShow);
    }

    processMessage(msg: any) {
        //  No hace nada actualmente con este tipo de mensaje en Corota ¿?
    }

    expireShown = false;
    showNextToExpire() {
        if (this.expireShown) return;

        this.expireShown = true;
        this.dialog.open(SessionExpireComponent).afterClosed().subscribe((result: boolean) => {
            if (result) {
                // Continuar
                this.pingServer();
            }
            else {
                // Salir
                this.auth.logOut();
            }

            this.expireShown = false;
        });
    }
    //#endregion

    //#region Comandos que se envian al server
    requestTimer: any;
    setRequestTimer() {
        if (this.requestTimer != undefined) {
            clearTimeout(this.requestTimer);
        }

        this.requestTimer = setTimeout(() => {
            this.requestTimer = undefined;
            this.requestPendingNotifications();
        }, 900000); // 15 minutos
    }

    requestPendingNotifications() {
        var notifyEvent = {
            timestamp: new Date(),
            socketKey: this.socketKey,
            notifyType: NotificationType.RequestPendingNotifications,
            notifyData: null
        };

        if (this.isSocketOpen()) {
            this.socket?.send(JSON.stringify(notifyEvent));
        }
    }

    pingServer() {
        var notifyEvent = {
            timestamp: new Date(),
            socketKey: this.socketKey,
            notifyType: NotificationType.ClientPing,
            notifyData: null
        };

        if (this.isSocketOpen()) {
            this.socket?.send(JSON.stringify(notifyEvent));
        }
    }
    //#endregion

    isSocketOpen() {
        return this.socket != undefined && this.socket?.readyState == WebSocket.OPEN;
    }

    colorFromWarningLevel(level: number) {
        switch (level) {
            case 0:
                return 'success';
            case 1:
                return 'info';
            case 2:
                return 'warning';
            case 3:
                return 'error'
            default:
                return '';
        }
    }
}

export class ShowTypes {
    public static INCIDENCIAS_RE = "Incidencias en RE";
    public static INCIDENCIAS_PRM = "Incidencias de PRM";
    public static INCIDENCIAS_EXITUS = "EXITUS sin procesar";
    public static DOCUMENTOS_MODIFICADOS = "Documentos modificados";
    public static PRESCRIPCIONES_MED_FALTANTES = "Prescripciones sin suministro";
}
