import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeModal } from '@interfaces/modal';

@Component({
    selector: 'app-send-order',
    templateUrl: './send-order.component.html',
    styleUrls: ['./send-order.component.scss'],
})
export class SendOrderComponent {
    public title: string = '';
    public message: string = '¿Estás seguro?';
    public confirmButtonText = 'Si';
    public cancelButtonText = 'No';
    public type: TypeModal = 'info';
    public colorButtonOk: string = 'primary';
    public form: FormGroup;
    public id: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SendOrderComponent>,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            useDate: [false],
        });
        if (data) {
            this.title = data.title || this.title;
            this.message = data.message || this.message;
            this.type = data.type || this.type;
            this.id = data.id;
            this.colorButtonOk = data.type == 'error' ? 'warn' : this.colorButtonOk;
            if (data.buttonText) {
                this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
        }
    }

    onConfirmClick(): void {
        const { useDate } = this.form.getRawValue();
        this.dialogRef.close({ confirm: true, data: useDate, id: this.id });
    }
}
