<div class="table" 
  *ngIf="patient.DebtDetail">

  <div class="item" *ngFor="let debt of patient.DebtDetail">
    <div class="field code">{{ debt.Codigo }}</div>
    <div class="field description">{{ debt.Descripcion }}</div>
    <div class="field quantity">{{ debt.Cantidad }}</div>
  </div>
</div>

<mat-spinner *ngIf="!patient.DebtDetail" [diameter]="20"></mat-spinner>
