<ng-container class="report-custom-container">
    <app-report-select [title]="'Plan farmacológico'" (previewClicked)="handlePreviewClick()"
        [disabled]="(showPatientSelect && ['', null, undefined].includes(form.controls['selectedPatient'].value)) || (showDepartmentSelect && ['', null, undefined].includes(form.controls['selectedDepartment'].value))"
        (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()" (excelClicked)="handleExcelClick()">
        <div slot="content" class="container-options">
            <form [formGroup]="form">

                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                        class="field"
                        [type]="'select'"
                        label="Selecciona un centro"
                        [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                        [options]="selectOptionsInsitutions">
                    </app-form-field>
        
                </section>
                  
                <section class="filter-container">
                    <p class="subtitle">Día de generación del plan</p>

                    <mat-form-field>
                        <div class="label">Generación</div>
                        <input matInput [matDatepicker]="generationDay" formControlName="generationDay" />
                        <mat-datepicker-toggle matSuffix [for]="generationDay">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #generationDay></mat-datepicker>
                    </mat-form-field>
                </section>
                <section class="filter-container">
                    <p class="subtitle">Filtrar por</p>
                    <mat-checkbox 
                        class="marginBottom"
                        formControlName="checkboxControlDepartment" 
                        (change)="toggleSelectDepartment()">
                        Departamento
                    </mat-checkbox>
                    <mat-form-field *ngIf="showDepartmentSelect" class="marginBottom">
                        <div class="label">Selecciona una opción</div>
                        <mat-select formControlName="selectedDepartment">
                            <mat-option *ngFor="let option of selectOptionsDepartment" [value]="option.id">
                                {{ option.nam }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                   
                    <mat-checkbox 
                        class="marginBottom"
                        formControlName="checkboxControlPatient" 
                        (change)="toggleSelectPatient()">
                        Paciente
                    </mat-checkbox>
                    <mat-checkbox *ngIf="showPatientSelect"
                        class="marginBottom" 
                        formControlName="checkboxControlPatientExitus"
                        (change)="toggleSelectPatientExitus()">
                        Exitus/Bajas
                    </mat-checkbox>
                    <app-form-field 
                        *ngIf="showPatientSelect" 
                        class="marginBottom"
                        label="Selecciona una opción" 
                        type="autocomplete" 
                        [options]="selectOptionsPatientsAutocomplete" 
                        [fieldControl]="form.controls['selectedPatient'] | asFormControl">
                    </app-form-field>
                </section>
            </form>
        </div>
    </app-report-select>
</ng-container>
