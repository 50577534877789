import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { REPatientAPP } from '@interfaces/re-status.interface';

@Component({
  selector: 'app-farmatic-number',
  templateUrl: './farmatic-number.component.html',
  styleUrls: ['./farmatic-number.component.scss']
})
export class FarmaticNumberComponent {

  public farmaticNumber: number;
  public patient: REPatientAPP;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FarmaticNumberComponent>
  ) {
    if (data) {
      this.patient = data.patient;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(this.farmaticNumber);
  }
}
