import { inject } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@services/auth.service"
import { WebSocketsService } from "@services/web-sockets.service";
import { Permission, PermissionType } from "../enums/PermissionType";
import { Utils } from "../Utils";
import { CallsService } from "@services/api/calls.service";
import { PatientAPI } from "@interfaces/patient/patient.interface";

export const authGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const webSocket = inject(WebSocketsService);
    const router = inject(Router);
    const calls = inject(CallsService);

    const result = (await authGuardToken(route, state) as boolean);

    const user = localStorage.getItem('current_user');
    const token = localStorage.getItem('access_token');

    if (
        !token || token === 'undefined'
        || !user || user === 'undefined'
        || !result
    ) {
        localStorage.clear();
        webSocket.disconnect();
        return router.navigate(['']);
    } else {
        // No conectamos a socket si estamos en /select-institution
        if (route.url.find(u => u.path == 'select-institution') == null) webSocket.connect();
        if (route.queryParamMap.has('token')) {
            const urlTree = router.parseUrl(state.url);
            const { token, data, ...rest } = urlTree.queryParams;
            urlTree.queryParams = rest;
            if (data) {
                const dataParsed = JSON.parse(decodeURI(data));
                return new Promise<UrlTree>((resolve) => {
                    calls.getPatientsListAPI(dataParsed.inst).subscribe({
                        next: (data) => {
                            const patients = data.payload as PatientAPI[];
                            const patient = patients.find(p => p.num == dataParsed.pn);

                            if (patient) {
                                router.navigate(['global-eye', 'patients', 'edit', patient?.id], {
                                    queryParams: {
                                        tab: 5
                                    },
                                    replaceUrl: true
                                });
                            }
                            else {
                                resolve(urlTree);
                            }
                        },
                        error: (err) => {
                            resolve(urlTree);
                        }
                    });
                });
            }
            else {
                return urlTree;
            }
        }
        return true;
    }
}

export const authGuardRol = (perm: Permission, permType?: PermissionType): CanActivateFn => {
    return async (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) => {
        const authService = inject(AuthService);
        const router = inject(Router);

        const token = localStorage.getItem('access_token');
        if (token == undefined) router.navigate(['/global-eye/'])

        if (token != undefined && AuthService.userRoles == undefined) {
            if (authService.corotaRoles.length == 0 || authService.corotaPerms == undefined) {
                await authService.preLogIn();
            }
            authService.extractRoles(token);
        }

        const perms = Utils.hasPermission(perm)();
        if (perms.length == 0 || (permType != undefined && !perms.includes(permType))) {
            return router.navigate(['/global-eye/']);
        }

        return true;
    }
}

function authGuardToken(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean | Promise<boolean> {
    const authService = inject(AuthService);
    const router = inject(Router);

    const token = route.queryParamMap.get('token');
    if (token != undefined) {
        return new Promise<boolean>((resolve) => {
            authService.loginFromToken(token, (res) => {
                resolve(res);
            });
        });
    }
    else {
        return true;
    }
}
