<ng-container *ngFor="let section of schema; let i = index;">
  <div class="section" 
    *ngIf="section.visible" 
    [class]="section.size || ''"
    [ngClass]="section.customClass"
    [ngStyle]="section.customStyle">
    <!-- Title -->
    <div class="title">{{section.label}}</div>

    <!-- Content -->
    <div class="content">
      <ng-container *ngFor="let child of section.childs">
        <ng-container [ngSwitch]="child.type">
  
          <!-- Default form field -->
          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="child.visible">
              <app-form-field 
                class="field"
                [class]="child.size || ''"
                [ngClass]="child.customClass"
                [ngStyle]="child.customStyle"
                [type]="child.type" 
                [label]="child.label" 
                [hideLabel]="child.hideLabel"
                [hintMsg]="child.hintMsg" 
                [placeholder]="child.placeholder"
                [fieldControl]="child.fieldControl | asFormControl" 
                [customFieldData]="child.customFieldData"
                [options]="child.options" 
                [readOnly]="child.readOnly" 
                [min]="child.min" 
                [max]="child.max"
                [inputPattern]="child.inputPattern" 
                [capitalize]="child.capitalize"
                [uppercase]="child.uppercase"
                [errorMsg]="child.errorMsg ?? ''" 
                [group]="child.group" 
                [customStyle]="child.customStyle">
              </app-form-field>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>