<table mat-table [dataSource]="rows" class="table-form-component">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef class="text-center">
            {{column.header}}
        </th>
        <td [style.color]="isInvalid(row) == 'uninitialized' ? '' : (isInvalid(row) == 'invalid' ? '#f00' : 'green')" mat-cell *matCellDef="let row" class="text-center dense-2"
            [ngClass]="{ 'text-center': column.header }">
            <ng-container *ngIf="column.isInput; else elseTemplate">
                <app-form-field #formField [type]="row.schema[column.columnDef].type" [label]="row.schema[column.columnDef].label"
                    [hideLabel]="row.schema[column.columnDef].hideLabel"
                    [hintMsg]="row.schema[column.columnDef].hintMsg"
                    [placeholder]="row.schema[column.columnDef].placeholder"
                    [fieldControl]="$any(fieldControl.controls[row.formName])?.controls['value'] | asFormControl"
                    [customFieldData]="row.schema[column.columnDef].customFieldData"
                    [options]="row.schema[column.columnDef].options"
                    [dynamicError]="row.schema[column.columnDef].dynamicError"
                    [inputPattern]="row.schema[column.columnDef].inputPattern"
                    [capitalize]="row.schema[column.columnDef].capitalize"
                    [uppercase]="row.schema[column.columnDef].uppercase"
                    [errorMsg]="row.schema[column.columnDef].errorMsg"
                    [group]="row.schema[column.columnDef].group"
                    [row]="row" (invalid)="setInvalid($event, formField, row)"
                    [customStyle]="row.schema.customStyle"
                    [readOnly]="readOnly"></app-form-field>
            </ng-container>
            <ng-template #elseTemplate>
                <div #tooltip class="tooltip"><span>{{column.cell(row)}}</span></div>
                <div (mouseenter)="column.columnDef === 'label' ? tooltip.style.display = 'flex' : null" (mouseleave)="tooltip.style.display = 'none'">
                    {{column.cell(row)}}
                </div>
            </ng-template>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
</table>
