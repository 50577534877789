import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataPatientMedicineUse } from '@constants/reports/report-p-medicine-use';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-p-medicine-use',
    templateUrl: './report-p-medicine-use.component.html',
    styleUrls: ['./report-p-medicine-use.component.scss'],
})
export class ReportPMedicineUseComponent implements OnInit{
    public reportDataPatientMedicineUse = reportDataPatientMedicineUse;
    public currentRoute: string;
    public dateForm: FormGroup;
    public report: Report;
    public pathReportDownload: string;

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    multipleInstitutionSelect: boolean = false;

    resetInstitutionsDropdown: boolean = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private cdRef: ChangeDetectorRef,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.dateForm = this.formBuilder.group({
            reportType: ['byPatients'],
            medicineType: ['machine'],
            startDate: [moment(), Validators.required],
            endDate: [moment().add(7, 'days'), Validators.required],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Consumo por paciente`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Consumo por paciente`);

        this.typeGlobal = this.roleManager.isPharma();
        
        this.getDataForTheSelects();

        this.dateForm.valueChanges.subscribe((data) => {
            this.institutionService.selectInstitutionById(this.dateForm.controls['selectedInstitution'].value.id);

            let institutionModeChanged: boolean = false;

            if ( data.reportType === 'byMedicines' && !this.multipleInstitutionSelect ) {
                this.multipleInstitutionSelect = true;
                institutionModeChanged = true;
            } else if ( data.reportType === 'byPatients' && this.multipleInstitutionSelect ) {
                this.multipleInstitutionSelect = false;
                institutionModeChanged = true;
            }

            if ( institutionModeChanged ) {
                this.dateForm.get('selectedInstitution')?.setValue('');
                this.resetInstitutionsDropdown = true;
                this.cdRef.detectChanges();
                this.resetInstitutionsDropdown = false;
                this.cdRef.detectChanges();
            }
        })
    }

    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
          this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

          if (this.selectOptionsInsitutions) {
            const hasEmptyValue = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
            );
            if (hasEmptyValue) {
              this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                (item) => item.value !== ''
              );
            }
          }

            this.dateForm.controls['selectedInstitution'].setValue(
                this.selectOptionsInsitutions.find((item) => item.value.id === this.institutionService.getCurrentInstitution())?.value
            )
        });
    }

 

    getFormData(): { startDate: string; endDate: string; selectedInstitution: string; reportType: string; medicineType: string} {
        const startDate = this.dateService.formatDateToString(
            moment(this.dateForm.get('startDate')?.value).startOf('day').toDate()
        );
        const endDate = this.dateService.formatDateToString(
            moment(this.dateForm.get('endDate')?.value).endOf('day').toDate()
        );

        const {
            selectedInstitution,
          } = this.dateForm.getRawValue();

        const reportType = this.dateForm.get('reportType')?.value;
        const medicineType = this.dateForm.get('medicineType')?.value;

        return { startDate, endDate ,  selectedInstitution, reportType, medicineType};
    }

    handlePreviewClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate,  selectedInstitution, reportType, medicineType } = this.getFormData();
            this.setInfoRP(startDate, endDate , selectedInstitution, reportType, medicineType,  true);
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPatientMedicineUse
            );
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handlePrintClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, reportType, medicineType } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, reportType, medicineType);
            this.reportService.handlePrintClick(this.reportDataPatientMedicineUse);
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handlePdfClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, reportType, medicineType } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, reportType, medicineType);
            this.reportService.handlePdfClick(this.reportDataPatientMedicineUse);
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handleExcelClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution, reportType, medicineType } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, reportType, medicineType);
            this.reportService.handleExcelClick(this.reportDataPatientMedicineUse);
        } else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    setInfoRP(startDate: string, endDate: string,   selectedInstitution: any, reportType: string, medicineType: string, isPreview: boolean = false): void {

        let  currentInstitution;
        let filterFlag: string = '1';

        if ( this.typeGlobal ) {    // Is Pharma
            const dataForm = this.dateForm.getRawValue()

            if ( dataForm.reportType === 'byPatients' ) {
                this.reportDataPatientMedicineUse.data.rpp = '/COROTA/PatientMedicineUse';
                currentInstitution = dataForm.selectedInstitution.id.toString();
            } else {
                this.reportDataPatientMedicineUse.data.rpp = '/COROTA/PatientMedicineUseByMed';
                currentInstitution = dataForm.selectedInstitution.map((item: any) => item.id).join();
            }

            switch ( dataForm.medicineType ) {
                case 'machine': filterFlag = '5'; break;
                case 'tray': filterFlag = '9'; break;
                case 'machineAndTry': filterFlag = '1'; break;
            }
            
        } else {    // Is Quantum
            this.reportDataPatientMedicineUse.data.rpp = '/COROTA/PatientMedicineUse';
            filterFlag = '1';
            currentInstitution = this.institutionService.getCurrentInstitution();
        }

        const rp = {
            LocaleId: '3082',
            InstitutionList: currentInstitution,
            DateFrom: startDate,
            DateUntil: endDate,
            FilterFlag: filterFlag,
        };
        const stringRP = JSON.stringify(rp);
        this.reportDataPatientMedicineUse.data.rp = stringRP;
        if (isPreview) this.reportDataPatientMedicineUse.data.dn = Utils.buildUniqueFileName + '_{0}.html';
        this.reportDataPatientMedicineUse.dataDownloadPDF.rp = stringRP;
        this.reportDataPatientMedicineUse.dataDownloadExcel.rp = stringRP;
        this.reportDataPatientMedicineUse.dataRenderReport.rp = stringRP;

        const now = moment();
        this.reportDataPatientMedicineUse.dataDownloadPDF.dn = this.reportDataPatientMedicineUse.dataRenderReport.dn =
            `Consumo_de_medicamentos_paciente_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataPatientMedicineUse.dataDownloadExcel.dn = `Consumo_de_medicamentos_paciente_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    
}
