<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de polimedicación'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="filter-container" *ngIf="typeGlobal" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section>
        <section class="interval">
          <p class="subtitle">Intervalo</p>
          <div class="container-fields">
            <mat-radio-group formControlName="intervalType" class="col-12">
              <mat-radio-button class="col-6"
                value="specificDay"
                (change)="toggleSelectSpecificDay()"
              >
                Día específico
              </mat-radio-button>

              <mat-radio-button class="col-6"
                value="intervalDay"
                (change)="toggleSelectIntervalDay()"
              >
                Intervalo mensual
              </mat-radio-button>
            </mat-radio-group>

            <mat-form-field class="col-6"
              *ngIf="form.controls['intervalType'].value === 'specificDay'"
            >
              <div class="label"> Día específico</div>
              <input
                matInput
                [matDatepicker]="specificDay"
                formControlName="specificDay"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="specificDay"
              >  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
              <mat-datepicker #specificDay></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-6"
              *ngIf="form.controls['intervalType'].value === 'intervalDay'"
              appDateFormat
            >
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
                [max]="fromDateMax"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              >
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker
                startView="year"
                #startDatePicker
                (monthSelected)="
                  setMonthAndYear($event, startDatePicker, 'startDate')
                "
              ></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="col-6"
              *ngIf="form.controls['intervalType'].value === 'intervalDay'"
              appDateFormat
            >
              <div class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
                [min]="toDateMin"
              [max]="toDateMax"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              >
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker
                startView="year"
                #endDatePicker
                (monthSelected)="
                  setMonthAndYear($event, endDatePicker, 'endDate')
                "
              ></mat-datepicker>
            </mat-form-field>
          </div>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
