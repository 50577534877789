<div class="operation-bar"
  [ngStyle]="{'display': !operationBarVisible() ? 'none' : 'block'}"
>
  <app-operation-bar 
      [arrowNavitation]="onEditPage()" 
      [listMode]="true" 
      [filter]="false" 
      [search]="true" 
      [canDelete]="onEditPage() && Utils.hasPermission(Permission.USERS)().includes(PermissionType.WRITE)"
      [canCreate]="Utils.hasPermission(Permission.USERS)().includes(PermissionType.WRITE)"
      [reportActions]="false" 
      [dropdownFilter]="true"
      [dropdownOptions]="userFilters"
      [showResults]="onEditPage() && resultsRefreshed"
      [filterResults]="filteredResults"
      [totalElements]="totalElements"
      [currentElementIndex]="currentElementIndex"
      (onAction)="onOperationBarAction($event)"
      (onFilter)="filter($event)"
      (onFilterResultSelected)="onFilterResultSelected($event)">
  </app-operation-bar>
</div>

<section class="content"
  [ngClass]="{'without-operation-bar': !operationBarVisible()}">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</section>