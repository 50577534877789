<div class="container-fluid" >
    <div class="page-title">
        Ventas Farmatic
    </div>

    <div class="body-fluid">
        <mat-tab-group (selectedTabChange)="typeChanged($event)" class="col-12">
            <mat-tab label="Pago">&nbsp;</mat-tab>
            <mat-tab label="Deuda">&nbsp;</mat-tab>
        </mat-tab-group>

        <div class="container-tabs">
            <div class="opciones col-12">
                <mat-form-field appearance="fill" class="col-3">
                    <div class="label">Centro</div>
                    <mat-select 
                      [(value)]="selectedInstitution"
                      (selectionChange)="onInstitutionChange($event)"
                    >
                      <mat-option *ngFor="let option of institutionOptions" [value]="option.value">
                        {{ option.label }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" class="col-3">
                    <div class="label">Buscardor</div>
                    <input matInput placeholder="Buscar" [(ngModel)]="search">
                </mat-form-field>
        
                <mat-form-field subscriptSizing="dynamic" class="col-3">
                    <div class="label">Month and Year</div>
                    <input matInput [matDatepicker]="dp" [formControl]="date">
                    <mat-datepicker-toggle matIconSuffix [for]="dp">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp startView="year" (monthSelected)="setMonthAndYear($event, dp)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>
                
               <div class="col-3">
                <button mat-raised-button color="primary"  *ngIf="toExport.length > 0"  class="buttonExport col-12" (click)="exportar()"> <mat-icon>insert_drive_file</mat-icon> Exportar</button>
               </div> 
            </div>
        
            <h4>Por exportar</h4>
            <div *ngIf="ventas != undefined && ventas.ventasNotDone.length > 0" class="por-exportar  col-12">
                <ng-container *ngFor="let notDone of ventas.ventasNotDone">
                    <div *ngIf="compareTextWithSearch(notDone.ShortName, $any(notDone.Id))" class="base-export">
                        <mat-checkbox (change)="checkChange($event, notDone)">{{notDone.ShortName}}</mat-checkbox>
                    </div>
                </ng-container>
            </div>
        
            <h4>Exportados</h4>
            <div *ngIf="ventas != undefined && ventas.ventasDone.length > 0" class="exportados">
                <ng-container *ngFor="let done of ventas.ventasDone">
                    <div *ngIf="compareTextWithSearch(done.ShortName, $any(done.Id))" class="base-export">
                        {{done.ShortName}}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>