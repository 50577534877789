import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT_DICTIONARY } from '@constants/document';
import { Document } from '@interfaces/file/document.interface';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() data: Document;
  @Input() canDelete: boolean = true;
  @Output() handleDownloadEvent = new EventEmitter<Document>();
  @Output() handleDeleteEvent = new EventEmitter<Document>();

  public img: string;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getImageFromAssets();

    this.changeTitleLength();
  }

  changeTitleLength(): void {
    const titleShortened =
      this.title.slice(0, 20) +
      '...' +
      this.title.substring(this.title.indexOf('.') + 1);

    this.title = this.title.length > 20 ? titleShortened : this.title;
  }

  getImageFromAssets(): void {
    let value = this.getImageSRC();

    this.http
      .get(`assets/pics/${value}.svg`, {
        responseType: 'blob',
      })
      .subscribe({
        next: (blob) => {
          const imageUrl = URL.createObjectURL(blob);
          this.img = imageUrl;
        },

        error: (err) => {
          // No es necesario mostrar al usuario este error,
          // simplemente saldrá el alt
          console.error('Error:', err);
        },
      });
  }

  getImageSRC(): string {
    const extensionDocument = this.title.substring(this.title.indexOf('.') + 1);
    const possibilitiesArray = DOCUMENT_DICTIONARY.map((value) =>
      value.possibilities.includes(extensionDocument)
    );

    const index = possibilitiesArray.indexOf(true);

    const value = index >= 0 ? DOCUMENT_DICTIONARY[index].value : 'unknown';

    return value;
  }

  triggerDownloadEvent(): void {
    this.handleDownloadEvent.emit(this.data);
  }
  triggerDeleteEvent(): void {
    this.handleDeleteEvent.emit(this.data);
  }
}
