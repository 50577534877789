import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
    reportDataMedicamentNoEmblis,
    reportDataMedicamentNoEmblisByPat,
} from '@constants/reports/report-medicament-no-emblis';
import { schedulesHalfHourOptions } from '@constants/schedules';
import { FormOption } from '@interfaces/dynamic-form.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    Medicine,
    MedicineAdminRoute,
} from '@interfaces/medicine/medicine.interface';
import {
    DepartmentPharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { MedicinesService } from '@services/medicines.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-report-medicament-no-emblis',
    templateUrl: './report-medicament-no-emblis.component.html',
    styleUrls: ['./report-medicament-no-emblis.component.scss'],
})
export class ReportMedicamentNoEmblisComponent {
    public reportDataMedicamentNoEmblis = reportDataMedicamentNoEmblis;
    public showPatientSelect: boolean = false;
    public exitusSelect: boolean = false;
    public currentInstitution: number;
    public subs: Subscription[] = [];
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public selectOptionsPatientsWithoutFilter: PatientPharma[];
    public selectOptionsPatients: PatientPharma[];
    public selectOptionsDepartment: PatientPharma[];

    public medicineAdminRouteList: MedicineAdminRoute[];
    public medicineAdminRouteListOptions: SelectOption[];
    public medicineSelect: Medicine[] = [];
    public medicineSelectOptions: SelectOption[] = [];
    public departmentSelect: DepartmentPharma[];
    public departmentSelectOptions: SelectOption[];
    public scheduleSelect: FormOption[] = [...schedulesHalfHourOptions];

    public toDateMin: Date;
    public fromDateMax: Date;
    public fromDateMin: Date;


    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
    

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private medicineService: MedicinesService,
        private roleManager: RoleManagerService, 
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        var date = new Date();

        this.scheduleSelect.push({ value: 'S/P', label: 'S/P' });

        this.form = this.formBuilder.group({
            startDate: [new Date(), Validators.required],
            endDate: [new Date(), Validators.required],
            selectedNE: [true],
            selectedSP: [true],
            selectedDiaper: [true],
            selectedHealMaterial: [true],
            selectedMedicationSchedule: [''],
            selectedAdminRoute: [''],
            selectedMedicines: [''],
            selectedDepartments: [''],
            selectedPatient: [''],
            checkboxControlDepartment: [''],
            checkboxControlMedicationSchedule: [''],
            checkboxControlAdminRoute: [''],
            checkboxControlMedicament: [''],
            reportType: ['consolidated'],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: No emblistados - S/P`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: No emblistados - S/P`);

        this.typeGlobal = this.roleManager.isPharma();
        
        
        this.getDataForTheSelects();
        // this.updateDateLimits();

        let change = false;
        this.form.get('startDate')?.valueChanges.subscribe((value: Moment) => {
            if (change) {
                change = false;
                return;
            }
            change = true;
            // this.form.controls['endDate'].setValue(value.clone().add(7, 'days'));
            // this.updateDateLimits();
        });

        this.form.get('endDate')?.valueChanges.subscribe((value: Moment) => {
            if (change) {
                change = false;
                return;
            }
            change = true;
            // this.form.controls['startDate'].setValue(value.clone().add(-7, 'days'));
            // this.updateDateLimits();
        });
    }

    // updateDateLimits(): void {
    //     const endDateValue = new Date(this.form.controls['endDate'].value);
    //     const startDateValue = new Date(this.form.controls['startDate'].value);

    //     const fromDateMax = new Date(
    //         endDateValue.getFullYear(),
    //         endDateValue.getMonth(),
    //         endDateValue.getDate() - 7
    //     );

    //     const toDateMin = new Date(
    //         startDateValue.getFullYear(),
    //         startDateValue.getMonth(),
    //         startDateValue.getDate() + 7
    //     );

    //     this.fromDateMax = fromDateMax;
    //     this.toDateMin = toDateMin;
    //     this.fromDateMin = fromDateMax;
    // }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleCheckbox(controlName: string): void {
        const newValue = !this.form.get(controlName)?.value;
        this.form.get(controlName)?.patchValue(newValue);
    }

    getDataForTheSelects(): void {
        this.getMedicineAdministrationOptions();
        this.getMedicineList();
        this.getDepartments();
        this.getInstitution();

    }


    getInstitution(): void {
        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
        });
    }
    getMedicineList(): void {
        this.subs.push(
            this.medicineService.medicineListSelect.subscribe((medicines) => {
                this.medicineSelect = medicines.map((p) =>
                    this.medicineService.transformMedicineAPItoApp(p)
                );

                this.medicineSelectOptions = this.medicineSelect.map((item: Medicine) => {
                    return <SelectOption>{ label: item.name, value: item.code };
                })
            })
        );

        this.medicineService.getMedicinesList(this.currentInstitution);
    }

    getDepartments(): void {
        this.reportService
            .getRepParamsEditDataNeList(this.currentInstitution)
            .subscribe((result) => {
                this.departmentSelect = result.payload.deps.map((d: any) => {
                    return {
                        id: d.Id,
                        nam: d.Name
                    }
                });

                this.departmentSelectOptions = this.departmentSelect.map((item: DepartmentPharma) => {
                    return <SelectOption>{ label: item.nam, value: item.id };
                })
            });
    }
    getMedicineAdministrationOptions(): void {
        this.subs.push(
            this.medicineService.medicineAdminRouteList.subscribe((adminRoutes) => {
                this.medicineAdminRouteList = adminRoutes.map((adminRoute) =>
                    this.medicineService.transformMedicineAdminRouteAPItoApp(adminRoute)
                );

                this.medicineAdminRouteListOptions = this.medicineAdminRouteList.map((item: MedicineAdminRoute) => {
                    return <SelectOption>{ label: item.name, value: item.id };
                })
            })
        );
        this.medicineService.getMedicineAdminRoute(
            true,
            true,
            this.institutionService.getCurrentInstitution()
        );
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const {
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial,
            } = this.getFormData();

            this.setInfoRP(
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial,
                true
            );

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataMedicamentNoEmblis
            );
        } else {

            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }

        }
    }

    getFormData(): {
        selectedNE: string;
        startDate: string;
        endDate: string;
        selectedPatient: string;
        selectedSP: string;
        selectedDiaper: string;
        selectedMedicationSchedule: string;
        selectedAdminRoute: string;
        selectedMedicines: string;
        selectedDepartments: string;
        selectedHealMaterial: string;
    } {
        const selectedNE = this.form.get('selectedNE')?.value;
        const selectedSP = this.form.get('selectedSP')?.value;
        const selectedDiaper = this.form.get('selectedDiaper')?.value;
        const selectedMedicationSchedule = this.form.get('checkboxControlMedicationSchedule')?.value ? this.form.get('selectedMedicationSchedule')?.value : [];
        const selectedAdminRoute = this.form.get('checkboxControlAdminRoute')?.value ? this.form.get('selectedAdminRoute')?.value : [];
        const selectedMedicines = this.form.get('checkboxControlMedicament')?.value ? this.form.get('selectedMedicines')?.value : [];
        const selectedDepartments = this.form.get('checkboxControlDepartment')?.value ? this.form.get('selectedDepartments')?.value : [];
        
        const startDate = this.dateService.formatDateToString(
            this.form.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.form.get('endDate')?.value
        );
        const selectedPatient = this.form.get('selectedPatient')?.value;

        const selectedHealMaterial = this.form.get('selectedHealMaterial')?.value;

        return {
            selectedNE,
            startDate,
            endDate,
            selectedPatient,
            selectedSP,
            selectedDiaper,
            selectedMedicationSchedule,
            selectedAdminRoute,
            selectedMedicines,
            selectedDepartments,
            selectedHealMaterial,
        };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const {
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial,
            } = this.getFormData();

            this.setInfoRP(
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial
            );
            this.reportService.handlePrintClick(this.reportDataMedicamentNoEmblis);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const {
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial,
            } = this.getFormData();
            this.setInfoRP(
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial
            );
            this.reportService.handlePdfClick(this.reportDataMedicamentNoEmblis);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const {
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial,
            } = this.getFormData();

            this.setInfoRP(
                selectedNE,
                selectedSP,
                selectedDiaper,
                selectedMedicationSchedule,
                selectedAdminRoute,
                selectedMedicines,
                selectedDepartments,
                startDate,
                endDate,
                selectedHealMaterial
            );
            this.reportService.handleExcelClick(this.reportDataMedicamentNoEmblis);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(
        selectedNE: string,
        selectedSP: string,
        selectedDiaper: string,
        selectedMedicationSchedule: string,
        selectedAdminRoute: string,
        selectedMedicines: string,
        selectedDepartments: string,
        startDate: string,
        endDate: string,
        selectedHealMaterial: string,
        isPreview: boolean = false
    ): void {
        if (this.form.controls['reportType'].value == 'consolidated') {
            this.reportDataMedicamentNoEmblis = reportDataMedicamentNoEmblis;
        } else {
            this.reportDataMedicamentNoEmblis = reportDataMedicamentNoEmblisByPat;
        }

        let filterTag = this.reportService.getFilter(
            selectedNE,
            selectedSP,
            selectedDiaper,
            selectedHealMaterial
        );
    

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
            //@ts-ignore
            currentInstitution = dataForm.selectedInstitution.id

        const rp = {
            LocaleId: '3082',
            InstitutionId: String(currentInstitution),
            DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split(".")[0],
            DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDate).startOf('day').toDate())?.toISOString().split(".")[0],
            FilterFlag: filterTag.toString(),
            Departments:
                selectedDepartments.length === 0
                    ? null
                    : selectedDepartments.toString(),
            Intakes:
                selectedMedicationSchedule.length === 0
                    ? null
                    : selectedMedicationSchedule.toString(),
            AdminRoutesIds:
                selectedAdminRoute.length === 0 ? null : selectedAdminRoute.toString(),
            MedicinesIds:
                selectedMedicines.length === 0 ? null : selectedMedicines.toString(),
        };

        this.reportDataMedicamentNoEmblis.data.rp = JSON.stringify(rp);
        if (isPreview) this.reportDataMedicamentNoEmblis.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataMedicamentNoEmblis.dataDownloadPDF.rp = JSON.stringify(rp);
        this.reportDataMedicamentNoEmblis.dataDownloadExcel.rp = JSON.stringify(rp);
        this.reportDataMedicamentNoEmblis.dataRenderReport.rp = JSON.stringify(rp);

        const now = moment();
        this.reportDataMedicamentNoEmblis.dataDownloadPDF.dn = this.reportDataMedicamentNoEmblis.dataRenderReport.dn =
            `Reporte_de_no_emblistados_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataMedicamentNoEmblis.dataDownloadExcel.dn = `Reporte_de_no_emblistados_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    noFilterSelected(): boolean {
        return !this.form.get('selectedNE')?.value && 
               !this.form.get('selectedSP')?.value && 
               !this.form.get('selectedDiaper')?.value && 
               !this.form.get('selectedHealMaterial')?.value;
    }
}
