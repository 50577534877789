import { ValidationErrorTexts } from "@interfaces/validationErrorTexts.interface";

export const validationErrors: { [key: string]: ValidationErrorTexts } = {
    min: {
        text: 'El valor mínimo requerido es: {min}.',
        errorValueKeys: ['min', 'actual']
      },
      max: {
          text: 'El valor máximo requerido es {max}.',
          errorValueKeys: ['max', 'actual']
      },
      required: {
          text: 'Este campo es requerido.',
          errorValueKeys: []
      },
      email: {
          text: 'Email incorrecto.',
          errorValueKeys: []
      },
      minlength: {
          text: 'La longitud máxima válida es {requiredLength}.',
          errorValueKeys: ['requiredLength', 'actualLength']
      },
      maxlength: {
          text: 'La longitud mínima válida es {requiredLength}.',
          errorValueKeys: ['requiredLength', 'actualLength']
      },
      pattern: {
          text: 'Valor incorrecto. El valor debe seguir el patrón {requiredPattern}.',
          errorValueKeys: ['requiredPattern', 'actualValue']
      },
}