import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { matchFieldValueValidator } from '@validators/custom-validators.validators';

@Component({
    selector: 'app-temp-password',
    templateUrl: './temp-password.component.html',
    styleUrls: ['./temp-password.component.scss']
})
export class TempPasswordComponent {
    public form: FormGroup;

    passwordChange: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<TempPasswordComponent>,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            activation: new FormControl('', [Validators.required]),
            newPwd: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$')]),
            repeat: new FormControl('', [Validators.required, matchFieldValueValidator('newPwd'), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$')])
        });

        this.passwordChange = !!data.componentData?.passwordChange;
    }

    onConfirmClick(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;
        const data = this.form.getRawValue();
        this.dialogRef.close({ confirm: true, data: {activation: data.activation, newPwd: data.newPwd} });
    }
}
