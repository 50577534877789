import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getReportReasonDescription } from '@constants/funtions-utils';
import { DailyChange, PatientREHistoryAPI, PrescriptionResponseREAPI, PrescriptionTag, ReviewWeekAPI } from '@interfaces/patient/patient.interface';
import { DayChanges, REPatientAPP } from '@interfaces/re-status.interface';
import { CallsService } from '@services/api/calls.service';
import { ModalService } from '@services/modal.service';
import { AlertComponent } from '@shared/dialogs/alert/alert.component';
import { EditReComponent } from '@shared/dialogs/edit-re/edit-re.component';
import { NoteComponent } from '@shared/dialogs/note/note.component';

@Component({
  selector: 'app-re-patient',
  templateUrl: './re-patient.component.html',
  styleUrls: ['./re-patient.component.scss']
})
export class RePatientComponent implements OnInit{

  @ViewChild('debtDetail') debtDetail: ElementRef;

  @Input() patient: REPatientAPP;
  @Input() weekData: ReviewWeekAPI | undefined;

  @Output() changed: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<{ patient: REPatientAPP; reviewWeek: ReviewWeekAPI; }> = new EventEmitter();

  @Output() close: EventEmitter<any> = new EventEmitter();

  public initialPatient: REPatientAPP;
  public initialWeekData: ReviewWeekAPI;

  public recipes: PatientREHistoryAPI[];
  public showDisabledRecipes: boolean = false;

  public farmaticNumber: number;
  public dni: string;
  public reviewed: boolean = false;
  public reviewedDate: Date | null;
  public problematic: boolean = false;
  public externalCap: boolean = false;
  public notes: string = '';

  get chronicRE(): any[] {
    return  this.recipes.filter(r => !r.IsRE2);
  }
  get severeRE(): any[] {
    return  this.recipes.filter(r => r.IsRE2);
  }

  get nextAPF(): string {
    let result = 0;
    this.recipes.forEach(r => {
      if (r.RE.includes('APF')) {
        const num = Number(r.RE.split('APF')[1]);
        result = result >= num ? result : num;
      }
    })
    return 'APF' + (result + 1).toString().padStart(8, '0') // 'APF00000004'
  }
  public columns: {header: string, tooltip: string, columnDef: string, align: string}[] = [
    { header: 'Medicina', tooltip: '', columnDef: 'DrugName', align: 'left'},
    { header: 'Código', tooltip: 'Código nacional', columnDef: 'DrugId', align: 'center'},
    { header: 'Fecha', tooltip: 'Fecha de alta', columnDef: 'PrescrStartDate', align: 'center'},
    { header: 'Mes', tooltip: 'Consumo mensual', columnDef: 'PeriodQty', align: 'center'},
    { header: 'Compr.', tooltip: 'Comprimidos por caja', columnDef: 'PPB', align: 'center'},
    { header: 'Fact.', tooltip: 'Facturado', columnDef: 'QtySale', align: 'center'},
    { header: 'Caja', tooltip: 'Cajas mensuales', columnDef: 'Boxes', align: 'center'},
    { header: 'Pauta', tooltip: '', columnDef: 'RecurrencePattern', align: 'left'},
    { header: 'Incidencia', tooltip: '', columnDef: 'IssueType', align: 'center'},
    { header: 'Acciones', tooltip: '', columnDef: 'actions', align: 'center'},
    { header: 'Inicio', tooltip: 'Inicio de incidencia', columnDef: 'IssueDate', align: 'center'},
  ];
  public columnNames: string[] = this.columns.map(c => c.columnDef);
  public rows: PrescriptionResponseREAPI[] = [];

  patientIdCurrentDebtDetail: number | undefined;
  patientCurrentDebtDetailPosition: any = {};

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.debtDetail?.nativeElement.contains(event.target)) {
      this.patientIdCurrentDebtDetail = undefined;  
    }
  }

  constructor(
    private modalService: ModalService,
    private router: Router,
    private callsService: CallsService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  onClickUndo():void {
    this.init();
    this.changed.emit(false);
  }

  onClickSave(): void {
    this.initialPatient.FarmaticNumber = this.farmaticNumber;
    this.initialPatient.DNI = this.dni;
    this.initialPatient.Reviewed = this.reviewedDate;
    this.initialPatient.Trouble = this.problematic;
    this.initialPatient.CapExt = this.externalCap;
    this.initialPatient.ReNote = this.notes;

    this.patient.hasChanges = false;
    this.save.emit({ 
      patient: this.initialPatient,
      reviewWeek: this.initialWeekData 
    });
  }

  onChanges(): void {
    this.changed.emit(true);
  }

  updateReviewedDate(data: any): void {
    this.reviewedDate = data ? new Date() : null
  }

  onClickDailyChangeTag(dayChange: DayChanges): void {
    if(dayChange.ReportReasonCode === 4) {
      if (this.checkPatientIssue()) {
        this.modalService.openModalSubs(AlertComponent, {
          title: 'Procesar Exitus',
          message: 'Deben procesarse todas las incidencias de RE antes de procesar el Exitus'
        }).subscribe(() => {
          return
        });
        return;
      } else if (!!this.initialPatient.Deuda) {
        this.modalService.openModalSubs(AlertComponent, {
          title: 'Procesar Exitus',
          message: 'Si el paciente tiene deudas no es posible procesar el Exitus'
        }).subscribe(() => {
          return;
        });
        return;
      }
    } 

    // Mark as reviewed
    dayChange.Flag = 1;

    this.onChanges();
  }

  getDailyChanges(patient: REPatientAPP): DayChanges[] {
    return patient?.DailyChanges?.filter(d => d.Flag === 0) || [];
  }

  init(): void {
    this.initialPatient = JSON.parse(JSON.stringify(this.patient));
    this.initialWeekData = JSON.parse(JSON.stringify(this.weekData));

    this.recipes = this.initialWeekData?.reHistory ? this.initialWeekData.reHistory : [];

    this.farmaticNumber = this.initialPatient.FarmaticNumber;
    this.dni = this.initialPatient.DNI;
    this.reviewed = !!this.initialPatient.Reviewed;
    this.reviewedDate = this.initialPatient.Reviewed;
    this.problematic = this.initialPatient.Trouble;
    this.externalCap = this.initialPatient.CapExt;
    this.notes = this.initialPatient.ReNote;

    this.rows = this.initialWeekData?.prs ? this.initialWeekData.prs : [];
  }

  getTag(day: DayChanges): string {
    const text = getReportReasonDescription(
      day.ReportReasonCode,
      day.BlockReason,
      day.ExitusReason,
      true
  )
    return text;
  }

  getPatternTags(prescription: PrescriptionResponseREAPI): PrescriptionTag[] {
    let result: PrescriptionTag[] = [];
    let text = '';
    switch(prescription.RecurrencePattern) {
      case 1: // Diario
        text += `Diario`
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      case 3: // Dairio pares
        text += 'Días pares';
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      case 4: // Dairio impares
        text += 'Días impares';
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      case 2: // Semanal
        text += `Semanal`
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      case 5: // Mensual
        text += 'Mensual';
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      case 6: // Si precisa
        result.push({ class: 'sp', label: 'S/P', tooltip: '' });
        break;
      case 7: // Cíclico
        text += `Ciclo`
        result.push({ class: 'pattern', label: text, tooltip: '' });
        break;
      default:
        break;
      }

      if(prescription.itks.length) {
        const days = ['', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM'];
        prescription.itks.forEach(itk => {
          if (itk.DayCode) {
            let label = prescription.RecurrencePattern === 2 ? days[itk.DayCode] : itk.DayCode;
            result.push({
              label: `${label}`,
              class: 'day',
              tooltip: ''
            })
          }
          itk.Dosis.forEach(dosis => {
            result.push({
              label: `${dosis.TimeName} ${dosis.Qty}`,
              class: 'dosis',
              tooltip: dosis.DepName
            })
          })
        })
      }
    return result;
  }

  newEditRE(re?: PatientREHistoryAPI) {
    this.modalService.openModalSubs(
      EditReComponent,
      {
        patient: this.initialPatient,
        reData: re,
        newNumber: (!!re && re.RE) ? re.RE : 'APF',
        create: !re
      }
    ).subscribe((res: PatientREHistoryAPI) => {
      if (res && !re) {
        this.recipes.push(res);
        this.onChanges();
      } else if (res && re) {
        const index = this.recipes.findIndex(r => r.RE === res.RE);
        this.recipes[index] = res;
        this.onChanges();
      }
    })
  }

  setAll1plus2(value: boolean): void {
    this.rows.forEach(row => {
      row.IssueType = !row.IssueType ? 0 : row.IssueType;
      const plus12 = !!(row.IssueType % 2); // if true 1+2 is set
      
      let result;
      if (value) { // set 1+2
        result = plus12 ? row.IssueType : row.IssueType + 1
      } else { // unset 1+2
        result = plus12 ? row.IssueType - 1 : row.IssueType;
      }
      this.setIssue(result, row);
    })
  }

  setIssue(issueType: number, prescription: PrescriptionResponseREAPI): void {
    prescription.IssueType = issueType;

    if (!issueType) {
      prescription.IssueDate = null;
    } else  {
      prescription.IssueDate = new Date();
    }
  }

  onIssueChange(issueType: number, prescription: PrescriptionResponseREAPI): void {
    this.setIssue(issueType, prescription);

    const plus12 = !!(issueType % 2);
    this.setAll1plus2(plus12)

    this.initialWeekData.prs = this.rows;
    this.onChanges();
  }

  checkPatientIssue(): boolean {
    return this.initialWeekData.prs.some(prescription => !!prescription.IssueType)
  }

  onNoteClick(prescription: PrescriptionResponseREAPI): void {
    this.modalService.openModalSubs(NoteComponent, { note: prescription.IssueNote }).subscribe(res => {
      if (res) {
        prescription.IssueNote = res.note;
        this.initialWeekData.prs = this.rows;

        if ( res.note ) {
          this.patient.hasChanges = true;
        }
      }
    })
  }

  onPrintClick(): void {
    this.router.navigate(['global-eye/reports/annex']);
  }

  getDebtDetail($event: any, patient: REPatientAPP) {
    $event.stopPropagation();

    this.patientIdCurrentDebtDetail = patient.PatientId;

    this.patientCurrentDebtDetailPosition.top = `${$event.target.offsetTop - 5}px`; 
    this.patientCurrentDebtDetailPosition.left = `${$event.target.offsetLeft + $event.target.offsetWidth + 5}px`;

    this.callsService.getPatientReviewDebtDetail(patient.PatientId).subscribe(res => {
      patient.DebtDetail = res.payload;
    });
  }

  getReTooltipAuxData(re: PatientREHistoryAPI) {
    let text = '';

    if ( re.REFix ) {
      if ( re.RE.includes('APF') ) {
        text = ' (APF)';
      }
      else {
        text = ` (${re.RE})`;
      }
    }

    return text;
  }

  hiddenRecipes(): boolean {
    return this.chronicRE.some(item => item.Forget) || this.severeRE.some(item => item.Forget);
  }

  dailyChangeReModActive(re: any): boolean {
    return this.getDailyChanges(this.initialPatient).some(item => (item.RE === re.RE || item.RE2 === re.RE));
  }

}