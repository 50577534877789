import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Utils } from '@json/src/app/Utils';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { CallsService } from '@services/api/calls.service';

import { reportDataMedicationPlanConsolidated, reportDataMedicationPlanTable, reportDataMedicationPlanGraph } from '@constants/reports/report-medication-plan';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-report-medication-plan',
  templateUrl: './report-medication-plan.component.html',
  styleUrls: ['./report-medication-plan.component.scss']
})
export class ReportMedicationPlanComponent implements OnInit {

  public form: FormGroup;
  public reportDataMedicationPlan: any;

  public selectOptionsInsitutions: SelectOption[];
  public planTypeOptions: SelectOption[] = [
    { label: '(Todos)', value: '-1' },
    { label: 'Plan semanal', value: '0' },
    { label: 'Vuelta hospital', value: '1' },
    { label: 'Alta', value: '2' },
    { label: 'Baja', value: '3' },
    { label: 'Urgencias', value: '4' },
    { label: 'Vacaciones', value: '5' },
  ];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private reportService: ReportsService,
    private calls: CallsService,
  ) { 
    this.form = this.formBuilder.group({
      reportType: ['0'],
      planType: ['0'],
      selectedInstitution: ['', Validators.required],
      startDate: [
        moment().toDate(),
        Validators.required,
      ],
      endDate: [moment().add(1, 'week').toDate(), Validators.required],
    });
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions = Object.assign([], this.institutionService.institutionList.getValue());
      
      // Order Alpabetically label and text uppercase
      this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
      // this.selectOptionsInsitutions.map((a) =>{
      //     a.label = a.label.toUpperCase();
      // })
      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }
      }
    });
  }

  getFormData(): {
    reportType: string;
    planType: string;
    startDate: string;
    endDate: string;
    selectedInstitution: string;
  } {
    const startDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('startDate')?.value).toDate())?.toISOString().split(".")[0];
    const endDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('endDate')?.value).toDate())?.toISOString().split(".")[0];

    const reportType = this.form.get('reportType')?.value;
    const planType = this.form.get('planType')?.value;
    const selectedInstitution = this.form.get('selectedInstitution')?.value;

    // @ts-ignore
    return { reportType, startDate, endDate, planType, selectedInstitution };
  }
  getReportData(reportType: string): any {
    switch ( reportType ) {
      case '0':
        return reportDataMedicationPlanConsolidated;
      case '1':
        return reportDataMedicationPlanTable;
      case '2':
        return reportDataMedicationPlanGraph;
      default:
        return reportDataMedicationPlanConsolidated;
    }
  }

  draftReportData(): boolean {
    const { reportType, startDate, endDate, planType, selectedInstitution } = this.getFormData();
    
    if ( reportType === '0' || (reportType !== '0' && this.form.get('selectedInstitution')?.valid ) ) {
      this.reportDataMedicationPlan = this.getReportData(reportType);
      this.setInfoRP(reportType, startDate, endDate, planType, selectedInstitution);
      return true;
    } else {
      this.form.get('selectedInstitution')?.markAsTouched();
      this.calls.openSnack('Debes seleccionar al menos un centro');
      return false;
    }
  }

  handlePreviewClick(): void {
    if ( this.draftReportData() )
      this.reportService.handlePreviewClick(this.router, this.reportDataMedicationPlan);
  }
  handlePrintClick(): void {
    if ( this.draftReportData() )
      this.reportService.handlePrintClick(this.reportDataMedicationPlan);
  }
  handlePdfClick(): void {
    if ( this.draftReportData() )
      this.reportService.handlePdfClick(this.reportDataMedicationPlan);
  }
  handleExcelClick(): void {
    if ( this.draftReportData() )
      this.reportService.handleExcelClick(this.reportDataMedicationPlan);
  }

  setInfoRP(reportType: string, startDate: string, endDate: string, planType: string, selectedInstitution: string, isPreview = false): void {
    const dataForm = this.form.getRawValue()
    let currentInstitution = null;

    if ( reportType !== '0' ) {
      currentInstitution = dataForm.selectedInstitution.map((item: any) => item.id).join();
    }

    const rp = JSON.stringify({
      LocaleId: '3082',
      InstitutionList: currentInstitution,
      OperationType: reportType !== '0' ? String(planType) : null,
      OperationName: reportType !== '0' ? String(this.planTypeOptions.find((item) => item.value === planType)?.label) : null,
      DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split(".")[0],
      DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate())?.toISOString().split(".")[0],
    });

    this.reportDataMedicationPlan.data.rp = rp;
    this.reportDataMedicationPlan.dataDownloadPDF.rp = rp;
    this.reportDataMedicationPlan.dataDownloadExcel.rp = rp;
    this.reportDataMedicationPlan.dataRenderReport.rp = rp;

    let type: string = '';
    switch( parseInt(reportType) ) {
      case 0: type = 'cons'; break;
      case 1: type = 'tipo'; break;
      case 2: type = 'graphic'; break;
    }

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    const now = moment();
    this.reportDataMedicationPlan.data.dn = 
      `Operaciones_de_pm_${type}_${institution}_Del_${moment(startDate).format('YYYYMMDD')}_Al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.html`;
    this.reportDataMedicationPlan.dataDownloadPDF.dn = 
    this.reportDataMedicationPlan.dataRenderReport.dn =
      `Operaciones_de_pm_${type}_${institution}_Del_${moment(startDate).format('YYYYMMDD')}_Al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataMedicationPlan.dataDownloadExcel.dn =
      `Operaciones_de_pm_${type}_${institution}_Del_${moment(startDate).format('YYYYMMDD')}_Al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
  }
}
