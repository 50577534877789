<div class="col-1">
  <div class="row-1" [ngClass]="{'disabled2': patients.length}">
    <div class="content target">
      <app-form-field
        class="field"
        [ngClass]="{'disabled': !departments.length}"
        [type]="'select'"
        label="Departamento"
        [fieldControl]="form.controls['departments'] | asFormControl"
        [multipleSelect]="true"
        [deselectAllShortcut]="false"
        [options]="departments">
      </app-form-field>

      <app-form-field
        class="field"
        [ngClass]="{'disabled': !departments.length}"
        [type]="'select'"
        label="Día"
        [fieldControl]="form.controls['date'] | asFormControl"
        [options]="dates">
      </app-form-field>
      
      <div class="hours" [ngClass]="{'disabled': !departments.length}">
        <span class="label">Horario *</span>

        <div class="shortcuts">
          <button mat-button class="button" matTooltip="Marcar todas" (click)="addAllHours()">
            Marcar todas
          </button>
          <button mat-button class="button" matTooltip="Desmarcar todas" (click)="removeAllHours()">
            Desmarcar todas
          </button> 
        </div>

        <ul class="list">
          <li 
            class="hour" 
            *ngFor="let hour of hours" 
            (mousedown)="mouseDown = true; setHour(hour.value)"
            (mouseover)="mouseDown && setHour(hour.value)"
            (mouseup)="mouseDown = false;"
            [ngClass]="{ 
              'selected': isHourSelected(hour.value), 
              'disabled': hour.hidden
            }"
          >
            {{ hour.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row-2">
    <div class="content">
      <div class="option-button" >
        <button 
          *ngIf="!patients.length"
          mat-button class="action" 
          title="Buscar pacientes" 
          [disabled]="!step1Complete" 
          (click)="getPatinets()"
        >
          <mat-icon>search</mat-icon>
          <span>BUSCAR PACIENTES</span>
        </button>

        <button 
          *ngIf="patients.length"
          mat-button class="action" 
          title="Buscar pacientes" 
          [disabled]="!step1Complete" 
          (click)="showChangeSelectionModal()"
        >
          <mat-icon>search</mat-icon>
          <span>CAMBIAR SELECCIÓN</span>
        </button>

        <button 
          mat-button 
          class="action" 
          title="Ver incidencias" 
          [disabled]="!patients.length"
          (click)="openIncidentModal()"
        >
          <mat-icon>checklist_rtl</mat-icon>
          <span>VER INCIDENCIAS</span>
        </button>

        <button 
          mat-button 
          class="action" 
          title="Administrar y firmar" 
          [disabled]="!patients.length"
          (click)="showSignModal()"
        >
          <mat-icon>done</mat-icon>
          <span>ADMINISTRAR Y FIRMAR</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="col-2" [ngClass]="{'disabled': !step1Complete}">
  <div class="row-1">
    <div class="content incident-type">
      <app-form-field
        class="field"
        [type]="'select'"
        label="Tipo de incidencia"
        [fieldControl]="form.controls['incident'] | asFormControl"
        [options]="incidentTypes">
      </app-form-field>
    </div>
  </div>

  <div class="row-2">
    <div class="content">

      <div class="patients-filter" *ngIf="step1Complete && !loadingPatients && patients.length">
        <app-form-field
          class="field"
          [type]="'text'"
          label="Filtrar pacientes"
          [fieldControl]="patientsFilter | asFormControl"
        />
      </div>

      <div class="no-data" *ngIf="step1Complete && !loadingPatients && !patients.length && patientsSearched">No hay pacientes en el horario seleccionado.</div>

      <ul class="patients" *ngIf="step1Complete && !loadingPatients && patients.length">
        <li 
          class="patient" 
          *ngFor="let patient of filteredPatients" 
          [ngClass]="{ 
            'selected': isPatientSelected(patient),
            'has-incidents': patientHasIncidents(patient),
          }"
          (click)="setPatient(patient)"
        >
          <span class="name">{{ patient.label }}</span>
          <div class="info">
            <div class="labels">
              <span class="label" *ngIf="patientHasGeneralIncidents(patient)">G</span>
              <span class="label" *ngIf="patientHasSPDAIncidents(patient)">SPDA</span>
              <span class="label" *ngIf="patientHasNEIncidents(patient)">N/E</span>
              <span class="label" *ngIf="patientHasSPIncidents(patient)">S/P</span>
            </div>
            <button 
              mat-icon-button 
              [matMenuTriggerFor]="menu"
              matTooltip="Eliminar incidencias"
              *ngIf="patientHasIncidents(patient)"
            >
              <mat-icon>delete_sweep</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="clearAllPatientIncidents($event, patient)">Eliminar todas las incidencias</button>
              <button mat-menu-item *ngIf="patientHasGeneralIncidents(patient)" (click)="clearPatientGeneralIncidents($event, patient)">Eliminar incidencias generales</button>
              <button mat-menu-item *ngIf="patientHasSPDAIncidents(patient)" (click)="clearPatientSPDAIncidents($event, patient)">Eliminar incidencias SPDA</button>
              <button mat-menu-item *ngIf="patientHasNEIncidents(patient)" (click)="clearPatientNEIncidents($event, patient)">Eliminar incidencias N/E</button>
              <button mat-menu-item *ngIf="patientHasSPIncidents(patient)" (click)="clearPatientSPIncidents($event, patient)">Eliminar incidencias S/P</button>
            </mat-menu>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="col-3" [ngClass]="{'disabled': !step2Complete}">
  <div class="content medication">
    <!-- General -->
    <ng-container *ngIf="step2Complete && currentIncident === 1">
      <app-form-field
        class="field"
        [type]="'checkbox'"
        label="Aplica a todos los horarios"
        [fieldControl]="currentPatient.applyToAllMedicationHours | asFormControl"
      ></app-form-field>

      <ng-container *ngIf="!appliesToAllMedicationHours">
        <div class="medication-hour" *ngFor="let schedule of currentPatient.generalIncident">
          <div class="hour">{{schedule.hour}}</div>

          <app-form-field
            class="field incident"
            [type]="'select'"
            label="Indica la incidencia"
            [fieldControl]="schedule.incident | asFormControl"
            [options]="incidentSubtypes">
          </app-form-field>
          <app-form-field
            class="field"
            type="textarea"
            label="Notas"
            [fieldControl]="schedule.note | asFormControl">
          </app-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="appliesToAllMedicationHours">
        <div class="medication-hour">
          <div class="hours">
            <div class="hour" *ngFor="let schedule of currentPatient.generalIncident">{{schedule.hour}}</div>
          </div>

          <app-form-field
            class="field incident"
            [type]="'select'"
            label="Indica la incidencia"
            [fieldControl]="currentPatient.generalIncident[0].incident | asFormControl"
            [options]="incidentSubtypes">
          </app-form-field>
          <app-form-field
            class="field"
            type="textarea"
            label="Notas"
            [fieldControl]="currentPatient.generalIncident[0].note | asFormControl">
          </app-form-field>
        </div>
      </ng-container>
    </ng-container>

    <!-- SPDA -->
    <ng-container *ngIf="step2Complete && currentIncident === 2">
      <div class="medication-hour" *ngFor="let schedule of currentPatient.spda">
        <div class="hour">{{schedule.hour}}</div>

        <div class="medicines">
          <div class="medicine" *ngFor="let medicine of schedule.medicines">
            <div class="name">
              <span>{{medicine.DrugName}}</span>
              <app-form-field
                class="field checkbox"
                [type]="'checkbox'"
                label="No administrado"
                [fieldControl]="medicine.notAdministered | asFormControl"
              ></app-form-field>
            </div>
          </div>
        </div>

        <app-form-field
          class="field"
          type="textarea"
          label="Notas"
          [fieldControl]="schedule.note | asFormControl">
        </app-form-field>
      </div>
    </ng-container>

    <!-- N/E -->
    <ng-container *ngIf="step2Complete && currentIncident === 3">
      <div class="medication-hour" *ngFor="let schedule of currentPatient.ne">
        <div class="hour">{{schedule.hour}}</div>

        <div class="medicines">
          <div class="medicine" *ngFor="let medicine of schedule.medicines">
            <div class="name">
              <span>{{medicine.DrugName}}</span>
              <app-form-field
                class="field checkbox"
                [type]="'checkbox'"
                label="No administrado"
                [fieldControl]="medicine.notAdministered | asFormControl"
              ></app-form-field>
            </div>
            <app-form-field
              class="field"
              type="textarea"
              label="Notas"
              [fieldControl]="medicine.note | asFormControl">
            </app-form-field>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- S/P -->
    <ng-container *ngIf="step2Complete && currentIncident === 4">
      <div class="medication-hour" *ngFor="let schedule of currentPatient.sp">
        <div class="hour">{{schedule.hour}}</div>

        <div class="medicines">
          <div class="medicine" *ngFor="let medicine of schedule.medicines">
            <div class="name">
              <span>{{medicine.DrugName}}</span>
              <app-form-field
                class="field checkbox"
                [type]="'checkbox'"
                label="Administrado"
                [fieldControl]="medicine.administered | asFormControl"
              ></app-form-field>
            </div>
            <div class="time">
              <app-form-field
                class="field"
                type="number"
                label="Hora"
                [min]="0"
                [max]="2"
                [exactPattern]="'^[0-9]$|^[1][0-9]$|^[2][0-3]$'"
                [numberFieldFastButtons]="true"
                [fieldControl]="medicine.hour | asFormControl">
              </app-form-field>
              <app-form-field
                class="field"
                type="number"
                label="Minuto"
                [min]="0"
                [max]="2"
                [exactPattern]="'^[0-9]$|^[1-5][0-9]$'"
                [numberFieldFastButtons]="true"
                [fieldControl]="medicine.minute | asFormControl">
              </app-form-field>
            </div>
            <app-form-field
              class="field"
              type="textarea"
              label="Notas"
              [fieldControl]="medicine.note | asFormControl">
            </app-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>