import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverlappedPrescription } from '@interfaces/medicine/medicine.interface';

@Component({
    selector: 'app-interactions',
    templateUrl: './interactions.component.html',
    styleUrls: ['./interactions.component.scss']
})
export class InteractionsComponent {

    public overlappedPrescriptions: OverlappedPrescription[] = [];
    public get overlapedType(): number {
        let result = 0
        this.overlappedPrescriptions.forEach(p => {
            if (p.OverlapType === 1) result = 1;
            else if (p.OverlapType === 2 && result !== 1) result = 2;
        })
        return result;
    }

    public get couldOverride(): boolean {
        return this.overlappedPrescriptions.every(p => {
            if (p.OverlapType == 1) {
                if (!p.CouldOverride) {
                    return false;
                }
            }

            return true;
        })
    }

    public columns: { header: string, columnDef: string }[] = [
        { header: 'CÓDIGO', columnDef: 'DrugId' },
        { header: 'NOMBRE', columnDef: 'Name' },
        { header: 'INICIO', columnDef: 'StartDate' },
        { header: 'FIN', columnDef: 'EndDate' },
        { header: 'INCOMPATIBILIDAD', columnDef: 'OverlapType' },
    ];
    public columnNames: string[] = this.columns.map(c => c.columnDef);


    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<InteractionsComponent>
    ) {
        if (data) {
            this.overlappedPrescriptions = data.overlaps;
        }
    }

    onConfirmClick(type: string): void {
        this.dialogRef.close(type);
    }

    getInteractionName(row: OverlappedPrescription): string {
        switch (row.OverlapType) {
            case 1:
                return 'Mismo medicamento' + (row.CouldOverride ? ' agudo con crónico' : '');
            case 2:
                return 'Interacción farmacéutica';
            default:
                return row.OverlapType + '';
        }
    }
}
