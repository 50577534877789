import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DateService } from '@services/date.service';
import { FormService } from '@services/form.service';
import { SortService } from '@services/sort.service';

import { ArraySubformComponent } from '@shared/array-subform/array-subform.component';
import { CalendarSummaryComponent } from '@shared/calendar-summary/calendar-summary.component';
import moment from 'moment';
import { pairwise, startWith } from 'rxjs';

@Component({
  selector: 'app-clinic-course',
  templateUrl: './clinic-course.component.html',
  styleUrls: ['./clinic-course.component.scss']
})
export class ClinicCourseComponent extends ArraySubformComponent implements OnInit, OnDestroy {

  @ViewChild(CalendarSummaryComponent, { static: false }) calendarSummary: CalendarSummaryComponent;

  monthSelectorFormControl: FormControl = new FormControl(true);
  monthSelectorOptions: any = [
    { value: false, label: 'Día' },
    { value: true, label: 'Mes' }
  ]

  constructor(
    protected override   formsService: FormService,
    protected override  sortService: SortService,
    protected override   dateService: DateService
  ) {
    super(formsService, sortService, dateService)
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.subs['monthSelector'] = this.monthSelectorFormControl.valueChanges.subscribe(value => {
      this.showCalendar = value;
    })


    this.subs['fieldControl1'] = this.fieldControl.valueChanges
      .pipe(startWith(this.fieldControl.value), pairwise())
      .subscribe(
      (value) => {
        const dateChange = value[1].map((item: any) => item.date).filter((x: any) => !value[0].map((item: any) => item.date).includes(x));
       
        if ( dateChange.length === 1 ) {
          this.filterItems(dateChange[0])
          try {
            // @ts-ignore
            this.updateDate({date: dateChange[0].toDate()});
          } catch(e) {
            // @ts-ignore
            this.updateDate({date: dateChange[0]});
          }
          this.calendarSummary.selectDate(dateChange[0])
        }
      }
    );
  }


  applyFilterDates(ctrl: any) {
    if (this.calenderUsedForFilter && this.showCalendar) {
        const date = ctrl.value[this.formConfig.calendarConfig.dateField];
        if (date == '') return true;
        if (moment(date).isSame(this.selectedDate, 'day')) {
            return true;
        }
        return true;
    }
    return true;
}

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
