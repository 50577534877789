import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

@Component({
    selector: 'app-medicine-edit-epigraphs',
    templateUrl: './epigraphs.component.html',
    styleUrls: ['./epigraphs.component.scss']
})
export class EpigraphsComponent extends DynamicFormComponent implements OnInit, OnDestroy {

    constructor(
        protected override sortService: SortService,
        protected override cdr: ChangeDetectorRef
    ) {
        super(sortService, cdr)
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.schema.forEach(schema => schema.childs?.forEach(child => { this.fieldVisible(child, child) }));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    addNewItem($event: any, section: any) {
        $event.stopPropagation();

        let element: ElementRef;

        switch (section.childs[0].customType) {

        }

        // All this elements extends from ArraySubformComponent so we
        // can ignore ts error here
        // @ts-ignore
        element?.addOne();
    }

}
