import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { sidebarConfig as pharmaSidebarConfig } from '@json/projects/pharma-app/src/config-farma';
import { sidebarConfig as quantumSidebarConfig } from '@json/projects/quantum-app/src/config-quantum';

import { sidebarConfigItem } from '@interfaces/sidebar.interface';
import { RoleManagerService } from '@services/role-manager.service';

@Component({
    selector: 'app-report-select',
    templateUrl: './report-select.component.html',
    styleUrls: ['./report-select.component.scss'],
})
export class ReportSelectComponent implements OnInit {
    @Input() title: string = '';
    @Input() disabled: boolean = false;
    @Output() previewClicked = new EventEmitter<string>();
    @Output() printClicked = new EventEmitter<string>();
    @Output() pdfClicked = new EventEmitter<string>();
    @Output() excelClicked = new EventEmitter<string>();

    public sidebarConfig: sidebarConfigItem[] = this.roleManager.isPharma() ? pharmaSidebarConfig : quantumSidebarConfig;

    links: sidebarConfigItem[] = [];

    constructor( 
        private route: ActivatedRoute,
        private roleManager: RoleManagerService,  
    ) {
        // Get quick access links
        this.route.url.subscribe(([url]) => {
            const { path, parameters } = url;
            const childs = this.sidebarConfig.find(item => item.link === 'reports');

            if ( childs?.items ) {
                const aux = childs.items
                .find((item: sidebarConfigItem) => 
                    item.items ? item?.items
                    .find((subItem: sidebarConfigItem) => 
                        subItem.link === `reports/${path}`
                    ) : undefined
                )

                this.links = aux?.items || [];
            }
        });
    }

    ngOnInit(): void {
    }

    onPreviewClick(): void {
        this.previewClicked.emit();
    }

    onPrintClick(): void {
        this.printClicked.emit();
    }

    onPdfClick(): void {
        this.pdfClicked.emit();
    }

    onExcelClick(): void {
        this.excelClicked.emit();
    }

    testPerm(item: sidebarConfigItem) {
        if (typeof(item.hasPermission) == 'function') {
            return item.hasPermission().length > 0; // Puede leer o escribir así que se debe mostrar
        }

        return item.hasPermission.length > 0; // Puede leer o escribir así que se debe mostrar
    }
}
