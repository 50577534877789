import {
  FormBuilderInterface,
  FormConfigInterface,
  TabInterface,
} from '@interfaces/dynamic-form.interface';
import { daysOfWeekOptions } from './days';
import {
  FRECUENCY_OPTIONS,
  MODALITY_OPTIONS,
  TYPE_AUTOEVALUATION_OPTIONS,
  TYPOLOGY_OPTIONS,
  WEEK_DAY_OPTOINS,
} from '@constants/options';
import { schedulesOptions } from './schedules';
import { Validators } from '@angular/forms';
import { Utils } from '../Utils';
import { Permission, PermissionType } from '../enums/PermissionType';

export const tabs: TabInterface[] = [
  { label: 'General', form: 'general', hidden: Utils.hasPermission(Permission.INSTITUTIONS_GENERAL) },
  { label: 'Horarios', form: 'schedules', hidden: Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES) },
  { label: 'Actus', form: 'actus', hidden: Utils.hasPermission(Permission.INSTITUTIONS_ACTUS) },
  { label: 'Departamentos', form: 'departments', hidden: Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS) },
  { label: 'RE', form: 're', hidden: Utils.hasPermission(Permission.INSTITUTIONS_RE) },
  { label: 'PM', form: 'pm', hidden: Utils.hasPermission(Permission.INSTITUTIONS_PM) },
  { label: 'Documentos', form: 'documents', hidden: Utils.hasPermission(Permission.INSTITUTIONS_DOCUMENTS) },
];

const general: FormBuilderInterface = {
  main: {
    type: 'group-label',
    label: 'Datos generales',
    childs: {
      nameAbbreviation: {
        label: 'Abreviatura',
        type: 'text',
        value: '',
        validators: [Validators.required, Validators.maxLength(8)],
        size: 'col-4',
        uppercase: true,
        max: 8,
        inputPattern: '^[A-Za-z]{0,8}$',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      name: {
        label: 'Nombre',
        type: 'text',
        value: '',
        validators: [Validators.required],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      type: {
        label: 'Institucion',
        type: 'select',
        value: '',
        validators: [Validators.required],
        options: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      modality: {
        label: 'Modalidad',
        type: 'select',
        value: '',
        validators: [],
        options: MODALITY_OPTIONS,
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      typology: {
        label: 'Tipología atención',
        type: 'select',
        value: '',
        validators: [],
        options: TYPOLOGY_OPTIONS,
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
    
      openDate: {
        label: 'Fecha apertura',
        type: 'date',
        value: Utils.NOW,
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      address: {
        label: 'Dirección',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      number: {
        label: 'Número',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      city: {
        label: 'Ciudad',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      postalCode: {
        label: 'Código postal',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        inputPattern: '^[0-9]*$',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      telephone: {
        label: 'Teléfono',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        inputPattern: '^[0-9]*$',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
    }
  },
  
  emailList: {
    type: 'group-label',
    label: 'Correo electrónico',
    childs: {
      general: {
        label: 'General',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      re: {
        label: 'RE',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      inventary: {
        label: 'Inventario',
        type: 'text',
        value: '',
        validators: [],
        size: 'col-4',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
    },
  },
  doctors: {
    type: 'group-label',
    label: 'Doctores',
    childs: {
      name1: {
        label: 'Nombre',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      collegiate1: {
        label: 'Colegiado',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      name2: {
        label: 'Nombre',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      collegiate2: {
        label: 'Colegiado',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
    },
  },
  otherData: {
    type: 'group-checkbox',
    label: 'Otros datos',
    childs: {
      ccaa: {
        label: 'Comunidad autónoma',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      businessGroup: {
        label: 'Grupo empresarial',
        type: 'text',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      obsolete: {
        label: 'Obsoleto',
        type: 'checkbox',
        value: false,
        validators: [],
        size: 'col-2',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
      allowFgp: {
        label: 'Permitir FGP',
        type: 'checkbox',
        value: false,
        validators: [],
        size: 'col-2',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_FGP)().includes(PermissionType.WRITE)
      },
      cfExport: {
        label: 'Exportar CF',
        type: 'checkbox',
        value: false,
        validators: [],
        size: 'col-2',
        visible: false,
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_GENERAL)().includes(PermissionType.WRITE)
      },
    },
  },
};

const schedules: FormBuilderInterface = {
  schedule: {
    type: 'group-label',
    label: 'Horarios',
    childs: {
      breakfast: {
        label: 'Desayuno',
        type: 'select',
        value: '',
        validators: [],
        options: schedulesOptions,
        size: 'col-2',
        customClass: ['col-2_4'],
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES)().includes(PermissionType.WRITE)
      },
      lunch: {
        label: 'Comida',
        type: 'select',
        value: '',
        validators: [],
        options: schedulesOptions,
        size: 'col-2',
        customClass: ['col-2_4'],
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES)().includes(PermissionType.WRITE)
      },
      snack: {
        label: 'Merienda',
        type: 'select',
        value: '',
        validators: [],
        options: schedulesOptions,
        size: 'col-2',
        customClass: ['col-2_4'],
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES)().includes(PermissionType.WRITE)
      },
      dinner: {
        label: 'Cena',
        type: 'select',
        value: '',
        validators: [],
        options: schedulesOptions,
        size: 'col-2',
        customClass: ['col-2_4'],
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES)().includes(PermissionType.WRITE)
      },
      sleep: {
        label: 'Dormir',
        type: 'select',
        value: '',
        validators: [],
        options: schedulesOptions,
        size: 'col-2',
        customClass: ['col-2_4'],
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_SCHEDULES)().includes(PermissionType.WRITE)
      },
    }
  }
};

const actus: FormBuilderInterface = {
  info: {
    type: 'group-label',
    label: '',
    size: 'col-12',
    hideLabel: true,
    customStyle: {
      'margin-bottom': '24px'
    },
    childs: {
      info1: {
        type: 'info',
        label: 'Proceda con cuidado. Las modificaciones a estos valores pueden alterar el funcionamiento de la aplicación de ACTUS'
      },
    }
  },
  validity: {
    type: 'group-label',
    label: 'Intervalos de validez de la hora de medicación',
    size: 'col-6',
    customClass: ['left'],
    childs: {
      before: {
        label: 'Anterior',
        type: 'sub-group',
        size: 'col-12',
        childs: {
          hours: {
            label: 'Horas',
            type: 'number',
            min: 0,
            max: 1,
            value: '',
            size: 'col-6',
            exactPattern: '^0$|^1$|^2$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
          minutes: {
            label: 'Minutos',
            type: 'number',
            min: 0,
            max: 2,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^[0-9]$|^[1-5][0-9]$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
        }
      },
      after: {
        label: 'Posterior',
        type: 'sub-group',
        size: 'col-12',
        childs: {
          hours: {
            label: 'Horas',
            type: 'number',
            min: 0,
            max: 1,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^0$|^1$|^2$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
          minutes: {
            label: 'Minutos',
            type: 'number',
            min: 0,
            max: 2,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^[0-9]$|^[1-5][0-9]$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
        }
      },
    }
  },
  preparation: {
    type: 'group-label',
    label: 'Intervalos de preparación de la hora de medicación',
    size: 'col-6',
    customClass: ['right'],
    childs: {
      before: {
        label: 'Anterior',
        type: 'sub-group',
        size: 'col-12',
        childs: {
          hours: {
            label: 'Horas',
            type: 'number',
            min: 0,
            max: 1,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^0$|^1$|^2$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
          minutes: {
            label: 'Minutos',
            type: 'number',
            min: 0,
            max: 2,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^[0-9]$|^[1-5][0-9]$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
        }
      },
      after: {
        label: 'Posterior',
        type: 'sub-group',
        size: 'col-12',
        childs: {
          hours: {
            label: 'Horas',
            type: 'number',
            min: 0,
            max: 1,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^0$|^1$|^2$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
          minutes: {
            label: 'Minutos',
            type: 'number',
            min: 0,
            max: 2,
            value: '',
            validators: [],
            size: 'col-6',
            exactPattern: '^[0-9]$|^[1-5][0-9]$',
            numberFieldFastButtons: true,
            readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_ACTUS)().includes(PermissionType.WRITE)
          },
        }
      }
    }
  }
}

const departmentSchedules: FormBuilderInterface = {
  id: {
    label: 'id',
    hideLabel: true,
    type: 'info',
  },
  title: {
    label: 'Department',
    hideLabel: true,
    type: 'info',
  },
  name: {
    label: 'Nombre',
    type: 'text',
    value: '',
    size: 'col-6',
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)

  },
  enableCustomSchedule: {
    label: 'Horarios personalizados',
    type: 'checkbox',
    size: 'col-6',
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
  breakfast: {
    label: 'Desayuno',
    type: 'select',
    value: '',
    validators: [],
    options: schedulesOptions,
    size: 'col-2',
    customClass: ['col-2_4'],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
  lunch: {
    label: 'Comida',
    type: 'select',
    value: '',
    validators: [],
    options: schedulesOptions,
    size: 'col-2',
    customClass: ['col-2_4'],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
  snack: {
    label: 'Merienda',
    type: 'select',
    value: '',
    validators: [],
    options: schedulesOptions,
    size: 'col-2',
    customClass: ['col-2_4'],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
  dinner: {
    label: 'Cena',
    type: 'select',
    value: '',
    validators: [],
    options: schedulesOptions,
    size: 'col-2',
    customClass: ['col-2_4'],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
  sleep: {
    label: 'Dormir',
    type: 'select',
    value: '',
    validators: [],
    options: schedulesOptions,
    size: 'col-2',
    customClass: ['col-2_4'],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
};

const departmentSchedule: FormBuilderInterface = {
  department: {
    type: 'group-expansion',
    label: 'Department',
    customFieldData: { title: '', newExpanded: true },
    childs: departmentSchedules,
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
};

const departments: FormBuilderInterface = {
  departments: {
    type: 'custom',
    customType: 'list-form',
    label: 'array',
    customFieldData: { schema: departmentSchedule },
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DEPARTMENTS)().includes(PermissionType.WRITE)
  },
};

export interface Item {
  Time: string;
  StandardMoment: number;
}

export interface InputObject {
  id: number;
  nam: string;
  itkms: Item[];
}

export interface Department {
  id: number;
  title: string;
  name: string;
  enableCustomSchedule: null | boolean;
  breakfast: string;
  lunch: string;
  snack: string;
  dinner: string;
  sleep: string;
}

export interface OutputObject {
  department: Department;
}

const re: FormBuilderInterface = {
  incontinence: {
    type: 'group-label',
    label: 'Incontinencia',
    childs: {
      deliveryDetails: {
        label: 'Detalles de entrega',
        type: 'textarea',
        value: null,
        validators: [],
        size: 'col-12',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    },
  }, 
  autovalidation: {
    type: 'group-label',
    label: 'Autovalidación',
    childs: {
      autoValidationOrder: {
        label: 'Autovalidar órdenes',
        type: 'checkbox',
        value: null,
        validators: [],
        size: 'col-3',
        customStyle: {
          'margin-bottom': '28px'
        },
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      type: {
        label: 'Tipo',
        type: 'radio',
        // value: 0,
        validators: [Validators.required],
        size: 'col-3',
        options: TYPE_AUTOEVALUATION_OPTIONS,
        conditionalConfig: {
          mode: 'hide',
          match: 'loose-equals',
          filter: 'every',
          fields: [{ name: 'autoValidationOrder', value: true }],
        },
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      day: {
        label: 'Día',
        type: 'select',
        value: null,
        validators: [Validators.required],
        options: daysOfWeekOptions,
        size: 'col-3',
        conditionalConfig: {
          mode: 'hide',
          match: 'loose-equals',
          filter: 'every',
          fields: [
            { name: 'autoValidationOrder', value: true },
            { name: 'type', value: 0 }
          ],
        },
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      hour: {
        label: 'Hora',
        type: 'select',
        value: null,
        validators: [Validators.required],
        options: schedulesOptions,
        size: 'col-3',
        conditionalConfig: {
          mode: 'hide',
          match: 'loose-equals',
          filter: 'every',
          fields: [
            { name: 'autoValidationOrder', value: true },
            { name: 'type', value: 0 }
          ],
        },
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      hours: {
        label: 'Horas',
        type: 'number',
        value: null,
        validators: [Validators.required],
        size: 'col-3',
        conditionalConfig: {
          mode: 'hide',
          match: 'loose-equals',
          filter: 'every',
          fields: [
            { name: 'autoValidationOrder', value: true },
            { name: 'type', value: 1 }
          ],
        },
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    },
  },
  autoconfirmation: {
    type: 'group-label',
    label: 'Autoconfirmación',
    size: 'col-6',
    childs: {
      autoconfirmation: {
        label: 'Horas para autoconfirmar (72 si se deja en blanco)',
        type: 'text',
        value: '',
        validators: [],
        options: schedulesOptions,
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    }
  },
  enableBonification: {
    label: 'Habilitar bonificaciones',
    type: 'checkbox',
    value: '',
    validators: [],
    size: 'col-6',
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
  },

  automaticReposition: {
    type: 'group-label',
    label: 'Reposición automática',
    childs: {
      frecuency: {
        type: 'radio',
        label: 'Día inicial',
        withTitle: true,
        size: 'col-6',
        options: FRECUENCY_OPTIONS,
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      excludedMedication: {
        label: 'Medicación excluida',
        type: 'select',
        value: null,
        validators: [],
        size: 'col-3',
        multipleSelect: true,
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },

      noSustituteMedication: {
        label: 'Medicación no sustituible',
        type: 'select',
        value: null,
        validators: [],
        size: 'col-3',
        multipleSelect: true,
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    },
  },

  MEC: {
    type: 'group-label',
    label: 'MEC',
    customStyle: {'margin-bottom' : '20px'},
    childs: {
      state: {
        label: 'Ignorar prescripciones con MEC',
        type: 'checkbox',
        value: null,
        validators: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    },
  },

  linkFarmatic: {
    type: 'group-label',
    label: 'Enlace a FARMATIC',
    childs: {
      prefix: {
        label: 'Prefijo',
        type: 'select',
        value: null,
        validators: [],
        options: [],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
      idFarmatic: {
        label: 'ID de FARMATIC',
        type: 'text',
        value: null,
        inputPattern: '^[A-Za-z0-9]{0,5}$',
        max: 5,
        // validators: [Validators.pattern(/^[A-Za-z0-9]{5}$/),],
        size: 'col-6',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_RE)().includes(PermissionType.WRITE)
      },
    },
  },
};

const pm: FormBuilderInterface = {
  interval: {
    type: 'group-label',
    label: 'Intervalo de generación',
    childs: {
      initialDay: {
        type: 'radio',
        label: 'Día inicial',
        withTitle: true,
        size: 'col-12',
        options: WEEK_DAY_OPTOINS,
        customStyle: {'margin-bottom' : '12px'},
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_PM)().includes(PermissionType.WRITE)
      },
      intervalDay: {
        label: 'Intervalo de días',
        type: 'number',
        value: null,
        min: 1,
        max: 14,
        validators: [Validators.min(1), Validators.max(14)],
        size: 'col-12',
        placeholder: 'Intervalo 1-14 días',
        readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_PM)().includes(PermissionType.WRITE)
      },
    },
  },
};

const documents: FormBuilderInterface = {
  documents: {
    type: 'custom',
    customType: 'documents-preview-upload',
    label: 'Documentos',
    value: [],
    readOnly: () => !Utils.hasPermission(Permission.INSTITUTIONS_DOCUMENTS)().includes(PermissionType.WRITE)
  },
};

export const formsConfig: FormConfigInterface = {
  general,
  schedules,
  actus,
  departments,
  re,
  pm,
  documents,
};
