import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEsp } from '@json/src/app/MatPaginatorIntlEsp';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from '@json/src/environments/environment';
import packageJson from '@json/package.json';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

registerLocaleData(localeEs, 'es');

Sentry.init(
  {
    dsn: 'https://f035383acd5de7dc70de83b424649691@o4506101062172672.ingest.sentry.io/4506101072003072',
    beforeSend(event, hint) {

      // Check if is non-error exception
      const isNonErrorException = 
        // @ts-ignore
        event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured') ||
        // @ts-ignore
        hint?.originalException['message']?.startsWith('Non-Error exception captured') ||
        // @ts-ignore
        event?.exception?.values[0]?.value?.startsWith('Handled unknown error') ||
        // @ts-ignore
        hint?.originalException['message']?.startsWith('Handled unknown error');

      // Drop events for development environments
      // Drop Non-Error exceptions
      if (
        window.location.href.indexOf("localhost:") != -1 || 
        isNonErrorException) {
        console.log('Sentry exception dropped', event);
        return null;
      }

      return event;
    },
    // To set your release and dist versions
    release: `quantum@${packageJson.version}`,
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
    environment: environment.production ? 'production' : 'development',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // integrations: [new SentryAngular.Replay()],
  },
);
@NgModule({
  declarations: [AppComponent],
  imports: [
    ComponentsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga_quantum),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es' },{provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp}],
  bootstrap: [AppComponent],
})
export class AppModule {}
