import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
    reportDataPolimedication,
    reportDataPolimedicationByMonth,
} from '@constants/reports/report-polimedication';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { DepartmentPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-report-polimedication',
    templateUrl: './report-polimedication.component.html',
    styleUrls: ['./report-polimedication.component.scss'],
})
export class ReportPolimedicationComponent {
    public reportDataPolimedication = reportDataPolimedication;
    public showPatientSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showIntervalDay: boolean = false;
    public showSpecificDaySelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public selectOptionsDepartment: DepartmentPharma[];

    public toDateMin: _moment.Moment;
    public toDateMax: _moment.Moment;
    public fromDateMax: _moment.Moment;

    public selectOptionsInsitutions: SelectOption[];
    
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            specificDay: [moment()],
            startDate: [moment().subtract(3, 'months')],
            endDate: [moment()],
            intervalType: ['specificDay'],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Índice de polimedicación`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Índice de polimedicación`);

        this.setListenerSelectDay();
        this.setCurrentDate();
        this.updateDateLimits();
        this.getDataForTheSelects();

        this.toDateMax = moment();

        this.form.get('startDate')?.valueChanges.subscribe(() => {
            this.updateDateLimits();
        });

        this.form.get('endDate')?.valueChanges.subscribe(() => {
            this.updateDateLimits();
        });

        this.typeGlobal = this.roleManager.isPharma();
    }

    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
        this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());

             // Order Alpabetically label and text uppercase
             this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            //  this.selectOptionsInsitutions.map((a) =>{
            //      a.label = a.label.toUpperCase();
            //  })
             
            if (this.selectOptionsInsitutions) {
                const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
                );
                if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                    (item) => item.value !== ''
                );
                }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
        });
    }
        
    updateDateLimits(): void {
        this.fromDateMax = moment(this.form.controls['endDate'].value).subtract(
            3,
            'M'
        );
        this.toDateMin = moment(this.form.controls['startDate'].value).add(3, 'M');
    }
    setCurrentDate(): void {
        const currentDate = new Date();

        this.form.patchValue({ specificDay: currentDate });
    }

    setMonthAndYear(
        normalizedMonthAndYear: _moment.Moment,
        datepicker: MatDatepicker<_moment.Moment>,
        formControlName: string
    ) {
        const ctrlValue = this.form.get(formControlName)?.value;

        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());

        this.form.patchValue({ [formControlName]: ctrlValue });
        datepicker.close();
    }

    setListenerSelectDay(): void {
        this.form.get('intervalType')?.valueChanges.subscribe((value) => {
            if (value === 'specificDay') {
                this.showSpecificDaySelect = true;
                this.showIntervalDay = false;
            } else if (value === 'intervalDay') {
                this.showSpecificDaySelect = false;
                this.showIntervalDay = true;
            }
        });
    }

    toggleSelectSpecificDay(): void {
        this.showSpecificDaySelect = !this.showSpecificDaySelect;
        if (!this.showSpecificDaySelect) {
            this.form.patchValue({
                specificDay: moment(),
            });
        }
    }

    toggleSelectIntervalDay(): void {
        this.showIntervalDay = !this.showIntervalDay;
        if (!this.showIntervalDay) {
            this.form.patchValue({
                startDate: moment(),
                endDate: moment(),
            });
        }
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate } = this.getFormData();

            this.setInfoRP(specificDay, startDate, endDate, true);

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPolimedication
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    getFormData(): {
        specificDay: string;
        startDate: string;
        endDate: string;
    } {
        const specificDay = this.dateService.formatDateToString(
            this.form.get('specificDay')?.value
        );
        const startDate = this.dateService.formatDateToString(
            this.form.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.form.get('endDate')?.value
        );

        return { specificDay, startDate, endDate };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate } = this.getFormData();

            this.setInfoRP(specificDay, startDate, endDate);
            this.reportService.handlePrintClick(this.reportDataPolimedication);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate } = this.getFormData();
            this.setInfoRP(specificDay, startDate, endDate);
            this.reportService.handlePdfClick(this.reportDataPolimedication);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { specificDay, startDate, endDate } = this.getFormData();

            this.setInfoRP(specificDay, startDate, endDate);
            this.reportService.handleExcelClick(this.reportDataPolimedication);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(specificDay: string, startDate: string, endDate: string, isPreview: boolean = false): void {
        let startDateRP;
        let endDateRP;
        let rp;
        // const currenInstitution = this.institutionService.getCurrentInstitution();


        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id

        if (this.form.controls['intervalType'].value == 'specificDay') {
            startDateRP = specificDay;
            endDateRP = this.dateService.transformDateTimeIncrementHours(specificDay);
            rp = JSON.stringify({
                LocaleId: '3082',
                StartDate: Utils.BUG_FixDateForTimeZone(moment(startDateRP).startOf('day').startOf('month').toDate())?.toISOString().split(".")[0],
                EndDate: Utils.BUG_FixDateForTimeZone(moment(endDateRP).endOf('day').endOf('month').toDate())?.toISOString().split(".")[0],
                InstitutionId: String(currentInstitution),
                ReportType: '1',
                PatientId: null,
                IncludeNoBlister: 'false',
            });
            this.reportDataPolimedication = reportDataPolimedication;
        } else {
            startDateRP = Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').startOf('month').toDate())?.toISOString().split(".")[0];
            endDateRP = Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').endOf('month').toDate())?.toISOString().split(".")[0];
            rp = JSON.stringify({
                LocaleId: '3082',
                StartDate: startDateRP,
                EndDate: endDateRP,
                InstitutionId: String(currentInstitution),
            });

            this.reportDataPolimedication = reportDataPolimedicationByMonth;
        }
        this.reportDataPolimedication.data.rp = rp;
        if (isPreview) this.reportDataPolimedication.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataPolimedication.dataDownloadPDF.rp = rp;
        this.reportDataPolimedication.dataDownloadExcel.rp = rp;
        this.reportDataPolimedication.dataRenderReport.rp = rp;

        const now = moment();
        this.reportDataPolimedication.dataDownloadPDF.dn = this.reportDataPolimedication.dataRenderReport.dn =
            `Reporte_indice_polimedicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataPolimedication.dataDownloadExcel.dn =
            `Reporte_indice_polimedicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
