<mat-dialog-content class="edit-re-modal-container" >
    <h3 class="title">{{ isEdit ? 'Editar RE' : 'Agregar RE'}}</h3>
    <div class="content">
        <div>Paciente: {{patient.PatientName}} ({{patient.PatientNumber}})</div>
        <mat-form-field>
            <mat-label>Número RE</mat-label>
            <input
                matInput
                type="number"
                [(ngModel)]="re.REFix"
                required
            >
        </mat-form-field>

        <mat-radio-group *ngIf="!create" [(ngModel)]="re.IsRE2">
            <mat-radio-button [value]="false">RE Crónica</mat-radio-button>
            <mat-radio-button [value]="true">RE Aguda</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="!re.IsRE2 && !re.Forget && !create">
            <mat-label>Próxima dispensación</mat-label>
            <input matInput 
                [matDatepicker]="datepicker"
                [(ngModel)]="re.NextDispenseDate"
            >
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
            </mat-datepicker>
        </mat-form-field>

        <mat-checkbox *ngIf="!create" [(ngModel)]="re.Forget">Desactivar</mat-checkbox>

    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="center" class="edit-re-modal-buttons">
    <button
      mat-raised-button
      color="primary"
      tabindex="1"
      (click)="onConfirmClick()"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      Cancelar
    </button>
  </mat-dialog-actions>
  