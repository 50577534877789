<div *ngIf="user != undefined && locales != undefined" class="container-fluid">
  <div class="profile">
    <app-form-detail
      [title]="user.nam"
      [userData]="user"
      [canSave]="true"
      [collapsedLayout]="collapsedProfile"
      [aditionalActions]="getAditionalActions()"
      [formChanged]="formChanged"
      [disabledAllActions]="false"
      (saveChanges)="save()"
      [type]="'users'"
      [disabledAllActions]="!isEdit"
      (collapseStatus)="collapsedProfile = $event">
    </app-form-detail>
  </div>

  <div class="content" [ngClass]="{'full-width': collapsedProfile}">
    <mat-tab-group animationDuration="0ms"
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="tabIndexChange($event)"
    >
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          {{ tab.label }}
        </ng-template>
        <ng-container [ngSwitch]="tab.form">
          <!-- General -->
          <ng-container *ngSwitchCase="'general'">
            <app-admin-user-general
              [user]="user"
              [locales]="locales"
              (formChanged)="formChanged = $event"
              (onResetPassword)="resetPassword()"
              (onChangePassword)="changePassword()"
              [canResetPassword]="Utils.hasPermission(Permission.USERS)().includes(PermissionType.WRITE)"
              [canChangePassword]="isMe() || Utils.hasPermission(Permission.USERS)().includes(PermissionType.WRITE)"
            >
            </app-admin-user-general>
          </ng-container>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
