import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionUpdateAPI } from '@interfaces/institution/institution-edit.interface';
import {
  Institution,
  InstitutionAPI,
  InstitutionByCA,
  InstitutionEditAPI,
  InstitutionEditEmpty,
  InstitutionEditForm,
  InstitutionSelectCondition,
  InstitutionSelectFilter,
  InstitutionType,
  InstitutionTypeAPI,
  UserQlikInstitution,
} from '@interfaces/institution/institution.interface';
import { CallsService } from '@services/api/calls.service';
import { BehaviorSubject } from 'rxjs';
import { InstitutionForm } from '@interfaces/institution/institution-edit.interface';
import { UserType } from '@enums/user-type';

@Injectable({
  providedIn: 'root',
})
export class InstitutionsService {
  private _institutionList: InstitutionAPI[] = [];
  public institutionList: BehaviorSubject<InstitutionAPI[]> =
    new BehaviorSubject<InstitutionAPI[]>([]);

  private _institutionTypeList: InstitutionTypeAPI[] = [];
  public institutionTypeList: BehaviorSubject<InstitutionTypeAPI[]> =
    new BehaviorSubject<InstitutionTypeAPI[]>([]);

  private _institutionEdit: InstitutionEditAPI;
  public institutionEdit: BehaviorSubject<InstitutionEditAPI> =
    new BehaviorSubject<InstitutionEditAPI>(new InstitutionEditEmpty());

  constructor(private calls: CallsService, private router: Router) {}

  clearStoredData(): void {
    this._institutionList = [];
    this.institutionList.next([]);
    this._institutionTypeList = [];
    this.institutionTypeList.next([]);
    this._institutionEdit = new InstitutionEditEmpty();
    this.institutionEdit.next(new InstitutionEditEmpty());
  }

  transformInstitutionAPItoApp(institution: InstitutionAPI): Institution {
    return new Institution(institution);
  }

  transformInstitutionTypeAPItoApp(
    institutionType: InstitutionTypeAPI
  ): InstitutionType {
    return new InstitutionType(institutionType);
  }

  transformInstitutionTypeToSelectGroup(
    institutionTypes: InstitutionType[]
  ): FilterSelect[] {
    const options = institutionTypes.map((i: InstitutionType): FilterOption => {
      return {
        id: i.id.toString(),
        value: i.name,
      };
    });
    return [{ name: 'Tipo', filter: options }];
  }

  tranformInstitutionTYpeToOption(
    institutionTypes: InstitutionType[]
  ): FilterOption[] {
    return institutionTypes.map((i: InstitutionType): FilterOption => {
      return {
        id: i.id.toString(),
        value: i.name,
      };
    });
  }

  transformInstitutionEditToForm(
    institution: InstitutionEditAPI
  ): InstitutionEditForm {
    return new InstitutionEditForm(institution);
  }

  transformInstitutionPrefixFarmaticToSelectOptions(prefix: {
    Prefix: string;
  }): SelectOption {
    return {
      value: prefix.Prefix,
      label: prefix.Prefix,
    };
  }

  getInstitutionEdit(id: number): void {
    this.calls.getInstitutionByID(id).subscribe({
      next: (res) => {
        this._institutionEdit = res.payload;
        this.institutionEdit.next(this._institutionEdit);
      },
      error: () => {
        this.calls.openSnack(
          'Error al obtener los centros (datos de edición)'
        );
      },
    });
  }

  getInstitutionsByFilter(filter: string): void {
    this.calls.getInstitutionsListAPIByFilter(filter).subscribe({
      next: (res) => {
        this._institutionTypeList = res.payload;
        this.institutionTypeList.next(this._institutionTypeList);
      },
      error: () => {
        this.calls.openSnack('Error al obtener los centros');
      },
    });
  }
  getInstitutions(force = false, idInstitution: number | null): void {
    if (!this._institutionList.length || force) {
      this.calls.getInstitutionsListAPI(idInstitution).subscribe({
        next: (res) => {
          this._institutionList = res.payload;
          this.institutionList.next(this._institutionList);
        },
        error: () => {
          this.calls.openSnack('Error al obtener los centros');
        },
      });
    }
  }

  getInstitutionsType(force = false): void {
    if (!this._institutionTypeList.length || force) {
      this.calls.getInstitutionsTypesAPI().subscribe({
        next: (res) => {
          this._institutionTypeList = res.payload;
          this.institutionTypeList.next(this._institutionTypeList);
        },
        error: () => {
          this.calls.openSnack(
            'Error al obtener los tipos referentes a instituciones'
          );
        },
      });
    }
  }

  getInstitutionsConditions(
    selectedFilters: InstitutionSelectFilter,
    type: string
  ): InstitutionSelectCondition {
    let pharmacyCondition = true;
    let hospitalCondition = true;
    let residencyCondition = true;
    let otherCondition = true;

    if (!selectedFilters.pharmacy) {
      pharmacyCondition = !type.includes('farmacia');
    }

    if (!selectedFilters.hospital) {
      hospitalCondition = !type.includes('hospital');
    }

    if (!selectedFilters.residency) {
      residencyCondition = !type.includes('residencia');
    }

    if (!selectedFilters.other) {
      otherCondition =
        type.includes('farmacia') &&
        type.includes('hospital') &&
        type.includes('residencia');
    }
    return {
      pharmacyCondition,
      hospitalCondition,
      residencyCondition,
      otherCondition,
    };
  }

  getSelectedFilters(filterValueType: SelectOption[]): InstitutionSelectFilter {
    let selectedFilters = {
      pharmacy: false,
      hospital: false,
      residency: false,
      other: false,
    };

    filterValueType.forEach((filter: SelectOption) => {
      if (filter.value === 'Farmacia') {
        selectedFilters.pharmacy = true;
      }

      if (filter.value === 'Hospital') {
        selectedFilters.hospital = true;
      }

      if (filter.value === 'Residencia') {
        selectedFilters.residency = true;
      }

      if (filter.value === 'Otro') {
        selectedFilters.other = true;
      }
    });

    return selectedFilters;
  }

  transformInstitutionFormToUpdateInstitution(
    institution: InstitutionForm
  ): InstitutionUpdateAPI {
    return new InstitutionUpdateAPI(institution);
  }

  updateInstitution(institution: InstitutionForm, institutionId: number | null, excludedMedicines: SelectOption[], notReplaceableMedicines: SelectOption[]) {
    let data = this.transformInstitutionFormToUpdateInstitution(institution);
    
    data.Id = institutionId;

    if ( institutionId ) {
      data.ib.InstitutionId = institutionId;
    }

    data.raIgnoreMedTransl = notReplaceableMedicines.map(item => { return { "MedicineId": item.value, "MedicineName": item.label, "Selected": false} })
    data.raStop = excludedMedicines.map(item => { return { "MedicineId": item.value, "MedicineName": item.label, "Selected": false} })

    data.raIgnoreMedTransl
    .filter(item => item.MedicineName)
    .forEach(item => {
      if ( Array.isArray(institution.automaticReposition.noSustituteMedication) && institution.automaticReposition.noSustituteMedication.some(subItem => subItem === item.MedicineId) ) {
        item.Selected = true;
      }
    })

    data.raStop
    .filter(item => item.MedicineName)
    .forEach(item => {
      if ( Array.isArray(institution.automaticReposition.excludedMedication) && institution.automaticReposition.excludedMedication.some(subItem => subItem === item.MedicineId) ) {
        item.Selected = true;
      }
    })
    
    const dataString = JSON.stringify(data);

    this.calls.insertUpdateInstitution(dataString).subscribe({
      next: (data) => {
        if ( !institutionId ) {
          institutionId = data.payload[0].Column1;
        }

        this.calls.openSnack('Se han guardado los cambios correctamente', 'Ok');

        const inst = localStorage.getItem('current_institution');
        if ( Number(inst) === institutionId ) {
          localStorage.setItem('current_institution_allow_fgp', institution.otherData.allowFgp ? 'true' : 'false');
        }

        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        this.router
          .navigate([`/global-eye/institutions/edit/${institutionId}`], { queryParams: queryParams, })
          .then(() => {
            window.location.reload();
          });
      },
      error: (error) => {
        if (
          error.message.includes(
            'No se puede insertar una fila de clave duplicada en el objeto'
          )
        ) {
          this.calls.openSnack('Error clave duplicada del objeto');
        } else {
          this.calls.openSnack(
            'Error al obtener los centros (datos de edición)'
          );
        }
      },
    });
  }

  // Qlik
  getQlikUsers() {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.getQlikUsersAPI().subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            res.payload.BiUsers = res.payload.BiUsers.map((user: any) => {
              return {
                id: user.Id,
                biAccess: user.BiAccess,
                institutions: user.Institutions,
                nam: user.UserName,
              }
            });

            res.payload.QuantumUsers = res.payload.QuantumUsers.map((user: any) => {
              return {
                id: user.Id,
                biAccess: user.BiAccess,
                institutions: user.Institutions,
                nam: user.UserName,
              }
            });

            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al obtener los usuarios'
          );
          reject();
        },
      });
    });
  }
  getQlikInstitutionListByCA() {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.getQlikInstitutionListByCAAPI().subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al obtener las instituciones por comunidad autónoma'
          );
          reject();
        },
      });
    });
  }
  getQlikUserInstitutions(userId: number, userType: UserType = UserType.Quantum) {
    return new Promise<UserQlikInstitution>((resolve, reject) => {
      this.calls.getQlikUserInstitutionsAPI(userId, userType).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al obtener las instituciones por usuario'
          );
          reject();
        },
      });
    });
  }
  saveQlikUserInstitutions(userId: number, institutions: number[], userType: UserType = UserType.Quantum) {
    return new Promise<UserQlikInstitution>((resolve, reject) => {
      this.calls.saveQlikUserInstitutionsAPI(userId, institutions, userType).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al guardar institutiones de usuario'
          );
          reject();
        },
      });
    });
  }
  toggleQlikAccess(userId: number, value: boolean, userType: UserType = UserType.Quantum) {
    return new Promise<UserQlikInstitution>((resolve, reject) => {
      this.calls.toggleQlikAccessAPI(userId, value, userType).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al cambiar permisos de usuario'
          );
          reject();
        },
      });
    });
  }
  createBiUser(userName: string, password: string, biAccess: boolean) {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.createBiUserAPI(userName, password, biAccess).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al crear usurio BI'
          );
          reject();
        },
      });
    });
  }
  updateBiUser(id: number, userName: string, password: string, biAccess: boolean) {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.updateBiUserAPI(id, userName, password, biAccess).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al modificar usurio BI'
          );
          reject();
        },
      });
    });
  }
  deleteBiUser(id: number) {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.deleteBiUserAPI(id).subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al eliminar usurio BI'
          );
          reject();
        },
      });
    });
  }
  getQlikOTT() {
    return new Promise<InstitutionByCA[]>((resolve, reject) => {
      this.calls.getQlikOTTAPI().subscribe({
        next: (res) => {
          if ( !res.header.HasErrors ) {
            resolve(res.payload);
          } else {
            reject();
          }
        },
        error: () => {
          this.calls.openSnack(
            'Error al obtener acceso a BI'
          );
          reject();
        },
      });
    });
  }
}
