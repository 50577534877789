import { InstitutionType } from '@interfaces/institution/institution.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardsComponent } from '../cards/cards.component';
import { Router } from '@angular/router';

import { CardAction } from '@interfaces/card-actions';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { Institution } from '@interfaces/institution/institution.interface';
import { TableColumn } from '@interfaces/table.interface';
import { InstitutionsService } from '@services/institutions/institutions.service';
import {
  actionsInstitution,
  routeInstitutions,
} from '@services/mocks/institution';
import { Subscription } from 'rxjs';
import { InstitutionService } from '@services/institution.service';
import { FilterService } from '@services/filter/filter.service';
import { LoadingService } from '@services/loading.service';
import { PaginationService } from '@services/pagination/pagination.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss', '../../../../../../../src/app/components/base/list/list.component.scss'],
})
export class ListsComponent extends CardsComponent implements OnInit, OnDestroy {
  

  constructor(
    protected override router: Router,
    protected override institutionsService: InstitutionsService,
    protected override filterService: FilterService,
    protected override institutionService: InstitutionService,
    protected override loadingService: LoadingService,
    protected override paginationService: PaginationService
  ) {
    super(
      router,
      institutionsService, 
      filterService, 
      institutionService,
      loadingService,
      paginationService
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
