import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SelectOption } from '@interfaces/input-select-option.interface';

@Component({
  selector: 'app-modal-patient-incidents-list',
  templateUrl: './modal-patient-incidents-list.component.html',
  styleUrls: ['./modal-patient-incidents-list.component.scss']
})
export class ModalPatientIncidentsListComponent {

  public patients: any[] = [];
  public incidentSubtypes: SelectOption[] = [];
  public canSign: boolean = false;

  get incidentCount(): number {
    return this.patients.reduce((acc: number, patient: any) => {
      acc += (patient.value?.generalIncident?.length || 0);
      acc += patient.value?.spda?.reduce((acc2: number, item: any) => {
        acc2 += item.medicines.filter((medicine: any) => medicine.notAdministered?.value).length;
        return acc2;
      }, 0) || 0;
      acc += patient.value?.ne?.reduce((acc2: number, item: any) => {
        acc2 += item.medicines.filter((medicine: any) => medicine.notAdministered?.value).length;
        return acc2;
      }, 0) || 0;
      acc += patient.value?.sp?.reduce((acc2: number, item: any) => {
        acc2 += item.medicines.filter((medicine: any) => medicine.administered?.value).length;
        return acc2;
      }, 0) || 0;
      return acc;
    }, 0);
  }

  constructor(
    public dialogRef: MatDialogRef<ModalPatientIncidentsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.patients = data.patients;
    this.incidentSubtypes = data.incidentSubtypes;
    this.canSign = data.canSign;
  }

  closeModal() {
    this.dialogRef.close();
  }
  sign() {
    this.dialogRef.close(true);
  }

  patientHasGeneralIncidents(patient: any): boolean {
    return patient?.value?.generalIncident?.some((item: any) => !!item.incident.value);
  }
  patientHasSPDAIncidents(patient: any): boolean {
    return patient?.value?.spda?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value));
  }
  patientHasNEIncidents(patient: any): boolean {
    return patient?.value?.ne?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value));
  }
  patientHasSPIncidents(patient: any): boolean {
    return patient?.value?.sp?.some((item: any) => item.medicines.some((medicine: any) => medicine?.administered?.value));
  }

  getIncidenceSubtypeLabel(incidenceSubtypeId: number): string {
    return this.incidentSubtypes.find((item: SelectOption) => item.value === incidenceSubtypeId)?.label || '';
  }
}
