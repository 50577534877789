<div class="container-fluid" >
  <div class="page-title">
    PLANES
  </div>
    
  <section class="proc-plans">
    <div class="plans-views">
      <div class="plans-settings col-12" >
        
        <div class="sectionContainer left">
          <p class="subtitle">Opciones</p>

          <div class="container-mode col-12">
            <div class="status-mode col-6">
                <div class="capsule">
                    <div  class="active"
                    [ngClass]="{'right': optionsModeSeleced=== 1  }"
                    [ngStyle]="{
                      'width': 'calc((100% - (14px * ' + optionsMode.length + ')) / ' + optionsMode.length + ')',
                    
                    }"
                        >
                    </div>
                    <div class="container-switch">
                        <div class="item-switch"
                          (click)="selectedOption(option)"
                          *ngFor="let option of optionsMode ; let i = index">
                          <span [ngClass]="{'selected ': option.selected === 1, 'disabled ': !optionsTree}">{{option.name}}</span></div>
                    </div>
                </div>
            </div>  

            <div class="menu-export-wrapper"  *ngIf=" optionsModeSeleced === 1">
              <button mat-raised-button [matMenuTriggerFor]="menu" color="primary">
                Exportar
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadPDF()">PDF</button>
                <button mat-menu-item (click)="downloadExcel()">Excel</button>
              </mat-menu>
            </div>
          </div>

          <span class="plans-info" *ngIf="!isOptionsSelected && optionsModeSeleced == 0">
            <p>
              Seleccione las opciones deseadas a la derecha y oprima el botón de
              <span class="recharge">Recargar</span>
            </p>
            <button
              mat-raised-button
              color="primary"
              (click)="handleRechargeButton()"
            >
              Recargar
            </button>
          </span>
          <div class="tree" *ngIf="optionsModeSeleced === 0 && isOptionsSelected && dynamicData !== undefined">
            <app-tree
              [dynamicData]="dynamicData"
              (requestData)="requestTreeBranch($event)"
              (onItemChecked)="handleChangedOptions()"
            ></app-tree>
          </div>
          
          <div #previewList class="view-table" *ngIf="optionsModeSeleced === 1">
            <app-table
              *ngIf="plans.length > 0"
              [input]="plans"
              [canFilter]="false"
              [columns]="patientColumns"
              [filterBack]="false"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              tableType="default"
            ></app-table>
          </div>
        </div>
        <div class="sectionContainer right">
          <div *ngFor="let tab of tabs">
            
            <div class="mt-2">
      
              <!-- Content -->
              <app-proc-plans-form
                *ngIf="forms[tab.form]"
                [schema]="forms[tab.form].schema"
                [formChanged]="formChanged"
                [canGeneratePlan]="!((!isOptionsSelected && optionsModeSeleced == 0) || !optionsTreeSelected)"
              >
              </app-proc-plans-form>
            </div>
          </div>
          <span class="generate-plan-wrapper">
            <button
              mat-raised-button
              color="primary"
              (click)="downloadPlan()"
              [disabled]="(!isOptionsSelected && optionsModeSeleced == 0) || !optionsTreeSelected"
            >
              Generar plan
            </button>
          </span>
        </div>
      </div>
    </div>
  </section>
</div>