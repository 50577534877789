import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feature-tag',
  templateUrl: './feature-tag.component.html',
  styleUrls: ['./feature-tag.component.scss']
})
export class FeatureTagComponent {

  @Input() type: 'new' | 'pro';
  
}
