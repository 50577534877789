import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';
import { filter, take } from 'rxjs';
import { Utils } from '@json/src/app/Utils';

@Component({
    selector: 'app-patient-edit-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent extends DynamicFormComponent implements OnInit, OnDestroy {

    @Input() cipaPattern: string | undefined;
    buttonCancel: boolean = false
    deregistrationDateCancellable: boolean = true;

    constructor(
        protected override sortService: SortService,
        protected override cdr: ChangeDetectorRef
    ) {
        super(sortService, cdr);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        // Disable the deregistration date and reason fields if the reason is "Death"
        this.schema[2].fieldControl.valueChanges
            .pipe(take(1))
            .subscribe((data) => {
                if ( data.deregistrationReason === 0 ) {
                    // @ts-ignore
                    this.schema[2].childs[1].fieldControl.disable();
                    // @ts-ignore
                    this.schema[2].childs[2].fieldControl.disable();
                    this.deregistrationDateCancellable = false;
                }
            })

        this.subs.push(
            this.schema[2].fieldControl.valueChanges.subscribe((data) => {
                if (data.deregistrationDate !== null && data.deregistrationReason !== 0) {
                    this.buttonCancel = true
                }

                if ( data.deregistrationReason === 0 ) {
                    // @ts-ignore
                    this.schema[2].childs[1].maxDate = Utils.NOW;
                } else {
                    // @ts-ignore
                    this.schema[2].childs[1].maxDate = null;
                }
            })
        )

        // Get the CIPA field
        const cipa = this.schema.find(schema =>
            schema.childs?.some(child => child.label === "CIPA")
        )?.childs?.find(child => child.label === "CIPA");

        if (cipa) {
            // Uppercase the CIPA field
            this.subs.push(
                cipa.fieldControl.valueChanges
                .pipe(filter((val) => !!val))
                .subscribe((val) => {
                    cipa.fieldControl.setValue(val.toUpperCase(), { emitEvent: false, onlySelf: true });
                })
            );
        }

        this.schema.forEach(schema => schema.childs?.forEach(child => { this.fieldVisible(child, child) }));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    cancelDeregistrationDate() {

        this.schema.forEach(schema => schema.childs?.forEach(child => {
            if (child.label === 'Baja') {
                child.fieldControl.setValue(null);

            }
            if (child.label === 'Motivo de baja') {
                child.fieldControl.setValue(null);
            }
        }));
        this.buttonCancel = false
    }
    ngOnChanges(changes: SimpleChanges): void {
        /*
         * This is a workaround to set the validators of the CIPA field
         */

        // @ts-ignore
        if (changes.cipaPattern) {
            const privateInsurance = this.schema.find(schema =>
                schema.childs?.some(child => child.label === "Seguro privado")
            )?.childs?.find(child => child.label === "Seguro privado");

            const cipa = this.schema.find(schema =>
                schema.childs?.some(child => child.label === "CIPA")
            )?.childs?.find(child => child.label === "CIPA");

            cipa?.fieldControl.setValidators([
                Validators.required,
                // @ts-ignore
                Validators.pattern(this.cipaPattern),
            ])
            cipa?.fieldControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });

            privateInsurance?.fieldControl.valueChanges.subscribe((val) => {
                if (val != undefined && cipa?.fieldControl.value == undefined) {
                    cipa?.fieldControl.setValidators([]);
                    cipa?.fieldControl.setErrors(null);
                }
                else if (val == undefined) {
                    cipa?.fieldControl.setValidators([
                        Validators.required,
                        // @ts-ignore
                        Validators.pattern(this.cipaPattern),
                    ])
                }
                cipa?.fieldControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            });
            // privateInsurance?.fieldControl.valueChanges
            //     .pipe(
            //         skipWhile((value) => value === null),
            //         take(1)
            //     )
            //     .subscribe((value) => {
            //         if (!value || !!cipa?.fieldControl.value) {
            //             cipa?.fieldControl.setValidators([
            //                 Validators.required,
            //                 // @ts-ignore
            //                 Validators.pattern(this.cipaPattern),
            //             ])
            //             cipa?.fieldControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            //         } else {
            //             cipa?.fieldControl.setValidators([]);
            //             cipa?.fieldControl.setErrors(null);
            //         }
            //     });
        }
    }
}
