export const reportDataMedChanges = {
  data: {
    rpp: '/COROTA/MedChanges',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/MedChanges',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/MedChanges',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/MedChanges',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};
export const showFilters = [
  { label: 'Emblistados', formControlName: 'checkboxEmblistables' },
  { label: 'N/E', formControlName: 'checkboxNE' },
  { label: 'S/P', formControlName: 'checkboxSP' },
  { label: 'Pago', formControlName: 'checkboxPayment' },
  { label: 'Agudos', formControlName: 'checkboxAcute' },
];
