export const reportDataElectronicRecipesConsolidated = {
  data: {
    rpp: '/COROTA/ReReviewCons',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/ReReviewCons',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/ReReviewCons',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/ReReviewCons',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const reportDataElectronicRecipesByPatients = {
  data: {
    rpp: '/COROTA/ReReviewByPatients',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/ReReviewByPatients',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/ReReviewByPatients',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/ReReviewByPatients',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const reportDataElectronicRecipesByIssues = {
  data: {
    rpp: '/COROTA/ReReviewByIssues',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/ReReviewByIssues',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/ReReviewByIssues',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/ReReviewByIssues',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const reportDataElectronicRecipesStatus = {
  data: {
    rpp: '/COROTA/ReReviewStatus',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },
  dataDownloadPDF: {
    rpp: '/COROTA/ReReviewStatus',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/ReReviewStatus',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/ReReviewStatus',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};
