import { SelectOption } from "@interfaces/input-select-option.interface";
import { UserRole } from "../enums/user-role";

export const QUANTUM_ROLES: SelectOption[] = [
  { label: 'Adm', value: UserRole.Adm },
  { label: 'Incontinencia', value: UserRole.Incontinencia },
  { label: 'Medicacion', value: UserRole.Medicacion },
  { label: 'Due', value: UserRole.Due },
  { label: 'PDA', value: UserRole.PDA },
  { label: 'Reportes', value: UserRole.Reportes },
  { label: 'Revisor', value: UserRole.Revisor },
  { label: 'Admin', value: UserRole.Admin },
  { label: 'IPF', value: UserRole.IPF }
];

export const PHARMA_ROLES: SelectOption[] = [
  { label: 'Titular', value: UserRole.Titular },
  { label: 'Farmaceutico', value: UserRole.Farmaceutico },
  { label: 'Tecnico', value: UserRole.Tecnico },
  { label: 'Admin', value: UserRole.Admin }
];
