<div class="operation-bar" 
  [ngClass]="{'bad-solution': onListPage()}"
  [ngStyle]="{'display': onListPage() || medicine?.isEdit ? 'block' : 'none'}">
  <app-operation-bar
    [arrowNavitation]="showArrows()"
    [listMode]="true"
    [canDelete]="Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE) && onEditPage()"
    [canCreate]="Utils.hasPermission(Permission.VADEMECUM)().includes(PermissionType.WRITE)"
    [reportActions]="false"
    [customButtonToggle]="true"
    [customButtonIcon]="'indeterminate_check_box'"
    [customButtonIconTooltip]="'No prescribir'"
    [customButtonToggle2]="true"
    [customButtonIcon2]="'do_not_disturb'"
    [customButtonIconTooltip2]="'Obsoletos'"
    [customButtonToggle3]="allowFgp"
    [customButtonIcon3]="'folder_off'"
    [customButtonIconTooltip3]="'FGP'"
    [search]="true"
    [searchLabel]="'Búsqueda'"
    [filter]="true"
    [filterLabel]="'Filtro fortaleza'"
    [advancedFilters]="true"
    [dropdownFilter]="true"
    [dropdownOptions]="medicineFilters"
    [dropdownInitialSelect]="selectedFilters"
    [dropdownFilterLabel]="'Filtros'"
    [alertButton]="true"
    [totalElements]="totalElements"
    [currentElementIndex]="currentElementIndex"
    [showResults]="onEditPage() && resultsRefreshed"
    [filterResults]="filteredResults"
    (onAction)="onOperationBarAction($event)"
    (onFilter)="filter($event)"
    (onFilterResultSelected)="onFilterResultSelected($event)"
    [infoButton]="guiaPreferente ? {
        icon: 'public',
        label: 'Guía Preferente',
        url: guiaPreferente
    } : undefined"
    [otherFilters]="[{name: 'pa', label: 'Filtro P. Activos', control: paFilter}]"
  >
  </app-operation-bar>
</div>

<section class="content" 
  *ngIf="!initLoading" 
  [ngClass]="{'bad-solution': onEditPage() && medicine?.medicineId}">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</section>
