import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataPresciptionEval } from '@constants/reports/report-pescriptions-p-eval';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { PatientPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-pescriptions-p-eval',
    templateUrl: './report-pescriptions-p-eval.component.html',
    styleUrls: ['./report-pescriptions-p-eval.component.scss'],
})
export class ReportPescriptionsPEvalComponent {
    public reportDataPresciptionEval = reportDataPresciptionEval;
    public showPatientSelect: boolean = false;
    public showDepartmentSelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public selectedPE: boolean = true;
    public selectOptionsPatientsWithoutFilter: SelectOption[];

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService, 
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            cutOffDay: [moment(), Validators.required],
            checkboxControlPatient: [''],
            checkboxPE: [''],
            checkboxPrescrSTOPS: [{ value: '', disabled: true }],
            selectedPatient: [''],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Pendientes de evaluación`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Pendientes de evaluación`);

        this.typeGlobal = this.roleManager.isPharma();
        
        this.getDataForTheSelects();
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelecteFilterPE(): void {
        const checkboxPE = this.form.get('checkboxPE');
        const checkboxPrescrSTOPS = this.form.get('checkboxPrescrSTOPS');

        if (checkboxPE?.value === true) {
            checkboxPrescrSTOPS?.enable();
        } else {
            checkboxPrescrSTOPS?.setValue('');
            checkboxPrescrSTOPS?.disable();
        }
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
          });

        this.reportService
            .getRepParamsEditDataPrescriptionsPE(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsPatientsWithoutFilter = result.payload.pats.map((patient: PatientPharma) => { return { value: patient.id, label: patient.nam } });
            });
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { cutOffDay, selectedPatient, filterPE, filterPESTOPS } =
                this.getFormData();

            this.setInfoRP(cutOffDay, selectedPatient, filterPE, filterPESTOPS, true);

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPresciptionEval
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de corte');

            }
        }
    }

    getFormData(): {
        cutOffDay: string;
        selectedPatient: string;
        filterPE: string;
        filterPESTOPS: string;
    } {
        const cutOffDay = this.dateService.formatDateToString(
            this.form.get('cutOffDay')?.value
        );
        const filterPE = this.form.get('checkboxPE')?.value;
        const filterPESTOPS = this.form.get('checkboxPrescrSTOPS')?.value;
        const selectedPatient = this.form.get('checkboxControlPatient')?.value ? this.form.get('selectedPatient')?.value : '';

        return { cutOffDay, selectedPatient, filterPE, filterPESTOPS };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { cutOffDay, selectedPatient, filterPE, filterPESTOPS } =
                this.getFormData();

            this.setInfoRP(cutOffDay, selectedPatient, filterPE, filterPESTOPS);
            this.reportService.handlePrintClick(this.reportDataPresciptionEval);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de corte');

            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { cutOffDay, selectedPatient, filterPE, filterPESTOPS } =
                this.getFormData();
            this.setInfoRP(cutOffDay, selectedPatient, filterPE, filterPESTOPS);
            this.reportService.handlePdfClick(this.reportDataPresciptionEval);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de corte');

            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { cutOffDay, selectedPatient } = this.getFormData();

            this.setInfoRP(cutOffDay, selectedPatient, 'false', 'false');
            this.reportService.handleExcelClick(this.reportDataPresciptionEval);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de corte');

            }
        }
    }

    setInfoRP(
        cutOffDay: string,
        selectedPatient: string,
        filterPE: string,
        filterPESTOP: string,
        isPreview: boolean = false
    ): void {


      

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id


        const rp = JSON.stringify({
            LocaleId: '3082',
            InstitutionId: String(currentInstitution),
            PatientId: selectedPatient ? String(selectedPatient) : null,
            ReferenceDate: Utils.BUG_FixDateForTimeZone(moment(cutOffDay).startOf('day').toDate())?.toISOString().split(".")[0],
            ShowDayPE: filterPE || false,
            ShowDayPrescrSTOPS: filterPESTOP || false,
        });

        this.reportDataPresciptionEval.data.rp = rp;
        if (isPreview) this.reportDataPresciptionEval.data.dn = Utils.buildUniqueFileName + "_{0}.html"
        this.reportDataPresciptionEval.dataDownloadPDF.rp = rp;
        this.reportDataPresciptionEval.dataDownloadExcel.rp = rp;
        this.reportDataPresciptionEval.dataRenderReport.rp = rp;

        const now = moment();
        this.reportDataPresciptionEval.dataDownloadPDF.dn = this.reportDataPresciptionEval.dataRenderReport.dn =
            `Prescripciones_pendiente_estudio_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataPresciptionEval.dataDownloadExcel.dn =
            `Prescripciones_pendiente_estudio_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }
}
