<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de pronóstico'"
    [disabled]="!form.controls['selectedInstitution'].valid"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="filter-container" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona los centros"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions"
            [multipleSelect]="true">
          </app-form-field>
  
        </section>

        <section class="interval">
          <div class="subtitle">Tipo de reporte</div>
          <mat-radio-group formControlName="reportType">
            <mat-radio-button value="1"> Máquina </mat-radio-button>
            <mat-radio-button value="2"> Bandeja </mat-radio-button>
            <mat-radio-button value="3"> Todos </mat-radio-button>
          </mat-radio-group>
        </section>

        <section *ngIf="form.controls['reportType'].value === '2'">
          <mat-checkbox formControlName="checkboxIndividualPatient">
            Individualizado por pacientes
          </mat-checkbox>
        </section>

        <section
        class="interval"
          *ngIf="
            form.controls['reportType'].value == '1' ||
            form.controls['reportType'].value == '3'
          "
        >
          <mat-checkbox formControlName="checkboxUnlockedInterval">
            Sólo desbloqueados en intervalo
          </mat-checkbox>
        </section>

        <div class="checkboxes">
          <mat-checkbox formControlName="checkboxNoInventary">
            Sólo sin inventario
          </mat-checkbox>

          <mat-checkbox formControlName="checkboxOnlySelfManaged">
            Sólo autogestión
          </mat-checkbox>
        </div>

        <section class="interval">
          <div class="subtitle">Intervalo 
            <button
              *ngIf="form.controls['reportType'].value == '2' && checkboxsForecastDates"
              class="custom-icon-button reload"
              type="button"
              mat-icon-button
              matSuffix
              (click)="getForecastHistoryDate()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </div>

          <div
            *ngIf="form.controls['reportType'].value != '2'"
            class="interval-days"
          >
            <mat-form-field>
              <div class="label">Desde</div>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
              <div class="label">Hasta</div>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div
            *ngIf="
              form.controls['reportType'].value == '2' && checkboxsForecastDates
            "
          >
            <ul>
              <mat-radio-group class="intervalsdays" formControlName="checkboxsDateOnlyTray">
                <li
                  *ngFor="
                    let checkboxDate of checkboxsForecastDates.lastDates;
                    let index = index
                  "
                >
                  <mat-radio-button
                  class="listRadio"
                    [value]="checkboxDate"
                    [checked]="index == checkboxsForecastDates.lastDates.length - 1"
                  >
                    {{ checkboxDate | date : "dd/MM/yyyy" }}
                  </mat-radio-button>

                  <button
                  class="custom-icon-button"
                  *ngIf="index == checkboxsForecastDates.lastDates.length - 1"
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="delete()"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
                </li>
                <li>
                  <mat-radio-button    class="listRadio"  [value]="checkboxsForecastDates.nextDate">{{
                    checkboxsForecastDates.nextDate | date : "dd/MM/yyyy"
                  }}</mat-radio-button>
               
                <button
                  class="custom-icon-button reload"
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="reload()"
                >
                  <mat-icon>refresh</mat-icon>
                </button>
              </li>
              </mat-radio-group>
            </ul>
          </div>
        </section>

        <!-- Maquina y bandeja solo tienen acceso a esto -->
        
        <div class="subtitle">Exportar pedido a farmatic</div>

        <button
          mat-stroked-button
          class="buttonExport"
          title="Exportar"
          color="primary"
          [disabled]="!form.controls['selectedInstitution'].valid || form.controls['reportType'].value == '3'"
          (click)="handleExport()"
        >
          Exportar pedido
        </button>
      </form>
    </div>
  </app-report-select>
</ng-container>
