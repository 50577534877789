import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PAGE_SIZE_OPTIONS } from '@constants/options';
import {
  Medicine,
  MedicineSelectFilter,
} from '@interfaces/medicine/medicine.interface';
import { CallsService } from '@services/api/calls.service';
import { FilterService } from '@services/filter/filter.service';
import { LoadingService } from '@services/loading.service';
import { MedicinesService } from '@services/medicines.service';
import {
  actionsMedicine,
  medicineFilterSelect,
  medicineFilterSelected,
  routeMedicines,
} from '@services/mocks/medicine';
import { ModalService } from '@services/modal.service';
import { PaginationService } from '@services/pagination/pagination.service';
import { Subscription, debounceTime } from 'rxjs';

import { ListComponent } from '@json/src/app/components/base/list/list.component';
import { ColorLegendItem } from '@interfaces/color-legend-item.interface';
import { ColorLegendItemType } from '@json/src/app/enums/color-legend-item-type';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent extends ListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('cards', { static: true }) cards: ElementRef;

  public pageIndex: number = 0;
  public pageSize: number = 10;
  public lowValue: number = 0;
  public highValue: number = this.pageSize;
  public pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;
  private subs: Subscription[] = [];

  public actions = actionsMedicine;
  public medicines: Medicine[] = [];
  public currentData: Medicine[] = [];
  public currentFilter: string = '';

  public dueTime: number = 1000;
  public idDeleteMedicine: number;

  public isToggled: boolean = false;
  public msgNotFound: string =
    'No hay medicamentos que coincidan con la búsqueda';

  public defaultImg: string = '';
  public currentSearch: string = '';
  public inputLabel: string = 'Medicinas';

  public inputSearchLabel: string = 'Búsqueda';
  public inputFilterLabel: string = 'Filtrar';

  public loading: boolean = true;
  public routes: string = routeMedicines;

  public selectedFilters = medicineFilterSelected;
  public patientFilters = medicineFilterSelect;
  public formFilterFront: FormGroup = new FormGroup({
    search: new FormControl(''),
    select: new FormControl(this.selectedFilters),
  });
  public formFilterBack: FormGroup = new FormGroup({
    filter: new FormControl(''),
  });

  colorLegendItems: ColorLegendItem[] = [
    {
      type: ColorLegendItemType.Icon,
      description: 'Sin inventario / Suministro limitado',
      color: '#ba1a1a',
      data: {
        icon: 'info'
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'No emblistable',
      color: 'var(--color-flag-noembistable)',
      data: {
        icon: 'bookmark'
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'Alzheimer',
      color: 'var(--color-flag-alzheimer)',
      data: {
        icon: 'bookmark',
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'Unidosis',
      color: 'var(--color-flag-unitdose)',
      data: {
        icon: 'bookmark',
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'Cobertura sanitaria',
      color: 'var(--color-flag-cobert)',
      data: {
        icon: 'bookmark',
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'No prescribir',
      color: 'black',
      data: {
        icon: 'bookmark',
      }
    },
    {
      type: ColorLegendItemType.Icon,
      description: 'FGP',
      color: 'red',
      data: {
        icon: 'bookmark',
      }
    },
    {
      type: ColorLegendItemType.Background,
      description: 'Obsoleto',
      color: 'rgb(243,243,243)'
    }
  ]

  constructor(
    protected medicinesService: MedicinesService,
    protected filterService: FilterService,
    protected calls: CallsService,
    protected modalService: ModalService,
    protected router: Router,
    protected loadingService: LoadingService,
    protected paginationService: PaginationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.calculatePageSizeOptions();
    
    this.paginator._intl.itemsPerPageLabel = `${this.inputLabel} por página`;

    this.currentData.map((data) => {
      if (data.nstk == 0) {
        data.alert = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  calculatePageSizeOptions() {
    if ( this.cards ) {
        const layoutWidth = this.cards.nativeElement.offsetWidth;
        const layoutHeight = this.cards.nativeElement.offsetHeight - (this.onListMode() ? 52 : 0);
        const gridGap = this.onListMode() ? 0 : 8  // Grid gap from style in px
        const minElementWidth = this.onListMode() ? layoutWidth : 240 // Min element width is 15rem and document font-size is 16px (15*16)
        
        const columns = Math.floor((layoutWidth - gridGap) / (minElementWidth + gridGap));
        const rows = Math.floor((layoutHeight - gridGap) / ((this.onListMode() ? 51 : 94) + gridGap));

        this.pageSize = columns * rows;
        this.highValue = this.pageSize;

        this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3, Math.ceil(this.pageSize * 4 / 100) * 100];



    }
  }

  onListMode() {
    return this.router.url.includes("/medicines/list");
  }

  getPageEvent(event: PageEvent): PageEvent {
    const previousPageIndex = this.pageIndex;
    const pageSizeChanged = event.pageSize !== this.pageSize;

    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    const { lowValue, highValue, pageIndex, pageSize } =
      this.paginationService.getPageValues(
        previousPageIndex,
        this.lowValue,
        this.highValue,
        this.pageIndex,
        this.pageSize,
        this.pageIndex,
        pageSizeChanged
      );

    this.pageIndex = pageIndex;
    this.lowValue = lowValue;
    this.highValue = highValue;
    this.pageSize = pageSize;

    return new PageEvent();
  }

  toggle(): void {
    this.isToggled = !this.isToggled;
    this.applyFiltersFront();
  }

  applyFiltersFront(): void {
    const filterValue = this.formFilterFront.value['search'];
    this.currentSearch = filterValue;

    const filters = this.medicinesService.getFilters(this.formFilterFront);
    this.currentData = this.filterMedicines(
      this.medicines,
      filterValue,
      filters
    );
  }

  filterMedicines(
    medicines: Medicine[],
    filterValue: string,
    filters: MedicineSelectFilter
  ): Medicine[] {
    return medicines.filter((item) => {
      const values = Object.values(item);
      const conditions = this.medicinesService.getFilterConditions(
        item.flag,
        filters
      );

      return values.some(
        (val) =>
          val?.toString().toLowerCase().includes(filterValue.toLowerCase()) &&
          this.medicinesService.checkAllConditions(conditions)
      );
    });
  }

  // addFormFilterBackListener(): void {
  //   this.formFilterBack.valueChanges
  //     .pipe(debounceTime(this.dueTime))
  //     .subscribe((res) => {
  //       this.currentFilter = res.filter;

  //       this.subs.push(
  //         this.medicinesService.medicineList.subscribe((patients) => {
  //           this.medicines = patients.map((p) =>
  //             this.medicinesService.transformMedicineAPItoApp(p)
  //           );
  //           this.currentData = [...this.medicines];
  //         })
  //       );

  //       this.medicinesService.getMedicinesByFilter(this.currentFilter);
  //     });
  // }

  clearFiltersBack(): void {
    this.formFilterBack.patchValue({ filter: '' });
  }

  getColorHeader(flag: number): string {
    if (this.medicinesService.isObsolete(flag)) return 'black';
    return 'default';
  }
      
  isObsolete(medicine: Medicine): boolean {
    return this.medicinesService.isObsolete(medicine.flag);
  }

  isFGP(medicine: Medicine): boolean {
    return medicine.FGP
  }

  hasAlert(medicine: Medicine): boolean {
    return this.medicinesService.hasAlert(medicine.flag);
  }

  alertReason(medicine: Medicine): string {
    return this.medicinesService.alertReason(medicine.nstk);
  }

  handleDelete(id: number): void {
    this.idDeleteMedicine = id;
    this.modalService.openModalCallback(
      () => this.deleteMedicine(),
      '¿Está seguro de que desea eliminar el medicamento?'
    );
  }

  deleteMedicine(): void {
    this.medicinesService.deleteMedicine(this.idDeleteMedicine).subscribe(
      () => {
        this.calls.openSnack('Se ha eliminado del medicamento correctamente');
        this.modalService.reloadComponent(this.router);
        // this.getMedicineInfo();
      },
      (error) => {
        this.calls.openSnack(
          'No ha podido eliminarse el medicamento. Intentelo en otro momento'
        );
      }
    );
  }
}
