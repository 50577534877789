<div class="button" (click)="menuTrigger.openMenu()"
  [ngClass]="{'expanded': isExpanded}">
  <button 
    #menuTrigger="matMenuTrigger" 
    mat-fab
    color="primary"
    [matMenuTriggerFor]="menu" 
    aria-label="Institutions menu"
    (menuOpened)="onMenuOpen()"
  >
    {{getInitials()}}
  </button>

  <span class="institution" *ngIf="isExpanded">{{optionSelected?.name}}</span>
</div>

<mat-menu #menu="matMenu" class="institutions-menu">
  <div class="selected-institution">
    <span>{{optionSelected?.name}}</span>
  </div>

  <div class="select-filter">
    <input 
      #selectFilter
      type="text" 
      placeholder="Filtrar" 
      [formControl]="filterSelectFormControl" 
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    />
  </div>

  <button mat-menu-item 
    *ngFor="let institution of filteredInstitutionOptions"
    (click)="openConfirm(institution)"
    [ngClass]="{'selected': optionSelected === institution.value}">
    <span>{{ institution.label }}</span>
    <mat-icon *ngIf="optionSelected === institution.value">check</mat-icon>
  </button>
</mat-menu>