import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Location } from '@angular/common';

import { User } from '@interfaces/user/user.interface';
import { TabInterface } from '@interfaces/dynamic-form.interface';
import { UserRole } from '@json/src/app/enums/user-role';
import { CallsService } from '@services/api/calls.service';
import { GeneralComponent } from './general/general.component';
import { LoadingService } from '@services/loading.service';
import { AuthService } from '@services/auth.service';
import { InstitutionService } from '@services/institution.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ModalService } from '@services/modal.service';
import { ImageService } from '@services/image/image.service';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { Subscription } from 'rxjs';
import { FormDetailAction } from '@interfaces/form/form-detail';
import { FormGroup } from '@angular/forms';
import { RoleManagerService } from '@services/role-manager.service';

import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

    @ViewChild(GeneralComponent) userData: GeneralComponent;
    // loading: boolean = false;
    selectedTab = 0;
    navChange = false;

    tabs: TabInterface[] = [
        { label: 'General', form: 'general' }
    ];

    // User list
    user?: User = undefined;
    locales?: Array<{ lid: number, nam: string }> = undefined;
    formChanged = false;
    Utils = Utils;
    Permission = Permission;
    PermissionType = PermissionType;

    isEdit: boolean = true;

    public collapsedProfile: boolean = false;

    subs: Subscription[] = [];

    private newUserAditionalActions?: FormDetailAction[] = [
        {
            id: '4',
            name: 'CANCELAR FICHA',
            iconName: 'close',
            callback: () => { this.cancelNew() },
            customClass: ['cancel']
        },
    ];

    public onUserId: (userId: string | null) => void;
    public onUserCreated: (userId: string | null) => void;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private calls: CallsService,
        private loading: LoadingService,
        private router: Router,
        private modal: ModalService,
        private auth: AuthService,
        private image: ImageService,
        private loadingService: LoadingService,
        private roleManager: RoleManagerService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.route.queryParamMap.subscribe((data) => {
            const index = Number(data.get("tab"));

            if (!isNaN(index) && index != undefined) {
                this.selectedTab = index;
            }
        });
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Perfil de usuario`);
        this.gaService.pageView(`${window.location.href}`, `Perfil de usuario`);

        this.loadingService.start('Cargando Formulario...');
        this.user = undefined;

        this.subs.push(this.route.paramMap.subscribe(params => {
            if (params.has("id")) {
                const userId = Number(params.get('id'));
                this.calls.getUserEditData(userId).subscribe({
                    next: (data) => {
                        this.locales = data.locales;
                        this.user = data.usr;
                        if (this.onUserId) this.onUserId(data.usr.id.toString());
                        this.loadingService.stop();
                    },
                    error: (err) => console.error(err)
                });
            }
            else {
                this.calls.getUserEditData(0).subscribe({
                    next: (data) => {
                        this.locales = data.locales;
                        this.user = data.usr;
                        this.isEdit = false;
                        if (this.onUserId) this.onUserId(data.usr.id.toString());
                        this.loadingService.stop();
                    },
                    error: (err) => console.error(err)
                });
            }
        }));
        // this.user = this.router.getCurrentNavigation()?.extras.state?.user;
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    @HostListener('window:popstate', ["$event"])
    popstate(event: any) {
        this.navChange = true;
    }

    getAditionalActions() {
        if ( !this.isEdit ) {
            return this.newUserAditionalActions;
        } else {
            return [];
        }
    }

    tabIndexChange(index: number) {
        if (this.navChange) {
            this.navChange = false;
            return;
        }

        this.location.go(location.pathname, "tab=" + index);
    }

    save() {

        if ( this.roleManager.isPharma() && !this.pharmaFormValid() ) {
            return ;    // BREAK EXECUTION
        } else if ( !this.roleManager.isPharma() && !this.quantumFormValid() ) {
            return ;    // BREAK EXECUTION
        }

        const rawFormValue = this.userData.form.getRawValue();

        const roles = Object.keys(rawFormValue.role)
            .filter(key => rawFormValue.role[key] === true);

        // @ts-ignore
        const userRoles: UserRole[] = roles.map((role: string) => UserRole[role]);
        const corotaRoles = this.roleManager.translateUserRoles(userRoles);

        this.loading.start('Guardando');

        // @ts-ignore
        if (this.user.id != 0) {
            this.calls.updateUser(
                // @ts-ignore
                this.user.id,
                this.userData.form.value.name,
                this.userData.form.value.gender,
                this.userData.form.value.language,
                //@ts-ignore
                corotaRoles,
                (this.userData.form.value.selectedInstitution as Array<any>)?.map(x => x.id)
            ).subscribe({
                next: (data) => {
                    if ( !data.header.HasErrors ) {
                        this.calls.openSnack(
                            'Se han guardado los cambios correctamente',
                            'Ok'
                        );
                    }
                },
                error: (err) => console.error(err),
                complete: () => {
                    this.loading.stop();
                    this.formChanged = false;
                }
            });
        }
        else {
            this.calls.registerUser(
                this.userData.form.value.name,
                this.userData.form.value.username,
                this.userData.form.value.gender,
                this.userData.form.value.language,
                //@ts-ignore
                corotaRoles,
                (this.userData.form.value.selectedInstitution as Array<any>)?.map(x => x.id),
                this.roleManager.isPharma() ? null : undefined
            ).subscribe({
                next: (data) => {
                    this.router.navigate(['/global-eye/admin/users/edit/', data.uid], {queryParams: {
                        tempPwd: data.tempPwd
                    }});

                    this.onUserCreated(data.uid.toString());
                },
                error: (err) => {
                    this.loading.stop();
                    this.calls.openSnack("Error: " + err.error);
                },
                complete: () => {
                    this.loading.stop();
                    this.formChanged = false;
                }
            });
        }
    }

    quantumFormValid(): boolean {
        const rawFormValue = this.userData.form.getRawValue();
        const roles = Object.keys(rawFormValue.role).filter(key => rawFormValue.role[key] === true);
        
        if ( !roles.length ) {
            this.calls.openSnack('Debes seleccionar al menus un rol', 'X', 5000);
            return false;
        } else if ( this.userData.form.invalid ) {
            const controls = Object.keys(this.userData.form.controls)
                .filter(key => this.userData.form.controls[key].invalid)
                
            if ( controls.length > 1 ) {
                this.calls.openSnack('Existen errores en el formulario', 'X', 5000);
            } else {
                switch (controls[0]) {
                    case 'username': 
                        this.calls.openSnack('El usuario es obligatorio', 'X', 5000);
                    break;
                    case 'name': 
                        this.calls.openSnack('El nombre es obligatorio', 'X', 5000);
                    break;
                    case 'gender':
                        this.calls.openSnack('El género es obligatorio', 'X', 5000);
                    break;
                    case 'language': 
                        this.calls.openSnack('El idioma es obligatorio', 'X', 5000);
                    break;
                    case 'selectedInstitution':
                        this.calls.openSnack('Debes seleccionar al menos una institución', 'X', 5000); 
                    break;
                }
            }

            return false;
        } else {
            return true;
        }
    }

    pharmaFormValid(): boolean {
        const rawFormValue = this.userData.form.getRawValue();
        const roles = Object.keys(rawFormValue.role).filter(key => rawFormValue.role[key] === true);
        
        const controls = Object.keys(this.userData.form.controls)
            .filter(key => this.userData.form.controls[key].invalid)

        if ( !roles.length ) {
            this.calls.openSnack('Debes seleccionar al menus un rol', 'X', 5000);
            return false;
        } else if ( this.userData.form.invalid && 
            (
                (controls.length === 1 && controls[0] !== 'selectedInstitution') ||
                controls.length > 1
            )
        ) {
            if ( controls.length > 1 ) {
                this.calls.openSnack('Existen errores en el formulario', 'X', 5000);
            } else {
                switch (controls[0]) {
                    case 'username': 
                        this.calls.openSnack('El usuario es obligatorio', 'X', 5000);
                    break;
                    case 'name': 
                        this.calls.openSnack('El nombre es obligatorio', 'X', 5000);
                    break;
                    case 'gender':
                        this.calls.openSnack('El género es obligatorio', 'X', 5000);
                    break;
                    case 'language': 
                        this.calls.openSnack('El idioma es obligatorio', 'X', 5000);
                    break;
                }
            }

            return false;
        } else {
            return true;
        }
    }

    resetPassword() {
        if (this.user == undefined)return;

        const u = this.user;
        this.modal.openModalCallback(() => {
            this.loading.start();
            this.calls.resetUserPassword(u.id).subscribe({
                next: (data) => {
                    this.router.navigate(['/global-eye/admin/users/edit/', u.id], {queryParams: {
                        tempPwd: data
                    }});
                },
                error: (err) => console.error(err),
                complete: () => this.loading.stop()
            })
        }, "¿Está seguro de que desea resetear la contraseña del usuario?", "Resetear contraseña", false);
    }

    changePassword() {
        if (this.user == undefined || this.user.id != this.auth.loggedUserId) return;

        this.auth.changePassword();
    }

    isMe() {
        if (this.user == undefined) return false;
        return this.user.id == this.auth.loggedUserId;
    }

    cancelNew() {
        this.modal.openModalCallback(
            () => {
                this.router.navigate(["/global-eye/admin/users"])
            },
            '¿Está seguro de volver sin guardar?',
            'Cancelar Ficha'
        );
    }
}
