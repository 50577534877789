import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterOption } from '@interfaces/filter.interface';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {}

  comparer(option1: FilterOption, option2: FilterOption): boolean {
    if (option1 && option2) {
      return option1.id === option2.id;
    } else {
      return option2 === option2;
    }
  }

  getFilterGender(formReference: FormGroup): string {
    const selectedOptions = formReference.value['select'];

    const maleOption = selectedOptions.find(
      (option: FilterOption) => option.id === 'male'
    );
    const femaleOption = selectedOptions.find(
      (option: FilterOption) => option.id === 'female'
    );

    if (maleOption && !femaleOption) {
      return 'M';
    } else if (!maleOption && femaleOption) {
      return 'F';
    } else if (!maleOption && !femaleOption) {
      return 'X';
    } else {
      return '';
    }
  }

  getFilterOption(formReference: FormGroup, optionId: string): boolean {
    return formReference.value['select'].some(
      (option: FilterOption) => option.id === optionId
    );
  }

  getFilterSelfManaged(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'self_management');
  }

  getFilterMutuas(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'mutuas');
  }

  getFilterExitus(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'exitus');
  }

  getFilterBloqVacation(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'vacations');
  }

  getFilterBloqHospitalization(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'hospitalization');
  }

  getFilterNoEmbistable(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'no-emblistable');
  }

  getFilterAlzheimer(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'alzheimer');
  }

  getFilterRA(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'ra');
  }

  getFilterMEC(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'mec');
  }

  getFilterRO(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'ro');
  }

  getFilterCobert(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'cobert');
  }

  getFilterUnitDose(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'unitdose');
  }

  getFilterObsolete(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'obsolete');
  }

  getFilterNoInventary(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'no-inventary');
  }

  getFilterNoPrescription(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'no-prescription');
  }

  getFilterHospitalUse(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'hospital-use');
  }

  getFilterNone(formReference: FormGroup): boolean {
    return this.getFilterOption(formReference, 'none');
  }
}
