<ng-container class="report-custom-container"><app-report-select [title]="'Reportes de indicadores farmacológicos'"
        (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
        (excelClicked)="handleExcelClick()">
        <div slot="content"  class="container-options">
            <form [formGroup]="form">

                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
            
                  </section>

                <section class="filter-container">

                <p class="subtitle">Tipo de reporte</p>
                <mat-radio-group formControlName="typeReport" class="col-12">
                    <mat-radio-button value="numIEF"> IEF numérico </mat-radio-button>
                    <mat-radio-button value="evolutiveIEF">
                        Gráfico evolutivo de IEF
                    </mat-radio-button>
                    <mat-radio-button value="evolutiveIEFmg">
                        Gráfico de evolución en mg
                    </mat-radio-button>
                </mat-radio-group>
            </section>

            <section class="filter-container">
                <p class="subtitle marginTop">Filtrar por</p>

                <mat-checkbox (change)="toggleSelectPatient()" class="col-12"> 
                    Paciente 
                </mat-checkbox>
                <div class="col-12"  *ngIf="showPatientSelect">
                    <app-form-field 
                        class="col-6" 
                        label="" 
                        type="autocomplete" 
                        [options]="selectOptionsPatientsOptions" 
                        [fieldControl]="form.controls['selectedPatient'] | asFormControl">
                    </app-form-field>
                </div>
                
                <mat-checkbox (change)="toggleSelectMedicine()" class="col-12">
                    Grupo de medicina
                </mat-checkbox>
                <div class="col-5" *ngIf="showMedicineGroupSelect">
                    <app-form-field 
                        class="col-12" 
                        label="" 
                        type="select" 
                        [options]="selectOptionsGroupMedicineOptions" 
                        [fieldControl]="form.controls['selectedGroupMedicine'] | asFormControl"
                        (change)="onSelectMedicineGroupChange($event)">
                    </app-form-field>
                </div>

                <app-form-field
                    class="field col-7"
                    *ngIf="
                        form.controls['typeReport'].value === 'evolutiveIEFmg' &&
                        showMedicineGroupSelect &&
                        form.controls['selectedGroupMedicine'].value !== ''
                    "
                    [type]="'select'"
                    label="Si solamente desea mostrar algunos medicamentos, selecciónelos aquí"
                    [fieldControl]="form.controls['selectedMedicineSubType'] | asFormControl"
                    [options]="selectOptionsMedicineSubTypeOptions"
                    [multipleSelect]="true">
                </app-form-field>
            </section>

            <section class="filter-container">
                <p class="subtitle marginTop">Intervalo</p>
                <mat-form-field>
                    <div class="label">Desde</div>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [max]="fromDateMax" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker startView="year" (monthSelected)="
            setMonthAndYear($event, startDatePicker, 'startDate')
            "></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <div class="label">Hasta</div>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [min]="toDateMin"
                        [max]="toDateMax" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker startView="year"
                        (monthSelected)="setMonthAndYear($event, endDatePicker, 'endDate')">
                        <mat-calendar></mat-calendar>
                    </mat-datepicker>
                </mat-form-field>
            </section>

            </form>
        </div>
    </app-report-select>
</ng-container>
