import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient, PatientAPI } from '@interfaces/patient/patient.interface';
import { CallsService } from '@services/api/calls.service';
import { LoadingService } from '@services/loading.service';
import {
    routePatients,
    patientFilterSelect,
    patientFilterSelected
} from '@services/mocks/patient';
import { ModalService } from '@services/modal.service';
import { PatientsService } from '@services/patients.service';
import { PatientEditComponent } from '@shared/patient-edit/patient-edit.component';
import { Subscription, filter, skipWhile, take } from 'rxjs';
import { CardsComponent } from './cards/cards.component';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { FilterService } from '@services/filter/filter.service';
import { FilterOption } from '@interfaces/filter.interface';
import { InstitutionService } from '@services/institution.service';
import { ListsComponent } from './lists/lists.component';
import { CustomButtonFilterStatus } from '@enums/custom-button-filter-status';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-patients-quantum',
    templateUrl: './patients-quantum.component.html',
    styleUrls: ['./patients-quantum.component.scss'],
})
export class PatientsQuantumComponent implements OnInit, OnDestroy {
    public href: string = '';
    public route: string = routePatients;
    public nameButton: string = 'patientSaveButton';
    patient?: PatientEditComponent;

    public static currentData: Patient[] = [];
    private sub: any = {};
    nextPatient?: number = undefined;
    prevPatient?: number = undefined;
    Utils = Utils;
    Permission = Permission;
    PermissionType = PermissionType;
    public totalElements: number | undefined = undefined;
    public currentElementIndex: number | undefined = undefined;

    public patients: Patient[] = [];
    private filteredPatients: Patient[] = [];

    public filteredResults: any[] = [];

    public currentPatientsData: string;

    public patientFilters = patientFilterSelect;
    public selectedFilters = patientFilterSelected;

    public resultsRefreshed: boolean = false;

    cardsRef: CardsComponent;

    public initLoading: boolean = false;

    currentInstitutionId: number;

    loadingPatientsTimeout: any;

    queryMed: string = '';

    constructor(
        private institutionService: InstitutionService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private gaService: GoogleAnalyticsService,
        public filterService: FilterService,
        protected modalService: ModalService,
        protected patientsService: PatientsService,
        protected router: Router,
        protected calls: CallsService,
        protected loading: LoadingService,
        protected cdr: ChangeDetectorRef
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.queryMed = params['med'] || '';
        });
    }

    ngOnInit(): void {
        this.loading.start();
        this.initLoading = true;

        // Get all patients
        this.getPatients();

        this.sub.institution = this.institutionService.institutionChanged.subscribe(() => {
            this.getPatients();
            this.currentInstitutionId = this.institutionService.getCurrentInstitution();
        })

        this.titleService.setTitle(`Pacientes`)
        this.gaService.pageView(`${window.location.href}`, `Pacientes`);
    }
    ngOnDestroy(): void {
        this.sub.institution.unsubscribe();
        this.sub.patientList.unsubscribe();
    }

    getPatients() {
        if ( this.queryMed.trim().length > 0 ) {
            this.patientsService.getPatientsByFilter(this.queryMed);
        } else {
            this.patientsService.getPatients();
        }


        // clearTimeout(this.loadingPatientsTimeout);
        // this.loadingPatientsTimeout = setTimeout(() => {
        //     this.loading.stop();
        //     this.initLoading = false;
        // }, 1500);

        this.sub.patientList = this.patientsService.patientList
        .pipe(
            skipWhile((data) => data === undefined),
        )
        .subscribe((data) => {
            // @ts-ignore
            this.patients = data.map((p) =>
                this.patientsService.transformPatientAPItoApp(p)
            );

            this.filterByConfig(
                {
                    alert: false,
                    custom: false,
                    filter: "",
                    forceFilterRequest: false,
                    search: "",
                    select: [
                        //{id: 'mutuas', value: 'MUTUAS'},
                        //{id: 'vacations', value: 'Bloqueados por vacaciones/otro'},
                        //{id: 'self_management', value: 'Autogestión'},
                        //{id: 'none', value: 'Ninguno anterior'},
                        //{id: 'male', value: 'Hombre'},
                        //{id: 'female', value: 'Mujer'}
                    ]
                },
                this.patients,
                this.initLoading
            )

            if ( this.cardsRef ) {
                this.cardsRef.currentData = this.filteredPatients;
                this.cardsRef.currentSortMode = 'desc';
                this.cardsRef.sortBy('name');
                this.cardsRef.loading = false;
            }
            this.loading.stop();
            this.initLoading = false;

            this.cdr.detectChanges();
        });
    }

    changeView(event: MatButtonToggleChange): void {
        this.modalService.changeView(event, this.route, this.nameButton);
    }

    onOperationBarAction($event: MatButtonToggleChange) {
        switch( $event.value ) {
            case 'first': this.gotToByIndex(0); break;
            case 'last': this.gotToByIndex(this.filteredPatients.length - 1); break;
            case 'next': this.siguiente(); break;
            case 'prev': this.anterior(); break;
            case 'delete': this.borrar(); break;
            default: this.changeView($event);
        }
    }

    onActivate(componentRef: any) {
        if (componentRef instanceof PatientEditComponent) {
            this.resultsRefreshed = false;
            this.patient = componentRef;


            if (PatientsQuantumComponent.currentData.length <= 0) {

                if (componentRef.patientId == null) {

                    componentRef.onPatientId = (id) => {
                        this.updateControlButtons(id, this.filteredPatients);
                    };
                }
                else {
                    this.updateControlButtons(componentRef.patientId, this.filteredPatients);
                }
            }
            else {
                this.updateControlButtons(componentRef.patientId, this.filteredPatients);
            }
        }
        else if (componentRef instanceof CardsComponent) {
            componentRef.onCurrentDataUpdate = (data) => {
                PatientsQuantumComponent.currentData = data;
            };

            this.cardsRef = componentRef;
            this.cardsRef.currentData = this.filteredPatients;
            this.cardsRef.currentSortMode = 'desc';
            this.cardsRef.sortBy('name');
            this.cardsRef.loading = false;
        }
    }

    applyDefaultFilters(
        data: Patient[],
        filterValue: string = '',
        genderFilter: string = '',
        exitusSelectedFilter: boolean = false,
        bloqVacationSelectedFilter: boolean = true,
        bloqHospitalizationSelectedFilter: boolean = false,
    ): Patient[] {
        return data.filter((item) => {
            const values = Object.values(item);
            const exitusCondition = exitusSelectedFilter // if expedition !== null means that the person is dead.
                ? true
                : item?.expedition == null;
            const bloqVacationCondition = bloqVacationSelectedFilter
                ? true
                : item?.blq == 1 || item?.blq == 0 || item?.blq == null;
            const bloqHospitalCondition = bloqHospitalizationSelectedFilter
                ? item?.blq == 2 || item?.blq == 4
                : true;

            const genderCondition = item.gender
                ?.toString()
                .toLowerCase()
                .includes(genderFilter.toLowerCase());

            return values.some(
                (val) =>
                    val?.toString().toLowerCase().includes(filterValue.toLowerCase()) &&
                    genderCondition &&
                    exitusCondition &&
                    bloqHospitalCondition &&
                    bloqVacationCondition &&
                    (!false || (false && item.alert == true))
            );
        }).sort((a, b) => a.id - b.id);
    }

    updateControlButtons(id: string | null, data: Patient[]) {
        if (id != null) {
            const index = data.findIndex(v => String(v.id) == id);

            this.totalElements = data.length;
            this.currentElementIndex = index + 1;   // Because array starts on 0

            if (index >= 0) {
                this.nextPatient = data[index + 1]?.id;
                this.prevPatient = data[index - 1]?.id;
                this.cdr.detectChanges();
            }
        }
    }

    anterior(evt?: MatButtonToggleChange) {
        if ( this.prevPatient ) {
            const params = new URLSearchParams(location.search);
            let queryParams: any = {};
            params.forEach((value, key) => {
                queryParams[key] = value;
            });

            // this.router.navigate(["/global-eye/welcome"]).then((r) => {
            //     if (r) {
                    this.patient?.ngOnDestroy();
                    this.router.navigate([routePatients, 'edit', this.prevPatient], {
                        queryParams: queryParams,
                        state: {
                            ignore: false
                        }
                    }).then(() => this.patient?.ngOnInit())
            //     }
            // });
            if (evt != undefined) evt.source.checked = false;
        } else {
            this.router.navigate(['/global-eye/patients/']);
        }
    }

    siguiente(evt?: MatButtonToggleChange) {
        if ( this.nextPatient ) {
            const params = new URLSearchParams(location.search);
            let queryParams: any = {};
            params.forEach((value, key) => {
                queryParams[key] = value;
            });

            // this.router.navigate(["/global-eye/welcome"]).then((r) => {
            //     if (r) {
                    this.patient?.ngOnDestroy();
                    this.router.navigate([routePatients, 'edit', this.nextPatient], {
                        queryParams: queryParams,
                        state: {
                            ignore: false
                        }
                    }).then(() => this.patient?.ngOnInit())
            //     }
            // });
            if (evt != undefined) evt.source.checked = false;
        } else {
            this.router.navigate(['/global-eye/patients/']);
        }
    }

    gotToByIndex(index: number, evt?: MatButtonToggleChange) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        this.patient?.ngOnDestroy();
        this.router.navigate([routePatients, 'edit', this.filteredPatients[index].id], {
            queryParams: queryParams,
            state: {
                ignore: false
            }
        }).then(() => this.patient?.ngOnInit())

        if (evt != undefined) evt.source.checked = false;
    }

    showArrows() {
        return this.router.url.includes("/patients/edit");
    }

    onListPage() {
        return this.router.url.includes("/patients/cards") || this.router.url.includes("/patients/list");
    }

    onEditPage() {
        return this.router.url.includes("/patients/edit");
    }

    borrar(evt?: MatButtonToggleChange) {
        if (evt != undefined) evt.source.checked = false;
        this.modalService.openModalCallback(() => {
            this.loading.start("Eliminando...");
            this.calls.deletePatient(Number(this.patient?.patientId), 0).subscribe({
                next: (data) => {
                    this.loading.stop()
                    if (data.payload == -1) {
                        this.modalService.openModalInfo("No se puede eliminar porque se perderán sus prescripciones y el historial de consumo almacenado.", "Este paciente tiene prescripciones vinculadas.");
                    }
                    else {
                        this.siguiente(undefined);
                    }
                },
                complete: () => {
                    this.getPatients();
                    this.loading.stop()
                }
            });
        }, "¿Está seguro de que desea eliminar el paciente?", "Eliminar paciente", false)
    }

    onFilterResultSelected($event: any) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        // this.router.navigate(["/global-eye/welcome"]).then((r) => {
        //     if (r) {
                this.patient?.ngOnDestroy();
                this.router.navigate([`${this.route}/edit/${$event}`], {
                    queryParams: queryParams,
                    state: {
                        ignore: false
                    }
                }).then(() => this.patient?.ngOnInit())
        //     }
        // });
    }

    filter($event: any) {
        if ( $event.filter.trim().length > 0 || $event.forceFilterRequest ) {
            this.sub.filter?.unsubscribe();

            // Request patients by filter
            this.patientsService.getPatientsByFilter($event.filter);

            this.sub.filter = this.patientsService.patientList
            .subscribe((p) => {
                /*
                 * Patients by filter query
                 */
                this.patients = !!p ? p.map((item) => this.patientsService.transformPatientAPItoApp(item)) : [];
                this.filterByConfig($event, this.patients);
            })
        } else {
            this.filterByConfig($event, this.patients);
        }
    }

    filterByConfig(filters: any, patients: Patient[], showResults: boolean = true) {
        /*
         * Filter patients by selected filters
         */
        const bloqVacationSelectedFilter = filters.select.find((item: FilterOption) => item.id === 'vacations') ? true : false;
        const bloqHospitalizationSelectedFilter =  filters.select.find((item: FilterOption) => item.id === 'hospitalization') ? true : false;
        const mutuas = filters.select.find((item: FilterOption) => item.id === 'mutuas') ? true : false;
        const selfManaged = filters.select.find((item: FilterOption) => item.id === 'self_management') ? true : false;
        const exitusSelectedFilter = filters.custom

        let genderFilter = '';
        const maleOption = filters.select.find((option: FilterOption) => option.id === 'male');
        const femaleOption = filters.select.find((option: FilterOption) => option.id === 'female');

        if (maleOption && !femaleOption) {
            genderFilter = 'M';
        } else if (!maleOption && femaleOption) {
            genderFilter = 'F';
        } else if (!maleOption && !femaleOption || maleOption && femaleOption) {
            genderFilter = 'X';
        }

        let mutuasPatients: Patient[] = patients.filter(p => p.mm == 1 && (!exitusSelectedFilter ? p.expedition == null : p.expedition !== null));
        let bloqHospitalizationPatients: Patient[] = patients.filter(p => p.blq != undefined && p.blq == 2 && (!exitusSelectedFilter ? p.expedition == null : p.expedition !== null));
        let bloqVacationPatients: Patient[] = patients.filter(p => p.blq != undefined && (p.blq == 1 || p.blq == 4) && (!exitusSelectedFilter ? p.expedition == null : p.expedition !== null));
        let selfManagedPatients: Patient[] = patients.filter(p => p.sm && (!exitusSelectedFilter ? p.expedition == null : p.expedition !== null));

        if ( mutuas || bloqHospitalizationSelectedFilter || bloqVacationSelectedFilter || selfManaged ) {
            this.filteredPatients = [];

            if ( mutuas )
                this.filteredPatients = [...new Set([...this.filteredPatients, ...mutuasPatients])];
            if ( bloqHospitalizationSelectedFilter )
                this.filteredPatients = [...new Set([...this.filteredPatients, ...bloqHospitalizationPatients])];
            if ( bloqVacationSelectedFilter )
                this.filteredPatients = [...new Set([...this.filteredPatients, ...bloqVacationPatients])];
            if ( selfManaged )
                this.filteredPatients = [...new Set([...this.filteredPatients, ...selfManagedPatients])];
        } else {
            this.filteredPatients = patients;
        }

        if ( exitusSelectedFilter === CustomButtonFilterStatus.Include ) {
            this.filteredPatients = [...new Set([...this.filteredPatients, ...this.filteredPatients.filter(p => p.expedition == null)])];
        } else if ( exitusSelectedFilter === CustomButtonFilterStatus.ShowOnly ) {
            this.filteredPatients = this.filteredPatients.filter(p => p.expedition != null);
        } else {
            this.filteredPatients = this.filteredPatients.filter(p => p.expedition == null);
        }

        this.filteredPatients = this.filteredPatients.filter((patient: Patient) =>
            genderFilter !== 'X' ? patient.gender?.toString().toLowerCase().includes(genderFilter.toLowerCase()) : true &&
            (filters.alert ? patient.alert : true) &&
            (filters.search.trim().length == 0 ? true : Object.values(patient).some((val) => 
                filters.search.trim().length > 0 && 
                filters.search.split(' ').every((search: string) => {
                    return val
                        ?.toString()
                        .toLowerCase()
                        .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                        .includes(search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
                })
            ))
        )


        // Sort filtered result
        this.filteredPatients = this.filteredPatients.sort((a: Patient, b: Patient) => a.id - b.id);

        // Update cards patients
        if ( this.cardsRef ) {
            this.cardsRef.currentData = this.filteredPatients;
            this.cardsRef.paginator.firstPage();
            this.cardsRef.currentSortMode = 'desc';
            this.cardsRef.sortBy('name');
        }

        this.filteredResults = this.filteredPatients.map((patient: Patient) => {
            return { id: patient.id, label: patient.name }
        });

        this.currentPatientsData = this.filteredPatients
            .map((patient: Patient) => patient.id)
            .join(',');

        if ( showResults ) {
            this.resultsRefreshed = false;
            this.cdr.detectChanges();
            this.resultsRefreshed = true;
            this.cdr.detectChanges();
        }
    }
}
