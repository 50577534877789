<div class="section right">

    <mat-button-toggle-group *ngIf="auxiliaryImageButton">
        <mat-button-toggle [matTooltip]="auxiliaryImageButton.label" (click)="navigate(auxiliaryImageButton.route)">
            <img class="image" [src]="auxiliaryImageButton.image" alt="" />
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group *ngIf="infoButton">
        <mat-button-toggle [matTooltip]="infoButton.label" (click)="openLink()">
            <mat-icon *ngIf="infoButton.icon">{{infoButton.icon}}</mat-icon>
            <span *ngIf="!infoButton.icon">{{infoButton.label}}</span>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- List options -->
    <mat-button-toggle-group aria-label="actions" *ngIf="listMode" (change)="onButtonAction($event)">
        <mat-button-toggle value="cards" title="Tarjetas">
            <mat-icon>grid_view</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
            <mat-icon>menu</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- Add and delete -->
    <mat-button-toggle-group aria-label="actions" *ngIf="canDelete || canCreate" (change)="onButtonAction($event)">
        <mat-button-toggle value="delete" *ngIf="canDelete">
            <mat-icon class="delete">delete_forever</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="edit" *ngIf="canCreate">
            <mat-icon>add</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>

<div class="section left">
    <!-- Navigation arrows -->
    <mat-button-toggle-group aria-label="navigation" class="nav-group" *ngIf="arrowNavitation" (change)="onButtonAction($event)">
        <mat-button-toggle value="first" [disabled]="currentElementIndex === 1">
            <mat-icon>first_page</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="prev" [disabled]="currentElementIndex === 1">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="index" disabled *ngIf="currentElementIndex && totalElements">
            {{currentElementIndex}} / {{totalElements}}
        </mat-button-toggle>
        <mat-button-toggle value="next" [disabled]="currentElementIndex === totalElements">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="last" [disabled]="currentElementIndex === totalElements">
            <mat-icon>last_page</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- Filters -->
    <div class='filters' *ngIf="dropdownFilter || dropdownInstitutionFilter ||  search || filter"
        [ngClass]="{'results-visible': showResults && filterResults}">

        <app-advanced-filter-select 
            *ngIf="dropdownFilter && advancedFilters"
            [options]="dropdownOptions"
            (onFilter)="advancedFilterSelect = $event; runFilter()"
        >
        </app-advanced-filter-select>

        <!-- Dropdown institution filter -->
        <app-form-field *ngIf="dropdownInstitutionFilter" class="field dropdownFilter" [type]="'select'"
            [label]="dropdownInstitutionFilterLabel" [hideLabel]="false"
            [fieldControl]="form.controls['institution'] | asFormControl" [options]="dropdownInstitutionOptions">
        </app-form-field>


        <!-- Dropdown filter -->
        <app-form-field *ngIf="dropdownFilter && !advancedFilters" class="field dropdownFilter" [type]="'select-group'"
            [label]="dropdownFilterLabel" [hideLabel]="false" [fieldControl]="form.controls['select'] | asFormControl"
            [optionsGroup]="dropdownOptions" [comparer]="filterService.comparer">
        </app-form-field>

        <!-- Search -->
        <app-form-field *ngIf="search" class="field search" [type]="'text'" [label]="searchLabel" [hideLabel]="false"
            [fieldControl]="form.controls['search'] | asFormControl">
        </app-form-field>

        <!-- Filter -->
        <app-form-field *ngIf="filter" class="field filter" [type]="'text'" [label]="filterLabel" [hideLabel]="false"
            [fieldControl]="form.controls['filter'] | asFormControl">
        </app-form-field>

        <!-- Other filters -->
        <app-form-field *ngFor="let filter of otherFilters" class="field filter" [type]="'text'" [label]="filter.label" [hideLabel]="false"
            [fieldControl]="filter.control | asFormControl">
        </app-form-field>

        <div class="disable" *ngIf="showResults && filterResults"
            [@inOutFade]="{value: true, params: {duration: '100ms'}}" (click)="showResults = false">
        </div>

        <!-- Results -->
        <div class="results" *ngIf="showResults && filterResults"
            [@inOutFade]="{value: true, params: {duration: '100ms'}}">

            <div class="element" *ngFor="let item of filterResults"
                (click)="onFilterResultSelected.emit(item.id); showResults = false;">
                {{item.label}}
            </div>
        </div>
    </div>

    <!-- Custom button -->
    <!-- <mat-button-toggle-group *ngIf="customButtonToggle" (change)="onButtonAction($event)" -->
    <mat-button-toggle-group 
        *ngIf="customButtonToggle"
        [ngClass]="{'results-visible': showResults && filterResults}"
    >
        <mat-button-toggle 
            mat-icon-button 
            class="custom-button" 
            value="custom" 
            [matMenuTriggerFor]="menu"
            [matTooltip]="customButtonIconTooltip"
            [ngClass]="{'selected': customButtonStatus}"
        >
            <mat-icon>{{customButtonIcon}}</mat-icon>
        </mat-button-toggle>

        <mat-menu #menu="matMenu">
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus === CustomButtonFilterStatus.Hide}"
                (click)="onCustomButtonAction(1, CustomButtonFilterStatus.Hide)"
            >
                <mat-icon>hide_source</mat-icon>
                <span>No mostrar</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus === CustomButtonFilterStatus.Include}"
                (click)="onCustomButtonAction(1, CustomButtonFilterStatus.Include)"
            >
                <mat-icon>playlist_add</mat-icon>
                <span>Incluir {{customButtonIconTooltip}}</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus === CustomButtonFilterStatus.ShowOnly}"
                (click)="onCustomButtonAction(1, CustomButtonFilterStatus.ShowOnly)"
            >
                <mat-icon>playlist_add_check</mat-icon>
                <span>Mostrar solo {{customButtonIconTooltip}}</span>
            </button>
        </mat-menu>
    </mat-button-toggle-group>

    <!-- Custom button 2 -->
    <!-- <mat-button-toggle-group *ngIf="customButtonToggle2" (change)="onButtonAction($event)" -->
    <mat-button-toggle-group 
        *ngIf="customButtonToggle2"
        [ngClass]="{'results-visible': showResults && filterResults}"
    >
        <mat-button-toggle 
            mat-icon-button 
            class="custom-button" 
            value="custom2" 
            [matMenuTriggerFor]="menu2"
            [matTooltip]="customButtonIconTooltip2"
            [ngClass]="{'selected': customButtonStatus2}"
        >
            <mat-icon>{{customButtonIcon2}}</mat-icon>
        </mat-button-toggle>

        <mat-menu #menu2="matMenu">
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus2 === CustomButtonFilterStatus.Hide}"
                (click)="onCustomButtonAction(2, CustomButtonFilterStatus.Hide)"
            >
                <mat-icon>hide_source</mat-icon>
                <span>No mostrar</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus2 === CustomButtonFilterStatus.Include}"
                (click)="onCustomButtonAction(2, CustomButtonFilterStatus.Include)"
            >
                <mat-icon>playlist_add</mat-icon>
                <span>Incluir {{customButtonIconTooltip2}}</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus2 === CustomButtonFilterStatus.ShowOnly}"
                (click)="onCustomButtonAction(2, CustomButtonFilterStatus.ShowOnly)"
            >
                <mat-icon>playlist_add_check</mat-icon>
                <span>Mostrar solo {{customButtonIconTooltip2}}</span>
            </button>
        </mat-menu>
    </mat-button-toggle-group>

    <!-- Custom button 3-->
    <!-- <mat-button-toggle-group *ngIf="customButtonToggle3" (change)="onButtonAction($event)" -->
    <mat-button-toggle-group 
        *ngIf="customButtonToggle3"
        [ngClass]="{'results-visible': showResults && filterResults}"
    >
        <mat-button-toggle 
            mat-icon-button 
            class="custom-button" 
            value="custom3" 
            [matMenuTriggerFor]="menu3"
            [matTooltip]="customButtonIconTooltip3"
            [ngClass]="{'selected': customButtonStatus3}"
        >
            <mat-icon>{{customButtonIcon3}}</mat-icon>
        </mat-button-toggle>

        <mat-menu #menu3="matMenu">
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus3 === CustomButtonFilterStatus.Hide}"
                (click)="onCustomButtonAction(3, CustomButtonFilterStatus.Hide)"
            >
                <mat-icon>hide_source</mat-icon>
                <span>No mostrar</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus3 === CustomButtonFilterStatus.Include}"
                (click)="onCustomButtonAction(3, CustomButtonFilterStatus.Include)"
            >
                <mat-icon>playlist_add</mat-icon>
                <span>Incluir {{customButtonIconTooltip3}}</span>
            </button>
            <button 
                mat-menu-item 
                [ngClass]="{'selected': customButtonStatus3 === CustomButtonFilterStatus.ShowOnly}"
                (click)="onCustomButtonAction(3, CustomButtonFilterStatus.ShowOnly)"
            >
                <mat-icon>playlist_add_check</mat-icon>
                <span>Mostrar solo {{customButtonIconTooltip3}}</span>
            </button>
        </mat-menu>
    </mat-button-toggle-group>

    <!-- Alert button -->
    <mat-button-toggle-group *ngIf="alertButton" (change)="onButtonAction($event)"
        [ngClass]="{'results-visible': showResults && filterResults}">
        <mat-button-toggle mat-icon-button class="alert-button" value="alert" matTooltip="Alertas"
            [ngClass]="{'selected': alertButtonStatus}">
            <mat-icon>info</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- Reports -->
    <app-entity-report-actions class="patient-report-actions" *ngIf="reportActions" [dataItems]="currentPatientsData"
        [institutionId]="reportInstitutionId">
    </app-entity-report-actions>
</div>
