<div class="operation-bar" 
  [ngClass]="{'bad-solution': onListPage()}"
  [ngStyle]="{'display': onListPage() || patient?.isEdit ? 'block' : 'none'}">
  <app-operation-bar
    [arrowNavitation]="showArrows()"
    [listMode]="true"
    [canDelete]="Utils.hasPermission(Permission.PATIENTS)().includes(PermissionType.WRITE) && onEditPage()"
    [canCreate]="Utils.hasPermission(Permission.PATIENTS)().includes(PermissionType.WRITE)"
    [reportActions]="onListPage()"
    [reportInstitutionId]="currentInstitutionId"
    [customButtonToggle]="true"
    [customButtonIcon]="'indeterminate_check_box'"
    [customButtonIconTooltip]="'Bajas'"
    [search]="true"
    [searchLabel]="'Búsqueda residentes'"
    [filter]="true"
    [filterQuery]="queryMed"
    [filterLabel]="'Filtro medicamentos'"
    [dropdownFilter]="true"
    [dropdownOptions]="patientFilters"
    [dropdownInitialSelect]="selectedFilters"
    [dropdownFilterLabel]="'Filtros'"
    [alertButton]="true"
    [totalElements]="totalElements"
    [currentElementIndex]="currentElementIndex"
    [showResults]="onEditPage() && resultsRefreshed"
    [filterResults]="filteredResults"
    [currentPatientsData]="currentPatientsData"
    (onAction)="onOperationBarAction($event)"
    (onFilter)="filter($event)"
    (onFilterResultSelected)="onFilterResultSelected($event)">
  </app-operation-bar>
</div>

<section class="content" 
  *ngIf="!initLoading" 
  [ngClass]="{'bad-solution': onEditPage() && patient?.patientId}">
  <router-outlet *ngIf="(!onEditPage() && patients?.length) || onEditPage()" (activate)="onActivate($event)"></router-outlet>

  <div class="no-data" *ngIf="!onEditPage() && !patients?.length">No hay residentes dados de alta en este centro</div>
</section>
