import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypeModal } from '@interfaces/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public title: string = '';
  public message: string = '¿Estás seguro?';
  public confirmButtonText = 'Si';
  public cancelButtonText = 'No';
  public auxButtonText: string | undefined = undefined;
  public type: TypeModal = 'info';
  public colorButtonOk: string = 'primary';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.type = data.type || this.type;
      this.colorButtonOk = data.type == 'error' ? 'warn' : this.colorButtonOk;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        this.auxButtonText = data.buttonText.aux;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  onAuxClick(): void {
    this.dialogRef.close('aux');
  }
}
