import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { createBinaryString } from '@constants/funtions-utils';

@Component({
  selector: 'app-re-issues',
  templateUrl: './re-issues.component.html',
  styleUrls: ['./re-issues.component.scss']
})
export class ReIssuesComponent implements OnChanges{

  @Input() issueType: number;
  @Input() disabled: boolean = false;
  @Output() issueChange: EventEmitter<number> = new EventEmitter();

  public flags = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['issueType']) {
      this.getIssueFlag(changes['issueType'].currentValue || 0);
    }
  }

  getIssueFlag(number: number): void {
    const flag = createBinaryString(number);
    const l = flag.length - 1;
    this.flags[0] = flag[l - 0] === '1' || flag[l - 1] === '1';
    this.flags[2] = flag[l - 2] === '1';
    this.flags[3] = flag[l - 3] === '1';
    this.flags[4] = flag[l - 4] === '1';
    this.flags[5] = flag[l - 5] === '1';
    this.flags[6] = flag[l - 6] === '1';
    this.flags[7] = flag[l - 7] === '1';
  }

  getIssueType(): number {
    const flags = Object.values(this.flags);
    let result = 0;
    flags.forEach((val, i) => {
      result += val ? Math.pow(2, i) : 0;
    })
    return result;
  }

  onFlagClick(flag:  0|1|2|3|4|5|6|7): void {
    if (this.disabled) return;
    
    this.flags[flag] = !this.flags[flag];
    const res = this.getIssueType();
    this.issueChange.emit(res);
  }

}
