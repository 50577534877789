//En este reporte cambian dos cosas RP: con los datos del form
import { DrugIdMap } from '@interfaces/report/report.interface';
import { TableColumn } from '@interfaces/table.interface';

// pero tambien el rpp, dependiendo de lo que se elija en el select, se llamará a una u a otra.
export const reportDataThirdParties = {
  data: {
    rpp: '/COROTA/TpLogGCR000000',
    pts: 1,
    fr: true,
    dn: '',
    rp: '',
  },

  dataDownloadPDF: {
    rpp: '/COROTA/TpLogGCR000002',
    pts: 0,
    fmt: 2,
    dn: '',
    rp: '',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/TpLogGCR000002',
    pts: 0,
    fmt: 3,
    dn: '',
    rp: '',
  },
  dataRenderReport: {
    rpp: '/COROTA/TpLogGCR000002',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: '',
    rp: '',
  },
};

export const rppThirdPartiesBase = '/COROTA/TpLogGCR000000';

export const mapCNColumns: TableColumn[] = [
  {
    columnDef: 'DrugIdMap',
    header: 'Mapa',
    cell: (drug: DrugIdMap) => `${drug.DrugIdMap}`,
  },
  {
    columnDef: 'DrugIdMaster',
    header: 'Maestro',
    cell: (drug: DrugIdMap) => `${drug.DrugIdMaster}`,
  },
  {
    columnDef: 'DrugIdInvc',
    header: 'Facturación',
    cell: (drug: DrugIdMap) => `${drug.DrugIdInvc}`,
  },
  {
    columnDef: 'Name',
    header: 'Nombre',
    cell: (drug: DrugIdMap) => `${drug.Name}`,
  },
  {
    columnDef: 'Obsolete',
    header: 'Obsoleto',
    cell: (drug: DrugIdMap) => `${drug.Obsolete}`,
  },
  {
    columnDef: 'RO',
    header: 'RO',
    cell: (drug: DrugIdMap) => `${drug.RO}`,
  },
  {
    columnDef: 'NoPrescribe',
    header: 'No prescribir',
    cell: (drug: DrugIdMap) => `${drug.NoPrescribe} `,
  },
  {
    columnDef: 'NoBlister',
    header: 'NE',
    cell: (drug: DrugIdMap) => `${drug.NoBlister} `,
  },
];
