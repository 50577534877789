import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiHeaderType } from '@interfaces/crud-helpers.interface';

@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  constructor(public _http: HttpClient) {}

  /**
   * common header
   * @returns
   */
  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${localStorage.getItem('access_token') ?? ''}`,
        // Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        CorotaWsVersion: '0',
        CorotaSpaVersion: '',
      }),
    };
  }

  /**
   * woct header
   * Returns httpOptions - headers without content-type
   */
  getHeaderWOCT(): any {
    return {
      headers: new HttpHeaders({
        Authorization: `Basic ${localStorage.getItem('access_token') ?? ''}`,
      }),
    };
  }

  /**
   * Get Header Without Authorization
   * @returns
   */
  getHeaderWOA() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
  }

  /**
   * Blob header
   * @returns
   */
  getHeaderBlob(): any {
    return {
      headers: new HttpHeaders({
        Authorization: `Basic ${localStorage.getItem('access_token') ?? ''}`,
      }),
      responseType: 'blob',
    };
  }

  /**
   * Text header
   * @returns
   */
  getHeaderText(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'text',
        Authorization: `Basic ${localStorage.getItem('access_token') ?? ''}`,
      }),
      responseType: 'text',
    };
  }

  getHeaderByType(type: ApiHeaderType): any {
    switch (type) {
      case 'common':
        return this.getHeaders();
      case 'woct':
        return this.getHeaderWOCT();
      case 'woa':
        return this.getHeaderWOA();
      case 'blob':
        return this.getHeaderBlob();
      case 'text':
        return this.getHeaderText();
      default:
        return this.getHeaders();
    }
  }
}
