import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PAGE_SIZE_OPTIONS } from '@constants/options';
import { CardAction } from '@interfaces/card-actions';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { InstitutionSelectAPP } from '@interfaces/institution/institution.interface';
import * as columns from '@interfaces/table.interface';
import { TableType } from '@interfaces/table.interface';
import { FilterService } from '@services/filter/filter.service';
import { MedicinesService } from '@services/medicines.service';
import { alertInfo } from '@services/mocks/patient';
import { TableService } from '@services/table/table.service';
import { Subscription } from 'rxjs';
import { CheckboxAction } from '@interfaces/card-actions';
import { Patient } from '@interfaces/patient/patient.interface';
import { environment } from '@json/src/environments/environment';
import { ImageService } from '@services/image/image.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges, OnDestroy {
    @Input() input: any;
    @Input() displayedColumns: string[] = [];
    @Input() actions: CardAction[];
    @Input() checkboxs: CheckboxAction[];
    @Input() route: string = '';
    @Input() columns: columns.TableColumn[];
    @Input() pageSize: number;
    @Input() pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

    @Input() canFilter: boolean = true;
    @Input() filterBack: boolean = false;
    @Input() filterFrontInputString: string = 'Búsqueda';
    @Input() filterBackInputString: string = 'Filtrar';
    @Input() selectFilter: FilterSelect[];
    @Input() selectedOptionsFilter: FilterOption[];
    @Input() tableType: TableType;
    @Input() currentFilter: string = '';
    @Input() currentSearch: string = '';

    @Input() selectInstitution?: boolean = false;
    @Input() institutionOptions?: SelectOption[] = [];
    @Input() selectedInstitution?: InstitutionSelectAPP;
    @Input() positionFilters?: string = 'absolute';
    @Input() currentPatientsData: string;

    @Output()
    newFilterBack = new EventEmitter<string>();

    @Output() onFilter = new EventEmitter<any>();

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('table', { static: true }) table: ElementRef;

    @Output() dataChanges = new EventEmitter<any[]>();
    @Output() onInstitutionChange = new EventEmitter<any[]>();
    @Output() onPageChange = new EventEmitter<PageEvent>();

    public highValue: number = 15;

    public dataSource: any;
    public nameColumns = columns.columns;

    //strings
    public imgPathString: string = 'imagePath';
    public nameString: string = 'name';
    public flagString: string = 'flag';
    public expeditionString: string = 'expedition';
    public editRoute: string = '../edit';
    public msgNotFound: string = 'No hay datos que coincidan con la búsqueda';
    public inputPlaceholder: string = 'Ej: Antonio';
    public alertString = alertInfo;

    private dataSourceSubscription: Subscription;

    //Filters
    public toggleButton: boolean = false;

    public filterObject = {
        filterValueGlobal: '',
        filterValueType: [],
        filterValueExitus: false,
        filterValueAlert: '',
        filterValueGender: '',
        filterValueBloqVac: false,
        filterValueBloqHosp: false,
    };

    public filterValueAlert: string = '';

    public formFilterBack: FormGroup = new FormGroup({
        filter: new FormControl(''),
    });

    public formFilterFront: FormGroup = new FormGroup({
        filter: new FormControl(''),
        select: new FormControl(''),
    });

    constructor(
        public filterService: FilterService,
        private medicineService: MedicinesService,
        public tableService: TableService
    ) { }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(
            this.input.slice(0, this.pageSizeOptions[0])
        );

        this.setListenerDataSource();

        this.reorderData();
        this.setFilterPredicate();
        this.setSelectedOptions();

        this.calculatePageSizeOptions();
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = this.input;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['input'] && this.dataSource) {
            this.dataSource.data = this.input;
            this.reorderData();
            this.onFilter.emit(this.dataSource.filteredData);
        }
    }

    ngOnDestroy(): void {
        if (this.dataSourceSubscription) {
            this.dataSource.disconnect();
            this.dataSourceSubscription.unsubscribe();
        }
    }

    setListenerDataSource(): void {
        this.dataSourceSubscription = this.dataSource.connect().subscribe(() => {
            const filteredData = this.dataSource.filteredData;
            this.dataChanges.emit(filteredData);
        });
    }

    setFilterPredicate(): void {
        switch (this.tableType) {
            default:
            case 'patient':
                this.dataSource.filterPredicate =
                    this.tableService.customFilterPredicatePatient;
                break;
            case 'medicine':
                this.dataSource.filterPredicate =
                    this.tableService.customFilterPredicateMedicine;
                break;
            case 'institution':
                this.dataSource.filterPredicate =
                    this.tableService.customFilterPredicateInstitution;
                break;
            case 'default':
                this.dataSource.filterPredicate =
                    this.tableService.customFilterPredicateDefault;
                break;
        }
    }

    setSelectedOptions() {
        this.formFilterFront.controls['select'].setValue(
            this.selectedOptionsFilter
        );
    }

    applyFilters(): void {
        const filterValue = this.formFilterFront.value['search'];
        this.currentSearch = filterValue;

        switch (this.tableType) {
            default:
            case 'patient':
                this.setPatientFilters(this.formFilterFront);
                break;
            case 'medicine':
                this.getMedicineFilters(this.formFilterFront);
                break;
            case 'institution':
                this.setInstitutionFilters(this.formFilterFront);
                break;
            case 'default':
                this.setDefaultFilters();
                break;
        }

        this.changePagination();

        this.onFilter.emit(this.dataSource.filteredData);
    }

    setInstitutionFilters(form: FormGroup<any>): void {
        const filterValueGlobal = this.formFilterFront.value['filter'];
        this.filterObject.filterValueGlobal = filterValueGlobal
            .trim()
            .toLowerCase();

        const selectedOptions = form.value['select'];
        this.filterObject.filterValueType = selectedOptions;

        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    setDefaultFilters(): void {
        const filterValueGlobal = this.formFilterFront.value['filter'];
        this.filterObject.filterValueGlobal = filterValueGlobal
            .trim()
            .toLowerCase();

        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    getMedicineFilters(form: FormGroup<any>): void {
        const filterValueGlobal = this.formFilterFront.value['filter']
            .trim()
            .toLowerCase();

        const filters = this.medicineService.getFilters(form);

        this.dataSource.filter = JSON.stringify({
            ...filters,
            filterValueGlobal,
        });
    }

    setPatientFilters(form: FormGroup<any>) {
        const filterValueGlobal = form.value['filter'];
        const filterValueGender = this.filterService
            .getFilterGender(form)
            .trim()
            .toLowerCase();
        const filterValueExitus = this.filterService.getFilterExitus(form);
        const filterValueBloqHosp =
            this.filterService.getFilterBloqHospitalization(form);
        const filterValueBloqVac = this.filterService.getFilterBloqVacation(form);

        this.filterObject = {
            filterValueGlobal,
            filterValueGender,
            filterValueBloqHosp,
            filterValueBloqVac,
            filterValueExitus,
            filterValueAlert: this.filterObject.filterValueAlert,
            filterValueType: this.filterObject.filterValueType,
        };
        this.dataSource.filter = JSON.stringify(this.filterObject);
    }

    applyAlertFilter(): void {
        this.toggleButton = !this.toggleButton;
        this.filterValueAlert = this.toggleButton
            ? 'true'.trim().toLowerCase()
            : '';

        this.filterObject.filterValueAlert = this.filterValueAlert;

        this.dataSource.filter = JSON.stringify(this.filterObject);
        this.changePagination();
    }

    changePagination(): void {
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    //Change the position of the image column at the beginning of the array
    reorderData(): void {
        if (this.dataSource !== undefined && this.dataSource._data._value[0] != undefined) {
            this.displayedColumns = Object.getOwnPropertyNames(
                this.dataSource._data._value[0]
            ).sort((a, b) => {
                return a == this.imgPathString ? -1 : b == this.imgPathString ? 1 : 0;
            });

            if (this.actions) {
                this.displayedColumns.push('actions');
            }

            if (this.checkboxs) {
                this.displayedColumns.push('checkboxs');
            }
        } else {
            this.displayedColumns = [];
        }
    }

    applyFilterBack(): void {
        this.newFilterBack.emit(this.formFilterBack.get('filter')?.value);
    }

    clearFiltersBack(): void {
        this.formFilterBack.get('filter')?.setValue('');
        this.newFilterBack.emit('');
    }

    isOutStock(flag: number): boolean {
        return this.medicineService.isOutStock(flag);
    }

    isObsolete(flag: number): boolean {
        return this.medicineService.isObsolete(flag);
    }

    handleInstitutionChange(event: any): void {
        this.onInstitutionChange.emit(event);
    }

    isProcessed(flag: number): boolean {
        return flag == 3;
    }

    isNotProcessed(flag: number): boolean {
        return flag == 1;
    }

    isBlock(blockReason: number): boolean {
        return blockReason !== null;
    }

    imageLoadError(evt: Event, row: Patient) {
        const name = row.name.split(', ');
        (evt.target as HTMLImageElement).src = `https://ui-avatars.com/api/?name=${name[0][0]}+${name[1][0]}`;
    }

    calculatePageSizeOptions() {
        if ( this.table ) {
            const layoutWidth = this.table.nativeElement.offsetWidth;
            const layoutHeight = this.table.nativeElement.offsetHeight - 20;
            const gridGap = 0  // Grid gap from style in px
            const minElementWidth = layoutWidth
            
            const columns = Math.floor((layoutWidth - gridGap) / (minElementWidth + gridGap));
            const rows = Math.floor((layoutHeight - gridGap) / (41) + gridGap);

            this.pageSize = columns * rows;
            this.highValue = this.pageSize;

            this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3, Math.ceil(this.pageSize * 4 / 100) * 100];
        }
    }
}
