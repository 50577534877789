export const reportDataAnnex5 = {
  data: {
    rpp: "/COROTA/BlankAnnex5", // 8
    pts: 1,
    fr: true,
    dn: "1690895828922_{0}.html",
    rp: "{}"
  },
  dataDownloadPDF: {
    rpp: "/COROTA/BlankAnnex5", // 8
    pts: 0,
    fmt: 2,
    dn: "Detecció_PRM.pdf", // "Full d’interconsulta.pdf"
    rp: "{}"
  },
  dataDownloadExcel: {
    rpp: "/COROTA/BlankAnnex5", // 8
    pts: 0,
    fmt: 3,
    dn: "Detecció_PRM.xls", // "Full d’interconsulta.xls"
    rp: "{}"
  },
  dataRenderReport: {
    rpp: "/COROTA/BlankAnnex5", // 8
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: "Detecció_PRM.pdf", // "Full d’interconsulta.pdf"
    rp: "{}"
  }
};
export const reportDataAnnex8 = {
  data: {
    rpp: "/COROTA/BlankAnnex8",
    pts: 1,
    fr: true,
    dn: "1690895828922_{0}.html",
    rp: "{}"
  },
  dataDownloadPDF: {
    rpp: "/COROTA/BlankAnnex8",
    pts: 0,
    fmt: 2,
    dn: "Full d’interconsulta.pdf",
    rp: "{}"
  },
  dataDownloadExcel: {
    rpp: "/COROTA/BlankAnnex8",
    pts: 0,
    fmt: 3,
    dn: "Full d’interconsulta.xls",
    rp: "{}"
  },
  dataRenderReport: {
    rpp: "/COROTA/BlankAnnex8",
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: "Full d’interconsulta.pdf",
    rp: "{}"
  }
};
