import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataSCP } from '@constants/reports/report-scp';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    AlertSCP,
    DepartmentPharma,
    PatientPharma,
    Report,
    User,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-scp',
    templateUrl: './report-scp.component.html',
    styleUrls: ['./report-scp.component.scss'],
})
export class ReportScpComponent {
    public reportDataSCP = reportDataSCP;
    public showPatientSelect: boolean = false;
    public showDepartmentSelect: boolean = false;
    public showPrescriptorSelect: boolean = false;
    public showTypologyAlertSelect: boolean = false;
    public showIntervalSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showIntervalDay: boolean = false;
    public showSpecificDaySelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public selectOptionsPatientsWithoutFilter: PatientPharma[];
    _selectOptionsPatients: PatientPharma[];
    set selectOptionsPatients(value: PatientPharma[]) {
        this._selectOptionsPatients = value;
        this.selectOptionsPatientsAutocomplete = value.map(v => {
            return {
                label: v.nam,
                value: v.id
            }
        })
    }
    get selectOptionsPatients() {
        return this._selectOptionsPatients;
    }
    selectOptionsPatientsAutocomplete: SelectOption[];
    public selectOptionsDepartment: DepartmentPharma[];

    public selectOptionsPersonalPrescriptor: User[];
    public selectOptionsTypologyAlert: AlertSCP[];

    public selectOptionsInsitutions: SelectOption[];

    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            startDate: [null],
            endDate: [null],
            selectedDepartment: [null],
            selectedPatient: [null],
            selectedPersonalPrescriptor: [null],
            selectedTypologyAlert: [''],
            selectedInstitution: ['', Validators.required],
        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: SCP`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: SCP`);

        this.getDataForTheSelects();
        this.setCurrentDate();
        this.typeGlobal = this.roleManager.isPharma();
    }


    setCurrentDate(): void {
        const currentDate = new Date();

        this.form.patchValue({ specificDay: currentDate });
    }

   

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
                Object.assign([], this.institutionService.institutionList.getValue());
                 // Order Alpabetically label and text uppercase
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
                // this.selectOptionsInsitutions.map((a) =>{
                //     a.label = a.label.toUpperCase();
                // })

                if (this.selectOptionsInsitutions) {
                    const hasEmptyValue = this.selectOptionsInsitutions.some(
                    (item) => item.value === ''
                    );
                    if (hasEmptyValue) {
                    this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                        (item) => item.value !== ''
                    );
                    }
                }

                if ( !this.typeGlobal ) {
                    this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
                }
            });

        this.reportService
            .getRepParamsEditDataPharmalert(this.currentInstitution)
            .subscribe((result) => {
                const { users, pats, deps, alerts } = result.payload;
                this.selectOptionsPatients = pats;
                this.selectOptionsDepartment = deps;
                this.selectOptionsTypologyAlert = alerts;
                this.selectOptionsPersonalPrescriptor = users;
            });
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const {
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,

                selectedPersonalPrescriptor,
                selectedTypologyAlert,
            } = this.getFormData();

            this.setInfoRP(
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert
            );

            this.reportService.handlePreviewClick(this.router, this.reportDataSCP);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    getFormData(): {
        startDate: string;
        endDate: string;
        selectedPatient: string;
        selectedDepartment: string;
        selectedPersonalPrescriptor: string;
        selectedTypologyAlert: string;
    } {
        const {
            startDate,
            endDate,
            selectedPatient,
            selectedDepartment,
            selectedPersonalPrescriptor,
            selectedTypologyAlert,
        } = this.form.getRawValue();

        return {
            startDate,
            endDate,
            selectedPatient,
            selectedDepartment,
            selectedPersonalPrescriptor,
            selectedTypologyAlert,
        };
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const {
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert,
            } = this.getFormData();

            this.setInfoRP(
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert
            );
            this.reportService.handlePrintClick(this.reportDataSCP);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const {
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert,
            } = this.getFormData();
            this.setInfoRP(
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert
            );
            this.reportService.handlePdfClick(this.reportDataSCP);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const {
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert,
            } = this.getFormData();

            this.setInfoRP(
                startDate,
                endDate,
                selectedPatient,
                selectedDepartment,
                selectedPersonalPrescriptor,
                selectedTypologyAlert
            );
            this.reportService.handleExcelClick(this.reportDataSCP);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(
        startDate: string,
        endDate: string,
        selectedPatient: string,
        selectedDepartment: string,
        selectedPersonalPrescriptor: string,
        selectedTypologyAlert: string,
        isPreview: boolean = false
    ): void {

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id

        const rp = JSON.stringify({
            InstitutionId: String(currentInstitution),
            DateFrom: startDate ? Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split(".")[0] : null,
            DateUntil: endDate ? Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate())?.toISOString().split(".")[0] : null,
            LocaleId: 3082,
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DepartmentId: selectedDepartment ? String(selectedDepartment) : null,
            AlertIds: selectedTypologyAlert.toString() ?? '',
            UserId: selectedPersonalPrescriptor ? String(selectedPersonalPrescriptor) : null,
        });

        const now = moment();
        Object.keys(this.reportDataSCP).forEach(key => {
            const original = (<any>this.reportDataSCP)[key].dn as string;
            (<any>this.reportDataSCP)[key].dn = original.replace('{date}', now.format('YYYYMMDD')).replace('{time}', now.format('HHMM'))
        });

        this.reportDataSCP.data.rp = rp;
        if (isPreview) this.reportDataSCP.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataSCP.dataDownloadPDF.rp = rp;
        this.reportDataSCP.dataDownloadExcel.rp = rp;
        this.reportDataSCP.dataRenderReport.rp = rp;
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: null,
            });
        }
    }

    toggleSelectFloor(): void {
        this.showDepartmentSelect = !this.showDepartmentSelect;
        if (!this.showDepartmentSelect) {
            this.form.patchValue({
                selectedDepartment: null,
            });
        }
    }

    toggleSelectPrescriptor(): void {
        this.showPrescriptorSelect = !this.showPrescriptorSelect;
        if (!this.showPrescriptorSelect) {
            this.form.patchValue({
                selectedPersonalPrescriptor: null,
            });
        }
    }

    toggleSelectTypologyAlert(): void {
        this.showTypologyAlertSelect = !this.showTypologyAlertSelect;
        if (!this.showTypologyAlertSelect) {
            this.form.patchValue({
                selectedTypologyAlert: '',
            });
        }
    }

    toggleSelectInterval(): void {
        this.showIntervalSelect = !this.showIntervalSelect;
        if (!this.showIntervalSelect) {
            this.form.patchValue({
                startDate: null,
                endDate: null,
            });
        } else {
            this.form.patchValue({
                startDate: moment().subtract(15, 'days'),
                endDate: moment(),
            });
        }
    }

    toggleSelectIntervalDay(): void {
        this.showIntervalDay = !this.showIntervalDay;
        if (!this.showIntervalDay) {
            this.form.patchValue({
                startDate: null,
                endDate: null,
            });
        }
    }
}
