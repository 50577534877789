import { Component } from '@angular/core';

@Component({
  selector: 'app-version-changed',
  templateUrl: './version-changed.component.html',
  styleUrls: ['./version-changed.component.scss']
})
export class VersionChangedComponent {

}
