import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { sidebarConfigItem } from '@interfaces/sidebar.interface';
import { LoadingService } from '@services/loading.service';
import { sidebarConfig } from '@json/projects/quantum-app/src/config-quantum';
import { filter, Subscription } from 'rxjs';

@Component({
    selector: 'app-layout-quantum',
    templateUrl: './layout-quantum.component.html',
    styleUrls: ['./layout-quantum.component.scss'],
})
export class LayoutQuantumComponent implements OnInit, OnDestroy {
    public sidebarConfig: sidebarConfigItem[] = sidebarConfig;
    public opened = false;

    public loading = false;
    public loadingMessage = '';

    private subs: Subscription[] = [];

    constructor(
        private loadingService: LoadingService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) { }

    // The use of the cdr is neccesary for avoid the error "Expression has changed after it was checked"
    // When you change the properties loading and loadingMessage, you inform Angular to re-valuate the binding and prevent the error.
    ngOnInit(): void {
        // Parar loading al cargar
        this.loadingService.stop();
        setTimeout(() => {
            this.subs.push(
                this.loadingService.loading.subscribe((res) => {
                    this.loading = res;
                    this.cdr.detectChanges(); // manually trigger change detection
                }),
                this.loadingService.message.subscribe((res) => {
                    this.loadingMessage = res;
                    this.cdr.detectChanges();
                }),
                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .subscribe(() => {
                        this.loadingService.stop();
                    })
            );
        }, 0);
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
