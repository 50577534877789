<mat-dialog-content class="note-modal-container" >
    <h3 class="title">Editar nota</h3>
    <div class="content">
      <mat-form-field class="example-full-width">
        <mat-label>Nota</mat-label>
        <textarea  matInput [(ngModel)]="note"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="note-modal-buttons">
    <button
      mat-raised-button
      color="primary"
      tabindex="1"
      (click)="onConfirmClick()"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      Cancelar
    </button>
  </mat-dialog-actions>
  