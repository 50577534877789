<div class="calendar-summary-container">
    <mat-calendar #calendar
        [ngClass]="{ 'disableSelect': !canSelect }"
        [dateClass]="isSelected"
        [selected]="$any(selected)"
        (selectedChange)="select($event)"
        >
    </mat-calendar>
</div>

