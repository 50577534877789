import { Validators } from '@angular/forms';
import { TYPE_ROBOT_OPTIONS } from '@constants/options';
import { schedulesOptions } from '@constants/schedules';
import {
  FormBuilderInterface,
  FormConfigInterface,
  OptionsObject,
  TabInterface,
} from '@interfaces/dynamic-form.interface';
import { ProcPlan } from '@interfaces/procs/proc-plans';
import { TableColumn } from '@interfaces/table.interface';

export const tabs: TabInterface[] = [{ label: 'Ajustes', form: 'settings' }];

const settings = (options: OptionsObject): FormBuilderInterface => {
  return {
    institution: {
      type: 'group-label',
      label: 'Centros',
      childs: {
        institution: {
          label: 'Centros',
          type: 'select',
          value: '',
          options: options['institutions'],
          validators: [Validators.required],
          size: 'col-12',
        },
      },
    },
    interval: {
      type: 'group-label',
      label: 'Intervalos a mostrar',
      customStyle: {'margin-bottom': '10px'},
      childs: {
        // permitFiveDays: {
        //   label: 'Permitir más de 5 días de adelanto',
        //   type: 'checkbox',
        //   value: null,
        //   validators: [],
        //   size: 'col-12',
        //   customStyle: {'margin-bottom': '10px'}
        // },
        startDate: {
          label: 'Desde',
          type: 'date',
          value: new Date(),
          validators: [Validators.required],
          size: 'col-6',
        },
        startHour: {
          label: 'Hora',
          type: 'select',
          value: '00:00',
          options: schedulesOptions,
          validators: [Validators.required],
          size: 'col-6',
        },
        initialDatePrevTodayErrorInfo: {
          label: '',
          type: 'info',
          value: 'ATENCIÓN, La fecha inicial es menor que el día actual',
          size: 'col-12',
          visible: false,
          customStyle: {
            'background-color': 'red',
            'color': 'white',
            'font-weight': 'bold',
            'border-radius': '8px',
            'margin-bottom': '12px'
          }
        },
        initialDateErrorInfo: {
          label: '',
          type: 'info',
          value: 'ATENCIÓN, La fecha inicial es mayor que 5 días a partir de hoy',
          size: 'col-12',
          visible: false,
          customStyle: {
            'background-color': 'red',
            'color': 'white',
            'font-weight': 'bold',
            'border-radius': '8px',
            'margin-bottom': '12px'
          }
        },
        endDate: {
          label: 'Hasta',
          type: 'date',
          value: new Date(Date.now() + 6 * 24 * 60 * 60 * 1000),
          validators: [Validators.required],
          size: 'col-6',
        },
        endHour: {
          label: 'Hora',
          type: 'select',
          value: '23:00',
          options: schedulesOptions,
          validators: [Validators.required],
          size: 'col-6',
        },
        intervalErrorInfo: {
          label: '',
          type: 'info',
          value: 'ATENCIÓN, El intervalo escogido tiene más de 7 días',
          size: 'col-12',
          visible: false,
          customStyle: {
            'background-color': 'red',
            'color': 'white',
            'font-weight': 'bold',
            'border-radius': '8px'
          }
        },
      },
    },

    branchHide: {
      type: 'group-label',
      label: 'No mostrar en el árbol',
      customStyle: {'margin-bottom': '10px'},
      childs: {
        hideDepartments: {
          label: 'Departamentos',
          type: 'checkbox',
          value: null,
          validators: [],
          customStyle: {'width': 'fit-content'}
        },
        hideDays: {
          label: 'Días',
          type: 'checkbox',
          value: null,
          validators: [],
          customStyle: {'width': 'fit-content'}
        },
        hideHours: {
          label: 'Horas',
          type: 'checkbox',
          value: null,
          validators: [],
          customStyle: {'width': 'fit-content'}
        },
      },
    },

    typeRobot: {
      type: 'group-label',
      label: 'Tipo de robot',
      customStyle: {'margin-bottom': '10px'},
      childs: {
        type: {
          label: 'Tipo',
          type: 'radio',
          value: 0,
          validators: [],
          size: 'col-12',
          options: TYPE_ROBOT_OPTIONS,
          customStyle: {'margin-bottom': '10px'},
        },
        pills: {
          label: 'Pastillas por blister',
          type: 'number',
          value: 7,
          validators: [Validators.min(1), Validators.max(50)],
          min: 1,
          max: 50,
          size: 'col-6',
          conditionalConfig: {
            mode: 'hide',
            match: 'loose-equals',
            filter: 'some',
            fields: [{ name: 'type', value: 1 }],
          },
        },
      },
    },

    typeRequest: {
      type: 'group-label',
      label: 'Tipo de petición',
      childs: {
        reason: {
          label: 'Motivo',
          type: 'select',
          value: 0,
          options: options['reasons'],
          validators: [Validators.required],
          size: 'col-12',
        },
      },
    }
  };
};

export const formsConfig = (options: OptionsObject): FormConfigInterface => {
  return {
    settings: settings(options),
  };
};

export const pmTreeView = {
  instid: 218,
  depid: null,
  patid: null,
  admdt: null,
  admtm: null,
  treelv: 'D',
  dtFrom: '/Date(1689552000000)/',
  dtUntil: '/Date(1690243140000)/',
  inclNB: 0,
};

export const plansColumns: TableColumn[] = [
  {
    columnDef: 'pn',
    header: 'Paciente',
    cell: (plan: ProcPlan) => `${plan.pn}`,
  },
  {
    columnDef: 'pnum',
    header: '',
    cell: (plan: ProcPlan) => ``,
  },
  {
    columnDef: 'dp',
    header: 'Departamento',
    cell: (plan: ProcPlan) => `${plan.dp}`,
  },
  {
    columnDef: 'did',
    header: 'Código',
    cell: (plan: ProcPlan) => `${plan.did}`,
  },
  {
    columnDef: 'dn',
    header: 'Medicamento',
    cell: (plan: ProcPlan) => `${plan.dn}`,
  },
  {
    columnDef: 'f',
    header: 'Fecha',
    cell: (plan: ProcPlan) => `${new Date(plan.f).toLocaleDateString('es-ES')}`,
  },
  {
    columnDef: 'h',
    header: 'Hora',
    cell: (plan: ProcPlan) => `${plan.h}`,
  },
  {
    columnDef: 'q',
    header: 'Cantidad',
    cell: (plan: ProcPlan) => `${plan.q}`,
  },
  {
    columnDef: 'ef',
    header: '',
    cell: (plan: ProcPlan) => ``,
  },
  {
    columnDef: 'pl',
    header: '',
    cell: (plan: ProcPlan) => ``,
  },
];

export const processPlanDataRender = {
  dataRenderReport: {
    rpp: '/COROTA/GetDispensingPlanPreview',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2, // fmt: 1: HTML 2: PDF 3: Excel
    dn: '', // lo unico que cambia, es el .pdf o el .excel
    rp: '',
  },
};

