
<div class="subform-array">

  <div class="container-calendar">
    <app-calendar-summary [formArray]="formArray" [config]="formConfig.calendarConfig"
    (dateSelected)="updateDate($event)" (viewChanges)="filterItems($event)"></app-calendar-summary>
  </div>
  
  <div class="controls-columns">
    <ng-container *ngFor="let item of formArray.controls; let i = index">
      <div class="item"
        class="item subform-array__subform"
        *ngIf="applyFilter(item)"
        [style.order]="itemPositions[i]"
        [style.display]="itemVisibility[i] ? 'unset' : 'none'">

        <ng-container *ngFor="let section of forms[i].schema; let i = index;">
          <ng-container [ngSwitch]="section.type">
            <!-- Default item -->
            <ng-container *ngSwitchDefault>
              <app-form-field
                class="field"
                [class]="section.size || ''"
                [ngClass]="section.customClass"
                [ngStyle]="section.customStyle"
                [type]="section.type"
                [label]="section.label"
                [hideLabel]="section.hideLabel"
                [hintMsg]="section.hintMsg"
                [placeholder]="section.placeholder"
                [fieldControl]="section.fieldControl | asFormControl"
                [customFieldData]="section.customFieldData"
                [options]="section.options"
                [readOnly]="section.readOnly"
                [min]="section.min"
                [max]="section.max"
                [minDate]="section.minDate"
                [maxDate]="section.maxDate"
                [inputPattern]="section.inputPattern"
                [capitalize]="section.capitalize"
                [uppercase]="section.uppercase"
                [errorMsg]="section.errorMsg ?? ''"
                [group]="section.group"
                [customStyle]="section.customStyle">
              </app-form-field>
            </ng-container>

            <!-- Group item -->
            <ng-container *ngSwitchCase="'group-label'">
              <div class="section"
                [class]="section.size || ''"
                [ngClass]="section.customClass"
                [ngStyle]="section.customStyle">
                <!-- Title -->
                <div class="title">{{section.label}}</div>

                <!-- Content -->
                <div class="content">
                  <ng-container *ngFor="let child of section.childs">
                    <app-form-field
                      class="field"
                      [class]="child.size || ''"
                      [ngClass]="child.customClass"
                      [ngStyle]="child.customStyle"
                      [type]="child.type"
                      [label]="child.label"
                      [hideLabel]="child.hideLabel"
                      [hintMsg]="child.hintMsg"
                      [placeholder]="child.placeholder"
                      [fieldControl]="child.fieldControl | asFormControl"
                      [customFieldData]="child.customFieldData"
                      [options]="child.options"
                      [readOnly]="child.readOnly"
                      [min]="child.min"
                      [max]="child.max"
                      [inputPattern]="child.inputPattern"
                      [capitalize]="child.capitalize"
                      [uppercase]="child.uppercase"
                      [errorMsg]="child.errorMsg ?? ''"
                      [group]="child.group"
                      [customStyle]="child.customStyle">
                    </app-form-field>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <button class="delete-button"
          mat-icon-button
          color="warn"
          *ngIf="canDelete && (readOnly ? !readOnly() : true) && !fieldControl.disabled"
          (click)="delete(i)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
