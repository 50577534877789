<mat-dialog-content class="interaction-modal-container">
	<h3 class="title"> Colisión/interacción farmacológica</h3>
	<div class="content">
        <table mat-table [dataSource]="overlappedPrescriptions">
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">

                <th mat-header-cell *matHeaderCellDef>
                    <ng-container [ngSwitch]="column.columnDef">
                        <span *ngSwitchDefault>
                            {{column.header}}
                        </span>
                    </ng-container>
                </th>

                <td mat-cell *matCellDef="let row">
                    <ng-container [ngSwitch]="column.columnDef">

                        <span *ngSwitchCase="'StartDate'">
                            {{ row[column.columnDef] | date: 'd/M/y' }}
                        </span>
                        <span *ngSwitchCase="'EndDate'">
                            {{ row[column.columnDef] ? (row[column.columnDef] | date: 'd/M/y') : '-' }}
                        </span>
                        <span *ngSwitchCase="'OverlapType'">
                            {{ getInteractionName(row) }}
                        </span>
                        <span *ngSwitchDefault>
                            {{ row[column.columnDef]}}
                        </span>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
        </table>

        <ng-container *ngIf="overlapedType === 2">
            <p>Puede guardar la prescripción tal y como está o retornar al editor para modificar sus parámetros.</p>
            <p>¿Desea guardar la prescripción tal y como está?</p>
        </ng-container>

        <ng-container *ngIf="overlapedType === 1 && !couldOverride">
            <p>Puede retornar al editor para modificar los parámetros de la prescripción o cancelar la edición.</p>
            <p>¿Desea retornar al editor de prescripciones?</p>
        </ng-container>

        <ng-container *ngIf="overlapedType === 1 && couldOverride">
            <p>Puede guardar la prescripción tal y como está o retornar al editor para modificar sus parámetros.</p>
            <p>¿Desea guardar la prescripción tal y como está?</p>
        </ng-container>

	</div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="interaction-modal-buttons">

    <ng-container *ngIf="overlapedType === 2 || (overlapedType === 1 && couldOverride)">
        <button mat-raised-button
            tabindex="-1"
            [disabled]="!couldOverride"
            (click)="onConfirmClick('yes_incompatibility')"
        >Sí</button>
        <button class="accept" mat-raised-button (click)="onConfirmClick('no_incompatibility')" tabindex="1">No</button>
        <button mat-raised-button mat-dialog-close tabindex="-1">Cancelar</button>
    </ng-container>

    <ng-container *ngIf="overlapedType === 1 && !couldOverride">
        <button class="accept" mat-raised-button (click)="onConfirmClick('yes_sameMedicine')" tabindex="1">Sí</button>
        <button mat-raised-button mat-dialog-close tabindex="-1">No</button>
    </ng-container>

</mat-dialog-actions>
