<footer>
    <div class="footer-content">
        <div role="contentinfo" class="links-wrapper">
            <a mat-button [href]="linkTermsConditions" target="_blank">{{
                termsConditions
                }}</a>
            <a mat-button [href]="linkPrivacyPolicy" target="_blank">{{
                privacyPolicy
                }}</a>
            <a mat-button [href]="linkCookiesPolicy" target="_blank">{{
                cookiesPolicy
                }}</a>
        </div>
        <div class="right">
            <mat-icon
                (click)="notifications.length > 0 ? hideNotifications = !hideNotifications : hideNotifications = true"
                *ngIf="!isLogin()" class="notifications-flag" [class.pending]="notifications.length > 0"
                [class.show]="notifications.length > 0 && !hideNotifications">
                {{notifications.length > 0 ? 'flag' : 'outlined_flag'}}

                <div class="notifications">
                    <ng-container *ngFor="let noti of notifications">
                        <span class="title"
                            [class.only-title]="isNotiOnlyTitle(noti)"
                            (click)="isNotiOnlyTitle(noti) ? navigateTo(noti.lines[0].action) : undefined">{{noti.title}}</span>
                        <ng-container *ngFor="let line of noti.lines">
                            <span class="noti" *ngIf="line.display != undefined"
                                (click)="navigateTo(line.action)">{{line.display}}</span>
                        </ng-container>
                    </ng-container>
                </div>
            </mat-icon>
            <div role="contentinfo" class="version">
                <span class="app-version">Versión: {{ version }}</span>
            </div>
        </div>
    </div>
</footer>
