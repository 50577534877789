<ng-container *ngFor="let section of schema; let i = index;">
    <div class="section checkbox" 
      *ngIf="visibility[i]?.visible && section.type == 'group-checkbox'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <!-- Title -->
      <div class="title" >{{section.label}}</div>
      <!-- Content -->
      <div class="content">
        <ng-container *ngFor="let child of section.childs">
          <ng-container >
              <ng-container >
                <app-form-field 
                  class="field"
                  [class]="child.size || ''"
                  [ngClass]="child.customClass"
                  [ngStyle]="child.customStyle"
                  [type]="child.type" 
                  [label]="child.label" 
                  [hideLabel]="child.hideLabel"
                  [hintMsg]="child.hintMsg" 
                  [placeholder]="child.placeholder"
                  [fieldControl]="child.fieldControl | asFormControl" 
                  [customFieldData]="child.customFieldData"
                  [options]="child.options" 
                  [readOnly]="child.readOnly" 
                  [min]="child.min" 
                  [max]="child.max"
                  [inputPattern]="child.inputPattern" 
                  [capitalize]="child.capitalize"
                  [uppercase]="child.uppercase"
                  [errorMsg]="child.errorMsg ?? ''" 
                  [group]="child.group" 
                  [customStyle]="child.customStyle">
                </app-form-field>
              </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="section " 
      *ngIf="visibility[i]?.visible && section.type == 'text'"
      [class]="section.size || ''"
      [ngClass]="section.customClass"
      [ngStyle]="section.customStyle">

      <!-- Content -->
      <div class="content">
        <ng-container >
          <app-form-field 
            class="field"
            [class]="section.size || ''"
            [ngClass]="section.customClass"
            [ngStyle]="section.customStyle"
            [type]="section.type" 
            [label]="section.label" 
            [hideLabel]="section.hideLabel"
            [hintMsg]="section.hintMsg" 
            [placeholder]="section.placeholder"
            [fieldControl]="section.fieldControl | asFormControl" 
            [customFieldData]="section.customFieldData"
            [options]="section.options" 
            [readOnly]="section.readOnly" 
            [min]="section.min" 
            [max]="section.max"
            [inputPattern]="section.inputPattern" 
            [capitalize]="section.capitalize"
            [uppercase]="section.uppercase"
            [errorMsg]="section.errorMsg ?? ''" 
            [group]="section.group" 
            [customStyle]="section.customStyle">
          </app-form-field>
        </ng-container>
      </div>
    </div>

  </ng-container>