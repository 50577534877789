<ng-container class="report-custom-container">
  <app-report-select 
    [title]="'Reportes de Actus'"
    (previewClicked)="handlePreviewClick()" 
    (printClicked)="handlePrintClick()" 
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()">

    <div slot="content" class="container-options">
      <form [formGroup]="form">

        <section class="language col-12">
          <p class="subtitle">Idioma</p>

          <div  class="select col-12">
            <mat-form-field >
              <!-- <div class="label">Selecciona una opción</div> -->
              <mat-select formControlName="language">
                <mat-option *ngFor="let option of languages" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <section class="type">
          <p class="subtitle">Tipo de reporte</p>

          <mat-radio-group formControlName="reportType" (change)="form.controls['issuesOnly'].setValue(0)">
            <mat-radio-button value="master">
              Maestro
            </mat-radio-button>

            <mat-checkbox formControlName="issuesOnly" *ngIf="form.controls['reportType'].value === 'master'">
              Sólo incidencias
            </mat-checkbox>

            <mat-radio-button value="staff">
              Por personal
            </mat-radio-button>

            <mat-radio-button value="patients">
              Por pacientes
            </mat-radio-button>

            <mat-radio-button value="issues">
              Por incidencias
            </mat-radio-button>

            <mat-radio-button value="departments">
              Por departamentos
            </mat-radio-button>
          </mat-radio-group>
        </section>

        <section class="interval">
          <p class="subtitle">Intervalo</p>

          <mat-radio-group formControlName="interval" (change)="fillDates()">
            <mat-radio-button value="oneWeekAgo">
              Una semana atrás
            </mat-radio-button>

            <mat-radio-button value="twoWeeksAgo">
              Dos semanas atrás
            </mat-radio-button>

            <mat-radio-button value="oneMonthAgo">
              Un mes atrás
            </mat-radio-button>

            <mat-radio-button value="specificDay">
              Día específico
            </mat-radio-button>

            <div class="container-fields date" *ngIf="form.controls['interval'].value === 'specificDay'">
              <mat-form-field>
                <!-- <div class="label">Día específico</div> -->
                <input matInput [matDatepicker]="specificDay" formControlName="specificDay" (dateChange)="fillDates()" />
                <mat-datepicker-toggle matSuffix [for]="specificDay">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #specificDay></mat-datepicker>
              </mat-form-field>
            </div>

            <mat-radio-button value="fullWeek">
              Semana completa
            </mat-radio-button>

            <div class="container-fields date" *ngIf="form.controls['interval'].value === 'fullWeek'">
              <mat-form-field>
                <!-- <div class="label">Semana completa</div> -->
                <input matInput [matDatepicker]="week" formControlName="week" (dateChange)="fillDates()" />
                <mat-datepicker-toggle matSuffix [for]="week">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #week></mat-datepicker>
              </mat-form-field>
            </div>

            <mat-radio-button value="fullMonth">
              Mes completo
            </mat-radio-button>

            <div class="container-fields date" *ngIf="form.controls['interval'].value === 'fullMonth'">
              <mat-form-field appDateFormat>
                <!-- <div class="label">Mes completo</div> -->
                <input matInput [matDatepicker]="month" formControlName="month" />
                <mat-datepicker-toggle matSuffix [for]="month">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #month startView="year" (monthSelected)="setFullMonth($event, month, 'month')"></mat-datepicker>
              </mat-form-field>
            </div>

            <mat-radio-button value="dateInterval">
              Intervalo de fechas
            </mat-radio-button>

            <div class="container-fields date" *ngIf="form.controls['interval'].value === 'dateInterval'">
              <mat-form-field>
                <div class="label">Desde</div>
                <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" (dateChange)="fillDates()" />
                <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <div class="label">Hasta</div>
                <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" (dateChange)="fillDates()" />
                <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

          </mat-radio-group>
        </section>

        <section class="filter-container">
          <p class="subtitle">Filtrar por</p>

          <mat-checkbox (change)="toggleSelectIssue()" class="col-12" [disabled]="!selectOptionsIssues.length">
            Incidencia
          </mat-checkbox>
          <div  class="select col-12" *ngIf="showIssueSelect">
            <app-form-field
              class="field"
              [type]="'select'"
              [label]="''"
              [hideLabel]="true"
              [fieldControl]="form.controls['selectedIssue'] | asFormControl"
              [options]="selectOptionsIssues">
            </app-form-field>
          </div>

          <mat-checkbox (change)="toggleSelectPatient()" class="col-12" [disabled]="!selectOptionsPatients || !selectOptionsPatients.length">
            Paciente
          </mat-checkbox>
          <div *ngIf="showPatientSelect" class="select col-12 ">
            <app-form-field 
              class="col-6" 
              label="" 
              [hideLabel]="true"
              [type]="'autocomplete'" 
              [options]="selectOptionsPatientsAutocomplete" 
              [fieldControl]="form.controls['selectedPatient'] | asFormControl">
            </app-form-field>
          </div>
          
          <mat-checkbox (change)="toggleSelectPersonal()"  class="col-12" [disabled]="!selectOptionsPersonal.length">
            Personal
          </mat-checkbox>
          <div class="select col-12" *ngIf="showPersonalSelect">
            <app-form-field
              class="field"
              [type]="'select'"
              [label]="''"
              [hideLabel]="true"
              [fieldControl]="form.controls['selectedPersonal'] | asFormControl"
              [options]="selectOptionsPersonal">
            </app-form-field>
          </div>

          <mat-checkbox class="col-12" (change)="toggleSelectDepartment()" [disabled]="!selectOptionsDepartment.length">
            Departamento
          </mat-checkbox>
          <div *ngIf="showDepartmentSelect" class="select col-12">
            <app-form-field
              class="field"
              [type]="'select'"
              [label]="''"
              [hideLabel]="true"
              [fieldControl]="form.controls['selectedDepartment'] | asFormControl"
              [options]="selectOptionsDepartment">
            </app-form-field>
          </div>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
