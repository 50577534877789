import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { showFilters } from '@constants/reports/report-change-medications';
import { reportDataSevere } from '@constants/reports/report-severe';
import { schedulesOptions } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { PatientPharma, Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';

import moment, { Moment } from 'moment';

@Component({
  selector: 'app-report-severe',
  templateUrl: './report-severe.component.html',
  styleUrls: ['./report-severe.component.scss'],
})
export class ReportSevereComponent {
  public reportDataSevere = reportDataSevere;
  public form: FormGroup;
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;
  public selectOptionsPatientsWithoutFilter: PatientPharma[];
  public selectOptionsInsitutions: SelectOption[];
  public filtersMedChanges = showFilters;
  public schedulesOptions = schedulesOptions;

  public error = 'Debes elegir una fecha válida';
  public errorInterval = '';
  public errorMsgInterval =
    'ATENCION: No es posible generar el reporte con intervalos mayores a 21 días';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private calls: CallsService,
    private reportService: ReportsService,
    private institutionService: InstitutionService
  ) {
    this.form = this.formBuilder.group({
      startDate: [new Date()],
      endDate: [new Date()],
      selectedInstitution: [''],
    });
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.getDataForTheSelects();
    this.setCurrentDate();
    this.listenerDateChanges();
  }

  listenerDateChanges(): void {
    this.form.controls['startDate'].valueChanges.subscribe((value) => {
      const endDate = this.form.controls['endDate'].value;
      if (this.dateService.diffDates(value, endDate) > 20) {
        this.errorInterval = this.errorMsgInterval;
      } else {
        this.errorInterval = '';
      }
    });

    this.form.controls['endDate'].valueChanges.subscribe((value) => {
      const startDate = this.form.controls['startDate'].value;
      if (this.dateService.diffDates(startDate, value) > 20) {
        this.errorInterval = this.errorMsgInterval;
      } else {
        this.errorInterval = '';
      }
    });
  }
  setCurrentDate(): void {
    const currentDate = new Date();

    this.form.patchValue({ specificDay: currentDate });
  }

  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());
         // Order Alpabetically label and text uppercase
         this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
        //  this.selectOptionsInsitutions.map((a) =>{
        //      a.label = a.label.toUpperCase();
        //  })
      if (this.selectOptionsInsitutions.length > 0) {
        const hasAllInstitutionsOption = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (!hasAllInstitutionsOption) {
          this.selectOptionsInsitutions.unshift({
            value: '',
            label: 'Todos los centros',
          });
        }
      }
    });
  }

  handlePreviewClick(): void {
    if (this.form.valid && this.errorInterval === '') {
      const { startDate, endDate, selectedInstitution } = this.getFormData();

      this.setInfoRP(startDate, endDate, selectedInstitution, true);

      this.reportService.handlePreviewClick(this.router, this.reportDataSevere);
    } else {
      this.showErrors();
    }
  }

  getFormData(): {
    startDate: string;
    endDate: string;
    selectedInstitution: string;
  } {
    const selectedInstitution = this.form.get('selectedInstitution')?.value.id;

    const startDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('startDate')?.value).startOf('day').toDate())?.toISOString().split(".")[0];
    const endDate = Utils.BUG_FixDateForTimeZone(moment(this.form.get('endDate')?.value).endOf('day').toDate())?.toISOString().split(".")[0];

    return {
      // @ts-ignore
      startDate,
      // @ts-ignore
      endDate,
      selectedInstitution,
    };
  }

  handlePrintClick(): void {
    if (this.form.valid && this.errorInterval == '') {
      const { startDate, endDate, selectedInstitution } = this.getFormData();

      this.setInfoRP(startDate, endDate, selectedInstitution);
      this.reportService.handlePrintClick(this.reportDataSevere);
    } else {
      this.showErrors();
    }
  }

  handlePdfClick(): void {
    if (this.form.valid && this.errorInterval === '') {
      const { startDate, endDate, selectedInstitution } = this.getFormData();
      this.setInfoRP(startDate, endDate, selectedInstitution);
      this.reportService.handlePdfClick(this.reportDataSevere);
    } else {
      this.showErrors();
    }
  }

  handleExcelClick(): void {
    if (this.form.valid && this.errorInterval === '') {
      const { startDate, endDate, selectedInstitution } = this.getFormData();

      this.setInfoRP(startDate, endDate, selectedInstitution);
      this.reportService.handleExcelClick(this.reportDataSevere);
    } else {
      this.showErrors();
    }
  }

  showErrors(): void {
    if (this.errorInterval) {
      this.calls.openSnack(this.errorInterval);
    } else {
      this.calls.openSnack(this.error);
    }
  }

  setInfoRP(
    startDate: string,
    endDate: string,
    selectedInstitution: string,
    isPreview: boolean = false
  ): void {
    const rp = JSON.stringify({
      LocaleId: '3082',
      DateFrom: startDate,
      DateUntil: endDate,
      InstitutionId: selectedInstitution == '' ? null : selectedInstitution,
    });

    isPreview ? this.reportDataSevere.data.dn = Utils.buildUniqueFileName + "_{0}.html" : null;
    const now = moment();

    const institution = this.form.get('selectedInstitution')?.value.shortName || 'Todas';

    this.reportDataSevere.dataDownloadPDF.dn = this.reportDataSevere.dataRenderReport.dn = `Reporte_de_agudos_${institution}_del_${moment(startDate).format('YYYYMMDD')}_al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
    this.reportDataSevere.dataDownloadExcel.dn = `Reporte_de_agudos_${institution}_del_${moment(startDate).format('YYYYMMDD')}_al_${moment(endDate).format('YYYYMMDD')}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;

    this.reportDataSevere.data.rp = rp;
    this.reportDataSevere.dataDownloadPDF.rp = rp;
    this.reportDataSevere.dataDownloadExcel.rp = rp;
    this.reportDataSevere.dataRenderReport.rp = rp;
  }
}
