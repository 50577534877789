<div class="container-fluid dynamic-form-container">
    <div class="row" [ngStyle]="customStyle">
        <div *ngFor="let item of schema; let i = index;" [class]="item.size" >
            <ng-container [ngSwitch]="item.type" *ngIf="visibility[i].visible">
                <ng-container *ngSwitchCase="'group-label'">
                    <h5 *ngIf="!item.hideLabel">{{ item.label }}</h5>
                    <app-dynamic-form [schema]="item.childs ?? []"></app-dynamic-form>
                </ng-container>

                <ng-container *ngSwitchCase="'group-checkbox'">
                    <h5 *ngIf="!item.hideLabel">{{ item.label }}</h5>
                    <div class="custom-title">
                        <p *ngIf="item.withTitle">{{ item.label }}</p>
                    </div>
                    <div>
                        <app-dynamic-form [schema]="item.childs ?? []" [customStyle]="item.customStyle"></app-dynamic-form>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'tabs-group'">
                    <mat-tab-group animationDuration="0ms">
                        <mat-tab *ngFor="let child of item.childs; let i = index">
                            <ng-template mat-tab-label>
                                {{ child.label }}
                            </ng-template>
                            <div class="mt-2">
                                <app-dynamic-form [schema]="item.childs ? [item.childs[i]] : []"></app-dynamic-form>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>

                <ng-container *ngSwitchCase="'group-expansion'">
                    <app-expansion-group [schema]="item.childs ?? []" [fieldControl]="item.fieldControl | asFormGroup"
                        [label]="item.label" [fieldsData]="item.customFieldData">
                    </app-expansion-group>
                </ng-container>

                <ng-container *ngSwitchCase="'expand-resume-group'">
                    <app-expand-resume-group [label]="item.label" [fieldControl]="item.fieldControl | asFormGroup"
                        [schema]="item.childs ?? []"></app-expand-resume-group>
                </ng-container>

                <ng-container *ngSwitchCase="'info'">
                    <div class="info" *ngIf="!item.hideLabel" [ngStyle]="item.customStyle">{{ item.label }}</div>
                </ng-container>

                <ng-container *ngSwitchCase="'custom'">
                    <ng-container [ngSwitch]="item.customType">
                        <ng-container *ngSwitchCase="'imc'">
                            <app-imc [label]="item.label" [hintMsg]="item.hintMsg"
                                [fieldControl]="item.fieldControl | asFormControl"
                                [fieldsData]="item.customFieldData"></app-imc>
                        </ng-container>

                        <ng-container *ngSwitchCase="'date-info'">
                            <app-date-info [readOnly]="item.readOnly" [label]="item.label" [hideLabel]="item.hideLabel"
                                [fieldControl]="item.fieldControl | asFormControl"></app-date-info>
                        </ng-container>

                        <ng-container *ngSwitchCase="'array-form'">
                            <app-array-subform [readOnly]="item.readOnly" [fieldControl]="item.fieldControl | asFormControl"
                                [formConfig]="item.customFieldData"></app-array-subform>
                        </ng-container>

                        <ng-container *ngSwitchCase="'list-form'">
                            <app-list-subform [fieldControl]="item.fieldControl | asFormControl"
                                [formConfig]="item.customFieldData"></app-list-subform>
                        </ng-container>

                        <ng-container *ngSwitchCase="'table-form'">
                            <app-table-form [readOnly]="item.readOnly" [fieldControl]="item.fieldControl | asFormGroup"
                                [formConfig]="item.customFieldData"></app-table-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'diagnosgtics-form'">
                            <app-diagnogstics-form [readOnly]="item.readOnly"
                                [fieldControl]="item.fieldControl | asFormControl"></app-diagnogstics-form>
                        </ng-container>

                        <ng-container *ngSwitchCase="'documents-preview-upload'">
                            <app-documents-preview-upload
                                [fieldControl]="item.fieldControl | asFormControl"></app-documents-preview-upload>
                        </ng-container>

                        <ng-container *ngSwitchCase="'re-cronica'">
                            <app-ultima-re *ngIf="$any(patient?.rawData?.pat?.id) != undefined" [label]="item.label"
                                [fieldControl]="item.fieldControl | asFormControl"
                                [patientId]="$any(patient?.rawData?.pat?.id)"
                                [formChanged]="formChanged"
                                [readOnly]="item.readOnly"
                                (save)="saveRe()"></app-ultima-re>
                        </ng-container>

                        <ng-container *ngSwitchCase="'re-aguda'">
                            <app-ultima-re *ngIf="$any(patient?.rawData?.pat?.id) != undefined" [label]="item.label"
                                [fieldControl]="item.fieldControl | asFormControl"
                                [patientId]="$any(patient?.rawData?.pat?.id)"
                                [formChanged]="formChanged"
                                [readOnly]="item.readOnly"
                                (save)="saveRe()"></app-ultima-re>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            No custom element found
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="!item.childs; else elseTemplate">
                        <app-form-field [type]="item.type" [label]="item.label" [hideLabel]="item.hideLabel"
                            [hintMsg]="item.hintMsg" [placeholder]="item.placeholder"
                            [fieldControl]="item.fieldControl | asFormControl" [customFieldData]="item.customFieldData"
                            [options]="item.options" [readOnly]="item.readOnly" [min]="item.min" [max]="item.max"
                            [minDate]="item.minDate" [maxDate]="item.maxDate"
                            [inputPattern]="item.inputPattern" [capitalize]="item.capitalize"
                            [uppercase]="item.uppercase"
                            [errorMsg]="item.errorMsg ?? ''" [group]="item.group" [customStyle]="item.customStyle"></app-form-field>
                    </ng-container>

                    <ng-template #elseTemplate> No element found </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
