import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormService } from '@services/form.service';
import { MatExpansionPanel } from '@angular/material/expansion';

import { SortService } from '@services/sort.service';
import { ListSubformComponent } from '@shared/list-subform/list-subform.component';

import { FormItemSchemaInterface } from '@interfaces/dynamic-form.interface';
import { trigger } from '@angular/animations';
import moment from 'moment';

@Component({
    selector: 'app-list-subform-lock',
    templateUrl: './list-subform-lock.component.html',
    styleUrls: ['./list-subform-lock.component.scss'],
})
export class ListSubformLockComponent extends ListSubformComponent implements OnInit, OnDestroy {

    @ViewChildren(MatExpansionPanel) expansionPanel: QueryList<MatExpansionPanel>[];

    public added: boolean = false;

    constructor(
        private cdRef: ChangeDetectorRef,
        protected override   formsService: FormService,
        protected override  sortService: SortService,
    ) {
        super(formsService, sortService)
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }
    override ngOnDestroy(): void {
    }

    getTitle(item: AbstractControl): string {
        return item.value.block.title;
    }

    addNewElement() {
        super.addOne(true);

        this.added = true;

        this.cdRef.detectChanges();
        // @ts-ignore
        this.expansionPanel.toArray().pop().open();
    }

    canBeDeleted(item: FormItemSchemaInterface[]): boolean {
        const currentDate = new Date();

        const id = item[0].childs?.find(child => child.label == 'Id del bloqueo')?.fieldControl.value;
        const startDate = new Date(item[0].childs?.find(child => child.customType === 'start-date')?.fieldControl.value);
        const startDateValid = item[0].childs?.find(child => child.customType === 'start-date')?.fieldControl.status;
        const endDate = new Date(item[0].childs?.find(child => child.customType === 'end-date')?.fieldControl.value);

        return id == undefined || (moment(startDate).isSameOrAfter(moment(), 'day'));
    }

    modificableItems: FormItemSchemaInterface[][] = [];

    canBeModified(item: FormItemSchemaInterface[]): boolean {
        const currentDate = new Date();

        const id = item[0].childs?.find(child => child.label == 'Id del bloqueo')?.fieldControl.value;
        if (id == undefined) return true;

        const startDate = new Date(item[0].childs?.find(child => child.customType === 'start-date')?.fieldControl.value);
        const startDateValid = item[0].childs?.find(child => child.customType === 'start-date')?.fieldControl.status;
        const endDate = new Date(item[0].childs?.find(child => child.customType === 'end-date')?.fieldControl.value);

        const flag = id == undefined || (moment(startDate).isSameOrAfter(moment(), 'day')) || endDate.getTime() == 0;
        if (flag && !this.modificableItems.includes(item)) {
            this.modificableItems.push(item);
        }

        if (this.modificableItems.includes(item)) {
            if (!moment(startDate).isSameOrAfter(moment(), 'day'))
                item[0].childs?.find(c => c.customType == 'start-date')?.fieldControl.disable({emitEvent: false, onlySelf: true});
            return true;
        }
        else return false;
    }

}
