<app-report-select
  class="report-custom-container"
  [title]="'Medicamentos faltantes'"
  (previewClicked)="handlePreviewClick()"
  (printClicked)="handlePrintClick()"
  (pdfClicked)="handlePdfClick()"
  (excelClicked)="handleExcelClick()"
>
  <div slot="content" class="container-options">
    
    
    <form [formGroup]="missingMedicationForm" >

      

      <p class="subtitle">Tipo de reporte</p>
      <section class="filter-container" >
        <mat-radio-group formControlName="selectedType">
          <mat-radio-button *ngFor="let type of typesMissingMedicine" class="col-4" [value]="type.name">
            {{type.label}}
          </mat-radio-button>

        </mat-radio-group>
      </section>

      <section class="filter-container" *ngIf="typeGlobal && missingMedicationForm.controls['selectedType'].value === 'missing-medicine-patient'" >
        <div class="subtitle">Centro</div>
        <app-form-field
          class="field"
          [type]="'select'"
          label="Selecciona un centro"
          [fieldControl]="missingMedicationForm.controls['selectedInstitution'] | asFormControl"
          [options]="selectOptionsInsitutions">
        </app-form-field>
      </section>

    </form>
  </div>
</app-report-select>