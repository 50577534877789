import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { shortenWithEllipsis } from '@constants/funtions-utils';
import { CardActionRA, ClassDescription } from '@interfaces/card-actions';

@Component({
  selector: 'app-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          height: 'auto',
          minWidth: '330px',
          maxWidth: '330px',
          width: '330px',
        })
      ),
      state(
        'collapsed',
        style({
          height: 'auto',
          minWidth: '330px',
          maxWidth: '330px',
        })
      ),
      transition('expanded <=> collapsed', animate('200ms ease-in-out')),
    ]),
  ],
})
export class ExpandableCardComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() description: string;
  @Input() additionalInfo: string[];
  @Input() actions: CardActionRA[];
  @Input() isExpanded: boolean = false;
  @Input() descriptionClass: ClassDescription = 'normal';
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('@expandCollapse') get cardState(): 'expanded' | 'collapsed' {
    return this.isExpanded ? 'expanded' : 'collapsed';
  }

  toggleCardState() {
    this.toggle.emit();
  }

  shortedTitle(num: number): string {
    return shortenWithEllipsis(this.title, num);
  }
}
