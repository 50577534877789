<mat-expansion-panel class="expand-resume-group">
    <mat-expansion-panel-header>
        <mat-panel-title class="title">
            {{label}}
        </mat-panel-title>
        <mat-panel-description>
            <div *ngFor="let status of statusList; let i = index"
                class="resume_icon"
                [ngClass]="{ 'filled': status }"
            >
                <span>{{i + 1}}</span>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <app-dynamic-form
        [schema]="schema"
    ></app-dynamic-form>
</mat-expansion-panel>