import { Component, Input } from '@angular/core';
import { inOutFade } from '@json/src/app/animations/general';

import { ColorLegendItem } from '@interfaces/color-legend-item.interface';

import { ColorLegendItemType } from '@json/src/app/enums/color-legend-item-type';

@Component({
  selector: 'app-color-legend',
  templateUrl: './color-legend.component.html',
  styleUrls: ['./color-legend.component.scss'],
  animations: [inOutFade]
})
export class ColorLegendComponent {

  @Input() legendPosition: 'bottom' | 'top' | 'left' | 'right' = 'bottom';
  @Input() items: ColorLegendItem[] = [];

  ColorLegendItemType = ColorLegendItemType;

  legendStatus: boolean = false;

  constructor() { }

  getBackgroundStyle(item: ColorLegendItem): any {
    return {...{'background-color': item.color}, ...item.data?.customStyle}
  }
  getIconStyle(item: ColorLegendItem): any {
    return {...{'color': item.color}, ...item.data?.iconStyle}
  }
}
