<div class="content" [ngClass]="{'expanded': isExpanded}">
  <div class="top">
    <!-- Profile -->
    <app-logged-user-detail
      *ngIf="auth.user != undefined"
      [input]="auth.user"
      [isExpanded]="isExpanded"
    ></app-logged-user-detail>

    <!-- Institution -->
    <app-dropdown-select-institution
      *ngIf="showSelect"
      [isExpanded]="isExpanded"
    ></app-dropdown-select-institution>

    <!-- Logo -->
    <div id="pharma-logo">
      <div class="logo-container"
        role="img"
        aria-label="pharma logo"
        [ngClass]="{'icon-only': !isExpanded}"
        (click)="isExpandedChange.emit(!isExpanded)"></div>
    </div>
  </div>

  <!-- Menu -->
  <div class="menu">
    <mat-nav-list>
      <ng-container *ngFor="let item of config">
        <app-menu-item #menu *ngIf="testPerm(item)" [item]="item" [(isExpanded)]="isExpanded"></app-menu-item>
      </ng-container>
    </mat-nav-list>
  </div>

  <!-- Help and manual buttons -->
  <div class="help">
    <a class="button" [href]="helpLink" target="_blank" matTooltip="Ayuda">
      <mat-icon>help_outline</mat-icon>
    </a>

    <!-- <a class="button" [href]="manualLink" target="_blank" matTooltip="Manual de usuario">
      <mat-icon>menu_book</mat-icon>
    </a> -->
  </div>

  <!-- Button contrast -->
  <div class="contrast">
    <div class="container-switch">
      <label class="toggle-switch">
        <input type="checkbox"
          [(ngModel)]="contrastStatus" (change)="onContrastChange()">
        <div class="slider"></div>
      </label>
    </div>
  </div>

  <!-- Notifications -->
  <div class="bottom" [ngClass]="{'expanded': isExpanded}">
    <mat-icon
      (click)="notifications.length > 0 ? hideNotifications = !hideNotifications : hideNotifications = true"
      class="notifications-flag"
      [class.pending]="notifications.length > 0"
      [class.show]="notifications.length > 0 && !hideNotifications"
    >
      {{notifications.length > 0 ? 'flag' : 'outlined_flag'}}
    </mat-icon>

    <div class="notifications"
      [class.only-one]="notifications.length === 1"
      [class.show]="notifications.length > 0 && !hideNotifications"
    >
      <ng-container *ngFor="let noti of notifications">
        <span class="title"
          [class.only-title]="isNotiOnlyTitle(noti)"
          (click)="isNotiOnlyTitle(noti) ? navigateTo(noti.lines[0].action) : undefined">
          {{noti.title}}
        </span>
        <ng-container *ngFor="let line of noti.lines">
          <span class="noti"
            *ngIf="line.display != undefined"
            (click)="navigateTo(line.action); hideNotifications = true">
            {{line.display}}
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
