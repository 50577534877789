import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { createBinaryString, objectToXMLString } from '@constants/funtions-utils';
import { APIResponseParsed } from '@interfaces/api/api.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    Interaction,
    InteractionAPI,
} from '@interfaces/medicine/interaction.interface';
import {
    AdministrationRoute,
    Medicine,
    MedicineAdminRoute,
    MedicineAdminRouteAPI,
    MedicineAPI,
    MedicineDropdownAPI,
    MedicineEdit,
    MedicineEditEmpty,
    MedicineEditLinqData,
    MedicineFieldsAPI,
    MedicineFilterCondition,
    MedicineFlags,
    MedicineForm,
    MedicineOption,
    MedicineSelectFilter,
    MedicineUpdateAPI,
    OverlappedPrescription,
    ActiveIngredient
} from '@interfaces/medicine/medicine.interface';
import { PrescriptionEditApp, PrescriptionInteractionDataAPI } from '@interfaces/patient/patient.interface';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { CallsService } from './api/calls.service';
import { FilterService } from './filter/filter.service';

import { LoadingService } from './loading.service';
import { AuthService } from './auth.service';
import { Events } from './events.service';
import { EventType } from '../enums/event-type';

@Injectable({
    providedIn: 'root',
})
export class MedicinesService {
    //medicines
    public _medicineList: MedicineAPI[] = [];
    public medicineList: BehaviorSubject<{GuiaPreferente: string, medicines: MedicineAPI[]} | undefined> = new BehaviorSubject<
        {GuiaPreferente: string, medicines: MedicineAPI[]} | undefined
    >(undefined);

    //medicine edit
    public _currentMedicine: MedicineEdit;
    public currentMedicine: BehaviorSubject<MedicineEdit> =
        new BehaviorSubject<MedicineEdit>(new MedicineEditEmpty());

    //medicine admin route
    private _medicineAdminRoute: MedicineAdminRouteAPI[] = [];
    public medicineAdminRouteList: BehaviorSubject<MedicineAdminRouteAPI[]> =
        new BehaviorSubject<MedicineAdminRouteAPI[]>([]);

    //selectMedicineList
    private _medicineListSelect: MedicineAPI[] = [];
    public medicineListSelect: BehaviorSubject<MedicineAPI[]> =
        new BehaviorSubject<MedicineAPI[]>([]);

    constructor(
        private calls: CallsService,
        private filterService: FilterService,
        private loadingService: LoadingService,
        private events: Events
    ) {
        this.events.subscribe(EventType.InstitutionChange, () => {
            this.clearStoredData();
        });
    }

    clearStoredData(emit: boolean = true): void {
        this._medicineList = [];
        if(emit)
            this.medicineList.next(undefined);
        this._currentMedicine = new MedicineEditEmpty();
        if (emit)
            this.currentMedicine.next(new MedicineEditEmpty());
        this._medicineAdminRoute = [];
        if (emit)
            this.medicineAdminRouteList.next([]);
    }

    clearCurrentMedicine() {
        this.currentMedicine.next(new MedicineEditEmpty());
    }

    transformMedicineAPItoApp(medicine: MedicineAPI): Medicine {
        return new Medicine(medicine);
    }

    transformMedicineEditToForm(medicine: MedicineEdit, asNew: boolean = false): MedicineForm {
        return new MedicineForm(medicine, asNew);
    }

    transformMedicineAdminRouteAPItoApp(
        medicine: MedicineAdminRouteAPI
    ): MedicineAdminRoute {
        return new MedicineAdminRoute(medicine);
    }

    transformMedicineIAdminRouteToSelectOption(
        adminRoute: MedicineAdminRoute
    ): SelectOption {
        return {
            label: adminRoute.name,
            value: adminRoute.id,
        };
    }

    transformMedicineFormToUpdateMedicine(
        medicine: MedicineForm
    ): MedicineUpdateAPI {
        return new MedicineUpdateAPI(medicine);
    }

    updateMedicine(medicine: MedicineForm, medicineId: number, linqData?: { farmaticLinks: Array<MedicineEditLinqData> }) {
        let data = this.transformMedicineFormToUpdateMedicine(medicine);
        data.Id = medicineId;
        data.LinqData = linqData;
        const dataString = JSON.stringify(data);
        return new Promise<number>((resolve, reject) => {
            this.calls.insertUpdateMedicine(dataString).subscribe({
                next: (response) => {
                    if ( !(!!medicineId) ) {
                        try {
                            medicineId = response.payload[0].Column1;
                        } catch(e) {
                            this.calls.openSnack(
                                'Error al crear medicina'
                            );
                            reject();
                        }
                    }

                    this.getMedicineEdit(medicineId)
                    .then(() => {
                        resolve(medicineId);
                        this.calls.openSnack('Se han guardado los cambios correctamente', 'Ok');
                    })
                    .catch(() => {
                        reject();
                    });
                },
                error: (error) => {
                    if (
                        error.message.includes(
                            'No se puede insertar una fila de clave duplicada en el objeto'
                        )
                    ) {
                        this.calls.openStaticSnack(
                            'Ya existe un medicamento con ese código maestro. Porfavor, proporciona otro "Código maestro"',
                            'OK'
                        );
                    } else {
                        this.calls.openSnack(
                            'Error al obtener las medicinas (datos de edición)'
                        );
                    }
                    reject();
                },
            });
        });
    }

    getMedicineEdit(id: number) {
        return new Promise<void>((resolve) => {
            this.calls.getMedicineByID(id).subscribe({
                next: (res) => {
                    this._currentMedicine = res.payload;
                    this.currentMedicine.next(this._currentMedicine);
                    resolve();
                },
                error: () => {
                    // this.calls.openSnack(
                    //   'Error al obtener las medicinas (datos de edición)'
                    // );
                },
            });
        });
    }

    getMedicineAdminRoute(
        activeOnly: boolean,
        forPrescription: boolean,
        instituionId: number
    ): void {
        this.calls
            .getMedicineAdminRoutes(activeOnly, forPrescription, instituionId)
            .subscribe({
                next: (res) => {
                    this._medicineAdminRoute = res.payload;
                    this._medicineAdminRoute.forEach(m => m.Name = m.Name.toUpperCase());
                    this.medicineAdminRouteList.next(this._medicineAdminRoute);
                },
                error: () => {
                    this.calls.openSnack(
                        'Error al obtener las Vías de administracción'
                    );
                },
            }
            );
    }

    getMedicineAdminRouteAsOptions(): Observable<SelectOption[]> {
        return this.medicineAdminRouteList.pipe(
            map(list => list.map(med => {
                return {
                    label: med.Name,
                    value: med.Id
                }
            }))
        )
    }

    deleteMedicine(idMedicine: number): Observable<APIResponseParsed> {
        return this.calls.deleteMedicine(idMedicine);
    }

    getMedicines(force = false) {
        return new Promise<void>((resolve) => {
            this.calls.getMedicinesListAPI().subscribe({
                next: (res) => {
                    this._medicineList = res.payload.meds;
                    this._medicineList.sort((a, b) => a.nam.localeCompare(b.nam));
                    this.medicineList.next({
                        GuiaPreferente: res.payload.GuiaPreferente,
                        medicines: this._medicineList
                    });
                },
                error: () => {
                    this.calls.openSnack('Error al obtener las Medicinas');
                },
                complete: () => resolve()
            });
        })
    }

    getMedicinesAdministrationRoutesOptions(institutionId: number): Observable<SelectOption[]> {
        return this.calls.getMedicinesListAPIDropdown(institutionId).pipe(
            map((res) => {
                return res.payload.map((via: AdministrationRoute) => {
                    return {
                        label: via.Name,
                        value: via.Id,
                    };
                });
            }),
            catchError(() => {
                this.calls.openSnack('Error al obtener las vías de administración');
                return [];
            })
        );
    }

    getMedicinesOptions(institutionId: number, filter: string | null = null): Observable<MedicineOption[]> {
        return this.calls.getMedicinesListAPIDropdown(institutionId, filter).pipe(
            map((res) => {
                const result = res.payload.map((med: MedicineDropdownAPI) => {
                    return {
                        label: med.nam,
                        value: {
                            ...med,
                            flags: this.getFlagsFromFlgs(med.flg)
                        }
                    }
                }).sort((a: MedicineOption, b: MedicineOption) => a.label.localeCompare(b.label));
            
                return result;
            }),
            catchError(() => {
                this.calls.openSnack('Error al obtener el listado de medicinas');
                return [];
            })
        );
    }
    getMedicinesOptionsByActiveIngredients(institutionId: number, activeIngredients: number[]): Observable<MedicineOption[]> {
        return this.calls.getMedicinesListAPIDropdown(institutionId, null, activeIngredients).pipe(
            map((res) => {
                const result = res.payload.map((med: MedicineDropdownAPI) => {
                    return {
                        label: med.nam,
                        value: {
                            ...med,
                            flags: this.getFlagsFromFlgs(med.flg)
                        }
                    }
                }).sort((a: MedicineOption, b: MedicineOption) => a.label.localeCompare(b.label));
            
                return result;
            }),
            catchError(() => {
                this.calls.openSnack('Error al obtener el listado de medicinas por principios activos');
                return [];
            })
        );
    }

    getFlagsFromFlgs(flg: number): MedicineFlags | any {
        const flags = createBinaryString(flg);
        const last = flags.length - 1;

        return {
            noBlister: !!Number(flags[last - 0]),
            payMed: !!Number(flags[last - 1]),
            noPartial: !!Number(flags[last - 2]),
            noTray: !!Number(flags[last - 3]),
            noStock: !!Number(flags[last - 4]),
            noHalf: !!Number(flags[last - 5]),
            ra: !!Number(flags[last - 6]),
            mec: !!Number(flags[last - 7]),
            diaper: !!Number(flags[last - 8]),
        }
    }

    getMedicineFields(id: number): Observable<MedicineFieldsAPI> {
        return this.calls.getMedicineFields(id).pipe(
            map(res => { return res.payload }),
            catchError(() => {
                this.calls.openSnack(
                    'Error al obtener la información del medicamento'
                );
                return [];
            })
        );
    }

    getMedicinesList(institutionId: number): void {
        this.calls.getMedicineList(institutionId).subscribe({
            next: (res) => {
                this._medicineListSelect = res.payload;
                this._medicineListSelect.sort((a, b) => a.nam.localeCompare(b.nam));
                this.medicineListSelect.next(this._medicineListSelect);
            },
            error: () => {
                this.calls.openSnack('Error al obtener las Medicinas');
            },
        });
    }

    getActiveIngredientsList(filter?: string): Observable<ActiveIngredient[]> {
        return this.calls.getActiveIngredientsList(filter).pipe(
            map(res => { return res.payload }),
            catchError(() => {
                this.calls.openSnack(
                    'Error al obtener los principios activos'
                );
                return [];
            })
        );
    }

    getCompatibleActiveIngredientsList(activeIngredients: number[]): Observable<ActiveIngredient[]> {
        return this.calls.getCompatibleActiveIngredientsList(activeIngredients).pipe(
            map(res => { return res.payload }),
            catchError(() => {
                this.calls.openSnack(
                    'Error al obtener los principios activos'
                );
                return [];
            })
        );

    }

    getMedicineInteractions(data: PrescriptionEditApp, patientId: number, prescriptionId?: number): Observable<APIResponseParsed> {

        const parsedData = new PrescriptionInteractionDataAPI(data, patientId, prescriptionId);

        return this.calls.getMedicineInteractions(parsedData).pipe(
            catchError(() => {
                this.calls.openSnack('Error al obtener las interacciones');
                return [];
            })
        );
    }

    transformInteractionToXML(overlap: OverlappedPrescription[]): string {
        let result = '<ArrayOfAlert>';
        overlap.forEach(interaction => {
            result += '<Alert>';
            result += objectToXMLString(interaction);
            result += '</Alert>';
        })
        result += '</ArrayOfAlert>';
        return result;
    }

    getMedicinesByFilter(filter: string): void {
        this.calls.getMedicinesListAPIByFilter(filter).subscribe({
            next: (res) => {
                this._medicineList = res.payload.meds;
                this.medicineList.next({
                    GuiaPreferente: res.payload.GuiaPreferente,
                    medicines: this._medicineList
                });
            },
            error: () => {
                this.calls.openSnack(
                    'Error al obtener las medicinas filtradas por el filtro'
                );
            },
        });
    }

    getSelectedAdminRoute(array: MedicineAdminRoute[], id: Number): string {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id == id.toString()) {
                return array[i].id;
            }
        }
        return '';
    }

    filterMedicinesById(medicines: MedicineAPI[], id: string | null) {
        return medicines.filter(
            (medicine: MedicineAPI) => medicine.id.toString() === id
        );
    }

    convertToInteraction(interactions: InteractionAPI[]): Interaction[] {
        const res = interactions.map((interaction) => new Interaction(interaction));

        return res.length === 1 ? [res[0]] : res;
    }

    getBookmarkFlags(flg: number): string[] {
        let flags = [];
        if (this.isNoBlister(flg)) {
            flags.push('no-emblistable');
        }

        if (this.isAlzheimer(flg)) {
            flags.push('alzheimer');
        }

        if (this.isUnidosis(flg)) {
            flags.push('unitdose');
        }

        if (this.isCobert(flg)) {
            flags.push('cobert');
        }

        // if (this.isOutStock(flg)) {
        //     flags.push('no-inventary');
        // }

        if ( this.isNoPrescribe(flg) ) {
            flags.push('no-prescription');
        }

        return flags;
    }

    isNoBlister(flg: number): boolean {
        return (flg & 1) > 0;
    }

    isAlzheimer(flg: number): boolean {
        return (flg & 2) > 0;
    }

    isObsolete(flg: number): boolean {
        return (flg & 4) > 0;
    }

    isCobert(flg: number): boolean {
        return (flg & 8) > 0;
    }

    isUnidosis(flg: number): boolean {
        return (flg & 0x10) > 0;
    }

    isOutStock(flg: number): boolean {
        return (flg & 0x20) > 0;
    }

    isDiaper(flg: number): boolean {
        return (flg & 0x40) > 0;
    }

    isRA(flg: number): boolean {
        return (flg & 0x80) > 0;
    }

    isMEC(flg: number): boolean {
        return (flg & 0x100) > 0;
    }

    isR0(flg: number): boolean {
        return (flg & 0x200) > 0;
    }

    isNoPrescribe(flg: number): boolean {
        return (flg & 0x400) > 0;
    }

    isHospitalUse(flg: number): boolean {
        return (flg & 0x800) > 0;
    }

    isAntidepressant(flg: number): boolean {
        return (flg & 0x1000) > 0;
    }

    isAnsiolitic(flg: number): boolean {
        return (flg & 0x2000) > 0;
    }

    isAntipsychotic(flg: number): boolean {
        return (flg & 0x4000) > 0;
    }

    isAnticonvulsant(flg: number): boolean {
        return (flg & 0x8000) > 0;
    }

    isAntibiotic(flg: number): boolean {
        return (flg & 0x10000) > 0;
    }
    
    isAnticoagulant(flg: number): boolean {
        return (flg & 0x20000) > 0;
    }

    isAINE(flg: number): boolean {
        return (flg & 0x40000) > 0;
    }

    isIBP(flg: number): boolean {
        return (flg & 0x80000) > 0;
    }

    isDiabetes(flg: number): boolean {
        return (flg & 0x100000) > 0;
    }

    isOpioid(flg: number): boolean {
        return (flg & 0x200000) > 0;
    }

    hasAlert(flg: number): boolean {
        return (flg & 0x20) > 0;
    }

    alertReason(nstk: number): string {
        switch (nstk) {
            case 0: return ''; break;
            case 1: return 'Sin inventario'; break;
            case 2: return 'Suministro limitado'; break;
            default: return ''; break;
        }
    }

    getFilterConditions(
        flag: number,
        filters: MedicineSelectFilter
    ): MedicineFilterCondition {
        return {
            roCondition: filters.RO ? true : !this.isR0(flag),
            raCondition: filters.RA ? true : !this.isRA(flag),
            mecCondition: filters.MEC ? true : !this.isMEC(flag),
            alzheimerCondition: filters.alzheimer ? true : !this.isAlzheimer(flag),
            obsoleteCondition: filters.obsolete ? true : !this.isObsolete(flag),
            noPrescriptionCondition: filters.noPrescription
                ? true
                : !this.isNoPrescribe(flag),
            unitDoseCondition: filters.unitDose ? true : !this.isUnidosis(flag),
            notCobertCondition: filters.cobert ? true : this.isCobert(flag),
            embistableCondition: filters.noEmbistable
                ? true
                : !this.isNoBlister(flag),
            noInventaryCondition: filters.noInventary ? true : !this.isOutStock(flag),
            noneCondition: filters.none
                ? true
                : this.isR0(flag) &&
                this.isRA(flag) &&
                this.isAlzheimer(flag) &&
                this.isObsolete(flag) &&
                this.isUnidosis(flag) &&
                !this.isCobert(flag) &&
                this.isNoBlister(flag) &&
                this.isOutStock(flag),
        };
    }

    checkAllConditions(conditions: MedicineFilterCondition): boolean {
        return (
            conditions.roCondition &&
            conditions.mecCondition &&
            conditions.obsoleteCondition &&
            conditions.noPrescriptionCondition &&
            conditions.raCondition &&
            conditions.unitDoseCondition &&
            conditions.notCobertCondition &&
            conditions.embistableCondition &&
            conditions.noInventaryCondition &&
            conditions.alzheimerCondition &&
            conditions.noneCondition
        );
    }

    getFilters(form: FormGroup<any>): MedicineSelectFilter {
        return {
            cobert: this.filterService.getFilterCobert(form),
            noEmbistable: this.filterService.getFilterNoEmbistable(form),
            alzheimer: this.filterService.getFilterAlzheimer(form),
            unitDose: this.filterService.getFilterUnitDose(form),
            RA: this.filterService.getFilterRA(form),
            MEC: this.filterService.getFilterMEC(form),
            RO: this.filterService.getFilterRO(form),
            obsolete: this.filterService.getFilterObsolete(form),
            noInventary: this.filterService.getFilterNoInventary(form),
            noPrescription: this.filterService.getFilterNoPrescription(form),
            hospitalUse: this.filterService.getFilterHospitalUse(form),
            none: this.filterService.getFilterNone(form),
        };
    }
}
