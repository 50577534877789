import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-process',
  templateUrl: './card-process.component.html',
  styleUrls: ['./card-process.component.scss'],
})
export class CardProcessComponent {
  @Input() title: string;
  @Input() content: string;
}
