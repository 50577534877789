<div class="subform-array">
    <div *ngIf="hasCalendar" class="subform-array__calendar-container" [class]="showCalendar ? 'col-3' : ''">
        <div class="d-flex align-items-center ms-2">
            <mat-icon fontIcon="event" color="primary" class="me-3"></mat-icon>
            <mat-slide-toggle [(ngModel)]="showCalendar"></mat-slide-toggle>
        </div>
        <app-calendar-summary *ngIf="showCalendar" [formArray]="formArray" [config]="formConfig.calendarConfig"
            (dateSelected)="updateDate($event)" (viewChanges)="filterItems($event)"></app-calendar-summary>
    </div>
    <div *ngFor="let item of formArray.controls; let i = index" [style.order]="itemPositions[i]"
        [style.display]="itemVisibility[i] ? 'unset' : 'none'" class="subform-array__subform">
        <app-dynamic-form [schema]="forms[i].schema"></app-dynamic-form>
        <button *ngIf="canDelete" mat-mini-fab class="subform-array__subform__button-delete" color="primary" (click)="delete(i)">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </div>
    <button mat-mini-fab *ngIf="!hideAddButton" color="primary" class="subform-array__button-add"
        (click)="addOne(true)">
        <mat-icon>add</mat-icon>
    </button>
</div>
