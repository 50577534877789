import { Validators } from '@angular/forms';
import {
    FormBuilderInterface,
    FormConfigInterface,
    TabInterface,
} from '@interfaces/dynamic-form.interface';
import { inventary } from '../../inventary';
import { TYPE_UNITS_OPTIONS } from '@constants/options';

export const tabs: TabInterface[] = [
    { label: 'General', form: 'general' },
    { label: 'Notas', form: 'notes' },
    { label: 'Robot', form: 'robot' },
];

const general: FormBuilderInterface = {
    general: {
        label: 'Datos generales',
        type: 'group-label',
        size: 'col-12',
        childs: {
            masterCode: {
                label: 'Código Maestro',
                type: 'text',
                value: '',
                validators: [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
                size: 'col-3',
            },
            name: {
                label: 'Nombre',
                type: 'text',
                value: '',
                validators: [Validators.required],
                size: 'col-9',
            },
            CMS: {
                label: 'Código Maestro Sustitutorio',
                type: 'text',
                value: '',
                validators: [
                    Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
                size: 'col-3',
            },
            display: {
                label: 'Presentación',
                type: 'text',
                value: '',
                validators: [Validators.required],
                size: 'col-9',
            },
            supplier: {
                label: 'Suministrador',
                type: 'text',
                value: '',
                validators: [],
                size: 'col-6',
            },
            strength: {
                label: 'Fortaleza',
                type: 'text',
                value: '',
                validators: [],
                size: 'col-3',
            },
            admin: {
                label: 'Vía de administración',
                type: 'select',
                value: '',
                options: [],
                validators: [Validators.required],
                size: 'col-3',
            },
        }
    },
    codesFacturation: {
        type: 'group-label',
        label: 'Códigos preferentes',
        childs: {
            primary: {
                label: 'Primario',
                type: 'text',
                value: '',
                placeholder: 'Código de 6 dígitos',
                validators: [],
                size: 'col-6',
            },
            secondary: {
                label: 'Secundario',
                type: 'text',
                value: '',
                placeholder: 'Código de 6 dígitos',
                validators: [],
                size: 'col-6',
            },
        },
    },
    units: {
        type: 'group-label',
        label: 'Unidades',
        childs: {
            box: {
                label: 'Caja',
                type: 'text',
                value: null,
                validators: [],
                size: 'col-4',
            },
            dose: {
                label: 'Dosis',
                type: 'text',
                value: null,
                validators: [],
                size: 'col-4',
            },
            type: {
                label: 'Tipo',
                type: 'radio',
                value: true,
                validators: [],
                size: 'col-4',
                options: TYPE_UNITS_OPTIONS,
            },
        },
    },

    classification: {
        type: 'group-checkbox',
        label: 'Clasificación IEF',
        validators: [Validators.required],
        customStyle: {'padding-bottom': '10px'},
        childs: {
            none: {
                label: 'Ninguna',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            alzheimer: {
                label: 'Alzheimer',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            anxiolytics: {
                label: 'Ansiolíticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            antibiotics: {
                label: 'Antibióticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            anticoagulants: {
                label: 'Anticoagulantes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            antidepressants: {
                label: 'Antidepresivos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },

            antipsychotics: {
                label: 'Antipsicoticos',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            anticonvulsants: {
                label: 'Eutimizantes/Anticonvulsivantes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            diabetes: {
                label: 'Diabetes',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            opioids: {
                label: 'Opioides',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            aine: {
                label: 'AINE',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            ibp: {
                label: 'IBP',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
        },
    },

    inventary: {
        type: 'group-label',
        label: 'Inventario',
        childs: {
            inventary: {
                label: 'Inventario',
                type: 'select',
                value: null,
                validators: [],
                options: inventary,
                size: 'col-4',
            },
        },
    },

    otherData: {
        type: 'group-checkbox',
        label: 'Otros datos',
        childs: {
            obsolete: {
                label: 'Obsoleto',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            hospitalUse: {
                label: 'Hospitalario',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            cobert: {
                label: 'Cobertura sanitaria',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            diaper: {
                label: 'Pañal',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            parapharmacy: {
                label: 'Parafarmacia',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            cureMaterial: {
                label: 'Material de cura',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            RA: {
                label: 'R.A',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            MEC: {
                label: 'M.E.C',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            RO: {
                label: 'R.O',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            notPrescribe: {
                label: 'No prescribir',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            FGP: {
                label: 'FGP',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
        },
    },
};

const notes: FormBuilderInterface = {
    observations1: {
        label: 'Comentario 1',
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
    },
    observations2: {
        label: 'Comentario 2',
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
    },
};

const epigraphInfo: FormBuilderInterface = {
    title: {
        label: 'Epígrafes',
        hideLabel: true,
        type: 'info',
    },
    info: {
        label: 'Información del epigrafe',
        hideLabel: true,
        type: 'textarea',
        value: '',
        validators: [],
        size: 'col-12',
        readOnly: () => true,
    },
};

const epigraph: FormBuilderInterface = {
    epigraph: {
        type: 'group-expansion',
        customFieldData: { title: '' },
        label: '',
        childs: epigraphInfo,
    },
};

const epigraphs: FormBuilderInterface = {
    status: {
        type: 'info',
        label: 'Epigrafes',
    },

    epigraphs: {
        type: 'custom',
        customType: 'list-form',
        label: 'array',
        customFieldData: { schema: epigraph },
    },
};

const interactions: FormBuilderInterface = {
    info1: {
        type: 'info',
        label: 'Interacciones',
    },

    interactions: {
        label: 'Interacciones',
        type: 'select',
        value: null,
        validators: [],
        size: 'col-4',
    },

    infoSelectedInteraction: {
        type: 'textarea',
        label: 'Información de la interacción',
        readOnly: () => true,
    },
};

const robot: FormBuilderInterface = {
    robotOptions: {
        type: 'group-checkbox',
        label: '',
        hideLabel: true,
        customStyle: {
            'flex-direction': "column"
        },
        childs: {
            noEmbistable: {
                label: 'No emblistable',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            uniDose: {
                label: 'Unidosis',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            indivisible: {
                label: 'Indivisible',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            alwaysPlate: {
                label: 'Siempre en bandeja',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
            noHalves: {
                label: 'No mitades',
                type: 'checkbox',
                value: false,
                validators: [],
                size: 'col-3',
            },
        },
    },
    tolva: {
        label: 'Tolva',
        type: 'text',
        value: null,
        validators: [Validators.minLength(3), Validators.maxLength(3)],
        size: 'col-4',
        inputPattern: '^[0-9]{0,3}$'
    },
};

export const formsConfig: FormConfigInterface = {
    general,
    notes,
    robot,
};
