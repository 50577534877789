<section>
  <div class="documents-container">
    <div *ngFor="let document of documents">
      <app-document-card
        [title]="document.name"
        [subtitle]="document.date"
        [data]="document"
        [canDelete]="!fieldControl.disabled && (readOnly ? !readOnly() : true)"
        (handleDeleteEvent)="handleDeleteEvent($event)"
        (handleDownloadEvent)="handleDownloadEvent($event)"
      >
      </app-document-card>
    </div>
  
  </div>

  <input
    #fileInput
    hidden
    type="file"
    id="file"
    accept="image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/pdf"
    (change)="addNewDocument($event)"
  />

  <div class="add" *ngIf="!fieldControl.disabled && (readOnly ? !readOnly() : true)" (click)="fileInput.click()">
    <button 
      mat-icon-button
      title="Añadir documento"
      class="add-button"
    >
      <mat-icon>add</mat-icon>
    </button>
    <span>Añadir documento</span>
  </div>
</section>


