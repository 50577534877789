import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
    EDUCATION_OPTIONS,
    GENDER_OPTIONS,
    OCUPATION_OPTIONS,
} from '@constants/options';
import {
    FormBuildeItemInterface,
    FormBuilderInterface,
    FormItemSchemaInterface,
    FormSchemaObjectInterface,
} from '@interfaces/dynamic-form.interface';
import { TableFormComponent } from '@shared/table-form/table-form.component';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    public educationOptions = EDUCATION_OPTIONS;
    public ocupationOptions = OCUPATION_OPTIONS;
    public genderOptions = GENDER_OPTIONS;

    constructor() { }

    setSchemaSingleField(
        field: FormBuildeItemInterface,
        control: FormControl | FormGroup | FormArray
    ): FormItemSchemaInterface {
        return {
            label: field.label,
            hideLabel: field.hideLabel,
            type: field.type,
            customType: field.customType,
            size: field.size ?? 'col-12',
            hintMsg: field.hintMsg,
            fieldControl: control,
            options: field.options,
            customFieldData: field.customFieldData,
            conditionalConfig: field.conditionalConfig,
            visible: field.visible,
            dynamicError: field.dynamicError,
            readOnly: field.readOnly,
            withTitle: field.withTitle,
            min: field.min,
            max: field.max,
            minDate: field.minDate,
            maxDate: field.maxDate,
            inputPattern: field.inputPattern,
            exactPattern: field.exactPattern,
            numberFieldFastButtons: field.numberFieldFastButtons,
            capitalize: field.capitalize,
            uppercase: field.uppercase,
            errorMsg: field.errorMsg ?? '',
            group: field.group,
            placeholder: field.placeholder,
            customStyle: field.customStyle,
            customClass: field.customClass,
            addable: field.addable,
            info: field.info,
            multipleSelect: field.multipleSelect
        };
    }

    setSchemaChildsField(
        field: FormBuildeItemInterface,
        childSchema: FormItemSchemaInterface[],
        control: FormControl | FormGroup
    ): FormItemSchemaInterface {
        return {
            type: field.type,
            customType: field.customType,
            size: field.size ?? 'col-12',
            label: field.label,
            hideLabel: field.hideLabel,
            childs: childSchema,
            fieldControl: control,
            customFieldData: field.customFieldData,
            conditionalConfig: field.conditionalConfig,
            visible: field.visible,
            readOnly: field.readOnly,
            withTitle: field.withTitle,
            min: field.min,
            max: field.max,
            minDate: field.minDate,
            maxDate: field.maxDate,
            customStyle: field.customStyle,
            customClass: field.customClass,
            addable: field.addable,
            info: field.info,
            multipleSelect: field.multipleSelect
        };
    }

    buildForm(
        data: FormBuilderInterface,
        debug: boolean = false
    ): [FormGroup, FormItemSchemaInterface[], FormSchemaObjectInterface] {

        const fields = Object.keys(data);
        let form = new FormGroup({});
        let formSchema: FormItemSchemaInterface[] = [];
        let formSchemaObj: FormSchemaObjectInterface = {};

        fields.forEach((fieldName) => {
            const field = data[fieldName];
            let schema: FormItemSchemaInterface;
            let control: FormGroup | FormControl | FormArray;

            if (field.childs) {
                let childSchema: FormItemSchemaInterface[];
                [control, childSchema] = this.buildForm(field.childs);
                schema = this.setSchemaChildsField(field, childSchema, control);
            } else {
                switch (field.customType) {
                    case 'table-form':
                        control = new FormGroup({});
                        break;

                    default:
                        control = new FormControl(field.value, {
                            validators: field.validators ?? [],
                            nonNullable: true,
                        });
                        break;
                }
                schema = this.setSchemaSingleField(field, control);
            }
            form.addControl(fieldName, control);
            formSchema.push(schema);
            formSchemaObj[fieldName] = schema;
        });

        return [form, formSchema, formSchemaObj];
    }
}
