export const inventary = [
  { value: 1, label: 'Sin inventario' }, //he visto que puede verse como 1 o como null
  { value: 0, label: 'Con inventario' },
  { value: 2, label: 'Suministro limitado' },
];

export function getInventaryOption(id: number | null): {
  value: number | null;
  label: string;
} {
  if (id == null) return inventary[1];
  return inventary[id];
}
