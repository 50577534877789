import { Component, EventEmitter, Output } from '@angular/core';

import { ListComponent } from '../../../base/list/list.component';

import { RoleManagerService } from '@services/role-manager.service';

import { User } from '@interfaces/user/user.interface';
import { CardAction } from '@interfaces/card-actions';

import { UserRole } from '@json/src/app/enums/user-role';

@Component({
  template: '',
})
export class BaseComponent extends ListComponent {
  
  UserRole = UserRole;

  currentData: User[];

  // Card actions list
  actions: CardAction[] = [
    {
      id: 'edit',
      name: 'Editar',
      iconName: 'edit',
      routeLink: '/edit'
    },
    {
      id: 'delete',
      name: 'Borrar',
      iconName: 'delete',
      routeLink: '',
    }
  ];

  pharmaRoles = [
    { label: 'Titular', value: [UserRole.Titular]},
    { label: 'Farmaceutico', value: [UserRole.Farmaceutico]},
    { label: 'Tecnico', value: [UserRole.Tecnico]},
    { label: 'Admin', value: [UserRole.Admin] }
  ];

  onDelete: (id: number) => void;

  constructor(
    protected roleManager: RoleManagerService,
  ) {
    super();
  }

  isPharma() {
    return this.roleManager.isPharma()
  }

  isValidPharmaRole(role: any) {
    return this.getUserRole(role) != undefined;
  }

  getUserRole(role: any) {
    return this.pharmaRoles.find(r => JSON.stringify(role) == JSON.stringify(r.value))?.label;
  }

  handleDelete(id: number): void {
    this.onDelete(id);
  }
}
