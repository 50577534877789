<ng-container class="report-custom-container"><app-report-select [title]="'Prescripciones pendientes de evaluación'"
        (previewClicked)="handlePreviewClick()" (printClicked)="handlePrintClick()" (pdfClicked)="handlePdfClick()"
        (excelClicked)="handleExcelClick()">
        <div slot="content" class="container-options">
            <form [formGroup]="form">

                <section class="filter-container" *ngIf="typeGlobal" >
                    <div class="subtitle">Centro</div>
                    <app-form-field
                      class="field"
                      [type]="'select'"
                      label="Selecciona un centro"
                      [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
                      [options]="selectOptionsInsitutions">
                    </app-form-field>
            
                  </section>

                <section class="filter-container" >
                    <p class="subtitle">Día de generación del plan</p>

                    <mat-form-field>
                        <div class="label">Día de corte</div>
                        <input matInput [matDatepicker]="cutOffDay" formControlName="cutOffDay" />
                        <mat-datepicker-toggle matSuffix [for]="cutOffDay">
                            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #cutOffDay></mat-datepicker>
                    </mat-form-field>
                </section>

                <section class="filter-container">
                    <p  class="subtitle">Filtrar por</p>
                    <app-form-field 
                        class="field col-12" 
                        [type]="'checkbox'"
                        label="Paciente" 
                        [fieldControl]="form.controls['checkboxControlPatient'] | asFormControl"
                        (change)="toggleSelectPatient()">
                    </app-form-field>
                    <app-form-field 
                        *ngIf="showPatientSelect"
                        class="select marginBottom"
                        label="" 
                        type="autocomplete" 
                        [options]="selectOptionsPatientsWithoutFilter" 
                        [fieldControl]="form.controls['selectedPatient'] | asFormControl">
                    </app-form-field>

                    <app-form-field 
                        class="field col-12" 
                        [type]="'checkbox'"
                        label="Mostrar solo PE del día" 
                        [fieldControl]="form.controls['checkboxPE'] | asFormControl"
                        (change)="toggleSelecteFilterPE()">
                    </app-form-field>

                    <app-form-field 
                        class="field col-12" 
                        [type]="'checkbox'"
                        label="Mostrar pautas en STOP que no sean PE" 
                        [fieldControl]="form.controls['checkboxPrescrSTOPS'] | asFormControl">
                    </app-form-field>
                </section>
            </form>
        </div>
    </app-report-select>
</ng-container>
