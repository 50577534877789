import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createBinaryString } from '@constants/funtions-utils';
import { BLOCK_REASON_OPTIONS } from '@constants/options';
import {
    APIResponseParsed,
    CountryOptionAPI,
} from '@interfaces/api/api.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    IncompatibilityDataAPI,
    InvalidHalfDataAPI,
    MedicineOption,
    OverdoseDataAPI,
} from '@interfaces/medicine/medicine.interface';
import {
    BlockResult,
    Blocks,
    ClinicAnalisysResult,
    clinicCourseResult,
    DependencyFragilityTestsResult,
    Diagnogstic,
    DiagnogsticsData,
    DiagnogsticsPayloadAPI,
    DiagnogsticsQuery,
    HandlingHabilityTestsResult,
    HaynesTestResult,
    InsertPrescriptionAPI,
    IntakeTable,
    Patient,
    PatientAPI,
    PatientDataAPIInterface,
    PatientFormData,
    PatientPrescriptionFilter,
    PatientREChangesAPI,
    PatientReviewPrescriptionsREQuantityFilter,
    PatientUpdateAPI,
    Patterns,
    PrescriptionEditAPI,
    PrescriptionEditApp,
    PrescriptionResponseAPI,
    Result2,
    ReviewWeekAPI,
    ReviewWeetQuantitiesAPI,
    StopPrescriptionResponseAPI,
    Vaccine,
    VitalSignsResult,
} from '@interfaces/patient/patient.interface';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { InstitutionService } from '@services/institution.service';
import { ModalService } from '@services/modal.service';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { Utils } from '../Utils';
import { environment } from '@json/src/environments/environment';
import { ImageService } from './image/image.service';

@Injectable({
    providedIn: 'root',
})
export class PatientsService {
    private _patientList: BehaviorSubject<PatientAPI[] | undefined> = new BehaviorSubject<PatientAPI[] | undefined>(undefined);
    patientList = this._patientList.asObservable();

    private blockReasonOptions = BLOCK_REASON_OPTIONS;

    constructor(
        private calls: CallsService,
        private institutionService: InstitutionService,
        private router: Router,
        private dateService: DateService,
        private modalService: ModalService,
        private imageService: ImageService
    ) {
        (window as any).Utils = Utils;
    }

    clearStoredData(): void {
        this._patientList.next([]);
    }

    transformPatientAPItoApp(p: PatientAPI): Patient {
        const type = this.imageService.getEntityTarget(this.router.url);
        const formatedID = this.imageService.formatNumberImage(Number(p.id));
        const imageUrl = `${environment.urlBack}resources/assets/pics/${type}/e00/${formatedID}.jpg`;

        const patient = {
            number: p.num,
            name: p.nam,
            department: p.dep,
            birthday: new Date(p.bth),
            id: p.id,
            gender: p.gnd,
            siteID: '0',
            inst: p.ins,
            loginDate: p.bth,
            alert: Boolean(p.alrt),
            imagePath: imageUrl,
            expedition: p.ex,
            blq: p.blq,
            sm: p.sm,
            mm: p.mm,
            cip: p.cip,
            cipaPattern: p.CipaPattern,
            privateInsurance: p.SeguroPrivado,
        } as Patient;


        return patient;
    }

    transformPatientFormToPatientUpdate(
        patient: PatientFormData,
        id: number | null
    ): PatientUpdateAPI {
        return new PatientUpdateAPI(patient, id);
    }

    getCountries(): Observable<SelectOption[]> {
        return this.calls.getFile('world.json').pipe(
            map((res) => {
                return res.payload.map((country: CountryOptionAPI) => {
                    return {
                        label: country.name,
                        value: country.id,
                    };
                });
            })
        );
    }

    getPatients(): void {
        this.calls
            .getPatientsListAPI(this.institutionService.getInstitutionName())
            .subscribe({
                next: (res) => {
                    this._patientList.next(res.payload);
                },
                error: () => {
                    this.calls.openSnack('Error al obtener los Pacientes');
                },
            });
    }

    deletePatient(
        idPatient: number,
        force?: number
    ): Observable<APIResponseParsed> {
        return this.calls.deletePatient(idPatient, force);
    }

    getNewPatientEditData(institutionId: number): Observable<PatientFormData> {
        return this.calls.getNewPatientEditData(institutionId).pipe(
            map((res) => {
                return this.convertPatient_APItoAPP(res);
            })
        );
    }

    getPatientData(id: string): Observable<PatientFormData> {
        return this.calls.getPatientAPI(id).pipe(
            map((res) => {
                return this.convertPatient_APItoAPP(res);
            })
        );
    }

    async updatePatientAPI(patient: PatientFormData, patientId: number | null, tabIndex: number = 0, refresh: boolean = true): Promise<void> {
        const data = this.transformPatientFormToPatientUpdate(patient, patientId);
        
        data.InstitutionID = patient.rawData?.pat.insid || this.institutionService.getCurrentInstitution()

        const newPatNumber = await new Promise<boolean>((resolve) => {
            if(patientId == null) {
                this.getNewPatientEditData(data.InstitutionID as number).subscribe({
                    next: (res) => {
                        data.PatientNumber = res.rawData!?.pat.num;
                        resolve(true);
                    },
                    error: (err) => {
                        resolve(false);
                    }
                });
            }
            else {
                data.PatientNumber = patient.rawData!?.pat.num; //(Math.floor(Math.random() * 999) + 1).toString();????
                resolve(true);
            }
        });

        if (!newPatNumber) {
            this.calls.openSnack("Ha ocurrido un error al intentar guardar los datos");
            return;
        }

        const dataString = JSON.stringify(data);
        this.calls.updatePatientAPI(dataString).subscribe({
            next: async (res) => {
                const patiendId = res[0].Column1;
                if (patiendId < 0) {
                    this.calls.openStaticSnack(
                        'Ya existe un paciente con ese CIP/A. Porfavor, proporciona otro "CIP/A"',
                        'OK'
                    );
                } else {
                    this.calls.openSnack(
                        'Se han guardado los cambios correctamente',
                        'Ok'
                    );
                    if (patiendId !== null) {
                        const currentUrl = this.router.url;
                        const editPatientURL = currentUrl + `/${patiendId}`;
                        this.router.navigateByUrl(editPatientURL);
                    } else {
                        await this.getPatientData(patiendId);
                        if (refresh)
                            this.modalService.reloadComponent(this.router, tabIndex);
                    }
                    this.getPatients();
                }
            },
            error: (error) => {
                this.calls.openSnack(
                    'Error al obtener los pacientes(datos de edición)'
                );
            },
        });
    }

    getPatientsByFilter(filter: string): void {
        this.calls.getPatientsListAPIByFilter(filter, this.institutionService.getInstitutionName()).subscribe({
            next: (res) => {
                this._patientList.next(res.payload);
            },
            error: () => {
                this.calls.openSnack('Error al obtener los Pacientes');
            },
        });
    }

    getPatientPrescriptionWeek(
        filter: PatientPrescriptionFilter
    ): Observable<PrescriptionResponseAPI[]> {
        return this.calls.getPatientPrescriptionWeek(filter).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al obtener la prescripción semanal');
                return [];
            })
        );
    }

    getPatientReviewPrescriptionsRE(
        filter: PatientPrescriptionFilter
    ): Observable<ReviewWeekAPI> {
        return this.calls.getPatientReviewPrescriptionsRE(filter).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al obtener las prescripciones');
                return [];
            })
        );
    }

    updatePatientReviewRE(
        filter: PatientREChangesAPI
    ): Observable<APIResponseParsed> {
        return this.calls.updatePatientReviewRE(filter).pipe(
            map((res) => res),
            catchError(() => {
                this.calls.openSnack('Error al guardar los cambios');
                return [];
            })
        );
    }

    getPatientReviewPrescriptionsREQuantity(
        filter: PatientReviewPrescriptionsREQuantityFilter
    ): Observable<ReviewWeetQuantitiesAPI> {
        return this.calls.getPatientReviewPrescriptionsREQuantity(filter).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al obtener las cantidades');
                return [];
            })
        );
    }

    stopPrescription(
        id: number,
        date: Date,
        createNew = false
    ): Observable<StopPrescriptionResponseAPI> {
        return this.calls.stopPatientPrescription(id, date, createNew).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al detener la prescripción');
                return [];
            })
        );
    }

    deletePrescription(id: number): Observable<string> {
        // 0
        return this.calls.deletePatientPrescription(id).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al borrar la prescripción');
                return [];
            })
        );
    }

    getPrescription(id: number) {
        return this.calls.getPrescriptionById(id).pipe(
            map((res) => res.payload),
            catchError(() => {
                this.calls.openSnack('Error al obtener la prescripción');
                return [];
            })
        );
    }

    logMedicineOverdose(data: OverdoseDataAPI): Observable<APIResponseParsed> {
        return this.calls.logOverdose(data).pipe(
            catchError(() => {
                this.calls.openSnack('Error al loguear sobredosis');
                return [];
            })
        );
    }

    logMedicineIncompatibility(
        data: IncompatibilityDataAPI
    ): Observable<APIResponseParsed> {
        return this.calls.logMedicineIncompatibility(data).pipe(
            catchError(() => {
                this.calls.openSnack('Error al loguear sobredosis');
                return [];
            })
        );
    }
    logMedicineInvalidHalf(data: InvalidHalfDataAPI): Observable<APIResponseParsed> {
        return this.calls.logInvalidHalf(data).pipe(
            catchError(() => {
                this.calls.openSnack('Error al loguear mitades no válidas');
                return [];
            })
        );
    }

    insertMedicinePrescription(
        data: PrescriptionEditApp,
        patientId: number,
        prescriptionId?: number
    ): Observable<APIResponseParsed> {
        const parsedData = new InsertPrescriptionAPI(
            data,
            patientId,
            prescriptionId
        );

        return this.calls.insertMedicinePrescription(parsedData).pipe(
            catchError(() => {
                this.calls.openSnack('Error al insertar la prescripción');
                return [];
            })
        );
    }

    parsePrescritionToApp(
        prescription: PrescriptionEditAPI
    ): PrescriptionEditApp | any {
        const data = prescription.result1[0];

        const months = createBinaryString(data.ActivePeriods ?? 0);
        const last = months.length - 1;

        return {
            medicine: {
                label: `${data.Name} ${data.Presentacion ? `- ${data.Presentacion}` : ''}`,
                value: {
                    CF1: '',
                    CF2: '',
                    CMS: data.CMS,
                    FGP: data.FGP,
                    Obsolete: data.Obsolete,
                    Presentacion: data.Presentacion,
                    did: data.DrugId,
                    id: Number(data.MedicineId),
                    flags: {
                        // diaper: data.Diaper,
                        mec: data.mecData,
                        noBlister: data.NoBlister,
                        noHalf: data.NoHalf,
                        noPartial: data.NoPartial,
                        // noStock: data.NoStock,
                        // noTray: data.NoTray,
                        payMed: data.PayMed,
                    },
                    nam: data.Name,
                    PA: data.PA,
                    GrupoPeligro: data.GrupoPeligro
                }
            },
            noBlister: !!data.NoBlister,
            forcePayment: data.ForcedPayment,
            payMed: data.PayMed,
            ifNeeded: data.RecurrencePattern === 6,
            severe: data.Agudo,
            adminRoute: data.AdminRoute,
            ra: !!data.raData,
            raNumber: data.raData ? data.raData.Qty + '' : '0',

            since: new Date(data.StartDate),
            forever: !data.EndDate,
            until: data.EndDate ? new Date(data.EndDate) : null,
            pe: data.PE !== null,
            peDate: data.PE !== null ? new Date(data.PE) : null,

            gen: !!Number(months[last - 0]),
            feb: !!Number(months[last - 1]),
            mar: !!Number(months[last - 2]),
            apr: !!Number(months[last - 3]),
            may: !!Number(months[last - 4]),
            jun: !!Number(months[last - 5]),
            jul: !!Number(months[last - 6]),
            aug: !!Number(months[last - 7]),
            sep: !!Number(months[last - 8]),
            oct: !!Number(months[last - 9]),
            nov: !!Number(months[last - 10]),
            dec: !!Number(months[last - 11]),

            ...this.getPattern(prescription),

            advancedSchedule: !!data.IsAdvanced,
            table: {},

            observations: data.Observaciones ?? '',
        };
    }

    fillTable(
        intakes: Result2[],
        table: IntakeTable,
        cols: SelectOption[],
        rows: SelectOption[]
    ): IntakeTable {
        const colNames: string[] = cols.map((c) => c.value);
        const rowDictionary: any = {};
        rows.forEach((r) => {
            rowDictionary[r.label] = r.value;
        });
        intakes.forEach((intake) => {
            const day = intake.DayCode > 0 ? intake.DayCode - 1 : 0;
            const colName = colNames[day];
            table[colName][rowDictionary[intake.TimeName]] = {
                quantity: intake.Qty + '',
                department: intake.DepId,
            };
        });
        return table;
    }

    getPattern(prescription: PrescriptionEditAPI): Patterns {
        const data = prescription.result1[0];
        const pattern = prescription.result1[0].RecurrencePattern;
        const result: Patterns = {
            pattern: 'daily',
            dailyPattern: 'repeat each',
            dailyRepeat: '1',
            weeklyRepeat: '1',
            monthlyRepeat: '1',
            cyclicActive: '1',
            cyclicRest: '1',
        };

        switch (
        pattern // 'daily' 'monthly' 'weekly' 'cyclic'
        ) {
            case 1:
                result.pattern = 'daily';
                result.dailyPattern = 'repeat each';
                result.dailyRepeat = data.RecurrenceInterval + '';
                break;
            case 2:
                result.pattern = 'weekly';
                result.weeklyRepeat = data.RecurrenceInterval + '';
                break;
            case 3:
                result.pattern = 'daily';
                result.dailyPattern = 'even days';
                break;
            case 4:
                result.pattern = 'daily';
                result.dailyPattern = 'odd days';
                break;
            case 5:
                result.pattern = 'monthly';
                result.monthlyRepeat = data.RecurrenceInterval + '';
                break;
            case 6: // If Needed
                break;
            case 7:
                result.pattern = 'cyclic';
                break;
        }
        return result;
    }

    getDiagnogsticsList(query: DiagnogsticsQuery): Observable<SelectOption[]> {
        return this.calls.getDiagnogsticsList(query).pipe(
            map((response) => {
                return response.payload.map((item: DiagnogsticsPayloadAPI) => {
                    return {
                        label: item.Name,
                        value: {
                            description: item.Name,
                            code: item.Id,
                        },
                    };
                });
            })
        );
    }

    uploadDiagnogsticsFile(
        file: File,
        formData: DiagnogsticsData
    ): Observable<any> {
        const data = new FormData();

        data.append('file', file);
        data.append('data[Token]', localStorage.getItem('access_token') ?? '');
        data.append('data[Id]', formData.patientId);
        data.append('data[FileName]', file.name);
        data.append('data[DiagDate]', formData.date.toISOString());
        data.append('data[OvrwCies]', formData.removeCurrent ? 'true' : 'false');
        data.append('data[OvrwDates]', formData.useDate ? 'true' : 'false');

        return this.calls.uploadDiagnogsticsFile(data).pipe(
            map((res) => {
                // TODO: hacer cosas si es necesario
                return res;
            })
        );
    }

    convertPatient_APItoAPP(data: PatientDataAPIInterface): PatientFormData {
        const patient = data.pat;
        return {
            rawData: data,
            general: {
                id: data.pat.id,
                institutionId: data.pat.insid,
                general: {
                    name: patient.fnam,
                    surname1: patient.mnam,
                    surname2: patient.lnam,
                    personalId: patient.LinqData?.patientReData?.DNI ?? '',
                    educationalLevel: patient.LinqData?.NivelEduc,
                    ocupationalLevel: patient.LinqData?.NivelOcup,
                },
                birthData: {
                    place: patient.LinqData?.BirthPlace,
                    city: patient.LinqData?.BirthMunic,
                    province: patient.LinqData?.BirthProv,
                    country: patient.LinqData?.BirthCtry,
                    birthday: patient.dobr,
                    gender: patient.gnd,
                },
                dates: {
                    registrationDate: patient.dob,
                    deregistrationDate: patient.doe,
                    deregistrationReason: patient.exr,
                },
                contacts: {
                    name1: patient.ctnam,
                    phone1: patient.ctphn,
                    name2: patient.ctnam2,
                    phone2: patient.ctphn2,
                },
                position: {
                    department: patient.dep,
                    room: patient.room,
                },
                medicalCard: {
                    cip: patient.cip,
                    tsi: patient.tsi,
                    lastChronicRE: patient.rec,
                    lastSevereRE: patient.rea,
                    privateInsurance: patient.SeguroPrivado,
                },
                medicalData: {
                    doctorSS: patient.LinqData?.MedicoSS,
                    checkboxGroup: {
                        mutuas_muface: patient.mumu,
                        at_home: patient.ath,
                        private: patient.IsPrivate,
                        autogetsion: patient.SelfManaged,
                    },
                }
            },
            clinicNotes: {
                observations: {
                    main: patient.obs,
                },
                allergies: { 
                    main: patient.Alergias.map(item => item.Id),
                },
                diet: {
                    liquid: patient.LinqData?.patientDiet?.DietaLiquida,
                    bland: patient.LinqData?.patientDiet?.DietaBlanda,
                    vasal: patient.LinqData?.patientDiet?.DietaBasal,
                    hipoprotic: patient.LinqData?.patientDiet?.DietaHipoproteica,
                    hiperproteic: patient.LinqData?.patientDiet?.DietaHiperproteica,
                    hipocaloric: patient.LinqData?.patientDiet?.DietaHipocalorica,
                    hoiposodic: patient.LinqData?.patientDiet?.DietaHiposodica,
                    hipopemiant: patient.LinqData?.patientDiet?.DietaHipolipemiante,
                    diabetic: patient.LinqData?.patientDiet?.DietaDiabetica,
                    noGluten: patient.LinqData?.patientDiet?.DietaSinGluten,
                    otra: patient.LinqData?.patientDiet?.DietaOtra,
                    notes: patient.LinqData?.patientDiet?.Dieta,
                },
                vitalSigns: { results: this.getVitalSigns(data) },
                clinicAnalisys: { results: this.getClinincAnalisys(data) },
                dependencyFragilityTests: { results: this.getDependencyTests(data) },
                haynesTest: { results: this.getHaynesTests(data) },
                handlingHabilityTests: { results: this.getHandlingTest(data) },
                antropometricMeasures: {
                    height: patient.LinqData?.Altura + '',
                    weight: patient.LinqData?.Peso + '',
                    absCircunference: patient.LinqData?.CircunfAbdom + '',
                    kneesHeigth: patient.LinqData?.AlturaRodilla + '',
                    imc: null,
                },
                vaccines: {
                    gripe: this.getVaccineData(data, 'Gripe'),
                    neumococo: this.getVaccineData(data, 'Neumococo'),
                    disteriaTetanosTosfeina: this.getVaccineData(
                        data,
                        'Difteria-Tetanos-Tos ferina'
                    ),
                    herpesZoster: this.getVaccineData(data, 'Herpes zóster'),
                    sarsCov2: this.getVaccineData(data, 'SARS-CoV-2'),
                },
            },
            diagnostic: {
                diagnogstics: { diagnogstics: this.getDiagnogstics(data) },
                clinicCourse: { results: this.getClinincCourse(data) },
            },
            dailyPattern: {
                pattern: this.getDailyPattern(data),
            },
            blocks: {
                info1: '',
                blocks: this.getBlocks(data),
            },
            prescriptions: {
                link: patient.enlaceAviso,
                exportCf: patient.ExportarCF,
                allergies: patient.alr
            },
            documents: {
                documents: patient.docs?.map((doc) => doc),
            },
        };
    }

    getVitalSigns(patient: PatientDataAPIInterface): VitalSignsResult[] {
        const vitalS = patient.pat.LinqData?.patientCv;
        let result: VitalSignsResult[] = [];

        if (vitalS && vitalS.length) {
            result = vitalS.map((item) => {
                return {
                    date: new Date(item.FechaAnalisis),
                    bloodPresure: {
                        max: item.TensionArterialMax + '',
                        min: item.TensionArterialMin + '',
                    },
                    heartRate: item.FrecCardiaca + '',
                    bloodO2: item.OxigenoSangre + '',
                    temperature: item.Temperatura + '',
                };
            });
        }

        return result;
    }
    getClinincAnalisys(patient: PatientDataAPIInterface): ClinicAnalisysResult[] {
        const analisys = patient.pat.LinqData?.patientAnlCli;
        let result: ClinicAnalisysResult[] = [];

        if (analisys && analisys.length) {
            result = analisys.map((item) => {
                return {
                    date: new Date(item.FechaAnalisis),
                    tabs: {
                        methabolics: {
                            glucose: { value: item.Glucosa + '' },
                            sodium: { value: item.Sodio + '' },
                            potassium: { value: item.Potasio + '' },
                            chloride: { value: item.Cloruro + '' },
                            carbonDioxide: { value: item.DioxidoCarbono + '' },
                            bun: { value: item.NitrogenoUreico + '' },
                            creatinine: { value: item.Creatinina + '' },
                            calcium: { value: item.Calcio + '' },
                            proteins: { value: item.Proteinas + '' },
                            albumin: { value: item.Albumina + '' },
                            bilirubin: { value: item.Bilirrubina + '' },
                            alp: { value: item.FosfatasaAlcalina + '' },
                        },
                        hemogram: {
                            wbc: { value: item.ConteoGlobBlancos + '' },
                            rbc: { value: item.ConteoGlobRojos + '' },
                            hemoglobin: { value: item.Hemoglobina + '' },
                            hematocrit: { value: item.Hematocrito + '' },
                            mcv: { value: item.VolGlobularMed + '' },
                            mch: { value: item.HemoglobCospusc + '' },
                            plateletCount: { value: item.Plaquetas + '' },
                        },
                        lipidgram: {
                            totalCholesterol: { value: item.ColestTotal + '' },
                            hdl: { value: item.HDL + '' },
                            ldl: { value: item.LDL + '' },
                            triglycerides: { value: item.Triglicer + '' },
                        },
                    },
                };
            });
        }
        return result;
    }
    getDependencyTests(
        patient: PatientDataAPIInterface
    ): DependencyFragilityTestsResult[] {
        const test = patient.pat.LinqData?.patientTestFD;
        let result: DependencyFragilityTestsResult[] = [];

        if (test && test.length) {
            result = test.map((item) => {
                return {
                    date: new Date(item.FechaAnalisis),
                    tee: item.TEE + '',
                    tgug: item.TGUG + '',
                };
            });
        }
        return result;
    }
    getHaynesTests(patient: PatientDataAPIInterface): HaynesTestResult[] {
        const test = patient.pat.LinqData?.patientMultiTest;
        let result: HaynesTestResult[] = [];

        if (test && test.length) {
            result = test
                .filter((test) => test.TipoTest === 51)
                .map((item) => {
                    return {
                        date: new Date(item.FechaTest),
                        comply: item.MultiResult === 1 ? true : false,
                    };
                });
        }
        return result;
    }
    getHandlingTest(
        patient: PatientDataAPIInterface
    ): HandlingHabilityTestsResult[] {
        const test = patient.pat.LinqData?.patientMultiTest;
        let result: HandlingHabilityTestsResult[] = [];
        if (test && test.length) {
            result = test
                .filter((test) => test.TipoTest === 52)
                .map((item) => {
                    return {
                        date: new Date(item.FechaTest),
                        usage: item.MultiResult === 1 ? true : false,
                        person: item.MultiResultAux1 === 1 ? true : false,
                    };
                });
        }
        return result;
    }
    getVaccineData(
        patient: PatientDataAPIInterface,
        vaccineName: string
    ): Vaccine {
        const vaccine = patient.pat.LinqData?.patientClinical.find(
            (v) => v.Nombre === vaccineName
        );
        let result: Vaccine = {
            dosis1: null,
            dosis2: null,
            dosis3: null,
            dosis4: null,
            dosis5: null,
            dosis6: null,
            dosis7: null,
            dosis8: null,
        };

        if (vaccine) {
            result.dosis1 = vaccine.FechaDosis[0];
            result.dosis2 = vaccine.FechaDosis[1];
            result.dosis3 = vaccine.FechaDosis[2];
            result.dosis4 = vaccine.FechaDosis[3];
            result.dosis5 = vaccine.FechaDosis[4];
            result.dosis6 = vaccine.FechaDosis[5];
            result.dosis7 = vaccine.FechaDosis[6];
            result.dosis8 = vaccine.FechaDosis[7];
        }
        return result;
    }
    getDiagnogstics(patient: PatientDataAPIInterface): Diagnogstic[] {
        const diagnogstics = patient.pat.cies;
        let result: Diagnogstic[] = [];
        if (diagnogstics && diagnogstics.length) {
            result = diagnogstics.map((diag) => {
                return {
                    date: new Date(diag.DiagDate),
                    code: diag.CieId,
                    description: diag.Name,
                };
            });
        }
        return result;
    }
    getClinincCourse(patient: PatientDataAPIInterface): clinicCourseResult[] {
        const clinicC = patient.pat.LinqData?.patientCursoClin;
        let result: clinicCourseResult[] = [];
        if (clinicC && clinicC.length) {
            result = clinicC.map((course) => {
                return {
                    date: new Date(course.Fecha),
                    notes: course.Nota,
                };
            });
        }
        return result;
    }
    getDailyPattern(patient: PatientDataAPIInterface): { date: Date }[] {
        if (patient?.pat?.dayp == undefined) return [];
        return patient.pat.dayp.map((d) => {
            return { date: new Date(d.day) };
        });
    }

    getBlocks(patient: PatientDataAPIInterface): { block: BlockResult }[] {
        if (patient?.pat?.blocks == undefined) return [];
        return patient.pat.blocks.map((block) => {
            const startDateBlock = new Date(block.blkf);
            const endDateBlock = new Date(block.blku);

            const reasonBlk = block.blkr ?? 3;

            const diffDays =
                this.dateService.diffDates(startDateBlock, endDateBlock) + 1;
            const reasonBlock = BLOCK_REASON_OPTIONS.filter(
                (reason) => reason.value == reasonBlk
            )[0];

            const derivatedTo = reasonBlock.value == 3 ? block.blkdvt : '';

            const from = block.blku != null ? `Desde el ${startDateBlock.toLocaleDateString()} ` : `Indefinidamente desde el ${startDateBlock.toLocaleDateString()} `;
            const until = block.blku != null ? `hasta el ${endDateBlock.toLocaleDateString()} ` : '';
            const moreInfo = block.blku != null ? `( ${diffDays} días, por ${reasonBlock.label.toLocaleLowerCase()} ${derivatedTo})` : `(por ${reasonBlock.label.toLocaleLowerCase()} ${derivatedTo})`;
            const title = from + until + moreInfo;

            return {
                block: {
                    id: block.id,
                    title: title,
                    startDateBlock: block.blkf,
                    endDateBlock: block.blku,
                    reasonBlock: reasonBlk,
                    derivatedTo: block.blkdvt,
                    activatedSIRVI: block.blkipda ?? false,
                },
            };
        }) as Blocks[];
    }

    getRegisterMedicationPatients(
        departments: number[], 
        hours: string [],
        date: string,
        institutionId: number = this.institutionService.getCurrentInstitution(),
    ) {
        return this.calls.getRegisterMedicationPatients(institutionId, departments, hours, date).pipe(
            map((res) => {
                return res.payload;
            }),
            catchError(() => {
                this.calls.openSnack('Error al obtener los pacientes');
                return [];
            })
        );
    }
    getRegisterPatientControllerData() {
        return this.calls.getRegisterPatientControllerData().pipe(
            map((res) => {
                return res.payload;
            }),
            catchError(() => {
                this.calls.openSnack('Error al obtener los pacientes');
                return [];
            })
        );
    }
    updateMedicationRegisterPatientPrescriptionList(
        depList: number[],
        patients: any,
        institutionId: number = this.institutionService.getCurrentInstitution()
    ) {
        return this.calls.updateMedicationRegisterPatientPrescriptionList(depList, institutionId, patients).pipe(
            map((res) => {
                return res.payload;
            }),
            catchError(() => {
                this.calls.openSnack('Error al firmar incidencias');
                return [];
            })
        );
    }
}
