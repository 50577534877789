import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverdoseType } from '@interfaces/medicine/medicine.interface';

@Component({
  selector: 'app-overdose',
  templateUrl: './overdose.component.html',
  styleUrls: ['./overdose.component.scss']
})
export class OverdoseComponent {

  public overdoseType: OverdoseType;
  public maxIntake: number;
  public maxDay: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OverdoseComponent>
  ) {
    if (data) {
      this.overdoseType = data.type;
      this.maxIntake = data.maxIntake;
      this.maxDay = data.maxDay;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
