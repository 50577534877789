import { AppConfig } from '@interfaces/app-config.interface';
import { sidebarConfigItem } from '@interfaces/sidebar.interface';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';

export const appConfig: AppConfig = {
  origin: 'quantum',
};

// Side menu config
export const sidebarConfig: sidebarConfigItem[] = [
  { 
    link: 'welcome', 
    label: 'Inicio', 
    type: 'link', 
    iconName: 'home', 
    hasPermission: Utils.hasPermission(Permission.DASHBOARD) 
  },
  { 
    link: 'patients', 
    label: 'Residentes', 
    type: 'link', 
    iconName: 'group', 
    hasPermission: Utils.hasPermission(Permission.PATIENTS) 
  },
  {
    link: 'institutions',
    label: 'Centros',
    type: 'link',
    iconName: 'apartment',
    hasPermission: Utils.hasPermission(Permission.INSTITUTIONS)
  },
  {
    link: 'medicines',
    label: 'Vademecum',
    type: 'link',
    iconName: 'medication',
    hasPermission: Utils.hasPermission(Permission.VADEMECUM)
  },
  {
    link: 'processes',
    label: 'Procesos',
    iconName: 'edit_note',
    type: 'menu',
    hasPermission: Utils.hasPermission(Permission.PROCESSES),
    items: [
      {
        link: 'process/medication-register',
        label: 'Registo de medicación',
        type: 'link',
        iconName: '',
        hasPermission: Utils.hasPermission(Permission.PROCESSES_MEDICATION_REGISTER)
      },
    ],
  },
  {
    link: 'reports',
    label: 'Reportes',
    iconName: 'library_books',
    type: 'menu',
    hasPermission: Utils.hasPermission(Permission.REPORTS),
    items: [
      {
        link: 'reports',
        label: 'Gestión de pacientes',
        iconName: 'group',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS),
        items: [
          {
            link: 'reports/autorblis',
            label: 'Autorización de blisters',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/pathmeds',
            label: 'Consumo por pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/patientlist',
            label: 'Listado de pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/patchgs',
            label: 'Movimientos de pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
          {
            link: 'reports/pharmaplan',
            label: 'Plan farmacológico',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_PATIENTS)
          },
        ],
      },
      {
        link: 'reports',
        label: 'Gestión residencial',
        iconName: 'apartment',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_RESI),
        items: [
          {
            link: 'reports/actus',
            label: 'Administración de medicación (Actus)',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
          {
            link: 'reports/patdiet',
            label: 'Dietas por pacientes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
          {
            link: 'reports/mednostock',
            label: 'Medicamentos faltantes',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
          {
            link: 'reports/nelist',
            label: 'No emblistados - S/P',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
          {
            link: 'reports/prescrpe',
            label: 'Pendientes de evaluación',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
          {
            link: 'reports/cursocl',
            label: 'Curso clínico',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_RESI)
          },
        ],
      },
      {
        link: 'reports',
        label: 'Gestión farmacológica',
        iconName: 'medical_services',
        type: 'menu',
        hasPermission: Utils.hasPermission(Permission.REPORTS_FARMA),
        items: [
          {
            link: 'reports/medchanges',
            label: 'Cambios de medicación',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/medidebt',
            label: 'Deudas agudos/adelantos/pedidos',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/restat',
            label: 'Receta electrónica',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/ief',
            label: 'Indicadores farmacológicos',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/diapers',
            label: 'Incontinencia',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORT_INCONTINENCIA)
          },
          {
            link: 'reports/polymed',
            label: 'Indice de polimedicación',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/pharmalert',
            label: 'Reporte SCP',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORTS_MED)
          },
          {
            link: 'reports/fgpprescriptions',
            label: 'Prescripciones FGP',
            type: 'link',
            iconName: '',
            hasPermission: Utils.hasPermission(Permission.REPORT_FGP)
          },
        ],
      },
    ],
  },
];
