import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { InstitutionService } from '@services/institution.service';
import { PatientsService } from '@services/patients.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';

import { ModalPatientIncidentsListComponent } from '@shared/modal-patient-incidents-list/modal-patient-incidents-list.component';

import { SelectOption } from '@interfaces/input-select-option.interface';

import moment, { Moment } from 'moment';

import { Utils } from '@json/src/app/Utils';

@Component({
  selector: 'app-process-medication-register',
  templateUrl: './process-medication-register.component.html',
  styleUrls: ['./process-medication-register.component.scss']
})
export class ProcessMedicationRegisterComponent {

  public form: FormGroup;

  public departments: SelectOption[] = [];

  public dates: SelectOption[] = [];
  public hours: SelectOption[] = [];

  public incidentTypes: SelectOption[] = [
    { label: 'General', value: 1 },
    { label: 'SPDA', value: 2 },
    { label: 'N/E', value: 3 },
    { label: 'S/P', value: 4 },
  ];

  public incidentSubtypes: SelectOption[] = [
    { label: '', value: 0 },
    { label: 'El residente rehúsa tomar la medicación', value: 1 },
    { label: 'No administrada por ingreso hospitalario', value: 2 },
    { label: 'Medicación administrada por familiar/tutor (ausente de la residencia)', value: 3 },
    { label: 'No admnistrada por prescripción médica', value: 4 },
    { label: 'El residente es baja del centro', value: 5 },
    { label: 'Medicación administrada con cambio de lugar', value: 6 },
    { label: 'No administrada por somnolencia', value: 7 },
    { label: 'QR no leido. Sin blíster preparado', value: 8 },
  ];

  public patients: SelectOption[] = [];
  public filteredPatients: SelectOption[] = [];

  public generalIncident: any[] = [];

  public mouseDown: boolean = false;
  loadingPatients: boolean = false;

  patientsSearched: boolean = false;

  private patientsRequest: any;

  patientsFilter = new FormControl(null);

  get step1Complete(): boolean {
    return (this.form.get('departments')?.valid && this.form.get('date')?.valid && this.form.get('hours')?.valid) || false;
  }
  get step2Complete(): boolean {
    return this.step1Complete && this.form.get('patient')?.valid || false;
  }
  get appliesToAllMedicationHours(): boolean {
    return this.currentPatient?.applyToAllMedicationHours?.value || false;
  }
  get currentIncident(): number {
    return this.form.get('incident')?.value;
  }
  get currentPatient(): any {
    return this.form.get('patient')?.value;
  }

  auxFormField1 = new FormControl(null);
  auxFormField2 = new FormControl(null);
  auxFormField3 = new FormControl(null);
  auxFormField4 = new FormControl(null);

  constructor(
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private patientsService: PatientsService,
    private loadingService: LoadingService,
    private modal: ModalService,
    private dialog: MatDialog,
  ) { 
    this.form = this.formBuilder.group({
      departments: ['', Validators.required],
      date: ['', Validators.required],
      // startHour: ['', Validators.required],
      // endHour: ['', Validators.required],
      incident: [1, Validators.required],
      incidentSubtype: [0],
      patient: ['', Validators.required],
      note: [''],
      hours: ['', Validators.required],
    });

    const now = moment().startOf('day').locale('es');
    const yesterday = moment().startOf('day').locale('es').subtract(1, 'days');
    const twoDaysAgo = moment().startOf('day').locale('es').subtract(2, 'days');

    this.dates = [
      { label: `Hoy (${now.format('dddd DD')})`, value: now },
      { label: `Ayer (${yesterday.format('dddd DD')})`, value: yesterday },
      { label: `Anteayer (${twoDaysAgo.format('dddd DD')})`, value: twoDaysAgo },
    ];

    for (let i = 0; i < 24; i++) {
      const hour = moment().startOf('day').add(i, 'hours');
      this.hours.push({ label: hour.format('HH:mm'), value: hour });
    }
  }

  ngOnInit(): void {
    this.institutionService.getInstitutionDepartments()
    .subscribe(departments => {
      this.departments = departments;
    });

    
    this.form.controls['incident'].valueChanges.subscribe(incident => {
      this.filterPatients();
    });

    this.form.controls['patient'].valueChanges.subscribe(patient => {
      if ( patient ) {
        const medicines = [...patient.spda.map((item: any) => item.medicines), ...patient.ne.map((item: any) => item.medicines), ...patient.sp.map((item: any) => item.medicines)].flat();
        
        const unique = medicines
        .filter((obj: any, index) => medicines.findIndex((item: any) => item.AdministerTime === obj.AdministerTime) === index
        ).filter((item: any) => !item.AdministerTime.includes('S/P'));

        // Check if patient has generalIncident initialized
        if ( !patient.generalIncident ) {
          // Initialize generalIncident
          patient.generalIncident = unique.map((item: any) => ({
            hour: item.AdministerTime,
            incident: new FormControl(null),
            note: new FormControl(null),
          }));

          // Initialize applyToAllMedicationHours
          patient.applyToAllMedicationHours = new FormControl(false);

          // Clear incidences when applyToAllMedicationHours is checked
          patient.applyToAllMedicationHours.valueChanges.subscribe((value: any) => {
            patient.generalIncident.forEach((item: any) => {
              item.incident.setValue(null);
              item.note.setValue(null);
            });
          });
        }

        // Check if patient spda form is initialized
        patient.spda.forEach((item: any) => {
          !item.note && (item.note = new FormControl(null));

          item.medicines.forEach((medicine: any) => {
            !medicine.notAdministered && (medicine.notAdministered = new FormControl(false));
          });
        });

        // Check if patient ne form is initialized
        patient.ne.forEach((item: any) => {
          item.medicines.forEach((medicine: any) => {
            !medicine.note && (medicine.note = new FormControl(null));
            !medicine.notAdministered && (medicine.notAdministered = new FormControl(false));
          });
        });
        // Check if patient sp form is initialized
        patient.sp.forEach((item: any) => {
          item.medicines.forEach((medicine: any) => {
            !medicine.note && (medicine.note = new FormControl(null));
            !medicine.administered && (medicine.administered = new FormControl(false));
            !medicine.hour && (medicine.hour = new FormControl(null));
            !medicine.minute && (medicine.minute = new FormControl(null));
          });
        })
      }
    });

    this.form.controls['date'].valueChanges.subscribe(date => {
      if ( !date ) return;

      this.removeAllHours()

      const now = moment();

      this.hours.forEach(hour => {
        const hourMoment = moment(hour.value);
        hour.hidden = date.isSame(now, 'day') && hourMoment.isAfter(now);
      });
    });

    this.patientsFilter.valueChanges.subscribe(value => {
      this.filterPatients();
    });

    this.patientsService.getRegisterPatientControllerData()
    .subscribe(data => {
      if ( data && data.length > 0 ) {
        this.incidentSubtypes = data
          .map((item: any) => ({ label: item.Description, value: Number(item.Id) }))
          .filter((item: any) => item.value > 1 && item.value < 51);
      }
    });
  }

  setHour(hour: Moment) {
    const hoursControl = this.form.get('hours');
    if (hoursControl) {
      const selectedHours = hoursControl.value || [];
      const hourIndex = selectedHours.findIndex((h: Moment) => h.isSame(hour));
      if (hourIndex > -1) {
        selectedHours.splice(hourIndex, 1);
      } else {
        selectedHours.push(hour);
      }
      hoursControl.setValue(selectedHours);
    }
  }
  setStartHour(hour: Moment) {
    this.form.patchValue({ startHour: hour });
  }
  setEndHour(hour: Moment) {
    this.form.patchValue({ endHour: hour });
  }
  setPatient(patient: SelectOption | null) {
    this.form.patchValue({ patient: patient?.value });
  }

  isHourSelected(hour: Moment): boolean {
    const hoursControl = this.form.get('hours');
    if (hoursControl) {
      const selectedHours = hoursControl.value || [];
      return selectedHours.some((h: Moment) => h.isSame(hour));
    }
    return false;
  }
  isPatientSelected(patient: SelectOption): boolean {
    return this.form.get('patient')?.value === patient.value;
  }

  patientHasIncidents(patient: any): boolean {
    return patient?.value?.generalIncident?.some((item: any) => !!item.incident.value) ||
      patient?.value?.spda?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value)) ||
      patient?.value?.ne?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value)) ||
      patient?.value?.sp?.some((item: any) => item.medicines.some((medicine: any) => medicine?.administered?.value));
  }
  patientHasGeneralIncidents(patient: any): boolean {
    return patient?.value?.generalIncident?.some((item: any) => !!item.incident.value);
  }
  patientHasSPDAIncidents(patient: any): boolean {
    return patient?.value?.spda?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value));
  }
  patientHasNEIncidents(patient: any): boolean {
    return patient?.value?.ne?.some((item: any) => item.medicines.some((medicine: any) => medicine?.notAdministered?.value));
  }
  patientHasSPIncidents(patient: any): boolean {
    return patient?.value?.sp?.some((item: any) => item.medicines.some((medicine: any) => medicine?.administered?.value));
  }
  clearAllPatientIncidents($event: any, patient: any) {
    $event.stopPropagation();

    this.clearPatientGeneralIncidents($event, patient);
    this.clearPatientSPDAIncidents($event, patient);
    this.clearPatientNEIncidents($event, patient);
    this.clearPatientSPIncidents($event, patient);
  }
  clearPatientGeneralIncidents($event: any, patient: any) {
    $event.stopPropagation();

    patient.value.generalIncident.forEach((item: any) => {
      item.incident.setValue(null);
    });
  }
  clearPatientSPDAIncidents($event: any, patient: any) {
    $event.stopPropagation();

    patient.value.spda.forEach((item: any) => {
      item.note.setValue(null);
      item.medicines.forEach((medicine: any) => {
        medicine.notAdministered.setValue(false);
      });
    });
  }
  clearPatientNEIncidents($event: any, patient: any) {
    $event.stopPropagation();

    patient.value.ne.forEach((item: any) => {
      item.medicines.forEach((medicine: any) => {
        medicine.note.setValue(null);
        medicine.notAdministered.setValue(false);
      });
    });
  }
  clearPatientSPIncidents($event: any, patient: any) {
    $event.stopPropagation();

    patient.value.sp.forEach((item: any) => {
      item.medicines.forEach((medicine: any) => {
        medicine.note.setValue(null);
        medicine.administered.setValue(false);
        medicine.hour.setValue(null);
        medicine.minute.setValue(null);
      });
    });
  }

  addAllHours() {
    this.form.patchValue({ hours: this.hours.filter(item => !item.hidden).map(hour => hour.value) });
  }
  removeAllHours() {
    this.form.patchValue({ hours: [] });
  }

  filterPatients() {
    const incident = this.form.get('incident')?.value;

    switch (incident) {
      case 1:
        this.filteredPatients = this.patients;
        break;
      case 2:
        this.filteredPatients = this.patients
          .filter((patient: any) => Object.keys(patient.value.spda).length > 0 && !this.patientHasGeneralIncidents(patient));
        break;
      case 3:
        this.filteredPatients = this.patients
          .filter((patient: any) => Object.keys(patient.value.ne).length > 0 && !this.patientHasGeneralIncidents(patient));
        break;
      case 4:
        this.filteredPatients = this.patients
          .filter((patient: any) => Object.keys(patient.value.sp).length > 0 && !this.patientHasGeneralIncidents(patient));
        break;
    }

    // @ts-ignore
    this.filteredPatients = this.filteredPatients.filter((patient: any) => patient.label.toLowerCase().includes(this.patientsFilter.value?.toLowerCase() || ''));
  
    this.setPatient(null);
  }

  keys(obj: any): string[] {
    return Object.keys(obj);
  }
  values(obj: any): any[] {
    return Object.values(obj);
  }

  getPatinets() {
    this.loadingPatients = true;
    this.loadingService.start();

    this.setPatient(null)

    this.patientsRequest?.unsubscribe();
    this.patientsRequest = this.patientsService.getRegisterMedicationPatients(
      this.form.get('departments')?.value,
      this.form.get('hours')?.value.map((hour: Moment) => hour.format('HH:mm')),
      // hours,
      `/Date(${Utils.BUG_FixDateForTimeZone(this.form.get('date')!.value.toDate())!.valueOf().toString()})/`
    ).subscribe({
      next: patients => {
        this.patients = patients.map((patient: any) => ({
          label: patient.PatientName,
          value: {
            rawData: patient,
            spda: Object.entries(patient.PrescrList
              .filter((prescr: any) => !prescr.AdministerTime.includes('S/P') && prescr.NoBlister === 0)
              .reduce((rv: any, x: any) => { (rv[x['AdministerTime']] = rv[x['AdministerTime']] || []).push(x); return rv; }, {}))
              // @ts-ignore
              .map(([hour, medicines]: [string, any[]]) => ({ hour, medicines })),
            ne: Object.entries(patient.PrescrList
              .filter((prescr: any) => !prescr.AdministerTime.includes('S/P') && prescr.NoBlister === 1)
              .reduce((rv: any, x: any) => { (rv[x['AdministerTime']] = rv[x['AdministerTime']] || []).push(x); return rv; }, {}))
              // @ts-ignore
              .map(([hour, medicines]: [string, any[]]) => ({ hour, medicines })),
            sp: Object.entries(patient.PrescrList
              .filter((prescr: any) => prescr.AdministerTime.includes('S/P'))
              .reduce((rv: any, x: any) => { (rv[x['AdministerTime']] = rv[x['AdministerTime']] || []).push(x); return rv; }, {}))
              // @ts-ignore
              .map(([hour, medicines]: [string, any[]]) => ({ hour, medicines })),
          }
        }));

        this.filterPatients();
      },
      complete: () => {
        this.loadingService.stop();
        this.loadingPatients = false;
        this.patientsSearched = true;
      }
    });
  }

  openIncidentModal(canSign: boolean = false) {
    let patients = this.patients.map(patient => ({
      ...patient,
      value: {
      ...patient.value,
      rawData: { ...patient.value.rawData },
      spda: patient.value.spda.map((item: any) => ({
        ...item,
        medicines: item.medicines.map((medicine: any) => ({ ...medicine }))
      })),
      ne: patient.value.ne.map((item: any) => ({
        ...item,
        medicines: item.medicines.map((medicine: any) => ({ ...medicine }))
      })),
      sp: patient.value.sp.map((item: any) => ({
        ...item,
        medicines: item.medicines.map((medicine: any) => ({ ...medicine }))
      }))
      }
    }));

    patients = patients.filter((patient: any) => this.patientHasIncidents(patient));

    patients.forEach((patient: any) => {
      patient.value.generalIncident = patient.value.generalIncident.filter((incident: any) => !!incident.incident.value);
      patient.value.spda = patient.value.spda.filter((item: any) => item.medicines.some((medicine: any) => medicine.notAdministered.value));
      patient.value.spda.forEach((item: any) => {
        item.medicines = item.medicines.filter((medicine: any) => medicine.notAdministered.value);
      });
      patient.value.ne = patient.value.ne.filter((item: any) => item.medicines.some((medicine: any) => medicine.notAdministered.value));
      patient.value.ne.forEach((item: any) => {
        item.medicines = item.medicines.filter((medicine: any) => medicine.notAdministered.value);
      });
      patient.value.sp = patient.value.sp.filter((item: any) => item.medicines.some((medicine: any) => medicine.administered.value));
      patient.value.sp.forEach((item: any) => {
        item.medicines = item.medicines.filter((medicine: any) => medicine.administered.value);
      });
    });

    const dialogRef = this.dialog.open(ModalPatientIncidentsListComponent, {
      data: {
        patients,
        incidentSubtypes: this.incidentSubtypes,
        canSign,
      },
    });

    dialogRef.afterClosed()
    .subscribe(result => {
      if ( result === true ) {
        this.sign();
      }
    });
  }

  showChangeSelectionModal() {
    this.modal.openModalCallback(
      () => { 
        this.patientsSearched = false,
        this.patients = this.filteredPatients = [],
        this.setPatient(null)
      },
      '¿Está seguro que desea volver a realizar la búsqueda?',
      'Cualquier incidencia registrada se perderá.',
    );
  }

  showSignModal() {
    this.modal.openModalCallback(
      () => { 
        this.sign();
      },
      '¿Está seguro de que desea firmar el registro?',
      'Se procederá a firmar el registro de administración de la medicación para el período seleccionado.',
      undefined,
      () => {},
      'Ver incidencias',
      () => {
        this.openIncidentModal(true);
      }
    );
  }

  sign() {
    this.loadingService.start();

    this.patients.forEach((patient: any) => {
      // SPDA
      patient.value.spda.forEach((item: any) => {
        item.medicines.forEach((medicine: any) => {
          if (medicine.notAdministered && medicine.notAdministered.value) {
            medicine.EditSource = 2;
            medicine.EditValue1 = 1;
            medicine.EditValue3 = item.note.value;
          }
        });
      });

      // NE
      patient.value.ne.forEach((item: any) => {
        item.medicines.forEach((medicine: any) => {
          if (medicine.notAdministered && medicine.notAdministered.value) {
            medicine.EditSource = 3;
            medicine.EditValue1 = 1;
            medicine.EditValue3 = medicine.note.value;
          } 
        });
      });

      // SP
      patient.value.sp.forEach((item: any) => {
        item.medicines.forEach((medicine: any) => {
          if (medicine.administered && medicine.administered.value) {
            medicine.EditSource = 4;
            medicine.EditValue1 = 1;
            medicine.EditValue2 = `${medicine.hour.value}:${medicine.minute.value}`;
            medicine.EditValue3 = medicine.note.value;
          }
        });
      });

      // General
      patient.value.generalIncident && patient.value.generalIncident.forEach((item: any) => {
        if (item.incident && item.incident.value) {
          patient.value.rawData.PrescrList
          .filter((medicine: any) => (patient.applyToAllMedicationHours && patient.applyToAllMedicationHours.value) ? true : medicine.AdministerTime === item.hour)
          .forEach((medicine: any) => {
            medicine.EditSource = 1;
            medicine.EditValue1 = 50 + item.incident.value;
            medicine.EditValue3 = item.note.value;
          });
        }
      });
    });

    const patients = this.patients
      .map((patient: any) => patient.value.rawData)

    patients.forEach((patient: any) => {
      patient.PrescrList.forEach((medicine: any) => {
        delete medicine.notAdministered;
        delete medicine.administered;
        delete medicine.hour;
        delete medicine.minute;
        delete medicine.note;
      })
    });

    this.patientsService.updateMedicationRegisterPatientPrescriptionList(
      this.form.get('departments')?.value,
      patients
    ).subscribe({
      next: () => {
        this.form.reset();
        this.patients = this.filteredPatients = [],
        this.patientsSearched = false;

        this.modal.openModalInfo('El registro de medicación se ha firmado correctamente.');
      },
      error: () => {
        this.modal.openModalErrorInfo('Ha ocurrido un error al firmar el registro de medicación.');
      },
      complete: () => {
        this.loadingService.stop();
      }
    });
  }
}
