export enum UserRole {
    Admin,
    Adm,
    Incontinencia,
    Medicacion,
    Due,
    PDA,
    Reportes,
    Revisor,
    Tecnico,
    Farmaceutico,
    Titular,
    IPF
}

export enum CorotaRole {
    Admin = "CorotaAdmin",
    Adm = "CorotaAdm",
    Incontinencia = "CorotaIncontinencia",
    Medicacion = "CorotaMedicacion",
    Due = "CorotaDUE",
    Reportes = "CorotaReportes",
    Revisor = "CorotaRevisor",
    Blisters = "CorotaBlisters",
    AdminRE = "CorotaAdminRE",
    PDA = "CorotaPDA",
    IPF = "CorotaIPF"
}