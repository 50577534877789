import { Injectable } from '@angular/core';
import { CallsService } from '@services/api/calls.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcReceptionsService {
  constructor(private calls: CallsService) {}

  getReceptions(jss: string): Observable<any> {
    return this.calls.getProcsReceptions(jss);
  }

  getFarmaticRecep(jss: string): Observable<any> {
    return this.calls.getFarmaticReceps(jss);
  }

  importFarmaticRecep(jss: string): Observable<any> {
    return this.calls.importFarmaticRecep(jss);
  }
}
