export const DOCUMENT_IMG = {
  excel: 'assets/pics/excel.svg',
  image: 'assets/pics/picture.svg',
  pdf: 'assets/pics/pdf.svg',
};

export const DOCUMENT_DICTIONARY = [
  { value: 'picture', possibilities: ['jpg', 'png', 'jpeg', 'gif'] },
  { value: 'excel', possibilities: ['xls', 'xlsx', 'xlsm', 'xlsb'] },
  { value: 'pdf', possibilities: ['pdf'] },
];
