<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Reporte de curso clínico'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">

        <section class="filter-container" *ngIf="typeGlobal" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>
  
        </section>

        <section class="interval">
          <p class="subtitle">Intervalo</p>
          <div class="container-fields">
            <mat-radio-group formControlName="intervalType" class="col-6">
              <mat-radio-button
                value="specificDay"
                (change)="toggleSelectSpecificDay()"
              >
                Día específico
              </mat-radio-button>
  
              <mat-radio-button
                value="intervalDay"
                (change)="toggleSelectIntervalDay()"
              >
                Intervalo de fechas
              </mat-radio-button>
            </mat-radio-group>
  
            <div class="col-12 subsection">
              <mat-form-field
                *ngIf="form.controls['intervalType'].value === 'specificDay'" class="col-6"
              >
                <div class="label"> Día específico</div>
                <input
                  matInput
                  [matDatepicker]="specificDay"
                  formControlName="specificDay"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="specificDay"
                >
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #specificDay>
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12 subsection">
              <mat-form-field
                *ngIf="form.controls['intervalType'].value === 'intervalDay'" class="col-3"
              >
                <div  class="label">Desde</div>
                <input
                  matInput
                  [matDatepicker]="startDatePicker"
                  formControlName="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDatePicker"
                >
                  <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #startDatePicker>
                </mat-datepicker>
              </mat-form-field>
    
              <mat-form-field
                *ngIf="form.controls['intervalType'].value === 'intervalDay'" class="col-3"
              >
                <div  class="label">Hasta</div>
                <input
                  matInput
                  [matDatepicker]="endDatePicker"
                  formControlName="endDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDatePicker"
                >
                <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #endDatePicker>
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>

      
        </section>

        <section class="filter-container">
          <p class="subtitle">Filtrar por</p>
          <mat-checkbox (change)="toggleSelectPatient()"class="col-12" >
            Paciente
          </mat-checkbox>
          <app-form-field 
            *ngIf="showPatientSelect" 
            class="col-6"
            label="" 
            type="autocomplete" 
            [options]="selectOptionsPatients" 
            [fieldControl]="form.controls['selectedPatient'] | asFormControl">
          </app-form-field>
        </section>
      </form>
    </div>
  </app-report-select>
</ng-container>
