import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { reportDataAuthBlisters } from '@constants/reports/report-auth-blisters';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-report-auth-blisters',
  templateUrl: './report-auth-blisters.component.html',
  styleUrls: ['./report-auth-blisters.component.scss'],
})
export class ReportAuthBlistersComponent implements OnInit {
  public reportDataAuthBlisters = reportDataAuthBlisters;

  public selectOptionsInsitutions: SelectOption[];
  public form: FormGroup;
  public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
  public currentRoute: string;
  public report: Report;
  public pathReportDownload: string;
  public safeURL: SafeUrl;

 
  constructor(
    private router: Router, 
    private reportService: ReportsService, 
    private roleManager: RoleManagerService, 
    private institutionService: InstitutionService, 
    private formBuilder: FormBuilder,
    private titleService: Title,
    private gaService: GoogleAnalyticsService
  ) {
    this.selectOptionsInsitutions = [];
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Reporte: Autorización de Blíster`);
    this.gaService.pageView(`${window.location.href}`, `Reporte: Autorización de blíster`);

    this.typeGlobal = this.roleManager.isPharma();
    this.form = this.formBuilder.group({
      selectedInstitution: ['', Validators.required],
    
    });
    this.getDataForTheSelects();

  }


  getDataForTheSelects(): void {
    this.institutionService.getInstitutions().then(() => {
      this.selectOptionsInsitutions =
        Object.assign([], this.institutionService.institutionList.getValue());

      // Order Alpabetically name
      this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
      // this.selectOptionsInsitutions.map((a) =>{
      //   a.label = a.label.toUpperCase();
      // })

      if (this.selectOptionsInsitutions) {
        const hasEmptyValue = this.selectOptionsInsitutions.some(
          (item) => item.value === ''
        );
        if (hasEmptyValue) {
          this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
            (item) => item.value !== ''
          );
        }
      }
    });
  }

  getFormData(): {
    selectedInstitution: string;
  } {
    const {
      selectedInstitution,
    } = this.form.getRawValue();

    return {
      selectedInstitution,
    };
  }



  handlePreviewClick(): void {
    this.reportDataAuthBlisters.data.dn = Utils.buildUniqueFileName + '_{0}.html';
    this.reportService.handlePreviewClick(
      this.router,
      this.reportDataAuthBlisters
    );
  }

  handlePrintClick(): void {
    this.reportService.handlePrintClick(this.reportDataAuthBlisters);
  }

  handlePdfClick(): void {
    this.reportService.handlePdfClick(this.reportDataAuthBlisters);
  }

  handleExcelClick(): void {
    this.reportService.handleExcelClick(this.reportDataAuthBlisters);
  }
}
