import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FilterOption, FilterSelect } from '@interfaces/filter.interface';

@Component({
  selector: 'app-advanced-filter-select',
  templateUrl: './advanced-filter-select.component.html',
  styleUrls: ['./advanced-filter-select.component.scss']
})
export class AdvancedFilterSelectComponent {

  @Input() options: FilterSelect[] = [];

  @Output() onFilter = new EventEmitter<{filterId: string, mode: 'exclude' | 'include' | 'exclusive'}[]>();

  filter = new FormControl(['']);

  filterConfiguration: {
    filter: FilterOption;
    mode: 'exclude' | 'include' | 'exclusive';
    group: string;
  }[] = [];

  value: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.options.forEach(option => {
      option.filter.forEach(filter => {
        this.filterConfiguration.push({
          filter,
          mode: filter.defaultState || 'include',
          group: option.name
        });
      });
    });

    this.generateFilterValue();
  }

  setItemState(event: Event, item: FilterOption, mode: 'exclude' | 'include' | 'exclusive'): void {
    event.stopPropagation();

    if ( mode === 'exclusive' ) {
      this.filterConfiguration.forEach(filter => {
        if (filter.filter.id !== item.id) {
          filter.mode = 'exclude';
        }
      });
    } else {
      this.filterConfiguration.forEach(filter => {
        if ( filter.mode === 'exclusive' ) {
          filter.mode = 'exclude';
        }
      });
    }

    const filter = this.filterConfiguration.find(filter => filter.filter.id === item.id);
    if (filter) {
      filter.mode = mode;
    }

    this.onFilter.emit(this.filterConfiguration.map(filter => ({filterId: filter.filter.id, mode: filter.mode})));

    this.generateFilterValue();
  }

  getItemState(item: FilterOption): 'exclude' | 'include' | 'exclusive' {
    const filter = this.filterConfiguration.find(filter => filter.filter.id === item.id);
    return filter ? filter.mode : 'include';
  }

  setGroupState(event: Event, group: string, mode: 'exclude' | 'include' | 'exclusive'): void {
    event.stopPropagation();

    if ( mode === 'exclusive' ) {
      this.filterConfiguration.forEach(filter => {
        if (filter.group !== group) {
          filter.mode = 'exclude';
        }
      });
    } else {
      this.filterConfiguration.forEach(filter => {
        if ( filter.mode === 'exclusive' ) {
          filter.mode = 'exclude';
        }
      });
    }

    this.filterConfiguration.forEach(filter => {
      if (filter.group === group) {
        filter.mode = mode;
      }
    });

    this.onFilter.emit(this.filterConfiguration.map(filter => ({filterId: filter.filter.id, mode: filter.mode})));

    this.generateFilterValue();
  }

  getGroupState(group: string): 'exclude' | 'include' | 'exclusive' {
    const filters = this.filterConfiguration.filter(filter => filter.group === group);

    if (filters.every(filter => filter.mode === 'exclude')) {
      return 'exclude';
    } else if (filters.every(filter => filter.mode === 'include')) {
      return 'include';
    } else {
      return 'exclusive';
    }
  }

  generateFilterValue() {
    const exclusiveFilter = this.filterConfiguration.find((item) => item.mode === 'exclusive');

    if ( exclusiveFilter ) {
      this.value = [exclusiveFilter.filter.value];
    } else {
      this.value = this.filterConfiguration
        .filter((item) => item.mode === 'include')
        .map((item) => item.filter.value);
    }

    // @ts-ignore
    this.filter.patchValue(this.value);
  }
}
