import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { reportDataPatientMovement } from '@constants/reports/report-p-movements';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Report } from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-p-movements',
    templateUrl: './report-p-movements.component.html',
    styleUrls: ['./report-p-movements.component.scss'],
})
export class ReportPMovementsComponent implements OnInit{
    public reportDataPatientMovement = reportDataPatientMovement;
    public dateForm: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public currenInstitution: number;



    public selectOptionsInsitutions: SelectOption[];
    public form: FormGroup;
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.dateForm = this.formBuilder.group({
            startDate: [moment(), Validators.required],
            endDate: [moment().add(7, 'days'), Validators.required],
            selectedInstitution: [''],
        });
        this.currentRoute = this.router.url;
        this.currenInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Movimientos de pacientes`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Movimientos de pacientes`);

        this.typeGlobal = this.roleManager.isPharma();
        
        this.getDataForTheSelects();
    }

    getDataForTheSelects(): void {
        this.institutionService.getInstitutions().then(() => {
          this.selectOptionsInsitutions =
            Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })
          if (this.selectOptionsInsitutions) {
            const hasEmptyValue = this.selectOptionsInsitutions.some(
              (item) => item.value === ''
            );
            if (hasEmptyValue) {
              this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                (item) => item.value !== ''
              );
            }

                this.selectOptionsInsitutions.unshift({
                    label: 'Todos los centros',
                    value: ''
                });
            }

          if ( !this.typeGlobal ) {
            this.dateForm.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
          }
        });
    }

    handlePreviewClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution } = this.getFormData();
            this.setInfoRP(startDate, endDate,  selectedInstitution, true);
            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataPatientMovement
            );
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    getFormData(): { startDate: string; endDate: string,    selectedInstitution: string} {
        const startDate = this.dateService.formatDateToString(
            this.dateForm.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.dateForm.get('endDate')?.value
        );

        const {
            selectedInstitution,
          } = this.dateForm.getRawValue();

        return { startDate, endDate ,  selectedInstitution};
    }

    handlePrintClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution } = this.getFormData();

             this.setInfoRP(startDate, endDate,  selectedInstitution);
            this.reportService.handlePrintClick(this.reportDataPatientMovement);
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handlePdfClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution } = this.getFormData();
             this.setInfoRP(startDate, endDate,  selectedInstitution);
            this.reportService.handlePdfClick(this.reportDataPatientMovement);
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    handleExcelClick(): void {
        if (this.dateForm.valid) {
            const { startDate, endDate, selectedInstitution } = this.getFormData();
             this.setInfoRP(startDate, endDate,  selectedInstitution);
            this.reportService.handleExcelClick(this.reportDataPatientMovement);
        }  else {
            if ( this.dateForm.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');

            }
        }
    }

    setInfoRP(startDate: string, endDate: string,   selectedInstitution: any, isPreview: boolean = false): void {
      
        let institutionId;

        if (selectedInstitution.id ) {
            //@ts-ignore
            institutionId = selectedInstitution.id.toString();
        } else {
            institutionId = null;
        }

        const rp = {
            LocaleId: '3082',
            // InstitutionList: selectedInstitution == '' ? null : selectInstitutions,
            InstitutionId: institutionId,
            DateFrom: Utils.BUG_FixDateForTimeZone(moment(startDate).startOf('day').toDate())?.toISOString().split('.')[0],
            DateUntil: Utils.BUG_FixDateForTimeZone(moment(endDate).endOf('day').toDate())?.toISOString().split('.')[0],
        };

        const stringRP = JSON.stringify(rp);
        this.reportDataPatientMovement.data.rp = stringRP;
        console.log(isPreview);
        console.log(Utils.buildUniqueFileName + "{0}.html");

        if (isPreview) this.reportDataPatientMovement.data.dn = Utils.buildUniqueFileName + "{0}.html";
        this.reportDataPatientMovement.dataDownloadPDF.rp = stringRP;
        this.reportDataPatientMovement.dataDownloadExcel.rp = stringRP;
        this.reportDataPatientMovement.dataRenderReport.rp = stringRP;

        this.reportDataPatientMovement.dataDownloadPDF.dn = this.reportDataPatientMovement.dataRenderReport.dn =
            `Movimientos_de_pacientes_${this.institutionService.getCurrentInstitutionShortName()}_Del_${moment(startDate).format('YYYYMMDD')}_AL_${moment(endDate).format('YYYYMMDD')}.pdf`;
        this.reportDataPatientMovement.dataDownloadExcel.dn = `Movimientos_de_pacientes_${this.institutionService.getCurrentInstitutionShortName()}_Del_${moment(startDate).format('YYYYMMDD')}_AL_${moment(endDate).format('YYYYMMDD')}.xls`;
    }
}
