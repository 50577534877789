import { Component, Input, ViewChild } from '@angular/core';
import { sidebarConfigItem } from '@interfaces/sidebar.interface';

@Component({
    selector: 'app-sub-menu-item',
    templateUrl: './sub-menu-item.component.html',
    styleUrls: ['./sub-menu-item.component.scss']
})
export class SubMenuItemComponent {
    @Input() items: sidebarConfigItem[] | undefined;
    @ViewChild('childMenu') public childMenu: any;

    onMouseEnter($event: MouseEvent) {
        try {
            // @ts-ignore
            $event?.target?.click();
        } catch (e) { }
    }

    testPerm(item: sidebarConfigItem) {
        if (typeof (item.hasPermission) == 'function') {
            return item.hasPermission().length > 0; // Puede leer o escribir así que se debe mostrar
        }

        return item.hasPermission.length > 0; // Puede leer o escribir así que se debe mostrar
    }
}
