import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  formsConfig,
  tabs,
} from '@constants/medicines/forms/medicine-new.config';
import { TabInterface } from '@interfaces/dynamic-form.interface';
import { FormDetailAction } from '@interfaces/form/form-detail';
import {
  MedicineAdminRoute,
  MedicineEditEmpty,
  MedicineForm,
  MedicineInteraction,
  MedicineInteractionText,
} from '@interfaces/medicine/medicine.interface';
import { FormInterface } from '@interfaces/patient/patient-edit.interface';
import { CallsService } from '@services/api/calls.service';
import { FormService } from '@services/form.service';
import { InstitutionService } from '@services/institution.service';
import { MedicinesService } from '@services/medicines.service';
import { InteractionService } from '@services/medicines/interaction.service';
import { Subscription, skipWhile, take } from 'rxjs';

@Component({
  selector: 'app-medicine-new',
  templateUrl: './medicine-new.component.html',
  styleUrls: ['./medicine-new.component.scss'],
})
export class MedicineNewComponent {
  public isEdit: boolean = true;

  public forms: FormInterface = {};
  public tabs: TabInterface[] = tabs;
  private formsConfig = formsConfig;

  private subs: Subscription[] = [];

  //medicines
  public medicineAdminRouteList: MedicineAdminRoute[];
  public medicineInteractionList: MedicineInteraction[];
  public medicineInteractionListText: MedicineInteractionText[];
  public medicineInfo: MedicineForm;
  public medicines: MedicineForm;
  public medicineId: string | null;

  public tabQueryParam: string | null;
  public selectedTabIndex: number;

  public formChanged: boolean = true;

  public collapsedProfile: boolean = false;

  @ViewChild('saveButton') saveButton: any;

  constructor(
    private route: ActivatedRoute,
    private medicineService: MedicinesService,
    private interactionService: InteractionService,
    private formService: FormService,
    private institutionService: InstitutionService,
    private calls: CallsService,
  ) {}

  ngOnInit(): void {
    this.medicineService.clearCurrentMedicine();
    this.getSelectedTab();
    this.initForms();
    this.getMedicineDefaults();
    this.getMedicineAdministrationOptions();
    this.medicineInfo = this.medicines;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getSelectedTab(): void {
    this.route.queryParamMap.subscribe((query) => {
      this.tabQueryParam = query.get('tab');
      this.selectedTabIndex = this.tabQueryParam
        ? parseInt(this.tabQueryParam)
        : 0;
    });
  }

  getMedicineInfo(): void {
    this.medicineService.currentMedicine
    .pipe(
        skipWhile(medicine => medicine instanceof MedicineEditEmpty),
        take(1)
    )
    .subscribe((medicine) => {
      this.medicines = this.medicineService.transformMedicineEditToForm(medicine, true);

      Object.keys(this.medicines).forEach((key: string) => {
        // @ts-ignore
        Object.keys(this.medicines[key]).forEach((subkey: string) => {
          // @ts-ignore
          this.medicines[key][subkey] === null ? this.medicines[key][subkey] = '' : null
        });
      }) 

      this.medicines.otherData.cureMaterial = false;
      this.medicines.robot.robotOptions.alwaysPlate = false;

      this.setGeneralFormInfo(this.medicines);
      this.setMedicineAdminRoute();
    })

    this.medicineService.getMedicineEdit(0);
  }

  setGeneralFormInfo(medicineInfo: MedicineForm): void {
    this.forms['general'].form.patchValue(medicineInfo, { emitEvent: false });
    this.setGeneralListener();
  }

  setGeneralListener(): void {
    this.forms['general'].form.valueChanges.subscribe((data) => {
      this.formChanged = true;

      // If hospital use is set, no embistable should be true
      if ( data['otherData'].hospitalUse ) {
        // @ts-ignore
        this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].setValue(true);
        // @ts-ignore
        this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].disable();
      } else {
        // @ts-ignore
        this.forms['robot'].form.controls['robotOptions'].controls['noEmbistable'].enable();
      }
    });
  }

  setMedicineAdminRoute(): void {
    //get the medicines admin routes & set the admin route option from the backend
    if (this.medicineAdminRouteList) {
      let optionsAdminRoute = this.medicineAdminRouteList.map((admin) => {
        return this.medicineService.transformMedicineIAdminRouteToSelectOption(
          admin
        );
      });

      // @ts-ignore
      if ( this.forms['general']?.schema[0]?.childs[4] ) {
        this.forms['general'].schema[0].childs[4].options = optionsAdminRoute;
      }
    }
  }

  setInteractionFormInfo(medicineInfo: MedicineForm): void {
    this.forms['interactions'].form.patchValue(medicineInfo.interactions);
    this.forms['interactions'].schema[1].options =
      this.medicineService.convertToInteraction(
        medicineInfo.interactions.interactions
      );
  }

  setEpigraphListener(): void {
    this.forms['epigraphs'].form.valueChanges.subscribe((change) => {
      this.subs.push(
        this.interactionService.medicineInteractionList.subscribe(
          (interaction) => {
            this.medicineInteractionList = interaction.map((i) =>
              this.interactionService.transformMedicineInteractiontoApp(i)
            );
          }
        )
      );
      this.formChanged = true;

      if (this.medicineId !== null) {
        this.interactionService.getMedicineInteraction(
          change.interactions,
          parseInt(this.medicineId)
        );
      }
    });
  }

  getMedicineDefaults() {
    this.medicineService.getMedicineEdit(0).then(data => {
      this.medicineService.currentMedicine
        .pipe(
            skipWhile(medicine => medicine instanceof MedicineEditEmpty),
            take(1)
        )
    })
  }

  // Admin route is a endpoint that you call when you need to get the options
  // list for "vía de administración" (of medication)
  getMedicineAdministrationOptions(): void {
    this.subs.push(
      this.medicineService.medicineAdminRouteList.subscribe((adminRoutes) => {
        this.medicineAdminRouteList = adminRoutes.map((adminRoute) =>
          this.medicineService.transformMedicineAdminRouteAPItoApp(adminRoute)
        );
        this.setMedicineAdminRoute();
      })
    );
    this.medicineService.getMedicineAdminRoute(
      false,
      false,
      0
    );
  }

  initForms(): void {
    this.tabs.forEach((tab) => {
      const formName = tab.form;

      const result = this.formService.buildForm(this.formsConfig[formName]);
      this.forms[formName] = {
        form: result[0],
        schema: result[1],
      };
    });
    this.getMedicineInfo();

    this.forms['general'].form.markAllAsTouched();
  }

  updateMedicineEdit(): void {
    // Check if classification is ok
    const classificationOk = Object.keys(this.forms['general'].form.value.classification)
    .some(key => this.forms['general'].form.value.classification[key] === true);

    if ( !classificationOk ) {
      // If classification is not ok, set custom style to the field
      this.forms['general'].schema[3].customClass = [...<[]>this.forms['general'].schema[3].customClass || [], 'error']
      this.calls.openSnack('Existen errores en el formulario', 'X', 5000);
    } else if ( this.forms['general'].form.valid ) {
      const medicine = this.getFormInfo();

      this.medicineService.updateMedicine(
        medicine,
        this.medicineId !== null ? +this.medicineId : 0,
        undefined
      ).then((medicineId) => {
        this.medicineId = medicineId.toString();
        this.formChanged = false;
      })
      .catch();

    } else {
      this.calls.openSnack('Existen errores en el formulario', 'X', 5000);
    }
  }

  getFormInfo(): MedicineForm {
    const object: { [key: string]: any } = {};
    this.tabs.forEach((tab) => {
      const formName = tab.form;
      object[formName] = this.forms[formName].form.value;
    });

    const { general, ...otherProperties } = object;
    const res: MedicineForm = { ...general, ...otherProperties };

    return res;
  }

  selectedIndex: number = 0;
  indexChange(idx: number) {
      this.selectedIndex = idx;
  }
}
