import { Component, OnInit, AfterViewInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatTable } from '@angular/material/table';
import { ifNeededScheduleOptions, monthDays, regularScheduleOptions, schedulesOptions, schedulesHalfHourOptions, weekDays } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { ActiveIngredient, IncompatibilityDataAPI, InvalidHalfDataAPI, Medicine, MedicineDosisData, MedicineDropdownApp, MedicineFlags, MedicineOption, MedicineValidations, OverdoseDataAPI, OverdoseType, Overdosis, OverlappedPrescription } from '@interfaces/medicine/medicine.interface';
import { IntakeTable, PatientGeneralForm } from '@interfaces/patient/patient.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { AuthService } from '@services/auth.service';
import { InstitutionService } from '@services/institution.service';
import { LoadingService } from '@services/loading.service';
import { MedicinesService } from '@services/medicines.service';
import { EpigraphService } from '@services/medicines/epigraph.service';
import { ModalService } from '@services/modal.service';
import { PatientsService } from '@services/patients.service';
import { RoleManagerService } from '@services/role-manager.service';
import { AlertComponent } from '@shared/dialogs/alert/alert.component';
import { InteractionsComponent } from '@shared/dialogs/interactions/interactions.component';
import { OverdoseComponent } from '@shared/dialogs/overdose/overdose.component';
import { FormFieldComponent } from '@shared/form-field/form-field.component';
import { intakeTableValidator } from '@validators/custom-validators.validators';
import { Subscription, firstValueFrom, forkJoin, lastValueFrom, of, pairwise, take } from 'rxjs';
import moment from "moment";
import { MatExpansionPanel } from '@angular/material/expansion';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FormInterface } from '@interfaces/patient/patient-edit.interface';

@Component({
    selector: 'app-prescription-edit',
    templateUrl: './prescription-edit.component.html',
    styleUrls: ['./prescription-edit.component.scss']
})
export class PrescriptionEditComponent implements OnInit, OnDestroy, AfterViewInit {

    Utils = Utils;

    @ViewChild("expansionPanel") expansionPanel: MatExpansionPanel;
    @ViewChild("medicina") medicina: FormFieldComponent;
    @ViewChild("activeIngredient") activeIngredient: FormFieldComponent;

    @Input() patientId: string | null;
    @Input() patientInstitutionId: number | undefined;
    @Input() isModification: boolean | undefined;
    @Input() prescriptionId: number | undefined;
    @Input() patientData: PatientGeneralForm;
    @Input() adminRouteOptions: SelectOption[] = [];
    @Input() stopDate: Date | null;
    @Input() forms: FormInterface;

    @Output() close = new EventEmitter<void>();

    @ViewChild('doseTable', { static: false }) doseTable: MatTable<any>;
    @ViewChild('editFormContainer', { static: false }) editFormContainer: ElementRef;

    public medicinesOptions: MedicineOption[] = [];
    public departmentOptions: SelectOption[] = [];

    public activeIngredients: ActiveIngredient[] = [];
    public activeIngredientsOptions: SelectOption[] = [];

    public patientDepartment: number | undefined;

    private headerCol: SelectOption[] = [{ label: 'Horario', value: 'schedule' }];
    public scheduleRows: SelectOption[] = [...regularScheduleOptions];
    private singleCol: SelectOption[] = [{ label: 'Cantidad', value: 'quantity' }];
    public scheduleCols: SelectOption[] = [...this.singleCol];

    public presetDosis: SelectOption[] = [
        { label: '0.5', value: '0.5' },
        { label: '1', value: '1' },
        { label: '1.5', value: '1.5' },
        { label: '2', value: '2' },
    ];
    public dosisOptions: SelectOption[] = [
    ];

    get cols(): SelectOption[] {
        return [...this.headerCol, ...this.scheduleCols];
    }
    get columnNames(): string[] {
        return this.cols.map(col => col.value);
    }

    public selectedCell = {
        column: '',
        row: ''
    }

    public months: SelectOption[] = [
        { label: 'ENE', value: 'gen' }, { label: 'FEB', value: 'feb' },
        { label: 'MAR', value: 'mar' }, { label: 'ABR', value: 'apr' },
        { label: 'MAY', value: 'may' }, { label: 'JUN', value: 'jun' },
        { label: 'JUL', value: 'jul' }, { label: 'AGO', value: 'aug' },
        { label: 'SEP', value: 'sep' }, { label: 'OCT', value: 'oct' },
        { label: 'NOV', value: 'nov' }, { label: 'DIC', value: 'dec' },
    ];

    public patterns: SelectOption[] = [
        { label: 'Diario', value: 'daily' },
        { label: 'Semanal', value: 'weekly' },
        { label: 'Mensual', value: 'monthly' },
        { label: 'Cíclico', value: 'cyclic' },
    ];

    public dailyPatterns: SelectOption[] = [
        { label: 'Repetir cada', value: 'repeat each' },
        { label: 'Días pares', value: 'even days' },
        { label: 'Días impares', value: 'odd days' },
    ];

    public monthlyRepeats: SelectOption[] = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '6', value: '6' },
        { label: '12', value: '12' },
    ];


    public today = new Date();
    public form: FormGroup = new FormGroup({});
    public editForm: FormGroup = new FormGroup({});
    // flags
    public isRa: boolean = false;
    public payMed: boolean = true;
    public isFGP: boolean = false;

    public posology: string[] = [];
    public defaultPosologyMsg = 'No existe posología definida para el medicamento.';

    public selectedDrugData: MedicineDosisData;
    public prescriptionLoaded: string;

    private sub: any = {};
    private subs: Subscription[] = [];

    public editFormContainerStyle: any = {};

    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum

    private medicineFlags: MedicineFlags;
    public medicineActiveIngredients: ActiveIngredient[] = [];
    public medicineDangerGroup: number | undefined;
    public expansionPanelOpen: boolean = false;

    noBlisterPreviousState: boolean = false;

    medicineOptionsRequest: Subscription;

    expansionInfo: 'posology' | 'active-ingredients' = 'posology';

    public loadingMedicinesByActiveIngredients: boolean = false;

    public clinicNotes: FormGroup;

    public allergic: boolean = false;

    get formBlocked(): boolean {
        return this.allergic || (!this.isModification && this.prescriptionId == undefined && !this.form.controls['medicineInput'].value);
    }

    constructor(
        private authService: AuthService,
        private patientsService: PatientsService,
        private loadingService: LoadingService,
        private institutionService: InstitutionService,
        private medicinesService: MedicinesService,
        private epigraphService: EpigraphService,
        private modalService: ModalService,
        private cdRef: ChangeDetectorRef,
        private roleManager: RoleManagerService,
        private calls: CallsService,
        private gaService: GoogleAnalyticsService,
    ) {
        // Hide options for business group 1 (Domus)
        if ( this.institutionService.getCurrentInstitutionBusinessGroup() === 1 ) {
            this.patterns = this.patterns.filter( pattern => pattern.value !== 'cyclic' );
            this.dailyPatterns = this.dailyPatterns.filter( pattern => pattern.value === 'repeat each' );
        }
    }

    async ngOnInit() {
        this.typeGlobal = this.roleManager.isPharma();

        this.initForm();
        this.initEditForm();
        this.initWatchers();

        this.setInitialValues();

        this.loadingService.start('Obteniendo datos...');

        // Get department options
        this.departmentOptions = (await this.institutionService.getInstitutionDepartments(this.institutionService.getCurrentInstitution()).toPromise()) || [];

        if (this.prescriptionId) {
            this.getPrescriptionData(this.prescriptionId);
        }

        this.initWatchersEditForm();

        if (this.prescriptionId != undefined && !this.isModification) {
            this.form.disable();
        }

        if (this.isModification) {
            this.form.controls['medicine'].disable();
            this.form.controls['medicineInput'].disable();
            this.form.controls['since'].disable();
        }

        if (this.prescriptionId == undefined)
            this.form.markAllAsTouched();


        this.patientDepartment = this.patientData.position.department;

        this.medicinesService.getMedicineAdminRoute(false, true, 0);
        this.subs.push(
            this.medicinesService.getMedicineAdminRouteAsOptions()
                .subscribe(list => this.adminRouteOptions = list)
        )

        this.medicinesService.getActiveIngredientsList().subscribe((res: ActiveIngredient[]) => {
            this.activeIngredients = res;
            this.activeIngredientsOptions = res.map((item: ActiveIngredient) => {
                let customStyle = {};
                if ( this.clinicNotes.controls['allergies'].get('main')?.value.includes(item.Id)) {
                    customStyle = {
                        'background-color': 'red',
                        'color': 'white'
                    }
                }

                return { 
                    label: item.Nombre, 
                    value: item.Id,
                    customStyle: customStyle
                }
            }).sort((a, b) => a.label.localeCompare(b.label));
        });

        if (this.forms) {
            this.clinicNotes = this.forms['clinicNotes'].form;
        }

        this.loadingService.stop();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        this.sub?.medicine.unsubscribe();
    }

    ngAfterViewInit() {
        this.subscribeToMedicineChange();
    }

    subscribeToMedicineChange() {
        this.sub.medicine?.unsubscribe();
        this.sub.medicine = this.medicina?.autocompleteControl?.valueChanges.subscribe((val) => {
            if ( val.trim().length >= 3 ) {
                // Cancel previous request
                if ( this.medicineOptionsRequest ) {
                    this.medicineOptionsRequest.unsubscribe();
                }

                // Get medicine options
                this.medicineOptionsRequest = this.medicinesService.getMedicinesOptions(
                    this.institutionService.getCurrentInstitution(),
                    val
                ).subscribe((allMedicineOptions: MedicineOption[]) => {
                    this.prepareMedicineOptiones(allMedicineOptions);
                });
            }
        })
    }

    getMedicinesOptionsByActiveIngredients(activeIngredients: number[]): void {
        this.loadingMedicinesByActiveIngredients = true;

        // Cancel previous request
        if ( this.medicineOptionsRequest ) {
            this.medicineOptionsRequest.unsubscribe();
        }

        // Get medicine options
        this.medicineOptionsRequest = this.medicinesService.getMedicinesOptionsByActiveIngredients(
            this.institutionService.getCurrentInstitution(),
            activeIngredients
        ).subscribe({
            next: (allMedicineOptions: MedicineOption[]) => {
                this.prepareMedicineOptiones(allMedicineOptions, true);
                this.loadingMedicinesByActiveIngredients = false;
            },
            error: (error) => {
                this.loadingMedicinesByActiveIngredients = false;
            },
        })
    }

    prepareMedicineOptiones(medicineOptionns: MedicineOption[], focusField: boolean = false) {
        this.medicinesOptions = medicineOptionns?.filter(item => !(item.value.Obsolete && item.value.did === item.value.CF1)) || [];
                
        // Add presentation to medicine label
        this.medicinesOptions.forEach(medicine => {
            medicine.value.Presentacion && (medicine.label += ` - ${medicine.value.Presentacion}`);
        })

        // Filter FGP medicines if not allowed
        if ( !this.institutionService.getCurrentInstitutionAllowFgp() ) {
            this.medicinesOptions = this.medicinesOptions.filter(item => !item.value.FGP);
        }

        // Filter all obsolete medicines if no CMS
        this.filterAllObsoleteMedicinesIfNoCMS();

        this.medicinesOptions.forEach(medicine => {
            if ( medicine.value.FGP ) {
                medicine.customStyle = {
                    'background-color': '#a5a5a5',
                    'color': 'white'
                }
            } 
            
            if ( medicine.value.PA.some(pa => this.clinicNotes.controls['allergies'].get('main')?.value.includes(pa.Id)) ) {
                medicine.customStyle = {
                    'background-color': 'red',
                    'color': 'white'
                }
            }
        })

        const duplicates = this.medicinesOptions
        .filter(medicine => this.medicinesOptions.filter(item => item.label === medicine.label).length > 1)

        duplicates.forEach((medicine, index) => {
            const currentSelection = duplicates.slice(0, index + 1);
            const equalAmmount = currentSelection
                .filter(item => item.label.trim() === medicine.label.trim() && item.value.id !== medicine.value.id)
                .length;

            for( let i = 0; i < equalAmmount; i++ ) {
                medicine.label += ' ';
            }
        });

        if ( focusField ) {
            this.cdRef.detectChanges();
            this.medicina?.focusField();
        }
    }

    filterAllObsoleteMedicinesIfNoCMS() {
        this.medicinesOptions = this.medicinesOptions.filter(item => !item.value.Obsolete || (item.value.Obsolete && !!item.value.CMS));
    }

    initForm(): void {
        // Medicación
        this.form.addControl('searchType', new FormControl(null, { validators: [] }));
        this.form.addControl('medicine', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('medicineInput', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('activeIngredientInput', new FormControl(null, { validators: [] }));
        this.form.addControl('noBlister', new FormControl(null, { validators: [] }));
        this.form.addControl('forcePayment', new FormControl(null, { validators: [] }));
        this.form.addControl('ifNeeded', new FormControl(null, { validators: [] }));
        this.form.addControl('severe', new FormControl(null, { validators: [] }));
        this.form.addControl('adminRoute', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('ra', new FormControl(null, { validators: [] }));
        this.form.addControl('raNumber', new FormControl(null, { validators: [] }));
        // Intervalo recurrencia
        this.form.addControl('since', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('forever', new FormControl(null, { validators: [] }));
        this.form.addControl('until', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('pe', new FormControl(null, { validators: [] }));
        this.form.addControl('peDate', new FormControl(null, { validators: [Validators.required] }));

        // Meses de actividad
        this.months.forEach(month => {
            this.form.addControl(month.value, new FormControl(null, { validators: [] }));
            // Disable fields for business group 1 (Domus)
            // if ( this.institutionService.getCurrentInstitutionBusinessGroup() === 1 ) {
            //     this.form.controls[month.value].disable();
            // }
        })


        // Patrón recurrencia
        this.form.addControl('pattern', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('dailyPattern', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('dailyRepeat', new FormControl(null, { validators: [Validators.required, Validators.min(1)] }));
        this.form.addControl('weeklyRepeat', new FormControl(null, { validators: [Validators.required, Validators.min(1)] }));
        this.form.addControl('monthlyRepeat', new FormControl(null, { validators: [Validators.required] }));
        this.form.addControl('cyclicActive', new FormControl(null, { validators: [Validators.required, Validators.min(1)] }));
        this.form.addControl('cyclicRest', new FormControl(null, { validators: [Validators.required, Validators.min(1)] }));

        // Tabla dosis
        this.form.addControl('advancedSchedule', new FormControl(null, { validators: [] }));
        this.form.addControl('table', new FormControl({}, { validators: [intakeTableValidator()] }));

        // Otros datos
        this.form.addControl('observations', new FormControl(null, { validators: [] }));
    }

    setInitialValues(): void {
        this.form.controls['searchType'].setValue(true, { emitEvent: false });

        this.form.controls['raNumber'].setValue('1');

        this.form.controls['since'].setValue(this.today);
        this.form.controls['forever'].setValue(true);

        this.form.controls['until'].disable();
        this.form.controls['peDate'].disable();

        this.form.controls['pattern'].setValue('daily');
        this.form.controls['dailyPattern'].setValue('repeat each');
        this.form.controls['dailyRepeat'].setValue('1');

        this.form.controls['weeklyRepeat'].setValue('1');

        this.form.controls['monthlyRepeat'].setValue('1');

        this.form.controls['cyclicActive'].setValue('1');
        this.form.controls['cyclicRest'].setValue('1');
    }

    resetCheckBoxes() {
        if (this.prescriptionId != undefined) return;
        this.form.controls['noBlister'].setValue(null);
        this.form.controls['forcePayment'].setValue(null);
        this.form.controls['ifNeeded'].setValue(null);
        this.form.controls['severe'].setValue(null);
        this.form.controls['ra'].setValue(null);
    }

    initWatchers(): void {
        this.subs.push(
            this.form.controls['searchType'].valueChanges.subscribe((val) => {
                this.form.controls['medicine'].setValue(null, { emitEvent: false });
                this.expansionPanel.close();
                this.form.controls['activeIngredientInput'].setValue(null, { emitEvent: false });
                this.resetCheckBoxes();

                if (val) {
                    this.gaService.event('medicación_tipo_búsqueda', 'click', 'Búsqueda de medicación por principio activo');
                    this.activeIngredientsOptions.forEach(item => {item.hidden = false})
                    this.medicinesOptions = [];
                    this.form.controls['activeIngredientInput'].setValidators([]);
                    this.form.controls['activeIngredientInput'].updateValueAndValidity({ emitEvent: false});
                    this.cdRef.detectChanges();
                    this.subscribeToMedicineChange();
                } else {
                    this.gaService.event('medicación_tipo_búsqueda', 'click', 'Búsqueda de medicación por nombre');
                    this.form.controls['activeIngredientInput'].setValidators([Validators.required]);
                    this.form.controls['activeIngredientInput'].updateValueAndValidity({ emitEvent: false});
                    this.cdRef.detectChanges();
                }
            }),
            this.form.controls['medicineInput'].valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
                if (prev?.id !== next?.id) {
                    this.onMedicineChange(next)
                }
            }),
            this.form.controls['activeIngredientInput'].valueChanges.subscribe((val) => {
                this.form.controls['medicine'].setValue(null);
                this.form.controls['medicineInput'].setValue(null);
                this.medicinesOptions = [];

                if ( val && val.length ) {
                    this.activeIngredient.closeSelect();

                    this.getMedicinesOptionsByActiveIngredients(val);

                    this.medicinesService.getCompatibleActiveIngredientsList(val).subscribe((res: ActiveIngredient[]) => {
                        this.activeIngredientsOptions.forEach(item => {item.hidden = true})
                        this.activeIngredientsOptions
                            .filter(item => !!res.find(resItem => resItem.Id === item.value))
                            .forEach(item => {item.hidden = false});
                    });
                } else if ( !val || !val.length ) {
                    this.activeIngredientsOptions.forEach(item => {item.hidden = false})
                }
            }),
            this.form.controls['ifNeeded'].valueChanges.subscribe((val) => this.onIfNeededChange(val)),
            this.form.controls['severe'].valueChanges.subscribe((val) => this.onSevereChange(val)),
            this.form.controls['since'].valueChanges.subscribe((val) => this.onSinceChange(val)),
            this.form.controls['forever'].valueChanges.subscribe((val) => this.onForeverChange(val)),
            this.form.controls['pe'].valueChanges.subscribe((val) => this.onPEChange(val)),
            this.form.controls['until'].valueChanges.subscribe((val) => this.onUntilChange(val)),
            this.form.controls['pattern'].valueChanges.subscribe((val) => this.onPatternChange(val)),
            this.form.controls['advancedSchedule'].valueChanges.subscribe((val) => this.onAdvancedScheduleChange(val)),
        )
    }

    initEditForm(): void {
        this.editForm.addControl('quantity', new FormControl(0, {
            validators: [
                Validators.required,
                Validators.min(0)
            ]
        }));
        // Sobreescribimos el setValue original para solo aceptar valores numericos, incluso desde controles externos
        const original = this.editForm.controls['quantity'].setValue;
        this.editForm.controls['quantity'].setValue = (val, opts) => {
            val = val;
            original.call(this.editForm.controls['quantity'], val, opts);
        };
        this.editForm.addControl('department', new FormControl(this.patientDepartment, { validators: [Validators.required] }));

        this.subs.push(
            this.editForm.controls['quantity'].valueChanges.subscribe((val) => {
                if ( Number(val) > 500 ) {
                    this.editForm.controls['quantity'].setValue("500");
                    this.calls.openSnack('La dosis máxima es de 500');
                }
            })
        )
    }

    setEditFormInitialValues(): void {
        this.editForm.controls['quantity'].setValue(0);
        this.editForm.controls['department'].setValue(this.patientDepartment);
    }

    loadEditForm(column: string, row: string): void {
        if (!column || !row || column === 'schedule') {
            this.editForm.controls['quantity'].setValue(0);
            this.editForm.controls['department'].setValue(this.patientDepartment);
        } else {
            const value = this.form.getRawValue().table?.[column]?.[row];
            if (value) {
                this.editForm.controls['quantity'].setValue(value.quantity);
                this.editForm.controls['department'].setValue(value.department ?? this.patientDepartment);
            }
        }
    }

    initWatchersEditForm(): void {
        this.subs.push(
            this.editForm.valueChanges.subscribe((val) => this.onEditFormChange(val)),
        );
    }

    onMedicineChange(medicine: MedicineDropdownApp): void {
        this.resetCheckBoxes();
        this.setEditFormInitialValues();
        this.buildTable();
        this.selectedCell.column = '';
        this.selectedCell.row = '';
        this.payMed = true;
        this.isFGP = false;

        if (!medicine) return;

        this.form.controls['medicine'].setValue(medicine);

        this.subs.push(
            this.medicinesService.getMedicineFields(Number(medicine.id)).subscribe(
                res => {
                    this.selectedDrugData = res;
                    this.selectedDrugData.Obsolete = medicine.Obsolete;

                    if ( !this.prescriptionId ) {
                        this.form.controls['adminRoute'].setValue(res.AdminRoute);
                    }
                    this.getDrugPosology();
                }
            )
        )

        const flags = medicine.flags;
        this.medicineFlags = medicine.flags;
        this.medicineActiveIngredients = medicine.PA;
        this.medicineDangerGroup = medicine.GrupoPeligro

        if ( this.medicineActiveIngredients && this.medicineActiveIngredients.length ) {
            if ( this.medicineActiveIngredients.some(pa => this.clinicNotes.controls['allergies'].get('main')?.value.includes(pa.Id)) ) {
                this.allergic = true;

                this.modalService.openModalErrorInfo(
                    'El paciente es alérgico a uno de los principios activos de este medicamento. No se permite su administración.', 
                    'Alergia'
                );
            } else {
                this.allergic = false;
            }
        }

        if (this.prescriptionId == undefined)
            this.form.controls['noBlister'].enable();
        if (flags.noBlister) {
            this.form.controls['noBlister'].setValue(true);
            this.form.controls['noBlister'].disable();
        }

        this.payMed = flags.payMed;

        if ( !this.payMed ) {
            this.form.controls['forcePayment'].setValue(true);
            this.form.controls['forcePayment'].disable();
        } else if ( this.prescriptionId == undefined && this.isModification) {
            this.form.controls['forcePayment'].setValue(false);
            this.form.controls['forcePayment'].enable();
        }

        this.isFGP = medicine.FGP;

        this.isRa = flags.ra;
        this.form.controls['ra'].setValue(false);
        this.form.controls['raNumber'].setValue('1');

        this.setDosisOptions();
    }

    onIfNeededChange(value: boolean): void {
        if (value) {
            this.gaService.event('si_precisa', 'click', 'Si precisa activado');

            this.noBlisterPreviousState = this.form.controls['noBlister'].value;
            this.form.controls['noBlister'].setValue(true);
            this.form.controls['noBlister'].disable();
            this.scheduleCols = [...this.singleCol];
            this.scheduleRows = [...ifNeededScheduleOptions];
            this.buildTable();
        } else {
            this.gaService.event('si_precisa', 'click', 'Si precisa desactivado');

            if ( !this.medicineFlags?.noBlister ) {
                this.form.controls['noBlister'].setValue(this.noBlisterPreviousState);
                this.form.controls['noBlister'].enable();
            }
            this.form.controls['advancedSchedule'].setValue(false);
            this.form.controls['pattern'].setValue('daily');
        }
    }

    onSevereChange(value: boolean): void {
        if (value) {
            this.gaService.event('agudo', 'click', 'Agudo activado');

            this.form.controls['noBlister'].setValue(true);
            this.form.controls['noBlister'].disable();
        } else if (this.prescriptionId == undefined && !this.medicineFlags?.noBlister) {
            this.gaService.event('agudo', 'click', 'Agudo desactivado');

            this.form.controls['noBlister'].setValue(false);
            this.form.controls['noBlister'].enable();
        } else if ( !this.medicineFlags?.noBlister ) {
            this.gaService.event('agudo', 'click', 'Agudo desactivado');

            this.form.controls['noBlister'].enable();
        }
    }

    onSinceChange(value: Date | null): void {
        if (value instanceof Date && this.prescriptionId == undefined) {
            this.form.controls['until'].setValue(null);
        }

        if ( this.checkSevereCanBeActive() ) {
            this.form.controls['severe'].enable();
        } else {
            this.form.controls['severe'].disable();
            this.form.controls['severe'].setValue(false);
        }

        this.checkAvailableActivityMonths();
    }

    onForeverChange(value: boolean): void {
        if (value) {
            this.gaService.event('intervalo_de_recurrencia', 'click', 'Hasta el');

            this.form.controls['until'].setValue(null);
            this.form.controls['until'].disable();
            this.form.controls['severe'].setValue(false);
            this.form.controls['severe'].disable();
        } else {
            this.gaService.event('intervalo_de_recurrencia', 'click', 'Por siempre');

            if (this.prescriptionId == undefined || this.isModification) {
                this.form.controls['until'].enable();
            }
        }

        this.checkAvailableActivityMonths();
    }

    onPEChange(value: boolean): void {
        if ( value ) {
            this.gaService.event('pe', 'click', 'PE activado');

            if (this.prescriptionId == undefined || this.isModification) {
                this.form.controls['peDate'].enable();
            }
        } else {
            this.gaService.event('pe', 'click', 'PE desactivado');

            this.form.controls['peDate'].setValue(null);
            this.form.controls['peDate'].disable();
        }
    }

    onUntilChange(value: Date | null): void {
        if (value) {
            if ( this.checkSevereCanBeActive() ) {
                this.form.controls['severe'].enable();
            } else {
                this.form.controls['severe'].disable();
                this.form.controls['severe'].setValue(false);
            }

            this.checkAvailableActivityMonths();
        } else {
            this.form.controls['severe'].disable();
        }
    }

    onPatternChange(value: string): void {
        if (this.selectedCell.column !== '') {
            this.onClickCell(this.selectedCell.column, this.selectedCell.row);
        }

        if (this.form.controls['ifNeeded'].value) return;
        switch (value) {
            case 'weekly':
                this.gaService.event('patrón_de_recurrencia', 'click', 'Semanal');
                this.scheduleCols = [...weekDays];
                break;
            case 'monthly':
                this.gaService.event('patrón_de_recurrencia', 'click', 'Mensual');
                this.scheduleCols = [...monthDays];
                break;
            case 'daily':
            case 'cyclic':
            default:
                this.gaService.event('patrón_de_recurrencia', 'click', 'Diario');
                this.scheduleCols = [...this.singleCol];
                break;
        }
        this.buildTable();
    }

    onAdvancedScheduleChange(value: string): void {
        if (this.form.controls['ifNeeded'].value) return;
        this.scheduleRows = value ? [...schedulesHalfHourOptions] : [...regularScheduleOptions];
        this.buildTable(!!value);

        this.selectedCell.column = '';
        this.selectedCell.row = '';
    }

    onEditFormChange(value: { quantity: string, department: number }): void {
        this.form.controls['table'].markAsTouched();

        if (!this.selectedCell.column || !this.selectedCell.row) return;

        const controlValue = { ...this.form.controls['table'].value };

        if (this.form.controls['medicine'].value?.flags.noPartial) {
            let quantity = Number(value.quantity);
            if (quantity % 1 !== 0) {
                value.quantity = Math.floor(quantity) + '';
                this.editForm.controls['quantity'].setValue(value.quantity);
            }
        }
        controlValue[this.selectedCell.column][this.selectedCell.row] = { ...value }
        this.form.controls['table'].setValue(controlValue);
    }

    onClickCell(column: string, row: string): void {
        const sameCell = this.selectedCell.column === column && this.selectedCell.row === row || column === 'schedule';
        this.selectedCell.column = sameCell ? '' : column;
        this.selectedCell.row = sameCell ? '' : row;

        this.scheduleRows = this.scheduleRows.filter(r => r.value !== 'edit');

        if (!sameCell) {
            const index = this.scheduleRows.findIndex(r => r.value === row);
            this.scheduleRows.splice(index + 1, 0, {
                value: 'edit',
                label: ''
            });
            this.doseTable.renderRows();
        }


        this.loadEditForm(column, row);
    }

    onToggleChange(event: MatButtonToggleChange): void {
        if (event.value !== this.editForm.value.quantity) {
            this.editForm.controls['quantity'].setValue(Number(event.value));
        }

    }
    onToggleDoseFastButton(event: MatButtonToggleChange): void {
        event.source.checked = false;
        const actualValue = this.editForm.controls['quantity'].value;

        const modifyQuantity = this.form.controls['medicine'].value?.flags.noPartial ? 1 : 0.25;

        switch (event.value) {
            case 1:
                actualValue > modifyQuantity
                    ? this.editForm.controls['quantity'].setValue(Number(actualValue) - modifyQuantity)
                    : null;
                break;
            case 2:
                this.editForm.controls['quantity'].setValue(Number(actualValue) + modifyQuantity);
                break;
        }
    }

    partiable(): boolean {
        return !this.form?.controls['medicine']?.value?.flags?.noPartial;
    }

    getWeeklyLabel(value: string | null): string {
        if (!value) return 'Repetir cada n semanas';
        if (value === '1') return 'Repetir cada semana';
        return `Repetir cada ${value} semanas`;
    }

    getMonthlyLabel(value: string | null): string {
        if (!value) return 'Repetir cada n meses';
        if (value === '1') return 'Repetir cada mes';
        return `Repetir cada ${value} meses`;
    }

    getPrescriptionData(prescriptionId: number): void {
        this.loadingService.start('Cargando Prescripción')
        this.subs.push(
            this.patientsService.getPrescription(prescriptionId).subscribe({
                next: (res) => {
                    this.prescriptionLoaded = res;

                    // habría que darle una vuelta a esto
                    const parsedData = this.patientsService.parsePrescritionToApp(res);
                    this.form.controls['ifNeeded'].setValue(parsedData.ifNeeded);
                    delete parsedData.ifNeeded;
                    this.form.controls['pattern'].setValue(parsedData.pattern);
                    delete parsedData.pattern;
                    this.form.controls['advancedSchedule'].setValue(parsedData.advancedSchedule);
                    delete parsedData.advancedSchedule;
                    this.form.controls['forcePayment'].setValue(parsedData.forcePayment);

                    parsedData.table = this.patientsService.fillTable(res.result2, this.form.getRawValue().table, this.scheduleCols, this.scheduleRows);

                    this.medicina.setValue(parsedData.medicine);

                    parsedData.medicine = parsedData.medicine.value;
                    this.form.patchValue(parsedData, { emitEvent: false });
                    
                    this.isFGP = parsedData.medicine.FGP;
                    this.payMed = parsedData.medicine.flags.payMed;
                    this.medicineFlags = parsedData.medicine.flags;
                    this.isRa = parsedData.medicine.flags.ra;

                    this.medicineActiveIngredients = parsedData.medicine.PA;

                    if (this.isModification && !!this.stopDate) {
                        this.form.controls['since'].setValue(this.stopDate, { emitEvent: false });
                    }

                    if ( this.form.controls['severe'].value ) {
                        this.form.controls['severe'].enable();
                    }

                    this.medicinesService.getMedicineFields(Number(parsedData.medicine.id))
                    .pipe(take(1))
                    .subscribe(
                        res => {
                            this.selectedDrugData = res;
                            this.selectedDrugData.Obsolete = parsedData.medicine.Obsolete;
        
                            if ( !this.prescriptionId ) {
                                this.form.controls['adminRoute'].setValue(res.AdminRoute);
                            }
                            this.getDrugPosology();
                        }
                    )
                },
                complete: () => {
                    this.loadingService.stop();
                }
            })
        );
    }

    setDosisOptions(): void {
        const medflags = this.form.getRawValue().medicine.flags;
        if (medflags.noHalf || medflags.noPartial) {
            this.dosisOptions = this.presetDosis.filter(d => {
                return Number(d.value) % 1 == 0;
            })
        } else {
            this.dosisOptions = [...this.presetDosis];
        }
    }

    getDrugPosology(): void {
        this.posology = [];
        this.subs.push(
            this.epigraphService.obtainEpigraphText(
                this.form.getRawValue().medicine.id,
                4
            )
                .subscribe((res) => {
                    this.posology = res.payload.map((text: { TEXTO: string }) => {
                        return this.epigraphService.parseTextEpigraph(text.TEXTO)
                    })
                })
        );
    }

    buildTable(translateIntakeHours: boolean = false): void {
        let table: any = {};
        this.scheduleCols.forEach(col => {
            table[col.value] = {};
            this.scheduleRows.forEach(row => {
                table[col.value][row.value] = {
                    quantity: this.prescriptionId != undefined ? this.form.controls['table'].getRawValue()?.[col.value]?.[row.value]?.quantity ?? '0' : '0',
                    department: this.patientData?.position.department,
                }
            })
        })

        if ( translateIntakeHours ) {
            // @ts-ignore
            this.prescriptionLoaded?.result2.forEach((item: any) => {
                console.log(table.quantity[item.TimeOfDay])
                table.quantity[item.TimeOfDay].quantity = item.Qty;
            });
        } else {
            // @ts-ignore
            this.prescriptionLoaded?.result2.forEach((item: any) => {
                if ( table.quantity ) {
                    switch ( item.TimeName ) {
                        case 'Desayuno': table.quantity.breakfast ? table.quantity.breakfast.quantity = item.Qty : null; break;
                        case 'Comida': table.quantity.lunch ? table.quantity.lunch.quantity = item.Qty : null; break;
                        case 'Cena': table.quantity.dinner ? table.quantity.dinner.quantity = item.Qty : null; break;
                        case 'Merienda': table.quantity.snack ? table.quantity.snack.quantity = item.Qty : null; break;
                        case 'Dormir': table.quantity.sleep ? table.quantity.sleep.quantity = item.Qty : null; break;
                    }
                }
            });
        }

        this.form.controls['table'].setValue(table);
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    async savePrescription(): Promise<void> {
        if ( this.isFGP && !(await this.openFgpModal()) ) {
            return ;    // BREAK EXECUTION because the user didn't accept the FGP
        }

        let close = false;
        this.loadingService.start('Validando prescripción');

        this.editForm.controls['quantity'].setValue( this.editForm.controls['quantity'].value.toString().replace(',', '.') );

        // 1.- Comprobar / avisar medias dosis
        const medFlags = this.form.getRawValue().medicine.flags;
        const halfDosis = (medFlags.noHalf || medFlags.noPartial) ? this.checkHalfDosis() : false;
        if (halfDosis) {
            this.openAlertModal();
            this.loadingService.stop();
            return; // Salimos del flujo
        }

        // 2.- Comprobar / avisar sobredosis
        const overdosis = await this.checkOverDosis();
        let overdosisType: OverdoseType | undefined;
        let acceptOverdosis = false;

        if (overdosis.intake && !overdosis.day) overdosisType = 'intake'; // overdosis type 3
        else if (overdosis.day && !overdosis.intake) overdosisType = 'day' // overdosis type 4
        else if (overdosis.intake && overdosis.day) overdosisType = 'both'; // overdosis type 3 y 4 (dos llamadas)

        acceptOverdosis = !!overdosisType ? !!await this.openOverdoseModal(overdosisType) : true;

        if (overdosisType) {
            await this.handleOverdosis(acceptOverdosis, overdosis);
        }

        if (!acceptOverdosis) {
            this.loadingService.stop();
            return; // Salimos del flujo después de loguear
        }

        // 3.- Buscamos interacciones
        const interactions: MedicineValidations = await this.getMedicineInteractions();

        let forcePrescription = false;
        if (interactions.OverlappedPrescriptions.length) {
            const userClick = await this.openInteractionModal(interactions.OverlappedPrescriptions);
            switch (userClick) {
                case 'yes_incompatibility':
                    await this.logMedicineIncompatibility(interactions.OverlappedPrescriptions, true);
                    forcePrescription = true;
                    break;
                case 'no_incompatibility':
                    await this.logMedicineIncompatibility(interactions.OverlappedPrescriptions, false);
                    break;
                case 'yes_sameMedicine':
                    break;
                default:
                    await this.logMedicineIncompatibility(interactions.OverlappedPrescriptions, false);
                    close = true;
                    break;
            }
        }
        if (!interactions.OverlappedPrescriptions.length || forcePrescription) {
            this.loadingService.start('Guardando prescripción');
            await this.insertPrescription();
            close = true;
        }


        this.loadingService.stop();
        if (close) {
            this.close.emit();
        }

    }

    async handleOverdosis(accept: boolean, overdosis: Overdosis): Promise<void> {
        return new Promise((resolve, reject) => {

            const data: OverdoseDataAPI = {
                insidSrc: this.institutionService.getCurrentInstitution(),
                uid: this.authService.getLoggedUser() + '',
                insid: this.patientInstitutionId as number,
                pid: Number(this.patientId),
                mid: this.form.controls['medicine'].value.id,
                force: accept,
                qtyAdministered: 0,
                qtyLimit: 0,
                overdosedType: 0,
            };
            const calls: any = {};
            if (overdosis.intake) {
                calls['intake'] = this.patientsService.logMedicineOverdose({
                    ...data,
                    qtyAdministered: Number(overdosis.maxIntake),
                    qtyLimit: this.getIntakeLimit(),
                    overdosedType: 3
                });
            }
            if (overdosis.day) {
                calls['day'] = this.patientsService.logMedicineOverdose({
                    ...data,
                    qtyAdministered: overdosis.maxDay,
                    qtyLimit: this.getDayLimit(),
                    overdosedType: 4
                });
            }

            forkJoin(calls).subscribe({
                next: (res) => {
                    console.log('forkjoin', res);
                    resolve();
                },
                error: () => {
                    resolve();
                }
            });

            resolve();

        })
    }

    async logMedicineIncompatibility(interactions: OverlappedPrescription[], force: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            const data: IncompatibilityDataAPI = {
                insidSrc: this.institutionService.getCurrentInstitution(),
                uid: this.authService.getLoggedUser() + '',
                insid: this.patientInstitutionId as number,
                pid: Number(this.patientId),
                mid: this.form.controls['medicine'].value.id,
                data: this.medicinesService.transformInteractionToXML(interactions),
                couldOverride: false,
                force,
            };

            this.patientsService.logMedicineIncompatibility(data)
                .subscribe({
                    next: (res) => resolve(res),
                })
        })
    }

    getDayLimit(): number {
        return Number(this.selectedDrugData.LimitPerDay) || 3; //NOTE: Why not? Así está en Corota
    }
    getIntakeLimit(): number {
        return Number(this.selectedDrugData.LimitPerIntake) || 2; //NOTE: Why not? Así está en Corota
    }

    checkHalfDosis(): boolean {
        const table: IntakeTable = this.form.controls['table'].value;
        return Object.values(table).some(day => {
            return Object.values(day).some(time => {
                const amount = Number(time.quantity)
                return amount % 0.5 === 0 && amount % 1 !== 0;
            })
        })
    }

    async getMedicineInteractions(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.medicinesService.getMedicineInteractions(
                this.form.getRawValue(),
                Number(this.patientId),
                this.prescriptionId
            ).subscribe(res => {
                resolve(res.payload);
            })
        });
    }

    async insertPrescription(): Promise<any> {
        let canInsert: boolean = true;

        if ( this.isModification && !!this.stopDate ) {
            try {
                const stoppedPrescriptionResult = await this.patientsService.stopPrescription(this.prescriptionId as number, this.stopDate as Date, true).toPromise();

                if ( stoppedPrescriptionResult ) {
                    this.prescriptionId = stoppedPrescriptionResult[0].NewPrescriptionId;
                }
            } catch (error) {
                canInsert = false;
                this.calls.openSnack('Ha ocurrido un error al detener la prescripción.');
            }
        }

        if ( canInsert ) {
            return new Promise((resolve, reject) => {
                this.patientsService.insertMedicinePrescription(
                    this.form.getRawValue(),
                    Number(this.patientId),
                    this.prescriptionId
                ).subscribe(res => {
                    this.gaService.event('creada_nueva_prescripción', 'event', 'Creada nueva prescripción');
                    resolve(res.payload);
                })
            });
        } else {
            return of(false);
        }
    }

    async checkOverDosis(): Promise<Overdosis> {
        return new Promise((resolve) => {

            const dayLimit = this.getDayLimit();
            const intakeLimit = this.getIntakeLimit();

            let prescriptedMaxIntake = 0;
            let prescriptedMaxDayIntake = 0;

            const table: IntakeTable = this.form.getRawValue().table;
            Object.values(table).forEach(day => {
                let dayIntake = 0;
                Object.values(day).forEach(time => {
                    const quantity = Number(time.quantity);
                    dayIntake += quantity;
                    prescriptedMaxIntake = prescriptedMaxIntake >= quantity ? prescriptedMaxIntake : quantity;
                })
                prescriptedMaxDayIntake = prescriptedMaxDayIntake >= dayIntake ? prescriptedMaxDayIntake : dayIntake;
            })

            resolve({
                intake: prescriptedMaxIntake > intakeLimit,
                day: prescriptedMaxDayIntake > dayLimit,
                maxIntake: prescriptedMaxIntake,
                maxDay: prescriptedMaxDayIntake,
            });
        });
    }

    async openOverdoseModal(type: OverdoseType): Promise<any> {
        return await lastValueFrom(
            this.modalService.openModalSubs(OverdoseComponent, {
                type,
                maxDay: this.getDayLimit(),
                maxIntake: this.getIntakeLimit()
            })
        );
    }

    async openInteractionModal(overlaps: OverlappedPrescription[]): Promise<any> {
        return await lastValueFrom(
            this.modalService.openModalSubs(InteractionsComponent, {
                overlaps,
            })
        );
    }

    openAlertModal(): void {
        this.modalService.openModalSubs(AlertComponent, {
            title: 'Dosis no válida',
            message: 'Se recomienda no fraccionar este medicamento. Existe otro medicamento con dosis exacta a la que busca.'
        }).subscribe(() => {
            const data: InvalidHalfDataAPI = {
                insidSrc: this.institutionService.getCurrentInstitution(),
                uid: this.authService.getLoggedUser() + '',
                insid: this.patientInstitutionId as number,
                pid: Number(this.patientId),
                mid: this.form.controls['medicine'].value.id,
            };

            this.patientsService.logMedicineInvalidHalf(data).subscribe()
        })
    }

    async openFgpModal(): Promise<boolean> {
        return await lastValueFrom(
            this.modalService.openModalSubs(AlertComponent, {
                title: 'Medicamento fuera de guía preferente',
                message: 'El medicamento no es preferente en la Guía de su Comunidad. Recomendamos la selección de otro fármaco equivalente.',
                confirmButtonText: 'Continuar',
                dismissButtonText: 'Cancelar'
            })
        );
    }

    checkSevereCanBeActive(): boolean {
        if ( this.form.controls['since'].value && this.form.controls['until'].value ) {
            var startDate = moment(this.form.controls['since'].value);
            var endDate = moment(this.form.controls['until'].value);

            if ( startDate.year() === endDate.year() && startDate.month() === endDate.month() ) {
                return true;
            } else if ( startDate.year() === endDate.year() && startDate.month() === endDate.month() - 1 && startDate.date() > endDate.date() ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    checkAvailableActivityMonths(): void {
        if ( this.form.controls['since'].value && this.form.controls['until'].value ) {
            let triggerAlert = false;

            // Get months between dates
            var startDate = moment(this.form.controls['since'].value);
            var endDate = moment(this.form.controls['until'].value);
            var betweenMonths: string[] = [];

            if (startDate < endDate){
                var date = startDate.startOf('month');

                while (date < endDate.endOf('month')) {
                    betweenMonths.push(date.locale("en").format('MMM'));
                    date.add(1,'month');
                }
            }

            betweenMonths = betweenMonths.map((month: string) => month.toLowerCase());

            this.months.forEach(month => {
                const m = month.value === 'gen' ? 'jan' : month.value;

                if ( betweenMonths.includes(m) ) {
                    this.form.controls[month.value].enable();
                } else {
                    if ( this.form.controls[month.value].value ) {
                        triggerAlert = true;
                    }
                    this.form.controls[month.value].setValue(false);
                    this.form.controls[month.value].disable();
                }
            })

            if ( triggerAlert ) {
                this.modalService.openModalSubs(AlertComponent, {
                    title: 'Prescripción inactiva',
                    message: 'El patrón de meses de actividad introducido no es compatible con la fecha de inicio de la prescripción, por lo que esta nunca estará activa. Por favor, revise nuevamente el patrón de meses de actividad y la fecha de inicio de la prescripción.',
                })
            }
        } else {
            this.months.forEach(month => {
                this.form.controls[month.value].enable();
            });
        }
    }

    showPosology() {
        if ( this.expansionPanelOpen && this.expansionInfo !== 'posology' ) {
            setTimeout(() => {
                this.expansionInfo = 'posology';
                this.expansionPanel.open();
            }, 200);
        } else {
            this.expansionInfo = 'posology';
        }
    }
    showActiveIngredients() {
        if ( this.expansionPanelOpen && this.expansionInfo !== 'active-ingredients' ) {
            setTimeout(() => {
                this.expansionInfo = 'active-ingredients';
                this.expansionPanel.open();
            }, 200);
        } else {
            this.expansionInfo = 'active-ingredients';
        }
    }

    deselectAllActiveIngredient() {
        this.form.controls['activeIngredientInput'].setValue(null);
    }
}
