import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilderInterface, FormSchemaObjectInterface, FormTableHeaderInterface, FormTableRowInterface } from '@interfaces/dynamic-form.interface';
import { FormConfigTableInterface, MatTableColumnInterface, MatTableRowInterface } from '@interfaces/table.form.interface';
import { FormService } from '@services/form.service';
import { FormFieldComponent } from '@shared/form-field/form-field.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-table-form',
    templateUrl: './table-form.component.html',
    styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent implements OnInit, OnDestroy {

    @Input() formConfig: any | FormConfigTableInterface;
    @Input() fieldControl: FormGroup;
    @Input() readOnly?: () => boolean = () => false;

    public columns: MatTableColumnInterface[] = [];
    public columnNames: string[] = [];
    public rows: MatTableRowInterface[] = [];
    private fomBuilderSchema: FormBuilderInterface = {};


    private subs: Subscription[] = [];
    constructor(
        private formsService: FormService
    ) { }

    public static flag = false;
    ngOnInit(): void {
        this.fomBuilderSchema = this.createFormBuilderSchema(this.formConfig);
        this.rows = this.parseRows(this.formConfig?.rows ?? [], this.fomBuilderSchema);
        this.columns = this.parseHeaders(this.formConfig?.headers ?? []);
        this.columnNames = this.columns.map(col => col.columnDef);
        this.updateFormGroup();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    parseHeaders(headers: FormTableHeaderInterface[]): MatTableColumnInterface[] {
        return headers.map((header) => {
            return {
                columnDef: header.fieldName,
                header: header.label,
                isInput: !!header.inputSchema,
                cell: (element: FormTableRowInterface) => `${element[header.fieldName]}`
            }
        });
    }

    parseRows(rows: FormTableRowInterface[], schemas: FormBuilderInterface): MatTableRowInterface[] {
        const t = rows.map((row) => {
            Object.keys(row).forEach(rowField => {
                if (rowField in schemas) {
                    row['schema'] = this.formsService.buildForm(schemas, true)[2];
                }
            })
            return row;
        });
        return t;
    }

    createFormBuilderSchema(formConfig: FormConfigTableInterface): FormBuilderInterface {
        const schemas: FormBuilderInterface = {};
        const headers = formConfig.headers ?? [];

        headers.forEach(header => {
            if (!!header.inputSchema) {
                schemas[header.fieldName] = header.inputSchema;
            }
        });

        return schemas;
    }

    updateFormGroup(): void {
        this.rows.forEach(row => {
            this.fieldControl.addControl(row.formName, new FormGroup({}));
            Object.keys(row.schema ?? {}).map(key => {
                if (row.schema) {
                    const control = this.fieldControl.controls[row.formName] as FormGroup;
                    control.addControl(key, row.schema[key].fieldControl);
                }
            });
        });
    }

    public realFormField: Array<{
        row: any,
        control: FormFieldComponent
    }> = [];
    setInvalid(value: 'invalid' | 'valid' | 'uninitialized', control: FormFieldComponent, row: any) {
        let real = this.realFormField.find(r => r.row == row);
        if (real == undefined) {
            real = {
                row: row,
                control: control
            };
            this.realFormField.push(real);
        }

        (<any>real.control).isInvalid = value;
    }

    isInvalid(row: any) {
        const real = this.realFormField.find(r => r.row == row);
        return real ? (<any>real.control).isInvalid : 'uninitialized';
    }
}
