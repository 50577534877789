export const reportDataSCP = {
  data: {
    rpp: '/COROTA/PharmaAlertList',
    pts: 1,
    fr: true,
    dn: '1687262692043_{0}.html',
    rp: '{"InstitutionId":"218","DateFrom":null,"DateUntil":null,"LocaleId":3082,"PatientId":null,"DepartmentId":null,"AlertIds":"","UserId":null}',
  },

  dataDownloadPDF: {
    rpp: '/COROTA/PharmaAlertList',
    pts: 0,
    fmt: 2,
    dn: 'Reporte_SCP_lista_{date}_{time}.pdf',
    rp: '{"InstitutionId":"218","DateFrom":null,"DateUntil":null,"LocaleId":3082,"PatientId":null,"DepartmentId":null,"AlertIds":"","UserId":null}',
  },
  dataDownloadExcel: {
    rpp: '/COROTA/PharmaAlertList',
    pts: 0,
    fmt: 3,
    dn: 'Reporte_SCP_lista_{date}_{time}.xls',
    rp: '{"InstitutionId":"218","DateFrom":null,"DateUntil":null,"LocaleId":3082,"PatientId":null,"DepartmentId":null,"AlertIds":"","UserId":null}',
  },
  dataRenderReport: {
    rpp: '/COROTA/PharmaAlertList',
    pts: 0,
    z: 100,
    htmf: false,
    fmt: 2,
    dl: false,
    dn: 'Reporte_SCP_lista_{date}_{time}.pdf',
    rp: '{"InstitutionId":"218","DateFrom":null,"DateUntil":null,"LocaleId":3082,"PatientId":null,"DepartmentId":null,"AlertIds":"","UserId":null}',
  },
};
