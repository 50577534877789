<div class="modal-dialog modal-md">
    <div class="header">
        <h3>Fin de sesión</h3>
    </div>
    <div class="body">
        <div class="col-sm-10 offset-sm-1">
            <p>
                La sesión está a punto de expirar.
            </p>
            <p>
                Oprima Continuar para seguir en ella o Salir para terminar.
            </p>
        </div>
        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <div class="progress" max="60000" type="warning">
                    <div class="progress-bar progress-bar-warning"
                        role="progressbar"  [ngStyle]="{width: (percent < 100 ? percent : 100) + '%'}"
                        style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer" mat-dialog-actions>
        <button mat-raised-button class="warning" (click)="continuar()">Continuar</button>
        <button mat-raised-button (click)="cerrar()">Salir</button>
    </div>
</div>
