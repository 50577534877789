import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { CustomFieldDataInterface } from '@interfaces/dynamic-form.interface';
import { SelectOption } from '@interfaces/input-select-option.interface';
import { Utils } from '@json/src/app/Utils';
import { sameErrorValidator } from '@validators/custom-validators.validators';
import moment, { Moment, isMoment } from 'moment';
import { Subscription, debounceTime } from 'rxjs';

import {MatFormField} from '@angular/material/form-field';
import { Permission } from '@json/src/app/enums/PermissionType';
import { FilterOption, FilterSelect } from '@interfaces/filter.interface';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CallsService } from '@services/api/calls.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, OnDestroy {
    @Input() label: string = 'Label';
    @Input() hideLabel?: boolean;
    @Input() errorMsg: string = '';
    @Input() hintMsg?: string = '';
    @Input() placeholder?: string = '';
    @Input() type: string = 'text';
    _opts?: SelectOption[] = [];
    _filteredOpts?: SelectOption[] = [];
    @Input() set options(opts: SelectOption[] | undefined) {
        this._opts = opts;
        this._filteredOpts = opts;
    };
    get options() {
        if (this.fieldControl.parent?.get('name1') != undefined) {
            console.log(this._opts)
        }
        return this._opts;
    }
    get filteredSelectOptions(): SelectOption[] | undefined {
        return this._filteredOpts;
    }
    optionsTest = [{value: 0, label:'0'},{value: 1, label:'1'},{value: 2, label:'2'}];
    @Input() optionsGroup: FilterSelect[];
    @Input() dynamicOptions?: {
        context: any,
        func: (value: string) => SelectOption[] | Promise<SelectOption[]>,
        debounce?: number,
    }
    @Input()searchInFields?: any;
    _fieldControl: FormControl;
    @Input() set fieldControl(val: FormControl) {
        // Es un control que proviene de table-form.component, estan bugueados y se asigna a todos al ultimo añadido, con esto lo solucionamos
        // if ((<any>val).tableField) {
        //     if (this._fieldControl != undefined) {
        //         return;
        //     }
        // }
        if (val == undefined) return;
        // console.log(this.element.nativeElement, this._fieldControl, val)
        this._fieldControl = val;
    }
    get fieldControl(): FormControl {
        return this._fieldControl;
    }
    @Input() customFieldData?: CustomFieldDataInterface;
    @Input() dateFieldData?: CustomFieldDataInterface;
    @Input() dynamicError?: boolean = false;
    @Input() readOnly?: () => boolean = () => false;
    @Input() min?: number;
    @Input() max?: number;
    @Input() minDate?: Date | Moment;
    @Input() maxDate?: Date | Moment;
    @Input() inputPattern?: string = undefined;
    @Input() exactPattern?: string = undefined;
    @Input() capitalize?: boolean = false;
    @Input() uppercase?: boolean = false;
    @Input() group?: string = undefined;
    @Input() row: any;
    @Input() customStyle?: {};
    @Input() customStyleBox: boolean = false;
    @Input() tooltip?: string;
    @Input() comparer: (option1: FilterOption, option2: FilterOption) => boolean = () => false;
    @Input() hideRequiredAsterisk: boolean = false;
    @Input() multipleSelect: boolean | undefined = false;
    @Input() allowSelectAll: boolean | undefined = true;
    @Input() deselectAllShortcut: boolean = true;
    @Input() maxOptionSelect: number | undefined = undefined;
    @Input() numberFieldFastButtons: boolean | undefined = false;
    @Input() numberFieldFastButtonsStep: number = 1;
    @Input() hideCloseButton: boolean = false;
    @Input() filterSelect: boolean = false;
    @Input() hideSingleSelectionIndicator: boolean = false;
    @Input() selectWithChips: boolean = false;
    @Input() selectCleanFilterInput: boolean = false;
    @Input() selectCleanFilterInputAndFocus: boolean = false;

    @Output() invalid = new EventEmitter<'invalid' | 'valid' | 'uninitialized'>();
    @Output() change = new EventEmitter();

    @ViewChild('select') select: MatSelect;
    @ViewChild('selectFilter') selectFilter: ElementRef;
    @ViewChild('matFormField', {static: false}) matFormField: MatFormField;

    filterSelectFormControl: FormControl = new FormControl();

    isOpen = false;

    selectorPosition: number = 0;

    public calendarValue: Date;
    public disablePreviousDate: Date | undefined | FormControl;

    public autocompleteControl: FormControl;
    get filteredOptions(): {label: string, otherData?: string, customStyle: {} | undefined}[] {
        if (this.type !== 'autocomplete') return [];
        const input = this.autocompleteControl.value || '';
        if (!this.options) return [];
        if (!input) return this.options.map((o) => {return {label: o.label, customStyle: o.customStyle}});
        let opts = this.options
            .filter((option) =>
                this.customFieldData?.['compareStart'] ?
                option.label.toLowerCase().startsWith(input.toLowerCase())
                :
                option.label.toLowerCase().includes(input.toLowerCase())
            );

        // En caso de no tener resultados y que el modo de busqueda sea comparando inicio, comenzar a buscar usando include
        if (opts.length == 0 && this.customFieldData?.['compareStart']) {
            opts = this.options
                .filter((option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                );
        }

        return opts.map((o) => {return {label: o.label, customStyle: o.customStyle, otherData: o.otherData}});
    }

    public regularInputs = [
        'color',
        'datetime-local',
        'email',
        'month',
        'number',
        'password',
        'search',
        'tel',
        'text',
        'time',
        'url',
        'week',
    ];
    public dateInputs = ['date'];
    public nonFormFields = ['radio', 'checkbox', 'custom', 'calendar', 'info'];

    private subs: Subscription[] = [];

    private dateWithDetail = false;

    public allSelected: boolean = false;

    constructor(
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
        private call: CallsService,
    ) { }

    normalValue?: string = undefined;
    // isNormal? = true;
    ngAfterViewInit() {
        if (this.row?.normalValue) {
            this.normalValue = this.row.normalValue as string;
            this.normalValue = this.normalValue.split('.').join('');
            this.normalValue = this.normalValue.split(',').join('.');
            this.subs.push(this.fieldControl.valueChanges.subscribe((value) => {
                if (isNaN(Number(value))) {
                    return;
                }
                const isNormal = this.isInNormalValue(value);
                this.invalid.emit(isNormal == undefined ? 'uninitialized' : (isNormal ? 'valid' : 'invalid'));
            }));
        }

        this.subs.push(
            this.filterSelectFormControl.valueChanges.subscribe((value) => {
                this.filterSelectOptions(value);
            })
        );

        // this.matFormField?.getConnectedOverlayOrigin().nativeElement.layout();
        // console.log(this.matFormField);
    }

    ngOnInit(): void {
        this.subs.push(
            this.fieldControl.valueChanges.subscribe((value) => {
                this.change.emit(value);
            })
        );

        if (this.group) {
            (this.fieldControl as any).group = this.group;
        }

        if (this.type === 'date') {
            this.configureDateField();
        }

        if (this.type === 'calendar') {
            this.subs.push(
                this.fieldControl.valueChanges.subscribe((value) => {
                    this.calendarValue = value;
                })
            );
        }

        if (this.type === 'autocomplete') {
            this.autocompleteControl = new FormControl('', [
                // Validators.required,
                sameErrorValidator(this.fieldControl),
            ]);
            this.subs.push(
                this.autocompleteControl.valueChanges.pipe(
                    (this.dynamicOptions?.debounce ? debounceTime(this.dynamicOptions.debounce) : debounceTime(0))
                ).subscribe(async (value) => {
                    if (this.dynamicOptions) {
                        const options = await this.dynamicOptions.func.call(this.dynamicOptions.context, value);
                        this.options = options;
                    }
                    this.onAutocompleteValueChange(value);
                })
            );

            this.autocompleteControl.markAsTouched();
            this.updateDisabledStatus(this.fieldControl.disabled);
        }

        this.fieldControl?.registerOnDisabledChange((isDisabled) => {
            this.updateDisabledStatus(isDisabled);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // @ts-ignore
        if ( changes.dateFieldData ) {
            this.configureDateField();
        }
    }

    autocompleteTrackByFn(index: number, item: {label: string, customStyle: {} | undefined}) {
        return item.label;
    }

    configureDateField() {
        this.disablePreviousDate = this?.dateFieldData?.['disablePreviousDate'];

        if (this.customFieldData) {
            this.dateWithDetail = this.customFieldData['withDetail'] ?? false;
            const conf = this.customFieldData['disablePreviousDates'];
            if (this.disablePreviousDate == undefined && conf && conf.dateField == undefined) {
                this.disablePreviousDate = this.customFieldData['disablePreviousDates'];
            }
            else if (this.disablePreviousDate == undefined && conf) {
                this.disablePreviousDate = this.findControlInParent(conf.dateField);
                if (this.disablePreviousDate) {
                    this.subs.push(
                        this.disablePreviousDate.valueChanges.subscribe((date: Moment | string) => {
                            if (!isMoment(date)) date = moment(date);

                            if (date.isAfter(this.fieldControl.value, 'day')) {
                                this.fieldControl.setValue(moment(date));
                            }
                        })
                    );
                }
            }
        }

        if (this.dateWithDetail) {
            this.generateDateWithDetailHint(this.fieldControl.value);

            this.subs.push(
                this.fieldControl.valueChanges.subscribe((data) => {
                    this.generateDateWithDetailHint(data);
                })
            );
        }
    }

    generateDateWithDetailHint(data: Date) {
        if ( data ) {
            if (isMoment(data)) {
                const now = moment();
                data.set({
                    'hour': now.hour(),
                    'minute': now.minute()
                });
                data = data.toDate();
            };

            this.hintMsg = data.toLocaleDateString(undefined, { hour: '2-digit', minute: '2-digit' });
            this.cdr.detectChanges();
        }
    }

    findControlInParent(name: string) : FormControl | undefined {
        if (this.fieldControl == undefined || this.fieldControl.parent == undefined) return undefined;
        return this.fieldControl.parent.get(name) as FormControl;
    }

    updateDisabledStatus(isDisabled: boolean) {
        if (isDisabled) this.autocompleteControl?.disable();
        else this.autocompleteControl?.enable();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    selectDate(event: Date): void {
        this.fieldControl.setValue(event);
    }

    weekendsDatesFilter = (d: Date | null): boolean => {
        if (!d) return false;
        const day = d.getDay();
        /* Prevent Saturday and Sunday for select. */
        return day !== 0 && day !== 6;
    };

    disablePreviousDates = (d: Date | null): boolean => {
        if (!d || !this.disablePreviousDate) return false;
        const date = new Date(d);
        const previousDate = this.disablePreviousDate instanceof FormControl ?  (this.disablePreviousDate?.value ? new Date(this.disablePreviousDate?.value) : Utils.NOW.toDate()) : new Date(this.disablePreviousDate);
        previousDate.setDate(previousDate.getDate() - 1);
        return date > previousDate;
    };

    onAutocompleteValueChange(value: string): void {
        value = value.toLowerCase();
        const option = this.options?.find(
            (option) => option.label.toLowerCase() === value
        );
        this.fieldControl.setValue(option ? option.value : null);
        this.fieldControl.markAllAsTouched();
        this.autocompleteControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
        });
    }

    setValue(value: any) {
        this.autocompleteControl.setValue(value.label);
        this.fieldControl.setValue(value.value);
        this.fieldControl.markAllAsTouched();
        this.autocompleteControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
        });
    }

    clearAutocompleteValue(): void {
        this.autocompleteControl.setValue('');
    }

    keyPressHandler(event: KeyboardEvent) {
        if (['Enter', 'Backspace', 'Delete', 'ArrowRight', 'ArrowLeft'].includes(event.key)) return true;
        
        if (this.inputPattern == undefined) {
            return true;
        } else {
            return new RegExp(this.inputPattern).test(event.key);
        }
    }

    keyUpHandler(event: KeyboardEvent) {
        var textBox = event.target as HTMLInputElement;

        if ( this.readOnly ? !this.readOnly() : false ) {
            if (this.capitalize) {
                var start = textBox.selectionStart;
                var end = textBox.selectionEnd;
                let words = textBox.value.split(' ');
                words.forEach((word, i) => {
                    words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                });
                textBox.value = words.join(' ');

                textBox.setSelectionRange(start, end);
                this.fieldControl.setValue(textBox.value);
            }
            else if (this.uppercase) {
                this.fieldControl.setValue(textBox.value.toUpperCase());
            }

            if ( this.exactPattern ) {
                if ( !(new RegExp(this.exactPattern).test(`${this.fieldControl.value}`)) ) {
                    this.fieldControl.setValue(textBox.value.slice(0, -1));
                }
            } 
        }
    }

    stateChange(event: MatCheckboxChange) {
        if (this.group != undefined) {
            if (this.fieldControl.parent && this.fieldControl.parent instanceof FormGroup) {
                const ctrls = Object.keys(this.fieldControl.parent.controls).map(k => {
                    if (this.fieldControl.parent && this.fieldControl.parent instanceof FormGroup) {
                        return this.fieldControl.parent?.controls[k];
                    }

                    return undefined;
                }).filter(c => c != undefined);

                ctrls.forEach(c => {
                    if ((c as any).group == this.group && c != this.fieldControl) {
                        c?.setValue(false);
                    }
                });
            }
        }
    }

    radioChange(event: MatRadioChange) {
        this.fieldControl.setValue(event.value);
    }

    radioChangeOption(event: any, index: number) {
        this.fieldControl.setValue(event);
        this.selectorPosition = index;
    }

    getOptionStyleSelected(event: any, selectedControl: any){
        //@ts-ignore
        let selectedTrue = event.filter(data => data.value == selectedControl)[0];
        this.selectorPosition = event.findIndex((data: any) => data.value == selectedControl)
       
        //@ts-ignore
        // if (this.selectorPosition == -1 ) {
        //     this.selectorPosition = 0
        // }
     
        //@ts-ignore
        if (selectedTrue !== undefined ) {
            return selectedTrue.customStyle
        }
        else  {
            return {};
        }
    }

    isInNormalValue(value?: number) {
        if (value == undefined || value.toString() == '' || this.normalValue == undefined) return undefined;
        if (this.normalValue.includes("-")) {
            const min = Number(this.normalValue.split('-')[0].replace(',', '.'));
            const max = Number(this.normalValue.split('-')[1].replace(',', '.'));
            return value >= min && value <= max;
        }
        else if (this.normalValue.includes('<') && this.normalValue.includes('>')) {
            return false;
        }
        else if (this.normalValue.startsWith('<')) {
            return value < Number(this.normalValue.slice(1).replace(',', '.'));
        }
        else if (this.normalValue.startsWith('>')) {
            return value > Number(this.normalValue.slice(1).replace(',', '.'));
        }

        return false;
    }

    getTGUGHint() {
        if (this.fieldControl.getRawValue() == undefined) return '';
        if (this.fieldControl.getRawValue() <= 20) return "<=20s: Normal"
        else return ">20s: Riesgo de caída";
    }

    isTGUGCorrect() {
        return this.getTGUGHint() == '' || this.getTGUGHint() == '<=20s: Normal';
    }

    getValueFromOptions() {
        return this.options?.find(o => o.value == this.fieldControl.value)?.label ?? '';
    }

    isRequired() {
        if ( this.fieldControl.validator ) {
            const validator = this.fieldControl?.validator({} as AbstractControl);

            if ( validator ) {
                // @ts-ignore
                return validator.required;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    toggleAllSelection() {
        if (this.allSelected) {
            if ( this.maxOptionSelect ) {
                this.select.options.forEach((item: MatOption) => item.deselect());
                this.select.options.toArray().slice(0, this.maxOptionSelect).forEach((item: MatOption) => item.select());
            } else
                this.select.options.forEach((item: MatOption) => item.select());
        } else {
          this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }
    deselectAllSelection() {
        this.select.options.forEach((item: MatOption) => item.deselect());
    }

    onOptionClick($event: SelectOption) {
        this.allSelected = !this.select.options.some(item => !item.selected);

        if ( this.maxOptionSelect && this.fieldControl.value.length > this.maxOptionSelect ) {
            this.select.options.find((item: MatOption) => item.value === $event.value)?.deselect();
            this.call.openSnack(`No puedes seleccionar más de ${this.maxOptionSelect} elementos`, "X");
        }
    }

    onToggleFastButton(event: MatButtonToggleChange): void {
        event.source.checked = false;
        const actualValue = this.fieldControl.value;

        const modifyQuantity = this.numberFieldFastButtonsStep;

        let newValue = 0;

        switch (event.value) {
            case 1:
                newValue = Number(actualValue) - modifyQuantity;
                break;
            case 2:
                newValue = Number(actualValue) + modifyQuantity;
                break;
        }

        if ( 
            this.exactPattern && new RegExp(this.exactPattern).test(newValue.toString()) ||
            this.inputPattern && new RegExp(this.inputPattern).test(newValue.toString()) ||
            !this.exactPattern && !this.inputPattern
        ) {
            this.fieldControl.setValue(newValue);
        }
    }

    filterSelectOptions(event: string) {
        this.options?.forEach((option) => {
            event.split(' ').every((search: string) => {
                return option.label
                    ?.toString()
                    .toLowerCase()
                    .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                    .includes(search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
            })
            ? option.hidden = false
            : option.hidden = true
        });
    }

    openSelectAndFocusFilter() {
        this.select.open();
        this.cdr.detectChanges();

        setTimeout(() => {
            this.selectFilter.nativeElement.focus();
        }, 10)
    }

    onSelectOpen(status: boolean) {
        if ( status && this.filterSelect ) {
            this.selectFilter.nativeElement.focus();
        }
    }

    getSelectedOptions() {
        if ( this.fieldControl && this.fieldControl.value ) {
            return this.options?.filter(option => this.fieldControl.value.includes(option.value)).map(option => option.label);
        } else {
            return [];
        }
    }

    onChipRemoved(option: string) {
        const chips = this.fieldControl.value as string[];
        this.removeFirst(chips, this.options?.find(o => o.label == option)?.value);
        this.fieldControl.setValue(chips); // To trigger change detection
    }
    
    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }
    
    onSelectionChange(event: MatSelectChange) {
        if ( this.selectCleanFilterInput || this.selectCleanFilterInputAndFocus ) {
            this.filterSelectFormControl.setValue('');
        }
        
        if ( this.selectCleanFilterInputAndFocus ) {
            this.selectFilter.nativeElement.focus();
        }
    }

    focusField() {
        // @ts-ignore
        this.matFormField._formFieldControl?._elementRef?.nativeElement.focus();
    }
    blurField() {
        // @ts-ignore
        this.matFormField._formFieldControl?._elementRef?.nativeElement.blur();
    }
    closeSelect() {
        this.select.close();
    }
}
