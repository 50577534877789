<section class="document-card">
    <img class="document-image" [src]="img" width="100" height="100" title="Descargar" [alt]="title"
        (click)="triggerDownloadEvent()" />

        <div class="text-docuemnts">
            <p class="document-title">{{ title }}</p>
            <p class="document-subtitle">{{ subtitle | date : "dd/MM/yyyy" }}</p>
        </div>
   
        <button mat-icon-button class="document-delete" color="accent" title="Eliminar" (click)="triggerDeleteEvent()"
            aria-label="Button for delete the document uploaded"
            *ngIf="canDelete">
            <mat-icon>delete_forever</mat-icon>
        </button>
</section>
