import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
  
import { FormMode } from '@json/src/app/enums/form-mode';
import { Utils } from '@json/src/app/Utils';
import { InstitutionsService } from '@services/institutions/institutions.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
    
  FormMode = FormMode;
  Utils = Utils;

  readonly dialogRef = inject(MatDialogRef<UserComponent>);
  readonly data = inject(MAT_DIALOG_DATA);

  mode: FormMode = FormMode.New;

  form: FormGroup = new FormGroup({});

  loading: boolean = false;
  deleting: boolean = false;

  constructor(
    private institutionsService: InstitutionsService
  ) {
    this.form.addControl('userName', new FormControl(null, { validators: [Validators.required] }));
    this.form.addControl('biAccess', new FormControl(false));

    if ( this.data && this.data.user ) {
      this.mode = FormMode.Edit;
      this.form.get('userName')?.setValue(this.data.user.nam);
      this.form.addControl('password', new FormControl(null, { validators: [] }));
    } else {
      this.form.addControl('password', new FormControl(null, { validators: [Validators.required] }));
    }
  }

  formValid(): boolean {
    return this.form.valid;
  }

  cancel() {
    this.dialogRef.close();
  }

  create() {
    if (this.formValid()) {
      this.loading = true;
      this.institutionsService.createBiUser(this.form.value['userName'], this.form.value['password'], this.form.value['biAccess'])
      .then((user) => {
        this.dialogRef.close(user);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }

  modify() {
    if (this.formValid()) {
      this.loading = true;
      this.institutionsService.updateBiUser(this.data.user.id, this.form.value['userName'], this.form.value['password'], this.form.value['biAccess'])
      .then((user) => {
        this.dialogRef.close(user);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }

  delete() {
    this.deleting = true;
    this.institutionsService.deleteBiUser(this.data.user.id)
    .then(() => {
      this.dialogRef.close(true);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      this.deleting = false;
    });
  }
}
