<!-- Users list mode -->
<div class="list list-mode">
  <div class="list-header">
      <div class="element space"></div>
      <div class="element sortable uid" (click)="sortBy('uid')">
        UID 
        <mat-icon *ngIf="currentSort === 'uid' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
        <mat-icon *ngIf="currentSort === 'uid' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
      </div>
      <div class="element sortable name" (click)="sortBy('nam')">
        Nombre 
        <mat-icon *ngIf="currentSort === 'nam' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
        <mat-icon *ngIf="currentSort === 'nam' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
      </div>
      <div class="element type">
        Tipo
      </div>
      <div class="element sortable date" (click)="sortBy('exdt')">
        Alta
        <mat-icon *ngIf="currentSort === 'exdt' && currentSortMode === 'asc'" class="arrow">arrow_drop_up</mat-icon>
        <mat-icon *ngIf="currentSort === 'exdt' && currentSortMode === 'desc'" class="arrow">arrow_drop_down</mat-icon>
      </div>
      <div class="element actions">
        Acciones
      </div>
  </div>

  <app-card 
      class="list-mode"
      *ngFor="let user of currentData" 
      [title]="user.nam" 
      [id]="user.id" 
      [img]="user.imagePath" 
      [actions]="actions"
      [colorHeader]="'default'" 
      [route]="'/global-eye/admin/users'"
      [defaultImg]="user.gnd == 'M' ? '/assets/pics/male_nurse.svg' : '/assets/pics/female_nurse.svg'"
      (deleteButtonCLicked)="handleDelete($event)"
   >
      <div class="uid" slot="number">
        {{ user.uid }}
      </div>
      <div slot="content" class="content">
          <div class="item roles">
            <ng-container *ngIf="!isPharma() || !isValidPharmaRole(user.roles)">
                <ng-container *ngFor="let role of user.roles | slice:0:2">
                    <div class="role-label admin" *ngIf="role === UserRole.Admin">
                        Admin
                    </div>
                    <div class="role-label other" *ngIf="role !== UserRole.Admin">
                        {{UserRole[role]}}
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isPharma() && isValidPharmaRole(user.roles)">
                <div class="role-label other">
                    {{getUserRole(user.roles)}}
                </div>
            </ng-container>
          </div>
          <div class="item date">
              {{user.exdt.toLocaleDateString()}}
          </div>
      </div>
  </app-card>
</div>