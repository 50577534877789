import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { DynamicFormComponent } from '@shared/dynamic-form/dynamic-form.component';

import { SortService } from '@services/sort.service';

import { inOutFade } from '@json/src/app/animations/general';
import { ListSubformComponent } from '@shared/list-subform/list-subform.component';

@Component({
    selector: 'app-patient-edit-locks',
    templateUrl: './locks.component.html',
    styleUrls: ['./locks.component.scss'],
    animations: [inOutFade]
})
export class LocksComponent extends DynamicFormComponent implements OnInit, OnDestroy {

    @ViewChild('clinicCourse') clinicCourse: ElementRef;

    constructor(
        protected override sortService: SortService,
        protected override cdr: ChangeDetectorRef
    ) {
        super(sortService, cdr)
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.schema.forEach(schema => schema.childs?.forEach(child => { this.fieldVisible(child, child) }));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
