<ng-container *ngIf="!lens">
  <img
    #image
    handleError
    draggable="false"
    [class.loaded]="isLoaded"
    [src]="source"
    [alt]="alt"
    [ngClass]="{'contain': isSvg || contain}"
    (load)="markForChange()"
    (error)="onError.emit()"
  />
</ng-container>

<ng-container *ngIf="lens">
  <img
    #image
    handleError
    draggable="false"
    [redZoom]="source"
    redZoomClass="red-zoom--style--magnifier"
    [class.loaded]="isLoaded"
    [src]="source"
    [alt]="alt"
    [ngClass]="{'contain': isSvg || contain}"
    (load)="markForChange()"
    (error)="onError.emit()"
  />
</ng-container>


<!-- <ion-skeleton-text *ngIf="!isLoaded" [animated]="true"></ion-skeleton-text> -->

