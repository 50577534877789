<div class="container-fluid" >
  <div class="page-title">
    Altas/Bajas/RE
  </div>

  <section class="proc-auth-cancel-wrapper">
    <div class="selector-inst">
      <mat-form-field class="interval">
        <div class="label">Intervalo</div>
        <mat-select
          [(value)]="selectedInterval"
          (selectionChange)="onIntervalChange($event)"
        >
          <mat-option
            *ngFor="let option of intervalOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-form-field
          type="checkbox"
          label="Mostrar procesados"
          [fieldControl]="form.controls['checkboxProcessed'] | asFormControl"
      ></app-form-field>
      <button class="print-button" mat-button matTooltip="Imprimir" (click)="handlePrint()">
        <mat-icon class="mat-icon-lg">print</mat-icon>
      </button>
      <mat-form-field>
        <div class="label">Centro</div>
        <mat-select
          [(value)]="selectedInstitution"
          (selectionChange)="onInstitutionChange($event)"
        >
          <mat-option
            *ngFor="let option of institutionOptions"
            [value]="option.id"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-table
      #table
      *ngIf="dataFiltered"
      tableType="default"
      [checkboxs]="checkboxs"
      [input]="dataFiltered"
      [columns]="dataColumns"
      (onFilter)="calculateCheckboxes($event)"
      (onPageChange)="onPageChange($event)"
    >
    </app-table>
  </section>
</div>
