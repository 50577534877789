<ng-container class="report-custom-container"
  ><app-report-select
    [title]="'Deudas agudos/adelantos/pedidos'"
    (previewClicked)="handlePreviewClick()"
    (printClicked)="handlePrintClick()"
    (pdfClicked)="handlePdfClick()"
    (excelClicked)="handleExcelClick()"
  >
    <div slot="content" class="container-options">
      <form [formGroup]="form">
        <section class="filter-container" *ngIf="typeGlobal" >
          <div class="subtitle">Centro</div>
          <app-form-field
            class="field"
            [type]="'select'"
            label="Selecciona un centro"
            [fieldControl]="form.controls['selectedInstitution'] | asFormControl"
            [options]="selectOptionsInsitutions">
          </app-form-field>

          <mat-checkbox 
            formControlName="consolidate" 
            class="col-12" 
            *ngIf="form.controls['selectedInstitution'].value === ''">
            Consolidar centros
          </mat-checkbox>
        </section>

        <div class="section">
            <p class="subtitle col-12">Tipo de reporte</p>
            <mat-radio-group formControlName="typeReport">
              <mat-radio-button value="1" class="col-4"> Todos </mat-radio-button>
              <mat-radio-button value="2" class="col-4"> Agudos/adelantos/pedidos </mat-radio-button>
              <mat-radio-button value="3" class="col-4"> MUTUAS </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="section" *ngIf="typeGlobal">
          <p class="subtitle col-12">Filtrar por</p>
          <mat-checkbox formControlName="OnlyExitus" class="col-12">
            Sólo Exitus/Bajas
          </mat-checkbox>
        </div>

        <div class="section" *ngIf="!typeGlobal || form.controls['selectedInstitution'].value !== ''">
          <p class="subtitle col-12">Agrupación por</p>
          <mat-checkbox formControlName="checkboxDoctorSS" class="col-4">
              Medico SS
          </mat-checkbox>
      </div>
      </form>
    </div>
  </app-report-select>
</ng-container>

