import { Component, OnInit, OnDestroy } from '@angular/core';
import { Utils } from '@json/src/app/Utils';

import { DateService } from '@services/date.service';
import { FormService } from '@services/form.service';
import { SortService } from '@services/sort.service';

import { ArraySubformComponent } from '@shared/array-subform/array-subform.component';

@Component({
    selector: 'app-vital-signs',
    templateUrl: './vital-signs.component.html',
    styleUrls: ['./vital-signs.component.scss'],
})
export class VitalSignsComponent extends ArraySubformComponent implements OnInit, OnDestroy {

    constructor(
        protected override   formsService: FormService,
        protected override  sortService: SortService,
        protected override   dateService: DateService
    ) {
        super(formsService, sortService, dateService)
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
