import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { reportDataIEF, rppIEF } from '@constants/reports/report-ief';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    MedPharma,
    MedSubtypePharma,
    PatientPharma,
    Report,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;
const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
    },
};

@Component({
    selector: 'app-report-ief',
    templateUrl: './report-ief.component.html',
    styleUrls: ['./report-ief.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class ReportIefComponent implements OnInit {
    public reporttDataIEF = reportDataIEF;
    public currentInstitution: number;
    public currentInstitutionShortName: string;

    public showPatientSelect: boolean = false;
    public showMedicineGroupSelect: boolean = false;
    public showMedicineSubType: boolean = false;
    public selectOptionsPatients: PatientPharma[];
    public selectOptionsPatientsOptions: SelectOption[];
    public selectOptionsGroupMedicine: MedPharma[];
    public selectOptionsGroupMedicineOptions: SelectOption[];
    public selectOptionsMedicineSubType: MedSubtypePharma[];
    public selectOptionsMedicineSubTypeOptions: SelectOption[];

    public form: FormGroup;
    public report: Report;
    public pathReportDownload: string;
    public minAllowedMonth: string;
    public initialMonth: Date;
    public maxAllowedMonth: Date | null;

    public toDateMin: _moment.Moment;
    public toDateMax: _moment.Moment;
    public fromDateMax: _moment.Moment;

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  
    

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private calls: CallsService,
        private reportService: ReportsService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService,  
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            startDate: [moment().subtract(3, 'months'), Validators.required],
            endDate: [moment(), Validators.required],
            typeReport: ['numIEF'],
            selectedPatient: [''],
            selectedGroupMedicine: [''],
            selectedMedicineSubType: [''],
            selectedInstitution: ['', Validators.required],
        });
        this.currentInstitution = this.institutionService.getCurrentInstitution();
        this.currentInstitutionShortName = this.institutionService.getCurrentInstitutionShortName();

        const now = moment();
        this.reporttDataIEF.dataDownloadPDF.dn = this.reporttDataIEF.dataRenderReport.dn =
            `Calculo_Indicadores_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reporttDataIEF.dataDownloadExcel.dn = `Calculo_Indicadores_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Indicadores farmacológicos`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Indicadores farmacológicos`);

        this.typeGlobal = this.roleManager.isPharma();

        this.getDataForTheSelects();
        this.updateDateLimits();
        this.toDateMax = moment();

        this.form.get('startDate')?.valueChanges.subscribe(() => {
            this.updateDateLimits();
        });

        this.form.get('endDate')?.valueChanges.subscribe(() => {
            this.updateDateLimits();
        });
    }
    updateDateLimits(): void {
        this.fromDateMax = moment(this.form.controls['endDate'].value).subtract(
            3,
            'M'
        );
        this.toDateMin = moment(this.form.controls['startDate'].value).add(3, 'M');
    }

    setMonthAndYear(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>,
        formControlName: string
    ) {
        const ctrlValue = this.form.get(formControlName)?.value;

        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());

        this.form.patchValue({ [formControlName]: ctrlValue });
        datepicker.close();
    }

    handlePreviewClick(): void {
        if (this.form.valid) {
            const { rpp } = this.getFormData();

            this.reporttDataIEF.data.dn = Utils.buildUniqueFileName + "_{0}.html";
            this.reporttDataIEF.data.rp = this.getInfoRP();
            this.reporttDataIEF.data.rpp = rpp;
            this.reportService.handlePreviewClick(this.router, this.reporttDataIEF);
        } else {
            this.calls.openSnack('Debes seleccionar dos fechas');
        }
    }
    onSelectMedicineGroupChange(event: any): void {
        this.selectOptionsMedicineSubType =
            this.selectOptionsGroupMedicine[event - 1].meds;

        this.selectOptionsMedicineSubTypeOptions = this.selectOptionsMedicineSubType.map((item: MedSubtypePharma) => {
            return { label: item.Name, value: item.DrugId };
        })
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());

            // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({shortName: this.institutionService.getInstitutionName()})
            }
          });

        this.reportService
            .getRepParamsEditDataIEF(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsPatients = result.payload.pats;
                this.selectOptionsGroupMedicine = result.payload.meds;

                this.selectOptionsGroupMedicineOptions = this.selectOptionsGroupMedicine.map((item: MedPharma) => {
                    return { label: item.Description, value: item.Id };
                })

                this.selectOptionsPatientsOptions = this.selectOptionsPatients.map((item: PatientPharma) => {
                    return { label: item.nam, value: item.num };
                })
            });
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelectMedicine(): void {
        this.showMedicineGroupSelect = !this.showMedicineGroupSelect;
        if (!this.showMedicineGroupSelect) {
            this.form.patchValue({
                selectedGroupMedicine: '',
            });
        }
    }

    handlePrintClick(): void {
        if (this.form.valid) {
            const { rpp } = this.getFormData();

            this.reporttDataIEF.dataRenderReport.rp = this.getInfoRP();
            this.reporttDataIEF.dataRenderReport.rpp = rpp;
            this.reportService.handlePrintClick(this.reporttDataIEF);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handlePdfClick(): void {
        if (this.form.valid) {
            const { rpp } = this.getFormData();

            this.reporttDataIEF.dataDownloadPDF.rp = this.getInfoRP();
            this.reporttDataIEF.dataDownloadPDF.rpp = rpp;
            this.reportService.handlePdfClick(this.reporttDataIEF);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    handleExcelClick(): void {
        if (this.form.valid) {
            const { rpp } = this.getFormData();

            this.reporttDataIEF.dataDownloadExcel.rp = this.getInfoRP();
            this.reporttDataIEF.dataDownloadExcel.rpp = rpp;
            this.reportService.handleExcelClick(this.reporttDataIEF);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar dos fechas');
            }
        }
    }

    getFormData(): {
        startDate: string;
        endDate: string;
        rpp: string;
        selectedPatient: string;
        selectedGroupMedicine: string;
        selectedMedicineSubType: string;
    } {
        const startDate = moment(this.form.get('startDate')?.value).format('MM/YYYY');
        const endDate = moment(this.form.get('endDate')?.value).format('MM/YYYY');

        const typeReport = this.form.get('typeReport')?.value;
        const rpp = this.reportService.findValueByType(typeReport, rppIEF);

        const selectedPatient = this.form.get('selectedPatient')?.value;
        const selectedGroupMedicine = this.form.get('selectedGroupMedicine')?.value;
        const selectedMedicineSubType = this.form
            .get('selectedMedicineSubType')
            ?.value.toString();

        return {
            startDate,
            endDate,
            rpp,
            selectedPatient,
            selectedGroupMedicine,
            selectedMedicineSubType,
        };
    }

    getInfoRP(): string {
        const {
            startDate,
            endDate,
            selectedPatient,
            selectedGroupMedicine,
            selectedMedicineSubType,
        } = this.getFormData();


        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.shortName

        const data = {
            LocaleId: '3082',
            Institution: String(currentInstitution),
            PatientNumber: String(selectedPatient),
            NervType: selectedGroupMedicine ? String(selectedGroupMedicine) : null,
            NervSubType: selectedGroupMedicine ? selectedMedicineSubType : null,
            DateFrom: Utils.BUG_FixDateForTimeZone(moment(this.form.get('startDate')?.value).startOf('day').startOf('month').toDate())?.toISOString().split(".")[0],
            DateUntil: Utils.BUG_FixDateForTimeZone(moment(this.form.get('endDate')?.value).endOf('day').endOf('month').toDate())?.toISOString().split(".")[0],
        };
        return JSON.stringify(data);
    }
}
