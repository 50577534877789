<section #table class="table-wrapper">
    <div class="table-filters" *ngIf="canFilter" [ngClass]="{
      'position-absolute': positionFilters === 'absolute',
      'position-relative': positionFilters === 'relative'
    }">
        <form [formGroup]="formFilterFront" class="search-filters">
            <mat-form-field appearance="fill" *ngIf="selectFilter">
                <div class="label">Filtros</div>
                <mat-select matNativeControl multiple formControlName="select" [compareWith]="filterService.comparer"
                    (selectionChange)="applyFilters()">
                    <mat-optgroup *ngFor="let group of selectFilter" [label]="group.name">
                        <mat-option *ngFor="let option of group.filter" [value]="option">
                            {{ option.value }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="selectInstitution">
                <div class="label">Centro</div>
                <mat-select [(value)]="selectedInstitution" (selectionChange)="handleInstitutionChange($event)">
                    <mat-option *ngFor="let option of institutionOptions" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <div class="label">{{ filterFrontInputString }}</div>
                <input matInput (keyup)="applyFilters()" [placeholder]="inputPlaceholder" #input
                    formControlName="filter" />
            </mat-form-field>
        </form>

        <form [formGroup]="formFilterBack" *ngIf="filterBack">
            <mat-form-field>
                <div class="label">{{ filterBackInputString }}</div>
                <input formControlName="filter" matInput (keyup)="applyFilterBack()" />
                <button type="button" mat-icon-button matSuffix aria-label="Limpiar filtros"
                    aria-pressed="Filtros limpiados" (click)="clearFiltersBack()">
                    <mat-icon>filter_alt</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <mat-button-toggle *ngIf="tableType === 'patient'" mat-icon-button class="patient-alert" value="alert"
            (change)="applyAlertFilter()" [title]="alertString">
            <mat-icon>info</mat-icon></mat-button-toggle>

        <app-entity-report-actions class="patient-report-actions" [dataItems]="this.currentPatientsData">
        </app-entity-report-actions>
    </div>

    <div class="tableScroll">
        <table class="patient-list-wrapper" mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <ng-container [ngSwitch]="column.columnDef">
                    <ng-container *ngSwitchCase="imgPathString">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ column.header }}
                        </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{
                'row-grey': row.expedition || isOutStock(row.flag),
                'row-black': isObsolete(row.flag),
                'row-green': isProcessed(row.Flag),
                'row-yellow': isNotProcessed(row.Flag),
                }">
                            <div mat-card-avatar class="list-img">
                                <img mat-card-image [src]="row.imagePath" (error)="imageLoadError($event, row)" />
                            </div>
                        </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="nameString">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ column.header }}
                        </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{
                'row-grey': row.expedition || isOutStock(row.flag),
                'row-black': isObsolete(row.flag),
                'row-green': isProcessed(row.Flag),
                'row-yellow': isNotProcessed(row.Flag)
                }">
                            <a [routerLink]="route + '/' + editRoute + '/' + row.id">{{
                                column.cell(row)
                                }}</a>
                        </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="flagString">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ column.header }}
                        </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="{
                'row-grey': row.expedition || isOutStock(row.flag),
                'row-black': isObsolete(row.flag),
                'row-green': isProcessed(row.Flag),
                'row-yellow': isNotProcessed(row.Flag)
                }">
                            <ng-container *ngFor="let icon of column.cell(row)">
                                <mat-icon [ngClass]="icon" class="card-flag" [title]="icon">bookmark</mat-icon>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="column.header == ''">
                            {{ column.header }}
                        </th>
                        <td mat-cell *matCellDef="let row" [hidden]="column.header == ''" [ngClass]="{
                'row-grey': row.expedition || isOutStock(row.flag),
                'row-black': isObsolete(row.flag),
                'row-green': isProcessed(row.Flag),
                'row-yellow': isNotProcessed(row.Flag)
                }">
                            {{ column.cell(row) }}
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!-- Row actions -->
            <ng-container *ngIf="actions" matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{ nameColumns["actions"] }}
                </th>
                <td mat-cell *matCellDef="let row" [ngClass]="{
            'row-grey': row.expedition || isOutStock(row.flag),
            'row-black': isObsolete(row.flag),
            'row-green': isProcessed(row.Flag),
            'row-yellow': isNotProcessed(row.Flag),
            }">
                    <div class="row-actions">
                        <ng-container *ngFor="let action of actions">
                            <button *ngIf="action.hasPermission == undefined || action.hasPermission().length > 0" mat-icon-button [title]="action.name"
                                [routerLink]="route + '/' + action.routeLink + '/' + row.id"
                                [queryParams]="action.queryParam">
                                <mat-icon>{{ action.iconName }}</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <!-- Row actions -->
            <ng-container *ngIf="checkboxs" matColumnDef="checkboxs">
                <th mat-header-cell *matHeaderCellDef>
                    {{ nameColumns["checkboxs"] }}
                </th>
                <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{
            'row-grey': row.expedition || isOutStock(row.flag),
            'row-black': isObsolete(row.flag),
            'row-green': isProcessed(row.Flag),
            'row-yellow': isNotProcessed(row.Flag),
            }">
                    <div class="row-actions" [ngClass]="{'not-allowed': checkboxs[i].disabled}">
                        <mat-checkbox [id]="checkboxs[i].id ?? i" [(ngModel)]="checkboxs[i].checked" [disabled]="checkboxs[i].disabled"
                            [title]="checkboxs[i].name" (change)="checkboxs[i].callback($event)"></mat-checkbox>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr [id]="row.id" mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <!-- <td class="mat-cell" colspan="4">
                    <mat-spinner *ngIf="!(currentFilter || currentSearch)"></mat-spinner>
                </td> -->

                <td class="mat-cell" colspan="7">
                    <!-- <span *ngIf="currentFilter || currentSearch"> -->
                    <div class="no-data">
                        {{ msgNotFound }} <span class="search-value">{{ input.value }} </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <mat-paginator 
        class="pagination" 
        aria-label="Seleccionar pagina" 
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize || 10"
        (page)="onPageChange.emit($event)">
    </mat-paginator>
</section>
