import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginForm } from '@interfaces/forms.interface';
import { AuthService } from '@services/auth.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public form: FormGroup<LoginForm> = new FormGroup({
        user: new FormControl('', { validators: [Validators.required], nonNullable: true }),
        password: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    });

    constructor(
        private authService: AuthService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Login`);
        this.gaService.pageView(`${window.location.href}`, `Login`);

        this.authService.logOut();
    }

    submit(): void {
        if (this.form.valid) {
            document.getElementById("loading-spinner")?.classList.add("show");
            this.authService.logIn(this.form.getRawValue()).finally(() => {
                document.getElementById("loading-spinner")?.classList.remove("show");
            });
        }
    }
}
