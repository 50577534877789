<div 
    id="reset-password-component"
    class="container-fluid h-100 d-flex flex-column justify-content-between"
>
    <div class="row"></div>
    <form 
        class="row d-flex justify-content-center flex-grow"
        [formGroup]="form"
        (submit)="submit()"
    >
        <div class="col-8 col-md-4">
            <div class="row">
                <h3 class="mb-4">Nueva Contraseña</h3>
            </div>
            
            <div class="row">
                <app-form-field
                    type="password"
                    label="Contraseña"
                    errorMsg="'Contraseña' es un campo requerido"
                    [fieldControl]="form.controls['password'] | asFormControl"
                ></app-form-field>
            </div>
            
            <div class="row">
                <app-form-field
                    type="password"
                    label="Confirmar Contraseña"
                    errorMsg="'Confirmar Contraseña' es un campo requerido"
                    [fieldControl]="form.controls['password2'] | asFormControl"
                ></app-form-field>
            </div>
    
            <div class="row p-bs">
                <button mat-raised-button color="primary">Enviar</button>
            </div>
        </div>
    </form>
    <app-footer></app-footer>
</div>