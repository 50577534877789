<div 
    id="forgot-password-component"
    class="container-fluid h-100 d-flex flex-column justify-content-between"
>
    <div></div>
    <form 
        class="row d-flex justify-content-center flex-grow"
        [formGroup]="form"
        (submit)="submit()"
    >
        <div class="col-8 col-md-4">
            <div class="row">
                <div class="logo-container"></div>
            </div>
            <div class="row">
                <h3 class="mb-4">Recuperar Contraseña</h3>
            </div>
                
            <div class="row">
                <app-form-field
                    type="text"
                    label="Usuario"
                    placeholder="Usuario"
                    errorMsg="'Usuario' es un campo requerido"
                    [fieldControl]="form.controls['user'] | asFormControl"
                ></app-form-field>
            </div>
    
            <div class="row p-bs">
                <button mat-raised-button color="primary">Enviar</button>
            </div>
        </div>
    </form>
    <app-footer></app-footer>
</div>
