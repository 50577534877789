import { Injectable } from '@angular/core';
import {
    MedicineInteraction,
    MedicineInteractionAPI,
    MedicineInteractionText,
} from '@interfaces/medicine/medicine.interface';
import { CallsService } from '@services/api/calls.service';
import { BehaviorSubject, retry } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InteractionService {
    //interactions
    private _medicineInteraction: MedicineInteractionAPI[] = [];
    public medicineInteractionList: BehaviorSubject<MedicineInteractionAPI[]> =
        new BehaviorSubject<MedicineInteractionAPI[]>([]);

    //nteraction text
    private _medicineInteractionText: MedicineInteractionText;
    public medicineInteractionListText: BehaviorSubject<MedicineInteractionText> =
        new BehaviorSubject<MedicineInteractionText>({ text: [''] });

    constructor(private calls: CallsService) { }

    transformMedicineInteractiontoApp(
        medicine: MedicineInteractionAPI
    ): MedicineInteraction {
        return new MedicineInteraction(medicine);
    }

    getMedicineInteraction(medicineId: number, medicineIdSelected: number) {
        return new Promise<MedicineInteractionAPI[] | undefined>((resolve) => {
            this.calls.getInteractionByIDs(medicineId, medicineIdSelected).subscribe({
                next: (res) => {
                    this._medicineInteraction = res.payload;
                    this.medicineInteractionList.next(this._medicineInteraction);
                    resolve(this._medicineInteraction);
                },
                error: () => {
                    resolve(undefined);
                    //FIXME: De momento dejarlo comentado hasta que hayan respuestas del back
                    /*
                    this.calls.openSnack(
                      'Error al obtener las interacciones de los medicamentos'
                    );
                    */
                },
            });
        });
    }

    parseTextInteraction(interactionText: MedicineInteractionText) {
        var plainText = interactionText.text[0].replace(/<[^>]+>/g, '');
        plainText = plainText
            .replace(/&aacute;/g, 'á')
            .replace(/&eacute;/g, 'é')
            .replace(/&iacute;/g, 'í')
            .replace(/&oacute;/g, 'ó')
            .replace(/&uacute;/g, 'ú')
            .replace(/&ntilde;/g, 'ñ');
        return plainText;
    }

    getMedicineInteractionText(interactionTextId: number) {
        return new Promise<MedicineInteractionText | undefined>((resolve) => {
            this.calls.getInteractionTextByID(interactionTextId).subscribe({
                next: (res) => {
                    this._medicineInteractionText = res.payload;
                    this.medicineInteractionListText.next(this._medicineInteractionText);
                    resolve(this._medicineInteractionText);
                },
                error: () => {
                    resolve(undefined);
                    this.calls.openSnack(
                        'Error al obtener el texto de las interacciones de los medicamentos'
                    );
                },
            });
        })
    }
}
