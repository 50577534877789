import { Injectable } from '@angular/core';
import { FuntionTypes } from '@interfaces/array.form.interface';
import { DataType } from '@interfaces/data-type.interface';
import { CompareTypeMatch } from '@interfaces/dynamic-form.interface';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  constructor() {}

  sortBy(data: any[], sortFunctionName: FuntionTypes): any[] {
    const type = this.getDataType(data[0]);

    let response = data;
    switch (sortFunctionName) {
      case 'ascending':
        response = this.sortAscending(data, type);
        break;
    }

    return response;
  }

  getOrders(data: any[], sortFunctionName: FuntionTypes): number[] {
    const type = this.getDataType(data.find(d => d != undefined && d != '')) ?? this.getDataType(data[0]);

    let sortedArray: any[];
    switch (sortFunctionName) {
      case 'ascending':
        sortedArray = this.sortAscending([...data], type);
        break;

      default:
        sortedArray = [...data];
        break;
    }

    return data.map((item) => {
      return sortedArray.findIndex((sortedItem) => sortedItem === item);
    });
  }

  getDataType(data: any): DataType {
    let type: DataType = typeof data;

    switch (type) {
      case 'object': //Null es object
        type = this.getObjectType(data);
        break;

      default:
        break;
    }

    return type;
  }

  getObjectType(data: object): DataType {
    if (data instanceof Date) {
      return 'date';
    } else if (Array.isArray(data)) {
      return 'array';
    } else if (data === null) {
      return 'null';
    } else {
      return 'object';
    }
  }

  sortAscending(data: any[], dataType: DataType): any[] {
    switch (dataType) {
      case 'number':
      case 'date':
        return this.ascendingDates(data);
      case 'string':
        return this.ascendingStrings(data);

      default:
        return data;
    }
  }

  ascendingDates(data: Date[]): Date[] {
    const notDateValues = data.filter(d => !(d instanceof Date));
    const dateValues = data.filter(d => d instanceof Date);
    dateValues.sort((a, b) => a.getTime() - b.getTime());
    return [...notDateValues, ...dateValues];
  }
  descendingNumbers(data: number[]): number[] {
    return data.sort((a, b) => b - a);
  }

  ascendingStrings(data: string[]): string[] {
    return data.sort((a, b) => a.localeCompare(b));
  }
  descendingStrings(data: string[]): string[] {
    return data.sort((a, b) => b.localeCompare(a));
  }

  compare(value1: any, value2: any, mode: CompareTypeMatch): boolean {
    switch (mode) {
      case 'contains':
        switch (this.getDataType(value1)) {
          case 'array':
          case 'string':
            return value1.includes(value2);
        }
        return false;
      case 'loose-equals':
        return value1 == value2;
      case 'equals':
        return value1 === value2;
      case 'exists':
        return !!value1;
      case 'not-exists':
        return !!!value1;
      case 'greater':
        return this.getDataType(value1) !== 'object' ? value1 > value2 : false;
      case 'greater-than':
        return this.getDataType(value1) !== 'object' ? value1 >= value2 : false;
      case 'lower':
        return this.getDataType(value1) !== 'object' ? value1 < value2 : false;
      case 'lower-than':
        return this.getDataType(value1) !== 'object' ? value1 <= value2 : false;

      default:
        return false;
    }
  }
}
