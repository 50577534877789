import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
    reportDataMedChanges,
    showFilters,
} from '@constants/reports/report-change-medications';
import { schedulesHalfHourOptions } from '@constants/schedules';
import { SelectOption } from '@interfaces/input-select-option.interface';
import {
    PatientPharma,
    Report,
    User,
} from '@interfaces/report/report.interface';
import { Utils } from '@json/src/app/Utils';
import { CallsService } from '@services/api/calls.service';
import { DateService } from '@services/date.service';
import { DocumentService } from '@services/document.service';
import { InstitutionService } from '@services/institution.service';
import { ReportsService } from '@services/reports/reports.service';
import { RoleManagerService } from '@services/role-manager.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import moment from 'moment';

@Component({
    selector: 'app-report-change-medications',
    templateUrl: './report-change-medications.component.html',
    styleUrls: ['./report-change-medications.component.scss'],
})
export class ReportChangeMedicationsComponent {
    public reportDataMedChanges = reportDataMedChanges;
    public showPatientSelect: boolean = false;
    public showPersonalSelect: boolean = false;
    public exitusSelect: boolean = false;
    public showIntervalDay: boolean = false;
    public showSpecificDaySelect: boolean = false;
    public currentInstitution: number;
    public form: FormGroup;
    public currentRoute: string;
    public report: Report;
    public pathReportDownload: string;
    public safeURL: SafeUrl;
    public selectOptionsPatients: SelectOption[];
    public selectOptionsPersonal: User[];
    public filtersMedChanges = showFilters;
    public schedulesOptions = schedulesHalfHourOptions;

    public selectOptionsInsitutions: SelectOption[];
    public typeGlobal: boolean = false; // para saber si es Pharma o Quatum
  

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private dateService: DateService,
        private calls: CallsService,
        private reportService: ReportsService,
        private documentService: DocumentService,
        private institutionService: InstitutionService,
        private roleManager: RoleManagerService, 
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.form = this.formBuilder.group({
            specificDay: [moment()],
            startDate: [moment()],
            endDate: [moment().add(1, 'week')],
            selectedPatient: [''],
            selectedPersonal: [''],
            intervalType: ['specificDay'],
            checkboxEmblistables: [true],
            checkboxNE: [''],
            checkboxSP: [''],
            checkboxPayment: [''],
            checkboxAcute: [''],
            checkboxDoctorSS: [''],
            selectedOptionFrom: ['00:00'],
            selectedOptionUntil: ['23:00'],
            selectedInstitution: ['', Validators.required],

        });
        this.currentRoute = this.router.url;
        this.currentInstitution = this.institutionService.getCurrentInstitution();
    }

    ngOnInit(): void {
        this.titleService.setTitle(`Reporte: Cambios de medicación`);
        this.gaService.pageView(`${window.location.href}`, `Reporte: Cambios de medicación`);

        this.typeGlobal = this.roleManager.isPharma();

        this.getDataForTheSelects();
        this.setListenerSelectDay();
    }

    setListenerSelectDay(): void {
        this.form.get('intervalType')?.valueChanges.subscribe((value) => {
            if (value === 'specificDay') {
                this.showSpecificDaySelect = true;
                this.showIntervalDay = false;
            } else if (value === 'intervalDay') {
                this.showSpecificDaySelect = false;
                this.showIntervalDay = true;
            }
        });
    }

    getDataForTheSelects(): void {

        this.institutionService.getInstitutions().then(() => {
            this.selectOptionsInsitutions =
              Object.assign([], this.institutionService.institutionList.getValue());
            
              // Order Alpabetically label and text uppercase
            this.selectOptionsInsitutions = this.selectOptionsInsitutions.sort((a, b) => a.label.localeCompare(b.label))
            // this.selectOptionsInsitutions.map((a) =>{
            //     a.label = a.label.toUpperCase();
            // })

            if (this.selectOptionsInsitutions) {
              const hasEmptyValue = this.selectOptionsInsitutions.some(
                (item) => item.value === ''
              );
              if (hasEmptyValue) {
                this.selectOptionsInsitutions = this.selectOptionsInsitutions.filter(
                  (item) => item.value !== ''
                );
              }
            }

            if ( !this.typeGlobal ) {
                this.form.controls['selectedInstitution'].setValue({id: this.institutionService.getCurrentInstitution()})
            }
          });

        this.reportService
            .getRepParamsEditDataMedchanges(this.currentInstitution)
            .subscribe((result) => {
                this.selectOptionsPatients = result.payload.pats.map((patient: PatientPharma) => { return { label: patient.nam, value: patient.id } });
                this.selectOptionsPersonal = result.payload.users;
            });
    }

    handlePreviewClick() {
        if (this.form.valid) {
            const {
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil,
            } = this.getFormData();

            this.setInfoRP(
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil,
                true
            );

            this.reportService.handlePreviewClick(
                this.router,
                this.reportDataMedChanges
            );
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    getFormData(): {
        specificDay: string;
        startDate: string;
        endDate: string;
        selectedPatient: string;
        selectedPersonal: string;
        checkboxEmblistables: string;
        checkboxNE: string;
        checkboxSP: string;
        checkboxPayment: string;
        checkboxAcute: string;
        checkboxDoctorSS: string;
        selectedOptionFrom: string;
        selectedOptionUntil: string;
    } {
        const specificDay = this.dateService.formatDateToString(
            this.form.get('specificDay')?.value
        );
        const startDate = this.dateService.formatDateToString(
            this.form.get('startDate')?.value
        );
        const endDate = this.dateService.formatDateToString(
            this.form.get('endDate')?.value
        );
        const selectedPatient = this.form.get('selectedPatient')?.value;
        const selectedPersonal = this.form.get('selectedPersonal')?.value;

        const checkboxEmblistables = this.form.get('checkboxEmblistables')?.value;
        const checkboxNE = this.form.get('checkboxNE')?.value;
        const checkboxSP = this.form.get('checkboxSP')?.value;
        const checkboxPayment = this.form.get('checkboxPayment')?.value;
        const checkboxAcute = this.form.get('checkboxAcute')?.value;
        const checkboxDoctorSS = this.form.get('checkboxDoctorSS')?.value;
        const selectedOptionFrom = this.form.get('selectedOptionFrom')?.value;
        const selectedOptionUntil = this.form.get('selectedOptionUntil')?.value;

        return {
            specificDay,
            startDate,
            endDate,
            selectedPatient,
            selectedPersonal,
            checkboxEmblistables,
            checkboxNE,
            checkboxSP,
            checkboxPayment,
            checkboxAcute,
            checkboxDoctorSS,
            selectedOptionFrom,
            selectedOptionUntil,
        };
    }

    handlePrintClick() {
        if (this.form.valid) {
            const {
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil,
            } = this.getFormData();

            this.setInfoRP(
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil
            );
            this.reportService.handlePrintClick(this.reportDataMedChanges);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handlePdfClick() {
        if (this.form.valid) {
            const {
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil,
            } = this.getFormData();
            this.setInfoRP(
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil
            );
            this.reportService.handlePdfClick(this.reportDataMedChanges);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    handleExcelClick() {
        if (this.form.valid) {
            const {
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil,
            } = this.getFormData();

            this.setInfoRP(
                specificDay,
                startDate,
                endDate,
                selectedPatient,
                selectedPersonal,
                checkboxEmblistables,
                checkboxNE,
                checkboxSP,
                checkboxPayment,
                checkboxAcute,
                checkboxDoctorSS,
                selectedOptionFrom,
                selectedOptionUntil
            );
            this.reportService.handleExcelClick(this.reportDataMedChanges);
        } else {
            if ( this.form.value.selectedInstitution == ''){
                this.calls.openSnack('Debes seleccionar un centro');
            } else {
                this.calls.openSnack('Debes seleccionar un día de generación de plan');
            }
        }
    }

    setInfoRP(
        specificDay: string,
        startDate: string,
        endDate: string,
        selectedPatient: string,
        selectedPersonal: string,
        checkboxEmblistables: string,
        checkboxNE: string,
        checkboxSP: string,
        checkboxPayment: string,
        checkboxAcute: string,
        checkboxDoctorSS: string,
        selectedOptionFrom: string,
        selectedOptionUntil: string,
        isPreview: boolean = false
    ): void {
        let startDateRP;
        let endDateRP;

        if (this.form.controls['intervalType'].value == 'specificDay') {
            startDateRP = this.dateService.transformToDateWithSpecificHours(specificDay, "00:00");
            endDateRP = this.dateService.transformDateTimeIncrementHours(specificDay);
        } else {
            startDateRP = Utils.zeroTimeDate(new Date(startDate));
            startDateRP = this.dateService.transformToDateWithSpecificHours(
                startDate,
                selectedOptionFrom
            );
            endDateRP = this.dateService.transformToDateWithSpecificHours(
                endDate,
                selectedOptionUntil
            );
        }

        let  currentInstitution;
        const dataForm = this.form.getRawValue()
        //@ts-ignore
        currentInstitution = dataForm.selectedInstitution.id


        const rp = JSON.stringify({
            LocaleId: '3082',
            InstitutionId: String(currentInstitution),
            UserName: selectedPersonal ? String(selectedPersonal) : null,
            PatientId: selectedPatient ? String(selectedPatient) : null,
            DateFrom: startDateRP,
            DateUntil: endDateRP,
            ShowNE: checkboxNE ? String(checkboxNE) : null,
            ShowSP: checkboxSP ? String(checkboxSP) : null,
            ShowPAGO: checkboxPayment ? String(checkboxPayment) : null,
            ShowEmb: checkboxEmblistables ? String(checkboxEmblistables) : null,
            ShowAgudos: checkboxAcute ? String(checkboxAcute) : null,
            GroupMedicoSS: checkboxDoctorSS ? String(checkboxDoctorSS) : null,
        });

        this.reportDataMedChanges.data.rp = rp;
        if (isPreview) this.reportDataMedChanges.data.dn = Utils.buildUniqueFileName + "_{0}.html";
        this.reportDataMedChanges.dataDownloadPDF.rp = rp;
        this.reportDataMedChanges.dataDownloadExcel.rp = rp;
        this.reportDataMedChanges.dataRenderReport.rp = rp;

        const now = moment();
        if (!this.showPersonalSelect) {
            this.reportDataMedChanges.data.rpp = this.reportDataMedChanges.dataDownloadPDF.rpp =
            this.reportDataMedChanges.dataRenderReport.rpp = this.reportDataMedChanges.dataDownloadExcel.rpp = '/COROTA/MedChanges';
        }
        else {
            this.reportDataMedChanges.data.rpp = this.reportDataMedChanges.dataDownloadPDF.rpp =
            this.reportDataMedChanges.dataRenderReport.rpp = this.reportDataMedChanges.dataDownloadExcel.rpp = '/COROTA/MedChangesUsers';
        }

        this.reportDataMedChanges.dataDownloadPDF.dn = this.reportDataMedChanges.dataRenderReport.dn =
            `Reporte_de_cambios_en_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.pdf`;
        this.reportDataMedChanges.dataDownloadExcel.dn = `Reporte_de_cambios_en_medicacion_${this.institutionService.getCurrentInstitutionShortName()}_${now.format('YYYYMMDD')}_${now.format('HHMM')}.xls`;
    }

    toggleSelectPatient(): void {
        this.showPatientSelect = !this.showPatientSelect;
        if (!this.showPatientSelect) {
            this.form.patchValue({
                selectedPatient: '',
            });
        }
    }

    toggleSelectPersonal(): void {
        this.showPersonalSelect = !this.showPersonalSelect;
        if (!this.showPersonalSelect) {
            this.form.patchValue({
                selectedPersonal: '',
            });
        }
    }

    toggleSelectSpecificDay(): void {
        this.showSpecificDaySelect = !this.showSpecificDaySelect;
        if (!this.showSpecificDaySelect) {
            this.form.patchValue({
                specificDay: '',
            });
        }
    }

    toggleSelectIntervalDay(): void {
        this.showIntervalDay = !this.showIntervalDay;
        if (!this.showIntervalDay) {
            this.form.patchValue({
                startDate: '',
                endDate: '',
            });
        }
    }

    isChecked() {
        return showFilters.some(f => this.form.controls[f.formControlName].value);
    }
}
