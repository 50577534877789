<h2 mat-dialog-title>Listado de incidencias ({{incidentCount}} incidencias en {{patients.length}} pacientes)</h2>

<mat-dialog-content>
  <div class="patients">
    <div class="patient" *ngFor="let patient of patients">
      <span class="name">{{patient.label}}</span>

      <!-- General -->
      <div class="incident general" *ngIf="patientHasGeneralIncidents(patient)">
        <span class="incident-name">General</span>
        <div class="hours">
          <ng-container *ngIf="!patient.value.applyToAllMedicationHours.value">
            <div class="hour" *ngFor="let schedule of patient.value.generalIncident">
              <span class="label">{{schedule.hour}}</span>
              <span>{{getIncidenceSubtypeLabel(schedule.incident.value)}}</span>
              <span class="note" *ngIf="schedule.note.value">
                Nota: {{schedule.note.value}}
              </span>
              <span class="no-note" *ngIf="!schedule.note.value">
                Sin notas
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="patient.value.applyToAllMedicationHours.value">
            <div class="hour">
              <div class="label-group">
                <span class="label" *ngFor="let schedule of patient.value.generalIncident">{{schedule.hour}}</span>
              </div>
              <span>{{getIncidenceSubtypeLabel(patient.value.generalIncident[0].incident.value)}}</span>
              <span class="note" *ngIf="patient.value.generalIncident[0].note.value">
                Nota: {{patient.value.generalIncident[0].note.value}}
              </span>
              <span class="no-note" *ngIf="!patient.value.generalIncident[0].note.value">
                Sin notas
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- SPDA -->
      <div class="incident spda" *ngIf="patientHasSPDAIncidents(patient)">
        <span class="incident-name">SPDA</span>
        <div class="hours">
          <div class="hour" *ngFor="let schedule of patient.value.spda">
            <span class="label">{{schedule.hour}}</span>

            <div class="medicines">
              <span class="medicines-header">No se han administrado lo siguientes medicamentos:</span>
              <div class="medicine" *ngFor="let medicine of schedule.medicines">
                <span>{{medicine.DrugName}}</span>
              </div>
            </div>
            <span class="note" *ngIf="schedule.note.value">
              Nota: {{schedule.note.value}}
            </span>
            <span class="no-note" *ngIf="!schedule.note.value">
              Sin notas
            </span>
          </div>
        </div>
      </div>
      <!-- NE -->
      <div class="incident ne" *ngIf="patientHasNEIncidents(patient)">
        <span class="incident-name">N/E</span>
        <div class="hours">
          <div class="hour" *ngFor="let schedule of patient.value.ne">
            <span class="label">{{schedule.hour}}</span>

            <div class="medicines">
              <span class="medicines-header">No se han administrado lo siguientes medicamentos:</span>
              <div class="medicine" *ngFor="let medicine of schedule.medicines">
                <span>{{medicine.DrugName}}</span>
                <span class="note" *ngIf="medicine.note.value">
                  Nota: {{medicine.note.value}}
                </span>
                <span class="no-note" *ngIf="!medicine.note.value">
                  Sin notas
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SP -->
      <div class="incident sp" *ngIf="patientHasSPIncidents(patient)">
        <span class="incident-name">S/P</span>
        <div class="hours">
          <div class="hour">
            <div class="medicines">
              <span class="medicines-header">Se han administrado lo siguientes medicamentos:</span>
              <div class="medicine" *ngFor="let medicine of patient.value.sp[0].medicines">
                <span>{{medicine.DrugName}}</span>
                <span class="note" *ngIf="medicine.note.value">
                  Nota: {{medicine.note.value}}
                </span>
                <span class="no-note" *ngIf="!medicine.note.value">
                  Sin notas
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
  <button mat-button *ngIf="canSign" (click)="sign()">Administrar y firmar</button>
</mat-dialog-actions>