import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RenderDispersinPlanPetition } from '@interfaces/procs/proc-plans';
import {
    DonwloadReportAPI,
    PreviewReportAPI,
    RenderReportAPI,
} from '@interfaces/report/report.interface';

import { catchError, map, Observable, of } from 'rxjs';

import { APIResponseParsed, LoginDataAPI } from '@interfaces/api/api.interface';
import { InstitutionSelectListAPI } from '@interfaces/institution/institution.interface';
import {
    IncompatibilityDataAPI,
    InvalidHalfDataAPI,
    OverdoseDataAPI,
} from '@interfaces/medicine/medicine.interface';
import {
    DiagnogsticsQuery,
    InsertPrescriptionAPI,
    PatientDataAPIInterface,
    PatientPrescriptionFilter,
    PatientREChangesAPI,
    PatientReviewPrescriptionsREQuantityFilter,
    PrescriptionInteractionDataAPI,
} from '@interfaces/patient/patient.interface';
import {
    GetDispensingPlanPetition,
    GetDispensingPlanPreviewPetition,
    PMTreeViewDataAPI,
} from '@interfaces/procs/proc-plans';
import { REReviewPatientsPayload, ShowInvoiceDataAPI, UpdateFarmaticNumberData } from '@interfaces/re-status.interface';
import { CRUDService } from './crud/crud.service';
import { DailyChangesPetition } from '@interfaces/procs/proc-auth-cancel';
import { Utils } from '../../Utils';
import { User } from '@interfaces/user/user.interface';
import { UserRole } from '../../enums/user-role';
import { RoleManagerService } from '@services/role-manager.service';
import { UserType } from '@enums/user-type';

// NOTE: Es posible que este servicio pierda el sentido conforme se arregle la API
@Injectable({
    providedIn: 'root',
})
export class CallsService {

    constructor(
        private _snackBar: MatSnackBar,
        private crud: CRUDService,
        private router: Router,
        private rolesManager: RoleManagerService
    ) { }

    openSnack(
        message: string,
        closeMessage: string = '',
        duration?: number
    ): void {
        this._snackBar.open(message, closeMessage.toUpperCase(), {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: duration ?? 3000,
            panelClass: ['quantum-snackbar']
        });
    }

    openStaticSnack(message: string, closeMessage: string = ''): void {
        this._snackBar.open(message, closeMessage, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    preLogin(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/PreLogin',
            method: 'create'
        });
    }

    loginAPI(loginData: LoginDataAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebService.svc/IniciarSesionUsuario',
            method: 'create',
            data: {
                pwd: loginData.password,
                usr: loginData.user,
                tzo: -60,
                origin: loginData.origin,
            },
        });
    }

    getLoggedUserData(tkn?: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/GetLoggedUserData',
            method: 'create',
            data: {},
            token: tkn
        });
    }

    //CorotaWebServiceReports.svc/GetLastForecastTrayHistoryDates

    getLastForecastTrayHistoryDates(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetLastForecastTrayHistoryDates',
            method: 'create',
            headerType: 'woct',
        });
    }

    buildNextForecastTrayDate(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/BuildNextForecastTrayDate',
            method: 'create',
            headerType: 'woct',
        });
    }

    removeLastForecastTrayHistoryDate(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/RemoveLastForecastTrayHistoryDate',
            method: 'create',
            headerType: 'woct',
        });
    }

    uploadEntityPic(data: FormData): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFileTransfers.svc/UploadEntityPic',
            method: 'create',
            data,
            headerType: 'woct',
        });
    }

    uploadEntityDoc(data: FormData): Observable<any> {
        return this.crud
            .run({
                call: 'CorotaWebServiceFileTransfers.svc/UploadEntityDoc',
                method: 'create',
                data,
                headerType: 'woct',
            })
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    //REPORT API
    previewReport(data: PreviewReportAPI): Observable<APIResponseParsed> {
        const { dn, fr, pts, rp, rpp } = data;
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/PreviewReport',
            method: 'create',
            data: {
                dn,
                fr,
                pts,
                rp,
                rpp,
            },
        });
    }

    renderReport(data: RenderReportAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/RenderReport',
            method: 'create',
            data: {
                dl: data.dl,
                dn: data.dn,
                fmt: data.fmt,
                htmf: data.htmf,
                pts: data.pts,
                rp: data.rp,
                rpp: data.rpp,
                z: data.z,
            },
            //headerType: 'woct',
        });
    }

    //render dispersing plan

    renderDispensingPlan(
        data: RenderDispersinPlanPetition
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/RenderDispensingPlan',
            method: 'create',
            data: {
                rpp: data.rpp,
                pts: data.pts,
                z: data.z,
                htmf: data.htmf,
                fmt: data.fmt,
                dn: data.dn,
                rp: data.rp,
            },
            headerType: 'woct',
        });
    }

    downloadReport(data: DonwloadReportAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/DownloadReport',
            method: 'create',
            data: {
                rpp: data.rpp,
                pts: data.pts,
                fmt: data.fmt,
                dn: data.dn,
                rp: data.rp,
            },
            headerType: 'woct',
        });
    }

    getRepParamsEditDataPharmacologicPlan(
        insId: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataPharmacologicPlan',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataPatDiet(
        insId: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataPatdiet',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataIEF(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataIEF',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataPrescriptionsPE(
        insId: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataPrescriptionsPE',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataNeList(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataNeList',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataCursoClin(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataCursoClin',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    getRepParamsEditDataDiapers(
        distname: '' | null
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataDiapers',
            method: 'create',
            data: {
                distname,
            },
        });
    }

    getRepParamsEditDataPharmalert(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/getRepParamsEditDataPharmalert',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }
    getRepParamsEditDataMedchanges(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataMedchanges',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }
    getRepParamsEditDataActus(insId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceReports.svc/GetRepParamsEditDataSirvi',
            method: 'create',
            data: {
                ins: insId,
            },
        });
    }

    //CorotaWebServiceRA.svc/GetRaReports
    getRaReports(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRA.svc/GetRaReports',
            method: 'create',
            data: {
                jss: jss,
            },
        });
    }

    getRaConfig(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRA.svc/GetRaConfig',
            method: 'create',
        });
    }

    updateRaConfig(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRA.svc/UpdateRaConfig',
            method: 'create',
            data: {
                jss: jss,
            },
        });
    }

    updateRaReportStatus(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRA.svc/UpdateRaReportStatus',
            method: 'create',
            data: {
                jss: jss,
            },
        });
    }

    insertRaOrder(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/InsertRaOrder',
            method: 'create',
            data: {
                jss: jss,
            },
        });
    }

    getFile(file: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFileTransfers.svc/GetContentFile',
            method: 'create',
            data: {
                fileName: file,
            },
        });
    }

    getFarmaciesListAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetFarmaticLinkSelectList',
            method: 'create',
            data: {},
        });
    }

    getInstitutionsListAPI(id: number | null): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionDisplayList',
            method: 'create',
            data: {
                flt: '',
                id: id,
            },
        });
    }

    getProcsReceptions(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/GetRecepImportedDmStats',
            method: 'create',
            data: {
                jss,
            },
        });
    }

    getFarmaticReceps(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/GetFarmaticReceps',
            method: 'create',
            data: {
                jss,
            },
        });
    }

    importFarmaticRecep(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/GetFarmaticReceps',
            method: 'create',
            data: {
                jss,
            },
        });
    }

    getDispensingPlanPreview(
        jss: GetDispensingPlanPreviewPetition
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePM.svc/GetDispensingPlanPreview',
            method: 'create',
            data: jss,
        });
    }

    getDispensingPlan(
        jss: GetDispensingPlanPetition
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePM.svc/GetDispensingPlan',
            method: 'create',
            data: jss,
        });
    }

    getPMTreeViewDat(jss: PMTreeViewDataAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePM.svc/GetPMTreeViewData',
            method: 'create',
            data: {
                instid: jss.instid,
                depid: jss.depid,
                patid: jss.patid,
                admdt: jss.admdt,
                admtm: jss.admtm,
                treelv: jss.treelv,
                dtFrom: jss.dtFrom,
                dtUntil: jss.dtUntil,
                inclNB: jss.inclNB,
            },
        });
    }

    getPMInstitutionData(instituionId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePM.svc/GetPMInstitutionData',
            method: 'create',
            data: {
                instid: instituionId,
            },
        });
    }

    getInstitutionsListByFarmacyIdAPI(
        farmacyId: number,
        json?: InstitutionSelectListAPI
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionSelectList',
            method: 'create',
            data: {
                fl: json?.fl || farmacyId,
                flds: json?.flds ?? 7,
                flt: json?.flt ?? 1, // ??
                sid: json?.sid ?? 'e00', // ??
            },
        });
    }

    selectInstitution(idInstitution: number): Observable<APIResponseParsed | undefined> {
        if (idInstitution == -1) return of(undefined);
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/SelectInstitution',
            method: 'create',
            data: {
                id: idInstitution,
            },
        });
    }

    getInstitutionsListAPIByFilter(
        filter: string
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionDisplayList',
            method: 'create',
            data: {
                flt: filter.toLowerCase(),
                id: null,
            },
        });
    }

    getInstitutionsTypesAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionTypes',
            method: 'create',
        });
    }

    getInstitutionsDepartmentsAPI(
        institutionId: number | null
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionDepartments',
            method: 'create',
            data: {
                ins: institutionId,
            },
        });
    }

    //no se para que sirve, ya que devuelve todo null y no hay ni datos en
    //department
    getNewPatientEditData(
        institutionId: number
    ): Observable<PatientDataAPIInterface> {
        return this.crud
            .run({
                call: 'CorotaWebServicePatient.svc/GetNewPatientEditData',
                method: 'create',
                data: {
                    instid: institutionId,
                },
            })
            .pipe(map((res) => res.payload));
    }

    getPatientsListAPI(shortName: string, filter: string = ''): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/GetPatientDisplayList',
            method: 'create',
            data: {
                ins: shortName,
                pat: null,
                flt: filter,
            },
        });
    }

    getPatientAPI(id: string): Observable<PatientDataAPIInterface> {
        return this.crud
            .run({
                call: 'CorotaWebServicePatient.svc/GetPatientEditData',
                method: 'create',
                data: { id },
            })
            .pipe(map((res) => res.payload));
    }

    updatePatientAPI(data: any): Observable<[{ Column1: number }]> {
        return this.crud
            .run({
                call: 'CorotaWebServicePatient.svc/InsertUpdatePatient',
                method: 'create',
                data: { jss: data },
            })
            .pipe(map((res) => res.payload));
    }

    deleteMedicine(idMedicine: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/DeleteMedicine',
            method: 'create',
            data: { Id: idMedicine },
        });
    }

    deleteInstitution(idInstitution: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/DeleteInstitution',
            method: 'create',
            data: { Id: idInstitution },
        });
    }

    deletePatient(
        idPatient: number,
        force?: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/DeletePatient',
            method: 'create',
            data: {
                Force: force || 0,
                Id: idPatient,
            },
        });
    }
    getPatientsListAPIByFilter(filter: string, shortName: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/GetPatientDisplayList',
            method: 'create',
            data: {
                flt: filter.toLowerCase(),
                pat: null,
                ins: shortName
            },
        });
    }

    getPatientPrescriptionWeek(
        filter: PatientPrescriptionFilter
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/GetPatientPrescriptionsWeek',
            method: 'create',
            data: {
                PatientId: filter.patientId,
                DateFrom: Utils.toMsJSON(filter.dateFrom),//`/Date(${filter.dateFrom.getTime()})/`,
                DateUntil: Utils.toMsJSON(filter.dateUntil)//`/Date(${filter.dateUntil.getTime()})/`,
            },
        });
    }

    getPatientReviewPrescriptionsRE(
        filter: PatientPrescriptionFilter
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRE.svc/GetReReviewPatientPrescriptions',
            method: 'create',
            data: {
                iflg: 1,
                patid: filter.patientId,
                dtFrom: `/Date(${filter.dateFrom.getTime()})/`,
                dtUntil: `/Date(${filter.dateUntil.getTime()})/`,
            },
        });
    }

    getPatientReviewDebtDetail(
        patientId: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRE.svc/GetReReviewPatientDebtDetail',
            method: 'create',
            data: {
                patid: patientId
            },
        });
    }

    updatePatientReviewRE(
        filter: PatientREChangesAPI
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRE.svc/InsertUpdateReReviews',
            method: 'create',
            data: {
                jss: JSON.stringify(filter)
            }
        });
    }

    getPatientReviewPrescriptionsREQuantity(
        filter: PatientReviewPrescriptionsREQuantityFilter
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceRE.svc/GetReReviewPrescriptionsQtySale',
            method: 'create',
            data: {
                jss: JSON.stringify(filter)
            }
        });
    }

    stopPatientPrescription(
        id: number,
        date: Date,
        createNew: boolean
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/StopPrescription',
            method: 'create',
            data: {
                jss: JSON.stringify({
                    Id: id,
                    EndDate: date,
                    CreateNew: createNew ? 1 : 0,
                }),
            },
        });
    }

    deletePatientPrescription(id: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/DeletePrescription',
            method: 'create',
            data: {
                Id: id,
            },
        });
    }

    getPrescriptionById(id: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/GetPrescriptionDataById',
            method: 'create',
            data: {
                Id: id,
            },
        });
    }

    logOverdose(data: OverdoseDataAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceEventLog.svc/LogMedicineOverdosed',
            method: 'create',
            data: { data: JSON.stringify(data) },
        });
    }

    logMedicineIncompatibility(
        data: IncompatibilityDataAPI
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceEventLog.svc/LogMedicineIncompatibility',
            method: 'create',
            data: { data: JSON.stringify(data) },
        });
    }

    logInvalidHalf(data: InvalidHalfDataAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceEventLog.svc/LogMedicineInvalidHalf',
            method: 'create',
            data: { data: JSON.stringify(data) },
        });
    }

    logShowREInvoiceData(data: ShowInvoiceDataAPI): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceEventLog.svc/LogReShowInvoincingData',
            method: 'create',
            data: { data: JSON.stringify(data) },
        });
    }

    getMedicineInteractions(
        data: PrescriptionInteractionDataAPI
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/GetPrescriptionValidationErrors',
            method: 'create',
            data: { jss: JSON.stringify(data) },
        });
    }

    insertMedicinePrescription(
        data: InsertPrescriptionAPI
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServicePatient.svc/InsertUpdatePrescription',
            method: 'create',
            data: { jss: JSON.stringify(data) },
        });
    }

    getDiagnogsticsList(query: DiagnogsticsQuery): Observable<APIResponseParsed> {
        let ofusquedType;
        // switch (query.type) {
        //     case 1:
        //         ofusquedType = 2;
        //         break;
        //     case 0:
        //         ofusquedType = 1;
        //         break;
        // }

        return this.crud.run({
            call: 'CorotaWebServiceCIEs.svc/FindCIEs',
            method: 'create',
            data: {
                srch: query.query.toLowerCase(),
                // srchtyp: ofusquedType,
            },
        });
    }

    uploadDiagnogsticsFile(data: FormData): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceCIEs.svc/UploadCieFile',
            method: 'create',
            data,
            headerType: 'woct',
        });
    }

    getAdministrationRoutes(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineAdminRoutes',
            method: 'create',
            data: {
                forPrescriptions: true,
                activeOnly: false,
                institutionId: 0,
            },
        });
    }

    getMedicinesListAPIDropdown(institutionId: number, filter: string | null = null, activeIngredients: number[] = []): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineList',
            method: 'create',
            data: {
                iobs: true,
                ine: false,
                iro: true,
                inp: false,
                flid: 0,
                insex: institutionId,
                act: false,
                ins: 0,
                flt: filter,
                pas: activeIngredients
            },
        });
    }

    getMedicineFields(medicineId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineFieldsById',
            method: 'create',
            data: {
                mid: Number(medicineId),
                flds: [
                    'AdminRoute',
                    'LimitPerIntake',
                    'LimitPerDay',
                    'DrugId',
                    'DrugIdInvc',
                    'CMS'
                ],
            },
        });
    }

    getMedicinesListAPIByFilter(filter: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineDisplayList',
            method: 'create',
            data: {
                flt: filter.toLowerCase(),
                pat: null,
            },
        });
    }

    insertUpdateMedicine(medicine: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/InsertUpdateMedicine',
            method: 'create',
            data: {
                jss: medicine,
            },
        });
    }

    insertUpdateInstitution(institution: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/InsertUpdateInstitution',
            method: 'create',
            data: {
                jss: institution,
            },
        });
    }

    getInteractionByIDs(
        id: number,
        idSelected: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicinesInteraction',
            method: 'create',
            data: {
                mid1: id,
                mid2: idSelected,
            },
        });
    }

    getInteractionTextByID(idText: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineInteractionText',
            method: 'create',
            data: {
                tid: idText,
            },
        });
    }

    getEpigraphText(
        idMedicine: number,
        idEpigraph: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineEpigrafeText',
            method: 'create',
            data: {
                MedicineId: idMedicine,
                EpigrafeId: idEpigraph,
            },
        });
    }

    getMedicineByID(id: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineEditData',
            method: 'create',
            data: {
                id,
            },
        });
    }

    getInstitutionByID(id: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceInstitution.svc/GetInstitutionEditData',
            method: 'create',
            data: {
                id,
            },
        });
    }

    getMedicineAdminRoutes(
        activeOnly: boolean,
        forPrescription: boolean,
        instituionId: number
    ): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineAdminRoutes',
            method: 'create',
            data: {
                activeOnly: activeOnly,
                forPrescriptions: forPrescription,
                institutionId: instituionId,
            },
        });
    }

    getMedicinesListAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineDisplayList',
            method: 'create',
            data: {
                flt: '',
                id: null,
            },
        });
    }

    getMedicineList(institutionId: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetMedicineList',
            method: 'create',
            data: {
                iobs: true,
                ine: false,
                iro: false,
                inp: true,
                flid: 0,
                insex: 0,
                act: true,
                ins: institutionId,
                flt: null
            },
        });
    }

    getActiveIngredientsList(filter: string | null = null): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetPAS',
            method: 'create',
            data: {
                flt: filter
            },
        });
    }

    getCompatibleActiveIngredientsList(activeIngredients: number[]): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedicine.svc/GetCompatiblePAS',
            method: 'create',
            data: {
                pas: activeIngredients
            },
        });
    }

    getInstitutionReData(institutionId: number): Observable<APIResponseParsed> {
        return this.crud
            .run({
                call: 'CorotaWebServiceRE.svc/GetInstitutionReData',
                method: 'create',
                data: { ins: institutionId },
            })
    }

    getReReviewPatients(data: REReviewPatientsPayload): Observable<APIResponseParsed> {
        return this.crud
            .run({
                call: 'CorotaWebServiceRE.svc/GetReReviewPatients',
                method: 'create',
                data
            })
    }

    updateFarmaticNumber(data: UpdateFarmaticNumberData): Observable<APIResponseParsed> {
        return this.crud
            .run({
                call: 'CorotaWebServiceRE.svc/InsertUpdateFarmaticNumber',
                method: 'create',
                data
            })
    }

    getReReviewInstitutions(farmaticLinkId: number): Observable<APIResponseParsed> {
        return this.crud
            .run({
                call: 'CorotaWebServiceRE.svc/GetReReviewInstitutions',
                method: 'create',
                data: {
                    farmaticLinkId
                },
            })
    }

    getDailyChange(data: DailyChangesPetition): Observable<APIResponseParsed> {
        const { fl, ins, de, a } = data;
        return this.crud.run({
            call: 'CorotaWebServiceDailyChanges.svc/GetDailyChanges',
            method: 'create',
            data: {
                fl,
                ins,
                de,
                a,
            },
        });
    }

    saveDailyChangesMarks(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceDailyChanges.svc/SaveDailyChangesMarks',
            method: 'create',
            data: {
                jss,
            },
        });
    }

    getThirdPartyDrugIdMap(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/GetThirdPartyDrugIdMap',
            method: 'create',
            data: {
                jss,
            },
        });
    }

    handle200Error(response: APIResponseParsed) {
        // TODO: Espero sea temporal
        console.log('handle200Error', response.payload.ErrorCode);
        switch (response.payload.ErrorCode) {
            case 250:
            case 266:
                console.log('handle200Error');
                this.router.navigate(['']);
                break;

            default:
                break;
        }
    }

    getFarmaticVentasOffDone(PeriodMonth: number, PeriodYear: number, TipoVentaOff: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/GetFarmaticVentasOffDone',
            method: 'create',
            data: {
                jss: JSON.stringify({
                    PeriodMonth,
                    PeriodYear,
                    TipoVentaOff
                })
            }
        });
    }

    insertDebtsVentasOff(PeriodMonth: number, PeriodYear: number, InstitutionList: string, NombreVend: string, TipoVentaOff: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/InsertDebtsVentasOFF',
            method: 'create',
            data: {
                jss: JSON.stringify({
                    PeriodMonth,
                    PeriodYear,
                    InstitutionList,
                    NombreVend,
                    TipoVentaOff
                })
            }
        });
    }

    testValidRE(pid: number, re: string): Observable<any> {
        return this.crud
            .run({
                call: 'CorotaWebServiceRE.svc/TestValidRE',
                method: 'create',
                data: {
                    pid,
                    re
                },
            })
            .pipe(map((res) => res.payload));
    }

    getUserDisplayList(): Observable<Array<User>> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/GetUserDisplayList',
            method: 'create',
            data: {
                flt: '',
                id: null
            }
        }).pipe(map((res) => {
            const users = res.payload as Array<User>;
            users.forEach(u => {
                u.exdt = new Date(u.exdt);
                u.crdt = new Date(u.crdt);
                //@ts-ignore
                u.roles = this.rolesManager.translateCorotaRoles(u.roles.map(r => 'Corota' + r));
            })

            return users;
        }));
    }

    getUserEditData(id: number): Observable<{ locales: Array<{ lid: number, nam: string }>, usr: User }> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/GetUserEditData',
            method: 'create',
            data: {
                id
            }
        }).pipe(map((res) => {
            const locales = res.payload.locales as any;
            const usr = res.payload.usr as User;

            //@ts-ignore
            usr.roles = this.rolesManager.translateCorotaRoles(usr.roles);
            usr.exdt = new Date(usr.exdt);
            usr.crdt = new Date(usr.crdt);
            usr.updt = new Date(usr.updt);

            return {
                locales,
                usr
            }
        }));
    }

    updateUser(uid: number, nombre: string, genero: 'M' | 'F', lid: number, rol: string[], institutions?: number[]) {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/ActualizarUsuario',
            method: 'create',
            data: {
                uid,
                fulln: nombre,
                gnd: genero == 'M' ? 1 : 2,
                locid: lid,
                perm: 0,
                roles: rol,
                institutions
            }
        });
    }

    registerUser(nombre: string, username: string,  genero: 'M' | 'F', lid: number, rol: string[], institutions?: number[], inst?: string | null): Observable<{uid: number, tempPwd: string}> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/RegistrarUsuario',
            method: 'create',
            data: {
                fulln: nombre,
                usr: username,
                gnd: genero == 'M' ? 1 : 2,
                locid: lid,
                perm: 0,
                roles: rol,
                institutions,
                inst
            }
        }).pipe(map((res) => {
            return {
                uid: Number(res.payload[0].Column1),
                tempPwd: res.payload[0].tempPwd
            };
        }));
    }

    deleteUser(uid: number) : Observable<boolean> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/EliminarUsuario',
            method: 'create',
            data: {
                uid
            }
        }).pipe(map((res) => {
            return Boolean(res.payload);
        }));
    }

    resetUserPassword(uid: number) : Observable<string> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/ResetearClaveDeUsuario',
            method: 'create',
            data: {
                uid
            }
        }).pipe(map((res) => {
            return res.payload.tempPwd;
        }));
    }

    changeUserPassword(tkn: string, newp: string, oldp: string, uid: number) : Observable<{correct: boolean, msg: any}> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/CambiarClaveDeUsuario',
            method: 'create',
            data: {
                newp,
                oldp,
                uid
            },
            token: tkn
        }).pipe(map((res) => {
            return {
                correct: true,
                msg: res.payload.updateDate
            };
        }), catchError((err, caught) => {
            return of({
                correct: false,
                msg: err
            });
        }));
    }

    getStatisticsOccupation(id: number) : Observable<{
        Actual: number,
        Distribucion: {
            Residencia: number,
            Hospital: number,
            Vacaciones: number,
            OtroCentro: number,
            Otros: number
        },
        Evolucion: {[key: number]: string}
    }> {
        return this.crud.run({
            call: 'CorotaWebServiceStatistics.svc/GetOccupation',
            method: 'create',
            data: {
                id
            }
        }).pipe(map((res) => {
            return res.payload;
        }));
    }

    getStatisticsMed(id: number) : Observable<{
        IEF: number,
        IEFByMonth: {[key: number]: number | null},
        Indice: number,
        RatioVsHiperpolimed: number,
        RatioVsPoblacion: number
    }> {
        return this.crud.run({
            call: 'CorotaWebServiceStatistics.svc/GetMed',
            method: 'create',
            data: {
                id
            }
        }).pipe(map((res) => {
            return res.payload;
        }));
    }

    getStatisticsDebt(id: number) : Observable<{
        Deuda: number
    }> {
        return this.crud.run({
            call: 'CorotaWebServiceStatistics.svc/GetDebt',
            method: 'create',
            data: {
                id
            }
        }).pipe(map((res) => {
            return res.payload;
        }));
    }

    getPMReasonType() {
        return this.crud.run({
            call: 'CorotaWebServicePM.svc/GetPMReasonType',
            method: 'create',
            data: {}
        }).pipe(map((res) => {
            return res.payload;
        }));
    }

    insertForecastOrder(jss: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceFarmatic.svc/InsertForecastOrder',
            method: 'create',
            data: {
                jss: jss,
            },
        });
    }

    getRegisterMedicationPatients(institutionId: number, depList: number[], hourList: string[], targetDay: string): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedRegister.svc/GetMedRegPatientPrescriptionList',
            method: 'create',
            data: {
                institutionId,
                depList,
                hourList,
                targetDay
            },
        });
    }
    getRegisterPatientControllerData(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedRegister.svc/GetMedRegPatientControllerData',
            method: 'create',
            data: {},
        });
    }
    updateMedicationRegisterPatientPrescriptionList(depList: number[], institutionId: number, data: any): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceMedRegister.svc/UpdateMedRegPatientPrescriptionList',
            method: 'create',
            data: {
                depList,
                institutionId,
                jsonObj: JSON.stringify(data)
            },
        });
    }

    getQlikUsersAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/GetUsers',
            method: 'create',
        });
    }
    getQlikInstitutionListByCAAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/GetInstitutionListByCA',
            method: 'create',
        });
    }
    getQlikUserInstitutionsAPI(userId: number, userType: UserType = UserType.Quantum): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/GetUserInstitutions',
            method: 'create',
            data: {
                userId,
                userType
            }
        });
    }
    saveQlikUserInstitutionsAPI(userId: number, institutions: number[], userType: UserType = UserType.Quantum): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/SaveUserInstitutions',
            method: 'create',
            data: {
                userId,
                institutions,
                userType
            }
        });
    }
    toggleQlikAccessAPI(userId: number, value: boolean, userType: UserType = UserType.Quantum): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/ToggleAccess',
            method: 'create',
            data: {
                userId,
                value,
                userType
            }
        });
    }

    createBiUserAPI(userName: string, password: string, biAccess: boolean): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/CrearUsuario',
            method: 'create',
            data: {
                userName,
                password,
                biAccess
            }
        });
    }
    updateBiUserAPI(id: number, userName: string, password: string, biAccess: boolean): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/ModificarUsuario',
            method: 'create',
            data: {
                id,
                userName,
                password,
                biAccess
            }
        });
    }
    deleteBiUserAPI(id: number): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceQlik.svc/BorrarUsuario',
            method: 'create',
            data: {
                id
            }
        });
    }
    getQlikOTTAPI(): Observable<APIResponseParsed> {
        return this.crud.run({
            call: 'CorotaWebServiceUsers.svc/GetOneTimeToken',
            method: 'create',
        });
    }
}
