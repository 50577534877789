<div class="re-patient-container">



   <div class="namePerson">
        <!-- Image -->
        <!-- <lazy-img [source]="src" [alt]="title"></lazy-img> -->
        <div class="col-12 right name">{{ patient.PatientName }}</div>
        <div class="col-12 right"> {{ patient.PatientNumber }} <span *ngIf="patient.Deuda" matTooltip="Deuda" (click)="getDebtDetail($event, patient)">{{patient.Deuda}}</span></div>

        <div #debtDetail 
            class="debt-detail"
            *ngIf="patientIdCurrentDebtDetail === patient.PatientId"
            [ngStyle]="{
                'top': patientCurrentDebtDetailPosition.top,
                'left': patientCurrentDebtDetailPosition.left
            }"
        >
            <app-re-patient-debt-detail [patient]="patient"></app-re-patient-debt-detail>
        </div>

        <mat-icon class="close" (click)="close.emit()">
            close
        </mat-icon>

        <div *ngIf="patient.hasChanges" class="re-patient-changes">
            <div class="text">
                <mat-icon>error_outline</mat-icon>
                <span>Hay cambios sin guardar</span>
            </div>
            <div class="buttons">
                <mat-icon matTooltip="Deshacer cambios" (click)="onClickUndo()">undo</mat-icon>
                <mat-icon matTooltip="Guardar cambios" (click)="onClickSave()">save</mat-icon>
            </div>
        </div>
    </div> 
   
    <div class="container-body">
        
        <div class="re-patient-info">
            <div class="re-patient-info__daily-changes">
                <div class="title">CAMBIOS DIARIOS</div>
                <div class="gridInfoDaily">
                    <ng-container *ngIf="getDailyChanges(initialPatient).length">
                        <div *ngFor="let day of getDailyChanges(initialPatient)"
                            class="tag"
                            [ngClass]="{
                                alta: [1,2,8].includes(day.ReportReasonCode),
                                blocked: [3].includes(day.ReportReasonCode),
                                exitus: [4].includes(day.ReportReasonCode),
                                re: [5,6,7].includes(day.ReportReasonCode),
                            }"
                            matTooltip="{{day.ReportReasonCode !== 3 ? (day.Fecha | date: 'dd/MM/yyyy') : (day.RE || day.RE2)}}"
                            (click)="onClickDailyChangeTag(day)"
                        >
                            {{getTag(day)}}
                        </div>
                    </ng-container>
                </div>
               
            </div>
            <div class="re-patient-info__re">
    
                <div class="re-patient-info__re__chronic-list">
                    <div class="title">
                        RE CRÓNICA 
                        <div class="buttons">
                            <mat-icon class="eye"
                                *ngIf="hiddenRecipes()"
                                matTooltip="Mostrar todas las RE"
                                (click)="showDisabledRecipes = !showDisabledRecipes"
                            >
                                remove_red_eye
                            </mat-icon>
                            
                            <mat-icon (click)="newEditRE()">add</mat-icon>
                        </div>
                    </div>
    
                    <ng-container *ngFor="let re of chronicRE">
                        <div  *ngIf="!re.Forget || showDisabledRecipes" class="recipe">
                            <span 
                                class="recipe-number chronic"
                                [ngClass]="{
                                    pendingReMod: !re.REFix && dailyChangeReModActive(re),
                                    edited: re.REFix,
                                    disabled: re.Forget
                                }"
                                [matTooltip]="(re.InputDate | date: 'dd/MM/yyyy') + getReTooltipAuxData(re)"
                            >
                                {{re.REFix || re.RE}}
                            </span>
                            <div class="flex-end">
                                <mat-icon color="primary"
                                    class="button butonedit"
                                    matTooltip="Editar RE"
                                    [ngClass]="{'nextDispense': re.NextDispenseDate && !re.Forget}"
                                    (click)="newEditRE(re)"
                                >
                                    edit
                                </mat-icon>
                                <mat-icon color="primary"
                                    class="button buttonarrow1"
                                    matTooltip="Covertir en RE aguda"
                                    (click)="re.IsRE2 = true; onChanges()"
                                >
                                    arrow_forward_ios
                                </mat-icon>
                            </div>
                        </div>
                    </ng-container>
                    <!-- <button mat-flat-button
                        class="add-re-button"
                        color="primary"
                        (click)="newEditRE()"
                    >
                        Agregar RE
                    </button> -->
                   
                </div>
                <div class="re-patient-info__re__severe-list">
                    <div class="title">RE AGUDA 

                    <div class="buttons">
                        <mat-icon (click)="newEditRE()">add</mat-icon>
                    </div>
                    </div>
                    <ng-container *ngFor="let re of severeRE">
                        <div  *ngIf="!re.Forget || showDisabledRecipes" class="recipe">
                            <div class="flex-start">
                                <mat-icon color="primary"
                                    class="button buttonarrow"
                                    matTooltip="Covertir en RE crónica"
                                    (click)="re.IsRE2 = false; onChanges()"
                                >
                                    arrow_back_ios
                                </mat-icon>
                                <span 
                                    class="recipe-number severe"
                                    [ngClass]="{
                                        pendingReMod: !re.REFix && dailyChangeReModActive(re),
                                        edited: re.REFix,
                                        disabled: re.Forget
                                    }"
                                    [matTooltip]="(re.InputDate | date: 'dd/MM/yyyy') + getReTooltipAuxData(re)"
                                >
                                    {{re.REFix || re.RE}}
                                </span>
                            </div>
                            <mat-icon color="primary"
                                class="button butonedit"
                                matTooltip="Editar RE"
                                (click)="newEditRE(re)"
                            >
                                edit
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- <button mat-flat-button
                        class="add-re-button"
                        color="primary"
                        (click)="newEditRE()"
                    >
                        Agregar RE
                    </button> -->
                </div>
            </div>
    
            <div class="re-patient-info__data">
                <div class="title">Datos Generales</div>
                <div class="container-fields content">
                    <mat-form-field class="field col-6">
                        <div class="label">NF</div>
                        <input matInput type="number"
                            [(ngModel)]="farmaticNumber"
                            (ngModelChange)="onChanges()"
                        >
                    </mat-form-field>
                    <mat-form-field  class="field col-6">
                        <div class="label">DNI</div>
                        <input matInput
                            type="text"
                            [(ngModel)]="dni"
                            (ngModelChange)="onChanges()"
                        >
                    </mat-form-field>
                </div>
         
                <div class="container-checkbox">
                    <mat-checkbox [(ngModel)]="problematic" (ngModelChange)="onChanges()" class="col-3">Problemático</mat-checkbox>
                    <mat-checkbox [(ngModel)]="externalCap" (ngModelChange)="onChanges()" class="col-3"> CAP externo</mat-checkbox>
                    <mat-checkbox [(ngModel)]="reviewed" (ngModelChange)="onChanges(); updateReviewedDate($event)"  class="col-6">Revisado <span *ngIf="reviewedDate">({{reviewedDate | date: 'dd/MM/yyyy'}})</span></mat-checkbox>
                </div>
             
            </div>
    
            <div class="re-patient-info__notes">
                <div class="title">Observaciones</div>
                <mat-form-field class="col-12">
                    <div class="label">Notas</div>
                    <textarea matInput [(ngModel)]="notes" (ngModelChange)="onChanges()"></textarea>
                </mat-form-field>
            </div>
        </div>
    
    
        <div class="contenedor-expansionPanel" >
            <mat-expansion-panel  [expanded]="true" >
                <!-- HEADER EXPANSION PANEL -->
                <mat-expansion-panel-header>
                  <div class="title" >
                    PRESCRIPCIONES MEDICAS
                  </div>
                </mat-expansion-panel-header>
                 <!-- CONTENT EXPANSION PANEL -->
                <div class="contenedor-expansionpanel">
                
                    <div class="re-patient-prescriptions">
                        <table mat-table [dataSource]="rows">
                            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                        
                                <th mat-header-cell *matHeaderCellDef
                                    [style.textAlign]="column.align"
                                >
                                    <ng-container >
                                        <span>
                                            {{column.header}}
                                        </span>
                                    </ng-container>
        
                                </th>
                                <td mat-cell *matCellDef="let row"
                                    [class]="column.columnDef"
                                    [style.textAlign]="column.align"
                                >
                                    <ng-container [ngSwitch]="column.columnDef">
                        
                                        <span *ngSwitchCase="'DrugName'" class="name">
                                            <span [ngClass]="{'no-stock': row.NoStock && !row.allowShowNoStock}"> {{ row['DrugName'] }}</span>
                                            <mat-icon *ngIf="row.NoStock && !row.allowShowNoStock">info</mat-icon>
                                            <mat-icon *ngIf="row.ForcedPayment">euro_symbol</mat-icon>
                                        </span>
        
                                        <span *ngSwitchCase="'IssueType'">
                                            <app-re-issues
                                                [issueType]="row['IssueType']"
                                                (issueChange)="onIssueChange($event, row)"
                                            ></app-re-issues>
                                        </span>
        
                                        <span *ngSwitchCase="'actions'" class="actionsPrescriptions">
                                            <ng-container>
                                                <button 
                                                    mat-icon-button
                                                    color="primary"
                                                    [disabled]="!row.IssueType"
                                                    [ngClass]="{ 'has-note': row.IssueNote}"
                                                    [matTooltip]="row.IssueNote || 'Notas generales'"
                                                    (click)="onNoteClick(row)"
                                                >
                                                    <mat-icon>chat_bubble</mat-icon>
                                                </button>
                                            </ng-container>
                                                
                                            <ng-container *ngIf="row.IssueType >= 128">
                                                <button 
                                                    mat-icon-button 
                                                    color="primary"
                                                    class="m-1"
                                                    matTooltip="Anexos tratamiento"
                                                    (click)="onPrintClick()"
                                                >
                                                    <mat-icon>print</mat-icon>
                                                </button>
                                            </ng-container>
                                            
                                        </span>
                        
                                        <span *ngSwitchCase="'PrescrStartDate'" [matTooltip]="column.tooltip">
                                            {{ row[column.columnDef] | date: 'd/M/y' }}
                                        </span>
                                        <span *ngSwitchCase="'IssueDate'" [matTooltip]="column.tooltip">
                                            {{ row[column.columnDef] | date: 'd/M/y' }}
                                        </span>
        
                                        <span *ngSwitchCase="'Boxes'" [matTooltip]="column.tooltip">
                                            {{ row[column.columnDef] > 0 ? (row[column.columnDef] | number : '1.0-2') : 'N/D' }}
                                        </span>

                                        <span *ngSwitchCase="'QtySale'" [matTooltip]="column.tooltip">
                                            {{ row[column.columnDef] > 0 ? (row[column.columnDef] | number : '1.0-2') : 'N/D' }}
                                        </span>
        
                                        <!-- TODO: -->
                                        <span *ngSwitchCase="'RecurrencePattern'" class="tags-container">
                                            <ng-container *ngFor="let tag of getPatternTags(row)">
                                                <span
                                                    class="tag"
                                                    [matTooltip]="tag.tooltip"
                                                    [class]="tag.class"
                                                >
                                                    {{ tag.label }}
                                                </span>
                                            </ng-container>
                                        </span>
                                        <!--  -->
        
                                        <span *ngSwitchDefault [matTooltip]="column.tooltip">
                                            {{ row[column.columnDef] }}
                                        </span>
                                    </ng-container>     
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnNames;"
                                [ngClass]="{
                                    'no-blister': row.NoBlister === 1
                                }"
                            ></tr>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        
        </div>
    
    </div>

</div>

