import { MedicineEditComponent } from '@shared/medicine-edit/medicine-edit.component';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
    medicineFilterSelect,
    medicineFilterSelected,
    routeMedicines } from '@services/mocks/medicine';
import { ModalService } from '@services/modal.service';
import { MedicineAPI, MedicineForm } from '@interfaces/medicine/medicine.interface';
import { skipWhile, take } from 'rxjs';
import { MedicinesService } from '@services/medicines.service';
import { Utils } from '@json/src/app/Utils';
import { Permission, PermissionType } from '@json/src/app/enums/PermissionType';
import { CardsComponent } from './cards/cards.component';
import { LoadingService } from '@services/loading.service';

import { Medicine } from '@interfaces/medicine/medicine.interface';
import { FilterOption } from '@interfaces/filter.interface';
import { ListsComponent } from './lists/lists.component';
import { CallsService } from '@services/api/calls.service';
import { AuthService } from '@services/auth.service';
import { InstitutionService } from '@services/institution.service';

import { CustomButtonFilterStatus } from '@enums/custom-button-filter-status';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-medicines-quantum',
    templateUrl: './medicines-quantum.component.html',
    styleUrls: ['./medicines-quantum.component.scss'],
})
export class MedicinesQuantumComponent implements OnInit {
    public href: string = '';
    public route: string = routeMedicines;
    public nameButton: string = 'medicineSaveButton';

    medicine?: MedicineEditComponent;
    public static currentData: Medicine[] = [];
    private sub: any = {};
    nextMed?: number = undefined;
    prevMed?: number = undefined;
    public Utils = Utils;
    public Permission = Permission;
    public PermissionType = PermissionType;

    public medicineFilters = JSON.parse(JSON.stringify(medicineFilterSelect));
    public selectedFilters = medicineFilterSelected;

    private medicines: Medicine[] = [];
    guiaPreferente?: string = undefined;
    private filteredMedicines: Medicine[] = [];

    public totalElements: number | undefined = undefined;
    public currentElementIndex: number | undefined = undefined;

    public resultsRefreshed: boolean = false;

    public filteredResults: any[] = [];

    cardsRef: CardsComponent;

    public initLoading: boolean = false;

    public allowFgp: boolean = false;

    public paFilter: FormControl = new FormControl();

    constructor(
        private modalService: ModalService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private medicinesService: MedicinesService,
        private loadingService: LoadingService,
        private calls: CallsService,
        private institutionService: InstitutionService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService,
    ) { }

    ngOnInit(): void {
        this.medicinesService.clearStoredData();
        this.loadingService.start('Cargando Vademecum...');
        this.initLoading = true;
        this.getMedicines();
        this.allowFgp = this.institutionService.getCurrentInstitutionAllowFgp();
        this.titleService.setTitle(`Vademecum`)
        this.gaService.pageView(`${window.location.href}`, `Vademecum`);
    }

    getMedicines() {
        this.medicinesService.getMedicines();

        this.medicinesService.medicineList
        .pipe(
            skipWhile((medicines) => medicines === undefined),
            take(1)
        )
        .subscribe((medicines) => {
            if (medicines != undefined) {
                // Solo se ve la guia preferente si es farmacia, no es nulo y no está vacia
                if (!AuthService.decodedToken.corotaIsFarmacia && medicines.GuiaPreferente != undefined && medicines.GuiaPreferente.trim().length > 0)
                    this.guiaPreferente = medicines.GuiaPreferente;

                this.medicines = medicines.medicines
                .filter((m) => {
                    // Filter FGP medicines if not allowed
                    if ( this.institutionService.getCurrentInstitutionAllowFgp() ) {
                        return true;
                    } else {
                        return !m.FGP;
                    }
                })
                .map((p) =>
                    this.medicinesService.transformMedicineAPItoApp(p)
                );

                // Hide CF codes on quantum
                this.medicines.forEach((m) => {
                    m.cf1 = '';
                    m.cf2 = '';
                });

                this.filterByConfig(
                    {
                        alert: false,
                        custom: false,
                        filter: "",
                        forceFilterRequest: false,
                        search: "",
                        institution: [],
                        select: []
                    },
                    this.medicines
                )

                if (this.cardsRef) {
                    this.cardsRef.currentData = this.filteredMedicines;
                    this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
                    this.cardsRef.loading = false;
                }

                this.loadingService.stop();
                this.initLoading = false;
            } else {
                this.medicinesService.getMedicines();
            } // Hay que llamar si nos llega vacío (si se entra directamente llega vacio)
        });
    }

    onOperationBarAction($event: MatButtonToggleChange) {
        switch( $event.value ) {
            case 'first': this.gotToByIndex(0); break;
            case 'last': this.gotToByIndex(this.filteredMedicines.length - 1); break;
            case 'next': this.siguiente(); break;
            case 'prev': this.anterior(); break;
            case 'delete': this.borrar(); break;
            default: this.changeView($event);
        }
    }

    changeView(event: MatButtonToggleChange): void {
        this.modalService.changeView(event, this.route, this.nameButton);
    }

    onActivate(componentRef: any) {
        if (componentRef instanceof MedicineEditComponent) {
            this.resultsRefreshed = false;
            this.medicine = componentRef;
            this.cdr.detectChanges();

            this.sub.medicineList?.unsubscribe();

            if (componentRef.medicineId == null) {
                componentRef.onMedicineId = (id) => {
                    this.updateControlButtons(id, this.filteredMedicines);
                }
            }
            else {
                this.updateControlButtons(componentRef.medicineId, this.filteredMedicines);
            }

            componentRef.onDestroy = () => {
                this.sub.medicineList?.unsubscribe();
            };
            componentRef.onMedicineUpdated = (medicine: MedicineForm, medicineId: string | null) => {
                this.medicinesService.getMedicines().then(() => {
                    /*
                     * Manually update local medicine lists
                     */

                    const updatedMedicine = this.medicinesService.medicineList.getValue()?.medicines.find((m) => m.id == Number(medicineId));
                
                    let medicineIndex: number = this.medicines.findIndex((m: Medicine) => m.id === Number(medicineId));
                    let filteredMedicineIndex: number = this.filteredMedicines.findIndex((m: Medicine) => m.id === Number(medicineId));
                    
                    if ( medicineIndex !== -1 ) {
                        // @ts-ignore
                        const aux = this.medicinesService.transformMedicineAPItoApp(updatedMedicine);

                        this.medicines[medicineIndex] = aux;
                        this.filteredMedicines[filteredMedicineIndex] = aux;
                    }
                });
            }
        } else if (componentRef instanceof CardsComponent) {
            this.cardsRef = componentRef;
            this.cardsRef.currentData = this.filteredMedicines;
            this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
            this.cardsRef.loading = false;
        }
    }

    updateControlButtons(id: string | null, data: Medicine[]) {
        if (id != null) {
            const index = data.findIndex(v => String(v.id) == id);

            this.totalElements = data.length;
            this.currentElementIndex = index + 1;   // Because array starts on 0

            if (index >= 0) {
                this.nextMed = data[index + 1]?.id;
                this.prevMed = data[index - 1]?.id;
                this.cdr.detectChanges();
            }
        }
    }

    anterior(evt?: MatButtonToggleChange) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        // this.router.navigate(["/global-eye/welcome"]).then((r) => {
        //     if (r) {
                this.medicine?.ngOnDestroy();
                this.router.navigate([routeMedicines, 'edit', this.prevMed], {
                    queryParams: queryParams,
                    state: {
                        ignore: false
                    }
                }).then(() => this.medicine?.ngOnInit())
        //     }
        // });
        if (evt != undefined) evt.source.checked = false;
    }

    siguiente(evt?: MatButtonToggleChange) {

        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        // this.router.navigate(["/global-eye/welcome"]).then((r) => {
        //     if (r) {
                this.medicine?.ngOnDestroy();
                this.router.navigate([routeMedicines, 'edit', this.nextMed], {
                    queryParams: queryParams,
                    state: {
                        ignore: false
                    }
                }).then(() => this.medicine?.ngOnInit())
        //     }
        // });
        if (evt != undefined) evt.source.checked = false;
    }

    gotToByIndex(index: number, evt?: MatButtonToggleChange) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        this.medicine?.ngOnDestroy();
        this.router.navigate([routeMedicines, 'edit', this.filteredMedicines[index].id], {
            queryParams: queryParams,
            state: {
                ignore: false
            }
        }).then(() => this.medicine?.ngOnInit())

        if (evt != undefined) evt.source.checked = false;
    }

    borrar(evt?: MatButtonToggleChange) {
        if (evt != undefined) evt.source.checked = false;
        this.modalService.openModalCallback(() => {
            this.loadingService.start("Eliminando...");
            this.calls.deleteMedicine(Number(this.medicine?.medicineId)).subscribe({
                next: (data) => {
                    this.loadingService.stop()
                    this.siguiente(undefined);
                },
                complete: () => this.loadingService.stop()
            });
        }, "¿Está seguro de que desea eliminar la medicina?", "Eliminar medicina", false)
    }

    showArrows() {
        return this.router.url.includes("/medicines/edit");
    }
    onListPage() {
        return this.router.url.includes("/medicines/cards") || this.router.url.includes("/medicines/list");
    }
    onEditPage() {
        return this.router.url.includes("/medicines/edit");
    }

    onFilterResultSelected($event: any) {
        const params = new URLSearchParams(location.search);
        let queryParams: any = {};
        params.forEach((value, key) => {
            queryParams[key] = value;
        });

        this.medicine?.ngOnDestroy();
        this.router.navigate([`${this.route}/edit/${$event}`], {
            queryParams: queryParams,
            state: {
                ignore: false
            }
        }).then(() => this.medicine?.ngOnInit())
    }

    filter($event: any) {
        if ( $event.filter.trim().length > 0 || $event.forceFilterRequest ) {
            this.sub.filter?.unsubscribe();

            // Request patients by filter
            this.medicinesService.getMedicinesByFilter($event.filter);

            this.sub.filter = this.medicinesService.medicineList
            .subscribe((p) => {
                /*
                 * Patients by filter query
                 */
                if (p != undefined) {
                    this.medicines = p.medicines.map((item) => this.medicinesService.transformMedicineAPItoApp(item));
                    this.filterByConfig($event, this.medicines);
                }
            })
        } else {
            this.filterByConfig($event, this.medicines);
        }
    }

    filterByConfig(filters: any, medicines: Medicine[]) {
        /*
         * Filter patients by selected filters
         */
        const cobertFilter = filters.select.find((item: FilterOption) => item.id === 'cobert') ? true : false;
        const alzheimerFilter = filters.select.find((item: FilterOption) => item.id === 'alzheimer') ? true : false;
        const noEmblistableFilter = filters.select.find((item: FilterOption) => item.id === 'no-emblistable') ? true : false;
        const unitdoseFilter = filters.select.find((item: FilterOption) => item.id === 'unitdose') ? true : false;
        const raFilter = filters.select.find((item: FilterOption) => item.id === 'ra') ? true : false;
        const mecFilter = filters.select.find((item: FilterOption) => item.id === 'mec') ? true : false;
        const roFilter = filters.select.find((item: FilterOption) => item.id === 'ro') ? true : false;
        const hospitalUseFilter = filters.select.find((item: FilterOption) => item.id === 'hospital-use') ? true : false;
        const noInventaryFilter = filters.select.find((item: FilterOption) => item.id === 'no-inventary') ? true : false;
        const dangerGroupFilter1 = filters.select.find((item: FilterOption) => item.id === 'dangerous-group-1') ? true : false;
        const dangerGroupFilter2 = filters.select.find((item: FilterOption) => item.id === 'dangerous-group-2') ? true : false;
        const dangerGroupFilter3 = filters.select.find((item: FilterOption) => item.id === 'dangerous-group-3') ? true : false;
        const noPrescriptionFilter = filters.custom;
        const obsoleteFilter = filters.custom2;
        const fgpFilter = filters.custom3;

        let cobertMedicines = medicines.filter(m => this.medicinesService.isCobert(m.flag));
        let alzheimerMedicines = medicines.filter(m => this.medicinesService.isAlzheimer(m.flag));
        let noEmblistableMedicines = medicines.filter(m => this.medicinesService.isNoBlister(m.flag));
        let unitdoseMedicines = medicines.filter(m => this.medicinesService.isUnidosis(m.flag));
        let raMedicines = medicines.filter(m => this.medicinesService.isRA(m.flag));
        let mecMedicines = medicines.filter(m => this.medicinesService.isMEC(m.flag));
        let roMedicines = medicines.filter(m => this.medicinesService.isR0(m.flag));
        let hospitalUseMedicines = medicines.filter(m => this.medicinesService.isHospitalUse(m.flag));
        let noInventaryMedicines = medicines.filter(m => this.medicinesService.isOutStock(m.flag));
        let dangerGroupMedicines1 = medicines.filter(m => m.dangerGroup === 1);
        let dangerGroupMedicines2 = medicines.filter(m => m.dangerGroup === 2);
        let dangerGroupMedicines3 = medicines.filter(m => m.dangerGroup === 3);

        if ( cobertFilter || alzheimerFilter || noEmblistableFilter || unitdoseFilter || 
            raFilter || mecFilter || roFilter || hospitalUseFilter || noInventaryFilter ||
            dangerGroupFilter1 || dangerGroupFilter2 || dangerGroupFilter3 ) {
            
            this.filteredMedicines = [];

            if ( cobertFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...cobertMedicines])];
            if ( alzheimerFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...alzheimerMedicines])];
            if ( noEmblistableFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...noEmblistableMedicines])];
            if ( unitdoseFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...unitdoseMedicines])];
            if ( raFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...raMedicines])];
            if ( mecFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...mecMedicines])];
            if ( roFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...roMedicines])];
            if ( hospitalUseFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...hospitalUseMedicines])];
            if ( noInventaryFilter )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...noInventaryMedicines])];
            if ( dangerGroupFilter1 )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...dangerGroupMedicines1])];
            if ( dangerGroupFilter2 )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...dangerGroupMedicines2])];
            if ( dangerGroupFilter3 )
                this.filteredMedicines = [...new Set([...this.filteredMedicines, ...dangerGroupMedicines3])];
        } else {
            this.filteredMedicines = medicines;
        }

        if ( noPrescriptionFilter === CustomButtonFilterStatus.Include ) {
            this.filteredMedicines = [...new Set([...this.filteredMedicines, ...this.filteredMedicines.filter(m => this.medicinesService.isNoPrescribe(m.flag))])];
        } else if ( noPrescriptionFilter === CustomButtonFilterStatus.ShowOnly ) {
            this.filteredMedicines = this.filteredMedicines.filter(m => this.medicinesService.isNoPrescribe(m.flag));
        } else {
            this.filteredMedicines = this.filteredMedicines.filter(m => !this.medicinesService.isNoPrescribe(m.flag));
        }

        if ( obsoleteFilter === CustomButtonFilterStatus.Include ) {
            this.filteredMedicines = [...new Set([...this.filteredMedicines, ...this.filteredMedicines.filter(m => this.medicinesService.isObsolete(m.flag))])];
        } else if ( obsoleteFilter === CustomButtonFilterStatus.ShowOnly ) {
            this.filteredMedicines = this.filteredMedicines.filter(m => this.medicinesService.isObsolete(m.flag));
        } else {
            this.filteredMedicines = this.filteredMedicines.filter(m => !this.medicinesService.isObsolete(m.flag));
        }

        if ( fgpFilter === CustomButtonFilterStatus.Include ) {
            this.filteredMedicines = [...new Set([...this.filteredMedicines, ...this.filteredMedicines.filter(m => m.FGP)])];
        } else if ( fgpFilter === CustomButtonFilterStatus.ShowOnly ) {
            this.filteredMedicines = this.filteredMedicines.filter(m => m.FGP);
        } else {
            this.filteredMedicines = this.filteredMedicines.filter(m => !m.FGP)
        }

        // Filter by name
        this.filteredMedicines = this.filteredMedicines
        .map((medicine: Medicine) => {
            // Disable filter by PA
            // @ts-ignore
            delete medicine.PA_LIST
            return medicine;
        })
        .filter((medicine: Medicine) =>
            (filters.alert ? this.medicinesService.hasAlert(medicine.flag) : true) &&
            (filters.search.trim().length == 0 ? true : Object.values(medicine).some((val) =>
                filters.search.trim().length > 0 &&
                filters.search.split(' ').every((search: string) => {
                    return val
                        ?.toString()
                        .toLowerCase()
                        .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                        .includes(search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
                })
            ))
        )

        // Filter by active ingredients
        if ( filters.otherFilters?.pa?.trim().length > 0 ) {
            this.filteredMedicines = this.filteredMedicines
            .map((medicine: Medicine) => {
                // Enable filter by PA by creating an string of PA names
                // @ts-ignore
                medicine.PA_LIST = medicine.PA.map((pa) => pa.Nombre).join(' ');
                return medicine;
            })
            .filter((medicine: Medicine) =>
                (filters.otherFilters.pa.trim().length == 0 ? true : Object.values(medicine).some((val) =>
                    filters.otherFilters.pa.trim().length > 0 &&
                    filters.otherFilters.pa.split(' ').every((search: string) => {
                        return val
                            ?.toString()
                            .toLowerCase()
                            .replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u')
                            .includes(search.toLowerCase().replace('á', 'a').replace('é', 'e').replace('í', 'i').replace('ó', 'o').replace('ú', 'u'))
                    })
                ))
            )
        }


        // Sort filtered result
        this.filteredMedicines = this.filteredMedicines.sort((a: Medicine, b: Medicine) => {
            if (a.name.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.name.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1
            if (a.name.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1
            return 0;
        });

        // Update cards medicines
        if ( this.cardsRef ) {
            this.cardsRef.currentData = this.filteredMedicines;
            this.cardsRef instanceof ListsComponent ? (this.cardsRef as ListsComponent).sort() : null;
            this.cardsRef.paginator.firstPage();
        }

        this.filteredResults = this.filteredMedicines.map((medicine: Medicine) => {
            return { id: medicine.id, label: medicine.name }
        });

        console.log(this.filteredMedicines[0])

        this.resultsRefreshed = false;
        this.cdr.detectChanges();
        this.resultsRefreshed = true;
        this.cdr.detectChanges();
    }
}
