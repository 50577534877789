import { DateService } from './../date.service';
import { Injectable } from '@angular/core';
import { OptionsObject } from '@interfaces/dynamic-form.interface';
import {
  FormValue,
  GetDispensingPlanPetition,
} from '@interfaces/procs/proc-plans';
import { CallsService } from '@services/api/calls.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcsService {
  constructor(private calls: CallsService, private dateService: DateService) {}

  getDn(
    institution: string,
    stardate: string,
    endDate: string,
    type: string
  ): string {
    const currentDate = new Date();
    let dn = `PM_${institution}_${stardate}_${
      `${currentDate.getHours()}` + `${currentDate.getMinutes()}`
    }_Del_${stardate}_Al_${endDate}`;
    switch (type) {
      case 'pdf':
      default:
        return (dn += '.pdf');
      case 'excel':
        return (dn += '.xls');
    }
  }

  parsePathHtml(globalPath: string, pathFp: string, fileName: string): string {
    let path = pathFp.replace(/\\/g, '/');
    return `${globalPath}${path}/${fileName}`;
  }

  createChildsArray(
    departmentIds: string[]
  ): { id: string; item: any; childs: never[] }[] {
    return departmentIds
      .filter((child) => child !== undefined)
      .map((child) => ({
        id: 'D',
        item: child,
        childs: [],
      }));
  }

  getInstitutionName(
    institution: string,
    options: OptionsObject
  ): string | undefined {
    return options['institutions'].find((inst) => inst.value === institution)
      ?.label;
  }

  getSelectedDepartmentsIds(
    optionsSelected: any[],
    departments: any[]
  ): string[] {
    if (optionsSelected.length !== departments.length) {
      return (
        optionsSelected.map((option) => {
          return departments
            .find((obj) => obj.title === option.item)
            ?.id.slice(1);
        }) || []
      );
    } else {
      return [];
    }
  }

  getRPData(forms: any, optionsTreeSelected: any, departments: any): string {
    const form = forms['settings'].form;
    const { institution } = form.value.institution;
    const { startDate, startHour, endDate, endHour } = form.value.interval;

    const whclInstrinfy = JSON.stringify(optionsTreeSelected);

    return JSON.stringify({
      StartDate: this.dateService.transformToDateWithSpecificHours(
        startDate,
        startHour
      ),
      EndDate: this.dateService.transformToDateWithSpecificHours(
        endDate,
        endHour
      ),
      InstitutionId: `${institution}`,
      Condition: whclInstrinfy,
      IncludeNoBlister: '0',
    });
  }

  createDispensingPlanPayload(
    institution: string,
    startDate: string,
    startHour: string,
    endDate: string,
    endHour: string,
    type: string,
    pills: string,
    whclInstrinfy: string,
    noLockDate: boolean,
    noShowDeps: boolean,
    noShowDays: boolean,
    noShowHours: boolean,
    reasonType: string,
    options: any
  ): GetDispensingPlanPetition {
    const institutionName = this.getInstitutionName(institution, options);

    return {
      jss: JSON.stringify({
        institutionId: parseInt(institution),
        dateFrom: `${this.dateService.formatDateToString(
          // @ts-ignore
          startDate,
          startHour
        )}`,
        dateUntil: `${this.dateService.formatDateToString(
          // @ts-ignore
          endDate,
          endHour
        )}`,
        includeNoBlisters: 2,
        planType: parseInt(type),
        reasonType: reasonType,
        noLockDate: String(noLockDate),
        noShowDeps: String(noShowDeps),
        noShowDays: String(noShowDays),
        noShowHours: String(noShowHours),
        downloadName: `${
          institutionName ?? institution
        }_${this.dateService.convertStringOfDate(
          startDate,
          startHour
        )}_${this.dateService.convertStringOfDate(startDate, startHour)}_.DAT`,
        clauseWHERE: whclInstrinfy,
        pillsPerBlister: parseInt(pills),
      })
    };
  }

  extractFormValues(forms: any): {
    startDate: string;
    startHour: string;
    endDate: string;
    endHour: string;
    institution: string;
    type: string;
    pills: string;
    hideDepartments: boolean;
    hideDays: boolean;
    hideHours: boolean;
    noLockDate: boolean;
    noShowDeps: boolean;
    noShowDays: boolean;
    noShowHours: boolean;
    reasonType: string;
  } {
    const formValue = forms['settings'].form.value as FormValue;
    const institution = formValue.institution.institution;
    const { startDate, startHour, endDate, endHour } = formValue.interval;
    const { type, pills } = formValue.typeRobot;
    const { hideDepartments, hideDays, hideHours } = formValue.branchHide;

    const noLockDate = formValue.interval.permitFiveDays || false;
    const noShowDeps = formValue.branchHide.hideDepartments || false;
    const noShowDays = formValue.branchHide.hideDays || false;
    const noShowHours = noShowDays ? true : formValue.branchHide.hideHours || false;
    const reasonType = formValue.typeRequest.reason.toString();
    
    return {
      startDate,
      startHour,
      endDate,
      endHour,
      institution,
      type,
      pills,
      hideDepartments,
      hideDays,
      hideHours,
      noLockDate,
      noShowDeps,
      noShowDays,
      noShowHours,
      reasonType
    };
  }

  getPMReasonType(): Observable<any> {
    return this.calls.getPMReasonType();
  }
}
