import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  public currentFile: File | undefined;
  public selectedImage: string | undefined;

  public selectImageText: string = 'Seleccionar imagen';
  public okButtonText: string = 'OK';
  public cancelButtonText: string = 'Cancelar';

  constructor(private dialogRef: MatDialogRef<ImageModalComponent>) {}

  handleImageUpload(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
      this.currentFile = new File([target.result as ArrayBuffer], file.name);
    };

    reader.readAsArrayBuffer(file);
  }

  uploadImage(): void {
    if (this.currentFile) {
      this.dialogRef.close(this.currentFile);
    }
  }
}
